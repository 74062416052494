import React, { useEffect, useRef, useState } from "react";
import { ChevronDown, Heart, Image } from "lucide-react";
import { twMerge } from "tailwind-merge";
import classNames from "classnames";

const DropDownInfo = ({
  name = "แขวง/ เขต/ จังหวัด/ รหัสไปรษณีย์",
  placeholder = "กรุณาเลือกสถานที่",
  allstyle = "flex flex-col w-full h-[51px]",
  inputstyle = "flex justify-between h-[51px] px-[24px] py-[13px] border-[1px] border-[#8E8E8E] outline-none rounded-[8.4px]",
  placeholderstyle = "text-[#8E8E8E] self-center",
  dropdownStyle = "flex flex-col text-base font-light px-[15px] py-[24px] space-y-[17px] bg-white rounded-[8.4px] shadow-admin_nav z-20",
  chevronStyle = "w-[24px] h-[24px]",
  reddot = true,
  openDropDown,
  setOpenDropDown,
  allInfo,
  setAllInfo,
  choice = [],
  typeOrnot = false,
  error = false,
}) => {
  const [alterPlaceholder, setAlterPlaceholder] = useState("");
  // const [alterPlaceholder, setAlterPlaceholder] = useState(placeholder);

  const wrapper = useRef(null);

  useEffect(() => {
    setAlterPlaceholder(placeholder);
  }, [placeholder]);

  // console.log("placeholder",placeholder)


  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenDropDown((prev) => ({
            ...prev,
            [openDropDown[0]]: false,
          }));
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(wrapper);

  const OpenChoice = () => {
    setOpenDropDown((prev) => ({
      ...prev,
      [openDropDown[0]]: !openDropDown[1],
    }));
  };

  const MakeChoice = () => {
    let allchoice = [];

    choice?.map((item, i) => {
      allchoice.push(
        <button
          key={i}
          onClick={() => {
            setAllInfo((prev) => ({
              ...prev,
              [allInfo[0]]: item.value,
            }));
            setOpenDropDown((prev) => ({
              ...prev,
              [openDropDown[0]]: !openDropDown[1],
            }));
            setAlterPlaceholder(item.label);
          }}
          className="flex justify-start"
        >
          {item.label}
        </button>
      );
    });

    return allchoice;
  };

  // console.log("alterPlaceholder", alterPlaceholder);
  // console.log("allInfo", allInfo);

  return (
    <div className={allstyle} ref={wrapper}>
      {typeOrnot ? (
        <label>
          <input
            type="text"
            className="w-full h-[51px] border-solid border-[1px] hidden border-[#8E8E8E] outline-none px-[14px] py-[24px] rounded-[8.4px]"
          ></input>
          <button
            onClick={OpenChoice}
            className={twMerge(
              classNames(inputstyle, error && "border-red-500 border-[2px]")
            )}
          >
            <div className={placeholderstyle}>{alterPlaceholder}</div>
            <ChevronDown className={chevronStyle} />
          </button>
        </label>
      ) : (
        <>
          <div className="flex text-base font-medium items-center">
            <div className="self-center">{name}</div>
            {reddot && <div className="text-[#C41F30]">*</div>}
          </div>
          <button
            onClick={OpenChoice}
            className={twMerge(
              classNames(inputstyle, error && "border-red-500 border-[2px]")
            )}
          >
            <div className={placeholderstyle}>{alterPlaceholder}</div>
            <ChevronDown className={chevronStyle} />
          </button>
        </>
      )}
      {openDropDown[1] && <div className={dropdownStyle}>{MakeChoice()}</div>}
    </div>
  );
};

export default DropDownInfo;
