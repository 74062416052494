import React, { useEffect, useState } from "react";
import { API } from "../../api/api.main";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import BannerCard from "../../component/card/bannerCard";
import PaginationMain from "../../component/pagination/paginationMain";
import qs from "qs";


const FILTERSTYLE =
  "flex justify-center items-center h-[26px] text-[14px] font-semibold rounded-[5px] px-[15px] py-[4px] hover:text-era-red hover:bg-era-red/[0.1] transition-all duration-300";

const ContentListBanner = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [alldata, setAllData] = useState([]);
  const [count, setCount] = useState("");
  const [moreload, setMoreload] = useState(30);
  const [skip, setSkip] = useState(0);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    // setReqTitle(location.search.split("=")[1]);
    loadData();
  }, [skip]);

  const pageSelect = (number) => {
    setSkip((number - 1) * moreload);
  };

  const mainfilter = {
    skip: skip,
    limit: moreload,
    sort: { updatedAt : -1 },
  };

  const loadData = async () => {
    try {
      setLoading(true);
      const { data } = await API.get(`/banners?${qs.stringify(mainfilter)}`);
      setAllData(data?.banners);
      setCount(data?.count);
    } catch (error) {
      console.log("product error", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  console.log("alldata", alldata);

  return (
    <div className=" pl-[30px] pt-[28px]">
      <div className="flex items-end w-full h-[56px] mb-[28px]">
        <div className="w-[56px] h-[36px] text-[28px] text-era-red font-semibold mr-[43px] pl-[23px]">
          Banner
        </div>
      </div>
      <div className="w-full pl-[23px]">
        {alldata?.map((item, index) => {
          return (
            <span className="w-fit h-fit inline-block mx-6 my-10">
              <BannerCard key={item?._id} ID={item?._id} letload={loadData}/>
            </span>
          );
        })}
      </div>
      {alldata.length >= count ? (
        <></>
      ) : (
        // <div className="flex w-[950px] justify-center mt-[70px]">
        <div className="flex w-11/12 justify-center mt-[70px]">
          <PaginationMain
            total={count}
            perpage={moreload}
            quanDisplay={5}
            setSkip={pageSelect}
          />
        </div>
      )}
    </div>
  );
};

export default ContentListBanner;
