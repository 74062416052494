const awsConfig = {
    aws_project_region: "ap-southeast-1",
    aws_cloud_logic_custom: [
      {
        name: "eraAPI",
        endpoint:
          "https://pjj6vo6pdd.execute-api.ap-southeast-1.amazonaws.com/dev",
        region: "ap-southeast-1",
      },
    ],
    aws_cognito_identity_pool_id:
      "ap-southeast-1:ed352d1c-8395-4995-9df7-82b92612f5ae",
    aws_cognito_region: "ap-southeast-1",
    aws_user_pools_id: "ap-southeast-1_fOHXhagOD",
    aws_user_pools_web_client_id: "1o3cltmkjpkhsv5vl2bb23pu28",
    oauth: {},
    aws_cognito_username_attributes: [],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: [],
    aws_cognito_mfa_configuration: "OFF",
    aws_cognito_mfa_types: ["SMS"],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: [],
    },
    aws_cognito_verification_mechanisms: ["EMAIL"],
    aws_user_files_s3_bucket:
      "fronterad7ea5ef9f9004429b462019946401e63164652-dev",
    aws_user_files_s3_bucket_region: "ap-southeast-1",
  };
  
  export { awsConfig };
  