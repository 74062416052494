import logo from "./logo.svg";
import "./App.css";
import { Amplify } from "aws-amplify";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomePage from "./pages/home/home.page";
import ProductPage from "./pages/product/product.page";
import LayOut from "./component/layout/layout.com";
import ProductShow from "./pages/product/prodoct.detail";
import ProductCreate from "./pages/product/product.create";
import { awsConfig } from "./config/aws.con";
import RequestHome from "./pages/request/requset.page";
import Requestlist from "./pages/request/request.list";
import Requestdetail from "./pages/request/request.detail";
import Careerlist from "./pages/career/career.list";
import CareerDatail from "./pages/career/career.datail";
import ContentPage from "./pages/content/content.page";
import Contentbanner from "./pages/content/content.banner";
import Contentyoutube from "./pages/content/content.youtube";
import Contentreview from "./pages/content/content.review";
import Contentaddcomment from "./pages/content/content.addcomment";
import Bloglist from "./pages/blog/blog.list";
import Blogadd from "./pages/blog/blog.add";
import ProductChange from "./pages/product/product.change";
import BlogEdit from "./pages/blog/blog.edit";
import LoginPage from "./pages/login/login";
import Memberlist from "./pages/member/member.list";
import Memberadd from "./pages/member/member.add";
import MemberEdit from "./pages/member/member.edit";
import ContentListBanner from "./pages/content/content.listBanner";
import Editbanner from "./pages/content/content.EditBanner";

Amplify.configure(awsConfig);

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <LayOut>
        <ProductPage />
      </LayOut>
    ),
  },
  {
    path: "/login",
    element: (
      <LoginPage />
    ),
  },
  {
    path: "/product",
    element: (
      <LayOut>
        <ProductPage />
      </LayOut>
    ),
  },
  {
    path: "/product/show",
    element: (
      <LayOut>
        <ProductShow />
      </LayOut>
    ),
  },
  {
    path: "/product/create",
    element: (
      <LayOut>
        <ProductCreate />
      </LayOut>
    ),
  },
  {
    path: "/product/change/:id",
    element: (
      <LayOut>
        <ProductChange />
      </LayOut>
    ),
  },
  {
    path: "/request",
    element: (
      <LayOut>
        <RequestHome />
      </LayOut>
    ),
  },
  {
    path: "/request/list",
    element: (
      <LayOut>
        <Requestlist />
      </LayOut>
    ),
  },
  {
    path: "/request/list/detail/:id",
    element: (
      <LayOut>
        <Requestdetail />
      </LayOut>
    ),
  },
  {
    path: "/career",
    element: (
      <LayOut>
        <Careerlist />
      </LayOut>
    ),
  },
  {
    path: "/career/detail/:id",
    element: (
      <LayOut>
        <CareerDatail />
      </LayOut>
    ),
  },
  {
    path: "/content",
    element: (
      <LayOut>
        <ContentPage/>
      </LayOut>
    ),
  },
  {
    path: "/content/banner",
    element: (
      <LayOut>
        <ContentListBanner />
      </LayOut>
    ),
  },
  {
    path: "/content/banner/edit/:id",
    element: (
      <LayOut>
        <Editbanner />
      </LayOut>
    ),
  },
  {
    path: "/content/banner/addbanner",
    element: (
      <LayOut>
        <Contentbanner/>
      </LayOut>
    ),
  },
  {
    path: "/content/youtube",
    element: (
      <LayOut>
        <Contentyoutube/>
      </LayOut>
    ),
  },
  {
    path: "/content/review",
    element: (
      <LayOut>
        <Contentreview/>
      </LayOut>
    ),
  },
  {
    path: "/content/review/:id",
    element: (
      <LayOut>
        <Contentaddcomment/>
      </LayOut>
    ),
  },
  {
    path: "/blog",
    element: (
      <LayOut>
        <Bloglist/>
      </LayOut>
    ),
  },
  {
    path: "/blog/addblog",
    element: (
      <LayOut>
        <Blogadd/>
      </LayOut>
    ),
  },
  {
    path: "/blog/edit/:id",
    element: (
      <LayOut>
        <BlogEdit/>
      </LayOut>
    ),
  },
  {
    path: "/member",
    element: (
      <LayOut>
        <Memberlist/>
      </LayOut>
    ),
  },
  {
    path: "/member/addmember",
    element: (
      <LayOut>
        <Memberadd/>
      </LayOut>
    ),
  },
  {
    path: "/member/editmember/:id",
    element: (
      <LayOut>
        <MemberEdit />
      </LayOut>
    ),
  },
]);

function App() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
