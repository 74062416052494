import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { ChevronDown, Heart, Image } from "lucide-react";
import DropDownInfo from "./dropDownInfo";
import { DISTANCE_UNIT_CONVERT } from "../utils/util.con";
import { twMerge } from "tailwind-merge";

const HeartCheckbox = ({
  name = "ตลาด",
  type = "",
  distance = "",
  nearby,
  setNearby,
  error = {
    nearbyname: false,
    nearbydistance: false,
  },
}) => {
  const [checkOrnot, setCheckOrNot] = useState(false);
  const [isFav, setIsFav] = useState(false);
  const [unitName, setUnitName] = useState({ nameunit: "meter" });
  const [unitdd, setUnitdd] = useState({
    unitopen: false,
  });
  const [initNearby, setINitNearby] = useState({
    nearbyname: "",
    nearbytype: type,
    nearbydistance: "",
    nearbyunit: "",
    nearbyFav: false,
  });
  let MainIndex = "";

  const [firstrender, setFirstrender] = useState(false);

  if (nearby.length > 0) {
    MainIndex = nearby.findIndex(
      (index) => index.nearbytype === initNearby.nearbytype
    );
  }

  useEffect(() => {
    if (!firstrender && nearby?.length > 0) {
      let tempNearby = [...nearby];
      let index = tempNearby.map((item) => item.nearbytype).indexOf(type);
      // console.log("nearby_type",nearby.map((item) => item.nearbytype).includes(type))
      if (nearby.map((item) => item.nearbytype).includes(type)) {
        // console.log("index ",index )
        setCheckOrNot(nearby.map((item) => item.nearbytype).includes(type));
        setIsFav(nearby[index].nearbyFav);
        setUnitName({ nameunit: nearby[index].nearbyunit });
        setINitNearby({
          nearbyname: nearby[index].nearbyname,
          nearbytype: type,
          nearbydistance: nearby[index].nearbydistance,
          nearbyunit: nearby[index].nearbyunit,
          nearbyFav: nearby[index].nearbyFav,
        });
      }
      setFirstrender(true);
    }
  }, [nearby, firstrender]);

  useEffect(() => {
    let tempNearby = [...nearby];
    let index = tempNearby.map((item) => item.product_nearbytype).indexOf(type);

    if (tempNearby.length > 0) {
      if (tempNearby.map((item) => item.nearbytype).includes(type)) {
        let index = tempNearby.map((item) => item.nearbytype).indexOf(type);
        tempNearby[index].nearbyunit = unitName.nameunit;
        setNearby(tempNearby);
      } else {
        tempNearby.push({ ...initNearby, nearbyunit: "meter" });

        setNearby(tempNearby);
      }
    } else {
      setINitNearby((prev) => ({
        ...prev,
        nearbyunit: unitName.nameunit,
      }));
      tempNearby.push({ ...initNearby, nearbyunit: unitName.nameunit });
      if (checkOrnot) {
        setNearby(tempNearby);
      }
    }
  }, [unitName.nameunit]);

  return (
    <div className="w-[341px] flex flex-col space-y-[8.5px]">
      <div className="w-full flex justify-start self-center space-x-[8px]">
        <input
          onChange={(e) => {
            let tempNearby = [...nearby];
            let index = tempNearby.map((item) => item.nearbytype).indexOf(type);

            if (tempNearby.length > 0) {
              tempNearby = tempNearby.filter(
                (item) => item.nearbytype !== initNearby.nearbytype
              );
            }

            if (e.target.checked) {
              tempNearby.push(initNearby);
            }

            if (e.target.checked === false) {
              console.log("tempNearby", tempNearby);
              setINitNearby((prev) => ({
                ...prev,
                nearbyname: "",
                nearbydistance: "",
              }));
              tempNearby = tempNearby.filter(
                (item) => item.nearbytype !== initNearby.nearbytype
              );
              setIsFav(false);
            }

            setCheckOrNot(e.target.checked);
            setNearby(tempNearby);
          }}
          checked={checkOrnot}
          type="checkbox"
          className="w-[20px] h-[20px] border-solid border-[1px] border-[#E0DFDF] rounded-[5px] accent-era-red"
        />
        <button
          onClick={() => {
            setIsFav(!isFav);
            // setCheckOrNot(true);
            let tempNearby = [...nearby];

            if (nearby.filter((item) => item.nearbyFav === true).length <= 1) {
              setCheckOrNot(true);
              if (tempNearby.length > 0) {
                if (tempNearby.map((item) => item.nearbytype).includes(type)) {
                  let index = tempNearby
                    .map((item) => item.nearbytype)
                    .indexOf(type);
                  tempNearby[index].nearbyFav = !tempNearby[index].nearbyFav;
                  setNearby(tempNearby);
                } else {
                  tempNearby.push({ ...initNearby, nearbyFav: true });

                  setNearby(tempNearby);
                }
              } else {
                setINitNearby((prev) => ({
                  ...prev,
                  nearbyFav: true,
                }));
                tempNearby.push({ ...initNearby, nearbyFav: true });

                setNearby(tempNearby);
              }
            } else {
              if (tempNearby.map((item) => item.nearbytype).includes(type)) {
                let index = tempNearby
                  .map((item) => item.nearbytype)
                  .indexOf(type);
                tempNearby[index].nearbyFav = !tempNearby[index].nearbyFav;
                setNearby(tempNearby);
              }
              setIsFav(false);
            }
          }}
        >
          <Heart
            className={classNames("w-[20px] h-[20px]", {
              "stroke-era-red fill-era-red": isFav === true,
              "stroke-[#E0DFDF] fill-white": isFav === false,
            })}
          />
        </button>
        <div className="text-base font-medium">{name}</div>
      </div>
      {checkOrnot && (
        <div className="flex space-x-[15px]">
          <input
            type="text"
            placeholder="ชื่อสถานที่"
            onChange={(e) => {
              let tempNearby = [...nearby];
              let updatedInitNearby = {
                ...initNearby,
                nearbyFav: isFav,
                nearbyname: e.target.value,
              };
              setINitNearby((prev) => ({
                ...prev,
                nearbyname: e.target.value,
              }));

              let index = tempNearby.findIndex(
                (index) => index.nearbytype === type
              );

              // tempNearby[index] = initNearby;
              tempNearby[index] = updatedInitNearby;
              setNearby(tempNearby);

              setINitNearby(updatedInitNearby);
            }}
            value={initNearby.nearbyname}
            className={twMerge(
              classNames(
                "w-[105px] h-[38px] p-[10px] placeholder-[#8E8E8E] rounded-[8.4px] border-solid border-[1px] border-black/[0.29]",
                error?.nearbyname && "border-[2px] border-red-500"
              )
            )}
          ></input>
          <input
            type="number"
            placeholder="ระยะทาง"
            onKeyDown={(evt) => {
              if (evt.target.type === "number") {
                ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault();
              }
            }}
            onChange={(e) => {
              let tempNearby = [...nearby];

              let updatedInitNearby = {
                ...initNearby,
                nearbyFav: isFav,
                // nearbydistance: newValue,
                nearbydistance: e.target.value,
              };
              setINitNearby((prev) => ({
                ...prev,
                // nearbydistance: newValue,
                nearbydistance: e.target.value,
              }));

              let index = tempNearby.findIndex(
                (index) => index.nearbytype === initNearby.nearbytype
              );

              tempNearby[index] = updatedInitNearby;
              setNearby(tempNearby);

              setINitNearby(updatedInitNearby);
            }}
            value={initNearby.nearbydistance}
            className={twMerge(
              classNames(
                "w-[105px] h-[38px] p-[10px] placeholder-[#8E8E8E] rounded-[8.4px] border-solid border-[1px] border-black/[0.29]",
                error?.nearbydistance && "border-[2px] border-red-500"
              )
            )}
          ></input>
          <DropDownInfo
            name=""
            placeholder={DISTANCE_UNIT_CONVERT[unitName.nameunit]}
            choice={[
              { label: "เมตร", value: "meter" },
              { label: "กิโลเมตร", value: "kilometer" },
            ]}
            allstyle="flex flex-col w-[105px] h-[38px]"
            inputstyle="flex self-center item-center justify-between w-[105px] h-[38px] px-[10px] py-[10px] border-[1px] border-[#8E8E8E] outline-none rounded-[8px]"
            chevronStyle="w-[20px] h-[20px] self-center item-center"
            reddot={false}
            openDropDown={Object.entries(unitdd)[0]}
            setOpenDropDown={setUnitdd}
            allInfo={Object.entries(unitName)[0]}
            setAllInfo={setUnitName}
          />
        </div>
      )}
    </div>
  );
};

export default HeartCheckbox;
