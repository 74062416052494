import React from "react";
import CategoryProduct from "../../component/button/categoryProduct";
import { PencilIcon, YoutubeIcon } from "lucide-react";

const ContentPage = () => {
  return (
    <div className=" flex flex-col items-center pt-[178px]">
      <div className="flex space-x-[19px] mb-[14px]">
        <CategoryProduct
          label="Banners"
          navto="/content/banner"
          icon={
            <svg
              width="60"
              height="60"
              viewBox="0 0 60 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="w-[60px] h-[60px] stroke-era-red self-center m-[10px]"
            >
              <path
                d="M22.3077 19.7436C22.3077 21.1597 21.1597 22.3077 19.7436 22.3077C18.3275 22.3077 17.1795 21.1597 17.1795 19.7436C17.1795 18.3275 18.3275 17.1795 19.7436 17.1795C21.1597 17.1795 22.3077 18.3275 22.3077 19.7436Z"
                fill="#C41F30"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M30 8.84615C24.577 8.84615 17.1654 9.34382 12.5374 9.70237C11.0154 9.82028 9.82028 11.0154 9.70237 12.5374C9.34382 17.1654 8.84615 24.577 8.84615 30C8.84615 34.3488 9.1662 39.9766 9.47789 44.422L26.8203 30.5481C31.3895 26.8928 37.9124 27.0033 42.3551 30.8113L50.9071 38.1416C51.0519 35.3153 51.1538 32.454 51.1538 30C51.1538 24.577 50.6562 17.1654 50.2976 12.5374C50.1797 11.0154 48.9846 9.82028 47.4626 9.70237C42.8346 9.34382 35.423 8.84615 30 8.84615ZM54.5234 42.233C54.7777 38.2377 55 33.6916 55 30C55 24.4137 54.491 16.8705 54.1323 12.2403C53.868 8.82852 51.1715 6.13202 47.7597 5.8677C43.1295 5.50899 35.5863 5 30 5C24.4137 5 16.8705 5.50899 12.2403 5.8677C8.82852 6.13202 6.13202 8.82852 5.8677 12.2403C5.50899 16.8705 5 24.4137 5 30C5 35.5863 5.50899 43.1295 5.8677 47.7597C6.13202 51.1715 8.82852 53.868 12.2403 54.1323C16.8705 54.491 24.4137 55 30 55C35.5863 55 43.1295 54.491 47.7597 54.1323C51.1715 53.868 53.868 51.1715 54.1323 47.7597C54.2532 46.1989 54.3912 44.3071 54.521 42.2708C54.522 42.2582 54.5228 42.2456 54.5234 42.233ZM50.6217 42.9627L39.8521 33.7315C36.8123 31.1261 32.3493 31.0504 29.223 33.5514L10.2147 48.7581C10.1882 48.7792 10.1613 48.7996 10.1341 48.8192C10.633 49.6486 11.5109 50.2181 12.5374 50.2976C17.1654 50.6562 24.577 51.1538 30 51.1538C35.423 51.1538 42.8346 50.6562 47.4626 50.2976C48.9846 50.1797 50.1797 48.9846 50.2976 47.4626C50.3993 46.1502 50.5122 44.6139 50.6217 42.9627Z"
                fill="#C41F30"
              />
            </svg>
          }
        />
        <CategoryProduct
          label="Youtube"
          navto="/content/youtube"
          icon={
            <svg
              width="61"
              height="60"
              viewBox="0 0 61 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="w-[60px] h-[60px] stroke-era-red self-center m-[10px]"
            >
              <path
                d="M25.5 37.5L38.475 30L25.5 22.5V37.5ZM54.4 17.925C54.725 19.1 54.95 20.675 55.1 22.675C55.275 24.675 55.35 26.4 55.35 27.9L55.5 30C55.5 35.475 55.1 39.5 54.4 42.075C53.775 44.325 52.325 45.775 50.075 46.4C48.9 46.725 46.75 46.95 43.45 47.1C40.2 47.275 37.225 47.35 34.475 47.35L30.5 47.5C20.025 47.5 13.5 47.1 10.925 46.4C8.675 45.775 7.225 44.325 6.6 42.075C6.275 40.9 6.05 39.325 5.9 37.325C5.725 35.325 5.65 33.6 5.65 32.1L5.5 30C5.5 24.525 5.9 20.5 6.6 17.925C7.225 15.675 8.675 14.225 10.925 13.6C12.1 13.275 14.25 13.05 17.55 12.9C20.8 12.725 23.775 12.65 26.525 12.65L30.5 12.5C40.975 12.5 47.5 12.9 50.075 13.6C52.325 14.225 53.775 15.675 54.4 17.925Z"
                fill="#C41F30"
              />
            </svg>
          }
        />
      </div>
      <div className="flex space-x-[19px]">
        <CategoryProduct
          label="Reviews"
          navto="/content/review"
          icon={
            <PencilIcon className="w-[60px] h-[60px] stroke-era-red self-center m-[10px]" />
          }
        />
      </div>
    </div>
  );
};

export default ContentPage;
