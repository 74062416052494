import React, { useEffect } from "react";
import Reqlist from "../../component/list/reqlist";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { API } from "../../api/api.main";
import { ConQs } from "../../utils/util.con";
import PaginationMain from "../../component/pagination/paginationMain";

const Requestlist = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reqtitle, setReqTitle] = useState("");
  const [alldata, setAllData] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [moreload, setMoreLoad] = useState(30);
  const [skip, setSkip] = useState(0);
  const [count, setcount] = useState(0);

  useEffect(() => {
    // console.log("location", location.pathname);
    console.log("location", location.search.split("=")[1]);
    setReqTitle(location.search.split("=")[1]);
    loadData();
  }, [skip]);

  const mainfilter = {
    skip: skip,
    limit: moreload,
    sort: { updatedAt: -1 },
  };

  const pageSelect = (number) => {
    // console.log("number",number)
    setSkip((number - 1) * moreload);
  };

  const CONTACT_CATEGORY = {
    sell: "ฝากขายบ้าน",
    find: "ฝากหาบ้าน",
    advice: "แนะนำ ติชม",
    ads: "ติดต่อลงโฆษณา",
    etc: "ติดต่อสอบถามเรื่องอื่น ๆ",
  };

  const loadData = async () => {
    switch (searchParams.get("title")) {
      case "contactus":
        try {
          setLoading(true);
          const { data } = await API.get("/" + searchParams.get("title")+"?"+ConQs(mainfilter));
          setAllData(data.contacts);
          setcount(data.count);
        } catch (error) {
          console.log("product error", error);
          setError(error);
        } finally {
          setLoading(false);
        }
        break;
      case "buyandsells":
        try {
          setLoading(true);
          const { data } = await API.get("/" + searchParams.get("title")+"?"+ConQs(mainfilter));
          // console.log("data", data);
          setAllData(data.buysells);
          setcount(data.count);
        } catch (error) {
          console.log("product error", error);
          setError(error);
        } finally {
          setLoading(false);
        }
        break;
      case "appointments":
        try {
          setLoading(true);
          const { data } = await API.get("/"+ searchParams.get("title")+"?"+ConQs(mainfilter));
          setAllData(data.appointments);
          setcount(data.count);
        } catch (error) {
          console.log("product error", error);
          setError(error);
        } finally {
          setLoading(false);
        }
        break;
      default:
        break;
    }
    // try {
    //   setLoading(true);
    //   const { data } = await API.get("/" + searchParams.get("title"));
    //   setAllData(data.contacts);
    // } catch (error) {
    //   console.log("product error", error);
    //   setError(error);
    // } finally {
    //   setLoading(false);
    // }
  };
  console.log("alldata", alldata);
  return (
    <div className="flex w-full pt-[23px] pl-[40px] pr-[65px]">
      <div className="flex flex-col  w-full">
        {searchParams.get("title") !== "appointments" ? (<div className="h-[67px] flex justify-between rounded-[10px] items-center pl-[42px] pr-[66px] mb-[26px] bg-era-red/[0.1]">
          <div className="w-[282px] text-era-red text-xl font-semibold items-center ">
            ชื่อ-นามสกุล
          </div>
          <div className="w-[158px] text-era-red text-xl font-semibold items-center ">
            เบอร์โทร
          </div>
          <div className="w-[272px] text-era-red text-xl font-semibold items-center ">
            อีเมล
          </div>
          <div className="w-[272px] text-era-red text-xl font-semibold items-center ">
            ประเภททรัพย์
          </div>
          <div className="text-era-red text-xl font-semibold items-center justify-self-end">
            ลบ
          </div>
        </div>):(
          <div className="w-full  h-[67px] flex justify-between rounded-[10px] items-center pl-[42px] pr-[66px] mb-[26px] bg-era-red/[0.1]">
          <div className="w-[211px] text-era-red text-xl font-semibold items-center ">
            ชื่อ-นามสกุล
          </div>
          <div className="w-[141px] text-era-red text-xl font-semibold items-center ">
            เบอร์โทร
          </div>
          <div className="w-[228px] text-era-red text-xl font-semibold items-center ">
            อีเมล
          </div>
          <div className="w-[111px] text-era-red text-xl font-semibold items-center ">
          วัน
          </div>
          <div className="w-[124px] text-era-red text-xl font-semibold items-center ">
          เวลา
          </div>
          <div className="w-[168px] text-era-red text-xl font-semibold items-center ">
          สถานที่
          </div>
          <div className="text-era-red text-xl font-semibold items-center justify-self-end">
            ลบ
          </div>
        </div>
        ) }
        {searchParams.get("title") === "appointments" && (
           alldata.map((item, index) => (
            <Reqlist
              ID={item._id}
              title={reqtitle}
              name={item.appointment_name}
              telnum={item.appointment_tel}
              email={item.appointment_email}
              time={item.appointment_time}
              date={item.appointment_date}
              address={item.appointment_Locate}
              type={CONTACT_CATEGORY[item.appointment_datail]}
              reloadData={loadData}
            />
          ))
        )}
        {searchParams.get("title") === "buyandsells" &&
          alldata.map((item, index) => (
            <Reqlist
              ID={item._id}
              title={reqtitle}
              name={item.buysell_name + " " + item.buysell_lastname}
              telnum={item.buysell_telNum}
              email={item.buysell_email}
              type={item.buysell_address}
              reloadData={loadData}
            />
          ))}
          {searchParams.get("title") === "contactus" &&
          alldata.map((item, index) => (
            <Reqlist
              ID={item._id}
              title={reqtitle}
              name={item?.contact_name}
              telnum={item?.contact_telNum}
              email={item?.contact_email}
              type={item?.contact_title}
              reloadData={loadData}
            />
          ))}
            {alldata.length >= count ? (
        <></>
      ) : (
        // <div className="flex w-[950px] justify-center mt-[70px]">
        <div className="flex w-11/12 justify-center mt-[70px]">
          <PaginationMain
            total={count}
            perpage={moreload}
            quanDisplay={5}
            setSkip={pageSelect}
          />
        </div>
      )}
      </div>
    </div>
  );
};

export default Requestlist;
