import React, { useEffect, useState } from "react";
import Reqlist from "../../component/list/reqlist";
import { API } from "../../api/api.main";
import PaginationMain from "../../component/pagination/paginationMain";
import { ConQs } from "../../utils/util.con";

const Memberlist = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [alldata, setAllData] = useState([]);

  const [moreload, setMoreLoad] = useState(30);
  const [skip, setSkip] = useState(0);
  const [count, setcount] = useState(0);

  useEffect(() => {
    loadData();
  }, [skip]);

  const mainfilter = {
    skip: skip,
    limit: moreload,
    sort: { updatedAt: -1 },
  };

  const pageSelect = (number) => {
    // console.log("number",number)
    setSkip((number - 1) * moreload);
  };

  const loadData = async () => {
    try {
      setLoading(true);
      const { data } = await API.get(`/member?${ConQs(mainfilter)}`);
      // console.log("data", data?.members);
      setAllData(data?.members);
      setcount(data.count);
    } catch (error) {
      setError(error);
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  if (error) {
    return <div>{JSON.stringify(error)}</div>;
  }

  return (
    <div className="flex justify-between w-full pt-[23px] pl-[40px] pr-[65px]">
      <div className="flex flex-col w-full">
        <div className="w-full h-[67px] flex justify-between rounded-[10px] items-center pl-[42px] pr-[66px] mb-[26px] bg-era-red/[0.1]">
          <div className="w-[149px] text-era-red text-xl font-semibold items-center ">
            รูปภาพ
          </div>
          <div className="w-[204px] text-era-red text-xl font-semibold items-center ">
            ชื่อ-นามสกุล
          </div>
          <div className="w-[204px] text-era-red text-xl font-semibold items-center ">
            ชื่อเล่น
          </div>
          <div className="w-[309px] text-era-red text-xl font-semibold items-center ">
            เบอร์โทร
          </div>
          <div className="w-[97px] text-era-red text-xl font-semibold items-center ">
            แก้ไข
          </div>
          <div className="text-era-red text-xl font-semibold items-center justify-self-end">
            ลบ
          </div>
        </div>
        {alldata?.map((item) => {
          return (
            <Reqlist
              ID={item?._id}
              Image={item?.image}
              title={"member"}
              name={item?.name}
              nickname={item?.nickname}
              telnum={item?.contactways[0]?.info}
              reloadData={loadData}
            />
          );
        })}
        {alldata.length >= count ? (
          <></>
        ) : (
          // <div className="flex w-[950px] justify-center mt-[70px]">
          <div className="flex w-11/12 justify-center mt-[70px]">
            <PaginationMain
              total={count}
              perpage={moreload}
              quanDisplay={5}
              setSkip={pageSelect}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Memberlist;
