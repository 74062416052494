import React, { useEffect, useState } from "react";
import InputText from "../../component/textinput";
import COVERIMAGE from "../../Image/timeline_20180320_203316 1.png";
import Cardyoutube from "../../component/card/cardyoutube";
import { API } from "../../api/api.main";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import PaginationMain from "../../component/pagination/paginationMain";
import qs from "qs";

const checkFormError = (form) => {
  return {
    vid_name: !form.vid_name,
    vid_url: !form.vid_url,
  };
};

const initError = {
  vid_name: false,
  vid_url: false,
};

const Contentyoutube = () => {
  const [loading, setloading] = useState(true);
  const [error, setError] = useState("");
  const [alldata, setAllData] = useState([]);
  const [formError, setFormError] = useState(initError);
  const [postdata, setPostdata] = useState({
    vid_name: "",
    vid_url: "",
  });

  const [moreload, setMoreLoad] = useState(30);
  const [skip, setSkip] = useState(0);
  const [count, setcount] = useState(0);

  const navigate = useNavigate();

  const mainfilter = {
    skip: skip,
    limit: moreload,
    sort: { updatedAt: -1 },
  };

  useEffect(() => {
    loadData();
  }, [skip]);

  const pageSelect = (number) => {
    // console.log("number",number)
    setSkip((number - 1) * moreload);
  };

  const loadData = async () => {
    try {
      setloading(true);
      let { data } = await API.get(`/videos?${qs.stringify(mainfilter)}`);
      console.log("data", data.videos);
      setAllData(data.videos);
      setcount(data?.count);
    } catch (error) {
      console.log("error", error);
      setError("error", error);
    } finally {
      setloading(false);
    }
  };
  const ClicktoSubmit = async () => {
    console.log("postdata", postdata);

    const thisFormError = checkFormError(postdata);
    console.log("thisFormError", thisFormError);
    const isFormError = Object.entries(thisFormError)?.some(
      ([_, value]) => value === true
    );

    if (isFormError) {
      console.log("checkTypePrice", thisFormError);
      setFormError(thisFormError);
      Swal.fire({
        title: "โปรดกรอกข้อมูลให้ครบถ้วน",
        icon: "warning",
      });
      return;
    } else {
      setFormError(initError);
    }

    if (postdata.vid_url !== "" && postdata.vid_name !== "") {
      await API.post("/videos", {
        vid_url: postdata.vid_url,
        vid_name: postdata.vid_name,
      })
        .then((res) => {
          Swal.fire({
            title: "เพิ่มข้อมูลข้อมูลเรียบร้อย",
            icon: "success",
          });
          console.log("res", res);
        })
        .catch((error) => {
          Swal.fire({
            title: "บันทึกข้อมูลผิดพลาด",
            icon: "error",
          });
          console.log(error);
        });
      setPostdata({
        vid_name: "",
        vid_url: "",
      });
      loadData();
    }
  };

  return (
    <div className=" pl-[40px] pr-[90px] pt-[28px] pb-[25px] flex flex-col just self-center">
      <div className="text-[28px] text-era-red font-semibold mb-[21px]">
        Youtube
      </div>
      <div className="flex space-x-[30px] mb-[42px]">
        <InputText
          name="ชื่อวิดีโอ"
          placeholder="กรอกชื่อวิดีโอ"
          allInfo={Object.entries(postdata)[0]}
          setAllInfo={setPostdata}
          error={formError.vid_name}
        />
        <InputText
          name="Link video"
          placeholder="กรอก Link video"
          allInfo={Object.entries(postdata)[1]}
          setAllInfo={setPostdata}
          error={formError.vid_url}
        />
        <button
          onClick={ClicktoSubmit}
          className="w-[189px] h-[50px] mt-[20px] flex justify-center items-center text-xl text-white font-semibold rounded-[40px] bg-era-red duration-300 hover:bg-[#E53648]"
        >
          บันทึก
        </button>
      </div>
      {/* <div className="flex space-x-[40px]"> */}
      <div className="w-full pl-[23px]">
        {alldata?.map((item, index) => (
          <span className="w-fit h-fit inline-block mx-6 my-10">
            {" "}
            <Cardyoutube
              ID={item?._id}
              name={item?.vid_name}
              Img={COVERIMAGE}
              Link={item?.vid_url}
              loadData={loadData}
            />
          </span>
        ))}
      </div>
      {alldata.length >= count ? (
        <></>
      ) : (
        // <div className="flex w-[950px] justify-center mt-[70px]">
        <div className="flex w-11/12 justify-center mt-[70px]">
          <PaginationMain
            total={count}
            perpage={moreload}
            quanDisplay={5}
            setSkip={pageSelect}
          />
        </div>
      )}
    </div>
  );
};

export default Contentyoutube;
