import { MinusCircle } from "lucide-react";
import numeral from "numeral";
import React from "react";
import { API } from "../../api/api.main";
import Swal from "sweetalert2";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { NEARBYICON } from "../../utils/util.con";

const PRODUCT_TYPE_STYLE =
  "flex justify-center w-[58px] rounded-r-[10px] text-white text-[20px] absolute z-10 top-[18px] left-0 py-[9.5px] max-sm:w-[24px] max-sm:h-[24px] max-sm:text-[11px] max-sm:rounded-l-[0px] max-sm:rounded-r-[3.72px] max-sm:pt-[2.3px] max-sm:pb-[3.75px] max-sm:px-[4.7px] max-sm:top-[8px] max-sm:left-0";
const FONT_TITLE = "text-[20px] font-bold max-sm:text-[12px] max-sm:font-bold";

const CardProduct = ({
  ID,
  product_draft = "",
  product_price = 0,
  product_cover,
  product_view = 12,
  product_title = "",
  product_location = "bb",
  product_bedRoom = 0,
  product_bathRoom = 0,
  product_area = [],
  product_status = "",
  product_category = "",
  product_unitarea = "",
  product_type = "sale",
  product_nearby = [],
  product_district,
  product_sub_district,
  product_province,
  product_postcode,
  product_price_rent = 0,
  loadData,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navto = useNavigate();

  const deleteData = (e) => {
    e.stopPropagation();
    Swal.fire({
      title: "ท่านต้องการลบทรัพย์นี้ใช่หรือไม่",
      showDenyButton: true,
      confirmButtonText: "ใช่",
      denyButtonText: `ไม่`,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        try {
          setLoading(true);
          await API.delete("/products/" + ID);
          await Swal.fire({
            title: "ลบข้อมูลเรียบร้อย",
            icon: "success",
          });
        } catch (error) {
          console.log("delete error", error);
          Swal.fire({
            title: "ลบข้อมูลผิดพลาด",
            icon: "warning",
          });
          setError(error);
        } finally {
          setLoading(false);
          loadData();
        }
        // API.delete("/products/" + ID)
        //   .then((res) => {
        //     console.log(res);
        //     Swal.fire("Saved!", "ทรัพย์นี้ถูกลบแล้ว", "success");
        //     loadData();
        //   })
        //   .error((error) => {
        //     console.log("delete error", error);
        //     Swal.fire("ตกลง", "เกิดข้อผิดพลาด", "error");
        //   });
      }
    });
  };

  let favnearby = product_nearby.filter(
    (item) => item.product_nearbyfavorite === true
  );

  const UnitDistance = {
    meter: "ม.",
    kilometer: "กม.",
  };

  return (
    <div
      onClick={() => {
        navto(`/product/change/${ID}`);
      }}
      className="w-[280px] h-[500px] pt-[5.3px] bg-white self-start items-start rounded-[5px] relative shadow-productCard hover:shadow-productCardhover duration-300 max-sm:w-[165px] max-sm:h-[295px] max-sm:rounded-[3px]"
      // style={{ boxShadow: "1px 1px 12px rgba(0, 0, 0, 0.30)" }}
    >
      <button
        onClick={deleteData}
        className="w-[30px] h-[30px] absolute -top-[15px] -right-[15px]"
      >
        <MinusCircle className="w-[30px] h-[30px] stroke-era-red fill-white " />
      </button>
      {product_type === "sale" && product_draft !== "draft" && (
        <div className="w-[58px] h-[41px] flex justify-center items-center absolute bg-[#FE0000] top-[18px] rounded-r-[10px] px-[8px] pb-[11px] pt-[4px] text-white text-xl font-sesmibold">
          ขาย
        </div>
      )}
      {product_type === "rent" && (
        <div className="w-[58px] h-[41px] flex justify-center items-center absolute bg-[#2B54E4] top-[18px] rounded-r-[10px] px-[8px] pb-[11px] pt-[4px] text-white text-xl font-sesmibold">
          เช่า
        </div>
      )}
      {product_type === "saleandrent" && product_draft !== "draft" && (
        <div
          className={`bg-white w-[90px] h-[41px] flex justify-center items-center absolute top-[18px] rounded-r-[10px] px-[8px] text-[#fe0000] text-xl font-sesmibold`}
        >
          ขาย/เช่า
        </div>
      )}
      {product_draft === "draft" && (
        <div className="w-[58px] h-[41px] flex justify-center items-center absolute bg-[#8E8E8E] top-[18px] rounded-r-[10px] px-[8px] pb-[11px] pt-[4px] text-white text-xl font-sesmibold">
          draft
        </div>
      )}
      <img
        // src={COVERIMAGE1}
        src={product_cover}
        className="w-[271px] h-[249px] mx-[4.5px] mb-[12px]"
      />
      <div className="flex mb-[10.5px]">
        <div className="w-full flex flex-col justify-start space-y-[6px] pl-[16px] pr-[5px]">
          <div className="w-full flex justify-between items-center">
            <div className="flex justify-start space-x-[10px] text-xl text-era-red font-semibold truncate">
              {product_type === "sale" || product_type === "rent" ? (
                <div>{numeral(product_price).format("0,0")}</div>
              ) : (
                <div>{numeral(product_price_rent).format("0,0")}</div>
              )}
              {product_type === "sale" ? <div>บาท</div> : <div>บาท/เดือน</div>}
            </div>
            <div className="flex items-center space-x-[7px] w-[45px] h-[16px] mr-[15px]">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.99935 6C7.46892 6 6.96021 6.21071 6.58513 6.58579C6.21006 6.96086 5.99935 7.46957 5.99935 8C5.99935 8.53043 6.21006 9.03914 6.58513 9.41421C6.96021 9.78929 7.46892 10 7.99935 10C8.52978 10 9.03849 9.78929 9.41356 9.41421C9.78864 9.03914 9.99935 8.53043 9.99935 8C9.99935 7.46957 9.78864 6.96086 9.41356 6.58579C9.03849 6.21071 8.52978 6 7.99935 6ZM7.99935 11.3333C7.11529 11.3333 6.26745 10.9821 5.64233 10.357C5.0172 9.7319 4.66602 8.88406 4.66602 8C4.66602 7.11595 5.0172 6.2681 5.64233 5.64298C6.26745 5.01786 7.11529 4.66667 7.99935 4.66667C8.8834 4.66667 9.73125 5.01786 10.3564 5.64298C10.9815 6.2681 11.3327 7.11595 11.3327 8C11.3327 8.88406 10.9815 9.7319 10.3564 10.357C9.73125 10.9821 8.8834 11.3333 7.99935 11.3333ZM7.99935 3C4.66602 3 1.81935 5.07333 0.666016 8C1.81935 10.9267 4.66602 13 7.99935 13C11.3327 13 14.1793 10.9267 15.3327 8C14.1793 5.07333 11.3327 3 7.99935 3Z"
                  fill="#8E8E8E"
                />
              </svg>
              <div className="text-xs font-normal">{product_view}</div>
            </div>
          </div>
          <div className="w-[230px] min-h-[28px] text-xl font-semibold truncate">
            {product_title}
          </div>
          <div className="flex text-[16px] space-x-1 font-normal ine-clamp-1 truncate">
            <div>ต.{product_sub_district}</div>
            <div>อ.{product_district}</div>
            <div>จ.{product_province}</div>
          </div>
        </div>
      </div>
      <div
        className={
          product_unitarea === "rai"
            ? "flex justify-start mb-[9px]"
            : "flex mb-[9px]"
        }
      >
        {product_category !== "land" && (
          <div
            className={
              product_unitarea === "rai"
                ? "flex flex-col justify-start ml-[20px] w-[70px] h-[49px]"
                : "flex flex-col justify-start ml-[20px] w-[78px] h-[49px]"
            }
          >
            <div className={"flex space-x-[4px]"}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 7H11V14H3V5H1V20H3V17H21V20H23V11C23 9.93913 22.5786 8.92172 21.8284 8.17157C21.0783 7.42143 20.0609 7 19 7ZM7 13C7.79565 13 8.55871 12.6839 9.12132 12.1213C9.68393 11.5587 10 10.7956 10 10C10 9.20435 9.68393 8.44129 9.12132 7.87868C8.55871 7.31607 7.79565 7 7 7C6.20435 7 5.44129 7.31607 4.87868 7.87868C4.31607 8.44129 4 9.20435 4 10C4 10.7956 4.31607 11.5587 4.87868 12.1213C5.44129 12.6839 6.20435 13 7 13Z"
                  fill="black"
                />
              </svg>
              <div className="text-[12px] font-normal align-middle">
                {product_bathRoom}
              </div>
            </div>
            <div className="text-[10px] font-light">จำนวนห้องนอน</div>
          </div>
        )}
        {product_category !== "land" && (
          <div
            className={
              product_unitarea === "rai"
                ? "flex flex-col justify-start w-[65px] h-[49px]"
                : "flex flex-col justify-start w-[78px] h-[49px]"
            }
          >
            <div className="flex space-x-[4px]">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 14V12H4V14C4 15.138 4.583 17.248 6.745 17.841C7.115 17.943 7.532 18 8 18H16C16.4236 18.0037 16.8457 17.9502 17.255 17.841C19.417 17.248 20 15.138 20 14Z"
                  fill="black"
                />
                <path
                  d="M3 12H4M4 12H20M4 12V14C4 15.138 4.583 17.248 6.745 17.841M20 12C20.2652 12 20.5196 11.8946 20.7071 11.7071C20.8946 11.5196 21 11.2652 21 11V7C21 6 20.4 4 18 4C15.6 4 15 6 15 7M20 12V14C20 15.138 19.417 17.248 17.255 17.841M15 7H13M15 7H17M17.255 17.841C16.8457 17.9502 16.4236 18.0037 16 18H8C7.532 18 7.115 17.943 6.745 17.841M17.255 17.841L18 20M6.745 17.841L6 20"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div className="text-[12px] font-normal align-middle">{product_bedRoom}</div>
            </div>
            <div className="text-[10px] font-light">จำนวนห้องน้ำ</div>
          </div>
        )}
        <div
          className={
            product_category !== "land"
              ? product_unitarea === "rai"
                ? "flex flex-col justify-start w-[120px] h-[49px]"
                : "flex flex-col justify-start w-[78px] h-[49px]"
              : "flex flex-col ml-[20px] justify-start w-full h-[49px]"
          }
        >
          <div className="flex space-x-[4px] ">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 2H20C21.1 2 22 2.9 22 4V10H10V20H14.55C14.73 20.3 14.91 20.58 15.09 20.84C15.38 21.27 15.66 21.66 15.92 22H4C2.9 22 2 21.1 2 20V4C2 2.9 2.9 2 4 2ZM8 4H4V14H8V4ZM4 16V20H8V16H4ZM10 8H20V4H10V8ZM18.5 22C18.5 22 17.92 21.35 17.23 20.41C17.14 20.28 17.04 20.14 16.94 20C16 18.66 15 16.89 15 15.5C15 13.6 16.6 12 18.5 12C19.03 12 19.54 12.13 20 12.35C21.17 12.92 22 14.13 22 15.5C22 17.04 20.77 19.04 19.77 20.41C19.08 21.35 18.5 22 18.5 22ZM17.3 15.6C17.3 16.2 17.8 16.8 18.5 16.8C19.2 16.8 19.8 16.2 19.7 15.6C19.7 15 19.1 14.4 18.5 14.4C17.9 14.4 17.3 14.9 17.3 15.6Z"
                fill="black"
              />
            </svg>
            <div
              className={
                product_category !== "land"
                  ? product_unitarea === "rai"
                    ? "w-full flex space-x-[6px] text-[12px] font-normal align-middle"
                    : "w-[47px] flex space-x-[6px] text-[12px] font-normal align-middle"
                  : "w-full flex space-x-[6px] text-[12px] font-normal "
              }
            >
              {product_unitarea === "rai" && (
                <div className="flex space-x-1 overflow-hidden">
                  {product_area[0] !== 0 && (
                    <div className="flex">
                      <div
                        className={
                          product_category !== "land"
                            ? "text-[12px] font-normal align-middle"
                            : "text-[12px] font-normal align-middle"
                        }
                      >
                        {" " + product_area[0] + " "}
                      </div>
                      <div
                        className={
                          product_category !== "land"
                            ? "text-[12px] font-normal align-middle"
                            : "text-[12px] font-normal align-middle"
                        }
                      >
                        {" " + " ไร่ " + " "}
                      </div>
                    </div>
                  )}
                  {product_area[1] !== 0 && (
                    <div className="flex">
                      <div
                        className={
                          product_category !== "land"
                            ? "text-[12px] font-normal align-middle"
                            : "text-[12px] font-normal align-middle"
                        }
                      >
                        {" " + product_area[1] + " "}
                      </div>
                      <div
                        className={
                          product_category !== "land"
                            ? "text-[12px] font-normal align-middle"
                            : "text-[12px] font-normal align-middle"
                        }
                      >
                        {" " + "งาน" + " "}
                      </div>
                    </div>
                  )}
                  {product_area[2] !== 0 && (
                    <div className="flex">
                      <div
                        className={
                          product_category !== "land"
                            ? "text-[12px] font-normal align-middle"
                            : "text-[12px] font-normal align-middle"
                        }
                      >
                        {" " + product_area[2] + " "}
                      </div>
                      <div
                        className={
                          product_category !== "land"
                            ? "text-[12px] font-normal align-middle"
                            : "text-[12px] font-normal align-middle"
                        }
                      >
                        {" ตร.ว. "}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {product_unitarea === "squaremeter" && (
                <>
                  <div className="text-[12px] font-normal align-middle">
                    {product_area[2]}
                  </div>
                  <div className="text-[12px] font-normal align-middle">ตร.ม.</div>
                </>
              )}
              {product_unitarea === "wa" && (
                <>
                  <div className="text-[12px] font-normal align-middle">
                    {product_area[2]}
                  </div>
                  <div className="text-[12px] font-normal align-middle">ตร.ว.</div>
                </>
              )}
              {/* <div className="text-[14px] font-semibold">{product_area}</div>
                  <div className="text-[14px] font-semibold">ตร.ม.</div> */}
            </div>
          </div>
          <div className="text-[10px] font-light">ขนาดพื้นที่</div>
        </div>
      </div>
      <div className="w-full self-end text-[10px] font-semibold pl-[9px] pb-[7px]">
        สถานที่ใกล้เคียง
      </div>
      <div className="flex self-end w-full">
        <div className="w-1/2 flex items-center justify-center h-[52px] pl-[4px] rounded-bl-[5px] space-x-[5px] border-solid border-[1px] border-[#CECECE5E]/[0.37]">
          <div className="w-[30px] h-[30px]">
            {NEARBYICON[favnearby[0]?.product_nearbytype]}
          </div>
          <div className="flex flex-col w-[101px] truncate justify-start">
            <div className="text-[10px] min-h-[15px] font-semibold line-clamp-2">
              {favnearby[0]?.product_nearbyname}
            </div>
            <div className="text-xs font-normal w-[101px]">
              {"เดินทาง "+favnearby[0]?.product_nearbydistance+" "+UnitDistance[favnearby[0]?.product_nearbyunit]}
            </div>
          </div>
        </div>
        <div className="w-1/2 flex items-center justify-center h-[52px] pl-[4px] rounded-br-[5px] space-x-[5px] border-solid border-[1px] border-[#CECECE5E]/[0.37]">
          <div className="w-[30px] h-[30px]">
            {NEARBYICON[favnearby[1]?.product_nearbytype]}
          </div>
          <div className="flex flex-col w-[101px] truncate justify-start">
            <div className="text-[10px] min-h-[15px] font-semibold line-clamp-2">
              {favnearby[1]?.product_nearbyname}
            </div>
            <div className="text-xs font-normal w-[101px]">
              {"เดินทาง "+favnearby[1]?.product_nearbydistance+" "+UnitDistance[favnearby[1]?.product_nearbyunit]}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardProduct;
