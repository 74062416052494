import React from "react";

const IconDrawerAdd1 = ({width = "24", height = "24", fill ="#030D45", className=" group-hover:fill-[#C41F30]" }) => {
  // console.log("className",className)
  //fill ="#030D45"
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 9.69231C9.80429 9.69231 6.79854 9.86404 4.95178 9.98536C4.35535 10.0245 3.8832 10.4754 3.81879 11.0569C3.68541 12.2608 3.53846 13.8676 3.53846 15.0769C3.53846 16.2862 3.68541 17.8931 3.81879 19.097C3.8832 19.6784 4.35535 20.1293 4.95178 20.1685C6.79854 20.2898 9.80429 20.4615 12 20.4615C14.1957 20.4615 17.2015 20.2898 19.0482 20.1685C19.6447 20.1293 20.1168 19.6784 20.1812 19.097C20.3146 17.8931 20.4615 16.2862 20.4615 15.0769C20.4615 13.8676 20.3146 12.2608 20.1812 11.0569C20.1168 10.4754 19.6446 10.0245 19.0482 9.98536C17.2015 9.86404 14.1957 9.69231 12 9.69231ZM4.85093 8.4502C6.69825 8.32885 9.74878 8.15385 12 8.15385C14.2512 8.15385 17.3018 8.32885 19.1491 8.4502C20.477 8.53744 21.5624 9.55217 21.7103 10.8875C21.8444 12.0977 22 13.7766 22 15.0769C22 16.3772 21.8444 18.0561 21.7103 19.2664C21.5624 20.6017 20.477 21.6164 19.1491 21.7036C17.3018 21.825 14.2512 22 12 22C9.74878 22 6.69825 21.825 4.85093 21.7036C3.52304 21.6164 2.43761 20.6017 2.28968 19.2664C2.1556 18.0561 2 16.3772 2 15.0769C2 13.7766 2.1556 12.0978 2.28968 10.8875C2.43761 9.55217 3.52304 8.53744 4.85093 8.4502Z"
        fill={fill}
        className={className}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 6.61539C10.3995 6.61539 8.248 6.69061 6.79989 6.75004C6.21382 6.77409 5.76196 7.18453 5.69116 7.70434C5.63175 8.14046 5.58974 8.57653 5.58974 8.92308C5.58974 9.34791 5.24535 9.69231 4.82051 9.69231C4.39568 9.69231 4.05128 9.34791 4.05128 8.92308C4.05128 8.47393 4.1039 7.95828 4.16677 7.4967C4.34645 6.17764 5.4686 5.26491 6.73681 5.21287C8.18625 5.15339 10.3652 5.07692 12 5.07692C13.6348 5.07692 15.8138 5.15339 17.2632 5.21287C18.5314 5.26491 19.6536 6.17764 19.8332 7.4967C19.8961 7.95828 19.9487 8.47393 19.9487 8.92308C19.9487 9.34791 19.6043 9.69231 19.1795 9.69231C18.7547 9.69231 18.4103 9.34791 18.4103 8.92308C18.4103 8.57653 18.3682 8.14046 18.3088 7.70434C18.238 7.18453 17.7862 6.77409 17.2001 6.75004C15.752 6.69061 13.6005 6.61539 12 6.61539Z"
        fill={fill}
        className={className}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 3.53846C11.0449 3.53846 9.80589 3.59168 8.81793 3.64423C8.22109 3.67598 7.75986 4.11837 7.70772 4.67291C7.6686 5.08895 7.64103 5.50609 7.64103 5.84615C7.64103 6.27099 7.29663 6.61539 6.8718 6.61539C6.44696 6.61539 6.10256 6.27099 6.10256 5.84615C6.10256 5.43606 6.13507 4.96436 6.17601 4.52889C6.30333 3.17484 7.41874 2.17801 8.73621 2.10794C9.72881 2.05515 11.0018 2 12 2C12.9982 2 14.2712 2.05515 15.2638 2.10794C16.5813 2.17801 17.6967 3.17484 17.824 4.52889C17.8649 4.96436 17.8974 5.43606 17.8974 5.84615C17.8974 6.27099 17.553 6.61539 17.1282 6.61539C16.7034 6.61539 16.359 6.27099 16.359 5.84615C16.359 5.50609 16.3314 5.08895 16.2923 4.67291C16.2401 4.11837 15.7789 3.67598 15.1821 3.64423C14.1941 3.59168 12.9551 3.53846 12 3.53846Z"
        fill={fill}
        className={className}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.17949 15.0769C9.17949 14.6521 9.52388 14.3077 9.94872 14.3077H14.0513C14.4761 14.3077 14.8205 14.6521 14.8205 15.0769C14.8205 15.5018 14.4761 15.8462 14.0513 15.8462H9.94872C9.52388 15.8462 9.17949 15.5018 9.17949 15.0769Z"
        fill={fill}
        className={className}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 17.8974C11.5752 17.8974 11.2308 17.553 11.2308 17.1282V13.0256C11.2308 12.6008 11.5752 12.2564 12 12.2564C12.4248 12.2564 12.7692 12.6008 12.7692 13.0256V17.1282C12.7692 17.553 12.4248 17.8974 12 17.8974Z"
        fill={fill}
        className={className}
      />
    </svg>
  );
};

export default IconDrawerAdd1;
