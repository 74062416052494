import React, { useEffect, useState } from "react";
import Reqlist from "../../component/list/reqlist";
import { API } from "../../api/api.main";
import qs from "qs";
import { ConQs } from "../../utils/util.con";
import PaginationMain from "../../component/pagination/paginationMain";

const Contentreview = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [alldata, setAlldata] = useState([]);

  const [moreload, setMoreLoad] = useState(30);
  const [skip, setSkip] = useState(0);
  const [count, setcount] = useState(0);

  useEffect(() => {
    loaddata();
  }, [skip]);

  const mainfilter = {
    skip: skip,
    limit: moreload,
    sort: { updatedAt: -1 },
  };

  const pageSelect = (number) => {
    // console.log("number",number)
    setSkip((number - 1) * moreload);
  };

  const loaddata = async () => {
    try {
      setLoading(true);
      const { data } = await API.get(`/comments?${ConQs(mainfilter)}`);
      // console.log("data", data.comments);
      setAlldata(data.comments);
      setcount(data.count);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex w-full  pt-[23px] pl-[40px] pr-[65px]">
      <div className="flex flex-col w-full">
        <div className="w-full h-[67px] flex justify-between rounded-[10px] items-center pl-[42px] pr-[66px] mb-[26px] bg-era-red/[0.1]">
          <div className="w-[105px] text-era-red text-xl font-semibold items-center ">
            ลำดับ
          </div>
          <div className="w-[280px] text-era-red text-xl font-semibold items-center ">
            ชื่อ-นามสกุล
          </div>
          <div className="w-[263px] text-era-red text-xl font-semibold items-center ">
            ระดับความคิดเห็น
          </div>
          <div className="w-[336px] text-era-red text-xl font-semibold items-center ">
            ความคิดเห็น
          </div>
          <div className="text-era-red text-xl font-semibold items-center justify-self-end">
            ลบ
          </div>
        </div>
        {alldata?.map((item, index) => (
          <Reqlist
            ID={item?._id}
            order={skip+index + 1}
            name={item?.comment_name}
            comment={item?.comment_boby}
            starNumber={item?.comment_star}
            title={"review"}
            reloadData={loaddata}
          />
        ))}
              {alldata.length >= count ? (
        <></>
      ) : (
        // <div className="flex w-[950px] justify-center mt-[70px]">
        <div className="flex w-11/12 justify-center mt-[70px]">
          <PaginationMain
            total={count}
            perpage={moreload}
            quanDisplay={5}
            setSkip={pageSelect}
          />
        </div>
      )}
      </div>
    </div>
  );
};

export default Contentreview;
