import { ChevronDown, Code, CrossIcon, Heart, Image, X } from "lucide-react";
import React, { useEffect, useId, useRef, useState } from "react";
import SunEditor from "suneditor-react";
import classNames from "classnames";
import "suneditor/dist/css/suneditor.min.css";
import InputText from "../../component/textinput";
import DropDownInfo from "../../component/dropDownInfo";
import HeartCheckbox from "../../component/heartCheckBox";
import { uploadData } from "aws-amplify/storage";
import UploadImage from "../../component/uploadImage";
import Namecon from "../../utils/Name.con";
import { ImgFromFile, NEARBY_TYPE } from "../../utils/util.con";
import axios from "axios";
import { ADDRESS_OPTIONS } from "../../constant/address";
import { Select } from "@sukhoi-ui/components";
import { API } from "../../api/api.main";
import FieldWysiwyg from "../../component/FieldWysiwyg/fieldWysiwyg";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { renderToString } from "react-dom/server";

const NOT_HAVE_LIVING_AREA = ["condo", "land",""];

const emptyField = () => {
  return {
    address: "ที่อยู่",
    // area_1: "",
    // area_2: "",
    area_3: "พื้นที่ใช้สอย",
    bathRoom: "จำนวนห้องน้ำ",
    bedroom: "จำนวนห้องนอน",
    category: "หมวดหมู่",
    contentPic: "รูปภาพเพิ่มเติม",
    coverPic: "รูปภาพสำหรับโปรโมต",
    errorPurpose: "ทรัพย์แนะนำ",
    floor: "จำนวนชั้น",
    fullAddress: "แขวง/ เขต/ จังหวัด/ รหัสไปรษณีย์",
    google_place: "Google place ID",
    member: "ผู้ประกาศ",
    nearbyFavError: "สถานที่ใกล้เคียง",
    price: "ราคา",
    propcode: "รหัสทรัพย์",
    status: "สถานะทรัพย์",
    title: "ชื่อทรัพย์",
    type: "ประเภททรัพย์",
    unitarea: "หน่วยพื้นที่ใช้สอย",
    urllocation: "แผนที่",
  };
};

const checkFormError = (
  form,
  member,
  nearby,
  cover,
  coverHorizon,
  contentImage,
  purpose
) => {
  let dynamicError = {};

  if (
    ["hotel", "businessBuilding", "office", "factory"].includes(form?.category)
  ) {
    // if (form.meetingRoom >= 0 && !form?.meetingRoom) {
    //   dynamicError.meetingRoom = false;
    // } else {
    //   dynamicError.meetingRoom = true;
    // }
  }

  if (form.category === "land") {
    dynamicError.bedroom = false;
    dynamicError.bathRoom = false;
    dynamicError.floor = false;
  } else {
    if (form?.bedroom === NaN || form?.bedroom < 0) {
      dynamicError.bedroom = true;
    } else {
      dynamicError.bedroom = false;
    }

    if (form?.bathRoom === NaN || form?.bathRoom < 0) {
      dynamicError.bathRoom = true;
    } else {
      dynamicError.bathRoom = false;
    }

    if (form?.floor === NaN || form?.floor < 0) {
      dynamicError.floor = true;
    } else {
      dynamicError.floor = false;
    }
  }

  if (form?.type === "saleandrent") {
    dynamicError.priceRent = !form?.priceRent;
  }

  const sumArea = form?.area?.[0] + form?.area?.[1] + form?.area?.[2];

  if (form?.unitarea === "rai") {
    if (sumArea > 0) {
      dynamicError.area_1 = form?.area?.[0];
      dynamicError.area_2 = form?.area?.[1];
      dynamicError.area_3 = form?.area?.[2];
    } else {
      dynamicError.area_1 = !form?.area?.[0];
      dynamicError.area_2 = !form?.area?.[1];
      dynamicError.area_3 = !form?.area?.[2];
    }
  } else {
    dynamicError.area_3 = !form?.area?.[2];
  }

  

  // console.log("cover",cover)

  if (cover.length < 1) {
    // console.log("aar")
    dynamicError.coverPic = true;
  } else {
    // console.log("bb")
    dynamicError.coverPic = false;
  }
  //coverHorizon

  if (coverHorizon.length < 1) {
    // console.log("aar")
    dynamicError.coverPicHorizon = true;
  } else {
    // console.log("bb")
    dynamicError.coverPicHorizon = false;
  }

  if (contentImage.length < 1) {
    dynamicError.contentPic = true;
  } else {
    // console.log("bb")
    dynamicError.contentPic = false;
  }

  if (purpose.length < 1) {
    dynamicError.errorPurpose = true;
  } else {
    dynamicError.errorPurpose = false;
  }

  let countNearbyFav = 0;

  for (let index = 0; index < nearby.length; index++) {
    const thisNearby = nearby[index];

    const nearbytype = thisNearby?.nearbytype;

    const thisKey = "nearby_" + nearbytype;

    dynamicError[thisKey] = {
      nearbyname: !thisNearby?.nearbyname,
      nearbydistance: !thisNearby?.nearbydistance,
    };

    const nearbyFav = thisNearby?.nearbyFav;

    if (nearbyFav) {
      countNearbyFav++;
    }
  }

  const sumLivingArea = form?.livingArea?.[0] + form?.livingArea?.[1] + form?.livingArea?.[2];

  if (
    !NOT_HAVE_LIVING_AREA.includes(form?.category)
  ) {
    dynamicError.unitLivingArea = !form.unitLivingArea;

    if (form?.unitLivingArea === "rai") {
      if (sumLivingArea > 0) {
        dynamicError.area_living_1 = form?.livingArea?.[0];
        dynamicError.area_living_2 = form?.livingArea?.[1];
        dynamicError.area_living_3 = form?.livingArea?.[2];
      } else {
        dynamicError.area_living_1 = !form?.livingArea?.[0];
        dynamicError.area_living_2 = !form?.livingArea?.[1];
        dynamicError.area_living_3 = !form?.livingArea?.[2];
      }
    } else {
      dynamicError.area_living_3 = !form?.livingArea?.[2];
    }
  }

  return {
    ...dynamicError,
    propcode: !form?.propcode,
    title: !form?.title,
    propcode: !form?.propcode,
    google_place: !form?.google_place,
    category: !form?.category,
    status: !form?.status,
    type: !form?.type,
    unitarea: !form?.unitarea,
    description: !form?.description,
    urllocation: !form?.urllocation,
    fullAddress: !form?.fullAddress,
    propcode: !form?.propcode,
    fullAddress: !form?.fullAddress,
    price: !form.price,
    address: !form.address,
    member: !member?.selectMember,
    nearbyFavError: countNearbyFav !== 2,
  };
};

const initError = {
  google_place: false,
  title: false,
  category: false,
  status: false,
  type: false,
  unitarea: false,
  bedroom: false,
  bathRoom: false,
  meetingRoom: false,
  description: false,
  urllocation: false,
  fullAddress: false,
  google_place: false,
  propcode: false,
  floor: false,
  priceRent: false,
  price: false,
  fullAddress: false,
  address: false,
};

const checkFormEmpty = (
  form,
  member,
  nearby,
  cover,
  coverHorizon,
  contentImage,
  purpose
) => {
  let dynamicError = {};
  // console.log("form");
  if (
    ["hotel", "businessBuilding", "office", "factory"].includes(form?.category)
  ) {
    // if (!form?.meetingRoom && form?.meetingRoom <= 0) {
    //   dynamicError.meetingRoom = "จำนวนห้องประชุม";
    // }
  }

  if (form.category !== "land") {
    if (!form?.bedroom || form?.bedroom < 0) {
      dynamicError.bedroom = "จำนวนห้องนอน";
    }
    if (!form?.bathRoom || form?.bathRoom < 0) {
      dynamicError.bathRoom = "จำนวนห้องน้ำ";
    }
    if (!form?.floor || form?.floor < 0) {
      dynamicError.floor = "จำนวนห้องชั้น";
    }
  }

  if (form?.type === "saleandrent") {
    if (!form.priceRent) {
      dynamicError.priceRent = "ราคาเช่า";
    }
  }

  if (!form?.google_place) {
    dynamicError.google_place = "google_place";
  }

  if (form?.unitarea === "rai") {
    if (!form?.area[0]) {
      dynamicError.area_1 = "พื้นที่(ไร่)";
    }
    if (!form?.area[1]) {
      dynamicError.area_2 = "พื้นที่(งาน)";
    }
    if (!form?.area[2]) {
      dynamicError.area_3 = "พื้นที่(ตร.ว.)";
    }
  } else {
    if (!form?.area[2]) {
      dynamicError.area_3 = "พื้นที่";
    }
  }

  if (!NOT_HAVE_LIVING_AREA.includes(form?.category)) {
    if (form?.unitLivingArea === "rai") {
      if (!form?.livingArea?.[0]) {
        dynamicError.livingArea_1 = "เนื้อที่(ไร่)";
      }
      if (!form?.livingArea?.[1]) {
        dynamicError.livingArea_2 = "เนื้อที่(งาน)";
      }
      if (!form?.livingArea?.[2]) {
        dynamicError.livingArea_3 = "เนื้อที่(ตร.ว.)";
      }
    } else {
      if (!form?.livingArea?.[2]) {
        dynamicError.livingArea_3 = "เนื้อที่";
      }
    }
   }

  if (cover.length < 1) {
    dynamicError.coverPic = "รูปภาพสำหรับโปรโมต(แนวตั้ง)";
  }
  if (coverHorizon.length < 1) {
    dynamicError.coverHorizon = "รูปภาพสำหรับโปรโมต(แนวนอน)";
  }

  if (contentImage.length < 1) {
    dynamicError.contentPic = "รูปภาพเพิ่มเติม";
  }

  if (purpose.length <= 0) {
    dynamicError.errorPurpose = "แนะนำทรัพย์";
  }

  let countNearbyFav = 0;

  for (let index = 0; index < nearby.length; index++) {
    const thisNearby = nearby[index];
    // console.log("thisNearby",thisNearby)

    const nearbytype = thisNearby?.nearbytype;

    const nearbynamethisKey = "nearbyname" + nearbytype;
    const nearbydistancethisKey = "nearbydistance" + nearbytype;

    if (thisNearby.nearbydistance === null) {
      dynamicError[
        nearbydistancethisKey
      ] = `ระยะทาง(${NEARBY_TYPE[nearbytype]})`;
    }

    if (thisNearby.nearbyname === "") {
      dynamicError[
        nearbynamethisKey
      ] = `ชื่อสถานที่ใกล้เคียง(${NEARBY_TYPE[nearbytype]})`;
    }

    const nearbyFav = thisNearby?.nearbyFav;

    if (nearbyFav) {
      countNearbyFav++;
    }
  }

  if (!form.title) {
    dynamicError.title = "ชื่อทรัพย์";
  }

  if (!form.title) {
    dynamicError.title = "ชื่อทรัพย์";
  }

  if (!form.category) {
    dynamicError.category = "หมวดหมู่";
  }
  if (!form.status) {
    dynamicError.status = "สถานะทรัพย์";
  }
  if (!form.type) {
    dynamicError.type = "ประเภททรัพย์";
  }
  if (!form.unitarea) {
    dynamicError.unitarea = "หน่วยพื้นที่ใช้สอย";
  }
  if (!form.description) {
    dynamicError.description = "คำอธิบายรายละเอียด";
  }
  if (!form.urllocation) {
    dynamicError.urllocation = "แผนที่";
  }
  if (!form.fullAddress) {
    dynamicError.fullAddress = "แขวง/ เขต/ จังหวัด/ รหัสไปรษณีย์";
  }
  if (!form.propcode) {
    dynamicError.propcode = "รหัสทรัพย์";
  }
  if (!form.price) {
    dynamicError.price = "ราคา";
  }
  if (!form.address) {
    dynamicError.address = "ที่อยู่";
  }
  if (member.selectMember.name === "") {
    dynamicError.member = "ผู้ประกาศ";
  }
  if (countNearbyFav < 2) {
    dynamicError.nearbyFavError = "สถานที่ใกล้เคียง(สำหรับแสดง)";
  }

  if (
    !NOT_HAVE_LIVING_AREA.includes(form.category) 
  ) {
    if (!form?.unitLivingArea) {
    dynamicError.unitLivingArea = "หน่วยเนื้อที่ใช้สอย";
    }
  }

  return {
    ...dynamicError,
  };
};

const ProductCreate = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [formLoading, setFormLoading] = useState(false);

  const [formError, setFormError] = useState(initError);

  const [allInfo, setAllInfo] = useState({
    title: "",
    location: "",
    address: "",
    category: "",
    status: "",
    price: "",
    type: "",
    area: [null, null, null],
    unitarea: "",
    bedroom: "",
    bathRoom: "",
    meetingRoom: "",
    description: "",
    urllocation: "",
    cover: "",
    image: "",
    google_place: "",
    propcode: "",
    floor: "",
    priceRent: "",
    fullAddress: null,
    livingArea: [null, null, null],
    unitLivingArea: "",
  });
  const [openDropDown, setOpenDropDown] = useState({
    address: false,
    category: false,
    type: false,
    area: false,
    unit: false,
    unitLivingArea: false,
  });

  const [purpose, setPurpose] = useState([]);
  const [nearby, setNearby] = useState([]);
  const [cover, setCover] = useState("");
  const [displayCover, setDisplayCover] = useState("");
  const [coverHorizon, setCoverHorizon] = useState("");
  const [displayCoverHorizon, setDisplayCoverHorizon] = useState("");
  const [contentImage, setImage] = useState([]);
  const [displayImage, setDisplayImage] = useState([]);

  const [memberData, setmemberData] = useState([]);
  const [openmember, setMember] = useState({ member: false });
  const [memberselect, setmemberselect] = useState({
    selectMember: "",
  });
  const navigate = useNavigate();

  const haveMeetingRoom = ["businessBuilding", "hotel", "office", "factory"];

  useEffect(() => {
    loadMemberData();
  }, []);

  const loadMemberData = async () => {
    try {
      setLoading(true);
      const { data } = await API.get("/member");
      // console.log("member", data);
      setmemberData(data);
    } catch (error) {
      console.log("Member product error", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  const onClickSubmit = async () => {
    //validate form
    let CoverName = "";
    let CoverNameHorizon = "";
    let ContentImageName = [];
    let allnearby = [];

    const thisFormError = checkFormError(
      allInfo,
      memberselect,
      nearby,
      cover,
      coverHorizon,
      contentImage,
      purpose
    );

    const isFormError = Object.entries(thisFormError)?.some(
      ([_, value]) => value === true
    );

    const notFillName = Object.entries(
      checkFormEmpty(
        allInfo,
        memberselect,
        nearby,
        cover,
        coverHorizon,
        contentImage,
        purpose
      )
    ).map((item) => item[1]);

    const listNotFill = () => {
      return notFillName.map((item) => (
        <span
          key={item}
          className="bg-gray-300 w-fit h-fit p-1 mx-1 my-1 rounded-md inline-block"
        >
          {item}
        </span>
      ));
    };

    if (isFormError) {
      console.log("thisFormError", thisFormError);
      console.log("isFormError", isFormError);
      // console.log("checkTypePrice",thisFormError)
      setFormError(thisFormError);
      Swal.fire({
        title: "โปรดกรอกข้อมูลให้ครบถ้วน",
        html: `<div>รายการ :${renderToString(listNotFill())}</div>`,
        icon: "warning",
      });
      setFormLoading(false);

      return;
    } else {
      setFormError(initError);
      setFormLoading(false);
    }

    if (allInfo.title !== "") {
      if (allInfo.type === "rent") {
        setAllInfo((prev) => ({
          ...prev,
          price: 0,
        }));
      }
      if (allInfo.type === "sale") {
        setAllInfo((prev) => ({
          ...prev,
          priceRent: 0,
        }));
      }
      if (cover !== "") {
        CoverName = Namecon(cover);
        const result = await uploadData({
          key: CoverName,
          data: cover,
        }).result;
        console.log("result", result);
      }

      if (coverHorizon !== "") {
        CoverNameHorizon = Namecon(coverHorizon);
        const result = await uploadData({
          key: CoverNameHorizon,
          data: coverHorizon,
        }).result;
        console.log("result", result);
      }

      if (contentImage.length > 0) {
        await Promise.all(
          Array.from(contentImage).map((item, index) => {
            ContentImageName.push(Namecon(item));
            const resultImage = uploadData({
              key: ContentImageName[index],
              data: item,
            }).result;
            console.log("result", resultImage);
          })
        );
      } else {
        console.error("contentImage is not an array or is empty");
      }
      nearby.map((item) => {
        allnearby.push({
          product_nearbyname: item.nearbyname,
          product_nearbytype: item.nearbytype,
          product_nearbydistance: item.nearbydistance,
          product_nearbyunit: item.nearbyunit,
          product_nearbyfavorite: item.nearbyFav,
        });
      });

      setFormLoading(true);

      setLoading(true);

      API.post("/products", {
        product_draft: "abcd",
        product_purpose: purpose,
        product_price:
          allInfo.type === "sale" ||
          allInfo.type === "rent" ||
          allInfo.type === "saleandrent"
            ? allInfo.price
            : 0,
        product_propcode: allInfo.propcode,
        product_google_place: allInfo.google_place,
        product_description: allInfo.description,
        product_contact: [],
        product_title: allInfo.title,
        product_address: allInfo.address,
        product_location: allInfo.fullAddress?.value,
        product_category: allInfo.category,
        product_type: allInfo.type,
        product_status: allInfo.status,
        product_area: allInfo.area,
        product_unitarea: allInfo.unitarea,
        product_living_area: allInfo.livingArea,
        product_unit_living_area: allInfo.unitLivingArea,
        product_urllocation: allInfo.urllocation,
        product_bedRoom: allInfo.bedroom,
        product_bathRoom: allInfo.bathRoom,
        product_meetingRoom: allInfo.meetingRoom,
        product_floor: allInfo.floor,
        product_nearby: allnearby,
        product_cover: CoverName,
        product_cover_horizon: CoverNameHorizon,
        product_images: ContentImageName,
        product_price_rent:
          allInfo.type === "saleandrent" ? allInfo.priceRent : 0,
        product_contact: {
          image: memberselect?.selectMember?.image,
          name: memberselect?.selectMember?.name,
          contactways: [
            {
              icon: "phoneIcon",
              name: "phone",
              info: memberselect?.selectMember?.contactways[0]?.info,
              color: {
                inside: "era_search/[0.37]",
                border: "era_search",
              },
              link: "",
            },
            {
              icon: "linelogo",
              name: "line",
              info: memberselect?.selectMember?.contactways[1]?.info,
              color: {
                inside: "lightGreen1/[0.37]",
                border: "linegreen",
              },
              link: memberselect?.selectMember?.contactways[1]?.link,
            },
            {
              icon: "mailIcon",
              name: "email",
              info: memberselect?.selectMember?.contactways[2]?.info,
              color: {
                inside: "lightBlue1/[0.37]",
                border: "lightBlue1",
              },
              link: "",
            },
          ],
        },
        product_member_id: memberselect?.selectMember?._id,
        product_Nickname: memberselect?.selectMember?.nickname,
        product_view: 0,
      })
        .then(function (response) {
          setFormLoading(false);
          setLoading(false);
          console.log(response);
          Swal.fire({
            title: "ส่งข้อมูลเรียบร้อย",
            icon: "success",
          }).then(() => {
            navigate("/");
          });
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire({
            title: "ส่งข้อมูลผิดพลาด",
            icon: "error",
          });
          setFormLoading(false);
          setLoading(false);
        })
        .finally(() => {});
    } else {
      console.log("fail to send data");
      Swal.fire({
        title: "กรุณากรองให้ครบ",
        text: "ท่านยังกรอกไม่ครบ",
        icon: "warning",
      });
    }
  };

  const onclickDraft = async () => {
    console.log("draft draft");
    let CoverName = "";
    let CoverNameHorizon = "";
    let ContentImageName = [];
    let allnearby = [];
    setFormLoading(true);

    if (allInfo.type === "rent") {
      setAllInfo((prev) => ({
        ...prev,
        price: 0,
      }));
    }
    if (allInfo.type === "sale") {
      setAllInfo((prev) => ({
        ...prev,
        priceRent: 0,
      }));
    }
    if (cover !== "") {
      CoverName = Namecon(cover);
      const result = await uploadData({
        key: CoverName,
        data: cover,
      }).result;
      console.log("result", result);
    } else {
      console.log("CoverName empty");
    }

    if (coverHorizon !== "") {
      CoverNameHorizon = Namecon(coverHorizon);
      const result = await uploadData({
        key: CoverNameHorizon,
        data: coverHorizon,
      }).result;
      console.log("result", result);
    } else {
      console.log("coverHorizon empty");
    }

    if (contentImage.length > 0) {
      await Promise.all(
        Array.from(contentImage).map((item, index) => {
          ContentImageName.push(Namecon(item));
          const resultImage = uploadData({
            key: ContentImageName[index],
            data: item,
          }).result;
          console.log("result", resultImage);
        })
      );
    } else {
      // console.error("contentImage is not an array or is empty");
      console.log("contentImage is not an array or is empty");
    }
    nearby.map((item) => {
      allnearby.push({
        product_nearbyname: item.nearbyname,
        product_nearbytype: item.nearbytype,
        product_nearbydistance: item.nearbydistance,
        product_nearbyunit: item.nearbyunit,
        product_nearbyfavorite: item.nearbyFav,
      });
    });

    API.post("/products", {
      product_draft: "draft",
      product_purpose: purpose,
      product_price:
        allInfo.type === "sale" ||
        allInfo.type === "rent" ||
        allInfo.type === "saleandrent"
          ? allInfo.price
          : 0,
      product_propcode: allInfo.propcode,
      product_google_place: allInfo.google_place,
      product_description: allInfo.description,
      product_contact: [],
      product_title: allInfo.title,
      product_address: allInfo.address,
      product_location: allInfo.fullAddress?.value,
      product_category: allInfo.category,
      product_type: allInfo.type,
      product_status: allInfo.status,
      product_area: allInfo.area,
      product_unitarea: allInfo.unitarea,
      product_urllocation: allInfo.urllocation,
      product_living_area: allInfo.livingArea,
      product_unit_living_area: allInfo.unitLivingArea,
      product_bedRoom: allInfo.bedroom,
      product_bathRoom: allInfo.bathRoom,
      product_meetingRoom: allInfo.meetingRoom,
      product_floor: allInfo.floor,
      product_nearby: allnearby,
      product_cover: CoverName,
      product_cover_horizon: CoverNameHorizon,
      product_images: ContentImageName,
      product_price_rent:
        allInfo.type === "saleandrent" ? allInfo.priceRent : 0,
      product_contact: {
        image: memberselect?.selectMember?.image,
        name: memberselect?.selectMember?.name,
        contactways: [
          {
            icon: "phoneIcon",
            name: "phone",
            info: memberselect?.selectMember
              ? memberselect?.selectMember?.contactways[0]?.info
              : "",
            color: {
              inside: "era_search/[0.37]",
              border: "era_search",
            },
            link: "",
          },
          {
            icon: "linelogo",
            name: "line",
            info: memberselect?.selectMember
              ? memberselect?.selectMember?.contactways[1]?.info
              : "",
            color: {
              inside: "lightGreen1/[0.37]",
              border: "linegreen",
            },
            link: "",
          },
          {
            icon: "mailIcon",
            name: "email",
            info: memberselect?.selectMember
              ? memberselect?.selectMember?.contactways[2]?.info
              : "",
            color: {
              inside: "lightBlue1/[0.37]",
              border: "lightBlue1",
            },
            link: "",
          },
        ],
      },
      product_Nickname: memberselect?.selectMember?.nickname,
      product_member_id: memberselect?.selectMember?._id,
      product_view: 0,
    })
      .then(function (response) {
        setFormLoading(false);

        console.log(response);
        Swal.fire({
          title: "เก็บข้อมูล Draft เรียบร้อย",
          icon: "success",
        }).then(() => {
          navigate("/");
        });
      })
      .catch(function (error) {
        setFormLoading(false);

        console.log(error);
        Swal.fire({
          title: "เก็บข้อมูล Draft ผิดพลาด",
          icon: "error",
        });
      })
      .finally(() => {
        setFormLoading(false);
      });
  };

  const addCover = async (e) => {
    // console.log(" e.target.files", e.target.files);
    let displayImg = "";
    if (e.target.files.length > 0) {
      setCover(e.target.files[0]);
      displayImg = await ImgFromFile(e.target.files[0]);
      setDisplayCover(displayImg);
    } else {
      setCover(cover);
      setDisplayCover(displayCover);
    }
  };

  const addCoverHorizon = async (e) => {
    // console.log(" e.target.files", e.target.files);
    let displayImg = "";
    if (e.target.files.length > 0) {
      setCoverHorizon(e.target.files[0]);
      displayImg = await ImgFromFile(e.target.files[0]);
      setDisplayCoverHorizon(displayImg);
    } else {
      setCoverHorizon(coverHorizon);
      setDisplayCoverHorizon(displayCoverHorizon);
    }
  };

  const addImage = async (e) => {
    let allpic = e.target.files;
    if (e.target.files.length > 0) {
      setImage(e.target.files);
      let tempImgs = [];
      for (let i = 0; i < allpic.length; i++) {
        let displayImg = await ImgFromFile(allpic[i]);
        tempImgs.push(displayImg);
      }
      setDisplayImage(tempImgs);
    } else {
      setImage(contentImage);
    }
  };

  // console.log("formError", formError);

  return (
    <div className="pb-[80px] pt-[28px]">
      <div className="flex flex-col w-[1100px] mt-[37px] ml-[40px] spcae-y-[20px]">
        <div className="flex mb-[19px]">
          <div className="text-[28px] text-era-red font-semibold mr-[32px]">
            Product
          </div>
          <div className="self-center">
            <label className="flex self-center space-x-[8px] mr-[20px]">
              <input
                type="checkbox"
                onChange={(e) => {
                  let tempPurpose = [...purpose];

                  if (tempPurpose.length > 0) {
                    tempPurpose = tempPurpose.filter(
                      (item) => item !== "forliving"
                    );
                  }

                  if (e.target.checked) {
                    tempPurpose.push("forliving");
                  }

                  setPurpose(tempPurpose);
                }}
              ></input>
              <div className="text-base font-medium">
                ทรัพย์แนะนำเพื่ออยู่อาศัย
              </div>
            </label>
          </div>
          <div className="self-center">
            <label className="flex self-center space-x-[8px]">
              <input
                onChange={(e) => {
                  let tempPurpose = [...purpose];

                  if (tempPurpose.length > 0) {
                    tempPurpose = tempPurpose.filter(
                      (item) => item !== "forinvestment"
                    );
                  }

                  if (e.target.checked) {
                    tempPurpose.push("forinvestment");
                  }

                  setPurpose(tempPurpose);
                }}
                type="checkbox"
              ></input>
              <div className="text-base font-medium">
                ทรัพย์แนะนำเพื่อการลงทุน
              </div>
            </label>
          </div>
        </div>
        <div className="flex w-full h-[80px] space-x-[30px] mb-[22px]">
          <InputText
            name="รหัสทรัพย์"
            placeholder="#01234"
            allInfo={Object.entries(allInfo)[17]}
            setAllInfo={setAllInfo}
            error={formError?.propcode}
            allstyle="w-full"
          />
          <InputText
            name="Google place ID"
            placeholder="ระบุ"
            allInfo={Object.entries(allInfo)[16]}
            setAllInfo={setAllInfo}
            error={formError?.google_place}
            allstyle="w-full"
          />
        </div>
        <div className="flex w-full h-[80px] space-x-[30px] mb-[22px]">
          <InputText
            allInfo={Object.entries(allInfo)[0]}
            setAllInfo={setAllInfo}
            typeOrnot={true}
            error={formError?.title}
            maxLength={80}
            displayLength={true}
          />
          <div className="w-full h-[51px] flex flex-col text-base font-medium ">
            <div className="flex text-base font-medium">
              <div className="">แขวง/ เขต/ จังหวัด/ รหัสไปรษณีย์</div>
              <div className="text-[#C41F30]">*</div>
            </div>
            <Select
              className="flex flex-col w-full h-[51px]"
              classNameBox={classNames(
                "flex justify-between h-[51px] px-[24px] py-[13px]  outline-none rounded-[8.4px]",
                formError?.fullAddress
                  ? "border-[2px] border-red-500"
                  : "border-[1px] border-[#8E8E8E]"
              )}
              value={allInfo?.fullAddress}
              placeholder="กรุณาเลือกสถานที่"
              options={ADDRESS_OPTIONS}
              renderChevron={<ChevronDown />}
              onChangeSelect={(value) =>
                setAllInfo((prev) => ({
                  ...prev,
                  fullAddress: value,
                }))
              }
            />
          </div>
        </div>
        <div className="flex w-full h-[80px] mb-[20px] space-x-[30px]">
          <InputText
            name="ที่อยู่"
            allInfo={Object.entries(allInfo)[2]}
            setAllInfo={setAllInfo}
            error={formError?.address}
          />
        </div>
        <div className="flex w-full h-[80px] space-x-[30px] mb-[20px]">
          <DropDownInfo
            name="หมวดหมู่"
            placeholder="กรุณาเลือกหมวดหมู่"
            openDropDown={Object.entries(openDropDown)[1]}
            setOpenDropDown={setOpenDropDown}
            allInfo={Object.entries(allInfo)[3]}
            setAllInfo={setAllInfo}
            error={formError?.category}
            choice={[
              {
                label: "ที่ดิน",
                value: "land",
              },
              {
                label: "บ้านเดี่ยว",
                value: "singleHouse",
              },
              {
                label: "ทาวน์โฮม, ทาวน์เฮ้าส์",
                value: "townHouse",
              },
              {
                label: "คอนโดมิเนียม",
                value: "condo",
              },
              {
                label: "อพาร์ทเมนท์",
                value: "apartment",
              },
              {
                label: "อาคารพาณิชย์",
                value: "businessBuilding",
              },
              {
                label: "โรงแรม, รีสอร์ท",
                value: "hotel",
              },
              {
                label: "พื้นที่สำนักงาน",
                value: "office",
              },
              {
                label: "โรงงาน, คลังสินค้า",
                value: "factory",
              },
              {
                label: "อื่นๆ",
                value: "etc",
              },
            ]}
          />
          <DropDownInfo
            name="สถานะทรัพย์"
            placeholder="กรุณาเลือกสถานะ"
            openDropDown={Object.entries(openDropDown)[2]}
            setOpenDropDown={setOpenDropDown}
            allInfo={Object.entries(allInfo)[4]}
            setAllInfo={setAllInfo}
            error={formError?.status}
            choice={[
              {
                label: "Public",
                value: "avilable",
              },
              {
                label: "Sold",
                value: "sold",
              },
            ]}
          />
        </div>
        <div className="flex w-full h-[80px] space-x-[30px] mb-[20px]">
          {allInfo.type === "saleandrent" && (
            <div className="flex space-x-[30px] w-full">
              <div className="flex w-full h-full space-x-[5px]">
                <InputText
                  name="ราคาขาย :บาท"
                  placeholder="กรอกราคา"
                  type="number"
                  allInfo={Object.entries(allInfo)[5]}
                  setAllInfo={setAllInfo}
                  error={formError?.price}
                />
              </div>
              <div className="flex w-full h-full space-x-[5px]">
                <InputText
                  name="ราคาเช่า :บาท"
                  placeholder="กรอกราคา"
                  type="number"
                  allInfo={Object.entries(allInfo)[19]}
                  setAllInfo={setAllInfo}
                  error={formError?.priceRent}
                />
              </div>
            </div>
          )}
          {allInfo.type === "" && (
            <InputText
              name="ราคาขาย :บาท"
              placeholder="กรอกราคา"
              type="number"
              allInfo={Object.entries(allInfo)[5]}
              setAllInfo={setAllInfo}
              error={formError?.price}
            />
          )}
          {allInfo.type === "sale" && (
            <InputText
              name="ราคาขาย :บาท"
              placeholder="กรอกราคา"
              type="number"
              allInfo={Object.entries(allInfo)[5]}
              setAllInfo={setAllInfo}
              error={formError?.price}
            />
          )}
          {allInfo.type === "rent" && (
            <InputText
              name="ราคาเช่า :บาท"
              placeholder="กรอกราคา"
              type="number"
              allInfo={Object.entries(allInfo)[5]}
              setAllInfo={setAllInfo}
              error={formError?.price}
            />
          )}
          <DropDownInfo
            name="ประเภททรัพย์"
            placeholder="กรุณาเลือกประเภททรัพย์"
            openDropDown={Object.entries(openDropDown)[3]}
            setOpenDropDown={setOpenDropDown}
            allInfo={Object.entries(allInfo)[6]}
            setAllInfo={setAllInfo}
            error={formError?.type}
            choice={[
              {
                label: "ขาย",
                value: "sale",
              },
              {
                label: "เช่า",
                value: "rent",
              },
              {
                label: "ขายและเช่า",
                value: "saleandrent",
              },
            ]}
          />
        </div>
        <div className="flex w-full h-[80px] space-x-[32px] mb-[20px]">
          {allInfo?.unitarea === "rai" ? (
            <>
              <InputText
                name="พื้นที่ใช้สอย :ไร่"
                placeholder="พื้นที่ใช้สอย:ไร่"
                type="number"
                allstyle="flex w-[157px] flex-col"
                allInfo={Object.entries(allInfo)[7]}
                setAllInfo={setAllInfo}
                ArrayAddress={0}
                error={formError?.area_1}
              />
              <InputText
                name="งาน"
                placeholder="พื้นที่ใช้สอย:งาน"
                type="number"
                allstyle="flex w-[157px] flex-col"
                allInfo={Object.entries(allInfo)[7]}
                setAllInfo={setAllInfo}
                ArrayAddress={1}
                error={formError?.area_2}
              />
              <InputText
                name="ตร.ว."
                placeholder="พื้นที่ใช้สอย:ตร.ว."
                type="number"
                allstyle="flex w-[157px] flex-col"
                allInfo={Object.entries(allInfo)[7]}
                setAllInfo={setAllInfo}
                ArrayAddress={2}
                error={formError?.area_3}
              />
            </>
          ) : (
            <InputText
              name="พื้นที่ใช้สอย"
              placeholder="กรอกขนาดพื้นที่ใช้สอย"
              type="number"
              allstyle="flex w-full flex-col"
              allInfo={Object.entries(allInfo)[7]}
              setAllInfo={setAllInfo}
              ArrayAddress={2}
              error={formError?.area_3}
            />
          )}
          <DropDownInfo
            name="หน่วยพื้นที่ใช้สอย"
            placeholder="กรุณาเลือกหน่วย"
            allstyle="flex flex-col min-w-[534px] h-[51px]"
            openDropDown={Object.entries(openDropDown)[4]}
            setOpenDropDown={setOpenDropDown}
            allInfo={Object.entries(allInfo)[8]}
            setAllInfo={setAllInfo}
            error={formError?.unitarea}
            choice={[
              {
                label: "ตารางเมตร",
                value: "squaremeter",
              },
              {
                label: "ตารางวา",
                value: "wa",
              },
              {
                label: "ไร่",
                value: "rai",
              },
            ]}
          />
        </div>
        {allInfo.category !== "condo" &&
          allInfo.category !== "land" &&
          allInfo.category !== "" && (
            <div className="flex w-full h-[80px] space-x-[32px] mb-[20px]">
              {allInfo?.unitLivingArea === "rai" ? (
                <>
                  <InputText
                    name="เนื้อที่ใช้สอย :ไร่"
                    placeholder="เนื้อที่ใช้สอย:ไร่"
                    type="number"
                    allstyle="flex w-[157px] flex-col"
                    allInfo={Object.entries(allInfo)[21]}
                    setAllInfo={setAllInfo}
                    ArrayAddress={0}
                    error={formError?.area_living_1}
                  />
                  <InputText
                    name="งาน"
                    placeholder="เนื้อที่ใช้สอย:งาน"
                    type="number"
                    allstyle="flex w-[157px] flex-col"
                    allInfo={Object.entries(allInfo)[21]}
                    setAllInfo={setAllInfo}
                    ArrayAddress={1}
                    error={formError?.area_living_2}
                  />
                  <InputText
                    name="ตร.ว."
                    placeholder="เนื้อที่ใช้สอย:ตร.ว."
                    type="number"
                    allstyle="flex w-[157px] flex-col"
                    allInfo={Object.entries(allInfo)[21]}
                    setAllInfo={setAllInfo}
                    ArrayAddress={2}
                    error={formError?.area_living_3}
                  />
                </>
              ) : (
                <InputText
                  name="เนื้อที่ใช้สอย"
                  placeholder="กรอกขนาดเนื้อที่ใช้สอย"
                  type="number"
                  allstyle="flex w-full flex-col"
                  allInfo={Object.entries(allInfo)[21]}
                  setAllInfo={setAllInfo}
                  ArrayAddress={2}
                  error={formError?.area_living_3}
                />
              )}
              <DropDownInfo
                name="หน่วยเนื้อที่ใช้สอย"
                placeholder="กรุณาเลือกหน่วย"
                allstyle="flex flex-col min-w-[534px] h-[51px]"
                openDropDown={Object.entries(openDropDown)[5]}
                setOpenDropDown={setOpenDropDown}
                allInfo={Object.entries(allInfo)[22]}
                setAllInfo={setAllInfo}
                error={formError?.unitLivingArea}
                choice={[
                  {
                    label: "ตารางเมตร",
                    value: "squaremeter",
                  },
                  {
                    label: "ตารางวา",
                    value: "wa",
                  },
                  {
                    label: "ไร่",
                    value: "rai",
                  },
                ]}
              />
            </div>
          )}
        {haveMeetingRoom.includes(allInfo.category) ? (
          <div className="flex w-full h-[80px] space-x-[32px] mb-[20px]">
            {allInfo.category !== "land" && (
              <InputText
                name="จำนวนห้องนอน"
                type="number"
                placeholder="กรอกจำนวนห้องนอน"
                allInfo={Object.entries(allInfo)[9]}
                setAllInfo={setAllInfo}
                error={formError?.bedroom}
                reddot={allInfo.category === "land" ? false : true}
              />
            )}
            {allInfo.category !== "land" && (
              <InputText
                name="จำนวนห้องน้ำ"
                type="number"
                placeholder="กรอกจำนวนห้องน้ำ"
                allInfo={Object.entries(allInfo)[10]}
                setAllInfo={setAllInfo}
                error={formError?.bathRoom}
                reddot={allInfo.category === "land" ? false : true}
              />
            )}
            {allInfo.category !== "land" && (
              <InputText
                name="จำนวนห้องประชุม"
                type="number"
                placeholder="กรอกจำนวนห้องประชุม"
                allInfo={Object.entries(allInfo)[11]}
                setAllInfo={setAllInfo}
                error={formError?.meetingRoom}
                reddot={allInfo.category === "land" ? false : true}
              />
            )}
          </div>
        ) : (
          allInfo.category !== "land" && (
            <div className="flex w-full h-[80px] space-x-[32px] mb-[20px]">
              <InputText
                name="จำนวนห้องนอน"
                type="number"
                placeholder="กรอกจำนวนห้องนอน"
                allInfo={Object.entries(allInfo)[9]}
                setAllInfo={setAllInfo}
                error={formError?.bedroom}
                reddot={allInfo.category === "land" ? false : true}
              />
              <InputText
                name="จำนวนห้องน้ำ"
                type="number"
                placeholder="กรอกจำนวนห้องน้ำ"
                allInfo={Object.entries(allInfo)[10]}
                setAllInfo={setAllInfo}
                error={formError?.bathRoom}
                reddot={allInfo.category === "land" ? false : true}
              />
            </div>
          )
        )}
        {allInfo.category !== "land" && (
          <div className="flex w-[48.5%] h-[80px] space-x-[32px] mb-[20px]">
            <InputText
              name={allInfo.category === "condo" ? "ชั้นที่" : "จำนวนชั้น"}
              type="number"
              placeholder={
                allInfo.category === "condo"
                  ? "กรอกที่ตั้งชั้นของทรัพย์"
                  : "กรอกจำนวนชั้น"
              }
              allInfo={Object.entries(allInfo)[18]}
              setAllInfo={setAllInfo}
              error={formError?.floor}
              reddot={allInfo.category === "land" ? false : true}
            />
          </div>
        )}

        <div className="flex flex-col mb-[30px]">
          <div className="flex items-center mb-[17px]">
            <div className="flex text-base font-medium">สถานที่ใกล้เคียง</div>
            <div className="flex text-base font-medium">
              {"(แสดงหน้าแรก 2 รายการ โดยการเลือก"}
            </div>
            <Heart className="w-[20px] h-[20px] stroke-[#E0DFDF] fill-white" />
            <div className="flex text-base font-medium">
              {")"}
              <div className="text-era-red">
                {formError?.nearbyFavError
                  ? "โปรดเลือกสถานที่โปรด 2 รายการ*"
                  : "*"}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-y-[17px]">
            <HeartCheckbox
              type="market"
              nearby={nearby}
              setNearby={setNearby}
              error={formError?.nearby_market}
            />
            <HeartCheckbox
              name="สถานศึกษา"
              type="study"
              nearby={nearby}
              setNearby={setNearby}
              error={formError?.nearby_study}
            />
            <HeartCheckbox
              name="สถานีขนส่ง"
              type="station"
              nearby={nearby}
              setNearby={setNearby}
              error={formError?.nearby_station}
            />
            <HeartCheckbox
              name="ห้างสรรพสินค้า"
              type="mall"
              nearby={nearby}
              setNearby={setNearby}
              error={formError?.nearby_mall}
            />
            <HeartCheckbox
              name="สถานที่ท่องเที่ยว"
              type="touristPlace"
              nearby={nearby}
              setNearby={setNearby}
              error={formError?.nearby_touristPlace}
            />
            <HeartCheckbox
              name="สถานีรถไฟ"
              type="trainStation"
              nearby={nearby}
              setNearby={setNearby}
              error={formError?.nearby_trainStation}
            />
            <HeartCheckbox
              name="นิคมอุตสาหกรรม"
              type="industryZone"
              nearby={nearby}
              setNearby={setNearby}
              error={formError?.nearby_industryZone}
            />
            <HeartCheckbox
              name="ถนน"
              type="road"
              nearby={nearby}
              setNearby={setNearby}
              error={formError?.nearby_road}
            />
            <HeartCheckbox
              name="ท่าเรือ"
              type="pier"
              nearby={nearby}
              setNearby={setNearby}
              error={formError?.nearby_pier}
            />
            <HeartCheckbox
              name="สนามบิน"
              type="airport"
              nearby={nearby}
              setNearby={setNearby}
              error={formError?.nearby_airport}
            />
            <HeartCheckbox
              name="โรงพยาบาล"
              type="hospital"
              nearby={nearby}
              setNearby={setNearby}
              error={formError?.nearby_hospital}
            />
            <HeartCheckbox
              name="ร้านอาหาร"
              type="restaurant"
              nearby={nearby}
              setNearby={setNearby}
              error={formError?.nearby_restaurant}
            />
            <HeartCheckbox
              name="บริษัท"
              type="company"
              nearby={nearby}
              setNearby={setNearby}
              error={formError?.nearby_company}
            />
            <HeartCheckbox
              name="สถานที่ออกกำลังกาย"
              type="exercisePlace"
              nearby={nearby}
              setNearby={setNearby}
              error={formError?.nearby_exercisePlace}
            />
            <HeartCheckbox
              name="สวนสาธารณะ"
              type="park"
              nearby={nearby}
              setNearby={setNearby}
              error={formError?.nearby_park}
            />
          </div>
        </div>
        <div className="flex flex-col space-y-[30px] mb-[30px]">
          <div className="flex space-x-[10px]">
            <UploadImage
              cover={cover}
              addCover={addCover}
              name="รูปภาพสำหรับโปรโมตบนการ์ด แนวตั้ง(270x270px)"
              displayStyle="w-[270px] h-[270px] rounded-[10px]"
              framstyle="w-[272px] h-[272px] flex justify-center items-center border-[1px] border-solid border-[#000]/[0.29] rounded-[10px]"
              displayImg={displayCover}
              multiple={false}
              error={formError?.coverPic}
            />
            {cover !== "" && (
              <div className="flex h-[30px] mt-[30px] space-x-[7px]">
                <button
                  onClick={() => {
                    setCover("");
                  }}
                >
                  <X className="w-[24px] h-[24px]" />
                </button>
                <div className="flex items-center text-base text-[#F0BF50] font-normal">
                  {cover?.name}
                </div>
              </div>
            )}
          </div>

          <div className="flex space-x-[10px]">
            <UploadImage
              cover={coverHorizon}
              addCover={addCoverHorizon}
              name="รูปภาพสำหรับโปรโมตบนการ์ด แนวนอน(600x300px)"
              displayStyle="w-[600px] h-[300px] rounded-[10px]"
              framstyle="w-[602px] h-[302px] flex justify-center items-center border-[1px] border-solid border-[#000]/[0.29] rounded-[10px]"
              displayImg={displayCoverHorizon}
              multiple={false}
              error={formError?.coverPicHorizon}
            />
            {coverHorizon !== "" && (
              <div className="flex h-[30px] mt-[30px] space-x-[7px]">
                <button
                  onClick={() => {
                    setCoverHorizon("");
                  }}
                >
                  <X className="w-[24px] h-[24px]" />
                </button>
                <div className="flex items-center text-base text-[#F0BF50] font-normal">
                  {coverHorizon?.name}
                </div>
              </div>
            )}
          </div>
          <div className="flex space-x-[10px]">
            <UploadImage
              cover={displayImage[0]}
              addCover={addImage}
              name="รูปภาพเพิ่มเติม(600x300 px)"
              displayStyle="w-[600px] h-[300px] rounded-[10px]"
              framstyle="w-[602px] h-[302px] flex justify-center items-center border-[1px] border-solid border-[#000]/[0.29] rounded-[10px]"
              displayImg={displayImage}
              error={formError?.contentPic}
            />
            <div className="flex flex-col mt-[30px]">
              {contentImage?.length > 0 &&
                Array.from(contentImage).map((item, index) => (
                  <div className="flex mt-[10px] space-x-[7px]">
                    <button
                      onClick={() => {
                        setImage((prev) => {
                          let newImage = [...prev];
                          newImage.splice(index, 1);
                          return newImage;
                          // displayImage, setDisplayImage
                        });
                        setDisplayImage((prev) => {
                          let newImage = [...prev];
                          newImage.splice(index, 1);
                          return newImage;
                        });
                      }}
                    >
                      <X className="w-[24px] h-[24px]" />
                    </button>
                    <button
                      onClick={async () => {
                        let displayImg = await ImgFromFile(contentImage[index]);
                        setDisplayImage([displayImg]);
                        //  let allpic = e.target.files;
                        //  if (e.target.files.length > 0) {
                        //    setImage(e.target.files);
                        //    let tempImgs = [];
                        //    for (let i = 0; i < allpic.length; i++) {
                        //      let displayImg = await ImgFromFile(allpic[i]);
                        //      tempImgs.push(displayImg);
                        //    }
                        //    setDisplayImage(tempImgs);
                        //  } else {
                        //    setImage(contentImage);
                        //  }
                      }}
                      className="text-base text-[#F0BF50] font-normal"
                    >
                      {item?.name}
                    </button>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col mb-[30px]">
          <div className="text-base font-medium">คำอธิบายรายละเอียด</div>
          <FieldWysiwyg
            value={allInfo.description}
            onChange={(value) => {
              setAllInfo((prev) => ({ ...prev, description: value }));
            }}
          />
        </div>
        <div className="flex flex-col mb-[30px]">
          <InputText
            name="แผนที่"
            placeholder="กรอก Link ของเส้นทาง"
            type="url"
            allInfo={Object.entries(allInfo)[13]}
            setAllInfo={setAllInfo}
            error={formError?.urllocation}
          />
        </div>
        <div className="w-full flex flex-col mb-[30px] ">
          <div className="w-[1100px] flex flex-col mb-[30px] justify-start">
            <div className="w-[167px] pb-[7px] mb-[30px] border-b-solid border-b-[1px] border-b-era-red">
              ข้อมูลการติดต่อผู้ประกาศ
            </div>
            <DropDownInfo
              error={formError?.member}
              name="Member"
              placeholder="เลือกผู้ประกาศ"
              openDropDown={Object.entries(openmember)[0]}
              setOpenDropDown={setMember}
              allInfo={Object.entries(memberselect)[0]}
              setAllInfo={setmemberselect}
              choice={memberData?.members?.map((item) => {
                return {
                  label: item?.name + " (" + item?.nickname + ")",
                  value: item,
                };
              })}
            />
          </div>
        </div>
        <div className="flex justify-center space-x-5">
          <button
            onClick={onclickDraft}
            className={
              formLoading
                ? "cursor-not-allowed pointer-events-none w-[189px] h-[50px] bg-[#8E8E8E] text-xl text-white font-semibold rounded-[40px]"
                : "cursor-pointer   w-[189px] h-[50px] bg-[#8E8E8E] text-xl text-white font-semibold rounded-[40px]"
            }
          >
            Draft
          </button>
          <button
            onClick={onClickSubmit}
            className={
              formLoading
                ? "cursor-not-allowed pointer-events-none w-[189px] h-[50px] bg-[#8E8E8E] text-xl text-white font-semibold rounded-[40px] duration-300 hover:bg-[#E53648]"
                : "cursor-pointer w-[189px] h-[50px] bg-era-red text-xl text-white font-semibold rounded-[40px] duration-300 hover:bg-[#E53648]"
            }
          >
            บันทึก
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductCreate;
