import React, { useEffect, useState } from "react";
import InputText from "../../component/textinput";
import UploadImage from "../../component/uploadImage";
import { API } from "../../api/api.main";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ImgFromFile,IMG_URL } from "../../utils/util.con";
import Namecon from "../../utils/Name.con";
import { uploadData } from "aws-amplify/storage";
import { X } from "lucide-react";
import Swal from "sweetalert2";

const StarIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.6666 11.1862C22.9143 11.0016 23.0981 10.7441 23.1922 10.4498C23.2862 10.1555 23.2858 9.83913 23.191 9.54507C23.0961 9.25101 22.9116 8.99401 22.6634 8.81009C22.4151 8.62618 22.1155 8.52457 21.8066 8.51953L15.8066 8.29286C15.7771 8.29082 15.7488 8.2803 15.7251 8.26256C15.7015 8.24482 15.6834 8.22061 15.6732 8.19286L13.5999 2.59286C13.4957 2.30795 13.3065 2.06194 13.0579 1.88813C12.8093 1.71431 12.5132 1.62109 12.2099 1.62109C11.9065 1.62109 11.6105 1.71431 11.3619 1.88813C11.1133 2.06194 10.9241 2.30795 10.8199 2.59286L8.75323 8.21286C8.74301 8.24061 8.72499 8.26482 8.70133 8.28256C8.67768 8.3003 8.64939 8.31083 8.61989 8.31286L2.61989 8.53953C2.31096 8.54457 2.01136 8.64618 1.76309 8.83009C1.51482 9.01401 1.33033 9.27101 1.2355 9.56507C1.14067 9.85913 1.14024 10.1755 1.23428 10.4698C1.32833 10.7641 1.51212 11.0216 1.75989 11.2062L6.46656 14.9062C6.49012 14.9247 6.50772 14.9498 6.5172 14.9782C6.52668 15.0066 6.52762 15.0372 6.51989 15.0662L4.89989 20.8062C4.81595 21.0985 4.82408 21.4095 4.92319 21.6971C5.02229 21.9846 5.20756 22.2346 5.45379 22.413C5.70002 22.5915 5.99526 22.6898 6.29933 22.6946C6.6034 22.6993 6.90156 22.6103 7.15323 22.4395L12.1266 19.1062C12.1511 19.0893 12.1801 19.0802 12.2099 19.0802C12.2397 19.0802 12.2687 19.0893 12.2932 19.1062L17.2666 22.4395C17.5148 22.6161 17.8119 22.7109 18.1166 22.7109C18.4212 22.7109 18.7183 22.6161 18.9666 22.4395C19.2129 22.2627 19.3983 22.014 19.4974 21.7274C19.5965 21.4409 19.6043 21.1307 19.5199 20.8395L17.8866 15.0795C17.8779 15.0506 17.8784 15.0197 17.8879 14.9911C17.8975 14.9625 17.9156 14.9375 17.9399 14.9195L22.6666 11.1862Z"
        fill="#F0BF50"
      />
    </svg>
  );
};

const StarDarkIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.6666 11.1862C22.9143 11.0016 23.0981 10.7441 23.1922 10.4498C23.2862 10.1555 23.2858 9.83913 23.191 9.54507C23.0961 9.25101 22.9116 8.99401 22.6634 8.81009C22.4151 8.62618 22.1155 8.52457 21.8066 8.51953L15.8066 8.29286C15.7771 8.29082 15.7488 8.2803 15.7251 8.26256C15.7015 8.24482 15.6834 8.22061 15.6732 8.19286L13.5999 2.59286C13.4957 2.30795 13.3065 2.06194 13.0579 1.88813C12.8093 1.71431 12.5132 1.62109 12.2099 1.62109C11.9065 1.62109 11.6105 1.71431 11.3619 1.88813C11.1133 2.06194 10.9241 2.30795 10.8199 2.59286L8.75323 8.21286C8.74301 8.24061 8.72499 8.26482 8.70133 8.28256C8.67768 8.3003 8.64939 8.31083 8.61989 8.31286L2.61989 8.53953C2.31096 8.54457 2.01136 8.64618 1.76309 8.83009C1.51482 9.01401 1.33033 9.27101 1.2355 9.56507C1.14067 9.85913 1.14024 10.1755 1.23428 10.4698C1.32833 10.7641 1.51212 11.0216 1.75989 11.2062L6.46656 14.9062C6.49012 14.9247 6.50772 14.9498 6.5172 14.9782C6.52668 15.0066 6.52762 15.0372 6.51989 15.0662L4.89989 20.8062C4.81595 21.0985 4.82408 21.4095 4.92319 21.6971C5.02229 21.9846 5.20756 22.2346 5.45379 22.413C5.70002 22.5915 5.99526 22.6898 6.29933 22.6946C6.6034 22.6993 6.90156 22.6103 7.15323 22.4395L12.1266 19.1062C12.1511 19.0893 12.1801 19.0802 12.2099 19.0802C12.2397 19.0802 12.2687 19.0893 12.2932 19.1062L17.2666 22.4395C17.5148 22.6161 17.8119 22.7109 18.1166 22.7109C18.4212 22.7109 18.7183 22.6161 18.9666 22.4395C19.2129 22.2627 19.3983 22.014 19.4974 21.7274C19.5965 21.4409 19.6043 21.1307 19.5199 20.8395L17.8866 15.0795C17.8779 15.0506 17.8784 15.0197 17.8879 14.9911C17.8975 14.9625 17.9156 14.9375 17.9399 14.9195L22.6666 11.1862Z"
        fill="#CECECE"
      />
    </svg>
  );
};

const Contentaddcomment = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [alldata, setAllData] = useState({
    comment_pic: "",
    comment_name: "",
    comment_boby: "",
    comment_star: "",
  });
  const [cover, setCover] = useState("");
  const [displayCover, setDisplayCover] = useState("");
  const location = useLocation();
  const param = useParams();
  const navigate = useNavigate();


  
  const addCover = async (e) => {
    console.log("e.target.files", e.target.files);
    let displayImg = "";
    if(e.target.files.length>0){
      setCover(e.target.files[0]);
      displayImg = await ImgFromFile(e.target.files[0]);
      setDisplayCover(displayImg);
    }else{
      setCover(cover);
      setDisplayCover(displayCover);
    }
  };

  useEffect(() => {
    console.log("param", param.id);
    loaddata();
  }, []);

  const loaddata = async () => {
    try {
      setLoading(true);
      let { data } = await API.get("/comments/" + param.id);
      setAllData({
        comment_pic: data?.comments?.comment_pic,
        comment_name: data?.comments?.comment_name,
        comment_boby: data?.comments?.comment_boby,
        comment_star: data?.comments?.comment_star,
      });
      setCover(data?.comments?.comment_pic);
      setDisplayCover(IMG_URL(data?.comments?.comment_pic));
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const ClickSubmit = async () =>{
    if (alldata.comment_pic !== "" &&
    alldata.comment_name !== "" &&
    alldata.comment_boby !== "" &&
    alldata.comment_star !== "" &&
    cover !== ""&&
    displayCover !== ""
    ) {
      let CoverName =""
      if (cover !== "" && cover?.type !== undefined) {
      let CoverName = Namecon(cover);
      const result = await uploadData({
        key: CoverName,
        data: cover,
      }).result;
    }else {
      CoverName = cover;
    }
      // console.log("result", result);

      await API.put("/comments/" + param.id,{
        comment_pic:CoverName,
        comment_name:alldata.comment_name,
        comment_boby:alldata.comment_boby,
        comment_star:alldata.comment_star,
      }).then((res)=>{
        console.log("res",res)
        Swal.fire({
          title: "บันทึกข้อมูลเรียบร้อย",
          icon: "success",
        }).then(() => {
          navigate("/");
        });
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          title: "บันทึกข้อมูลผิดพลาด",
          icon: "error",
        });
      }).catch((error)=>{
        console.log("error",error)
      })
      loaddata()
    }else{
      console.log("not complete fill")
      Swal.fire({
        title: "กรุณากรองให้ครบ",
        text: "ท่านยังกรอกไม่ครบ",
        icon: "warning",
      });
    }
  }
  console.log("cover",cover.type)
  return (
    <div className=" pl-[40px] pr-[90px] pt-[28px] pb-[25px] flex flex-col just self-center">
      <div className="text-[28px] text-era-red font-semibold mb-[21px]">
        ความคิดเห็นที่ {location.search.split("?")[1].split("=")[1]}
      </div>
      <div className="flex space-x-[30px] mb-[16px]">
        <InputText
          name="ชื่อ-สกุล"
          placeholder=""
          allInfo={Object.entries(alldata)[1]}
          setAllInfo={setAllData}
        />
        <div className="w-[263px] flex space-x-[10px] text-era-red text-base font-light items-start ">
          <div className="flex flex-col">
            <div className="flex text-black text-base font-medium mb-[10px]">
              <div className="">ระดับความคิดเห็น</div>
              <div className="text-[#C41F30]">*</div>
            </div>
            <div className="flex space-x-[10px]">
              {alldata.comment_star > 0 ? (<button onClick={()=>{setAllData((prev)=>({...prev,comment_star:1}))}}><StarIcon /></button>):(<button onClick={()=>{setAllData((prev)=>({...prev,comment_star:1}))}}><StarDarkIcon/></button>)}
              {alldata.comment_star > 1 ? (<button onClick={()=>{setAllData((prev)=>({...prev,comment_star:2}))}}><StarIcon /></button>):(<button onClick={()=>{setAllData((prev)=>({...prev,comment_star:2}))}}><StarDarkIcon/></button>)}
              {alldata.comment_star > 2 ? (<button onClick={()=>{setAllData((prev)=>({...prev,comment_star:3}))}}><StarIcon /></button>):(<button onClick={()=>{setAllData((prev)=>({...prev,comment_star:3}))}}><StarDarkIcon/></button>)}
              {alldata.comment_star > 3 ? (<button onClick={()=>{setAllData((prev)=>({...prev,comment_star:4}))}}><StarIcon /></button>):(<button onClick={()=>{setAllData((prev)=>({...prev,comment_star:4}))}}><StarDarkIcon/></button>)}
              {alldata.comment_star > 4 ? (<button onClick={()=>{setAllData((prev)=>({...prev,comment_star:5}))}}><StarIcon /></button>):(<button onClick={()=>{setAllData((prev)=>({...prev,comment_star:5}))}}><StarDarkIcon/></button>)}
            </div>
          </div>
        </div>
      </div>
      <InputText
        name="ความคิดเห็น"
        placeholder=""
        allInfo={Object.entries(alldata)[2]}
        setAllInfo={setAllData}
        inputstyle="w-full h-[161px] border-solid border-[1px] line-clamp-5 self-start border-[#8E8E8E] outline-none px-[24px] py-[15px] rounded-[8.4px]"
      />
      <div className="pt-[20px]">
        <UploadImage
          name="Image (60x60 px)"
          cover={cover}
          addCover={addCover}
          displayImg={displayCover}
          multiple={false}
          displayStyle="w-[60px] h-[60px] rounded-[10px]"
          framstyle="w-[200px] h-[150px] flex justify-center items-center border-[1px] border-solid border-[#000]/[0.29] rounded-[10px]"
        />
        {cover !== "" && (
              <div className="flex mt-[10px] space-x-[7px]">
                <button onClick={()=>{
                  setCover('')
                }}>
                  <X className="w-[24px] h-[24px]" />
                </button>
                <div className="text-base text-[#F0BF50] font-normal">
                {cover?.name ? cover?.name : cover}
                </div>
              </div>
            )}
      </div>
      <div className="w-full flex justify-center">
        <button
          onClick={ClickSubmit}
          className="w-[189px] h-[50px] mt-[20px] flex justify-center items-center text-xl text-white font-semibold rounded-[40px] bg-era-red duration-300 hover:bg-[#E53648]"
        >
          บันทึก
        </button>
      </div>
    </div>
  );
};

export default Contentaddcomment;
