import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import React, { useEffect, useRef, useState } from "react";


const InputText = ({
  name = "ชื่อทรัพย์",
  placeholder = "กรอกชื่อทรัพย์",
  type = "text",
  allstyle = "flex w-full flex-col",
  inputstyle = "w-full h-[51px] border-solid border-[1px] border-[#8E8E8E] outline-none px-[14px] py-[24px] rounded-[8.4px]",
  allInfo,
  setAllInfo,
  ArrayAddress = 0,
  reddot = true,
  error = false,
  maxLength=9999999999,
  displayLength = false
}) => {
  // Extract key and value from allInfo
  const [key, value] = allInfo;

  // Handle both array and non-array cases
  const handleChange = (e) => {
    const newValue =
      type === "number" ? parseFloat(e.target.value) : e.target.value;
    
    setAllInfo((prev) => {
      // If the current value is an array, create a new array with the updated value
      if (Array.isArray(prev[key])) {
        const newArray = [...prev[key]];
        newArray[ArrayAddress] = newValue;
        return { ...prev, [key]: newArray };
      }
      // If it's not an array, update the value directly
      return { ...prev, [key]: newValue };
    });
  };

  return (
    <div className={allstyle}>
      <div className="flex text-base font-medium">
        <div className="">{name}</div>
        {reddot && <div className="text-[#C41F30]">*</div>}
      </div>
      <input
        type={type}
        placeholder={placeholder}
        className={twMerge(
          classNames(inputstyle, error && "border-red-500 border-[2px]")
        )}
        value={Array.isArray(value) ? value[ArrayAddress] : value}
        onKeyDown={(evt) =>{if(type === "number"){ ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}}}
        onChange={handleChange}
        maxLength={maxLength}
      />
      {displayLength && <div className="w-full text-end mt-[5px] text-[#8E8E8E] text-base font-medium">{value?.length}/{maxLength}</div>}
    </div>
  );
};

export default InputText;
