import React, { useEffect } from "react";
import Reqlist from "../../component/list/reqlist";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { API } from "../../api/api.main";
import { ConQs } from "../../utils/util.con";
import PaginationMain from "../../component/pagination/paginationMain";

const Careerlist = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [alldata, setAllData] = useState([]);
  const [reqtitle, setReqTitle] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [moreload, setMoreLoad] = useState(30);
  const [skip, setSkip] = useState(0);
  const [count, setcount] = useState(0);

  useEffect(() => {
    console.log("location", location.search.split("=")[1]);
    setReqTitle(location.search.split("=")[1]);
    loadData();
  }, [skip]);

  const mainfilter = {
    skip: skip,
    limit: moreload,
    sort: { updatedAt: -1 },
  };

  const pageSelect = (number) => {
    // console.log("number",number)
    setSkip((number - 1) * moreload);
  };

  const loadData = async () => {
    try {
      setLoading(true);
      const { data } = await API.get(`/appliedjobs?${ConQs(mainfilter)}`);
      // console.log("data", data.appliedJobs);
      setAllData(data.appliedJobs);
      setcount(data.count);
    } catch (error) {
      console.log("product error", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    // <div className="flex w-[1440px]  pt-[23px] pl-[40px] pr-[65px]">
    <div className="flex w-full pt-[23px] pl-[40px] pr-[65px]">
      <div className="flex flex-col w-full">
        {/* <div className="w-[1117px] h-[67px] flex rounded-[10px] items-center pl-[42px] pr-[66px] mb-[26px] bg-era-red/[0.1]"> */}
        <div className="w-full h-[67px] flex justify-between rounded-[10px] items-center pl-[42px] pr-[66px] mb-[26px] bg-era-red/[0.1]">
          <div className="w-[213px] text-era-red text-xl font-semibold items-center ">
            ชื่อ-นามสกุล
          </div>
          <div className="w-[156px] text-era-red text-xl font-semibold items-center ">
            Line id
          </div>
          <div className="w-[169px] text-era-red text-xl font-semibold items-center ">
            เบอร์โทร
          </div>
          <div className="w-[201px] text-era-red text-xl font-semibold items-center ">
            อาชีพปัจจุบัน
          </div>
          <div className="w-[372px] text-era-red text-xl font-semibold items-center ">
            โซนที่อยู่อาศัย
          </div>
          <div className="text-era-red text-xl font-semibold items-center justify-self-end">
            ลบ
          </div>
        </div>
        {alldata?.map((item, index) => (
          <Reqlist
            key={index}
            ID={item?._id}
            name={item?.appliedjob_name}
            LineId={item?.appliedjob_lineID}
            title={"career"}
            currentjob={item?.appliedjob_currentJob}
            livingArea={item?.appliedjob_livingArea}
            telnum={item?.appliedjob_tel}
            email={item?.appliedjob_email}
            reloadData={loadData}
          />
        ))}
             {alldata.length >= count ? (
        <></>
      ) : (
        // <div className="flex w-[950px] justify-center mt-[70px]">
        <div className="flex w-11/12 justify-center mt-[70px]">
          <PaginationMain
            total={count}
            perpage={moreload}
            quanDisplay={5}
            setSkip={pageSelect}
          />
        </div>
      )}
      </div>
    </div>
  );
};

export default Careerlist;
