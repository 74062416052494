import React, { useEffect, useState } from "react";
import ERA_LOGO from "../../Image/ERA-logo-timeline.png";
import Iconhome1 from "../../icon/iconhome1";
import { Image, Link, UserPlus, Users } from "lucide-react";
import {
  BUTTON_NAV_LAYOUT,
  BUTTON_NAV_TEXT,
} from "../../constant/layout.style";
import IconNote1 from "../../icon/iconNote1";
import IconDrawerAdd1 from "../../icon/iconDrawerAdd1";
import Navbar from "../navbar/navbar";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import classNames from "classnames";

const ButtonNav = ({
  Icon,
  text = "Product",
  allstyle = BUTTON_NAV_LAYOUT,
  textstyle = BUTTON_NAV_TEXT,
  onnav,
  setonnev,
  className,
  navto = "/",
}) => {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => {
        // window.location = navto;
        setonnev(text);
        navigate(navto);
      }}
      className={classNames(allstyle, {
        "bg-[#C41F30]/[0.1]": onnav === text,
        "bg-white": onnav !== text,
      })}
    >
      {Icon}
      <div
        className={classNames(textstyle, {
          "text-[#C41F30] ": onnav === text,
          "text-black ": onnav !== text,
        })}
      >
        {text}
      </div>
    </button>
  );
};

function LayOut({ children }) {
  const [navgoto, setNavGoTo] = useState();
  const [onnav, setonnev] = useState("");
  const location = useLocation();
  const params = useParams();
  const searchParams = new URLSearchParams(location.search);
  //console.log("layoutparams", location.pathname.split("/")[1]);

  useEffect(() => {
    setNavGoTo(location.pathname.split("/")[1]);
  }, [onnav]);

  let navigate = useNavigate();

  const routeChange = () => {
    let path = "";
    switch (navgoto) {
      case "":
        path = "/product/create";
        break;
      case "product":
        path = "/product/create";
        break;
      case "blog":
        path = "/blog/addblog";
        break;
      case "member":
        path = "/member/addmember";
        break;
      default:
        break;
    }
    navigate(path);
  };

  const NAV_PLACEHOLDER = {
    "": "+ Add product",
    product: "+ Add product",
    blog: "+ Add blog",
    member: "+ Add member",
  };

  // console.log("navgoto", navgoto);

  return (
    <section className="w-full h-full flex font-Sarabun">
      <div className="w-[230px] h-full bg-white flex flex-col items-center pt-[12px] px-[12px] shadow-admin_nav fixed z-[90]">
        <div className="flex justify-center w-full pb-[9px] mb-[25px] border-b-solid border-b-[0.5px] border-b-[#CECECE]">
          <img
            onClick={() => {
              setonnev("Product");
              navigate("/");
            }}
            src={ERA_LOGO}
            alt="Logo"
            className="w-[48px] h-[73px]"
          />
        </div>
        <div className="w-full flex flex-col space-y-[10px]">
          <ButtonNav
            text="Product"
            allstyle={BUTTON_NAV_LAYOUT}
            textstyle={BUTTON_NAV_TEXT}
            Icon={
              onnav === "Product" ? (
                <Iconhome1
                  fill="#C41F30"
                  className="fill-[#C41F30] group-hover:fill-[#C41F30]"
                />
              ) : (
                <Iconhome1 />
              )
            }
            onnav={onnav}
            setonnev={setonnev}
          />
          <ButtonNav
            text="Apply course"
            navto="/career"
            Icon={
              onnav === "Career" ? (
                <Users className="stroke-[#C41F30] group-hover:stroke-[#C41F30]" />
              ) : (
                <Users className="stroke-[#030D45] group-hover:stroke-[#C41F30]" />
              )
            }
            allstyle={BUTTON_NAV_LAYOUT}
            textstyle={BUTTON_NAV_TEXT}
            onnav={onnav}
            setonnev={setonnev}
          />
          <ButtonNav
            text="Blog"
            navto="/blog"
            Icon={
              onnav === "Blog" ? <IconNote1 fill="#C41F30" /> : <IconNote1 />
            }
            allstyle={BUTTON_NAV_LAYOUT}
            textstyle={BUTTON_NAV_TEXT}
            onnav={onnav}
            setonnev={setonnev}
          />
          <ButtonNav
            text="Content"
            navto="/content"
            Icon={
              onnav === "Content" ? (
                <Image className="stroke-[#C41F30] group-hover:stroke-[#C41F30] " />
              ) : (
                <Image className="stroke-[#000] group-hover:stroke-[#C41F30]" />
              )
            }
            allstyle={BUTTON_NAV_LAYOUT}
            textstyle={BUTTON_NAV_TEXT}
            onnav={onnav}
            setonnev={setonnev}
          />
          <ButtonNav
            text="Request"
            navto="/request"
            Icon={
              onnav === "Request" ? (
                <div className="w-[24px] h-[24px]">
                  <IconDrawerAdd1
                    fill="#C41F30"
                    className="fill-[#C41F30] group-hover:fill-[#C41F30]"
                  />
                </div>
              ) : (
                <div className="w-[24px] h-[24px]">
                  <IconDrawerAdd1
                    fill="#030D45"
                    className="fill-[#030D45] group-hover:fill-[#C41F30]"
                  />
                </div>
              )
            }
            allstyle={BUTTON_NAV_LAYOUT}
            textstyle={BUTTON_NAV_TEXT}
            onnav={onnav}
            setonnev={setonnev}
          />
          <ButtonNav
            text="Member"
            navto="/member"
            Icon={
              onnav === "Member" ? (
                <div className="w-[24px] h-[24px]">
                  <UserPlus className="stroke-[#C41F30] group-hover:stroke-[#C41F30]" />
                  {/* <IconDrawerAdd1 stroke="#C41F30" className="stroke-[#C41F30] group-hover:stroke-[#C41F30]"/> */}
                </div>
              ) : (
                <div className="w-[24px] h-[24px]">
                  <UserPlus className="stroke-[#030D45] group-hover:stroke-[#C41F30]" />
                  {/* <IconDrawerAdd1 fill="#030D45" className="fill-[#030D45] group-hover:fill-[#C41F30]"/> */}
                </div>
              )
            }
            allstyle={BUTTON_NAV_LAYOUT}
            textstyle={BUTTON_NAV_TEXT}
            onnav={onnav}
            setonnev={setonnev}
          />
        </div>
      </div>
      <div className="w-full h-full ">
          <Navbar
            Butlabel={NAV_PLACEHOLDER[navgoto]}
            navgoto={navgoto}
            ButFunction={routeChange}
          />
        <div className="mt-[90px] ml-[218px]">{children}</div>
      </div>
    </section>
    // <div>LayOut

    //     {children}
    // </div>
  );
}

export default LayOut;
