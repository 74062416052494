import React, { useEffect, useState } from "react";
import IconLogin from "../../icon/iconLogin";
import { useLocation, useNavigate } from "react-router-dom";

const Navbar = ({
  navgoto = "",
  Butlabel = "+ Add product",
  ButFunction = "",
  ButStyle = "shadow-login px-[17px] py-[9px] rounded-[10px] text-xl font-semibold text-era-red hover:bg-era-red hover:text-white duration-300",
}) => {
  const location = useLocation();
  const [secondOnnav, setSecondOnnev] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    setSecondOnnev(location.pathname.split("/"));
  }, [location]);
  // useEffect(() => {
  //   setSecondOnnev(location.pathname.split("/"));
  // }, [secondOnnav]);
  // console.log("navgoto",navgoto)

  return (
    <div className="w-full h-[94px] bg-white flex justify-end self-center items-center pr-[87px] shadow-top_nav fixed z-[70] ">
      <div className="space-x-[16px] items-center self-center">
        {navgoto === "" && (
          <button onClick={ButFunction} className={ButStyle}>
            {Butlabel}
          </button>
        )}
        {navgoto === "product" && (
          <button onClick={ButFunction} className={ButStyle}>
            {Butlabel}
          </button>
        )}
        {navgoto === "contact" && (
          <button onClick={ButFunction} className={ButStyle}>
            {Butlabel}
          </button>
        )}
        {navgoto === "blog" && (
          <button onClick={ButFunction} className={ButStyle}>
            {Butlabel}
          </button>
        )}
        {navgoto === "member" && (
          <button onClick={ButFunction} className={ButStyle}>
            {Butlabel}
          </button>
        )}
        {secondOnnav.includes("banner" || "addbanner") && (
          <button
            onClick={() => {
              navigate("/content/banner/addbanner");
            }}
            className={ButStyle}
          >
            {"+ Add banner"}
          </button>
        )}
        <button
          onClick={() => {
            // navigate("/login");
          }}
          className="w-[42px] h-[42px] shadow-login p-[9px] rounded-[10px] self-center group transition-all duration-300"
        >
          <IconLogin />
        </button>
      </div>
    </div>
  );
};

export default Navbar;
