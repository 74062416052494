const Namecon = (cover) => {
  console.log("cover",cover);
  const getExtension = (fileName) => {
    const splitFileName = fileName?.split("/")?.[1];
    return splitFileName;
  }

  const Imagename = new Date().getTime() + "." + getExtension(cover?.type);
  // console.log("Imagename",Imagename);
  return Imagename;
};

export default Namecon;

