import React, { useState } from "react";
import InputText from "../../component/textinput";
import DropDownInfo from "../../component/dropDownInfo";
import SunEditor from "suneditor-react";
import UploadImage from "../../component/uploadImage";
import { ImgFromFile }from "../../utils/util.con";
import { uploadData } from "aws-amplify/storage";
import Namecon from "../../utils/Name.con";
import { API } from "../../api/api.main";
import { X } from "lucide-react";
import FieldWysiwyg from "../../component/FieldWysiwyg/fieldWysiwyg";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const checkFormError = (form,cover) => {
  let dynamicError = {}

  if(cover !== ""){
    dynamicError.errorCover= false;
  }else{
    dynamicError.errorCover = true;
  }

  return {
    ...dynamicError,
    article_type: !form.article_type,
    article_title:!form.article_title,
  };
};

const initError = {
  article_type: false,
  article_title: false,
  article_body: false,
};


const Blogadd = () => {
  const [cover, setCover] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [displayCover, setDisplayCover] = useState("");
  const [openDropDown, setOpenDropDown] = useState({
    article: false,
  });
  const [allInfo, setAllInfo] = useState({
    article_type: "",
    article_title: "",
    article_body: "",
  });

  const [formError, setFormError] = useState(initError);

  const addCover = async (e) => {
    console.log("e.target.files", e.target.files);
    let displayImg = "";
    if(e.target.files.length>0){
      setCover(e.target.files[0]);
      displayImg = await ImgFromFile(e.target.files[0]);
      setDisplayCover(displayImg);
    }else{
      setCover(cover);
      setDisplayCover(displayCover);
    }
  };

  const navigate = useNavigate();

  const clicksubmit = async () => {

    const thisFormError = checkFormError(allInfo,cover);
    console.log("thisFormError",thisFormError)
    const isFormError = Object.entries(thisFormError)?.some(
      ([_, value]) => value === true
    );

    if (isFormError ) {
      console.log("checkTypePrice",thisFormError)
      setFormError(thisFormError);
      Swal.fire({
        title: "โปรดกรอกข้อมูลให้ครบถ้วน",
        icon: "warning",
      });
      return;
    } else {
      setFormError(initError);
    }

    if (
      allInfo.article_type !== "" &&
      allInfo.article_title !== "" 
      &&
      allInfo.article_body !== "" 
      &&
      cover !== "" &&
      displayCover !== ""
    ) {
      let CoverName = Namecon(cover);
      const result = await uploadData({
        key: CoverName,
        data: cover,
      }).result;
      console.log("result", result);

      await API.post("/articles", {
        article_type: allInfo.article_type,
        article_title: allInfo.article_title,
        article_body: allInfo.article_body,
        article_picture: CoverName,
      })
        .then((res) => {
          Swal.fire({
            title: "เพิ่มข้อมูลข้อมูลเรียบร้อย",
            icon: "success",
          }).then(()=>{
            navigate("/blog")
          })
          console.log("res", res);
        })
        .catch((error) => {
          console.log("error", error);
        });

        setAllInfo({
          article_type: "",
          article_title: "",
          article_body: "",
        });
        setCover("");
        setDisplayCover("");
    } else {
      console.log("not complete fill");
      Swal.fire({
        title: "กรุณากรอกให้ครบ",
        icon: "alert",
      })
    }
  };

  // console.log("allInfo", allInfo);

  return (
    <div className="w-full pl-[39px] pr-[82px] pt-[28px] pb-[68px]">
      <div className="flex items-end w-full h-[56px] mb-[28px]">
        <div className="w-[56px] h-[36px] text-[28px] text-era-red font-semibold mr-[43px]">
          Blog
        </div>
      </div>
      <div className="flex w-full space-x-[42px] mb-[20px]">
        <InputText
          name="หัวข้อ"
          placeholder="ใส่ชื่อหัวข้อ"
          allInfo={Object.entries(allInfo)[1]}
          setAllInfo={setAllInfo}
          error={formError.article_title}
        />
        <DropDownInfo
          name="หมวดหมู่"
          placeholder="กรุณาเลือกหมวดหมู่"
          openDropDown={Object.entries(openDropDown)[0]}
          setOpenDropDown={setOpenDropDown}
          allInfo={Object.entries(allInfo)[0]}
          setAllInfo={setAllInfo}
          error={formError.article_type}
          choice={[
            {
              label: "ข่าวสารองค์กร",
              value: "news",
            },
            {
              label: "บทความ",
              value: "articles",
            },
          ]}
        />
      </div>
      <div className="mb-[20px]">
        <div>คำอธิบาย</div>
        <FieldWysiwyg value={allInfo.article_body} onChange={(value) => {
            setAllInfo((prev) => ({ ...prev, article_body: value }));
          }}/>
      </div>
      <div className="mb-[44px]">
        <UploadImage
          name={"Image ( ขนาด 4:3 )"}
          reddot={false}
          cover={cover}
          addCover={addCover}
          displayImg={displayCover}
          multiple={false}
          error={formError.errorCover}
          displayStyle="w-[670px] h-[320px] rounded-[10px] "
          framstyle="w-[670px] h-[320px] flex justify-center items-center border-[1px] border-solid border-[#000]/[0.29] rounded-[10px]"
        />
        {cover !== "" && (
              <div className="flex mt-[10px] space-x-[7px]">
                <button onClick={()=>{
                  setCover('')
                }}>
                  <X className="w-[24px] h-[24px]" />
                </button>
                <div className="text-base text-[#F0BF50] font-normal">
                  {cover?.name }
                </div>
              </div>
            )}
      </div>
      <div className="flex justify-center">
        <button
          onClick={clicksubmit}
          className="w-[189px] h-[50px] bg-era-red text-xl text-white font-semibold rounded-[40px] duration-300 hover:bg-[#E53648]"
        >
          บันทึก
        </button>
      </div>
    </div>
  );
};

export default Blogadd;
