import { X } from "lucide-react";
import React, { useState } from "react";
import InputText from "../../component/textinput";
import UploadImage from "../../component/uploadImage";
import { ImgFromFile } from "../../utils/util.con";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../../api/api.main";
import { uploadData } from "aws-amplify/storage";
import Namecon from "../../utils/Name.con";
import Swal from "sweetalert2";

const checkFormError = (
  contactPhone,
  contactLine,
  lineLink,
  contactEmail,
  contactName,
  contactNickName,
  contactImage
) => {
  let dynamicError = {};

  if (contactPhone !== "") {
    dynamicError.errorContactPhone = false;
  } else {
    dynamicError.errorContactPhone = true;
  }

  if (contactLine !== "") {
    dynamicError.errorContactLine = false;
  } else {
    dynamicError.errorContactLine = true;
  }

  // if (lineLink !== "") {
  //   dynamicError.errorlineLink = false;
  // } else {
  //   dynamicError.errorlineLink = true;
  // }

  if (contactEmail !== "") {
    dynamicError.errorContactEmail = false;
  } else {
    dynamicError.errorContactEmail = true;
  }

  if (contactName !== "") {
    dynamicError.errorContactName = false;
  } else {
    dynamicError.errorContactName = true;
  }

  if (contactNickName !== "") {
    dynamicError.errorContactNickName = false;
  } else {
    dynamicError.errorContactNickName = true;
  }

  if (contactImage !== "") {
    dynamicError.errorContactImage = false;
  } else {
    dynamicError.errorContactImage = true;
  }

  return {
    ...dynamicError,
  };
};

const initError = {
  google_place: false,
  title: false,
  category: false,
  status: false,
  type: false,
  unitarea: false,
  bedroom: false,
  bathRoom: false,
  meetingRoom: false,
  description: false,
  urllocation: false,
  fullAddress: false,
  google_place: false,
  propcode: false,
  floor: false,
  priceRent: false,
  price: false,
  fullAddress: false,
  address: false,
};

const Memberadd = () => {
  const [formError, setFormError] = useState(initError);
  const [contactPhone, setContactPhone] = useState({
    phone: "",
  });
  const [contactLine, setContactLine] = useState({
    line: "",
  });
  const [lineLink, setlineLink] = useState({
    lineLink: "",
  });
  const [contactEmail, setContactEmail] = useState({
    email: "",
  });
  const [contactName, setcontactNames] = useState({
    name: "",
  });
  const [contactNickName, setNickname] = useState({
    nickname: "",
  });
  const [contactImage, setContactImage] = useState("");
  const [contactDisplayImage, setContactDisplayImage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [alldata, setAllData] = useState([]);
  const navigate = useNavigate();
  const params = useParams();

  const addConatactImage = async (e) => {
    console.log(" e.target.files", e.target.files);
    let displayImg = "";
    if (e.target.files.length > 0) {
      setContactImage(e.target.files[0]);
      displayImg = await ImgFromFile(e.target.files[0]);
      setContactDisplayImage(displayImg);
    } else {
      setContactImage(contactImage);
      setContactDisplayImage(contactDisplayImage);
    }
  };

  const clicksubmit = async () => {
    const thisFormError = checkFormError(
      contactPhone.phone,
      contactLine.line,
      lineLink.lineLink,
      contactEmail.email,
      contactName.name,
      contactNickName.nickname,
      contactImage
    );
    console.log("thisFormError", thisFormError);
    const isFormError = Object.entries(thisFormError)?.some(
      ([_, value]) => value === true
    );

    if (isFormError) {
      console.log("checkTypePrice", thisFormError);
      setFormError(thisFormError);
      Swal.fire({
        title: "โปรดกรอกข้อมูลให้ครบถ้วน",
        icon: "warning",
      });
      return;
    } else {
      setFormError(initError);
    }

    let CoverName = "";
    if (
      contactPhone.phone !== "" &&
      contactLine.line !== "" &&
      contactEmail.email !== "" &&
      contactName.name !== "" &&
      contactNickName.nickname !== "" &&
      contactImage !== "" &&
      contactDisplayImage !== ""
    ) {
      if (contactImage !== "" && contactImage?.type !== undefined) {
        CoverName = Namecon(contactImage);
        const result = await uploadData({
          key: CoverName,
          data: contactImage,
        }).result;
        console.log("result", result);
      } else {
        CoverName = contactImage;
      }

      await API.post("/member", {
        image: CoverName,
        name: contactName.name,
        nickname: contactNickName.nickname,
        contactways: [
          {
            icon: "phoneIcon",
            name: "phone",
            info: contactPhone.phone,
            color: {
              inside: "era_search/[0.37]",
              border: "era_search",
            },
            link: "",
          },
          {
            icon: "linelogo",
            name: "line",
            info: contactLine.line,
            color: {
              inside: "lightGreen1/[0.37]",
              border: "linegreen",
            },
            link: lineLink.lineLink,
          },
          {
            icon: "mailIcon",
            name: "email",
            info: contactEmail.email,
            color: {
              inside: "lightBlue1/[0.37]",
              border: "lightBlue1",
            },
            link: "",
          },
        ],
      })
        .then((res) => {
          Swal.fire({
            title: "เพิ่มข้อมูลข้อมูลเรียบร้อย",
            icon: "success",
          }).then(() => {
            navigate("/member");
          });
          console.log("res", res);
        })
        .catch((error) => {
          Swal.fire({
            title: "บันทึกข้อมูลผิดพลาด",
            icon: "error",
          });
          console.log("error", error);
        });
    } else {
      Swal.fire({
        title: "กรุณากรองให้ครบ",
        text: "ท่านยังกรอกไม่ครบ",
        icon: "warning",
      });
    }
  };

  return (
    <div className="flex flex-col w-full max-w-[1440px] pt-[23px] pl-[40px] pr-[65px]">
      <div className="w-full flex flex-col mb-[30px] ">
        <div className="w-full flex flex-col mb-[30px] justify-start">
          <div className="flex items-end w-full h-[56px] mb-[19px]">
            <div className="w-[225px] h-[36px] text-[20px] text-era-red font-semibold mr-[43px]">
              Add member page
            </div>
          </div>
          <div className="w-[167px] pb-[7px] mb-[30px] border-b-solid border-b-[1px] border-b-era-red">
            ข้อมูลการติดต่อผู้ประกาศ
          </div>
          <div className="grid grid-cols-2 gap-[30px] mb-[30px]">
            <InputText
              name="ชื่อ - นามสกุล"
              type="text"
              placeholder="ระบุ"
              allInfo={Object.entries(contactName)[0]}
              setAllInfo={setcontactNames}
              error={formError?.errorContactName}
            />
            <InputText
              name="ชื่อเล่น"
              type="text"
              placeholder="ระบุ"
              allInfo={Object.entries(contactNickName)[0]}
              setAllInfo={setNickname}
              error={formError?.errorContactNickName}
            />
            <InputText
              name="เบอร์โทร"
              type="tel"
              placeholder="ระบุ"
              allInfo={Object.entries(contactPhone)[0]}
              setAllInfo={setContactPhone}
              error={formError?.errorContactPhone}
            />
            <InputText
              name="Line ID"
              type="text"
              placeholder="ระบุ"
              allInfo={Object.entries(contactLine)[0]}
              setAllInfo={setContactLine}
              error={formError?.errorContactLine}
            />
          </div>
          <div className="w-full mb-[30px]">
            <InputText
              name="ลิงก์เพิ่มเพื่อน Line"
              type="text"
              placeholder="ระบุ"
              reddot={false}
              allInfo={Object.entries(lineLink)[0]}
              setAllInfo={setlineLink}
              error={formError?.errorlineLink}
            />
          </div>
          <div className="w-full mb-[30px]">
            <InputText
              name="Email"
              type="email"
              placeholder="ระบุ"
              allInfo={Object.entries(contactEmail)[0]}
              setAllInfo={setContactEmail}
              error={formError?.errorContactEmail}
            />
          </div>
        </div>
        <div className="flex flex-col overflow-hidden">
          <UploadImage
            cover={contactImage}
            addCover={addConatactImage}
            name="รูปภาพโปรไฟล์(60x60 px)"
            displayImg={contactDisplayImage}
            multiple={false}
            error={formError?.errorContactImage}
          />
          {contactImage !== "" && (
            <div className="flex mt-[10px] space-x-[7px]">
              <button
                onClick={() => {
                  setContactImage("");
                }}
              >
                <X className="w-[24px] h-[24px]" />
              </button>
              <div className="text-base text-[#F0BF50] font-normal">
                {contactImage?.name ? contactImage?.name : contactImage}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="w-full flex justify-center">
        <button
          onClick={clicksubmit}
          className="w-[189px] h-[50px] mt-[20px] mb-[47px] flex justify-center items-center text-xl text-white font-semibold rounded-[40px] bg-era-red duration-300 hover:bg-[#E53648]"
        >
          บันทึก
        </button>
      </div>
    </div>
  );
};
//aa
export default Memberadd;
