import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API, S3_URL } from "../../api/api.main";
import axios from "axios";
import Swal from "sweetalert2";
import { PenSquare } from "lucide-react";

const StarDarkIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.6666 11.1862C22.9143 11.0016 23.0981 10.7441 23.1922 10.4498C23.2862 10.1555 23.2858 9.83913 23.191 9.54507C23.0961 9.25101 22.9116 8.99401 22.6634 8.81009C22.4151 8.62618 22.1155 8.52457 21.8066 8.51953L15.8066 8.29286C15.7771 8.29082 15.7488 8.2803 15.7251 8.26256C15.7015 8.24482 15.6834 8.22061 15.6732 8.19286L13.5999 2.59286C13.4957 2.30795 13.3065 2.06194 13.0579 1.88813C12.8093 1.71431 12.5132 1.62109 12.2099 1.62109C11.9065 1.62109 11.6105 1.71431 11.3619 1.88813C11.1133 2.06194 10.9241 2.30795 10.8199 2.59286L8.75323 8.21286C8.74301 8.24061 8.72499 8.26482 8.70133 8.28256C8.67768 8.3003 8.64939 8.31083 8.61989 8.31286L2.61989 8.53953C2.31096 8.54457 2.01136 8.64618 1.76309 8.83009C1.51482 9.01401 1.33033 9.27101 1.2355 9.56507C1.14067 9.85913 1.14024 10.1755 1.23428 10.4698C1.32833 10.7641 1.51212 11.0216 1.75989 11.2062L6.46656 14.9062C6.49012 14.9247 6.50772 14.9498 6.5172 14.9782C6.52668 15.0066 6.52762 15.0372 6.51989 15.0662L4.89989 20.8062C4.81595 21.0985 4.82408 21.4095 4.92319 21.6971C5.02229 21.9846 5.20756 22.2346 5.45379 22.413C5.70002 22.5915 5.99526 22.6898 6.29933 22.6946C6.6034 22.6993 6.90156 22.6103 7.15323 22.4395L12.1266 19.1062C12.1511 19.0893 12.1801 19.0802 12.2099 19.0802C12.2397 19.0802 12.2687 19.0893 12.2932 19.1062L17.2666 22.4395C17.5148 22.6161 17.8119 22.7109 18.1166 22.7109C18.4212 22.7109 18.7183 22.6161 18.9666 22.4395C19.2129 22.2627 19.3983 22.014 19.4974 21.7274C19.5965 21.4409 19.6043 21.1307 19.5199 20.8395L17.8866 15.0795C17.8779 15.0506 17.8784 15.0197 17.8879 14.9911C17.8975 14.9625 17.9156 14.9375 17.9399 14.9195L22.6666 11.1862Z"
        fill="#CECECE"
      />
    </svg>
  );
};

const StarIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.6666 11.1862C22.9143 11.0016 23.0981 10.7441 23.1922 10.4498C23.2862 10.1555 23.2858 9.83913 23.191 9.54507C23.0961 9.25101 22.9116 8.99401 22.6634 8.81009C22.4151 8.62618 22.1155 8.52457 21.8066 8.51953L15.8066 8.29286C15.7771 8.29082 15.7488 8.2803 15.7251 8.26256C15.7015 8.24482 15.6834 8.22061 15.6732 8.19286L13.5999 2.59286C13.4957 2.30795 13.3065 2.06194 13.0579 1.88813C12.8093 1.71431 12.5132 1.62109 12.2099 1.62109C11.9065 1.62109 11.6105 1.71431 11.3619 1.88813C11.1133 2.06194 10.9241 2.30795 10.8199 2.59286L8.75323 8.21286C8.74301 8.24061 8.72499 8.26482 8.70133 8.28256C8.67768 8.3003 8.64939 8.31083 8.61989 8.31286L2.61989 8.53953C2.31096 8.54457 2.01136 8.64618 1.76309 8.83009C1.51482 9.01401 1.33033 9.27101 1.2355 9.56507C1.14067 9.85913 1.14024 10.1755 1.23428 10.4698C1.32833 10.7641 1.51212 11.0216 1.75989 11.2062L6.46656 14.9062C6.49012 14.9247 6.50772 14.9498 6.5172 14.9782C6.52668 15.0066 6.52762 15.0372 6.51989 15.0662L4.89989 20.8062C4.81595 21.0985 4.82408 21.4095 4.92319 21.6971C5.02229 21.9846 5.20756 22.2346 5.45379 22.413C5.70002 22.5915 5.99526 22.6898 6.29933 22.6946C6.6034 22.6993 6.90156 22.6103 7.15323 22.4395L12.1266 19.1062C12.1511 19.0893 12.1801 19.0802 12.2099 19.0802C12.2397 19.0802 12.2687 19.0893 12.2932 19.1062L17.2666 22.4395C17.5148 22.6161 17.8119 22.7109 18.1166 22.7109C18.4212 22.7109 18.7183 22.6161 18.9666 22.4395C19.2129 22.2627 19.3983 22.014 19.4974 21.7274C19.5965 21.4409 19.6043 21.1307 19.5199 20.8395L17.8866 15.0795C17.8779 15.0506 17.8784 15.0197 17.8879 14.9911C17.8975 14.9625 17.9156 14.9375 17.9399 14.9195L22.6666 11.1862Z"
        fill="#F0BF50"
      />
    </svg>
  );
};

const Reqlist = ({
  ID,
  OnDelete,
  order,
  title,
  name,
  nickname,
  telnum,
  email,
  type,
  date,
  time,
  address,
  comment,
  LineId,
  Image,
  currentjob,
  livingArea,
  starNumber,
  reloadData,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navgo = useNavigate();

  const NAVTO = {
    contactus: `/request/list/detail/${ID}?title=${title}`,
    buyandsells: `/request/list/detail/${ID}?title=${title}`,
    appointments: `/request/list/detail/${ID}?title=${title}`,
    career: `/career/detail/${ID}`,
    review: `/content/review/${ID}?order=${order}`,
    member: `/member/editmember/${ID}`,
  };

  const CONVERT_CONTACT_TYPE = {
    sell: "ฝากขายบ้าน",
    find: "ฝากหาบ้าน",
    advice: "แนะนำ ติชม",
    ads: "ติดต่อลงโฆษณา",
    etc: "ติดต่อสอบถามเรื่องอื่น ๆ",
  };

  const onClickNavigate = () => {
    // window.location = NAVTO[title];
    navgo(NAVTO[title]);
  };

  return (
    <div
      onClick={onClickNavigate}
      // to={NAVTO[title]}
      className="w-full h-[67px] flex rounded-[10px] items-center pl-[42px] pr-[66px] mb-[18px] bg-white border-solid border-[1px] border-era-red transition-all duration-300 hover:bg-[#C41F301A]"
      // className="w-[1117px] h-[67px] flex rounded-[10px] items-center pl-[42px] pr-[66px] mb-[18px] bg-white border-solid border-[1px] border-era-red transition-all duration-300 hover:bg-[#C41F301A]"
    >
      {title === "contactus" && (
        <div className="w-full flex flex-row justify-between">
          <div className="w-[282px] text-era-red text-base font-light items-center">
            {name}
          </div>
          <div className="w-[158px] text-era-red text-base font-light items-center ">
            {telnum}
          </div>
          <div className="w-[272px] text-era-red text-base font-light items-center ">
            {email}
          </div>
          <div className="w-[272px] text-era-red text-base font-light items-center ">
            {CONVERT_CONTACT_TYPE[type]}
          </div>
          <button
            onClick={async (e) => {
              try {
                e.stopPropagation();
                setLoading(true);
                await API.delete("/contactus/" + ID);
                Swal.fire({
                  title: "ลบข้อมูลเรียบร้อย",
                  icon: "success",
                }).then(() => {
                  reloadData();
                });
              } catch (error) {
                console.log("product error", error);
                Swal.fire({
                  title: "ลบข้อมูลผิดพลาด",
                  icon: "warning",
                });
                setError(error);
              } finally {
                setLoading(false);
                reloadData();
              }
            }}
            className="text-era-red text-base font-light justify-self-end"
          >
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.11193 8.49252C6.55719 8.5033 6.90935 8.87068 6.8985 9.31308L6.09229 9.29357L5.28608 9.27405C5.29692 8.83165 5.66667 8.48175 6.11193 8.49252ZM6.09229 9.29357C5.28608 9.27405 5.28608 9.27405 5.28608 9.27405L5.285 9.31961L5.28209 9.44909C5.27964 9.56165 5.27626 9.725 5.27249 9.92874C5.26497 10.3361 5.25595 10.9054 5.24993 11.5533C5.23793 12.8456 5.23774 14.4639 5.28642 15.7343C5.33967 17.1239 5.48304 18.9226 5.60134 20.2619C5.73493 21.7745 7.01357 22.9154 8.5294 22.9154H16.4732C17.989 22.9154 19.2677 21.7745 19.4013 20.2619C19.5196 18.9226 19.6629 17.1239 19.7162 15.7343C19.7649 14.4639 19.7647 12.8456 19.7527 11.5533C19.7467 10.9054 19.7376 10.3361 19.7301 9.92874C19.7263 9.725 19.723 9.56165 19.7205 9.44909L19.7176 9.31961L19.7165 9.27489C19.7057 8.83249 19.3359 8.48175 18.8907 8.49252C18.4454 8.5033 18.0933 8.87068 18.1041 9.31308L18.6179 9.30065C18.1041 9.31308 18.1041 9.31308 18.1041 9.31308L18.1051 9.35655L18.108 9.48375C18.1104 9.59473 18.1138 9.75632 18.1175 9.95814C18.1249 10.3618 18.1339 10.9261 18.1398 11.5681C18.1518 12.8557 18.1516 14.4426 18.1044 15.6733C18.0528 17.0207 17.9125 18.786 17.7945 20.1218C17.7351 20.7946 17.1653 21.3128 16.4732 21.3128H8.5294C7.83733 21.3128 7.26749 20.7946 7.20807 20.1218C7.09008 18.786 6.94979 17.0207 6.89816 15.6733C6.85099 14.4426 6.8508 12.8557 6.86276 11.5681C6.86873 10.9261 6.87767 10.3618 6.88513 9.95814C6.88885 9.75632 6.8922 9.59473 6.89462 9.48375L6.89747 9.35655L6.8985 9.31308L6.09229 9.29357Z"
                fill="#C41F30"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.16797 6.08844C4.16797 5.64591 4.52903 5.28716 4.97442 5.28716H20.0282C20.4736 5.28716 20.8346 5.64591 20.8346 6.08844C20.8346 6.53098 20.4736 6.88972 20.0282 6.88972H4.97442C4.52903 6.88972 4.16797 6.53098 4.16797 6.08844Z"
                fill="#C41F30"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.60187 3.63746C9.11578 2.68006 10.1189 2.08203 11.211 2.08203H13.7916C14.8837 2.08203 15.8868 2.68006 16.4007 3.63746L17.514 5.71137C17.7235 6.10184 17.5749 6.5872 17.1819 6.79545C16.7889 7.00371 16.3004 6.85599 16.0908 6.46552L14.9776 4.39161C14.744 3.95643 14.288 3.6846 13.7916 3.6846H11.211C10.7146 3.6846 10.2586 3.95643 10.025 4.39161L8.9118 6.46552C8.70221 6.85599 8.21371 7.00371 7.82072 6.79545C7.42773 6.5872 7.27906 6.10184 7.48865 5.71137L8.60187 3.63746Z"
                fill="#C41F30"
              />
            </svg>
          </button>
        </div>
      )}
      {title === "buyandsells" && (
        <div className="w-full flex flex-row justify-between">
          <div className="w-[282px] text-era-red text-base font-light items-start">
            {name}
          </div>
          <div className="w-[158px] text-era-red text-base font-light items-start ">
            {telnum}
          </div>
          <div className="w-[272px] text-era-red text-base font-light items-start ">
            {email}
          </div>
          <div className="w-[272px] text-era-red text-base font-light items-start line-clamp-1 ">
            {type}
          </div>
          <button
            onClick={async (e) => {
              try {
                e.stopPropagation();
                setLoading(true);
                await API.delete("/buyandsells/" + ID);
                Swal.fire({
                  title: "ลบข้อมูลเรียบร้อย",
                  icon: "success",
                }).then(() => {
                  reloadData();
                });
                reloadData();
              } catch (error) {
                console.log("product error", error);
                setError(error);
                Swal.fire({
                  title: "ลบข้อมูลผิดพลาด",
                  icon: "warning",
                });
              } finally {
                setLoading(false);
                reloadData();
              }
            }}
            className="text-era-red text-base font-light justify-self-end"
          >
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.11193 8.49252C6.55719 8.5033 6.90935 8.87068 6.8985 9.31308L6.09229 9.29357L5.28608 9.27405C5.29692 8.83165 5.66667 8.48175 6.11193 8.49252ZM6.09229 9.29357C5.28608 9.27405 5.28608 9.27405 5.28608 9.27405L5.285 9.31961L5.28209 9.44909C5.27964 9.56165 5.27626 9.725 5.27249 9.92874C5.26497 10.3361 5.25595 10.9054 5.24993 11.5533C5.23793 12.8456 5.23774 14.4639 5.28642 15.7343C5.33967 17.1239 5.48304 18.9226 5.60134 20.2619C5.73493 21.7745 7.01357 22.9154 8.5294 22.9154H16.4732C17.989 22.9154 19.2677 21.7745 19.4013 20.2619C19.5196 18.9226 19.6629 17.1239 19.7162 15.7343C19.7649 14.4639 19.7647 12.8456 19.7527 11.5533C19.7467 10.9054 19.7376 10.3361 19.7301 9.92874C19.7263 9.725 19.723 9.56165 19.7205 9.44909L19.7176 9.31961L19.7165 9.27489C19.7057 8.83249 19.3359 8.48175 18.8907 8.49252C18.4454 8.5033 18.0933 8.87068 18.1041 9.31308L18.6179 9.30065C18.1041 9.31308 18.1041 9.31308 18.1041 9.31308L18.1051 9.35655L18.108 9.48375C18.1104 9.59473 18.1138 9.75632 18.1175 9.95814C18.1249 10.3618 18.1339 10.9261 18.1398 11.5681C18.1518 12.8557 18.1516 14.4426 18.1044 15.6733C18.0528 17.0207 17.9125 18.786 17.7945 20.1218C17.7351 20.7946 17.1653 21.3128 16.4732 21.3128H8.5294C7.83733 21.3128 7.26749 20.7946 7.20807 20.1218C7.09008 18.786 6.94979 17.0207 6.89816 15.6733C6.85099 14.4426 6.8508 12.8557 6.86276 11.5681C6.86873 10.9261 6.87767 10.3618 6.88513 9.95814C6.88885 9.75632 6.8922 9.59473 6.89462 9.48375L6.89747 9.35655L6.8985 9.31308L6.09229 9.29357Z"
                fill="#C41F30"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.16797 6.08844C4.16797 5.64591 4.52903 5.28716 4.97442 5.28716H20.0282C20.4736 5.28716 20.8346 5.64591 20.8346 6.08844C20.8346 6.53098 20.4736 6.88972 20.0282 6.88972H4.97442C4.52903 6.88972 4.16797 6.53098 4.16797 6.08844Z"
                fill="#C41F30"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.60187 3.63746C9.11578 2.68006 10.1189 2.08203 11.211 2.08203H13.7916C14.8837 2.08203 15.8868 2.68006 16.4007 3.63746L17.514 5.71137C17.7235 6.10184 17.5749 6.5872 17.1819 6.79545C16.7889 7.00371 16.3004 6.85599 16.0908 6.46552L14.9776 4.39161C14.744 3.95643 14.288 3.6846 13.7916 3.6846H11.211C10.7146 3.6846 10.2586 3.95643 10.025 4.39161L8.9118 6.46552C8.70221 6.85599 8.21371 7.00371 7.82072 6.79545C7.42773 6.5872 7.27906 6.10184 7.48865 5.71137L8.60187 3.63746Z"
                fill="#C41F30"
              />
            </svg>
          </button>
        </div>
      )}
      {title === "appointments" && (
        <div className="w-full flex flex-row justify-between">
          <div className="w-[211px] text-era-red text-base font-light items-center">
            {name}
          </div>
          <div className="w-[141px] text-era-red text-base font-light items-center ">
            {telnum}
          </div>
          <div className="w-[228px] text-era-red text-base font-light items-center ">
            {email}
          </div>
          <div className="w-[111px] text-era-red text-base font-light items-center ">
            {date}
          </div>
          <div className="w-[124px] text-era-red text-base font-light items-center ">
            {time}
          </div>
          <div className="w-[168px] text-era-red text-base font-light items-center ">
            {address}
          </div>
          <button
            onClick={async (e) => {
              try {
                e.stopPropagation();
                setLoading(true);
                await API.delete("/appointments/" + ID);
                Swal.fire({
                  title: "ลบข้อมูลเรียบร้อย",
                  icon: "success",
                }).then(() => {
                  reloadData();
                });
                reloadData();
              } catch (error) {
                console.log("product error", error);
                Swal.fire({
                  title: "ลบข้อมูลผิดพลาด",
                  icon: "warning",
                });
                setError(error);
              } finally {
                setLoading(false);
                reloadData();
              }
            }}
            className="text-era-red text-base font-light justify-self-end"
          >
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.11193 8.49252C6.55719 8.5033 6.90935 8.87068 6.8985 9.31308L6.09229 9.29357L5.28608 9.27405C5.29692 8.83165 5.66667 8.48175 6.11193 8.49252ZM6.09229 9.29357C5.28608 9.27405 5.28608 9.27405 5.28608 9.27405L5.285 9.31961L5.28209 9.44909C5.27964 9.56165 5.27626 9.725 5.27249 9.92874C5.26497 10.3361 5.25595 10.9054 5.24993 11.5533C5.23793 12.8456 5.23774 14.4639 5.28642 15.7343C5.33967 17.1239 5.48304 18.9226 5.60134 20.2619C5.73493 21.7745 7.01357 22.9154 8.5294 22.9154H16.4732C17.989 22.9154 19.2677 21.7745 19.4013 20.2619C19.5196 18.9226 19.6629 17.1239 19.7162 15.7343C19.7649 14.4639 19.7647 12.8456 19.7527 11.5533C19.7467 10.9054 19.7376 10.3361 19.7301 9.92874C19.7263 9.725 19.723 9.56165 19.7205 9.44909L19.7176 9.31961L19.7165 9.27489C19.7057 8.83249 19.3359 8.48175 18.8907 8.49252C18.4454 8.5033 18.0933 8.87068 18.1041 9.31308L18.6179 9.30065C18.1041 9.31308 18.1041 9.31308 18.1041 9.31308L18.1051 9.35655L18.108 9.48375C18.1104 9.59473 18.1138 9.75632 18.1175 9.95814C18.1249 10.3618 18.1339 10.9261 18.1398 11.5681C18.1518 12.8557 18.1516 14.4426 18.1044 15.6733C18.0528 17.0207 17.9125 18.786 17.7945 20.1218C17.7351 20.7946 17.1653 21.3128 16.4732 21.3128H8.5294C7.83733 21.3128 7.26749 20.7946 7.20807 20.1218C7.09008 18.786 6.94979 17.0207 6.89816 15.6733C6.85099 14.4426 6.8508 12.8557 6.86276 11.5681C6.86873 10.9261 6.87767 10.3618 6.88513 9.95814C6.88885 9.75632 6.8922 9.59473 6.89462 9.48375L6.89747 9.35655L6.8985 9.31308L6.09229 9.29357Z"
                fill="#C41F30"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.16797 6.08844C4.16797 5.64591 4.52903 5.28716 4.97442 5.28716H20.0282C20.4736 5.28716 20.8346 5.64591 20.8346 6.08844C20.8346 6.53098 20.4736 6.88972 20.0282 6.88972H4.97442C4.52903 6.88972 4.16797 6.53098 4.16797 6.08844Z"
                fill="#C41F30"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.60187 3.63746C9.11578 2.68006 10.1189 2.08203 11.211 2.08203H13.7916C14.8837 2.08203 15.8868 2.68006 16.4007 3.63746L17.514 5.71137C17.7235 6.10184 17.5749 6.5872 17.1819 6.79545C16.7889 7.00371 16.3004 6.85599 16.0908 6.46552L14.9776 4.39161C14.744 3.95643 14.288 3.6846 13.7916 3.6846H11.211C10.7146 3.6846 10.2586 3.95643 10.025 4.39161L8.9118 6.46552C8.70221 6.85599 8.21371 7.00371 7.82072 6.79545C7.42773 6.5872 7.27906 6.10184 7.48865 5.71137L8.60187 3.63746Z"
                fill="#C41F30"
              />
            </svg>
          </button>
          </div>
      )}
      {title === "career" && (
        <div className="w-full flex flex-row justify-between">
          <div className="w-[213px] text-era-red text-base font-light items-center">
            {name}
          </div>
          <div className="w-[156px] text-era-red text-base font-light items-center">
            {LineId}
          </div>
          <div className="w-[169px] text-era-red text-base font-light items-center ">
            {telnum}
          </div>
          <div className="w-[201px] text-era-red text-base font-light items-center ">
            {currentjob}
          </div>
          <div className="w-[372px] text-era-red text-base font-light items-center ">
            {livingArea}
          </div>
          <button
            onClick={async (e) => {
              try {
                e.stopPropagation();
                setLoading(true);
                await API.delete("/appliedjobs/" + ID);
                await Swal.fire({
                  title: "ลบข้อมูลเรียบร้อย",
                  icon: "success",
                });
              } catch (error) {
                console.log("product error", error);
                Swal.fire({
                  title: "ลบข้อมูลผิดพลาด",
                  icon: "warning",
                });
                setError(error);
              } finally {
                setLoading(false);
                reloadData();
              }
            }}
            className="text-era-red text-base font-light justify-self-end"
          >
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.11193 8.49252C6.55719 8.5033 6.90935 8.87068 6.8985 9.31308L6.09229 9.29357L5.28608 9.27405C5.29692 8.83165 5.66667 8.48175 6.11193 8.49252ZM6.09229 9.29357C5.28608 9.27405 5.28608 9.27405 5.28608 9.27405L5.285 9.31961L5.28209 9.44909C5.27964 9.56165 5.27626 9.725 5.27249 9.92874C5.26497 10.3361 5.25595 10.9054 5.24993 11.5533C5.23793 12.8456 5.23774 14.4639 5.28642 15.7343C5.33967 17.1239 5.48304 18.9226 5.60134 20.2619C5.73493 21.7745 7.01357 22.9154 8.5294 22.9154H16.4732C17.989 22.9154 19.2677 21.7745 19.4013 20.2619C19.5196 18.9226 19.6629 17.1239 19.7162 15.7343C19.7649 14.4639 19.7647 12.8456 19.7527 11.5533C19.7467 10.9054 19.7376 10.3361 19.7301 9.92874C19.7263 9.725 19.723 9.56165 19.7205 9.44909L19.7176 9.31961L19.7165 9.27489C19.7057 8.83249 19.3359 8.48175 18.8907 8.49252C18.4454 8.5033 18.0933 8.87068 18.1041 9.31308L18.6179 9.30065C18.1041 9.31308 18.1041 9.31308 18.1041 9.31308L18.1051 9.35655L18.108 9.48375C18.1104 9.59473 18.1138 9.75632 18.1175 9.95814C18.1249 10.3618 18.1339 10.9261 18.1398 11.5681C18.1518 12.8557 18.1516 14.4426 18.1044 15.6733C18.0528 17.0207 17.9125 18.786 17.7945 20.1218C17.7351 20.7946 17.1653 21.3128 16.4732 21.3128H8.5294C7.83733 21.3128 7.26749 20.7946 7.20807 20.1218C7.09008 18.786 6.94979 17.0207 6.89816 15.6733C6.85099 14.4426 6.8508 12.8557 6.86276 11.5681C6.86873 10.9261 6.87767 10.3618 6.88513 9.95814C6.88885 9.75632 6.8922 9.59473 6.89462 9.48375L6.89747 9.35655L6.8985 9.31308L6.09229 9.29357Z"
                fill="#C41F30"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.16797 6.08844C4.16797 5.64591 4.52903 5.28716 4.97442 5.28716H20.0282C20.4736 5.28716 20.8346 5.64591 20.8346 6.08844C20.8346 6.53098 20.4736 6.88972 20.0282 6.88972H4.97442C4.52903 6.88972 4.16797 6.53098 4.16797 6.08844Z"
                fill="#C41F30"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.60187 3.63746C9.11578 2.68006 10.1189 2.08203 11.211 2.08203H13.7916C14.8837 2.08203 15.8868 2.68006 16.4007 3.63746L17.514 5.71137C17.7235 6.10184 17.5749 6.5872 17.1819 6.79545C16.7889 7.00371 16.3004 6.85599 16.0908 6.46552L14.9776 4.39161C14.744 3.95643 14.288 3.6846 13.7916 3.6846H11.211C10.7146 3.6846 10.2586 3.95643 10.025 4.39161L8.9118 6.46552C8.70221 6.85599 8.21371 7.00371 7.82072 6.79545C7.42773 6.5872 7.27906 6.10184 7.48865 5.71137L8.60187 3.63746Z"
                fill="#C41F30"
              />
            </svg>
          </button>
        </div>
      )}
      {title === "review" && (
        <div className="w-full flex flex-row justify-between">
          <div className="w-[105px] text-era-red text-base font-light items-center">
            {order}
          </div>
          <div className="w-[280px] text-era-red text-base font-light items-center">
            {name}
          </div>
          <div className="w-[263px] flex space-x-[10px] text-era-red text-base font-light items-center ">
            {starNumber > 0 ? (
              <button>
                <StarIcon />
              </button>
            ) : (
              <button>
                <StarDarkIcon />
              </button>
            )}
            {starNumber > 1 ? (
              <button>
                <StarIcon />
              </button>
            ) : (
              <button>
                <StarDarkIcon />
              </button>
            )}
            {starNumber > 2 ? (
              <button>
                <StarIcon />
              </button>
            ) : (
              <button>
                <StarDarkIcon />
              </button>
            )}
            {starNumber > 3 ? (
              <button>
                <StarIcon />
              </button>
            ) : (
              <button>
                <StarDarkIcon />
              </button>
            )}
            {starNumber > 4 ? (
              <button>
                <StarIcon />
              </button>
            ) : (
              <button>
                <StarDarkIcon />
              </button>
            )}
          </div>
          <div className="w-[336px] text-era-red text-base font-light items-center">
            <div className="w-[255px] h-[42px] text-ellipsis overflow-hidden">
              {comment}
            </div>
          </div>
          <button
            onClick={async (e) => {
              try {
                e.stopPropagation();
                setLoading(true);
                await API.delete("/comments/" + ID);
                Swal.fire({
                  title: "ลบข้อมูลเรียบร้อย",
                  icon: "success",
                }).then(() => {
                  reloadData();
                });
                reloadData();
              } catch (error) {
                console.log("product error", error);
                Swal.fire({
                  title: "ลบข้อมูลผิดพลาด",
                  icon: "warning",
                });
                setError(error);
              } finally {
                setLoading(false);
                reloadData();
              }
            }}
            className="text-era-red text-base font-light justify-self-end"
          >
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.11193 8.49252C6.55719 8.5033 6.90935 8.87068 6.8985 9.31308L6.09229 9.29357L5.28608 9.27405C5.29692 8.83165 5.66667 8.48175 6.11193 8.49252ZM6.09229 9.29357C5.28608 9.27405 5.28608 9.27405 5.28608 9.27405L5.285 9.31961L5.28209 9.44909C5.27964 9.56165 5.27626 9.725 5.27249 9.92874C5.26497 10.3361 5.25595 10.9054 5.24993 11.5533C5.23793 12.8456 5.23774 14.4639 5.28642 15.7343C5.33967 17.1239 5.48304 18.9226 5.60134 20.2619C5.73493 21.7745 7.01357 22.9154 8.5294 22.9154H16.4732C17.989 22.9154 19.2677 21.7745 19.4013 20.2619C19.5196 18.9226 19.6629 17.1239 19.7162 15.7343C19.7649 14.4639 19.7647 12.8456 19.7527 11.5533C19.7467 10.9054 19.7376 10.3361 19.7301 9.92874C19.7263 9.725 19.723 9.56165 19.7205 9.44909L19.7176 9.31961L19.7165 9.27489C19.7057 8.83249 19.3359 8.48175 18.8907 8.49252C18.4454 8.5033 18.0933 8.87068 18.1041 9.31308L18.6179 9.30065C18.1041 9.31308 18.1041 9.31308 18.1041 9.31308L18.1051 9.35655L18.108 9.48375C18.1104 9.59473 18.1138 9.75632 18.1175 9.95814C18.1249 10.3618 18.1339 10.9261 18.1398 11.5681C18.1518 12.8557 18.1516 14.4426 18.1044 15.6733C18.0528 17.0207 17.9125 18.786 17.7945 20.1218C17.7351 20.7946 17.1653 21.3128 16.4732 21.3128H8.5294C7.83733 21.3128 7.26749 20.7946 7.20807 20.1218C7.09008 18.786 6.94979 17.0207 6.89816 15.6733C6.85099 14.4426 6.8508 12.8557 6.86276 11.5681C6.86873 10.9261 6.87767 10.3618 6.88513 9.95814C6.88885 9.75632 6.8922 9.59473 6.89462 9.48375L6.89747 9.35655L6.8985 9.31308L6.09229 9.29357Z"
                fill="#C41F30"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.16797 6.08844C4.16797 5.64591 4.52903 5.28716 4.97442 5.28716H20.0282C20.4736 5.28716 20.8346 5.64591 20.8346 6.08844C20.8346 6.53098 20.4736 6.88972 20.0282 6.88972H4.97442C4.52903 6.88972 4.16797 6.53098 4.16797 6.08844Z"
                fill="#C41F30"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.60187 3.63746C9.11578 2.68006 10.1189 2.08203 11.211 2.08203H13.7916C14.8837 2.08203 15.8868 2.68006 16.4007 3.63746L17.514 5.71137C17.7235 6.10184 17.5749 6.5872 17.1819 6.79545C16.7889 7.00371 16.3004 6.85599 16.0908 6.46552L14.9776 4.39161C14.744 3.95643 14.288 3.6846 13.7916 3.6846H11.211C10.7146 3.6846 10.2586 3.95643 10.025 4.39161L8.9118 6.46552C8.70221 6.85599 8.21371 7.00371 7.82072 6.79545C7.42773 6.5872 7.27906 6.10184 7.48865 5.71137L8.60187 3.63746Z"
                fill="#C41F30"
              />
            </svg>
          </button>
        </div>
      )}
      {title === "member" && (
        <div className="w-full flex flex-row justify-between">
          <div className="w-[149px] text-era-red text-base font-light items-center">
            <img
              src={S3_URL + "/" + Image}
              className="w-[44px] h-[44px] rounded-[44px]"
            />
            {/* <img src={S3_URL+"/"+Image} className="w-[44px] h-[44px]"/> */}
          </div>
          <div className="w-[204px] text-era-red text-base font-light items-center">
            {name}
          </div>
          <div className="w-[204px] text-era-red text-base font-light items-center">
            {nickname}
          </div>
          <div className="w-[309px] text-era-red text-base font-light items-center ">
            {telnum}
          </div>
          <button className="w-[97px] pl-[11px]">
            <PenSquare className="stroke-era-red w-[25px] h-[25px]" />
          </button>
          <button
            onClick={async (e) => {
              try {
                e.stopPropagation();
                setLoading(true);
                await API.delete("/member/" + ID);
                await Swal.fire({
                  title: "ลบข้อมูลเรียบร้อย",
                  icon: "success",
                });
              } catch (error) {
                console.log("product error", error);
                Swal.fire({
                  title: "ลบข้อมูลผิดพลาด",
                  icon: "warning",
                });
                setError(error);
              } finally {
                setLoading(false);
                reloadData();
              }
            }}
            className="text-era-red text-base font-light justify-self-end"
          >
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.11193 8.49252C6.55719 8.5033 6.90935 8.87068 6.8985 9.31308L6.09229 9.29357L5.28608 9.27405C5.29692 8.83165 5.66667 8.48175 6.11193 8.49252ZM6.09229 9.29357C5.28608 9.27405 5.28608 9.27405 5.28608 9.27405L5.285 9.31961L5.28209 9.44909C5.27964 9.56165 5.27626 9.725 5.27249 9.92874C5.26497 10.3361 5.25595 10.9054 5.24993 11.5533C5.23793 12.8456 5.23774 14.4639 5.28642 15.7343C5.33967 17.1239 5.48304 18.9226 5.60134 20.2619C5.73493 21.7745 7.01357 22.9154 8.5294 22.9154H16.4732C17.989 22.9154 19.2677 21.7745 19.4013 20.2619C19.5196 18.9226 19.6629 17.1239 19.7162 15.7343C19.7649 14.4639 19.7647 12.8456 19.7527 11.5533C19.7467 10.9054 19.7376 10.3361 19.7301 9.92874C19.7263 9.725 19.723 9.56165 19.7205 9.44909L19.7176 9.31961L19.7165 9.27489C19.7057 8.83249 19.3359 8.48175 18.8907 8.49252C18.4454 8.5033 18.0933 8.87068 18.1041 9.31308L18.6179 9.30065C18.1041 9.31308 18.1041 9.31308 18.1041 9.31308L18.1051 9.35655L18.108 9.48375C18.1104 9.59473 18.1138 9.75632 18.1175 9.95814C18.1249 10.3618 18.1339 10.9261 18.1398 11.5681C18.1518 12.8557 18.1516 14.4426 18.1044 15.6733C18.0528 17.0207 17.9125 18.786 17.7945 20.1218C17.7351 20.7946 17.1653 21.3128 16.4732 21.3128H8.5294C7.83733 21.3128 7.26749 20.7946 7.20807 20.1218C7.09008 18.786 6.94979 17.0207 6.89816 15.6733C6.85099 14.4426 6.8508 12.8557 6.86276 11.5681C6.86873 10.9261 6.87767 10.3618 6.88513 9.95814C6.88885 9.75632 6.8922 9.59473 6.89462 9.48375L6.89747 9.35655L6.8985 9.31308L6.09229 9.29357Z"
                fill="#C41F30"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.16797 6.08844C4.16797 5.64591 4.52903 5.28716 4.97442 5.28716H20.0282C20.4736 5.28716 20.8346 5.64591 20.8346 6.08844C20.8346 6.53098 20.4736 6.88972 20.0282 6.88972H4.97442C4.52903 6.88972 4.16797 6.53098 4.16797 6.08844Z"
                fill="#C41F30"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.60187 3.63746C9.11578 2.68006 10.1189 2.08203 11.211 2.08203H13.7916C14.8837 2.08203 15.8868 2.68006 16.4007 3.63746L17.514 5.71137C17.7235 6.10184 17.5749 6.5872 17.1819 6.79545C16.7889 7.00371 16.3004 6.85599 16.0908 6.46552L14.9776 4.39161C14.744 3.95643 14.288 3.6846 13.7916 3.6846H11.211C10.7146 3.6846 10.2586 3.95643 10.025 4.39161L8.9118 6.46552C8.70221 6.85599 8.21371 7.00371 7.82072 6.79545C7.42773 6.5872 7.27906 6.10184 7.48865 5.71137L8.60187 3.63746Z"
                fill="#C41F30"
              />
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};

export default Reqlist;
