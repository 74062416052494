import { ArchiveIcon } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";

const CategoryProduct = ({
  navto = "/product/show?category=singleHouse",
  icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-[40px] h-[40px] stroke-era-red self-center m-[10px]"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
      />
    </svg>
  ),
  label = "บ้านเดี่ยว",
  DisplayNumber = false,
  number = 0,
}) => {
  const theNav = useNavigate();

  return (
    <button
      onClick={() => {
        theNav(navto);
      }}
      className="flex flex-col justify-start w-[385px] h-[114px] item-center space-x-[6px] border-solid border-[1px] border-era-red px-[8px] py-[8px] duration-300 transform origin-center hover:scale-105"
      // className="flex justify-center w-[385px] h-[114px] item-center space-x-[6px] border-solid border-[1px] border-era-red px-[8px] py-[8px] duration-300 transform origin-center hover:scale-105"
    >
      {DisplayNumber && <div className="self-end px-[9px] min-w-[58px] h-[22px] flex flex-row space-x-[3px] justify-center items-center font-medium line-clamp-1 text-era-red text-xs border-solid border-[0.5px] border-era-red rounded-[13px]">
        <ArchiveIcon className="w-4 h-4 " />
        <div className="text-xs font-normal">{number}</div>
      </div>}
      <div className="w-full h-full flex justify-center items-center">
        {icon}
        <div className="w-fit text-xl font-semibold text-era-red self-center">
          {label}
        </div>
      </div>
    </button>
  );
};

export default CategoryProduct;
