export const ADDRESS_OPTIONS = [
    { value: "ต้า ขุนตาล เชียงราย 57340", label: "ต้า ขุนตาล เชียงราย 57340" },
    {
      value: "ป่าตาล ขุนตาล เชียงราย 57340",
      label: "ป่าตาล ขุนตาล เชียงราย 57340",
    },
    {
      value: "ยางฮอม ขุนตาล เชียงราย 57340",
      label: "ยางฮอม ขุนตาล เชียงราย 57340",
    },
    {
      value: "ปงน้อย ดอยหลวง เชียงราย 57110",
      label: "ปงน้อย ดอยหลวง เชียงราย 57110",
    },
    {
      value: "หนองป่าก่อ ดอยหลวง เชียงราย 57110",
      label: "หนองป่าก่อ ดอยหลวง เชียงราย 57110",
    },
    {
      value: "โชคชัย ดอยหลวง เชียงราย 57110",
      label: "โชคชัย ดอยหลวง เชียงราย 57110",
    },
    {
      value: "ป่าแงะ ป่าแดด เชียงราย 57190",
      label: "ป่าแงะ ป่าแดด เชียงราย 57190",
    },
    {
      value: "ป่าแดด ป่าแดด เชียงราย 57190",
      label: "ป่าแดด ป่าแดด เชียงราย 57190",
    },
    {
      value: "ศรีโพธิ์เงิน ป่าแดด เชียงราย 57190",
      label: "ศรีโพธิ์เงิน ป่าแดด เชียงราย 57190",
    },
    {
      value: "สันมะค่า ป่าแดด เชียงราย 57190",
      label: "สันมะค่า ป่าแดด เชียงราย 57190",
    },
    {
      value: "โรงช้าง ป่าแดด เชียงราย 57190",
      label: "โรงช้าง ป่าแดด เชียงราย 57190",
    },
    {
      value: "ตาดควัน พญาเม็งราย เชียงราย 57290",
      label: "ตาดควัน พญาเม็งราย เชียงราย 57290",
    },
    {
      value: "เม็งราย พญาเม็งราย เชียงราย 57290",
      label: "เม็งราย พญาเม็งราย เชียงราย 57290",
    },
    {
      value: "แม่ต๋ำ พญาเม็งราย เชียงราย 57290",
      label: "แม่ต๋ำ พญาเม็งราย เชียงราย 57290",
    },
    {
      value: "แม่เปา พญาเม็งราย เชียงราย 57290",
      label: "แม่เปา พญาเม็งราย เชียงราย 57290",
    },
    {
      value: "ไม้ยา พญาเม็งราย เชียงราย 57290",
      label: "ไม้ยา พญาเม็งราย เชียงราย 57290",
    },
    { value: "ดอยงาม พาน เชียงราย 57120", label: "ดอยงาม พาน เชียงราย 57120" },
    { value: "ทรายขาว พาน เชียงราย 57120", label: "ทรายขาว พาน เชียงราย 57120" },
    {
      value: "ทานตะวัน พาน เชียงราย 57280",
      label: "ทานตะวัน พาน เชียงราย 57280",
    },
    { value: "ธารทอง พาน เชียงราย 57250", label: "ธารทอง พาน เชียงราย 57250" },
    { value: "ป่าหุ่ง พาน เชียงราย 57120", label: "ป่าหุ่ง พาน เชียงราย 57120" },
    { value: "ม่วงคำ พาน เชียงราย 57120", label: "ม่วงคำ พาน เชียงราย 57120" },
    { value: "สันกลาง พาน เชียงราย 57120", label: "สันกลาง พาน เชียงราย 57120" },
    {
      value: "สันติสุข พาน เชียงราย 57120",
      label: "สันติสุข พาน เชียงราย 57120",
    },
    {
      value: "สันมะเค็ด พาน เชียงราย 57120",
      label: "สันมะเค็ด พาน เชียงราย 57120",
    },
    { value: "หัวง้ม พาน เชียงราย 57120", label: "หัวง้ม พาน เชียงราย 57120" },
    {
      value: "เจริญเมือง พาน เชียงราย 57120",
      label: "เจริญเมือง พาน เชียงราย 57120",
    },
    {
      value: "เมืองพาน พาน เชียงราย 57120",
      label: "เมืองพาน พาน เชียงราย 57120",
    },
    {
      value: "เวียงห้าว พาน เชียงราย 57120",
      label: "เวียงห้าว พาน เชียงราย 57120",
    },
    { value: "แม่อ้อ พาน เชียงราย 57120", label: "แม่อ้อ พาน เชียงราย 57120" },
    { value: "แม่เย็น พาน เชียงราย 57280", label: "แม่เย็น พาน เชียงราย 57280" },
    {
      value: "ครึ่ง เชียงของ เชียงราย 57140",
      label: "ครึ่ง เชียงของ เชียงราย 57140",
    },
    {
      value: "บุญเรือง เชียงของ เชียงราย 57140",
      label: "บุญเรือง เชียงของ เชียงราย 57140",
    },
    {
      value: "ริมโขง เชียงของ เชียงราย 57140",
      label: "ริมโขง เชียงของ เชียงราย 57140",
    },
    {
      value: "ศรีดอนชัย เชียงของ เชียงราย 57140",
      label: "ศรีดอนชัย เชียงของ เชียงราย 57140",
    },
    {
      value: "สถาน เชียงของ เชียงราย 57140",
      label: "สถาน เชียงของ เชียงราย 57140",
    },
    {
      value: "ห้วยซ้อ เชียงของ เชียงราย 57140",
      label: "ห้วยซ้อ เชียงของ เชียงราย 57140",
    },
    {
      value: "เวียง เชียงของ เชียงราย 57140",
      label: "เวียง เชียงของ เชียงราย 57140",
    },
    {
      value: "บ้านแซว เชียงแสน เชียงราย 57150",
      label: "บ้านแซว เชียงแสน เชียงราย 57150",
    },
    {
      value: "ป่าสัก เชียงแสน เชียงราย 57150",
      label: "ป่าสัก เชียงแสน เชียงราย 57150",
    },
    {
      value: "ศรีดอนมูล เชียงแสน เชียงราย 57150",
      label: "ศรีดอนมูล เชียงแสน เชียงราย 57150",
    },
    {
      value: "เวียง เชียงแสน เชียงราย 57150",
      label: "เวียง เชียงแสน เชียงราย 57150",
    },
    {
      value: "แม่เงิน เชียงแสน เชียงราย 57150",
      label: "แม่เงิน เชียงแสน เชียงราย 57150",
    },
    {
      value: "โยนก เชียงแสน เชียงราย 57150",
      label: "โยนก เชียงแสน เชียงราย 57150",
    },
    { value: "งิ้ว เทิง เชียงราย 57160", label: "งิ้ว เทิง เชียงราย 57160" },
    {
      value: "ตับเต่า เทิง เชียงราย 57160",
      label: "ตับเต่า เทิง เชียงราย 57160",
    },
    { value: "ปล้อง เทิง เชียงราย 57230", label: "ปล้อง เทิง เชียงราย 57230" },
    {
      value: "ศรีดอนไชย เทิง เชียงราย 57230",
      label: "ศรีดอนไชย เทิง เชียงราย 57230",
    },
    {
      value: "สันทรายงาม เทิง เชียงราย 57160",
      label: "สันทรายงาม เทิง เชียงราย 57160",
    },
    { value: "หงาว เทิง เชียงราย 57160", label: "หงาว เทิง เชียงราย 57160" },
    {
      value: "หนองแรด เทิง เชียงราย 57230",
      label: "หนองแรด เทิง เชียงราย 57230",
    },
    {
      value: "เชียงเคี่ยน เทิง เชียงราย 57230",
      label: "เชียงเคี่ยน เทิง เชียงราย 57230",
    },
    { value: "เวียง เทิง เชียงราย 57160", label: "เวียง เทิง เชียงราย 57160" },
    { value: "แม่ลอย เทิง เชียงราย 57230", label: "แม่ลอย เทิง เชียงราย 57230" },
    {
      value: "ดอยลาน เมืองเชียงราย เชียงราย 57000",
      label: "ดอยลาน เมืองเชียงราย เชียงราย 57000",
    },
    {
      value: "ดอยฮาง เมืองเชียงราย เชียงราย 57000",
      label: "ดอยฮาง เมืองเชียงราย เชียงราย 57000",
    },
    {
      value: "ท่าสาย เมืองเชียงราย เชียงราย 57000",
      label: "ท่าสาย เมืองเชียงราย เชียงราย 57000",
    },
    {
      value: "ท่าสุด เมืองเชียงราย เชียงราย 57100",
      label: "ท่าสุด เมืองเชียงราย เชียงราย 57100",
    },
    {
      value: "นางแล เมืองเชียงราย เชียงราย 57100",
      label: "นางแล เมืองเชียงราย เชียงราย 57100",
    },
    {
      value: "บ้านดู่ เมืองเชียงราย เชียงราย 57100",
      label: "บ้านดู่ เมืองเชียงราย เชียงราย 57100",
    },
    {
      value: "ป่าอ้อดอนชัย เมืองเชียงราย เชียงราย 57000",
      label: "ป่าอ้อดอนชัย เมืองเชียงราย เชียงราย 57000",
    },
    {
      value: "รอบเวียง เมืองเชียงราย เชียงราย 57000",
      label: "รอบเวียง เมืองเชียงราย เชียงราย 57000",
    },
    {
      value: "ริมกก เมืองเชียงราย เชียงราย 57100",
      label: "ริมกก เมืองเชียงราย เชียงราย 57100",
    },
    {
      value: "สันทราย เมืองเชียงราย เชียงราย 57000",
      label: "สันทราย เมืองเชียงราย เชียงราย 57000",
    },
    {
      value: "ห้วยชมภู เมืองเชียงราย เชียงราย 57000",
      label: "ห้วยชมภู เมืองเชียงราย เชียงราย 57000",
    },
    {
      value: "ห้วยสัก เมืองเชียงราย เชียงราย 57000",
      label: "ห้วยสัก เมืองเชียงราย เชียงราย 57000",
    },
    {
      value: "เวียง เมืองเชียงราย เชียงราย 57000",
      label: "เวียง เมืองเชียงราย เชียงราย 57000",
    },
    {
      value: "แม่กรณ์ เมืองเชียงราย เชียงราย 57000",
      label: "แม่กรณ์ เมืองเชียงราย เชียงราย 57000",
    },
    {
      value: "แม่ข้าวต้ม เมืองเชียงราย เชียงราย 57100",
      label: "แม่ข้าวต้ม เมืองเชียงราย เชียงราย 57100",
    },
    {
      value: "แม่ยาว เมืองเชียงราย เชียงราย 57100",
      label: "แม่ยาว เมืองเชียงราย เชียงราย 57100",
    },
    {
      value: "ดอนศิลา เวียงชัย เชียงราย 57210",
      label: "ดอนศิลา เวียงชัย เชียงราย 57210",
    },
    {
      value: "ผางาม เวียงชัย เชียงราย 57210",
      label: "ผางาม เวียงชัย เชียงราย 57210",
    },
    {
      value: "เมืองชุม เวียงชัย เชียงราย 57210",
      label: "เมืองชุม เวียงชัย เชียงราย 57210",
    },
    {
      value: "เวียงชัย เวียงชัย เชียงราย 57210",
      label: "เวียงชัย เวียงชัย เชียงราย 57210",
    },
    {
      value: "เวียงเหนือ เวียงชัย เชียงราย 57210",
      label: "เวียงเหนือ เวียงชัย เชียงราย 57210",
    },
    {
      value: "บ้านโป่ง เวียงป่าเป้า เชียงราย 57170",
      label: "บ้านโป่ง เวียงป่าเป้า เชียงราย 57170",
    },
    {
      value: "ป่างิ้ว เวียงป่าเป้า เชียงราย 57170",
      label: "ป่างิ้ว เวียงป่าเป้า เชียงราย 57170",
    },
    {
      value: "สันสลี เวียงป่าเป้า เชียงราย 57170",
      label: "สันสลี เวียงป่าเป้า เชียงราย 57170",
    },
    {
      value: "เวียง เวียงป่าเป้า เชียงราย 57170",
      label: "เวียง เวียงป่าเป้า เชียงราย 57170",
    },
    {
      value: "เวียงกาหลง เวียงป่าเป้า เชียงราย 57260",
      label: "เวียงกาหลง เวียงป่าเป้า เชียงราย 57260",
    },
    {
      value: "แม่เจดีย์ เวียงป่าเป้า เชียงราย 57260",
      label: "แม่เจดีย์ เวียงป่าเป้า เชียงราย 57260",
    },
    {
      value: "แม่เจดีย์ใหม่ เวียงป่าเป้า เชียงราย 57260",
      label: "แม่เจดีย์ใหม่ เวียงป่าเป้า เชียงราย 57260",
    },
    {
      value: "ดงมหาวัน เวียงเชียงรุ้ง เชียงราย 57210",
      label: "ดงมหาวัน เวียงเชียงรุ้ง เชียงราย 57210",
    },
    {
      value: "ทุ่งก่อ เวียงเชียงรุ้ง เชียงราย 57210",
      label: "ทุ่งก่อ เวียงเชียงรุ้ง เชียงราย 57210",
    },
    {
      value: "ป่าซาง เวียงเชียงรุ้ง เชียงราย 57210",
      label: "ป่าซาง เวียงเชียงรุ้ง เชียงราย 57210",
    },
    {
      value: "ท่าข้าม เวียงแก่น เชียงราย 57310",
      label: "ท่าข้าม เวียงแก่น เชียงราย 57310",
    },
    {
      value: "ปอ เวียงแก่น เชียงราย 57310",
      label: "ปอ เวียงแก่น เชียงราย 57310",
    },
    {
      value: "ม่วงยาย เวียงแก่น เชียงราย 57310",
      label: "ม่วงยาย เวียงแก่น เชียงราย 57310",
    },
    {
      value: "หล่ายงาว เวียงแก่น เชียงราย 57310",
      label: "หล่ายงาว เวียงแก่น เชียงราย 57310",
    },
    {
      value: "จอมสวรรค์ แม่จัน เชียงราย 57110",
      label: "จอมสวรรค์ แม่จัน เชียงราย 57110",
    },
    {
      value: "จันจว้า แม่จัน เชียงราย 57270",
      label: "จันจว้า แม่จัน เชียงราย 57270",
    },
    {
      value: "จันจว้าใต้ แม่จัน เชียงราย 57270",
      label: "จันจว้าใต้ แม่จัน เชียงราย 57270",
    },
    {
      value: "ท่าข้าวเปลือก แม่จัน เชียงราย 57110",
      label: "ท่าข้าวเปลือก แม่จัน เชียงราย 57110",
    },
    {
      value: "ป่าซาง แม่จัน เชียงราย 57110",
      label: "ป่าซาง แม่จัน เชียงราย 57110",
    },
    {
      value: "ป่าตึง แม่จัน เชียงราย 57110",
      label: "ป่าตึง แม่จัน เชียงราย 57110",
    },
    {
      value: "ศรีค้ำ แม่จัน เชียงราย 57110",
      label: "ศรีค้ำ แม่จัน เชียงราย 57110",
    },
    {
      value: "สันทราย แม่จัน เชียงราย 57110",
      label: "สันทราย แม่จัน เชียงราย 57110",
    },
    {
      value: "แม่คำ แม่จัน เชียงราย 57240",
      label: "แม่คำ แม่จัน เชียงราย 57240",
    },
    {
      value: "แม่จัน แม่จัน เชียงราย 57110",
      label: "แม่จัน แม่จัน เชียงราย 57110",
    },
    {
      value: "แม่ไร่ แม่จัน เชียงราย 57240",
      label: "แม่ไร่ แม่จัน เชียงราย 57240",
    },
    {
      value: "เทอดไทย แม่ฟ้าหลวง เชียงราย 57240",
      label: "เทอดไทย แม่ฟ้าหลวง เชียงราย 57240",
    },
    {
      value: "แม่ฟ้าหลวง แม่ฟ้าหลวง เชียงราย 57240",
      label: "แม่ฟ้าหลวง แม่ฟ้าหลวง เชียงราย 57240",
    },
    {
      value: "แม่สลองนอก แม่ฟ้าหลวง เชียงราย 57110",
      label: "แม่สลองนอก แม่ฟ้าหลวง เชียงราย 57110",
    },
    {
      value: "แม่สลองใน แม่ฟ้าหลวง เชียงราย 57110",
      label: "แม่สลองใน แม่ฟ้าหลวง เชียงราย 57110",
    },
    {
      value: "จอมหมอกแก้ว แม่ลาว เชียงราย 57250",
      label: "จอมหมอกแก้ว แม่ลาว เชียงราย 57250",
    },
    {
      value: "ดงมะดะ แม่ลาว เชียงราย 57250",
      label: "ดงมะดะ แม่ลาว เชียงราย 57250",
    },
    {
      value: "บัวสลี แม่ลาว เชียงราย 57250",
      label: "บัวสลี แม่ลาว เชียงราย 57250",
    },
    {
      value: "ป่าก่อดำ แม่ลาว เชียงราย 57250",
      label: "ป่าก่อดำ แม่ลาว เชียงราย 57250",
    },
    {
      value: "โป่งแพร่ แม่ลาว เชียงราย 57000",
      label: "โป่งแพร่ แม่ลาว เชียงราย 57000",
    },
    {
      value: "ท่าก๊อ แม่สรวย เชียงราย 57180",
      label: "ท่าก๊อ แม่สรวย เชียงราย 57180",
    },
    {
      value: "ป่าแดด แม่สรวย เชียงราย 57180",
      label: "ป่าแดด แม่สรวย เชียงราย 57180",
    },
    {
      value: "วาวี แม่สรวย เชียงราย 57180",
      label: "วาวี แม่สรวย เชียงราย 57180",
    },
    {
      value: "ศรีถ้อย แม่สรวย เชียงราย 57180",
      label: "ศรีถ้อย แม่สรวย เชียงราย 57180",
    },
    {
      value: "เจดีย์หลวง แม่สรวย เชียงราย 57180",
      label: "เจดีย์หลวง แม่สรวย เชียงราย 57180",
    },
    {
      value: "แม่พริก แม่สรวย เชียงราย 57180",
      label: "แม่พริก แม่สรวย เชียงราย 57180",
    },
    {
      value: "แม่สรวย แม่สรวย เชียงราย 57180",
      label: "แม่สรวย แม่สรวย เชียงราย 57180",
    },
    {
      value: "บ้านด้าย แม่สาย เชียงราย 57220",
      label: "บ้านด้าย แม่สาย เชียงราย 57220",
    },
    {
      value: "ศรีเมืองชุม แม่สาย เชียงราย 57130",
      label: "ศรีเมืองชุม แม่สาย เชียงราย 57130",
    },
    {
      value: "ห้วยไคร้ แม่สาย เชียงราย 57220",
      label: "ห้วยไคร้ แม่สาย เชียงราย 57220",
    },
    {
      value: "เกาะช้าง แม่สาย เชียงราย 57130",
      label: "เกาะช้าง แม่สาย เชียงราย 57130",
    },
    {
      value: "เวียงพางคำ แม่สาย เชียงราย 57130",
      label: "เวียงพางคำ แม่สาย เชียงราย 57130",
    },
    {
      value: "แม่สาย แม่สาย เชียงราย 57130",
      label: "แม่สาย แม่สาย เชียงราย 57130",
    },
    {
      value: "โป่งงาม แม่สาย เชียงราย 57130",
      label: "โป่งงาม แม่สาย เชียงราย 57130",
    },
    {
      value: "โป่งผา แม่สาย เชียงราย 57130",
      label: "โป่งผา แม่สาย เชียงราย 57130",
    },
    {
      value: "บ้านจันทร์ กัลยาณิวัฒนา เชียงใหม่ 58130",
      label: "บ้านจันทร์ กัลยาณิวัฒนา เชียงใหม่ 58130",
    },
    {
      value: "แจ่มหลวง กัลยาณิวัฒนา เชียงใหม่ 58130",
      label: "แจ่มหลวง กัลยาณิวัฒนา เชียงใหม่ 58130",
    },
    {
      value: "แม่แดด กัลยาณิวัฒนา เชียงใหม่ 58130",
      label: "แม่แดด กัลยาณิวัฒนา เชียงใหม่ 58130",
    },
    {
      value: "ข่วงเปา จอมทอง เชียงใหม่ 50160",
      label: "ข่วงเปา จอมทอง เชียงใหม่ 50160",
    },
    {
      value: "ดอยแก้ว จอมทอง เชียงใหม่ 50160",
      label: "ดอยแก้ว จอมทอง เชียงใหม่ 50160",
    },
    {
      value: "บ้านหลวง จอมทอง เชียงใหม่ 50160",
      label: "บ้านหลวง จอมทอง เชียงใหม่ 50160",
    },
    {
      value: "บ้านแปะ จอมทอง เชียงใหม่ 50240",
      label: "บ้านแปะ จอมทอง เชียงใหม่ 50240",
    },
    {
      value: "สบเตี๊ยะ จอมทอง เชียงใหม่ 50160",
      label: "สบเตี๊ยะ จอมทอง เชียงใหม่ 50160",
    },
    {
      value: "แม่สอย จอมทอง เชียงใหม่ 50240",
      label: "แม่สอย จอมทอง เชียงใหม่ 50240",
    },
    {
      value: "ตลาดขวัญ ดอยสะเก็ด เชียงใหม่ 50220",
      label: "ตลาดขวัญ ดอยสะเก็ด เชียงใหม่ 50220",
    },
    {
      value: "ตลาดใหญ่ ดอยสะเก็ด เชียงใหม่ 50220",
      label: "ตลาดใหญ่ ดอยสะเก็ด เชียงใหม่ 50220",
    },
    {
      value: "ป่าป้อง ดอยสะเก็ด เชียงใหม่ 50220",
      label: "ป่าป้อง ดอยสะเก็ด เชียงใหม่ 50220",
    },
    {
      value: "ป่าลาน ดอยสะเก็ด เชียงใหม่ 50220",
      label: "ป่าลาน ดอยสะเก็ด เชียงใหม่ 50220",
    },
    {
      value: "ป่าเมี่ยง ดอยสะเก็ด เชียงใหม่ 50220",
      label: "ป่าเมี่ยง ดอยสะเก็ด เชียงใหม่ 50220",
    },
    {
      value: "ลวงเหนือ ดอยสะเก็ด เชียงใหม่ 50220",
      label: "ลวงเหนือ ดอยสะเก็ด เชียงใหม่ 50220",
    },
    {
      value: "สง่าบ้าน ดอยสะเก็ด เชียงใหม่ 50220",
      label: "สง่าบ้าน ดอยสะเก็ด เชียงใหม่ 50220",
    },
    {
      value: "สันปูเลย ดอยสะเก็ด เชียงใหม่ 50220",
      label: "สันปูเลย ดอยสะเก็ด เชียงใหม่ 50220",
    },
    {
      value: "สำราญราษฎร์ ดอยสะเก็ด เชียงใหม่ 50220",
      label: "สำราญราษฎร์ ดอยสะเก็ด เชียงใหม่ 50220",
    },
    {
      value: "เชิงดอย ดอยสะเก็ด เชียงใหม่ 50220",
      label: "เชิงดอย ดอยสะเก็ด เชียงใหม่ 50220",
    },
    {
      value: "เทพเสด็จ ดอยสะเก็ด เชียงใหม่ 50220",
      label: "เทพเสด็จ ดอยสะเก็ด เชียงใหม่ 50220",
    },
    {
      value: "แม่คือ ดอยสะเก็ด เชียงใหม่ 50220",
      label: "แม่คือ ดอยสะเก็ด เชียงใหม่ 50220",
    },
    {
      value: "แม่ฮ้อยเงิน ดอยสะเก็ด เชียงใหม่ 50220",
      label: "แม่ฮ้อยเงิน ดอยสะเก็ด เชียงใหม่ 50220",
    },
    {
      value: "แม่โป่ง ดอยสะเก็ด เชียงใหม่ 50220",
      label: "แม่โป่ง ดอยสะเก็ด เชียงใหม่ 50220",
    },
    {
      value: "ดอยหล่อ ดอยหล่อ เชียงใหม่ 50160",
      label: "ดอยหล่อ ดอยหล่อ เชียงใหม่ 50160",
    },
    {
      value: "ยางคราม ดอยหล่อ เชียงใหม่ 50160",
      label: "ยางคราม ดอยหล่อ เชียงใหม่ 50160",
    },
    {
      value: "สองแคว ดอยหล่อ เชียงใหม่ 50160",
      label: "สองแคว ดอยหล่อ เชียงใหม่ 50160",
    },
    {
      value: "สันติสุข ดอยหล่อ เชียงใหม่ 50160",
      label: "สันติสุข ดอยหล่อ เชียงใหม่ 50160",
    },
    {
      value: "ดอยเต่า ดอยเต่า เชียงใหม่ 50260",
      label: "ดอยเต่า ดอยเต่า เชียงใหม่ 50260",
    },
    {
      value: "ท่าเดื่อ ดอยเต่า เชียงใหม่ 50260",
      label: "ท่าเดื่อ ดอยเต่า เชียงใหม่ 50260",
    },
    {
      value: "บงตัน ดอยเต่า เชียงใหม่ 50260",
      label: "บงตัน ดอยเต่า เชียงใหม่ 50260",
    },
    {
      value: "บ้านแอ่น ดอยเต่า เชียงใหม่ 50260",
      label: "บ้านแอ่น ดอยเต่า เชียงใหม่ 50260",
    },
    {
      value: "มืดกา ดอยเต่า เชียงใหม่ 50260",
      label: "มืดกา ดอยเต่า เชียงใหม่ 50260",
    },
    {
      value: "โปงทุ่ง ดอยเต่า เชียงใหม่ 50260",
      label: "โปงทุ่ง ดอยเต่า เชียงใหม่ 50260",
    },
    {
      value: "ม่อนปิ่น ฝาง เชียงใหม่ 50110",
      label: "ม่อนปิ่น ฝาง เชียงใหม่ 50110",
    },
    {
      value: "สันทราย ฝาง เชียงใหม่ 50110",
      label: "สันทราย ฝาง เชียงใหม่ 50110",
    },
    { value: "เวียง ฝาง เชียงใหม่ 50110", label: "เวียง ฝาง เชียงใหม่ 50110" },
    { value: "แม่ข่า ฝาง เชียงใหม่ 50320", label: "แม่ข่า ฝาง เชียงใหม่ 50320" },
    { value: "แม่คะ ฝาง เชียงใหม่ 50110", label: "แม่คะ ฝาง เชียงใหม่ 50110" },
    { value: "แม่งอน ฝาง เชียงใหม่ 50320", label: "แม่งอน ฝาง เชียงใหม่ 50320" },
    { value: "แม่สูน ฝาง เชียงใหม่ 50110", label: "แม่สูน ฝาง เชียงใหม่ 50110" },
    {
      value: "โป่งน้ำร้อน ฝาง เชียงใหม่ 50110",
      label: "โป่งน้ำร้อน ฝาง เชียงใหม่ 50110",
    },
    {
      value: "ทุ่งหลวง พร้าว เชียงใหม่ 50190",
      label: "ทุ่งหลวง พร้าว เชียงใหม่ 50190",
    },
    {
      value: "น้ำแพร่ พร้าว เชียงใหม่ 50190",
      label: "น้ำแพร่ พร้าว เชียงใหม่ 50190",
    },
    {
      value: "บ้านโป่ง พร้าว เชียงใหม่ 50190",
      label: "บ้านโป่ง พร้าว เชียงใหม่ 50190",
    },
    {
      value: "ป่าตุ้ม พร้าว เชียงใหม่ 50190",
      label: "ป่าตุ้ม พร้าว เชียงใหม่ 50190",
    },
    {
      value: "ป่าไหน่ พร้าว เชียงใหม่ 50190",
      label: "ป่าไหน่ พร้าว เชียงใหม่ 50190",
    },
    {
      value: "สันทราย พร้าว เชียงใหม่ 50190",
      label: "สันทราย พร้าว เชียงใหม่ 50190",
    },
    {
      value: "เขื่อนผาก พร้าว เชียงใหม่ 50190",
      label: "เขื่อนผาก พร้าว เชียงใหม่ 50190",
    },
    {
      value: "เวียง พร้าว เชียงใหม่ 50190",
      label: "เวียง พร้าว เชียงใหม่ 50190",
    },
    {
      value: "แม่ปั๋ง พร้าว เชียงใหม่ 50190",
      label: "แม่ปั๋ง พร้าว เชียงใหม่ 50190",
    },
    {
      value: "แม่แวน พร้าว เชียงใหม่ 50190",
      label: "แม่แวน พร้าว เชียงใหม่ 50190",
    },
    {
      value: "โหล่งขอด พร้าว เชียงใหม่ 50190",
      label: "โหล่งขอด พร้าว เชียงใหม่ 50190",
    },
    {
      value: "บ่อแก้ว สะเมิง เชียงใหม่ 50250",
      label: "บ่อแก้ว สะเมิง เชียงใหม่ 50250",
    },
    {
      value: "ยั้งเมิน สะเมิง เชียงใหม่ 50250",
      label: "ยั้งเมิน สะเมิง เชียงใหม่ 50250",
    },
    {
      value: "สะเมิงเหนือ สะเมิง เชียงใหม่ 50250",
      label: "สะเมิงเหนือ สะเมิง เชียงใหม่ 50250",
    },
    {
      value: "สะเมิงใต้ สะเมิง เชียงใหม่ 50250",
      label: "สะเมิงใต้ สะเมิง เชียงใหม่ 50250",
    },
    {
      value: "แม่สาบ สะเมิง เชียงใหม่ 50250",
      label: "แม่สาบ สะเมิง เชียงใหม่ 50250",
    },
    {
      value: "ต้นเปา สันกำแพง เชียงใหม่ 50130",
      label: "ต้นเปา สันกำแพง เชียงใหม่ 50130",
    },
    {
      value: "ทรายมูล สันกำแพง เชียงใหม่ 50130",
      label: "ทรายมูล สันกำแพง เชียงใหม่ 50130",
    },
    {
      value: "บวกค้าง สันกำแพง เชียงใหม่ 50130",
      label: "บวกค้าง สันกำแพง เชียงใหม่ 50130",
    },
    {
      value: "ร้องวัวแดง สันกำแพง เชียงใหม่ 50130",
      label: "ร้องวัวแดง สันกำแพง เชียงใหม่ 50130",
    },
    {
      value: "สันกลาง สันกำแพง เชียงใหม่ 50130",
      label: "สันกลาง สันกำแพง เชียงใหม่ 50130",
    },
    {
      value: "สันกำแพง สันกำแพง เชียงใหม่ 50130",
      label: "สันกำแพง สันกำแพง เชียงใหม่ 50130",
    },
    {
      value: "ห้วยทราย สันกำแพง เชียงใหม่ 50130",
      label: "ห้วยทราย สันกำแพง เชียงใหม่ 50130",
    },
    {
      value: "ออนใต้ สันกำแพง เชียงใหม่ 50130",
      label: "ออนใต้ สันกำแพง เชียงใหม่ 50130",
    },
    {
      value: "แช่ช้าง สันกำแพง เชียงใหม่ 50130",
      label: "แช่ช้าง สันกำแพง เชียงใหม่ 50130",
    },
    {
      value: "แม่ปูคา สันกำแพง เชียงใหม่ 50130",
      label: "แม่ปูคา สันกำแพง เชียงใหม่ 50130",
    },
    {
      value: "ป่าไผ่ สันทราย เชียงใหม่ 50210",
      label: "ป่าไผ่ สันทราย เชียงใหม่ 50210",
    },
    {
      value: "สันทรายน้อย สันทราย เชียงใหม่ 50210",
      label: "สันทรายน้อย สันทราย เชียงใหม่ 50210",
    },
    {
      value: "สันทรายหลวง สันทราย เชียงใหม่ 50210",
      label: "สันทรายหลวง สันทราย เชียงใหม่ 50210",
    },
    {
      value: "สันนาเม็ง สันทราย เชียงใหม่ 50210",
      label: "สันนาเม็ง สันทราย เชียงใหม่ 50210",
    },
    {
      value: "สันป่าเปา สันทราย เชียงใหม่ 50210",
      label: "สันป่าเปา สันทราย เชียงใหม่ 50210",
    },
    {
      value: "สันพระเนตร สันทราย เชียงใหม่ 50210",
      label: "สันพระเนตร สันทราย เชียงใหม่ 50210",
    },
    {
      value: "หนองจ๊อม สันทราย เชียงใหม่ 50210",
      label: "หนองจ๊อม สันทราย เชียงใหม่ 50210",
    },
    {
      value: "หนองหาร สันทราย เชียงใหม่ 50290",
      label: "หนองหาร สันทราย เชียงใหม่ 50290",
    },
    {
      value: "หนองแหย่ง สันทราย เชียงใหม่ 50210",
      label: "หนองแหย่ง สันทราย เชียงใหม่ 50210",
    },
    {
      value: "เมืองเล็น สันทราย เชียงใหม่ 50210",
      label: "เมืองเล็น สันทราย เชียงใหม่ 50210",
    },
    {
      value: "แม่แฝก สันทราย เชียงใหม่ 50290",
      label: "แม่แฝก สันทราย เชียงใหม่ 50290",
    },
    {
      value: "แม่แฝกใหม่ สันทราย เชียงใหม่ 50290",
      label: "แม่แฝกใหม่ สันทราย เชียงใหม่ 50290",
    },
    {
      value: "ทุ่งต้อม สันป่าตอง เชียงใหม่ 50120",
      label: "ทุ่งต้อม สันป่าตอง เชียงใหม่ 50120",
    },
    {
      value: "ทุ่งสะโตก สันป่าตอง เชียงใหม่ 50120",
      label: "ทุ่งสะโตก สันป่าตอง เชียงใหม่ 50120",
    },
    {
      value: "ท่าวังพร้าว สันป่าตอง เชียงใหม่ 50120",
      label: "ท่าวังพร้าว สันป่าตอง เชียงใหม่ 50120",
    },
    {
      value: "น้ำบ่อหลวง สันป่าตอง เชียงใหม่ 50120",
      label: "น้ำบ่อหลวง สันป่าตอง เชียงใหม่ 50120",
    },
    {
      value: "บ้านกลาง สันป่าตอง เชียงใหม่ 50120",
      label: "บ้านกลาง สันป่าตอง เชียงใหม่ 50120",
    },
    {
      value: "บ้านแม สันป่าตอง เชียงใหม่ 50120",
      label: "บ้านแม สันป่าตอง เชียงใหม่ 50120",
    },
    {
      value: "มะขามหลวง สันป่าตอง เชียงใหม่ 50120",
      label: "มะขามหลวง สันป่าตอง เชียงใหม่ 50120",
    },
    {
      value: "มะขุนหวาน สันป่าตอง เชียงใหม่ 50120",
      label: "มะขุนหวาน สันป่าตอง เชียงใหม่ 50120",
    },
    {
      value: "ยุหว่า สันป่าตอง เชียงใหม่ 50120",
      label: "ยุหว่า สันป่าตอง เชียงใหม่ 50120",
    },
    {
      value: "สันกลาง สันป่าตอง เชียงใหม่ 50120",
      label: "สันกลาง สันป่าตอง เชียงใหม่ 50120",
    },
    {
      value: "แม่ก๊า สันป่าตอง เชียงใหม่ 50120",
      label: "แม่ก๊า สันป่าตอง เชียงใหม่ 50120",
    },
    {
      value: "ขัวมุง สารภี เชียงใหม่ 50140",
      label: "ขัวมุง สารภี เชียงใหม่ 50140",
    },
    { value: "ชมภู สารภี เชียงใหม่ 50140", label: "ชมภู สารภี เชียงใหม่ 50140" },
    {
      value: "ดอนแก้ว สารภี เชียงใหม่ 50140",
      label: "ดอนแก้ว สารภี เชียงใหม่ 50140",
    },
    {
      value: "ท่ากว้าง สารภี เชียงใหม่ 50140",
      label: "ท่ากว้าง สารภี เชียงใหม่ 50140",
    },
    {
      value: "ท่าวังตาล สารภี เชียงใหม่ 50140",
      label: "ท่าวังตาล สารภี เชียงใหม่ 50140",
    },
    {
      value: "ป่าบง สารภี เชียงใหม่ 50140",
      label: "ป่าบง สารภี เชียงใหม่ 50140",
    },
    {
      value: "ยางเนิ้ง สารภี เชียงใหม่ 50140",
      label: "ยางเนิ้ง สารภี เชียงใหม่ 50140",
    },
    {
      value: "สันทราย สารภี เชียงใหม่ 50140",
      label: "สันทราย สารภี เชียงใหม่ 50140",
    },
    {
      value: "สารภี สารภี เชียงใหม่ 50140",
      label: "สารภี สารภี เชียงใหม่ 50140",
    },
    {
      value: "หนองผึ้ง สารภี เชียงใหม่ 50140",
      label: "หนองผึ้ง สารภี เชียงใหม่ 50140",
    },
    {
      value: "หนองแฝก สารภี เชียงใหม่ 50140",
      label: "หนองแฝก สารภี เชียงใหม่ 50140",
    },
    {
      value: "ไชยสถาน สารภี เชียงใหม่ 50140",
      label: "ไชยสถาน สารภี เชียงใหม่ 50140",
    },
    {
      value: "ขุนคง หางดง เชียงใหม่ 50230",
      label: "ขุนคง หางดง เชียงใหม่ 50230",
    },
    {
      value: "น้ำแพร่ หางดง เชียงใหม่ 50230",
      label: "น้ำแพร่ หางดง เชียงใหม่ 50230",
    },
    {
      value: "บ้านปง หางดง เชียงใหม่ 50230",
      label: "บ้านปง หางดง เชียงใหม่ 50230",
    },
    {
      value: "บ้านแหวน หางดง เชียงใหม่ 50230",
      label: "บ้านแหวน หางดง เชียงใหม่ 50230",
    },
    {
      value: "สบแม่ข่า หางดง เชียงใหม่ 50230",
      label: "สบแม่ข่า หางดง เชียงใหม่ 50230",
    },
    {
      value: "สันผักหวาน หางดง เชียงใหม่ 50230",
      label: "สันผักหวาน หางดง เชียงใหม่ 50230",
    },
    {
      value: "หนองควาย หางดง เชียงใหม่ 50230",
      label: "หนองควาย หางดง เชียงใหม่ 50230",
    },
    {
      value: "หนองตอง หางดง เชียงใหม่ 50340",
      label: "หนองตอง หางดง เชียงใหม่ 50340",
    },
    {
      value: "หนองแก๋ว หางดง เชียงใหม่ 50230",
      label: "หนองแก๋ว หางดง เชียงใหม่ 50230",
    },
    {
      value: "หางดง หางดง เชียงใหม่ 50230",
      label: "หางดง หางดง เชียงใหม่ 50230",
    },
    {
      value: "หารแก้ว หางดง เชียงใหม่ 50230",
      label: "หารแก้ว หางดง เชียงใหม่ 50230",
    },
    {
      value: "นาเกียน อมก๋อย เชียงใหม่ 50310",
      label: "นาเกียน อมก๋อย เชียงใหม่ 50310",
    },
    {
      value: "ม่อนจอง อมก๋อย เชียงใหม่ 50310",
      label: "ม่อนจอง อมก๋อย เชียงใหม่ 50310",
    },
    {
      value: "ยางเปียง อมก๋อย เชียงใหม่ 50310",
      label: "ยางเปียง อมก๋อย เชียงใหม่ 50310",
    },
    {
      value: "สบโขง อมก๋อย เชียงใหม่ 50310",
      label: "สบโขง อมก๋อย เชียงใหม่ 50310",
    },
    {
      value: "อมก๋อย อมก๋อย เชียงใหม่ 50310",
      label: "อมก๋อย อมก๋อย เชียงใหม่ 50310",
    },
    {
      value: "แม่ตื่น อมก๋อย เชียงใหม่ 50310",
      label: "แม่ตื่น อมก๋อย เชียงใหม่ 50310",
    },
    {
      value: "นาคอเรือ ฮอด เชียงใหม่ 50240",
      label: "นาคอเรือ ฮอด เชียงใหม่ 50240",
    },
    { value: "บ่อสลี ฮอด เชียงใหม่ 50240", label: "บ่อสลี ฮอด เชียงใหม่ 50240" },
    {
      value: "บ่อหลวง ฮอด เชียงใหม่ 50240",
      label: "บ่อหลวง ฮอด เชียงใหม่ 50240",
    },
    {
      value: "บ้านตาล ฮอด เชียงใหม่ 50240",
      label: "บ้านตาล ฮอด เชียงใหม่ 50240",
    },
    { value: "หางดง ฮอด เชียงใหม่ 50240", label: "หางดง ฮอด เชียงใหม่ 50240" },
    { value: "ฮอด ฮอด เชียงใหม่ 50240", label: "ฮอด ฮอด เชียงใหม่ 50240" },
    {
      value: "ทุ่งข้าวพวง เชียงดาว เชียงใหม่ 50170",
      label: "ทุ่งข้าวพวง เชียงดาว เชียงใหม่ 50170",
    },
    {
      value: "ปิงโค้ง เชียงดาว เชียงใหม่ 50170",
      label: "ปิงโค้ง เชียงดาว เชียงใหม่ 50170",
    },
    {
      value: "เชียงดาว เชียงดาว เชียงใหม่ 50170",
      label: "เชียงดาว เชียงดาว เชียงใหม่ 50170",
    },
    {
      value: "เมืองคอง เชียงดาว เชียงใหม่ 50170",
      label: "เมืองคอง เชียงดาว เชียงใหม่ 50170",
    },
    {
      value: "เมืองงาย เชียงดาว เชียงใหม่ 50170",
      label: "เมืองงาย เชียงดาว เชียงใหม่ 50170",
    },
    {
      value: "เมืองนะ เชียงดาว เชียงใหม่ 50170",
      label: "เมืองนะ เชียงดาว เชียงใหม่ 50170",
    },
    {
      value: "แม่นะ เชียงดาว เชียงใหม่ 50170",
      label: "แม่นะ เชียงดาว เชียงใหม่ 50170",
    },
    {
      value: "ช้างคลาน เมืองเชียงใหม่ เชียงใหม่ 50100",
      label: "ช้างคลาน เมืองเชียงใหม่ เชียงใหม่ 50100",
    },
    {
      value: "ช้างม่อย เมืองเชียงใหม่ เชียงใหม่ 50300",
      label: "ช้างม่อย เมืองเชียงใหม่ เชียงใหม่ 50300",
    },
    {
      value: "ช้างเผือก เมืองเชียงใหม่ เชียงใหม่ 50300",
      label: "ช้างเผือก เมืองเชียงใหม่ เชียงใหม่ 50300",
    },
    {
      value: "ท่าศาลา เมืองเชียงใหม่ เชียงใหม่ 50000",
      label: "ท่าศาลา เมืองเชียงใหม่ เชียงใหม่ 50000",
    },
    {
      value: "ป่าตัน เมืองเชียงใหม่ เชียงใหม่ 50300",
      label: "ป่าตัน เมืองเชียงใหม่ เชียงใหม่ 50300",
    },
    {
      value: "ป่าแดด เมืองเชียงใหม่ เชียงใหม่ 50100",
      label: "ป่าแดด เมืองเชียงใหม่ เชียงใหม่ 50100",
    },
    {
      value: "พระสิงห์ เมืองเชียงใหม่ เชียงใหม่ 50200",
      label: "พระสิงห์ เมืองเชียงใหม่ เชียงใหม่ 50200",
    },
    {
      value: "ฟ้าฮ่าม เมืองเชียงใหม่ เชียงใหม่ 50000",
      label: "ฟ้าฮ่าม เมืองเชียงใหม่ เชียงใหม่ 50000",
    },
    {
      value: "วัดเกต เมืองเชียงใหม่ เชียงใหม่ 50000",
      label: "วัดเกต เมืองเชียงใหม่ เชียงใหม่ 50000",
    },
    {
      value: "ศรีภูมิ เมืองเชียงใหม่ เชียงใหม่ 50200",
      label: "ศรีภูมิ เมืองเชียงใหม่ เชียงใหม่ 50200",
    },
    {
      value: "สันผีเสื้อ เมืองเชียงใหม่ เชียงใหม่ 50300",
      label: "สันผีเสื้อ เมืองเชียงใหม่ เชียงใหม่ 50300",
    },
    {
      value: "สุเทพ เมืองเชียงใหม่ เชียงใหม่ 50200",
      label: "สุเทพ เมืองเชียงใหม่ เชียงใหม่ 50200",
    },
    {
      value: "หนองป่าครั่ง เมืองเชียงใหม่ เชียงใหม่ 50000",
      label: "หนองป่าครั่ง เมืองเชียงใหม่ เชียงใหม่ 50000",
    },
    {
      value: "หนองหอย เมืองเชียงใหม่ เชียงใหม่ 50000",
      label: "หนองหอย เมืองเชียงใหม่ เชียงใหม่ 50000",
    },
    {
      value: "หายยา เมืองเชียงใหม่ เชียงใหม่ 50100",
      label: "หายยา เมืองเชียงใหม่ เชียงใหม่ 50100",
    },
    {
      value: "แม่เหียะ เมืองเชียงใหม่ เชียงใหม่ 50100",
      label: "แม่เหียะ เมืองเชียงใหม่ เชียงใหม่ 50100",
    },
    {
      value: "เปียงหลวง เวียงแหง เชียงใหม่ 50350",
      label: "เปียงหลวง เวียงแหง เชียงใหม่ 50350",
    },
    {
      value: "เมืองแหง เวียงแหง เชียงใหม่ 50350",
      label: "เมืองแหง เวียงแหง เชียงใหม่ 50350",
    },
    {
      value: "แสนไห เวียงแหง เชียงใหม่ 50350",
      label: "แสนไห เวียงแหง เชียงใหม่ 50350",
    },
    {
      value: "ขี้เหล็ก แม่ริม เชียงใหม่ 50180",
      label: "ขี้เหล็ก แม่ริม เชียงใหม่ 50180",
    },
    {
      value: "ดอนแก้ว แม่ริม เชียงใหม่ 50180",
      label: "ดอนแก้ว แม่ริม เชียงใหม่ 50180",
    },
    {
      value: "ริมเหนือ แม่ริม เชียงใหม่ 50180",
      label: "ริมเหนือ แม่ริม เชียงใหม่ 50180",
    },
    {
      value: "ริมใต้ แม่ริม เชียงใหม่ 50180",
      label: "ริมใต้ แม่ริม เชียงใหม่ 50180",
    },
    {
      value: "สะลวง แม่ริม เชียงใหม่ 50330",
      label: "สะลวง แม่ริม เชียงใหม่ 50330",
    },
    {
      value: "สันโป่ง แม่ริม เชียงใหม่ 50180",
      label: "สันโป่ง แม่ริม เชียงใหม่ 50180",
    },
    {
      value: "ห้วยทราย แม่ริม เชียงใหม่ 50180",
      label: "ห้วยทราย แม่ริม เชียงใหม่ 50180",
    },
    {
      value: "เหมืองแก้ว แม่ริม เชียงใหม่ 50180",
      label: "เหมืองแก้ว แม่ริม เชียงใหม่ 50180",
    },
    {
      value: "แม่สา แม่ริม เชียงใหม่ 50180",
      label: "แม่สา แม่ริม เชียงใหม่ 50180",
    },
    {
      value: "แม่แรม แม่ริม เชียงใหม่ 50180",
      label: "แม่แรม แม่ริม เชียงใหม่ 50180",
    },
    {
      value: "โป่งแยง แม่ริม เชียงใหม่ 50180",
      label: "โป่งแยง แม่ริม เชียงใหม่ 50180",
    },
    {
      value: "ดอนเปา แม่วาง เชียงใหม่ 50360",
      label: "ดอนเปา แม่วาง เชียงใหม่ 50360",
    },
    {
      value: "ทุ่งปี้ แม่วาง เชียงใหม่ 50360",
      label: "ทุ่งปี้ แม่วาง เชียงใหม่ 50360",
    },
    {
      value: "ทุ่งรวงทอง แม่วาง เชียงใหม่ 50360",
      label: "ทุ่งรวงทอง แม่วาง เชียงใหม่ 50360",
    },
    {
      value: "บ้านกาด แม่วาง เชียงใหม่ 50360",
      label: "บ้านกาด แม่วาง เชียงใหม่ 50360",
    },
    {
      value: "แม่วิน แม่วาง เชียงใหม่ 50360",
      label: "แม่วิน แม่วาง เชียงใหม่ 50360",
    },
    {
      value: "ทาเหนือ แม่ออน เชียงใหม่ 50130",
      label: "ทาเหนือ แม่ออน เชียงใหม่ 50130",
    },
    {
      value: "บ้านสหกรณ์ แม่ออน เชียงใหม่ 50130",
      label: "บ้านสหกรณ์ แม่ออน เชียงใหม่ 50130",
    },
    {
      value: "ห้วยแก้ว แม่ออน เชียงใหม่ 50130",
      label: "ห้วยแก้ว แม่ออน เชียงใหม่ 50130",
    },
    {
      value: "ออนกลาง แม่ออน เชียงใหม่ 50130",
      label: "ออนกลาง แม่ออน เชียงใหม่ 50130",
    },
    {
      value: "ออนเหนือ แม่ออน เชียงใหม่ 50130",
      label: "ออนเหนือ แม่ออน เชียงใหม่ 50130",
    },
    {
      value: "แม่ทา แม่ออน เชียงใหม่ 50130",
      label: "แม่ทา แม่ออน เชียงใหม่ 50130",
    },
    {
      value: "ท่าตอน แม่อาย เชียงใหม่ 50280",
      label: "ท่าตอน แม่อาย เชียงใหม่ 50280",
    },
    {
      value: "บ้านหลวง แม่อาย เชียงใหม่ 50280",
      label: "บ้านหลวง แม่อาย เชียงใหม่ 50280",
    },
    {
      value: "มะลิกา แม่อาย เชียงใหม่ 50280",
      label: "มะลิกา แม่อาย เชียงใหม่ 50280",
    },
    {
      value: "สันต้นหมื้อ แม่อาย เชียงใหม่ 50280",
      label: "สันต้นหมื้อ แม่อาย เชียงใหม่ 50280",
    },
    {
      value: "แม่นาวาง แม่อาย เชียงใหม่ 50280",
      label: "แม่นาวาง แม่อาย เชียงใหม่ 50280",
    },
    {
      value: "แม่สาว แม่อาย เชียงใหม่ 50280",
      label: "แม่สาว แม่อาย เชียงใหม่ 50280",
    },
    {
      value: "แม่อาย แม่อาย เชียงใหม่ 50280",
      label: "แม่อาย แม่อาย เชียงใหม่ 50280",
    },
    {
      value: "กองแขก แม่แจ่ม เชียงใหม่ 50270",
      label: "กองแขก แม่แจ่ม เชียงใหม่ 50270",
    },
    {
      value: "ช่างเคิ่ง แม่แจ่ม เชียงใหม่ 50270",
      label: "ช่างเคิ่ง แม่แจ่ม เชียงใหม่ 50270",
    },
    {
      value: "ท่าผา แม่แจ่ม เชียงใหม่ 50270",
      label: "ท่าผา แม่แจ่ม เชียงใหม่ 50270",
    },
    {
      value: "บ้านทับ แม่แจ่ม เชียงใหม่ 50270",
      label: "บ้านทับ แม่แจ่ม เชียงใหม่ 50270",
    },
    {
      value: "ปางหินฝน แม่แจ่ม เชียงใหม่ 50270",
      label: "ปางหินฝน แม่แจ่ม เชียงใหม่ 50270",
    },
    {
      value: "แม่นาจร แม่แจ่ม เชียงใหม่ 50270",
      label: "แม่นาจร แม่แจ่ม เชียงใหม่ 50270",
    },
    {
      value: "แม่ศึก แม่แจ่ม เชียงใหม่ 50270",
      label: "แม่ศึก แม่แจ่ม เชียงใหม่ 50270",
    },
    {
      value: "กื้ดช้าง แม่แตง เชียงใหม่ 50150",
      label: "กื้ดช้าง แม่แตง เชียงใหม่ 50150",
    },
    {
      value: "ขี้เหล็ก แม่แตง เชียงใหม่ 50150",
      label: "ขี้เหล็ก แม่แตง เชียงใหม่ 50150",
    },
    {
      value: "ช่อแล แม่แตง เชียงใหม่ 50150",
      label: "ช่อแล แม่แตง เชียงใหม่ 50150",
    },
    {
      value: "บ้านช้าง แม่แตง เชียงใหม่ 50150",
      label: "บ้านช้าง แม่แตง เชียงใหม่ 50150",
    },
    {
      value: "บ้านเป้า แม่แตง เชียงใหม่ 50150",
      label: "บ้านเป้า แม่แตง เชียงใหม่ 50150",
    },
    {
      value: "ป่าแป๋ แม่แตง เชียงใหม่ 50150",
      label: "ป่าแป๋ แม่แตง เชียงใหม่ 50150",
    },
    {
      value: "สบเปิง แม่แตง เชียงใหม่ 50150",
      label: "สบเปิง แม่แตง เชียงใหม่ 50150",
    },
    {
      value: "สันป่ายาง แม่แตง เชียงใหม่ 50330",
      label: "สันป่ายาง แม่แตง เชียงใหม่ 50330",
    },
    {
      value: "สันมหาพน แม่แตง เชียงใหม่ 50150",
      label: "สันมหาพน แม่แตง เชียงใหม่ 50150",
    },
    {
      value: "อินทขิล แม่แตง เชียงใหม่ 50150",
      label: "อินทขิล แม่แตง เชียงใหม่ 50150",
    },
    {
      value: "เมืองก๋าย แม่แตง เชียงใหม่ 50150",
      label: "เมืองก๋าย แม่แตง เชียงใหม่ 50150",
    },
    {
      value: "แม่หอพระ แม่แตง เชียงใหม่ 50150",
      label: "แม่หอพระ แม่แตง เชียงใหม่ 50150",
    },
    {
      value: "แม่แตง แม่แตง เชียงใหม่ 50150",
      label: "แม่แตง แม่แตง เชียงใหม่ 50150",
    },
    {
      value: "ปงตำ ไชยปราการ เชียงใหม่ 50320",
      label: "ปงตำ ไชยปราการ เชียงใหม่ 50320",
    },
    {
      value: "ศรีดงเย็น ไชยปราการ เชียงใหม่ 50320",
      label: "ศรีดงเย็น ไชยปราการ เชียงใหม่ 50320",
    },
    {
      value: "หนองบัว ไชยปราการ เชียงใหม่ 50320",
      label: "หนองบัว ไชยปราการ เชียงใหม่ 50320",
    },
    {
      value: "แม่ทะลบ ไชยปราการ เชียงใหม่ 50320",
      label: "แม่ทะลบ ไชยปราการ เชียงใหม่ 50320",
    },
    { value: "งอบ ทุ่งช้าง น่าน 55130", label: "งอบ ทุ่งช้าง น่าน 55130" },
    {
      value: "ทุ่งช้าง ทุ่งช้าง น่าน 55130",
      label: "ทุ่งช้าง ทุ่งช้าง น่าน 55130",
    },
    { value: "ปอน ทุ่งช้าง น่าน 55130", label: "ปอน ทุ่งช้าง น่าน 55130" },
    { value: "และ ทุ่งช้าง น่าน 55130", label: "และ ทุ่งช้าง น่าน 55130" },
    { value: "จอมพระ ท่าวังผา น่าน 55140", label: "จอมพระ ท่าวังผา น่าน 55140" },
    { value: "ตาลชุม ท่าวังผา น่าน 55140", label: "ตาลชุม ท่าวังผา น่าน 55140" },
    {
      value: "ท่าวังผา ท่าวังผา น่าน 55140",
      label: "ท่าวังผา ท่าวังผา น่าน 55140",
    },
    { value: "ป่าคา ท่าวังผา น่าน 55140", label: "ป่าคา ท่าวังผา น่าน 55140" },
    { value: "ผาตอ ท่าวังผา น่าน 55140", label: "ผาตอ ท่าวังผา น่าน 55140" },
    { value: "ผาทอง ท่าวังผา น่าน 55140", label: "ผาทอง ท่าวังผา น่าน 55140" },
    { value: "ยม ท่าวังผา น่าน 55140", label: "ยม ท่าวังผา น่าน 55140" },
    { value: "ริม ท่าวังผา น่าน 55140", label: "ริม ท่าวังผา น่าน 55140" },
    {
      value: "ศรีภูมิ ท่าวังผา น่าน 55140",
      label: "ศรีภูมิ ท่าวังผา น่าน 55140",
    },
    { value: "แสนทอง ท่าวังผา น่าน 55140", label: "แสนทอง ท่าวังผา น่าน 55140" },
    { value: "นาน้อย นาน้อย น่าน 55150", label: "นาน้อย นาน้อย น่าน 55150" },
    { value: "น้ำตก นาน้อย น่าน 55150", label: "น้ำตก นาน้อย น่าน 55150" },
    { value: "บัวใหญ่ นาน้อย น่าน 55150", label: "บัวใหญ่ นาน้อย น่าน 55150" },
    { value: "ศรีษะเกษ นาน้อย น่าน 55150", label: "ศรีษะเกษ นาน้อย น่าน 55150" },
    { value: "สถาน นาน้อย น่าน 55150", label: "สถาน นาน้อย น่าน 55150" },
    { value: "สันทะ นาน้อย น่าน 55150", label: "สันทะ นาน้อย น่าน 55150" },
    { value: "เชียงของ นาน้อย น่าน 55150", label: "เชียงของ นาน้อย น่าน 55150" },
    { value: "นาทะนุง นาหมื่น น่าน 55180", label: "นาทะนุง นาหมื่น น่าน 55180" },
    { value: "บ่อแก้ว นาหมื่น น่าน 55180", label: "บ่อแก้ว นาหมื่น น่าน 55180" },
    { value: "ปิงหลวง นาหมื่น น่าน 55180", label: "ปิงหลวง นาหมื่น น่าน 55180" },
    { value: "เมืองลี นาหมื่น น่าน 55180", label: "เมืองลี นาหมื่น น่าน 55180" },
    { value: "ดงพญา บ่อเกลือ น่าน 55220", label: "ดงพญา บ่อเกลือ น่าน 55220" },
    {
      value: "บ่อเกลือเหนือ บ่อเกลือ น่าน 55220",
      label: "บ่อเกลือเหนือ บ่อเกลือ น่าน 55220",
    },
    {
      value: "บ่อเกลือใต้ บ่อเกลือ น่าน 55220",
      label: "บ่อเกลือใต้ บ่อเกลือ น่าน 55220",
    },
    { value: "ภูฟ้า บ่อเกลือ น่าน 55220", label: "ภูฟ้า บ่อเกลือ น่าน 55220" },
    {
      value: "บ้านพี้ บ้านหลวง น่าน 55190",
      label: "บ้านพี้ บ้านหลวง น่าน 55190",
    },
    {
      value: "บ้านฟ้า บ้านหลวง น่าน 55190",
      label: "บ้านฟ้า บ้านหลวง น่าน 55190",
    },
    {
      value: "ป่าคาหลวง บ้านหลวง น่าน 55190",
      label: "ป่าคาหลวง บ้านหลวง น่าน 55190",
    },
    { value: "สวด บ้านหลวง น่าน 55190", label: "สวด บ้านหลวง น่าน 55190" },
    { value: "ปัว ปัว น่าน 55120", label: "ปัว ปัว น่าน 55120" },
    { value: "ป่ากลาง ปัว น่าน 55120", label: "ป่ากลาง ปัว น่าน 55120" },
    { value: "ภูคา ปัว น่าน 55120", label: "ภูคา ปัว น่าน 55120" },
    { value: "วรนคร ปัว น่าน 55120", label: "วรนคร ปัว น่าน 55120" },
    { value: "ศิลาเพชร ปัว น่าน 55120", label: "ศิลาเพชร ปัว น่าน 55120" },
    { value: "ศิลาแลง ปัว น่าน 55120", label: "ศิลาแลง ปัว น่าน 55120" },
    { value: "สกาด ปัว น่าน 55120", label: "สกาด ปัว น่าน 55120" },
    { value: "สถาน ปัว น่าน 55120", label: "สถาน ปัว น่าน 55120" },
    { value: "อวน ปัว น่าน 55120", label: "อวน ปัว น่าน 55120" },
    { value: "เจดีย์ชัย ปัว น่าน 55120", label: "เจดีย์ชัย ปัว น่าน 55120" },
    { value: "แงง ปัว น่าน 55120", label: "แงง ปัว น่าน 55120" },
    { value: "ไชยวัฒนา ปัว น่าน 55120", label: "ไชยวัฒนา ปัว น่าน 55120" },
    { value: "ท่าน้าว ภูเพียง น่าน 55000", label: "ท่าน้าว ภูเพียง น่าน 55000" },
    { value: "นาปัง ภูเพียง น่าน 55000", label: "นาปัง ภูเพียง น่าน 55000" },
    {
      value: "น้ำเกี๋ยน ภูเพียง น่าน 55000",
      label: "น้ำเกี๋ยน ภูเพียง น่าน 55000",
    },
    { value: "น้ำแก่น ภูเพียง น่าน 55000", label: "น้ำแก่น ภูเพียง น่าน 55000" },
    { value: "ฝายแก้ว ภูเพียง น่าน 55000", label: "ฝายแก้ว ภูเพียง น่าน 55000" },
    {
      value: "ม่วงตึ๊ด ภูเพียง น่าน 55000",
      label: "ม่วงตึ๊ด ภูเพียง น่าน 55000",
    },
    {
      value: "เมืองจัง ภูเพียง น่าน 55000",
      label: "เมืองจัง ภูเพียง น่าน 55000",
    },
    { value: "ชนแดน สองแคว น่าน 55160", label: "ชนแดน สองแคว น่าน 55160" },
    {
      value: "นาไร่หลวง สองแคว น่าน 55160",
      label: "นาไร่หลวง สองแคว น่าน 55160",
    },
    { value: "ยอด สองแคว น่าน 55160", label: "ยอด สองแคว น่าน 55160" },
    {
      value: "ดู่พงษ์ สันติสุข น่าน 55210",
      label: "ดู่พงษ์ สันติสุข น่าน 55210",
    },
    {
      value: "ป่าแลวหลวง สันติสุข น่าน 55210",
      label: "ป่าแลวหลวง สันติสุข น่าน 55210",
    },
    { value: "พงษ์ สันติสุข น่าน 55210", label: "พงษ์ สันติสุข น่าน 55210" },
    {
      value: "ขุนน่าน เฉลิมพระเกียรติ น่าน 55130",
      label: "ขุนน่าน เฉลิมพระเกียรติ น่าน 55130",
    },
    {
      value: "ห้วยโก๋น เฉลิมพระเกียรติ น่าน 55130",
      label: "ห้วยโก๋น เฉลิมพระเกียรติ น่าน 55130",
    },
    {
      value: "พญาแก้ว เชียงกลาง น่าน 55160",
      label: "พญาแก้ว เชียงกลาง น่าน 55160",
    },
    {
      value: "พระธาตุ เชียงกลาง น่าน 55160",
      label: "พระธาตุ เชียงกลาง น่าน 55160",
    },
    {
      value: "พระพุทธบาท เชียงกลาง น่าน 55160",
      label: "พระพุทธบาท เชียงกลาง น่าน 55160",
    },
    {
      value: "เชียงกลาง เชียงกลาง น่าน 55160",
      label: "เชียงกลาง เชียงกลาง น่าน 55160",
    },
    {
      value: "เชียงคาน เชียงกลาง น่าน 55160",
      label: "เชียงคาน เชียงกลาง น่าน 55160",
    },
    { value: "เปือ เชียงกลาง น่าน 55160", label: "เปือ เชียงกลาง น่าน 55160" },
    {
      value: "กองควาย เมืองน่าน น่าน 55000",
      label: "กองควาย เมืองน่าน น่าน 55000",
    },
    {
      value: "ดู่ใต้ เมืองน่าน น่าน 55000",
      label: "ดู่ใต้ เมืองน่าน น่าน 55000",
    },
    {
      value: "ถืมตอง เมืองน่าน น่าน 55000",
      label: "ถืมตอง เมืองน่าน น่าน 55000",
    },
    { value: "นาซาว เมืองน่าน น่าน 55000", label: "นาซาว เมืองน่าน น่าน 55000" },
    { value: "บ่อ เมืองน่าน น่าน 55000", label: "บ่อ เมืองน่าน น่าน 55000" },
    {
      value: "ผาสิงห์ เมืองน่าน น่าน 55000",
      label: "ผาสิงห์ เมืองน่าน น่าน 55000",
    },
    { value: "สวก เมืองน่าน น่าน 55000", label: "สวก เมืองน่าน น่าน 55000" },
    {
      value: "สะเนียน เมืองน่าน น่าน 55000",
      label: "สะเนียน เมืองน่าน น่าน 55000",
    },
    { value: "เรือง เมืองน่าน น่าน 55000", label: "เรือง เมืองน่าน น่าน 55000" },
    {
      value: "ในเวียง เมืองน่าน น่าน 55000",
      label: "ในเวียง เมืองน่าน น่าน 55000",
    },
    {
      value: "ไชยสถาน เมืองน่าน น่าน 55000",
      label: "ไชยสถาน เมืองน่าน น่าน 55000",
    },
    {
      value: "กลางเวียง เวียงสา น่าน 55110",
      label: "กลางเวียง เวียงสา น่าน 55110",
    },
    { value: "ขึ่ง เวียงสา น่าน 55110", label: "ขึ่ง เวียงสา น่าน 55110" },
    {
      value: "จอมจันทร์ เวียงสา น่าน 55110",
      label: "จอมจันทร์ เวียงสา น่าน 55110",
    },
    { value: "ตาลชุม เวียงสา น่าน 55110", label: "ตาลชุม เวียงสา น่าน 55110" },
    {
      value: "ทุ่งศรีทอง เวียงสา น่าน 55110",
      label: "ทุ่งศรีทอง เวียงสา น่าน 55110",
    },
    {
      value: "นาเหลือง เวียงสา น่าน 55110",
      label: "นาเหลือง เวียงสา น่าน 55110",
    },
    { value: "น้ำปั้ว เวียงสา น่าน 55110", label: "น้ำปั้ว เวียงสา น่าน 55110" },
    { value: "น้ำมวบ เวียงสา น่าน 55110", label: "น้ำมวบ เวียงสา น่าน 55110" },
    { value: "ปงสนุก เวียงสา น่าน 55110", label: "ปงสนุก เวียงสา น่าน 55110" },
    {
      value: "ยาบหัวนา เวียงสา น่าน 55110",
      label: "ยาบหัวนา เวียงสา น่าน 55110",
    },
    { value: "ส้าน เวียงสา น่าน 55110", label: "ส้าน เวียงสา น่าน 55110" },
    {
      value: "ส้านนาหนองใหม่ เวียงสา น่าน 55110",
      label: "ส้านนาหนองใหม่ เวียงสา น่าน 55110",
    },
    {
      value: "อ่ายนาไลย เวียงสา น่าน 55110",
      label: "อ่ายนาไลย เวียงสา น่าน 55110",
    },
    {
      value: "แม่ขะนิง เวียงสา น่าน 55110",
      label: "แม่ขะนิง เวียงสา น่าน 55110",
    },
    { value: "แม่สา เวียงสา น่าน 55110", label: "แม่สา เวียงสา น่าน 55110" },
    { value: "แม่สาคร เวียงสา น่าน 55110", label: "แม่สาคร เวียงสา น่าน 55110" },
    {
      value: "ไหล่น่าน เวียงสา น่าน 55110",
      label: "ไหล่น่าน เวียงสา น่าน 55110",
    },
    { value: "น้ำปาย แม่จริม น่าน 55170", label: "น้ำปาย แม่จริม น่าน 55170" },
    { value: "น้ำพาง แม่จริม น่าน 55170", label: "น้ำพาง แม่จริม น่าน 55170" },
    { value: "หนองแดง แม่จริม น่าน 55170", label: "หนองแดง แม่จริม น่าน 55170" },
    {
      value: "หมอเมือง แม่จริม น่าน 55170",
      label: "หมอเมือง แม่จริม น่าน 55170",
    },
    { value: "แม่จริม แม่จริม น่าน 55170", label: "แม่จริม แม่จริม น่าน 55170" },
    { value: "จุน จุน พะเยา 56150", label: "จุน จุน พะเยา 56150" },
    { value: "ทุ่งรวงทอง จุน พะเยา 56150", label: "ทุ่งรวงทอง จุน พะเยา 56150" },
    {
      value: "พระธาตุขิงแกง จุน พะเยา 56150",
      label: "พระธาตุขิงแกง จุน พะเยา 56150",
    },
    { value: "ลอ จุน พะเยา 56150", label: "ลอ จุน พะเยา 56150" },
    { value: "หงส์หิน จุน พะเยา 56150", label: "หงส์หิน จุน พะเยา 56150" },
    {
      value: "ห้วยข้าวก่ำ จุน พะเยา 56150",
      label: "ห้วยข้าวก่ำ จุน พะเยา 56150",
    },
    { value: "ห้วยยางขาม จุน พะเยา 56150", label: "ห้วยยางขาม จุน พะเยา 56150" },
    {
      value: "คือเวียง ดอกคำใต้ พะเยา 56120",
      label: "คือเวียง ดอกคำใต้ พะเยา 56120",
    },
    {
      value: "ดงสุวรรณ ดอกคำใต้ พะเยา 56120",
      label: "ดงสุวรรณ ดอกคำใต้ พะเยา 56120",
    },
    {
      value: "ดอกคำใต้ ดอกคำใต้ พะเยา 56120",
      label: "ดอกคำใต้ ดอกคำใต้ พะเยา 56120",
    },
    {
      value: "ดอนศรีชุม ดอกคำใต้ พะเยา 56120",
      label: "ดอนศรีชุม ดอกคำใต้ พะเยา 56120",
    },
    {
      value: "บุญเกิด ดอกคำใต้ พะเยา 56120",
      label: "บุญเกิด ดอกคำใต้ พะเยา 56120",
    },
    {
      value: "บ้านถ้ำ ดอกคำใต้ พะเยา 56120",
      label: "บ้านถ้ำ ดอกคำใต้ พะเยา 56120",
    },
    {
      value: "บ้านปิน ดอกคำใต้ พะเยา 56120",
      label: "บ้านปิน ดอกคำใต้ พะเยา 56120",
    },
    {
      value: "ป่าซาง ดอกคำใต้ พะเยา 56120",
      label: "ป่าซาง ดอกคำใต้ พะเยา 56120",
    },
    {
      value: "สว่างอารมณ์ ดอกคำใต้ พะเยา 56120",
      label: "สว่างอารมณ์ ดอกคำใต้ พะเยา 56120",
    },
    {
      value: "สันโค้ง ดอกคำใต้ พะเยา 56120",
      label: "สันโค้ง ดอกคำใต้ พะเยา 56120",
    },
    {
      value: "หนองหล่ม ดอกคำใต้ พะเยา 56120",
      label: "หนองหล่ม ดอกคำใต้ พะเยา 56120",
    },
    {
      value: "ห้วยลาน ดอกคำใต้ พะเยา 56120",
      label: "ห้วยลาน ดอกคำใต้ พะเยา 56120",
    },
    { value: "ขุนควร ปง พะเยา 56140", label: "ขุนควร ปง พะเยา 56140" },
    { value: "ควร ปง พะเยา 56140", label: "ควร ปง พะเยา 56140" },
    { value: "งิม ปง พะเยา 56140", label: "งิม ปง พะเยา 56140" },
    { value: "นาปรัง ปง พะเยา 56140", label: "นาปรัง ปง พะเยา 56140" },
    { value: "ปง ปง พะเยา 56140", label: "ปง ปง พะเยา 56140" },
    { value: "ผาช้างน้อย ปง พะเยา 56140", label: "ผาช้างน้อย ปง พะเยา 56140" },
    { value: "ออย ปง พะเยา 56140", label: "ออย ปง พะเยา 56140" },
    { value: "ดงเจน ภูกามยาว พะเยา 56000", label: "ดงเจน ภูกามยาว พะเยา 56000" },
    {
      value: "ห้วยแก้ว ภูกามยาว พะเยา 56000",
      label: "ห้วยแก้ว ภูกามยาว พะเยา 56000",
    },
    {
      value: "แม่อิง ภูกามยาว พะเยา 56000",
      label: "แม่อิง ภูกามยาว พะเยา 56000",
    },
    {
      value: "ทุ่งกล้วย ภูซาง พะเยา 56110",
      label: "ทุ่งกล้วย ภูซาง พะเยา 56110",
    },
    { value: "ป่าสัก ภูซาง พะเยา 56110", label: "ป่าสัก ภูซาง พะเยา 56110" },
    { value: "ภูซาง ภูซาง พะเยา 56110", label: "ภูซาง ภูซาง พะเยา 56110" },
    { value: "สบบง ภูซาง พะเยา 56110", label: "สบบง ภูซาง พะเยา 56110" },
    { value: "เชียงแรง ภูซาง พะเยา 56110", label: "เชียงแรง ภูซาง พะเยา 56110" },
    {
      value: "ทุ่งผาสุข เชียงคำ พะเยา 56110",
      label: "ทุ่งผาสุข เชียงคำ พะเยา 56110",
    },
    { value: "น้ำแวน เชียงคำ พะเยา 56110", label: "น้ำแวน เชียงคำ พะเยา 56110" },
    {
      value: "ฝายกวาง เชียงคำ พะเยา 56110",
      label: "ฝายกวาง เชียงคำ พะเยา 56110",
    },
    {
      value: "ร่มเย็น เชียงคำ พะเยา 56110",
      label: "ร่มเย็น เชียงคำ พะเยา 56110",
    },
    { value: "หย่วน เชียงคำ พะเยา 56110", label: "หย่วน เชียงคำ พะเยา 56110" },
    {
      value: "อ่างทอง เชียงคำ พะเยา 56110",
      label: "อ่างทอง เชียงคำ พะเยา 56110",
    },
    {
      value: "เจดีย์คำ เชียงคำ พะเยา 56110",
      label: "เจดีย์คำ เชียงคำ พะเยา 56110",
    },
    {
      value: "เชียงบาน เชียงคำ พะเยา 56110",
      label: "เชียงบาน เชียงคำ พะเยา 56110",
    },
    { value: "เวียง เชียงคำ พะเยา 56110", label: "เวียง เชียงคำ พะเยา 56110" },
    { value: "แม่ลาว เชียงคำ พะเยา 56110", label: "แม่ลาว เชียงคำ พะเยา 56110" },
    {
      value: "บ้านมาง เชียงม่วน พะเยา 56160",
      label: "บ้านมาง เชียงม่วน พะเยา 56160",
    },
    { value: "สระ เชียงม่วน พะเยา 56160", label: "สระ เชียงม่วน พะเยา 56160" },
    {
      value: "เชียงม่วน เชียงม่วน พะเยา 56160",
      label: "เชียงม่วน เชียงม่วน พะเยา 56160",
    },
    {
      value: "จำป่าหวาย เมืองพะเยา พะเยา 56000",
      label: "จำป่าหวาย เมืองพะเยา พะเยา 56000",
    },
    {
      value: "ท่าจำปี เมืองพะเยา พะเยา 56000",
      label: "ท่าจำปี เมืองพะเยา พะเยา 56000",
    },
    {
      value: "ท่าวังทอง เมืองพะเยา พะเยา 56000",
      label: "ท่าวังทอง เมืองพะเยา พะเยา 56000",
    },
    {
      value: "บ้านตุ่น เมืองพะเยา พะเยา 56000",
      label: "บ้านตุ่น เมืองพะเยา พะเยา 56000",
    },
    {
      value: "บ้านต๊ำ เมืองพะเยา พะเยา 56000",
      label: "บ้านต๊ำ เมืองพะเยา พะเยา 56000",
    },
    {
      value: "บ้านต๋อม เมืองพะเยา พะเยา 56000",
      label: "บ้านต๋อม เมืองพะเยา พะเยา 56000",
    },
    {
      value: "บ้านสาง เมืองพะเยา พะเยา 56000",
      label: "บ้านสาง เมืองพะเยา พะเยา 56000",
    },
    {
      value: "บ้านใหม่ เมืองพะเยา พะเยา 56000",
      label: "บ้านใหม่ เมืองพะเยา พะเยา 56000",
    },
    {
      value: "สันป่าม่วง เมืองพะเยา พะเยา 56000",
      label: "สันป่าม่วง เมืองพะเยา พะเยา 56000",
    },
    {
      value: "เวียง เมืองพะเยา พะเยา 56000",
      label: "เวียง เมืองพะเยา พะเยา 56000",
    },
    {
      value: "แม่กา เมืองพะเยา พะเยา 56000",
      label: "แม่กา เมืองพะเยา พะเยา 56000",
    },
    {
      value: "แม่ต๋ำ เมืองพะเยา พะเยา 56000",
      label: "แม่ต๋ำ เมืองพะเยา พะเยา 56000",
    },
    {
      value: "แม่นาเรือ เมืองพะเยา พะเยา 56000",
      label: "แม่นาเรือ เมืองพะเยา พะเยา 56000",
    },
    {
      value: "แม่ปืม เมืองพะเยา พะเยา 56000",
      label: "แม่ปืม เมืองพะเยา พะเยา 56000",
    },
    {
      value: "แม่ใส เมืองพะเยา พะเยา 56000",
      label: "แม่ใส เมืองพะเยา พะเยา 56000",
    },
    {
      value: "บ้านเหล่า แม่ใจ พะเยา 56130",
      label: "บ้านเหล่า แม่ใจ พะเยา 56130",
    },
    { value: "ป่าแฝก แม่ใจ พะเยา 56130", label: "ป่าแฝก แม่ใจ พะเยา 56130" },
    { value: "ศรีถ้อย แม่ใจ พะเยา 56130", label: "ศรีถ้อย แม่ใจ พะเยา 56130" },
    {
      value: "เจริญราษฎร์ แม่ใจ พะเยา 56130",
      label: "เจริญราษฎร์ แม่ใจ พะเยา 56130",
    },
    { value: "แม่สุก แม่ใจ พะเยา 56130", label: "แม่สุก แม่ใจ พะเยา 56130" },
    { value: "แม่ใจ แม่ใจ พะเยา 56130", label: "แม่ใจ แม่ใจ พะเยา 56130" },
    {
      value: "ทุ่งศรี ร้องกวาง แพร่ 54140",
      label: "ทุ่งศรี ร้องกวาง แพร่ 54140",
    },
    { value: "น้ำเลา ร้องกวาง แพร่ 54140", label: "น้ำเลา ร้องกวาง แพร่ 54140" },
    {
      value: "บ้านเวียง ร้องกวาง แพร่ 54140",
      label: "บ้านเวียง ร้องกวาง แพร่ 54140",
    },
    {
      value: "ร้องกวาง ร้องกวาง แพร่ 54140",
      label: "ร้องกวาง ร้องกวาง แพร่ 54140",
    },
    {
      value: "ร้องเข็ม ร้องกวาง แพร่ 54140",
      label: "ร้องเข็ม ร้องกวาง แพร่ 54140",
    },
    {
      value: "ห้วยโรง ร้องกวาง แพร่ 54140",
      label: "ห้วยโรง ร้องกวาง แพร่ 54140",
    },
    {
      value: "แม่ทราย ร้องกวาง แพร่ 54140",
      label: "แม่ทราย ร้องกวาง แพร่ 54140",
    },
    {
      value: "แม่ยางตาล ร้องกวาง แพร่ 54140",
      label: "แม่ยางตาล ร้องกวาง แพร่ 54140",
    },
    {
      value: "แม่ยางร้อง ร้องกวาง แพร่ 54140",
      label: "แม่ยางร้อง ร้องกวาง แพร่ 54140",
    },
    {
      value: "แม่ยางฮ่อ ร้องกวาง แพร่ 54140",
      label: "แม่ยางฮ่อ ร้องกวาง แพร่ 54140",
    },
    { value: "ไผ่โทน ร้องกวาง แพร่ 54140", label: "ไผ่โทน ร้องกวาง แพร่ 54140" },
    { value: "ต้าผามอก ลอง แพร่ 54150", label: "ต้าผามอก ลอง แพร่ 54150" },
    { value: "ทุ่งแล้ง ลอง แพร่ 54150", label: "ทุ่งแล้ง ลอง แพร่ 54150" },
    { value: "บ่อเหล็กลอง ลอง แพร่ 54150", label: "บ่อเหล็กลอง ลอง แพร่ 54150" },
    { value: "บ้านปิน ลอง แพร่ 54150", label: "บ้านปิน ลอง แพร่ 54150" },
    { value: "ปากกาง ลอง แพร่ 54150", label: "ปากกาง ลอง แพร่ 54150" },
    { value: "หัวทุ่ง ลอง แพร่ 54150", label: "หัวทุ่ง ลอง แพร่ 54150" },
    { value: "ห้วยอ้อ ลอง แพร่ 54150", label: "ห้วยอ้อ ลอง แพร่ 54150" },
    { value: "เวียงต้า ลอง แพร่ 54150", label: "เวียงต้า ลอง แพร่ 54150" },
    { value: "แม่ปาน ลอง แพร่ 54150", label: "แม่ปาน ลอง แพร่ 54150" },
    { value: "นาพูน วังชิ้น แพร่ 54160", label: "นาพูน วังชิ้น แพร่ 54160" },
    { value: "ป่าสัก วังชิ้น แพร่ 54160", label: "ป่าสัก วังชิ้น แพร่ 54160" },
    { value: "วังชิ้น วังชิ้น แพร่ 54160", label: "วังชิ้น วังชิ้น แพร่ 54160" },
    { value: "สรอย วังชิ้น แพร่ 54160", label: "สรอย วังชิ้น แพร่ 54160" },
    { value: "แม่ป้าก วังชิ้น แพร่ 54160", label: "แม่ป้าก วังชิ้น แพร่ 54160" },
    { value: "แม่พุง วังชิ้น แพร่ 54160", label: "แม่พุง วังชิ้น แพร่ 54160" },
    {
      value: "แม่เกิ๋ง วังชิ้น แพร่ 54160",
      label: "แม่เกิ๋ง วังชิ้น แพร่ 54160",
    },
    { value: "ทุ่งน้าว สอง แพร่ 54120", label: "ทุ่งน้าว สอง แพร่ 54120" },
    { value: "บ้านกลาง สอง แพร่ 54120", label: "บ้านกลาง สอง แพร่ 54120" },
    { value: "บ้านหนุน สอง แพร่ 54120", label: "บ้านหนุน สอง แพร่ 54120" },
    { value: "สะเอียบ สอง แพร่ 54120", label: "สะเอียบ สอง แพร่ 54120" },
    { value: "หัวเมือง สอง แพร่ 54120", label: "หัวเมือง สอง แพร่ 54120" },
    { value: "ห้วยหม้าย สอง แพร่ 54120", label: "ห้วยหม้าย สอง แพร่ 54120" },
    { value: "เตาปูน สอง แพร่ 54120", label: "เตาปูน สอง แพร่ 54120" },
    { value: "แดนชุมพล สอง แพร่ 54120", label: "แดนชุมพล สอง แพร่ 54120" },
    { value: "ดอนมูล สูงเม่น แพร่ 54130", label: "ดอนมูล สูงเม่น แพร่ 54130" },
    { value: "น้ำชำ สูงเม่น แพร่ 54130", label: "น้ำชำ สูงเม่น แพร่ 54130" },
    {
      value: "บ้านกวาง สูงเม่น แพร่ 54130",
      label: "บ้านกวาง สูงเม่น แพร่ 54130",
    },
    { value: "บ้านกาศ สูงเม่น แพร่ 54130", label: "บ้านกาศ สูงเม่น แพร่ 54130" },
    { value: "บ้านปง สูงเม่น แพร่ 54130", label: "บ้านปง สูงเม่น แพร่ 54130" },
    {
      value: "บ้านเหล่า สูงเม่น แพร่ 54130",
      label: "บ้านเหล่า สูงเม่น แพร่ 54130",
    },
    { value: "พระหลวง สูงเม่น แพร่ 54130", label: "พระหลวง สูงเม่น แพร่ 54130" },
    { value: "ร่องกาศ สูงเม่น แพร่ 54130", label: "ร่องกาศ สูงเม่น แพร่ 54130" },
    { value: "สบสาย สูงเม่น แพร่ 54130", label: "สบสาย สูงเม่น แพร่ 54130" },
    { value: "สูงเม่น สูงเม่น แพร่ 54130", label: "สูงเม่น สูงเม่น แพร่ 54130" },
    { value: "หัวฝาย สูงเม่น แพร่ 54130", label: "หัวฝาย สูงเม่น แพร่ 54130" },
    {
      value: "เวียงทอง สูงเม่น แพร่ 54000",
      label: "เวียงทอง สูงเม่น แพร่ 54000",
    },
    {
      value: "ตำหนักธรรม หนองม่วงไข่ แพร่ 54170",
      label: "ตำหนักธรรม หนองม่วงไข่ แพร่ 54170",
    },
    {
      value: "ทุ่งแค้ว หนองม่วงไข่ แพร่ 54170",
      label: "ทุ่งแค้ว หนองม่วงไข่ แพร่ 54170",
    },
    {
      value: "น้ำรัด หนองม่วงไข่ แพร่ 54170",
      label: "น้ำรัด หนองม่วงไข่ แพร่ 54170",
    },
    {
      value: "วังหลวง หนองม่วงไข่ แพร่ 54170",
      label: "วังหลวง หนองม่วงไข่ แพร่ 54170",
    },
    {
      value: "หนองม่วงไข่ หนองม่วงไข่ แพร่ 54170",
      label: "หนองม่วงไข่ หนองม่วงไข่ แพร่ 54170",
    },
    {
      value: "แม่คำมี หนองม่วงไข่ แพร่ 54170",
      label: "แม่คำมี หนองม่วงไข่ แพร่ 54170",
    },
    {
      value: "ปงป่าหวาย เด่นชัย แพร่ 54110",
      label: "ปงป่าหวาย เด่นชัย แพร่ 54110",
    },
    { value: "ห้วยไร่ เด่นชัย แพร่ 54110", label: "ห้วยไร่ เด่นชัย แพร่ 54110" },
    { value: "เด่นชัย เด่นชัย แพร่ 54110", label: "เด่นชัย เด่นชัย แพร่ 54110" },
    {
      value: "แม่จั๊วะ เด่นชัย แพร่ 54110",
      label: "แม่จั๊วะ เด่นชัย แพร่ 54110",
    },
    { value: "ไทรย้อย เด่นชัย แพร่ 54110", label: "ไทรย้อย เด่นชัย แพร่ 54110" },
    {
      value: "กาญจนา เมืองแพร่ แพร่ 54000",
      label: "กาญจนา เมืองแพร่ แพร่ 54000",
    },
    { value: "ช่อแฮ เมืองแพร่ แพร่ 54000", label: "ช่อแฮ เมืองแพร่ แพร่ 54000" },
    {
      value: "ทุ่งกวาว เมืองแพร่ แพร่ 54000",
      label: "ทุ่งกวาว เมืองแพร่ แพร่ 54000",
    },
    {
      value: "ทุ่งโฮ้ง เมืองแพร่ แพร่ 54000",
      label: "ทุ่งโฮ้ง เมืองแพร่ แพร่ 54000",
    },
    {
      value: "ท่าข้าม เมืองแพร่ แพร่ 54000",
      label: "ท่าข้าม เมืองแพร่ แพร่ 54000",
    },
    {
      value: "นาจักร เมืองแพร่ แพร่ 54000",
      label: "นาจักร เมืองแพร่ แพร่ 54000",
    },
    { value: "น้ำชำ เมืองแพร่ แพร่ 54000", label: "น้ำชำ เมืองแพร่ แพร่ 54000" },
    {
      value: "บ้านถิ่น เมืองแพร่ แพร่ 54000",
      label: "บ้านถิ่น เมืองแพร่ แพร่ 54000",
    },
    {
      value: "ป่าแดง เมืองแพร่ แพร่ 54000",
      label: "ป่าแดง เมืองแพร่ แพร่ 54000",
    },
    {
      value: "ป่าแมต เมืองแพร่ แพร่ 54000",
      label: "ป่าแมต เมืองแพร่ แพร่ 54000",
    },
    {
      value: "ร่องฟอง เมืองแพร่ แพร่ 54000",
      label: "ร่องฟอง เมืองแพร่ แพร่ 54000",
    },
    { value: "วังธง เมืองแพร่ แพร่ 54000", label: "วังธง เมืองแพร่ แพร่ 54000" },
    {
      value: "วังหงษ์ เมืองแพร่ แพร่ 54000",
      label: "วังหงษ์ เมืองแพร่ แพร่ 54000",
    },
    {
      value: "สวนเขื่อน เมืองแพร่ แพร่ 54000",
      label: "สวนเขื่อน เมืองแพร่ แพร่ 54000",
    },
    {
      value: "ห้วยม้า เมืองแพร่ แพร่ 54000",
      label: "ห้วยม้า เมืองแพร่ แพร่ 54000",
    },
    {
      value: "เหมืองหม้อ เมืองแพร่ แพร่ 54000",
      label: "เหมืองหม้อ เมืองแพร่ แพร่ 54000",
    },
    {
      value: "แม่คำมี เมืองแพร่ แพร่ 54000",
      label: "แม่คำมี เมืองแพร่ แพร่ 54000",
    },
    { value: "แม่ยม เมืองแพร่ แพร่ 54000", label: "แม่ยม เมืองแพร่ แพร่ 54000" },
    {
      value: "แม่หล่าย เมืองแพร่ แพร่ 54000",
      label: "แม่หล่าย เมืองแพร่ แพร่ 54000",
    },
    {
      value: "ในเวียง เมืองแพร่ แพร่ 54000",
      label: "ในเวียง เมืองแพร่ แพร่ 54000",
    },
    {
      value: "ขุนยวม ขุนยวม แม่ฮ่องสอน 58140",
      label: "ขุนยวม ขุนยวม แม่ฮ่องสอน 58140",
    },
    {
      value: "เมืองปอน ขุนยวม แม่ฮ่องสอน 58140",
      label: "เมืองปอน ขุนยวม แม่ฮ่องสอน 58140",
    },
    {
      value: "แม่กิ๊ ขุนยวม แม่ฮ่องสอน 58140",
      label: "แม่กิ๊ ขุนยวม แม่ฮ่องสอน 58140",
    },
    {
      value: "แม่ยวมน้อย ขุนยวม แม่ฮ่องสอน 58140",
      label: "แม่ยวมน้อย ขุนยวม แม่ฮ่องสอน 58140",
    },
    {
      value: "แม่อูคอ ขุนยวม แม่ฮ่องสอน 58140",
      label: "แม่อูคอ ขุนยวม แม่ฮ่องสอน 58140",
    },
    {
      value: "แม่เงา ขุนยวม แม่ฮ่องสอน 58140",
      label: "แม่เงา ขุนยวม แม่ฮ่องสอน 58140",
    },
    {
      value: "ถ้ำลอด ปางมะผ้า แม่ฮ่องสอน 58150",
      label: "ถ้ำลอด ปางมะผ้า แม่ฮ่องสอน 58150",
    },
    {
      value: "นาปู่ป้อม ปางมะผ้า แม่ฮ่องสอน 58150",
      label: "นาปู่ป้อม ปางมะผ้า แม่ฮ่องสอน 58150",
    },
    {
      value: "ปางมะผ้า ปางมะผ้า แม่ฮ่องสอน 58150",
      label: "ปางมะผ้า ปางมะผ้า แม่ฮ่องสอน 58150",
    },
    {
      value: "สบป่อง ปางมะผ้า แม่ฮ่องสอน 58150",
      label: "สบป่อง ปางมะผ้า แม่ฮ่องสอน 58150",
    },
    {
      value: "ทุ่งยาว ปาย แม่ฮ่องสอน 58130",
      label: "ทุ่งยาว ปาย แม่ฮ่องสอน 58130",
    },
    {
      value: "เมืองแปง ปาย แม่ฮ่องสอน 58130",
      label: "เมืองแปง ปาย แม่ฮ่องสอน 58130",
    },
    {
      value: "เวียงเหนือ ปาย แม่ฮ่องสอน 58130",
      label: "เวียงเหนือ ปาย แม่ฮ่องสอน 58130",
    },
    {
      value: "เวียงใต้ ปาย แม่ฮ่องสอน 58130",
      label: "เวียงใต้ ปาย แม่ฮ่องสอน 58130",
    },
    {
      value: "แม่นาเติง ปาย แม่ฮ่องสอน 58130",
      label: "แม่นาเติง ปาย แม่ฮ่องสอน 58130",
    },
    {
      value: "แม่ฮี้ ปาย แม่ฮ่องสอน 58130",
      label: "แม่ฮี้ ปาย แม่ฮ่องสอน 58130",
    },
    {
      value: "โป่งสา ปาย แม่ฮ่องสอน 58130",
      label: "โป่งสา ปาย แม่ฮ่องสอน 58130",
    },
    {
      value: "กองก๋อย สบเมย แม่ฮ่องสอน 58110",
      label: "กองก๋อย สบเมย แม่ฮ่องสอน 58110",
    },
    {
      value: "ป่าโปง สบเมย แม่ฮ่องสอน 58110",
      label: "ป่าโปง สบเมย แม่ฮ่องสอน 58110",
    },
    {
      value: "สบเมย สบเมย แม่ฮ่องสอน 58110",
      label: "สบเมย สบเมย แม่ฮ่องสอน 58110",
    },
    {
      value: "แม่คะตวน สบเมย แม่ฮ่องสอน 58110",
      label: "แม่คะตวน สบเมย แม่ฮ่องสอน 58110",
    },
    {
      value: "แม่สวด สบเมย แม่ฮ่องสอน 58110",
      label: "แม่สวด สบเมย แม่ฮ่องสอน 58110",
    },
    {
      value: "แม่สามแลบ สบเมย แม่ฮ่องสอน 58110",
      label: "แม่สามแลบ สบเมย แม่ฮ่องสอน 58110",
    },
    {
      value: "จองคำ เมืองแม่ฮ่องสอน แม่ฮ่องสอน 58000",
      label: "จองคำ เมืองแม่ฮ่องสอน แม่ฮ่องสอน 58000",
    },
    {
      value: "ปางหมู เมืองแม่ฮ่องสอน แม่ฮ่องสอน 58000",
      label: "ปางหมู เมืองแม่ฮ่องสอน แม่ฮ่องสอน 58000",
    },
    {
      value: "ผาบ่อง เมืองแม่ฮ่องสอน แม่ฮ่องสอน 58000",
      label: "ผาบ่อง เมืองแม่ฮ่องสอน แม่ฮ่องสอน 58000",
    },
    {
      value: "หมอกจำแป่ เมืองแม่ฮ่องสอน แม่ฮ่องสอน 58000",
      label: "หมอกจำแป่ เมืองแม่ฮ่องสอน แม่ฮ่องสอน 58000",
    },
    {
      value: "ห้วยปูลิง เมืองแม่ฮ่องสอน แม่ฮ่องสอน 58000",
      label: "ห้วยปูลิง เมืองแม่ฮ่องสอน แม่ฮ่องสอน 58000",
    },
    {
      value: "ห้วยผา เมืองแม่ฮ่องสอน แม่ฮ่องสอน 58000",
      label: "ห้วยผา เมืองแม่ฮ่องสอน แม่ฮ่องสอน 58000",
    },
    {
      value: "ห้วยโป่ง เมืองแม่ฮ่องสอน แม่ฮ่องสอน 58000",
      label: "ห้วยโป่ง เมืองแม่ฮ่องสอน แม่ฮ่องสอน 58000",
    },
    {
      value: "ขุนแม่ลาน้อย แม่ลาน้อย แม่ฮ่องสอน 58120",
      label: "ขุนแม่ลาน้อย แม่ลาน้อย แม่ฮ่องสอน 58120",
    },
    {
      value: "ท่าผาปุ้ม แม่ลาน้อย แม่ฮ่องสอน 58120",
      label: "ท่าผาปุ้ม แม่ลาน้อย แม่ฮ่องสอน 58120",
    },
    {
      value: "สันติคีรี แม่ลาน้อย แม่ฮ่องสอน 58120",
      label: "สันติคีรี แม่ลาน้อย แม่ฮ่องสอน 58120",
    },
    {
      value: "ห้วยห้อม แม่ลาน้อย แม่ฮ่องสอน 58120",
      label: "ห้วยห้อม แม่ลาน้อย แม่ฮ่องสอน 58120",
    },
    {
      value: "แม่นาจาง แม่ลาน้อย แม่ฮ่องสอน 58120",
      label: "แม่นาจาง แม่ลาน้อย แม่ฮ่องสอน 58120",
    },
    {
      value: "แม่ลาน้อย แม่ลาน้อย แม่ฮ่องสอน 58120",
      label: "แม่ลาน้อย แม่ลาน้อย แม่ฮ่องสอน 58120",
    },
    {
      value: "แม่ลาหลวง แม่ลาน้อย แม่ฮ่องสอน 58120",
      label: "แม่ลาหลวง แม่ลาน้อย แม่ฮ่องสอน 58120",
    },
    {
      value: "แม่โถ แม่ลาน้อย แม่ฮ่องสอน 58120",
      label: "แม่โถ แม่ลาน้อย แม่ฮ่องสอน 58120",
    },
    {
      value: "บ้านกาศ แม่สะเรียง แม่ฮ่องสอน 58110",
      label: "บ้านกาศ แม่สะเรียง แม่ฮ่องสอน 58110",
    },
    {
      value: "ป่าแป๋ แม่สะเรียง แม่ฮ่องสอน 58110",
      label: "ป่าแป๋ แม่สะเรียง แม่ฮ่องสอน 58110",
    },
    {
      value: "เสาหิน แม่สะเรียง แม่ฮ่องสอน 58110",
      label: "เสาหิน แม่สะเรียง แม่ฮ่องสอน 58110",
    },
    {
      value: "แม่คง แม่สะเรียง แม่ฮ่องสอน 58110",
      label: "แม่คง แม่สะเรียง แม่ฮ่องสอน 58110",
    },
    {
      value: "แม่ยวม แม่สะเรียง แม่ฮ่องสอน 58110",
      label: "แม่ยวม แม่สะเรียง แม่ฮ่องสอน 58110",
    },
    {
      value: "แม่สะเรียง แม่สะเรียง แม่ฮ่องสอน 58110",
      label: "แม่สะเรียง แม่สะเรียง แม่ฮ่องสอน 58110",
    },
    {
      value: "แม่เหาะ แม่สะเรียง แม่ฮ่องสอน 58110",
      label: "แม่เหาะ แม่สะเรียง แม่ฮ่องสอน 58110",
    },
    { value: "นาแก งาว ลำปาง 52110", label: "นาแก งาว ลำปาง 52110" },
    { value: "บ้านร้อง งาว ลำปาง 52110", label: "บ้านร้อง งาว ลำปาง 52110" },
    { value: "บ้านหวด งาว ลำปาง 52110", label: "บ้านหวด งาว ลำปาง 52110" },
    { value: "บ้านอ้อน งาว ลำปาง 52110", label: "บ้านอ้อน งาว ลำปาง 52110" },
    { value: "บ้านแหง งาว ลำปาง 52110", label: "บ้านแหง งาว ลำปาง 52110" },
    { value: "บ้านโป่ง งาว ลำปาง 52110", label: "บ้านโป่ง งาว ลำปาง 52110" },
    { value: "ปงเตา งาว ลำปาง 52110", label: "ปงเตา งาว ลำปาง 52110" },
    { value: "หลวงเหนือ งาว ลำปาง 52110", label: "หลวงเหนือ งาว ลำปาง 52110" },
    { value: "หลวงใต้ งาว ลำปาง 52110", label: "หลวงใต้ งาว ลำปาง 52110" },
    { value: "แม่ตีบ งาว ลำปาง 52110", label: "แม่ตีบ งาว ลำปาง 52110" },
    {
      value: "ทุ่งฮั้ว วังเหนือ ลำปาง 52140",
      label: "ทุ่งฮั้ว วังเหนือ ลำปาง 52140",
    },
    {
      value: "ร่องเคาะ วังเหนือ ลำปาง 52140",
      label: "ร่องเคาะ วังเหนือ ลำปาง 52140",
    },
    {
      value: "วังซ้าย วังเหนือ ลำปาง 52140",
      label: "วังซ้าย วังเหนือ ลำปาง 52140",
    },
    {
      value: "วังทรายคำ วังเหนือ ลำปาง 52140",
      label: "วังทรายคำ วังเหนือ ลำปาง 52140",
    },
    {
      value: "วังทอง วังเหนือ ลำปาง 52140",
      label: "วังทอง วังเหนือ ลำปาง 52140",
    },
    {
      value: "วังเหนือ วังเหนือ ลำปาง 52140",
      label: "วังเหนือ วังเหนือ ลำปาง 52140",
    },
    {
      value: "วังแก้ว วังเหนือ ลำปาง 52140",
      label: "วังแก้ว วังเหนือ ลำปาง 52140",
    },
    {
      value: "วังใต้ วังเหนือ ลำปาง 52140",
      label: "วังใต้ วังเหนือ ลำปาง 52140",
    },
    { value: "นายาง สบปราบ ลำปาง 52170", label: "นายาง สบปราบ ลำปาง 52170" },
    { value: "สบปราบ สบปราบ ลำปาง 52170", label: "สบปราบ สบปราบ ลำปาง 52170" },
    { value: "สมัย สบปราบ ลำปาง 52170", label: "สมัย สบปราบ ลำปาง 52170" },
    { value: "แม่กัวะ สบปราบ ลำปาง 52170", label: "แม่กัวะ สบปราบ ลำปาง 52170" },
    {
      value: "ปงยางคก ห้างฉัตร ลำปาง 52190",
      label: "ปงยางคก ห้างฉัตร ลำปาง 52190",
    },
    {
      value: "วอแก้ว ห้างฉัตร ลำปาง 52190",
      label: "วอแก้ว ห้างฉัตร ลำปาง 52190",
    },
    {
      value: "หนองหล่ม ห้างฉัตร ลำปาง 52190",
      label: "หนองหล่ม ห้างฉัตร ลำปาง 52190",
    },
    {
      value: "ห้างฉัตร ห้างฉัตร ลำปาง 52190",
      label: "ห้างฉัตร ห้างฉัตร ลำปาง 52190",
    },
    {
      value: "เมืองยาว ห้างฉัตร ลำปาง 52190",
      label: "เมืองยาว ห้างฉัตร ลำปาง 52190",
    },
    {
      value: "เวียงตาล ห้างฉัตร ลำปาง 52190",
      label: "เวียงตาล ห้างฉัตร ลำปาง 52190",
    },
    {
      value: "แม่สัน ห้างฉัตร ลำปาง 52190",
      label: "แม่สัน ห้างฉัตร ลำปาง 52190",
    },
    { value: "ท่าผา เกาะคา ลำปาง 52130", label: "ท่าผา เกาะคา ลำปาง 52130" },
    { value: "นาแก้ว เกาะคา ลำปาง 52130", label: "นาแก้ว เกาะคา ลำปาง 52130" },
    { value: "นาแส่ง เกาะคา ลำปาง 52130", label: "นาแส่ง เกาะคา ลำปาง 52130" },
    {
      value: "ลำปางหลวง เกาะคา ลำปาง 52130",
      label: "ลำปางหลวง เกาะคา ลำปาง 52130",
    },
    {
      value: "วังพร้าว เกาะคา ลำปาง 52130",
      label: "วังพร้าว เกาะคา ลำปาง 52130",
    },
    { value: "ศาลา เกาะคา ลำปาง 52130", label: "ศาลา เกาะคา ลำปาง 52130" },
    { value: "เกาะคา เกาะคา ลำปาง 52130", label: "เกาะคา เกาะคา ลำปาง 52130" },
    {
      value: "ใหม่พัฒนา เกาะคา ลำปาง 52130",
      label: "ใหม่พัฒนา เกาะคา ลำปาง 52130",
    },
    { value: "ไหล่หิน เกาะคา ลำปาง 52130", label: "ไหล่หิน เกาะคา ลำปาง 52130" },
    { value: "นาโป่ง เถิน ลำปาง 52160", label: "นาโป่ง เถิน ลำปาง 52160" },
    { value: "ล้อมแรด เถิน ลำปาง 52160", label: "ล้อมแรด เถิน ลำปาง 52160" },
    { value: "เถินบุรี เถิน ลำปาง 52160", label: "เถินบุรี เถิน ลำปาง 52160" },
    { value: "เวียงมอก เถิน ลำปาง 52160", label: "เวียงมอก เถิน ลำปาง 52160" },
    { value: "แม่ถอด เถิน ลำปาง 52160", label: "แม่ถอด เถิน ลำปาง 52160" },
    { value: "แม่ปะ เถิน ลำปาง 52160", label: "แม่ปะ เถิน ลำปาง 52160" },
    { value: "แม่มอก เถิน ลำปาง 52160", label: "แม่มอก เถิน ลำปาง 52160" },
    { value: "แม่วะ เถิน ลำปาง 52230", label: "แม่วะ เถิน ลำปาง 52230" },
    {
      value: "ทุ่งกว๋าว เมืองปาน ลำปาง 52240",
      label: "ทุ่งกว๋าว เมืองปาน ลำปาง 52240",
    },
    {
      value: "บ้านขอ เมืองปาน ลำปาง 52240",
      label: "บ้านขอ เมืองปาน ลำปาง 52240",
    },
    {
      value: "หัวเมือง เมืองปาน ลำปาง 52240",
      label: "หัวเมือง เมืองปาน ลำปาง 52240",
    },
    {
      value: "เมืองปาน เมืองปาน ลำปาง 52240",
      label: "เมืองปาน เมืองปาน ลำปาง 52240",
    },
    {
      value: "แจ้ซ้อน เมืองปาน ลำปาง 52240",
      label: "แจ้ซ้อน เมืองปาน ลำปาง 52240",
    },
    {
      value: "กล้วยแพะ เมืองลำปาง ลำปาง 52000",
      label: "กล้วยแพะ เมืองลำปาง ลำปาง 52000",
    },
    {
      value: "ชมพู เมืองลำปาง ลำปาง 52100",
      label: "ชมพู เมืองลำปาง ลำปาง 52100",
    },
    {
      value: "ต้นธงชัย เมืองลำปาง ลำปาง 52000",
      label: "ต้นธงชัย เมืองลำปาง ลำปาง 52000",
    },
    {
      value: "ทุ่งฝาย เมืองลำปาง ลำปาง 52000",
      label: "ทุ่งฝาย เมืองลำปาง ลำปาง 52000",
    },
    {
      value: "นิคมพัฒนา เมืองลำปาง ลำปาง 52000",
      label: "นิคมพัฒนา เมืองลำปาง ลำปาง 52000",
    },
    {
      value: "บุญนาคพัฒนา เมืองลำปาง ลำปาง 52000",
      label: "บุญนาคพัฒนา เมืองลำปาง ลำปาง 52000",
    },
    {
      value: "บ่อแฮ้ว เมืองลำปาง ลำปาง 52100",
      label: "บ่อแฮ้ว เมืองลำปาง ลำปาง 52100",
    },
    {
      value: "บ้านค่า เมืองลำปาง ลำปาง 52100",
      label: "บ้านค่า เมืองลำปาง ลำปาง 52100",
    },
    {
      value: "บ้านเป้า เมืองลำปาง ลำปาง 52100",
      label: "บ้านเป้า เมืองลำปาง ลำปาง 52100",
    },
    {
      value: "บ้านเสด็จ เมืองลำปาง ลำปาง 52000",
      label: "บ้านเสด็จ เมืองลำปาง ลำปาง 52000",
    },
    {
      value: "บ้านเอื้อม เมืองลำปาง ลำปาง 52100",
      label: "บ้านเอื้อม เมืองลำปาง ลำปาง 52100",
    },
    {
      value: "บ้านแลง เมืองลำปาง ลำปาง 52000",
      label: "บ้านแลง เมืองลำปาง ลำปาง 52000",
    },
    {
      value: "ปงแสนทอง เมืองลำปาง ลำปาง 52100",
      label: "ปงแสนทอง เมืองลำปาง ลำปาง 52100",
    },
    {
      value: "พระบาท เมืองลำปาง ลำปาง 52000",
      label: "พระบาท เมืองลำปาง ลำปาง 52000",
    },
    {
      value: "พิชัย เมืองลำปาง ลำปาง 52000",
      label: "พิชัย เมืองลำปาง ลำปาง 52000",
    },
    {
      value: "สบตุ๋ย เมืองลำปาง ลำปาง 52100",
      label: "สบตุ๋ย เมืองลำปาง ลำปาง 52100",
    },
    {
      value: "สวนดอก เมืองลำปาง ลำปาง 52100",
      label: "สวนดอก เมืองลำปาง ลำปาง 52100",
    },
    {
      value: "หัวเวียง เมืองลำปาง ลำปาง 52000",
      label: "หัวเวียง เมืองลำปาง ลำปาง 52000",
    },
    {
      value: "เวียงเหนือ เมืองลำปาง ลำปาง 52000",
      label: "เวียงเหนือ เมืองลำปาง ลำปาง 52000",
    },
    {
      value: "ทุ่งงาม เสริมงาม ลำปาง 52210",
      label: "ทุ่งงาม เสริมงาม ลำปาง 52210",
    },
    {
      value: "เสริมกลาง เสริมงาม ลำปาง 52210",
      label: "เสริมกลาง เสริมงาม ลำปาง 52210",
    },
    {
      value: "เสริมขวา เสริมงาม ลำปาง 52210",
      label: "เสริมขวา เสริมงาม ลำปาง 52210",
    },
    {
      value: "เสริมซ้าย เสริมงาม ลำปาง 52210",
      label: "เสริมซ้าย เสริมงาม ลำปาง 52210",
    },
    {
      value: "ทุ่งผึ้ง แจ้ห่ม ลำปาง 52120",
      label: "ทุ่งผึ้ง แจ้ห่ม ลำปาง 52120",
    },
    { value: "บ้านสา แจ้ห่ม ลำปาง 52120", label: "บ้านสา แจ้ห่ม ลำปาง 52120" },
    { value: "ปงดอน แจ้ห่ม ลำปาง 52120", label: "ปงดอน แจ้ห่ม ลำปาง 52120" },
    {
      value: "วิเชตนคร แจ้ห่ม ลำปาง 52120",
      label: "วิเชตนคร แจ้ห่ม ลำปาง 52120",
    },
    {
      value: "เมืองมาย แจ้ห่ม ลำปาง 52120",
      label: "เมืองมาย แจ้ห่ม ลำปาง 52120",
    },
    { value: "แจ้ห่ม แจ้ห่ม ลำปาง 52120", label: "แจ้ห่ม แจ้ห่ม ลำปาง 52120" },
    { value: "แม่สุก แจ้ห่ม ลำปาง 52120", label: "แม่สุก แจ้ห่ม ลำปาง 52120" },
    { value: "ดอนไฟ แม่ทะ ลำปาง 52150", label: "ดอนไฟ แม่ทะ ลำปาง 52150" },
    { value: "นาครัว แม่ทะ ลำปาง 52150", label: "นาครัว แม่ทะ ลำปาง 52150" },
    { value: "น้ำโจ้ แม่ทะ ลำปาง 52150", label: "น้ำโจ้ แม่ทะ ลำปาง 52150" },
    { value: "บ้านกิ่ว แม่ทะ ลำปาง 52150", label: "บ้านกิ่ว แม่ทะ ลำปาง 52150" },
    { value: "บ้านบอม แม่ทะ ลำปาง 52150", label: "บ้านบอม แม่ทะ ลำปาง 52150" },
    { value: "ป่าตัน แม่ทะ ลำปาง 52150", label: "ป่าตัน แม่ทะ ลำปาง 52150" },
    { value: "วังเงิน แม่ทะ ลำปาง 52150", label: "วังเงิน แม่ทะ ลำปาง 52150" },
    {
      value: "สันดอนแก้ว แม่ทะ ลำปาง 52150",
      label: "สันดอนแก้ว แม่ทะ ลำปาง 52150",
    },
    { value: "หัวเสือ แม่ทะ ลำปาง 52150", label: "หัวเสือ แม่ทะ ลำปาง 52150" },
    { value: "แม่ทะ แม่ทะ ลำปาง 52150", label: "แม่ทะ แม่ทะ ลำปาง 52150" },
    { value: "ผาปัง แม่พริก ลำปาง 52180", label: "ผาปัง แม่พริก ลำปาง 52180" },
    {
      value: "พระบาทวังตวง แม่พริก ลำปาง 52230",
      label: "พระบาทวังตวง แม่พริก ลำปาง 52230",
    },
    { value: "แม่ปุ แม่พริก ลำปาง 52180", label: "แม่ปุ แม่พริก ลำปาง 52180" },
    {
      value: "แม่พริก แม่พริก ลำปาง 52180",
      label: "แม่พริก แม่พริก ลำปาง 52180",
    },
    {
      value: "จางเหนือ แม่เมาะ ลำปาง 52220",
      label: "จางเหนือ แม่เมาะ ลำปาง 52220",
    },
    { value: "นาสัก แม่เมาะ ลำปาง 52220", label: "นาสัก แม่เมาะ ลำปาง 52220" },
    { value: "บ้านดง แม่เมาะ ลำปาง 52220", label: "บ้านดง แม่เมาะ ลำปาง 52220" },
    { value: "สบป้าด แม่เมาะ ลำปาง 52220", label: "สบป้าด แม่เมาะ ลำปาง 52220" },
    {
      value: "แม่เมาะ แม่เมาะ ลำปาง 52220",
      label: "แม่เมาะ แม่เมาะ ลำปาง 52220",
    },
    {
      value: "ตะเคียนปม ทุ่งหัวช้าง ลำพูน 51160",
      label: "ตะเคียนปม ทุ่งหัวช้าง ลำพูน 51160",
    },
    {
      value: "ทุ่งหัวช้าง ทุ่งหัวช้าง ลำพูน 51160",
      label: "ทุ่งหัวช้าง ทุ่งหัวช้าง ลำพูน 51160",
    },
    {
      value: "บ้านปวง ทุ่งหัวช้าง ลำพูน 51160",
      label: "บ้านปวง ทุ่งหัวช้าง ลำพูน 51160",
    },
    { value: "บ้านธิ บ้านธิ ลำพูน 51180", label: "บ้านธิ บ้านธิ ลำพูน 51180" },
    { value: "ห้วยยาบ บ้านธิ ลำพูน 51180", label: "ห้วยยาบ บ้านธิ ลำพูน 51180" },
    {
      value: "บ้านโฮ่ง บ้านโฮ่ง ลำพูน 51130",
      label: "บ้านโฮ่ง บ้านโฮ่ง ลำพูน 51130",
    },
    {
      value: "ป่าพลู บ้านโฮ่ง ลำพูน 51130",
      label: "ป่าพลู บ้านโฮ่ง ลำพูน 51130",
    },
    {
      value: "ศรีเตี้ย บ้านโฮ่ง ลำพูน 51130",
      label: "ศรีเตี้ย บ้านโฮ่ง ลำพูน 51130",
    },
    {
      value: "หนองปลาสะวาย บ้านโฮ่ง ลำพูน 51130",
      label: "หนองปลาสะวาย บ้านโฮ่ง ลำพูน 51130",
    },
    {
      value: "เหล่ายาว บ้านโฮ่ง ลำพูน 51130",
      label: "เหล่ายาว บ้านโฮ่ง ลำพูน 51130",
    },
    { value: "ท่าตุ้ม ป่าซาง ลำพูน 51120", label: "ท่าตุ้ม ป่าซาง ลำพูน 51120" },
    {
      value: "นครเจดีย์ ป่าซาง ลำพูน 51120",
      label: "นครเจดีย์ ป่าซาง ลำพูน 51120",
    },
    { value: "น้ำดิบ ป่าซาง ลำพูน 51120", label: "น้ำดิบ ป่าซาง ลำพูน 51120" },
    {
      value: "บ้านเรือน ป่าซาง ลำพูน 51120",
      label: "บ้านเรือน ป่าซาง ลำพูน 51120",
    },
    { value: "ปากบ่อง ป่าซาง ลำพูน 51120", label: "ปากบ่อง ป่าซาง ลำพูน 51120" },
    { value: "ป่าซาง ป่าซาง ลำพูน 51120", label: "ป่าซาง ป่าซาง ลำพูน 51120" },
    { value: "มะกอก ป่าซาง ลำพูน 51120", label: "มะกอก ป่าซาง ลำพูน 51120" },
    {
      value: "ม่วงน้อย ป่าซาง ลำพูน 51120",
      label: "ม่วงน้อย ป่าซาง ลำพูน 51120",
    },
    { value: "แม่แรง ป่าซาง ลำพูน 51120", label: "แม่แรง ป่าซาง ลำพูน 51120" },
    { value: "ก้อ ลี้ ลำพูน 51110", label: "ก้อ ลี้ ลำพูน 51110" },
    { value: "ดงดำ ลี้ ลำพูน 51110", label: "ดงดำ ลี้ ลำพูน 51110" },
    { value: "นาทราย ลี้ ลำพูน 51110", label: "นาทราย ลี้ ลำพูน 51110" },
    { value: "ป่าไผ่ ลี้ ลำพูน 51110", label: "ป่าไผ่ ลี้ ลำพูน 51110" },
    { value: "ลี้ ลี้ ลำพูน 51110", label: "ลี้ ลี้ ลำพูน 51110" },
    { value: "ศรีวิชัย ลี้ ลำพูน 51110", label: "ศรีวิชัย ลี้ ลำพูน 51110" },
    { value: "แม่ตืน ลี้ ลำพูน 51110", label: "แม่ตืน ลี้ ลำพูน 51110" },
    { value: "แม่ลาน ลี้ ลำพูน 51110", label: "แม่ลาน ลี้ ลำพูน 51110" },
    {
      value: "ต้นธง เมืองลำพูน ลำพูน 51000",
      label: "ต้นธง เมืองลำพูน ลำพูน 51000",
    },
    {
      value: "บ้านกลาง เมืองลำพูน ลำพูน 51000",
      label: "บ้านกลาง เมืองลำพูน ลำพูน 51000",
    },
    {
      value: "บ้านแป้น เมืองลำพูน ลำพูน 51000",
      label: "บ้านแป้น เมืองลำพูน ลำพูน 51000",
    },
    {
      value: "ประตูป่า เมืองลำพูน ลำพูน 51000",
      label: "ประตูป่า เมืองลำพูน ลำพูน 51000",
    },
    {
      value: "ป่าสัก เมืองลำพูน ลำพูน 51000",
      label: "ป่าสัก เมืองลำพูน ลำพูน 51000",
    },
    {
      value: "มะเขือแจ้ เมืองลำพูน ลำพูน 51000",
      label: "มะเขือแจ้ เมืองลำพูน ลำพูน 51000",
    },
    {
      value: "ริมปิง เมืองลำพูน ลำพูน 51000",
      label: "ริมปิง เมืองลำพูน ลำพูน 51000",
    },
    {
      value: "ศรีบัวบาน เมืองลำพูน ลำพูน 51000",
      label: "ศรีบัวบาน เมืองลำพูน ลำพูน 51000",
    },
    {
      value: "หนองช้างคืน เมืองลำพูน ลำพูน 51150",
      label: "หนองช้างคืน เมืองลำพูน ลำพูน 51150",
    },
    {
      value: "หนองหนาม เมืองลำพูน ลำพูน 51000",
      label: "หนองหนาม เมืองลำพูน ลำพูน 51000",
    },
    {
      value: "อุโมงค์ เมืองลำพูน ลำพูน 51150",
      label: "อุโมงค์ เมืองลำพูน ลำพูน 51150",
    },
    {
      value: "เวียงยอง เมืองลำพูน ลำพูน 51000",
      label: "เวียงยอง เมืองลำพูน ลำพูน 51000",
    },
    {
      value: "เหมืองง่า เมืองลำพูน ลำพูน 51000",
      label: "เหมืองง่า เมืองลำพูน ลำพูน 51000",
    },
    {
      value: "เหมืองจี้ เมืองลำพูน ลำพูน 51000",
      label: "เหมืองจี้ เมืองลำพูน ลำพูน 51000",
    },
    {
      value: "ในเมือง เมืองลำพูน ลำพูน 51000",
      label: "ในเมือง เมืองลำพูน ลำพูน 51000",
    },
    {
      value: "วังผาง เวียงหนองล่อง ลำพูน 51120",
      label: "วังผาง เวียงหนองล่อง ลำพูน 51120",
    },
    {
      value: "หนองยวง เวียงหนองล่อง ลำพูน 51120",
      label: "หนองยวง เวียงหนองล่อง ลำพูน 51120",
    },
    {
      value: "หนองล่อง เวียงหนองล่อง ลำพูน 51120",
      label: "หนองล่อง เวียงหนองล่อง ลำพูน 51120",
    },
    { value: "ทากาศ แม่ทา ลำพูน 51170", label: "ทากาศ แม่ทา ลำพูน 51170" },
    {
      value: "ทาขุมเงิน แม่ทา ลำพูน 51170",
      label: "ทาขุมเงิน แม่ทา ลำพูน 51170",
    },
    {
      value: "ทาทุ่งหลวง แม่ทา ลำพูน 51170",
      label: "ทาทุ่งหลวง แม่ทา ลำพูน 51170",
    },
    { value: "ทาปลาดุก แม่ทา ลำพูน 51140", label: "ทาปลาดุก แม่ทา ลำพูน 51140" },
    { value: "ทาสบเส้า แม่ทา ลำพูน 51140", label: "ทาสบเส้า แม่ทา ลำพูน 51140" },
    { value: "ทาแม่ลอบ แม่ทา ลำพูน 51170", label: "ทาแม่ลอบ แม่ทา ลำพูน 51170" },
    {
      value: "ข่อยสูง ตรอน อุตรดิตถ์ 53140",
      label: "ข่อยสูง ตรอน อุตรดิตถ์ 53140",
    },
    {
      value: "น้ำอ่าง ตรอน อุตรดิตถ์ 53140",
      label: "น้ำอ่าง ตรอน อุตรดิตถ์ 53140",
    },
    {
      value: "บ้านแก่ง ตรอน อุตรดิตถ์ 53140",
      label: "บ้านแก่ง ตรอน อุตรดิตถ์ 53140",
    },
    {
      value: "วังแดง ตรอน อุตรดิตถ์ 53140",
      label: "วังแดง ตรอน อุตรดิตถ์ 53140",
    },
    {
      value: "หาดสองแคว ตรอน อุตรดิตถ์ 53140",
      label: "หาดสองแคว ตรอน อุตรดิตถ์ 53140",
    },
    {
      value: "น้ำพี้ ทองแสนขัน อุตรดิตถ์ 53230",
      label: "น้ำพี้ ทองแสนขัน อุตรดิตถ์ 53230",
    },
    {
      value: "บ่อทอง ทองแสนขัน อุตรดิตถ์ 53230",
      label: "บ่อทอง ทองแสนขัน อุตรดิตถ์ 53230",
    },
    {
      value: "ป่าคาย ทองแสนขัน อุตรดิตถ์ 53230",
      label: "ป่าคาย ทองแสนขัน อุตรดิตถ์ 53230",
    },
    {
      value: "ผักขวง ทองแสนขัน อุตรดิตถ์ 53230",
      label: "ผักขวง ทองแสนขัน อุตรดิตถ์ 53230",
    },
    {
      value: "จริม ท่าปลา อุตรดิตถ์ 53150",
      label: "จริม ท่าปลา อุตรดิตถ์ 53150",
    },
    {
      value: "ท่าปลา ท่าปลา อุตรดิตถ์ 53150",
      label: "ท่าปลา ท่าปลา อุตรดิตถ์ 53150",
    },
    {
      value: "นางพญา ท่าปลา อุตรดิตถ์ 53150",
      label: "นางพญา ท่าปลา อุตรดิตถ์ 53150",
    },
    {
      value: "น้ำหมัน ท่าปลา อุตรดิตถ์ 53150",
      label: "น้ำหมัน ท่าปลา อุตรดิตถ์ 53150",
    },
    {
      value: "ผาเลือด ท่าปลา อุตรดิตถ์ 53190",
      label: "ผาเลือด ท่าปลา อุตรดิตถ์ 53190",
    },
    {
      value: "ร่วมจิต ท่าปลา อุตรดิตถ์ 53190",
      label: "ร่วมจิต ท่าปลา อุตรดิตถ์ 53190",
    },
    {
      value: "หาดล้า ท่าปลา อุตรดิตถ์ 53190",
      label: "หาดล้า ท่าปลา อุตรดิตถ์ 53190",
    },
    {
      value: "ท่าแฝก น้ำปาด อุตรดิตถ์ 53110",
      label: "ท่าแฝก น้ำปาด อุตรดิตถ์ 53110",
    },
    {
      value: "น้ำไคร้ น้ำปาด อุตรดิตถ์ 53110",
      label: "น้ำไคร้ น้ำปาด อุตรดิตถ์ 53110",
    },
    {
      value: "น้ำไผ่ น้ำปาด อุตรดิตถ์ 53110",
      label: "น้ำไผ่ น้ำปาด อุตรดิตถ์ 53110",
    },
    {
      value: "บ้านฝาย น้ำปาด อุตรดิตถ์ 53110",
      label: "บ้านฝาย น้ำปาด อุตรดิตถ์ 53110",
    },
    {
      value: "ห้วยมุ่น น้ำปาด อุตรดิตถ์ 53110",
      label: "ห้วยมุ่น น้ำปาด อุตรดิตถ์ 53110",
    },
    {
      value: "เด่นเหล็ก น้ำปาด อุตรดิตถ์ 53110",
      label: "เด่นเหล็ก น้ำปาด อุตรดิตถ์ 53110",
    },
    {
      value: "แสนตอ น้ำปาด อุตรดิตถ์ 53110",
      label: "แสนตอ น้ำปาด อุตรดิตถ์ 53110",
    },
    {
      value: "นาขุม บ้านโคก อุตรดิตถ์ 53180",
      label: "นาขุม บ้านโคก อุตรดิตถ์ 53180",
    },
    {
      value: "บ่อเบี้ย บ้านโคก อุตรดิตถ์ 53180",
      label: "บ่อเบี้ย บ้านโคก อุตรดิตถ์ 53180",
    },
    {
      value: "บ้านโคก บ้านโคก อุตรดิตถ์ 53180",
      label: "บ้านโคก บ้านโคก อุตรดิตถ์ 53180",
    },
    {
      value: "ม่วงเจ็ดต้น บ้านโคก อุตรดิตถ์ 53180",
      label: "ม่วงเจ็ดต้น บ้านโคก อุตรดิตถ์ 53180",
    },
    {
      value: "คอรุม พิชัย อุตรดิตถ์ 53120",
      label: "คอรุม พิชัย อุตรดิตถ์ 53120",
    },
    {
      value: "ท่ามะเฟือง พิชัย อุตรดิตถ์ 53120",
      label: "ท่ามะเฟือง พิชัย อุตรดิตถ์ 53120",
    },
    {
      value: "ท่าสัก พิชัย อุตรดิตถ์ 53220",
      label: "ท่าสัก พิชัย อุตรดิตถ์ 53220",
    },
    {
      value: "นายาง พิชัย อุตรดิตถ์ 53120",
      label: "นายาง พิชัย อุตรดิตถ์ 53120",
    },
    {
      value: "นาอิน พิชัย อุตรดิตถ์ 53120",
      label: "นาอิน พิชัย อุตรดิตถ์ 53120",
    },
    {
      value: "บ้านดารา พิชัย อุตรดิตถ์ 53220",
      label: "บ้านดารา พิชัย อุตรดิตถ์ 53220",
    },
    {
      value: "บ้านหม้อ พิชัย อุตรดิตถ์ 53120",
      label: "บ้านหม้อ พิชัย อุตรดิตถ์ 53120",
    },
    {
      value: "บ้านโคน พิชัย อุตรดิตถ์ 53120",
      label: "บ้านโคน พิชัย อุตรดิตถ์ 53120",
    },
    {
      value: "พญาแมน พิชัย อุตรดิตถ์ 53120",
      label: "พญาแมน พิชัย อุตรดิตถ์ 53120",
    },
    {
      value: "ในเมือง พิชัย อุตรดิตถ์ 53120",
      label: "ในเมือง พิชัย อุตรดิตถ์ 53120",
    },
    {
      value: "ไร่อ้อย พิชัย อุตรดิตถ์ 53120",
      label: "ไร่อ้อย พิชัย อุตรดิตถ์ 53120",
    },
    {
      value: "บ้านเสี้ยว ฟากท่า อุตรดิตถ์ 53160",
      label: "บ้านเสี้ยว ฟากท่า อุตรดิตถ์ 53160",
    },
    {
      value: "ฟากท่า ฟากท่า อุตรดิตถ์ 53160",
      label: "ฟากท่า ฟากท่า อุตรดิตถ์ 53160",
    },
    {
      value: "สองคอน ฟากท่า อุตรดิตถ์ 53160",
      label: "สองคอน ฟากท่า อุตรดิตถ์ 53160",
    },
    {
      value: "สองห้อง ฟากท่า อุตรดิตถ์ 53160",
      label: "สองห้อง ฟากท่า อุตรดิตถ์ 53160",
    },
    {
      value: "ชัยจุมพล ลับแล อุตรดิตถ์ 53130",
      label: "ชัยจุมพล ลับแล อุตรดิตถ์ 53130",
    },
    {
      value: "ด่านแม่คำมัน ลับแล อุตรดิตถ์ 53210",
      label: "ด่านแม่คำมัน ลับแล อุตรดิตถ์ 53210",
    },
    {
      value: "ทุ่งยั้ง ลับแล อุตรดิตถ์ 53210",
      label: "ทุ่งยั้ง ลับแล อุตรดิตถ์ 53210",
    },
    {
      value: "นานกกก ลับแล อุตรดิตถ์ 53130",
      label: "นานกกก ลับแล อุตรดิตถ์ 53130",
    },
    {
      value: "ฝายหลวง ลับแล อุตรดิตถ์ 53130",
      label: "ฝายหลวง ลับแล อุตรดิตถ์ 53130",
    },
    {
      value: "ศรีพนมมาศ ลับแล อุตรดิตถ์ 53130",
      label: "ศรีพนมมาศ ลับแล อุตรดิตถ์ 53130",
    },
    {
      value: "แม่พูล ลับแล อุตรดิตถ์ 53130",
      label: "แม่พูล ลับแล อุตรดิตถ์ 53130",
    },
    {
      value: "ไผ่ล้อม ลับแล อุตรดิตถ์ 53210",
      label: "ไผ่ล้อม ลับแล อุตรดิตถ์ 53210",
    },
    {
      value: "ขุนฝาง เมืองอุตรดิตถ์ อุตรดิตถ์ 53000",
      label: "ขุนฝาง เมืองอุตรดิตถ์ อุตรดิตถ์ 53000",
    },
    {
      value: "คุ้งตะเภา เมืองอุตรดิตถ์ อุตรดิตถ์ 53000",
      label: "คุ้งตะเภา เมืองอุตรดิตถ์ อุตรดิตถ์ 53000",
    },
    {
      value: "งิ้วงาม เมืองอุตรดิตถ์ อุตรดิตถ์ 53000",
      label: "งิ้วงาม เมืองอุตรดิตถ์ อุตรดิตถ์ 53000",
    },
    {
      value: "ถ้ำฉลอง เมืองอุตรดิตถ์ อุตรดิตถ์ 53000",
      label: "ถ้ำฉลอง เมืองอุตรดิตถ์ อุตรดิตถ์ 53000",
    },
    {
      value: "ท่าอิฐ เมืองอุตรดิตถ์ อุตรดิตถ์ 53000",
      label: "ท่าอิฐ เมืองอุตรดิตถ์ อุตรดิตถ์ 53000",
    },
    {
      value: "ท่าเสา เมืองอุตรดิตถ์ อุตรดิตถ์ 53000",
      label: "ท่าเสา เมืองอุตรดิตถ์ อุตรดิตถ์ 53000",
    },
    {
      value: "น้ำริด เมืองอุตรดิตถ์ อุตรดิตถ์ 53000",
      label: "น้ำริด เมืองอุตรดิตถ์ อุตรดิตถ์ 53000",
    },
    {
      value: "บ้านด่าน เมืองอุตรดิตถ์ อุตรดิตถ์ 53000",
      label: "บ้านด่าน เมืองอุตรดิตถ์ อุตรดิตถ์ 53000",
    },
    {
      value: "บ้านด่านนาขาม เมืองอุตรดิตถ์ อุตรดิตถ์ 53000",
      label: "บ้านด่านนาขาม เมืองอุตรดิตถ์ อุตรดิตถ์ 53000",
    },
    {
      value: "บ้านเกาะ เมืองอุตรดิตถ์ อุตรดิตถ์ 53000",
      label: "บ้านเกาะ เมืองอุตรดิตถ์ อุตรดิตถ์ 53000",
    },
    {
      value: "ป่าเซ่า เมืองอุตรดิตถ์ อุตรดิตถ์ 53000",
      label: "ป่าเซ่า เมืองอุตรดิตถ์ อุตรดิตถ์ 53000",
    },
    {
      value: "ผาจุก เมืองอุตรดิตถ์ อุตรดิตถ์ 53000",
      label: "ผาจุก เมืองอุตรดิตถ์ อุตรดิตถ์ 53000",
    },
    {
      value: "วังกะพี้ เมืองอุตรดิตถ์ อุตรดิตถ์ 53170",
      label: "วังกะพี้ เมืองอุตรดิตถ์ อุตรดิตถ์ 53170",
    },
    {
      value: "วังดิน เมืองอุตรดิตถ์ อุตรดิตถ์ 53000",
      label: "วังดิน เมืองอุตรดิตถ์ อุตรดิตถ์ 53000",
    },
    {
      value: "หาดกรวด เมืองอุตรดิตถ์ อุตรดิตถ์ 53000",
      label: "หาดกรวด เมืองอุตรดิตถ์ อุตรดิตถ์ 53000",
    },
    {
      value: "หาดงิ้ว เมืองอุตรดิตถ์ อุตรดิตถ์ 53000",
      label: "หาดงิ้ว เมืองอุตรดิตถ์ อุตรดิตถ์ 53000",
    },
    {
      value: "แสนตอ เมืองอุตรดิตถ์ อุตรดิตถ์ 53000",
      label: "แสนตอ เมืองอุตรดิตถ์ อุตรดิตถ์ 53000",
    },
    {
      value: "กมลาไสย กมลาไสย กาฬสินธุ์ 46130",
      label: "กมลาไสย กมลาไสย กาฬสินธุ์ 46130",
    },
    {
      value: "ดงลิง กมลาไสย กาฬสินธุ์ 46130",
      label: "ดงลิง กมลาไสย กาฬสินธุ์ 46130",
    },
    {
      value: "ธัญญา กมลาไสย กาฬสินธุ์ 46130",
      label: "ธัญญา กมลาไสย กาฬสินธุ์ 46130",
    },
    {
      value: "หนองแปน กมลาไสย กาฬสินธุ์ 46130",
      label: "หนองแปน กมลาไสย กาฬสินธุ์ 46130",
    },
    {
      value: "หลักเมือง กมลาไสย กาฬสินธุ์ 46130",
      label: "หลักเมือง กมลาไสย กาฬสินธุ์ 46130",
    },
    {
      value: "เจ้าท่า กมลาไสย กาฬสินธุ์ 46130",
      label: "เจ้าท่า กมลาไสย กาฬสินธุ์ 46130",
    },
    {
      value: "โคกสมบูรณ์ กมลาไสย กาฬสินธุ์ 46130",
      label: "โคกสมบูรณ์ กมลาไสย กาฬสินธุ์ 46130",
    },
    {
      value: "โพนงาม กมลาไสย กาฬสินธุ์ 46130",
      label: "โพนงาม กมลาไสย กาฬสินธุ์ 46130",
    },
    {
      value: "กุดค้าว กุฉินารายณ์ กาฬสินธุ์ 46110",
      label: "กุดค้าว กุฉินารายณ์ กาฬสินธุ์ 46110",
    },
    {
      value: "กุดหว้า กุฉินารายณ์ กาฬสินธุ์ 46110",
      label: "กุดหว้า กุฉินารายณ์ กาฬสินธุ์ 46110",
    },
    {
      value: "จุมจัง กุฉินารายณ์ กาฬสินธุ์ 46110",
      label: "จุมจัง กุฉินารายณ์ กาฬสินธุ์ 46110",
    },
    {
      value: "นาขาม กุฉินารายณ์ กาฬสินธุ์ 46110",
      label: "นาขาม กุฉินารายณ์ กาฬสินธุ์ 46110",
    },
    {
      value: "นาโก กุฉินารายณ์ กาฬสินธุ์ 46110",
      label: "นาโก กุฉินารายณ์ กาฬสินธุ์ 46110",
    },
    {
      value: "บัวขาว กุฉินารายณ์ กาฬสินธุ์ 46110",
      label: "บัวขาว กุฉินารายณ์ กาฬสินธุ์ 46110",
    },
    {
      value: "สมสะอาด กุฉินารายณ์ กาฬสินธุ์ 46110",
      label: "สมสะอาด กุฉินารายณ์ กาฬสินธุ์ 46110",
    },
    {
      value: "สามขา กุฉินารายณ์ กาฬสินธุ์ 46110",
      label: "สามขา กุฉินารายณ์ กาฬสินธุ์ 46110",
    },
    {
      value: "หนองห้าง กุฉินารายณ์ กาฬสินธุ์ 46110",
      label: "หนองห้าง กุฉินารายณ์ กาฬสินธุ์ 46110",
    },
    {
      value: "เหล่าใหญ่ กุฉินารายณ์ กาฬสินธุ์ 46110",
      label: "เหล่าใหญ่ กุฉินารายณ์ กาฬสินธุ์ 46110",
    },
    {
      value: "เหล่าไฮงาม กุฉินารายณ์ กาฬสินธุ์ 46110",
      label: "เหล่าไฮงาม กุฉินารายณ์ กาฬสินธุ์ 46110",
    },
    {
      value: "แจนแลน กุฉินารายณ์ กาฬสินธุ์ 46110",
      label: "แจนแลน กุฉินารายณ์ กาฬสินธุ์ 46110",
    },
    {
      value: "ดินจี่ คำม่วง กาฬสินธุ์ 46180",
      label: "ดินจี่ คำม่วง กาฬสินธุ์ 46180",
    },
    {
      value: "ทุ่งคลอง คำม่วง กาฬสินธุ์ 46180",
      label: "ทุ่งคลอง คำม่วง กาฬสินธุ์ 46180",
    },
    {
      value: "นาทัน คำม่วง กาฬสินธุ์ 46180",
      label: "นาทัน คำม่วง กาฬสินธุ์ 46180",
    },
    {
      value: "นาบอน คำม่วง กาฬสินธุ์ 46180",
      label: "นาบอน คำม่วง กาฬสินธุ์ 46180",
    },
    {
      value: "เนินยาง คำม่วง กาฬสินธุ์ 46180",
      label: "เนินยาง คำม่วง กาฬสินธุ์ 46180",
    },
    { value: "โพน คำม่วง กาฬสินธุ์ 46180", label: "โพน คำม่วง กาฬสินธุ์ 46180" },
    {
      value: "ฆ้องชัยพัฒนา ฆ้องชัย กาฬสินธุ์ 46130",
      label: "ฆ้องชัยพัฒนา ฆ้องชัย กาฬสินธุ์ 46130",
    },
    {
      value: "ลำชี ฆ้องชัย กาฬสินธุ์ 46130",
      label: "ลำชี ฆ้องชัย กาฬสินธุ์ 46130",
    },
    {
      value: "เหล่ากลาง ฆ้องชัย กาฬสินธุ์ 46130",
      label: "เหล่ากลาง ฆ้องชัย กาฬสินธุ์ 46130",
    },
    {
      value: "โคกสะอาด ฆ้องชัย กาฬสินธุ์ 46130",
      label: "โคกสะอาด ฆ้องชัย กาฬสินธุ์ 46130",
    },
    {
      value: "โนนศิลาเลิง ฆ้องชัย กาฬสินธุ์ 46130",
      label: "โนนศิลาเลิง ฆ้องชัย กาฬสินธุ์ 46130",
    },
    {
      value: "ดงพยุง ดอนจาน กาฬสินธุ์ 46000",
      label: "ดงพยุง ดอนจาน กาฬสินธุ์ 46000",
    },
    {
      value: "ดอนจาน ดอนจาน กาฬสินธุ์ 46000",
      label: "ดอนจาน ดอนจาน กาฬสินธุ์ 46000",
    },
    {
      value: "นาจำปา ดอนจาน กาฬสินธุ์ 46000",
      label: "นาจำปา ดอนจาน กาฬสินธุ์ 46000",
    },
    {
      value: "ม่วงนา ดอนจาน กาฬสินธุ์ 46000",
      label: "ม่วงนา ดอนจาน กาฬสินธุ์ 46000",
    },
    {
      value: "สะอาดไชยศรี ดอนจาน กาฬสินธุ์ 46000",
      label: "สะอาดไชยศรี ดอนจาน กาฬสินธุ์ 46000",
    },
    {
      value: "กุงเก่า ท่าคันโท กาฬสินธุ์ 46190",
      label: "กุงเก่า ท่าคันโท กาฬสินธุ์ 46190",
    },
    {
      value: "กุดจิก ท่าคันโท กาฬสินธุ์ 46190",
      label: "กุดจิก ท่าคันโท กาฬสินธุ์ 46190",
    },
    {
      value: "ดงสมบูรณ์ ท่าคันโท กาฬสินธุ์ 46190",
      label: "ดงสมบูรณ์ ท่าคันโท กาฬสินธุ์ 46190",
    },
    {
      value: "ท่าคันโท ท่าคันโท กาฬสินธุ์ 46190",
      label: "ท่าคันโท ท่าคันโท กาฬสินธุ์ 46190",
    },
    {
      value: "นาตาล ท่าคันโท กาฬสินธุ์ 46190",
      label: "นาตาล ท่าคันโท กาฬสินธุ์ 46190",
    },
    {
      value: "ยางอู้ม ท่าคันโท กาฬสินธุ์ 46190",
      label: "ยางอู้ม ท่าคันโท กาฬสินธุ์ 46190",
    },
    { value: "นาคู นาคู กาฬสินธุ์ 46160", label: "นาคู นาคู กาฬสินธุ์ 46160" },
    {
      value: "บ่อแก้ว นาคู กาฬสินธุ์ 46160",
      label: "บ่อแก้ว นาคู กาฬสินธุ์ 46160",
    },
    {
      value: "ภูแล่นช้าง นาคู กาฬสินธุ์ 46160",
      label: "ภูแล่นช้าง นาคู กาฬสินธุ์ 46160",
    },
    {
      value: "สายนาวัง นาคู กาฬสินธุ์ 46160",
      label: "สายนาวัง นาคู กาฬสินธุ์ 46160",
    },
    {
      value: "โนนนาจาน นาคู กาฬสินธุ์ 46160",
      label: "โนนนาจาน นาคู กาฬสินธุ์ 46160",
    },
    { value: "นามน นามน กาฬสินธุ์ 46230", label: "นามน นามน กาฬสินธุ์ 46230" },
    {
      value: "ยอดแกง นามน กาฬสินธุ์ 46230",
      label: "ยอดแกง นามน กาฬสินธุ์ 46230",
    },
    {
      value: "สงเปลือย นามน กาฬสินธุ์ 46230",
      label: "สงเปลือย นามน กาฬสินธุ์ 46230",
    },
    {
      value: "หนองบัว นามน กาฬสินธุ์ 46230",
      label: "หนองบัว นามน กาฬสินธุ์ 46230",
    },
    {
      value: "หลักเหลี่ยม นามน กาฬสินธุ์ 46230",
      label: "หลักเหลี่ยม นามน กาฬสินธุ์ 46230",
    },
    {
      value: "คลองขาม ยางตลาด กาฬสินธุ์ 46120",
      label: "คลองขาม ยางตลาด กาฬสินธุ์ 46120",
    },
    {
      value: "ดอนสมบูรณ์ ยางตลาด กาฬสินธุ์ 46120",
      label: "ดอนสมบูรณ์ ยางตลาด กาฬสินธุ์ 46120",
    },
    {
      value: "นาดี ยางตลาด กาฬสินธุ์ 46120",
      label: "นาดี ยางตลาด กาฬสินธุ์ 46120",
    },
    {
      value: "นาเชือก ยางตลาด กาฬสินธุ์ 46120",
      label: "นาเชือก ยางตลาด กาฬสินธุ์ 46120",
    },
    {
      value: "บัวบาน ยางตลาด กาฬสินธุ์ 46120",
      label: "บัวบาน ยางตลาด กาฬสินธุ์ 46120",
    },
    {
      value: "ยางตลาด ยางตลาด กาฬสินธุ์ 46120",
      label: "ยางตลาด ยางตลาด กาฬสินธุ์ 46120",
    },
    {
      value: "หนองตอกแป้น ยางตลาด กาฬสินธุ์ 46120",
      label: "หนองตอกแป้น ยางตลาด กาฬสินธุ์ 46120",
    },
    {
      value: "หนองอิเฒ่า ยางตลาด กาฬสินธุ์ 46120",
      label: "หนองอิเฒ่า ยางตลาด กาฬสินธุ์ 46120",
    },
    {
      value: "หัวงัว ยางตลาด กาฬสินธุ์ 46120",
      label: "หัวงัว ยางตลาด กาฬสินธุ์ 46120",
    },
    {
      value: "หัวนาคำ ยางตลาด กาฬสินธุ์ 46120",
      label: "หัวนาคำ ยางตลาด กาฬสินธุ์ 46120",
    },
    {
      value: "อิตื้อ ยางตลาด กาฬสินธุ์ 46120",
      label: "อิตื้อ ยางตลาด กาฬสินธุ์ 46120",
    },
    {
      value: "อุ่มเม่า ยางตลาด กาฬสินธุ์ 46120",
      label: "อุ่มเม่า ยางตลาด กาฬสินธุ์ 46120",
    },
    {
      value: "เขาพระนอน ยางตลาด กาฬสินธุ์ 46120",
      label: "เขาพระนอน ยางตลาด กาฬสินธุ์ 46120",
    },
    {
      value: "เว่อ ยางตลาด กาฬสินธุ์ 46120",
      label: "เว่อ ยางตลาด กาฬสินธุ์ 46120",
    },
    {
      value: "โนนสูง ยางตลาด กาฬสินธุ์ 46120",
      label: "โนนสูง ยางตลาด กาฬสินธุ์ 46120",
    },
    {
      value: "ร่องคำ ร่องคำ กาฬสินธุ์ 46210",
      label: "ร่องคำ ร่องคำ กาฬสินธุ์ 46210",
    },
    {
      value: "สามัคคี ร่องคำ กาฬสินธุ์ 46210",
      label: "สามัคคี ร่องคำ กาฬสินธุ์ 46210",
    },
    {
      value: "เหล่าอ้อย ร่องคำ กาฬสินธุ์ 46210",
      label: "เหล่าอ้อย ร่องคำ กาฬสินธุ์ 46210",
    },
    {
      value: "ผาเสวย สมเด็จ กาฬสินธุ์ 46150",
      label: "ผาเสวย สมเด็จ กาฬสินธุ์ 46150",
    },
    {
      value: "มหาไชย สมเด็จ กาฬสินธุ์ 46150",
      label: "มหาไชย สมเด็จ กาฬสินธุ์ 46150",
    },
    {
      value: "ลำห้วยหลัว สมเด็จ กาฬสินธุ์ 46150",
      label: "ลำห้วยหลัว สมเด็จ กาฬสินธุ์ 46150",
    },
    {
      value: "ศรีสมเด็จ สมเด็จ กาฬสินธุ์ 46150",
      label: "ศรีสมเด็จ สมเด็จ กาฬสินธุ์ 46150",
    },
    {
      value: "สมเด็จ สมเด็จ กาฬสินธุ์ 46150",
      label: "สมเด็จ สมเด็จ กาฬสินธุ์ 46150",
    },
    {
      value: "หนองแวง สมเด็จ กาฬสินธุ์ 46150",
      label: "หนองแวง สมเด็จ กาฬสินธุ์ 46150",
    },
    {
      value: "หมูม่น สมเด็จ กาฬสินธุ์ 46150",
      label: "หมูม่น สมเด็จ กาฬสินธุ์ 46150",
    },
    {
      value: "แซงบาดาล สมเด็จ กาฬสินธุ์ 46150",
      label: "แซงบาดาล สมเด็จ กาฬสินธุ์ 46150",
    },
    {
      value: "นามะเขือ สหัสขันธ์ กาฬสินธุ์ 46140",
      label: "นามะเขือ สหัสขันธ์ กาฬสินธุ์ 46140",
    },
    {
      value: "นิคม สหัสขันธ์ กาฬสินธุ์ 46140",
      label: "นิคม สหัสขันธ์ กาฬสินธุ์ 46140",
    },
    {
      value: "ภูสิงห์ สหัสขันธ์ กาฬสินธุ์ 46140",
      label: "ภูสิงห์ สหัสขันธ์ กาฬสินธุ์ 46140",
    },
    {
      value: "สหัสขันธ์ สหัสขันธ์ กาฬสินธุ์ 46140",
      label: "สหัสขันธ์ สหัสขันธ์ กาฬสินธุ์ 46140",
    },
    {
      value: "โนนน้ำเกลี้ยง สหัสขันธ์ กาฬสินธุ์ 46140",
      label: "โนนน้ำเกลี้ยง สหัสขันธ์ กาฬสินธุ์ 46140",
    },
    {
      value: "โนนบุรี สหัสขันธ์ กาฬสินธุ์ 46140",
      label: "โนนบุรี สหัสขันธ์ กาฬสินธุ์ 46140",
    },
    {
      value: "โนนศิลา สหัสขันธ์ กาฬสินธุ์ 46140",
      label: "โนนศิลา สหัสขันธ์ กาฬสินธุ์ 46140",
    },
    {
      value: "โนนแหลมทอง สหัสขันธ์ กาฬสินธุ์ 46140",
      label: "โนนแหลมทอง สหัสขันธ์ กาฬสินธุ์ 46140",
    },
    {
      value: "คำสร้างเที่ยง สามชัย กาฬสินธุ์ 46180",
      label: "คำสร้างเที่ยง สามชัย กาฬสินธุ์ 46180",
    },
    {
      value: "สำราญ สามชัย กาฬสินธุ์ 46180",
      label: "สำราญ สามชัย กาฬสินธุ์ 46180",
    },
    {
      value: "สำราญใต้ สามชัย กาฬสินธุ์ 46180",
      label: "สำราญใต้ สามชัย กาฬสินธุ์ 46180",
    },
    {
      value: "หนองช้าง สามชัย กาฬสินธุ์ 46180",
      label: "หนองช้าง สามชัย กาฬสินธุ์ 46180",
    },
    {
      value: "ดงมูล หนองกุงศรี กาฬสินธุ์ 46220",
      label: "ดงมูล หนองกุงศรี กาฬสินธุ์ 46220",
    },
    {
      value: "ลำหนองแสน หนองกุงศรี กาฬสินธุ์ 46220",
      label: "ลำหนองแสน หนองกุงศรี กาฬสินธุ์ 46220",
    },
    {
      value: "หนองกุงศรี หนองกุงศรี กาฬสินธุ์ 46220",
      label: "หนองกุงศรี หนองกุงศรี กาฬสินธุ์ 46220",
    },
    {
      value: "หนองบัว หนองกุงศรี กาฬสินธุ์ 46220",
      label: "หนองบัว หนองกุงศรี กาฬสินธุ์ 46220",
    },
    {
      value: "หนองสรวง หนองกุงศรี กาฬสินธุ์ 46220",
      label: "หนองสรวง หนองกุงศรี กาฬสินธุ์ 46220",
    },
    {
      value: "หนองหิน หนองกุงศรี กาฬสินธุ์ 46220",
      label: "หนองหิน หนองกุงศรี กาฬสินธุ์ 46220",
    },
    {
      value: "หนองใหญ่ หนองกุงศรี กาฬสินธุ์ 46220",
      label: "หนองใหญ่ หนองกุงศรี กาฬสินธุ์ 46220",
    },
    {
      value: "เสาเล้า หนองกุงศรี กาฬสินธุ์ 46220",
      label: "เสาเล้า หนองกุงศรี กาฬสินธุ์ 46220",
    },
    {
      value: "โคกเครือ หนองกุงศรี กาฬสินธุ์ 46220",
      label: "โคกเครือ หนองกุงศรี กาฬสินธุ์ 46220",
    },
    {
      value: "คำบง ห้วยผึ้ง กาฬสินธุ์ 46240",
      label: "คำบง ห้วยผึ้ง กาฬสินธุ์ 46240",
    },
    {
      value: "นิคมห้วยผึ้ง ห้วยผึ้ง กาฬสินธุ์ 46240",
      label: "นิคมห้วยผึ้ง ห้วยผึ้ง กาฬสินธุ์ 46240",
    },
    {
      value: "หนองอีบุตร ห้วยผึ้ง กาฬสินธุ์ 46240",
      label: "หนองอีบุตร ห้วยผึ้ง กาฬสินธุ์ 46240",
    },
    {
      value: "ไค้นุ่น ห้วยผึ้ง กาฬสินธุ์ 46240",
      label: "ไค้นุ่น ห้วยผึ้ง กาฬสินธุ์ 46240",
    },
    {
      value: "กุดโดน ห้วยเม็ก กาฬสินธุ์ 46170",
      label: "กุดโดน ห้วยเม็ก กาฬสินธุ์ 46170",
    },
    {
      value: "คำเหมือดแก้ว ห้วยเม็ก กาฬสินธุ์ 46170",
      label: "คำเหมือดแก้ว ห้วยเม็ก กาฬสินธุ์ 46170",
    },
    {
      value: "คำใหญ่ ห้วยเม็ก กาฬสินธุ์ 46170",
      label: "คำใหญ่ ห้วยเม็ก กาฬสินธุ์ 46170",
    },
    {
      value: "ทรายทอง ห้วยเม็ก กาฬสินธุ์ 46170",
      label: "ทรายทอง ห้วยเม็ก กาฬสินธุ์ 46170",
    },
    {
      value: "บึงนาเรียง ห้วยเม็ก กาฬสินธุ์ 46170",
      label: "บึงนาเรียง ห้วยเม็ก กาฬสินธุ์ 46170",
    },
    {
      value: "พิมูล ห้วยเม็ก กาฬสินธุ์ 46170",
      label: "พิมูล ห้วยเม็ก กาฬสินธุ์ 46170",
    },
    {
      value: "หัวหิน ห้วยเม็ก กาฬสินธุ์ 46170",
      label: "หัวหิน ห้วยเม็ก กาฬสินธุ์ 46170",
    },
    {
      value: "ห้วยเม็ก ห้วยเม็ก กาฬสินธุ์ 46170",
      label: "ห้วยเม็ก ห้วยเม็ก กาฬสินธุ์ 46170",
    },
    {
      value: "โนนสะอาด ห้วยเม็ก กาฬสินธุ์ 46170",
      label: "โนนสะอาด ห้วยเม็ก กาฬสินธุ์ 46170",
    },
    {
      value: "กุดปลาค้าว เขาวง กาฬสินธุ์ 46160",
      label: "กุดปลาค้าว เขาวง กาฬสินธุ์ 46160",
    },
    {
      value: "กุดสิมคุ้มใหม่ เขาวง กาฬสินธุ์ 46160",
      label: "กุดสิมคุ้มใหม่ เขาวง กาฬสินธุ์ 46160",
    },
    {
      value: "คุ้มเก่า เขาวง กาฬสินธุ์ 46160",
      label: "คุ้มเก่า เขาวง กาฬสินธุ์ 46160",
    },
    {
      value: "สงเปลือย เขาวง กาฬสินธุ์ 46160",
      label: "สงเปลือย เขาวง กาฬสินธุ์ 46160",
    },
    {
      value: "สระพังทอง เขาวง กาฬสินธุ์ 46160",
      label: "สระพังทอง เขาวง กาฬสินธุ์ 46160",
    },
    {
      value: "หนองผือ เขาวง กาฬสินธุ์ 46160",
      label: "หนองผือ เขาวง กาฬสินธุ์ 46160",
    },
    {
      value: "กลางหมื่น เมืองกาฬสินธุ์ กาฬสินธุ์ 46000",
      label: "กลางหมื่น เมืองกาฬสินธุ์ กาฬสินธุ์ 46000",
    },
    {
      value: "กาฬสินธุ์ เมืองกาฬสินธุ์ กาฬสินธุ์ 46000",
      label: "กาฬสินธุ์ เมืองกาฬสินธุ์ กาฬสินธุ์ 46000",
    },
    {
      value: "ขมิ้น เมืองกาฬสินธุ์ กาฬสินธุ์ 46000",
      label: "ขมิ้น เมืองกาฬสินธุ์ กาฬสินธุ์ 46000",
    },
    {
      value: "นาจารย์ เมืองกาฬสินธุ์ กาฬสินธุ์ 46000",
      label: "นาจารย์ เมืองกาฬสินธุ์ กาฬสินธุ์ 46000",
    },
    {
      value: "บึงวิชัย เมืองกาฬสินธุ์ กาฬสินธุ์ 46000",
      label: "บึงวิชัย เมืองกาฬสินธุ์ กาฬสินธุ์ 46000",
    },
    {
      value: "ภูดิน เมืองกาฬสินธุ์ กาฬสินธุ์ 46000",
      label: "ภูดิน เมืองกาฬสินธุ์ กาฬสินธุ์ 46000",
    },
    {
      value: "ภูปอ เมืองกาฬสินธุ์ กาฬสินธุ์ 46000",
      label: "ภูปอ เมืองกาฬสินธุ์ กาฬสินธุ์ 46000",
    },
    {
      value: "ลำคลอง เมืองกาฬสินธุ์ กาฬสินธุ์ 46000",
      label: "ลำคลอง เมืองกาฬสินธุ์ กาฬสินธุ์ 46000",
    },
    {
      value: "ลำปาว เมืองกาฬสินธุ์ กาฬสินธุ์ 46000",
      label: "ลำปาว เมืองกาฬสินธุ์ กาฬสินธุ์ 46000",
    },
    {
      value: "ลำพาน เมืองกาฬสินธุ์ กาฬสินธุ์ 46000",
      label: "ลำพาน เมืองกาฬสินธุ์ กาฬสินธุ์ 46000",
    },
    {
      value: "หนองกุง เมืองกาฬสินธุ์ กาฬสินธุ์ 46000",
      label: "หนองกุง เมืองกาฬสินธุ์ กาฬสินธุ์ 46000",
    },
    {
      value: "หลุบ เมืองกาฬสินธุ์ กาฬสินธุ์ 46000",
      label: "หลุบ เมืองกาฬสินธุ์ กาฬสินธุ์ 46000",
    },
    {
      value: "ห้วยโพธิ์ เมืองกาฬสินธุ์ กาฬสินธุ์ 46000",
      label: "ห้วยโพธิ์ เมืองกาฬสินธุ์ กาฬสินธุ์ 46000",
    },
    {
      value: "เชียงเครือ เมืองกาฬสินธุ์ กาฬสินธุ์ 46000",
      label: "เชียงเครือ เมืองกาฬสินธุ์ กาฬสินธุ์ 46000",
    },
    {
      value: "เหนือ เมืองกาฬสินธุ์ กาฬสินธุ์ 46000",
      label: "เหนือ เมืองกาฬสินธุ์ กาฬสินธุ์ 46000",
    },
    {
      value: "โพนทอง เมืองกาฬสินธุ์ กาฬสินธุ์ 46000",
      label: "โพนทอง เมืองกาฬสินธุ์ กาฬสินธุ์ 46000",
    },
    {
      value: "ไผ่ เมืองกาฬสินธุ์ กาฬสินธุ์ 46000",
      label: "ไผ่ เมืองกาฬสินธุ์ กาฬสินธุ์ 46000",
    },
    {
      value: "ดูนสาด กระนวน ขอนแก่น 40170",
      label: "ดูนสาด กระนวน ขอนแก่น 40170",
    },
    {
      value: "น้ำอ้อม กระนวน ขอนแก่น 40170",
      label: "น้ำอ้อม กระนวน ขอนแก่น 40170",
    },
    {
      value: "บ้านฝาง กระนวน ขอนแก่น 40170",
      label: "บ้านฝาง กระนวน ขอนแก่น 40170",
    },
    {
      value: "หนองกุงใหญ่ กระนวน ขอนแก่น 40170",
      label: "หนองกุงใหญ่ กระนวน ขอนแก่น 40170",
    },
    {
      value: "หนองโก กระนวน ขอนแก่น 40170",
      label: "หนองโก กระนวน ขอนแก่น 40170",
    },
    {
      value: "หนองโน กระนวน ขอนแก่น 40170",
      label: "หนองโน กระนวน ขอนแก่น 40170",
    },
    {
      value: "หัวนาคำ กระนวน ขอนแก่น 40170",
      label: "หัวนาคำ กระนวน ขอนแก่น 40170",
    },
    {
      value: "ห้วยยาง กระนวน ขอนแก่น 40170",
      label: "ห้วยยาง กระนวน ขอนแก่น 40170",
    },
    {
      value: "ห้วยโจด กระนวน ขอนแก่น 40170",
      label: "ห้วยโจด กระนวน ขอนแก่น 40170",
    },
    {
      value: "กุดเพียขอม ชนบท ขอนแก่น 40180",
      label: "กุดเพียขอม ชนบท ขอนแก่น 40180",
    },
    { value: "ชนบท ชนบท ขอนแก่น 40180", label: "ชนบท ชนบท ขอนแก่น 40180" },
    {
      value: "บ้านแท่น ชนบท ขอนแก่น 40180",
      label: "บ้านแท่น ชนบท ขอนแก่น 40180",
    },
    { value: "ปอแดง ชนบท ขอนแก่น 40180", label: "ปอแดง ชนบท ขอนแก่น 40180" },
    { value: "วังแสง ชนบท ขอนแก่น 40180", label: "วังแสง ชนบท ขอนแก่น 40180" },
    {
      value: "ศรีบุญเรือง ชนบท ขอนแก่น 40180",
      label: "ศรีบุญเรือง ชนบท ขอนแก่น 40180",
    },
    { value: "ห้วยแก ชนบท ขอนแก่น 40180", label: "ห้วยแก ชนบท ขอนแก่น 40180" },
    {
      value: "โนนพะยอม ชนบท ขอนแก่น 40180",
      label: "โนนพะยอม ชนบท ขอนแก่น 40180",
    },
    {
      value: "ขัวเรียง ชุมแพ ขอนแก่น 40130",
      label: "ขัวเรียง ชุมแพ ขอนแก่น 40130",
    },
    { value: "ชุมแพ ชุมแพ ขอนแก่น 40130", label: "ชุมแพ ชุมแพ ขอนแก่น 40130" },
    {
      value: "นาหนองทุ่ม ชุมแพ ขอนแก่น 40290",
      label: "นาหนองทุ่ม ชุมแพ ขอนแก่น 40290",
    },
    {
      value: "นาเพียง ชุมแพ ขอนแก่น 40130",
      label: "นาเพียง ชุมแพ ขอนแก่น 40130",
    },
    {
      value: "วังหินลาด ชุมแพ ขอนแก่น 40130",
      label: "วังหินลาด ชุมแพ ขอนแก่น 40130",
    },
    {
      value: "หนองเขียด ชุมแพ ขอนแก่น 40290",
      label: "หนองเขียด ชุมแพ ขอนแก่น 40290",
    },
    {
      value: "หนองเสาเล้า ชุมแพ ขอนแก่น 40130",
      label: "หนองเสาเล้า ชุมแพ ขอนแก่น 40130",
    },
    {
      value: "หนองไผ่ ชุมแพ ขอนแก่น 40130",
      label: "หนองไผ่ ชุมแพ ขอนแก่น 40130",
    },
    {
      value: "โนนสะอาด ชุมแพ ขอนแก่น 40290",
      label: "โนนสะอาด ชุมแพ ขอนแก่น 40290",
    },
    { value: "โนนหัน ชุมแพ ขอนแก่น 40290", label: "โนนหัน ชุมแพ ขอนแก่น 40290" },
    {
      value: "โนนอุดม ชุมแพ ขอนแก่น 40130",
      label: "โนนอุดม ชุมแพ ขอนแก่น 40130",
    },
    { value: "ไชยสอ ชุมแพ ขอนแก่น 40130", label: "ไชยสอ ชุมแพ ขอนแก่น 40130" },
    { value: "กระนวน ซำสูง ขอนแก่น 40170", label: "กระนวน ซำสูง ขอนแก่น 40170" },
    { value: "คำแมด ซำสูง ขอนแก่น 40170", label: "คำแมด ซำสูง ขอนแก่น 40170" },
    { value: "คูคำ ซำสูง ขอนแก่น 40170", label: "คูคำ ซำสูง ขอนแก่น 40170" },
    {
      value: "บ้านโนน ซำสูง ขอนแก่น 40170",
      label: "บ้านโนน ซำสูง ขอนแก่น 40170",
    },
    {
      value: "ห้วยเตย ซำสูง ขอนแก่น 40170",
      label: "ห้วยเตย ซำสูง ขอนแก่น 40170",
    },
    {
      value: "กุดน้ำใส น้ำพอง ขอนแก่น 40310",
      label: "กุดน้ำใส น้ำพอง ขอนแก่น 40310",
    },
    {
      value: "ทรายมูล น้ำพอง ขอนแก่น 40140",
      label: "ทรายมูล น้ำพอง ขอนแก่น 40140",
    },
    {
      value: "ท่ากระเสริม น้ำพอง ขอนแก่น 40140",
      label: "ท่ากระเสริม น้ำพอง ขอนแก่น 40140",
    },
    {
      value: "น้ำพอง น้ำพอง ขอนแก่น 40140",
      label: "น้ำพอง น้ำพอง ขอนแก่น 40140",
    },
    {
      value: "บัวเงิน น้ำพอง ขอนแก่น 40140",
      label: "บัวเงิน น้ำพอง ขอนแก่น 40140",
    },
    {
      value: "บัวใหญ่ น้ำพอง ขอนแก่น 40140",
      label: "บัวใหญ่ น้ำพอง ขอนแก่น 40140",
    },
    {
      value: "บ้านขาม น้ำพอง ขอนแก่น 40140",
      label: "บ้านขาม น้ำพอง ขอนแก่น 40140",
    },
    {
      value: "พังทุย น้ำพอง ขอนแก่น 40140",
      label: "พังทุย น้ำพอง ขอนแก่น 40140",
    },
    {
      value: "ม่วงหวาน น้ำพอง ขอนแก่น 40310",
      label: "ม่วงหวาน น้ำพอง ขอนแก่น 40310",
    },
    {
      value: "วังชัย น้ำพอง ขอนแก่น 40140",
      label: "วังชัย น้ำพอง ขอนแก่น 40140",
    },
    { value: "สะอาด น้ำพอง ขอนแก่น 40310", label: "สะอาด น้ำพอง ขอนแก่น 40310" },
    {
      value: "หนองกุง น้ำพอง ขอนแก่น 40140",
      label: "หนองกุง น้ำพอง ขอนแก่น 40140",
    },
    {
      value: "บ้านฝาง บ้านฝาง ขอนแก่น 40270",
      label: "บ้านฝาง บ้านฝาง ขอนแก่น 40270",
    },
    {
      value: "บ้านเหล่า บ้านฝาง ขอนแก่น 40270",
      label: "บ้านเหล่า บ้านฝาง ขอนแก่น 40270",
    },
    {
      value: "ป่ามะนาว บ้านฝาง ขอนแก่น 40270",
      label: "ป่ามะนาว บ้านฝาง ขอนแก่น 40270",
    },
    {
      value: "ป่าหวายนั่ง บ้านฝาง ขอนแก่น 40270",
      label: "ป่าหวายนั่ง บ้านฝาง ขอนแก่น 40270",
    },
    {
      value: "หนองบัว บ้านฝาง ขอนแก่น 40270",
      label: "หนองบัว บ้านฝาง ขอนแก่น 40270",
    },
    {
      value: "โคกงาม บ้านฝาง ขอนแก่น 40270",
      label: "โคกงาม บ้านฝาง ขอนแก่น 40270",
    },
    {
      value: "โนนฆ้อง บ้านฝาง ขอนแก่น 40270",
      label: "โนนฆ้อง บ้านฝาง ขอนแก่น 40270",
    },
    {
      value: "บ้านแฮด บ้านแฮด ขอนแก่น 40110",
      label: "บ้านแฮด บ้านแฮด ขอนแก่น 40110",
    },
    {
      value: "หนองแซง บ้านแฮด ขอนแก่น 40110",
      label: "หนองแซง บ้านแฮด ขอนแก่น 40110",
    },
    {
      value: "โคกสำราญ บ้านแฮด ขอนแก่น 40110",
      label: "โคกสำราญ บ้านแฮด ขอนแก่น 40110",
    },
    {
      value: "โนนสมบูรณ์ บ้านแฮด ขอนแก่น 40110",
      label: "โนนสมบูรณ์ บ้านแฮด ขอนแก่น 40110",
    },
    {
      value: "บ้านลาน บ้านไผ่ ขอนแก่น 40110",
      label: "บ้านลาน บ้านไผ่ ขอนแก่น 40110",
    },
    {
      value: "บ้านไผ่ บ้านไผ่ ขอนแก่น 40110",
      label: "บ้านไผ่ บ้านไผ่ ขอนแก่น 40110",
    },
    {
      value: "ป่าปอ บ้านไผ่ ขอนแก่น 40110",
      label: "ป่าปอ บ้านไผ่ ขอนแก่น 40110",
    },
    {
      value: "ภูเหล็ก บ้านไผ่ ขอนแก่น 40110",
      label: "ภูเหล็ก บ้านไผ่ ขอนแก่น 40110",
    },
    {
      value: "หนองน้ำใส บ้านไผ่ ขอนแก่น 40110",
      label: "หนองน้ำใส บ้านไผ่ ขอนแก่น 40110",
    },
    {
      value: "หัวหนอง บ้านไผ่ ขอนแก่น 40110",
      label: "หัวหนอง บ้านไผ่ ขอนแก่น 40110",
    },
    {
      value: "หินตั้ง บ้านไผ่ ขอนแก่น 40110",
      label: "หินตั้ง บ้านไผ่ ขอนแก่น 40110",
    },
    {
      value: "เมืองเพีย บ้านไผ่ ขอนแก่น 40110",
      label: "เมืองเพีย บ้านไผ่ ขอนแก่น 40110",
    },
    {
      value: "แคนเหนือ บ้านไผ่ ขอนแก่น 40110",
      label: "แคนเหนือ บ้านไผ่ ขอนแก่น 40110",
    },
    {
      value: "ในเมือง บ้านไผ่ ขอนแก่น 40110",
      label: "ในเมือง บ้านไผ่ ขอนแก่น 40110",
    },
    {
      value: "ขามป้อม พระยืน ขอนแก่น 40320",
      label: "ขามป้อม พระยืน ขอนแก่น 40320",
    },
    {
      value: "บ้านโต้น พระยืน ขอนแก่น 40320",
      label: "บ้านโต้น พระยืน ขอนแก่น 40320",
    },
    { value: "พระบุ พระยืน ขอนแก่น 40320", label: "พระบุ พระยืน ขอนแก่น 40320" },
    {
      value: "พระยืน พระยืน ขอนแก่น 40320",
      label: "พระยืน พระยืน ขอนแก่น 40320",
    },
    {
      value: "หนองแวง พระยืน ขอนแก่น 40320",
      label: "หนองแวง พระยืน ขอนแก่น 40320",
    },
    { value: "ลอมคอม พล ขอนแก่น 40120", label: "ลอมคอม พล ขอนแก่น 40120" },
    {
      value: "หนองมะเขือ พล ขอนแก่น 40120",
      label: "หนองมะเขือ พล ขอนแก่น 40120",
    },
    {
      value: "หนองแวงนางเบ้า พล ขอนแก่น 40120",
      label: "หนองแวงนางเบ้า พล ขอนแก่น 40120",
    },
    {
      value: "หนองแวงโสกพระ พล ขอนแก่น 40120",
      label: "หนองแวงโสกพระ พล ขอนแก่น 40120",
    },
    { value: "หัวทุ่ง พล ขอนแก่น 40120", label: "หัวทุ่ง พล ขอนแก่น 40120" },
    { value: "เก่างิ้ว พล ขอนแก่น 40120", label: "เก่างิ้ว พล ขอนแก่น 40120" },
    { value: "เพ็กใหญ่ พล ขอนแก่น 40120", label: "เพ็กใหญ่ พล ขอนแก่น 40120" },
    { value: "เมืองพล พล ขอนแก่น 40120", label: "เมืองพล พล ขอนแก่น 40120" },
    { value: "โคกสง่า พล ขอนแก่น 40120", label: "โคกสง่า พล ขอนแก่น 40120" },
    { value: "โจดหนองแก พล ขอนแก่น 40120", label: "โจดหนองแก พล ขอนแก่น 40120" },
    { value: "โนนข่า พล ขอนแก่น 40120", label: "โนนข่า พล ขอนแก่น 40120" },
    { value: "โสกนกเต็น พล ขอนแก่น 40120", label: "โสกนกเต็น พล ขอนแก่น 40120" },
    {
      value: "นาฝาย ภูผาม่าน ขอนแก่น 40350",
      label: "นาฝาย ภูผาม่าน ขอนแก่น 40350",
    },
    {
      value: "ภูผาม่าน ภูผาม่าน ขอนแก่น 40350",
      label: "ภูผาม่าน ภูผาม่าน ขอนแก่น 40350",
    },
    {
      value: "วังสวาบ ภูผาม่าน ขอนแก่น 40350",
      label: "วังสวาบ ภูผาม่าน ขอนแก่น 40350",
    },
    {
      value: "ห้วยม่วง ภูผาม่าน ขอนแก่น 40350",
      label: "ห้วยม่วง ภูผาม่าน ขอนแก่น 40350",
    },
    {
      value: "โนนคอม ภูผาม่าน ขอนแก่น 40350",
      label: "โนนคอม ภูผาม่าน ขอนแก่น 40350",
    },
    {
      value: "กุดขอนแก่น ภูเวียง ขอนแก่น 40150",
      label: "กุดขอนแก่น ภูเวียง ขอนแก่น 40150",
    },
    {
      value: "ดินดำ ภูเวียง ขอนแก่น 40150",
      label: "ดินดำ ภูเวียง ขอนแก่น 40150",
    },
    {
      value: "ทุ่งชมพู ภูเวียง ขอนแก่น 40150",
      label: "ทุ่งชมพู ภูเวียง ขอนแก่น 40150",
    },
    {
      value: "นาชุมแสง ภูเวียง ขอนแก่น 40150",
      label: "นาชุมแสง ภูเวียง ขอนแก่น 40150",
    },
    {
      value: "นาหว้า ภูเวียง ขอนแก่น 40150",
      label: "นาหว้า ภูเวียง ขอนแก่น 40150",
    },
    {
      value: "บ้านเรือ ภูเวียง ขอนแก่น 40150",
      label: "บ้านเรือ ภูเวียง ขอนแก่น 40150",
    },
    {
      value: "ภูเวียง ภูเวียง ขอนแก่น 40150",
      label: "ภูเวียง ภูเวียง ขอนแก่น 40150",
    },
    {
      value: "สงเปือย ภูเวียง ขอนแก่น 40150",
      label: "สงเปือย ภูเวียง ขอนแก่น 40150",
    },
    {
      value: "หนองกุงธนสาร ภูเวียง ขอนแก่น 40150",
      label: "หนองกุงธนสาร ภูเวียง ขอนแก่น 40150",
    },
    {
      value: "หนองกุงเซิน ภูเวียง ขอนแก่น 40150",
      label: "หนองกุงเซิน ภูเวียง ขอนแก่น 40150",
    },
    {
      value: "หว้าทอง ภูเวียง ขอนแก่น 40150",
      label: "หว้าทอง ภูเวียง ขอนแก่น 40150",
    },
    {
      value: "เขาน้อย เวียงเก่า ขอนแก่น 40150",
      label: "เขาน้อย เวียงเก่า ขอนแก่น 40150",
    },
    {
      value: "เมืองเก่าพัฒนา เวียงเก่า ขอนแก่น 40150",
      label: "เมืองเก่าพัฒนา เวียงเก่า ขอนแก่น 40150",
    },
    {
      value: "ในเมือง เวียงเก่า ขอนแก่น 40150",
      label: "ในเมือง เวียงเก่า ขอนแก่น 40150",
    },
    {
      value: "กุดเค้า มัญจาคีรี ขอนแก่น 40160",
      label: "กุดเค้า มัญจาคีรี ขอนแก่น 40160",
    },
    {
      value: "คำแคน มัญจาคีรี ขอนแก่น 40160",
      label: "คำแคน มัญจาคีรี ขอนแก่น 40160",
    },
    {
      value: "ท่าศาลา มัญจาคีรี ขอนแก่น 40160",
      label: "ท่าศาลา มัญจาคีรี ขอนแก่น 40160",
    },
    {
      value: "นาข่า มัญจาคีรี ขอนแก่น 40160",
      label: "นาข่า มัญจาคีรี ขอนแก่น 40160",
    },
    {
      value: "นางาม มัญจาคีรี ขอนแก่น 40160",
      label: "นางาม มัญจาคีรี ขอนแก่น 40160",
    },
    {
      value: "สวนหม่อน มัญจาคีรี ขอนแก่น 40160",
      label: "สวนหม่อน มัญจาคีรี ขอนแก่น 40160",
    },
    {
      value: "หนองแปน มัญจาคีรี ขอนแก่น 40160",
      label: "หนองแปน มัญจาคีรี ขอนแก่น 40160",
    },
    {
      value: "โพนเพ็ก มัญจาคีรี ขอนแก่น 40160",
      label: "โพนเพ็ก มัญจาคีรี ขอนแก่น 40160",
    },
    { value: "ซำยาง สีชมพู ขอนแก่น 40220", label: "ซำยาง สีชมพู ขอนแก่น 40220" },
    { value: "ดงลาน สีชมพู ขอนแก่น 40220", label: "ดงลาน สีชมพู ขอนแก่น 40220" },
    { value: "นาจาน สีชมพู ขอนแก่น 40220", label: "นาจาน สีชมพู ขอนแก่น 40220" },
    {
      value: "บริบูรณ์ สีชมพู ขอนแก่น 40220",
      label: "บริบูรณ์ สีชมพู ขอนแก่น 40220",
    },
    {
      value: "บ้านใหม่ สีชมพู ขอนแก่น 40220",
      label: "บ้านใหม่ สีชมพู ขอนแก่น 40220",
    },
    {
      value: "ภูห่าน สีชมพู ขอนแก่น 40220",
      label: "ภูห่าน สีชมพู ขอนแก่น 40220",
    },
    {
      value: "วังเพิ่ม สีชมพู ขอนแก่น 40220",
      label: "วังเพิ่ม สีชมพู ขอนแก่น 40220",
    },
    {
      value: "ศรีสุข สีชมพู ขอนแก่น 40220",
      label: "ศรีสุข สีชมพู ขอนแก่น 40220",
    },
    {
      value: "สีชมพู สีชมพู ขอนแก่น 40220",
      label: "สีชมพู สีชมพู ขอนแก่น 40220",
    },
    {
      value: "หนองแดง สีชมพู ขอนแก่น 40220",
      label: "หนองแดง สีชมพู ขอนแก่น 40220",
    },
    {
      value: "กุดธาตุ หนองนาคำ ขอนแก่น 40150",
      label: "กุดธาตุ หนองนาคำ ขอนแก่น 40150",
    },
    {
      value: "ขนวน หนองนาคำ ขอนแก่น 40150",
      label: "ขนวน หนองนาคำ ขอนแก่น 40150",
    },
    {
      value: "บ้านโคก หนองนาคำ ขอนแก่น 40150",
      label: "บ้านโคก หนองนาคำ ขอนแก่น 40150",
    },
    {
      value: "คึมชาด หนองสองห้อง ขอนแก่น 40190",
      label: "คึมชาด หนองสองห้อง ขอนแก่น 40190",
    },
    {
      value: "ดงเค็ง หนองสองห้อง ขอนแก่น 40190",
      label: "ดงเค็ง หนองสองห้อง ขอนแก่น 40190",
    },
    {
      value: "ดอนดั่ง หนองสองห้อง ขอนแก่น 40190",
      label: "ดอนดั่ง หนองสองห้อง ขอนแก่น 40190",
    },
    {
      value: "ดอนดู่ หนองสองห้อง ขอนแก่น 40190",
      label: "ดอนดู่ หนองสองห้อง ขอนแก่น 40190",
    },
    {
      value: "ตะกั่วป่า หนองสองห้อง ขอนแก่น 40190",
      label: "ตะกั่วป่า หนองสองห้อง ขอนแก่น 40190",
    },
    {
      value: "วังหิน หนองสองห้อง ขอนแก่น 40190",
      label: "วังหิน หนองสองห้อง ขอนแก่น 40190",
    },
    {
      value: "สำโรง หนองสองห้อง ขอนแก่น 40190",
      label: "สำโรง หนองสองห้อง ขอนแก่น 40190",
    },
    {
      value: "หนองสองห้อง หนองสองห้อง ขอนแก่น 40190",
      label: "หนองสองห้อง หนองสองห้อง ขอนแก่น 40190",
    },
    {
      value: "หนองเม็ก หนองสองห้อง ขอนแก่น 40190",
      label: "หนองเม็ก หนองสองห้อง ขอนแก่น 40190",
    },
    {
      value: "หนองไผ่ล้อม หนองสองห้อง ขอนแก่น 40190",
      label: "หนองไผ่ล้อม หนองสองห้อง ขอนแก่น 40190",
    },
    {
      value: "หันโจด หนองสองห้อง ขอนแก่น 40190",
      label: "หันโจด หนองสองห้อง ขอนแก่น 40190",
    },
    {
      value: "โนนธาตุ หนองสองห้อง ขอนแก่น 40190",
      label: "โนนธาตุ หนองสองห้อง ขอนแก่น 40190",
    },
    {
      value: "กุดกว้าง หนองเรือ ขอนแก่น 40210",
      label: "กุดกว้าง หนองเรือ ขอนแก่น 40210",
    },
    {
      value: "จระเข้ หนองเรือ ขอนแก่น 40240",
      label: "จระเข้ หนองเรือ ขอนแก่น 40240",
    },
    {
      value: "บ้านกง หนองเรือ ขอนแก่น 40240",
      label: "บ้านกง หนองเรือ ขอนแก่น 40240",
    },
    {
      value: "บ้านผือ หนองเรือ ขอนแก่น 40240",
      label: "บ้านผือ หนองเรือ ขอนแก่น 40240",
    },
    {
      value: "บ้านเม็ง หนองเรือ ขอนแก่น 40210",
      label: "บ้านเม็ง หนองเรือ ขอนแก่น 40210",
    },
    {
      value: "ยางคำ หนองเรือ ขอนแก่น 40240",
      label: "ยางคำ หนองเรือ ขอนแก่น 40240",
    },
    {
      value: "หนองเรือ หนองเรือ ขอนแก่น 40210",
      label: "หนองเรือ หนองเรือ ขอนแก่น 40210",
    },
    {
      value: "โนนทอง หนองเรือ ขอนแก่น 40210",
      label: "โนนทอง หนองเรือ ขอนแก่น 40210",
    },
    {
      value: "โนนทัน หนองเรือ ขอนแก่น 40210",
      label: "โนนทัน หนองเรือ ขอนแก่น 40210",
    },
    {
      value: "โนนสะอาด หนองเรือ ขอนแก่น 40210",
      label: "โนนสะอาด หนองเรือ ขอนแก่น 40210",
    },
    {
      value: "ทุ่งโป่ง อุบลรัตน์ ขอนแก่น 40250",
      label: "ทุ่งโป่ง อุบลรัตน์ ขอนแก่น 40250",
    },
    {
      value: "นาคำ อุบลรัตน์ ขอนแก่น 40250",
      label: "นาคำ อุบลรัตน์ ขอนแก่น 40250",
    },
    {
      value: "บ้านดง อุบลรัตน์ ขอนแก่น 40250",
      label: "บ้านดง อุบลรัตน์ ขอนแก่น 40250",
    },
    {
      value: "ศรีสุขสำราญ อุบลรัตน์ ขอนแก่น 40250",
      label: "ศรีสุขสำราญ อุบลรัตน์ ขอนแก่น 40250",
    },
    {
      value: "เขื่อนอุบลรัตน์ อุบลรัตน์ ขอนแก่น 40250",
      label: "เขื่อนอุบลรัตน์ อุบลรัตน์ ขอนแก่น 40250",
    },
    {
      value: "โคกสูง อุบลรัตน์ ขอนแก่น 40250",
      label: "โคกสูง อุบลรัตน์ ขอนแก่น 40250",
    },
    {
      value: "คำม่วง เขาสวนกวาง ขอนแก่น 40280",
      label: "คำม่วง เขาสวนกวาง ขอนแก่น 40280",
    },
    {
      value: "ดงเมืองแอม เขาสวนกวาง ขอนแก่น 40280",
      label: "ดงเมืองแอม เขาสวนกวาง ขอนแก่น 40280",
    },
    {
      value: "นางิ้ว เขาสวนกวาง ขอนแก่น 40280",
      label: "นางิ้ว เขาสวนกวาง ขอนแก่น 40280",
    },
    {
      value: "เขาสวนกวาง เขาสวนกวาง ขอนแก่น 40280",
      label: "เขาสวนกวาง เขาสวนกวาง ขอนแก่น 40280",
    },
    {
      value: "โนนสมบูรณ์ เขาสวนกวาง ขอนแก่น 40280",
      label: "โนนสมบูรณ์ เขาสวนกวาง ขอนแก่น 40280",
    },
    {
      value: "ขามป้อม เปือยน้อย ขอนแก่น 40340",
      label: "ขามป้อม เปือยน้อย ขอนแก่น 40340",
    },
    {
      value: "วังม่วง เปือยน้อย ขอนแก่น 40340",
      label: "วังม่วง เปือยน้อย ขอนแก่น 40340",
    },
    {
      value: "สระแก้ว เปือยน้อย ขอนแก่น 40340",
      label: "สระแก้ว เปือยน้อย ขอนแก่น 40340",
    },
    {
      value: "เปือยน้อย เปือยน้อย ขอนแก่น 40340",
      label: "เปือยน้อย เปือยน้อย ขอนแก่น 40340",
    },
    {
      value: "ดอนช้าง เมืองขอนแก่น ขอนแก่น 40000",
      label: "ดอนช้าง เมืองขอนแก่น ขอนแก่น 40000",
    },
    {
      value: "ดอนหัน เมืองขอนแก่น ขอนแก่น 40260",
      label: "ดอนหัน เมืองขอนแก่น ขอนแก่น 40260",
    },
    {
      value: "ท่าพระ เมืองขอนแก่น ขอนแก่น 40260",
      label: "ท่าพระ เมืองขอนแก่น ขอนแก่น 40260",
    },
    {
      value: "บึงเนียม เมืองขอนแก่น ขอนแก่น 40000",
      label: "บึงเนียม เมืองขอนแก่น ขอนแก่น 40000",
    },
    {
      value: "บ้านค้อ เมืองขอนแก่น ขอนแก่น 40000",
      label: "บ้านค้อ เมืองขอนแก่น ขอนแก่น 40000",
    },
    {
      value: "บ้านทุ่ม เมืองขอนแก่น ขอนแก่น 40000",
      label: "บ้านทุ่ม เมืองขอนแก่น ขอนแก่น 40000",
    },
    {
      value: "บ้านหว้า เมืองขอนแก่น ขอนแก่น 40000",
      label: "บ้านหว้า เมืองขอนแก่น ขอนแก่น 40000",
    },
    {
      value: "บ้านเป็ด เมืองขอนแก่น ขอนแก่น 40000",
      label: "บ้านเป็ด เมืองขอนแก่น ขอนแก่น 40000",
    },
    {
      value: "พระลับ เมืองขอนแก่น ขอนแก่น 40000",
      label: "พระลับ เมืองขอนแก่น ขอนแก่น 40000",
    },
    {
      value: "ศิลา เมืองขอนแก่น ขอนแก่น 40000",
      label: "ศิลา เมืองขอนแก่น ขอนแก่น 40000",
    },
    {
      value: "สาวะถี เมืองขอนแก่น ขอนแก่น 40000",
      label: "สาวะถี เมืองขอนแก่น ขอนแก่น 40000",
    },
    {
      value: "สำราญ เมืองขอนแก่น ขอนแก่น 40000",
      label: "สำราญ เมืองขอนแก่น ขอนแก่น 40000",
    },
    {
      value: "หนองตูม เมืองขอนแก่น ขอนแก่น 40000",
      label: "หนองตูม เมืองขอนแก่น ขอนแก่น 40000",
    },
    {
      value: "เมืองเก่า เมืองขอนแก่น ขอนแก่น 40000",
      label: "เมืองเก่า เมืองขอนแก่น ขอนแก่น 40000",
    },
    {
      value: "แดงใหญ่ เมืองขอนแก่น ขอนแก่น 40000",
      label: "แดงใหญ่ เมืองขอนแก่น ขอนแก่น 40000",
    },
    {
      value: "โคกสี เมืองขอนแก่น ขอนแก่น 40000",
      label: "โคกสี เมืองขอนแก่น ขอนแก่น 40000",
    },
    {
      value: "โนนท่อน เมืองขอนแก่น ขอนแก่น 40000",
      label: "โนนท่อน เมืองขอนแก่น ขอนแก่น 40000",
    },
    {
      value: "ในเมือง เมืองขอนแก่น ขอนแก่น 40000",
      label: "ในเมือง เมืองขอนแก่น ขอนแก่น 40000",
    },
    {
      value: "ก้านเหลือง แวงน้อย ขอนแก่น 40230",
      label: "ก้านเหลือง แวงน้อย ขอนแก่น 40230",
    },
    {
      value: "ทางขวาง แวงน้อย ขอนแก่น 40230",
      label: "ทางขวาง แวงน้อย ขอนแก่น 40230",
    },
    {
      value: "ท่านางแนว แวงน้อย ขอนแก่น 40230",
      label: "ท่านางแนว แวงน้อย ขอนแก่น 40230",
    },
    {
      value: "ท่าวัด แวงน้อย ขอนแก่น 40230",
      label: "ท่าวัด แวงน้อย ขอนแก่น 40230",
    },
    {
      value: "ละหานนา แวงน้อย ขอนแก่น 40230",
      label: "ละหานนา แวงน้อย ขอนแก่น 40230",
    },
    {
      value: "แวงน้อย แวงน้อย ขอนแก่น 40230",
      label: "แวงน้อย แวงน้อย ขอนแก่น 40230",
    },
    {
      value: "คอนฉิม แวงใหญ่ ขอนแก่น 40330",
      label: "คอนฉิม แวงใหญ่ ขอนแก่น 40330",
    },
    {
      value: "แวงใหญ่ แวงใหญ่ ขอนแก่น 40330",
      label: "แวงใหญ่ แวงใหญ่ ขอนแก่น 40330",
    },
    {
      value: "โนนทอง แวงใหญ่ ขอนแก่น 40330",
      label: "โนนทอง แวงใหญ่ ขอนแก่น 40330",
    },
    {
      value: "โนนสะอาด แวงใหญ่ ขอนแก่น 40330",
      label: "โนนสะอาด แวงใหญ่ ขอนแก่น 40330",
    },
    {
      value: "ใหม่นาเพียง แวงใหญ่ ขอนแก่น 40330",
      label: "ใหม่นาเพียง แวงใหญ่ ขอนแก่น 40330",
    },
    {
      value: "ซับสมบูรณ์ โคกโพธิ์ไชย ขอนแก่น 40160",
      label: "ซับสมบูรณ์ โคกโพธิ์ไชย ขอนแก่น 40160",
    },
    {
      value: "นาแพง โคกโพธิ์ไชย ขอนแก่น 40160",
      label: "นาแพง โคกโพธิ์ไชย ขอนแก่น 40160",
    },
    {
      value: "บ้านโคก โคกโพธิ์ไชย ขอนแก่น 40160",
      label: "บ้านโคก โคกโพธิ์ไชย ขอนแก่น 40160",
    },
    {
      value: "โพธิ์ไชย โคกโพธิ์ไชย ขอนแก่น 40160",
      label: "โพธิ์ไชย โคกโพธิ์ไชย ขอนแก่น 40160",
    },
    {
      value: "บ้านหัน โนนศิลา ขอนแก่น 40110",
      label: "บ้านหัน โนนศิลา ขอนแก่น 40110",
    },
    {
      value: "หนองปลาหมอ โนนศิลา ขอนแก่น 40110",
      label: "หนองปลาหมอ โนนศิลา ขอนแก่น 40110",
    },
    {
      value: "เปือยใหญ่ โนนศิลา ขอนแก่น 40110",
      label: "เปือยใหญ่ โนนศิลา ขอนแก่น 40110",
    },
    {
      value: "โนนศิลา โนนศิลา ขอนแก่น 40110",
      label: "โนนศิลา โนนศิลา ขอนแก่น 40110",
    },
    {
      value: "โนนแดง โนนศิลา ขอนแก่น 40110",
      label: "โนนแดง โนนศิลา ขอนแก่น 40110",
    },
    {
      value: "คอนสวรรค์ คอนสวรรค์ ชัยภูมิ 36140",
      label: "คอนสวรรค์ คอนสวรรค์ ชัยภูมิ 36140",
    },
    {
      value: "ช่องสามหมอ คอนสวรรค์ ชัยภูมิ 36140",
      label: "ช่องสามหมอ คอนสวรรค์ ชัยภูมิ 36140",
    },
    {
      value: "บ้านโสก คอนสวรรค์ ชัยภูมิ 36140",
      label: "บ้านโสก คอนสวรรค์ ชัยภูมิ 36140",
    },
    {
      value: "ยางหวาย คอนสวรรค์ ชัยภูมิ 36140",
      label: "ยางหวาย คอนสวรรค์ ชัยภูมิ 36140",
    },
    {
      value: "ศรีสำราญ คอนสวรรค์ ชัยภูมิ 36140",
      label: "ศรีสำราญ คอนสวรรค์ ชัยภูมิ 36140",
    },
    {
      value: "หนองขาม คอนสวรรค์ ชัยภูมิ 36140",
      label: "หนองขาม คอนสวรรค์ ชัยภูมิ 36140",
    },
    {
      value: "ห้วยไร่ คอนสวรรค์ ชัยภูมิ 36140",
      label: "ห้วยไร่ คอนสวรรค์ ชัยภูมิ 36140",
    },
    {
      value: "โคกมั่งงอย คอนสวรรค์ ชัยภูมิ 36140",
      label: "โคกมั่งงอย คอนสวรรค์ ชัยภูมิ 36140",
    },
    {
      value: "โนนสะอาด คอนสวรรค์ ชัยภูมิ 36140",
      label: "โนนสะอาด คอนสวรรค์ ชัยภูมิ 36140",
    },
    {
      value: "คอนสาร คอนสาร ชัยภูมิ 36180",
      label: "คอนสาร คอนสาร ชัยภูมิ 36180",
    },
    {
      value: "ดงกลาง คอนสาร ชัยภูมิ 36180",
      label: "ดงกลาง คอนสาร ชัยภูมิ 36180",
    },
    { value: "ดงบัง คอนสาร ชัยภูมิ 36180", label: "ดงบัง คอนสาร ชัยภูมิ 36180" },
    {
      value: "ทุ่งนาเลา คอนสาร ชัยภูมิ 36180",
      label: "ทุ่งนาเลา คอนสาร ชัยภูมิ 36180",
    },
    {
      value: "ทุ่งพระ คอนสาร ชัยภูมิ 36180",
      label: "ทุ่งพระ คอนสาร ชัยภูมิ 36180",
    },
    {
      value: "ทุ่งลุยลาย คอนสาร ชัยภูมิ 36180",
      label: "ทุ่งลุยลาย คอนสาร ชัยภูมิ 36180",
    },
    {
      value: "ห้วยยาง คอนสาร ชัยภูมิ 36180",
      label: "ห้วยยาง คอนสาร ชัยภูมิ 36180",
    },
    {
      value: "โนนคูณ คอนสาร ชัยภูมิ 36180",
      label: "โนนคูณ คอนสาร ชัยภูมิ 36180",
    },
    {
      value: "กุดน้ำใส จัตุรัส ชัยภูมิ 36130",
      label: "กุดน้ำใส จัตุรัส ชัยภูมิ 36130",
    },
    {
      value: "บ้านกอก จัตุรัส ชัยภูมิ 36130",
      label: "บ้านกอก จัตุรัส ชัยภูมิ 36130",
    },
    {
      value: "บ้านขาม จัตุรัส ชัยภูมิ 36130",
      label: "บ้านขาม จัตุรัส ชัยภูมิ 36130",
    },
    {
      value: "ละหาน จัตุรัส ชัยภูมิ 36130",
      label: "ละหาน จัตุรัส ชัยภูมิ 36130",
    },
    {
      value: "ส้มป่อย จัตุรัส ชัยภูมิ 36130",
      label: "ส้มป่อย จัตุรัส ชัยภูมิ 36130",
    },
    {
      value: "หนองบัวบาน จัตุรัส ชัยภูมิ 36130",
      label: "หนองบัวบาน จัตุรัส ชัยภูมิ 36130",
    },
    {
      value: "หนองบัวโคก จัตุรัส ชัยภูมิ 36220",
      label: "หนองบัวโคก จัตุรัส ชัยภูมิ 36220",
    },
    {
      value: "หนองบัวใหญ่ จัตุรัส ชัยภูมิ 36130",
      label: "หนองบัวใหญ่ จัตุรัส ชัยภูมิ 36130",
    },
    {
      value: "หนองโดน จัตุรัส ชัยภูมิ 36130",
      label: "หนองโดน จัตุรัส ชัยภูมิ 36130",
    },
    {
      value: "ซับใหญ่ ซับใหญ่ ชัยภูมิ 36130",
      label: "ซับใหญ่ ซับใหญ่ ชัยภูมิ 36130",
    },
    {
      value: "ตะโกทอง ซับใหญ่ ชัยภูมิ 36130",
      label: "ตะโกทอง ซับใหญ่ ชัยภูมิ 36130",
    },
    {
      value: "ท่ากูบ ซับใหญ่ ชัยภูมิ 36130",
      label: "ท่ากูบ ซับใหญ่ ชัยภูมิ 36130",
    },
    {
      value: "บ้านชวน บำเหน็จณรงค์ ชัยภูมิ 36160",
      label: "บ้านชวน บำเหน็จณรงค์ ชัยภูมิ 36160",
    },
    {
      value: "บ้านตาล บำเหน็จณรงค์ ชัยภูมิ 36220",
      label: "บ้านตาล บำเหน็จณรงค์ ชัยภูมิ 36220",
    },
    {
      value: "บ้านเพชร บำเหน็จณรงค์ ชัยภูมิ 36160",
      label: "บ้านเพชร บำเหน็จณรงค์ ชัยภูมิ 36160",
    },
    {
      value: "หัวทะเล บำเหน็จณรงค์ ชัยภูมิ 36220",
      label: "หัวทะเล บำเหน็จณรงค์ ชัยภูมิ 36220",
    },
    {
      value: "เกาะมะนาว บำเหน็จณรงค์ ชัยภูมิ 36160",
      label: "เกาะมะนาว บำเหน็จณรงค์ ชัยภูมิ 36160",
    },
    {
      value: "โคกเพชรพัฒนา บำเหน็จณรงค์ ชัยภูมิ 36160",
      label: "โคกเพชรพัฒนา บำเหน็จณรงค์ ชัยภูมิ 36160",
    },
    {
      value: "โคกเริงรมย์ บำเหน็จณรงค์ ชัยภูมิ 36160",
      label: "โคกเริงรมย์ บำเหน็จณรงค์ ชัยภูมิ 36160",
    },
    {
      value: "ชีบน บ้านเขว้า ชัยภูมิ 36170",
      label: "ชีบน บ้านเขว้า ชัยภูมิ 36170",
    },
    {
      value: "ตลาดแร้ง บ้านเขว้า ชัยภูมิ 36170",
      label: "ตลาดแร้ง บ้านเขว้า ชัยภูมิ 36170",
    },
    {
      value: "บ้านเขว้า บ้านเขว้า ชัยภูมิ 36170",
      label: "บ้านเขว้า บ้านเขว้า ชัยภูมิ 36170",
    },
    {
      value: "ภูแลนคา บ้านเขว้า ชัยภูมิ 36170",
      label: "ภูแลนคา บ้านเขว้า ชัยภูมิ 36170",
    },
    {
      value: "ลุ่มลำชี บ้านเขว้า ชัยภูมิ 36170",
      label: "ลุ่มลำชี บ้านเขว้า ชัยภูมิ 36170",
    },
    {
      value: "โนนแดง บ้านเขว้า ชัยภูมิ 36170",
      label: "โนนแดง บ้านเขว้า ชัยภูมิ 36170",
    },
    {
      value: "บ้านเต่า บ้านแท่น ชัยภูมิ 36190",
      label: "บ้านเต่า บ้านแท่น ชัยภูมิ 36190",
    },
    {
      value: "บ้านแท่น บ้านแท่น ชัยภูมิ 36190",
      label: "บ้านแท่น บ้านแท่น ชัยภูมิ 36190",
    },
    {
      value: "สระพัง บ้านแท่น ชัยภูมิ 36190",
      label: "สระพัง บ้านแท่น ชัยภูมิ 36190",
    },
    {
      value: "สามสวน บ้านแท่น ชัยภูมิ 36190",
      label: "สามสวน บ้านแท่น ชัยภูมิ 36190",
    },
    {
      value: "หนองคู บ้านแท่น ชัยภูมิ 36190",
      label: "หนองคู บ้านแท่น ชัยภูมิ 36190",
    },
    {
      value: "บ้านเจียง ภักดีชุมพล ชัยภูมิ 36260",
      label: "บ้านเจียง ภักดีชุมพล ชัยภูมิ 36260",
    },
    {
      value: "วังทอง ภักดีชุมพล ชัยภูมิ 36260",
      label: "วังทอง ภักดีชุมพล ชัยภูมิ 36260",
    },
    {
      value: "เจาทอง ภักดีชุมพล ชัยภูมิ 36260",
      label: "เจาทอง ภักดีชุมพล ชัยภูมิ 36260",
    },
    {
      value: "แหลมทอง ภักดีชุมพล ชัยภูมิ 36260",
      label: "แหลมทอง ภักดีชุมพล ชัยภูมิ 36260",
    },
    {
      value: "กวางโจน ภูเขียว ชัยภูมิ 36110",
      label: "กวางโจน ภูเขียว ชัยภูมิ 36110",
    },
    {
      value: "กุดยม ภูเขียว ชัยภูมิ 36110",
      label: "กุดยม ภูเขียว ชัยภูมิ 36110",
    },
    {
      value: "ธาตุทอง ภูเขียว ชัยภูมิ 36110",
      label: "ธาตุทอง ภูเขียว ชัยภูมิ 36110",
    },
    {
      value: "บ้านดอน ภูเขียว ชัยภูมิ 36110",
      label: "บ้านดอน ภูเขียว ชัยภูมิ 36110",
    },
    {
      value: "บ้านเพชร ภูเขียว ชัยภูมิ 36110",
      label: "บ้านเพชร ภูเขียว ชัยภูมิ 36110",
    },
    {
      value: "บ้านแก้ง ภูเขียว ชัยภูมิ 36110",
      label: "บ้านแก้ง ภูเขียว ชัยภูมิ 36110",
    },
    {
      value: "ผักปัง ภูเขียว ชัยภูมิ 36110",
      label: "ผักปัง ภูเขียว ชัยภูมิ 36110",
    },
    {
      value: "หนองคอนไทย ภูเขียว ชัยภูมิ 36110",
      label: "หนองคอนไทย ภูเขียว ชัยภูมิ 36110",
    },
    {
      value: "หนองตูม ภูเขียว ชัยภูมิ 36110",
      label: "หนองตูม ภูเขียว ชัยภูมิ 36110",
    },
    {
      value: "โคกสะอาด ภูเขียว ชัยภูมิ 36110",
      label: "โคกสะอาด ภูเขียว ชัยภูมิ 36110",
    },
    { value: "โอโล ภูเขียว ชัยภูมิ 36110", label: "โอโล ภูเขียว ชัยภูมิ 36110" },
    {
      value: "วังตะเฆ่ หนองบัวระเหว ชัยภูมิ 36250",
      label: "วังตะเฆ่ หนองบัวระเหว ชัยภูมิ 36250",
    },
    {
      value: "หนองบัวระเหว หนองบัวระเหว ชัยภูมิ 36250",
      label: "หนองบัวระเหว หนองบัวระเหว ชัยภูมิ 36250",
    },
    {
      value: "ห้วยแย้ หนองบัวระเหว ชัยภูมิ 36250",
      label: "ห้วยแย้ หนองบัวระเหว ชัยภูมิ 36250",
    },
    {
      value: "โคกสะอาด หนองบัวระเหว ชัยภูมิ 36250",
      label: "โคกสะอาด หนองบัวระเหว ชัยภูมิ 36250",
    },
    {
      value: "โสกปลาดุก หนองบัวระเหว ชัยภูมิ 36250",
      label: "โสกปลาดุก หนองบัวระเหว ชัยภูมิ 36250",
    },
    {
      value: "กุดชุมแสง หนองบัวแดง ชัยภูมิ 36210",
      label: "กุดชุมแสง หนองบัวแดง ชัยภูมิ 36210",
    },
    {
      value: "คูเมือง หนองบัวแดง ชัยภูมิ 36210",
      label: "คูเมือง หนองบัวแดง ชัยภูมิ 36210",
    },
    {
      value: "ถ้ำวัวแดง หนองบัวแดง ชัยภูมิ 36210",
      label: "ถ้ำวัวแดง หนองบัวแดง ชัยภูมิ 36210",
    },
    {
      value: "ท่าใหญ่ หนองบัวแดง ชัยภูมิ 36210",
      label: "ท่าใหญ่ หนองบัวแดง ชัยภูมิ 36210",
    },
    {
      value: "นางแดด หนองบัวแดง ชัยภูมิ 36210",
      label: "นางแดด หนองบัวแดง ชัยภูมิ 36210",
    },
    {
      value: "วังชมภู หนองบัวแดง ชัยภูมิ 36210",
      label: "วังชมภู หนองบัวแดง ชัยภูมิ 36210",
    },
    {
      value: "หนองบัวแดง หนองบัวแดง ชัยภูมิ 36210",
      label: "หนองบัวแดง หนองบัวแดง ชัยภูมิ 36210",
    },
    {
      value: "หนองแวง หนองบัวแดง ชัยภูมิ 36210",
      label: "หนองแวง หนองบัวแดง ชัยภูมิ 36210",
    },
    {
      value: "กุดเลาะ เกษตรสมบูรณ์ ชัยภูมิ 36120",
      label: "กุดเลาะ เกษตรสมบูรณ์ ชัยภูมิ 36120",
    },
    {
      value: "บ้านบัว เกษตรสมบูรณ์ ชัยภูมิ 36120",
      label: "บ้านบัว เกษตรสมบูรณ์ ชัยภูมิ 36120",
    },
    {
      value: "บ้านยาง เกษตรสมบูรณ์ ชัยภูมิ 36120",
      label: "บ้านยาง เกษตรสมบูรณ์ ชัยภูมิ 36120",
    },
    {
      value: "บ้านหัน เกษตรสมบูรณ์ ชัยภูมิ 36120",
      label: "บ้านหัน เกษตรสมบูรณ์ ชัยภูมิ 36120",
    },
    {
      value: "บ้านเดื่อ เกษตรสมบูรณ์ ชัยภูมิ 36120",
      label: "บ้านเดื่อ เกษตรสมบูรณ์ ชัยภูมิ 36120",
    },
    {
      value: "บ้านเป้า เกษตรสมบูรณ์ ชัยภูมิ 36120",
      label: "บ้านเป้า เกษตรสมบูรณ์ ชัยภูมิ 36120",
    },
    {
      value: "สระโพนทอง เกษตรสมบูรณ์ ชัยภูมิ 36120",
      label: "สระโพนทอง เกษตรสมบูรณ์ ชัยภูมิ 36120",
    },
    {
      value: "หนองข่า เกษตรสมบูรณ์ ชัยภูมิ 36120",
      label: "หนองข่า เกษตรสมบูรณ์ ชัยภูมิ 36120",
    },
    {
      value: "หนองโพนงาม เกษตรสมบูรณ์ ชัยภูมิ 36120",
      label: "หนองโพนงาม เกษตรสมบูรณ์ ชัยภูมิ 36120",
    },
    {
      value: "โนนกอก เกษตรสมบูรณ์ ชัยภูมิ 36120",
      label: "โนนกอก เกษตรสมบูรณ์ ชัยภูมิ 36120",
    },
    {
      value: "โนนทอง เกษตรสมบูรณ์ ชัยภูมิ 36120",
      label: "โนนทอง เกษตรสมบูรณ์ ชัยภูมิ 36120",
    },
    {
      value: "นายางกลัก เทพสถิต ชัยภูมิ 36230",
      label: "นายางกลัก เทพสถิต ชัยภูมิ 36230",
    },
    {
      value: "บ้านไร่ เทพสถิต ชัยภูมิ 36230",
      label: "บ้านไร่ เทพสถิต ชัยภูมิ 36230",
    },
    {
      value: "วะตะแบก เทพสถิต ชัยภูมิ 36230",
      label: "วะตะแบก เทพสถิต ชัยภูมิ 36230",
    },
    {
      value: "ห้วยยายจิ๋ว เทพสถิต ชัยภูมิ 36230",
      label: "ห้วยยายจิ๋ว เทพสถิต ชัยภูมิ 36230",
    },
    {
      value: "โป่งนก เทพสถิต ชัยภูมิ 36230",
      label: "โป่งนก เทพสถิต ชัยภูมิ 36230",
    },
    {
      value: "กะฮาด เนินสง่า ชัยภูมิ 36130",
      label: "กะฮาด เนินสง่า ชัยภูมิ 36130",
    },
    {
      value: "ตาเนิน เนินสง่า ชัยภูมิ 36130",
      label: "ตาเนิน เนินสง่า ชัยภูมิ 36130",
    },
    {
      value: "รังงาม เนินสง่า ชัยภูมิ 36130",
      label: "รังงาม เนินสง่า ชัยภูมิ 36130",
    },
    {
      value: "หนองฉิม เนินสง่า ชัยภูมิ 36130",
      label: "หนองฉิม เนินสง่า ชัยภูมิ 36130",
    },
    {
      value: "กุดตุ้ม เมืองชัยภูมิ ชัยภูมิ 36000",
      label: "กุดตุ้ม เมืองชัยภูมิ ชัยภูมิ 36000",
    },
    {
      value: "ชีลอง เมืองชัยภูมิ ชัยภูมิ 36000",
      label: "ชีลอง เมืองชัยภูมิ ชัยภูมิ 36000",
    },
    {
      value: "ซับสีทอง เมืองชัยภูมิ ชัยภูมิ 36000",
      label: "ซับสีทอง เมืองชัยภูมิ ชัยภูมิ 36000",
    },
    {
      value: "ท่าหินโงม เมืองชัยภูมิ ชัยภูมิ 36000",
      label: "ท่าหินโงม เมืองชัยภูมิ ชัยภูมิ 36000",
    },
    {
      value: "นาฝาย เมืองชัยภูมิ ชัยภูมิ 36000",
      label: "นาฝาย เมืองชัยภูมิ ชัยภูมิ 36000",
    },
    {
      value: "นาเสียว เมืองชัยภูมิ ชัยภูมิ 36000",
      label: "นาเสียว เมืองชัยภูมิ ชัยภูมิ 36000",
    },
    {
      value: "บุ่งคล้า เมืองชัยภูมิ ชัยภูมิ 36000",
      label: "บุ่งคล้า เมืองชัยภูมิ ชัยภูมิ 36000",
    },
    {
      value: "บ้านค่าย เมืองชัยภูมิ ชัยภูมิ 36240",
      label: "บ้านค่าย เมืองชัยภูมิ ชัยภูมิ 36240",
    },
    {
      value: "บ้านเล่า เมืองชัยภูมิ ชัยภูมิ 36000",
      label: "บ้านเล่า เมืองชัยภูมิ ชัยภูมิ 36000",
    },
    {
      value: "รอบเมือง เมืองชัยภูมิ ชัยภูมิ 36000",
      label: "รอบเมือง เมืองชัยภูมิ ชัยภูมิ 36000",
    },
    {
      value: "ลาดใหญ่ เมืองชัยภูมิ ชัยภูมิ 36000",
      label: "ลาดใหญ่ เมืองชัยภูมิ ชัยภูมิ 36000",
    },
    {
      value: "หนองนาแซง เมืองชัยภูมิ ชัยภูมิ 36000",
      label: "หนองนาแซง เมืองชัยภูมิ ชัยภูมิ 36000",
    },
    {
      value: "หนองไผ่ เมืองชัยภูมิ ชัยภูมิ 36240",
      label: "หนองไผ่ เมืองชัยภูมิ ชัยภูมิ 36240",
    },
    {
      value: "ห้วยต้อน เมืองชัยภูมิ ชัยภูมิ 36000",
      label: "ห้วยต้อน เมืองชัยภูมิ ชัยภูมิ 36000",
    },
    {
      value: "ห้วยบง เมืองชัยภูมิ ชัยภูมิ 36000",
      label: "ห้วยบง เมืองชัยภูมิ ชัยภูมิ 36000",
    },
    {
      value: "โคกสูง เมืองชัยภูมิ ชัยภูมิ 36000",
      label: "โคกสูง เมืองชัยภูมิ ชัยภูมิ 36000",
    },
    {
      value: "โนนสำราญ เมืองชัยภูมิ ชัยภูมิ 36240",
      label: "โนนสำราญ เมืองชัยภูมิ ชัยภูมิ 36240",
    },
    {
      value: "โพนทอง เมืองชัยภูมิ ชัยภูมิ 36000",
      label: "โพนทอง เมืองชัยภูมิ ชัยภูมิ 36000",
    },
    {
      value: "ในเมือง เมืองชัยภูมิ ชัยภูมิ 36000",
      label: "ในเมือง เมืองชัยภูมิ ชัยภูมิ 36000",
    },
    {
      value: "ช่องสามหมอ แก้งคร้อ ชัยภูมิ 36150",
      label: "ช่องสามหมอ แก้งคร้อ ชัยภูมิ 36150",
    },
    {
      value: "ท่ามะไฟหวาน แก้งคร้อ ชัยภูมิ 36150",
      label: "ท่ามะไฟหวาน แก้งคร้อ ชัยภูมิ 36150",
    },
    {
      value: "นาหนองทุ่ม แก้งคร้อ ชัยภูมิ 36150",
      label: "นาหนองทุ่ม แก้งคร้อ ชัยภูมิ 36150",
    },
    {
      value: "บ้านแก้ง แก้งคร้อ ชัยภูมิ 36150",
      label: "บ้านแก้ง แก้งคร้อ ชัยภูมิ 36150",
    },
    {
      value: "หนองขาม แก้งคร้อ ชัยภูมิ 36150",
      label: "หนองขาม แก้งคร้อ ชัยภูมิ 36150",
    },
    {
      value: "หนองสังข์ แก้งคร้อ ชัยภูมิ 36150",
      label: "หนองสังข์ แก้งคร้อ ชัยภูมิ 36150",
    },
    {
      value: "หนองไผ่ แก้งคร้อ ชัยภูมิ 36150",
      label: "หนองไผ่ แก้งคร้อ ชัยภูมิ 36150",
    },
    {
      value: "หลุบคา แก้งคร้อ ชัยภูมิ 36150",
      label: "หลุบคา แก้งคร้อ ชัยภูมิ 36150",
    },
    {
      value: "เก่าย่าดี แก้งคร้อ ชัยภูมิ 36150",
      label: "เก่าย่าดี แก้งคร้อ ชัยภูมิ 36150",
    },
    {
      value: "โคกกุง แก้งคร้อ ชัยภูมิ 36150",
      label: "โคกกุง แก้งคร้อ ชัยภูมิ 36150",
    },
    {
      value: "ท่าจำปา ท่าอุเทน นครพนม 48120",
      label: "ท่าจำปา ท่าอุเทน นครพนม 48120",
    },
    {
      value: "ท่าอุเทน ท่าอุเทน นครพนม 48120",
      label: "ท่าอุเทน ท่าอุเทน นครพนม 48120",
    },
    { value: "พนอม ท่าอุเทน นครพนม 48120", label: "พนอม ท่าอุเทน นครพนม 48120" },
    {
      value: "พะทาย ท่าอุเทน นครพนม 48120",
      label: "พะทาย ท่าอุเทน นครพนม 48120",
    },
    {
      value: "รามราช ท่าอุเทน นครพนม 48120",
      label: "รามราช ท่าอุเทน นครพนม 48120",
    },
    {
      value: "หนองเทา ท่าอุเทน นครพนม 48120",
      label: "หนองเทา ท่าอุเทน นครพนม 48120",
    },
    {
      value: "เวินพระบาท ท่าอุเทน นครพนม 48120",
      label: "เวินพระบาท ท่าอุเทน นครพนม 48120",
    },
    {
      value: "โนนตาล ท่าอุเทน นครพนม 48120",
      label: "โนนตาล ท่าอุเทน นครพนม 48120",
    },
    {
      value: "ไชยบุรี ท่าอุเทน นครพนม 48120",
      label: "ไชยบุรี ท่าอุเทน นครพนม 48120",
    },
    {
      value: "กุดฉิม ธาตุพนม นครพนม 48110",
      label: "กุดฉิม ธาตุพนม นครพนม 48110",
    },
    {
      value: "ดอนนางหงส์ ธาตุพนม นครพนม 48110",
      label: "ดอนนางหงส์ ธาตุพนม นครพนม 48110",
    },
    {
      value: "ธาตุพนม ธาตุพนม นครพนม 48110",
      label: "ธาตุพนม ธาตุพนม นครพนม 48110",
    },
    {
      value: "ธาตุพนมเหนือ ธาตุพนม นครพนม 48110",
      label: "ธาตุพนมเหนือ ธาตุพนม นครพนม 48110",
    },
    {
      value: "นาถ่อน ธาตุพนม นครพนม 48110",
      label: "นาถ่อน ธาตุพนม นครพนม 48110",
    },
    {
      value: "นาหนาด ธาตุพนม นครพนม 48110",
      label: "นาหนาด ธาตุพนม นครพนม 48110",
    },
    {
      value: "น้ำก่ำ ธาตุพนม นครพนม 48110",
      label: "น้ำก่ำ ธาตุพนม นครพนม 48110",
    },
    {
      value: "ฝั่งแดง ธาตุพนม นครพนม 48110",
      label: "ฝั่งแดง ธาตุพนม นครพนม 48110",
    },
    {
      value: "พระกลางทุ่ง ธาตุพนม นครพนม 48110",
      label: "พระกลางทุ่ง ธาตุพนม นครพนม 48110",
    },
    {
      value: "อุ่มเหม้า ธาตุพนม นครพนม 48110",
      label: "อุ่มเหม้า ธาตุพนม นครพนม 48110",
    },
    {
      value: "แสนพัน ธาตุพนม นครพนม 48110",
      label: "แสนพัน ธาตุพนม นครพนม 48110",
    },
    {
      value: "โพนแพง ธาตุพนม นครพนม 48110",
      label: "โพนแพง ธาตุพนม นครพนม 48110",
    },
    { value: "ดอนเตย นาทม นครพนม 48140", label: "ดอนเตย นาทม นครพนม 48140" },
    { value: "นาทม นาทม นครพนม 48140", label: "นาทม นาทม นครพนม 48140" },
    { value: "หนองซน นาทม นครพนม 48140", label: "หนองซน นาทม นครพนม 48140" },
    {
      value: "ท่าเรือ นาหว้า นครพนม 48180",
      label: "ท่าเรือ นาหว้า นครพนม 48180",
    },
    {
      value: "นาคูณใหญ่ นาหว้า นครพนม 48180",
      label: "นาคูณใหญ่ นาหว้า นครพนม 48180",
    },
    { value: "นางัว นาหว้า นครพนม 48180", label: "นางัว นาหว้า นครพนม 48180" },
    { value: "นาหว้า นาหว้า นครพนม 48180", label: "นาหว้า นาหว้า นครพนม 48180" },
    {
      value: "บ้านเสียว นาหว้า นครพนม 48180",
      label: "บ้านเสียว นาหว้า นครพนม 48180",
    },
    {
      value: "เหล่าพัฒนา นาหว้า นครพนม 48180",
      label: "เหล่าพัฒนา นาหว้า นครพนม 48180",
    },
    {
      value: "ก้านเหลือง นาแก นครพนม 48130",
      label: "ก้านเหลือง นาแก นครพนม 48130",
    },
    { value: "คำพี้ นาแก นครพนม 48130", label: "คำพี้ นาแก นครพนม 48130" },
    { value: "นาคู่ นาแก นครพนม 48130", label: "นาคู่ นาแก นครพนม 48130" },
    { value: "นาเลียง นาแก นครพนม 48130", label: "นาเลียง นาแก นครพนม 48130" },
    { value: "นาแก นาแก นครพนม 48130", label: "นาแก นาแก นครพนม 48130" },
    { value: "บ้านแก้ง นาแก นครพนม 48130", label: "บ้านแก้ง นาแก นครพนม 48130" },
    { value: "พระซอง นาแก นครพนม 48130", label: "พระซอง นาแก นครพนม 48130" },
    { value: "พิมาน นาแก นครพนม 48130", label: "พิมาน นาแก นครพนม 48130" },
    { value: "พุ่มแก นาแก นครพนม 48130", label: "พุ่มแก นาแก นครพนม 48130" },
    { value: "สีชมพู นาแก นครพนม 48130", label: "สีชมพู นาแก นครพนม 48130" },
    { value: "หนองบ่อ นาแก นครพนม 48130", label: "หนองบ่อ นาแก นครพนม 48130" },
    {
      value: "หนองสังข์ นาแก นครพนม 48130",
      label: "หนองสังข์ นาแก นครพนม 48130",
    },
    { value: "นางัว บ้านแพง นครพนม 48140", label: "นางัว บ้านแพง นครพนม 48140" },
    { value: "นาเข บ้านแพง นครพนม 48140", label: "นาเข บ้านแพง นครพนม 48140" },
    {
      value: "บ้านแพง บ้านแพง นครพนม 48140",
      label: "บ้านแพง บ้านแพง นครพนม 48140",
    },
    {
      value: "หนองแวง บ้านแพง นครพนม 48140",
      label: "หนองแวง บ้านแพง นครพนม 48140",
    },
    {
      value: "โพนทอง บ้านแพง นครพนม 48140",
      label: "โพนทอง บ้านแพง นครพนม 48140",
    },
    {
      value: "ไผ่ล้อม บ้านแพง นครพนม 48140",
      label: "ไผ่ล้อม บ้านแพง นครพนม 48140",
    },
    {
      value: "กุตาไก้ ปลาปาก นครพนม 48160",
      label: "กุตาไก้ ปลาปาก นครพนม 48160",
    },
    {
      value: "นามะเขือ ปลาปาก นครพนม 48160",
      label: "นามะเขือ ปลาปาก นครพนม 48160",
    },
    { value: "ปลาปาก ปลาปาก นครพนม 48160", label: "ปลาปาก ปลาปาก นครพนม 48160" },
    { value: "มหาชัย ปลาปาก นครพนม 48160", label: "มหาชัย ปลาปาก นครพนม 48160" },
    { value: "หนองฮี ปลาปาก นครพนม 48160", label: "หนองฮี ปลาปาก นครพนม 48160" },
    {
      value: "หนองเทาใหญ่ ปลาปาก นครพนม 48160",
      label: "หนองเทาใหญ่ ปลาปาก นครพนม 48160",
    },
    {
      value: "โคกสว่าง ปลาปาก นครพนม 48160",
      label: "โคกสว่าง ปลาปาก นครพนม 48160",
    },
    { value: "โคกสูง ปลาปาก นครพนม 48160", label: "โคกสูง ปลาปาก นครพนม 48160" },
    { value: "ยอดชาด วังยาง นครพนม 48130", label: "ยอดชาด วังยาง นครพนม 48130" },
    { value: "วังยาง วังยาง นครพนม 48130", label: "วังยาง วังยาง นครพนม 48130" },
    {
      value: "หนองโพธิ์ วังยาง นครพนม 48130",
      label: "หนองโพธิ์ วังยาง นครพนม 48130",
    },
    { value: "โคกสี วังยาง นครพนม 48130", label: "โคกสี วังยาง นครพนม 48130" },
    {
      value: "ท่าบ่อสงคราม ศรีสงคราม นครพนม 48150",
      label: "ท่าบ่อสงคราม ศรีสงคราม นครพนม 48150",
    },
    {
      value: "นาคำ ศรีสงคราม นครพนม 48150",
      label: "นาคำ ศรีสงคราม นครพนม 48150",
    },
    {
      value: "นาเดื่อ ศรีสงคราม นครพนม 48150",
      label: "นาเดื่อ ศรีสงคราม นครพนม 48150",
    },
    {
      value: "บ้านข่า ศรีสงคราม นครพนม 48150",
      label: "บ้านข่า ศรีสงคราม นครพนม 48150",
    },
    {
      value: "บ้านเอื้อง ศรีสงคราม นครพนม 48150",
      label: "บ้านเอื้อง ศรีสงคราม นครพนม 48150",
    },
    {
      value: "ศรีสงคราม ศรีสงคราม นครพนม 48150",
      label: "ศรีสงคราม ศรีสงคราม นครพนม 48150",
    },
    {
      value: "สามผง ศรีสงคราม นครพนม 48150",
      label: "สามผง ศรีสงคราม นครพนม 48150",
    },
    {
      value: "หาดแพง ศรีสงคราม นครพนม 48150",
      label: "หาดแพง ศรีสงคราม นครพนม 48150",
    },
    {
      value: "โพนสว่าง ศรีสงคราม นครพนม 48150",
      label: "โพนสว่าง ศรีสงคราม นครพนม 48150",
    },
    {
      value: "กุรุคุ เมืองนครพนม นครพนม 48000",
      label: "กุรุคุ เมืองนครพนม นครพนม 48000",
    },
    {
      value: "ขามเฒ่า เมืองนครพนม นครพนม 48000",
      label: "ขามเฒ่า เมืองนครพนม นครพนม 48000",
    },
    {
      value: "คำเตย เมืองนครพนม นครพนม 48000",
      label: "คำเตย เมืองนครพนม นครพนม 48000",
    },
    {
      value: "ดงขวาง เมืองนครพนม นครพนม 48000",
      label: "ดงขวาง เมืองนครพนม นครพนม 48000",
    },
    {
      value: "ท่าค้อ เมืองนครพนม นครพนม 48000",
      label: "ท่าค้อ เมืองนครพนม นครพนม 48000",
    },
    {
      value: "นาทราย เมืองนครพนม นครพนม 48000",
      label: "นาทราย เมืองนครพนม นครพนม 48000",
    },
    {
      value: "นาราชควาย เมืองนครพนม นครพนม 48000",
      label: "นาราชควาย เมืองนครพนม นครพนม 48000",
    },
    {
      value: "บ้านกลาง เมืองนครพนม นครพนม 48000",
      label: "บ้านกลาง เมืองนครพนม นครพนม 48000",
    },
    {
      value: "บ้านผึ้ง เมืองนครพนม นครพนม 48000",
      label: "บ้านผึ้ง เมืองนครพนม นครพนม 48000",
    },
    {
      value: "วังตามัว เมืองนครพนม นครพนม 48000",
      label: "วังตามัว เมืองนครพนม นครพนม 48000",
    },
    {
      value: "หนองญาติ เมืองนครพนม นครพนม 48000",
      label: "หนองญาติ เมืองนครพนม นครพนม 48000",
    },
    {
      value: "หนองแสง เมืองนครพนม นครพนม 48000",
      label: "หนองแสง เมืองนครพนม นครพนม 48000",
    },
    {
      value: "อาจสามารถ เมืองนครพนม นครพนม 48000",
      label: "อาจสามารถ เมืองนครพนม นครพนม 48000",
    },
    {
      value: "โพธิ์ตาก เมืองนครพนม นครพนม 48000",
      label: "โพธิ์ตาก เมืองนครพนม นครพนม 48000",
    },
    {
      value: "ในเมือง เมืองนครพนม นครพนม 48000",
      label: "ในเมือง เมืองนครพนม นครพนม 48000",
    },
    {
      value: "ท่าลาด เรณูนคร นครพนม 48170",
      label: "ท่าลาด เรณูนคร นครพนม 48170",
    },
    { value: "นาขาม เรณูนคร นครพนม 48170", label: "นาขาม เรณูนคร นครพนม 48170" },
    { value: "นางาม เรณูนคร นครพนม 48170", label: "นางาม เรณูนคร นครพนม 48170" },
    {
      value: "หนองย่างชิ้น เรณูนคร นครพนม 48170",
      label: "หนองย่างชิ้น เรณูนคร นครพนม 48170",
    },
    { value: "เรณู เรณูนคร นครพนม 48170", label: "เรณู เรณูนคร นครพนม 48170" },
    {
      value: "เรณูใต้ เรณูนคร นครพนม 48170",
      label: "เรณูใต้ เรณูนคร นครพนม 48170",
    },
    {
      value: "โคกหินแฮ่ เรณูนคร นครพนม 48170",
      label: "โคกหินแฮ่ เรณูนคร นครพนม 48170",
    },
    {
      value: "โพนทอง เรณูนคร นครพนม 48170",
      label: "โพนทอง เรณูนคร นครพนม 48170",
    },
    {
      value: "นาขมิ้น โพนสวรรค์ นครพนม 48190",
      label: "นาขมิ้น โพนสวรรค์ นครพนม 48190",
    },
    {
      value: "นาหัวบ่อ โพนสวรรค์ นครพนม 48190",
      label: "นาหัวบ่อ โพนสวรรค์ นครพนม 48190",
    },
    {
      value: "นาใน โพนสวรรค์ นครพนม 48190",
      label: "นาใน โพนสวรรค์ นครพนม 48190",
    },
    {
      value: "บ้านค้อ โพนสวรรค์ นครพนม 48190",
      label: "บ้านค้อ โพนสวรรค์ นครพนม 48190",
    },
    {
      value: "โพนจาน โพนสวรรค์ นครพนม 48190",
      label: "โพนจาน โพนสวรรค์ นครพนม 48190",
    },
    {
      value: "โพนบก โพนสวรรค์ นครพนม 48190",
      label: "โพนบก โพนสวรรค์ นครพนม 48190",
    },
    {
      value: "โพนสวรรค์ โพนสวรรค์ นครพนม 48190",
      label: "โพนสวรรค์ โพนสวรรค์ นครพนม 48190",
    },
    {
      value: "ขามทะเลสอ ขามทะเลสอ นครราชสีมา 30280",
      label: "ขามทะเลสอ ขามทะเลสอ นครราชสีมา 30280",
    },
    {
      value: "บึงอ้อ ขามทะเลสอ นครราชสีมา 30280",
      label: "บึงอ้อ ขามทะเลสอ นครราชสีมา 30280",
    },
    {
      value: "พันดุง ขามทะเลสอ นครราชสีมา 30280",
      label: "พันดุง ขามทะเลสอ นครราชสีมา 30280",
    },
    {
      value: "หนองสรวง ขามทะเลสอ นครราชสีมา 30280",
      label: "หนองสรวง ขามทะเลสอ นครราชสีมา 30280",
    },
    {
      value: "โป่งแดง ขามทะเลสอ นครราชสีมา 30280",
      label: "โป่งแดง ขามทะเลสอ นครราชสีมา 30280",
    },
    {
      value: "ขามสะแกแสง ขามสะแกแสง นครราชสีมา 30290",
      label: "ขามสะแกแสง ขามสะแกแสง นครราชสีมา 30290",
    },
    {
      value: "ชีวึก ขามสะแกแสง นครราชสีมา 30290",
      label: "ชีวึก ขามสะแกแสง นครราชสีมา 30290",
    },
    {
      value: "พะงาด ขามสะแกแสง นครราชสีมา 30290",
      label: "พะงาด ขามสะแกแสง นครราชสีมา 30290",
    },
    {
      value: "หนองหัวฟาน ขามสะแกแสง นครราชสีมา 30290",
      label: "หนองหัวฟาน ขามสะแกแสง นครราชสีมา 30290",
    },
    {
      value: "เมืองนาท ขามสะแกแสง นครราชสีมา 30290",
      label: "เมืองนาท ขามสะแกแสง นครราชสีมา 30290",
    },
    {
      value: "เมืองเกษตร ขามสะแกแสง นครราชสีมา 30290",
      label: "เมืองเกษตร ขามสะแกแสง นครราชสีมา 30290",
    },
    {
      value: "โนนเมือง ขามสะแกแสง นครราชสีมา 30290",
      label: "โนนเมือง ขามสะแกแสง นครราชสีมา 30290",
    },
    {
      value: "ขามสมบูรณ์ คง นครราชสีมา 30260",
      label: "ขามสมบูรณ์ คง นครราชสีมา 30260",
    },
    { value: "คูขาด คง นครราชสีมา 30260", label: "คูขาด คง นครราชสีมา 30260" },
    {
      value: "ดอนใหญ่ คง นครราชสีมา 30260",
      label: "ดอนใหญ่ คง นครราชสีมา 30260",
    },
    { value: "ตาจั่น คง นครราชสีมา 30260", label: "ตาจั่น คง นครราชสีมา 30260" },
    {
      value: "บ้านปรางค์ คง นครราชสีมา 30260",
      label: "บ้านปรางค์ คง นครราชสีมา 30260",
    },
    {
      value: "หนองบัว คง นครราชสีมา 30260",
      label: "หนองบัว คง นครราชสีมา 30260",
    },
    {
      value: "หนองมะนาว คง นครราชสีมา 30260",
      label: "หนองมะนาว คง นครราชสีมา 30260",
    },
    {
      value: "เทพาลัย คง นครราชสีมา 30260",
      label: "เทพาลัย คง นครราชสีมา 30260",
    },
    {
      value: "เมืองคง คง นครราชสีมา 30260",
      label: "เมืองคง คง นครราชสีมา 30260",
    },
    {
      value: "โนนเต็ง คง นครราชสีมา 30260",
      label: "โนนเต็ง คง นครราชสีมา 30260",
    },
    {
      value: "ครบุรี ครบุรี นครราชสีมา 30250",
      label: "ครบุรี ครบุรี นครราชสีมา 30250",
    },
    {
      value: "ครบุรีใต้ ครบุรี นครราชสีมา 30250",
      label: "ครบุรีใต้ ครบุรี นครราชสีมา 30250",
    },
    {
      value: "จระเข้หิน ครบุรี นครราชสีมา 30250",
      label: "จระเข้หิน ครบุรี นครราชสีมา 30250",
    },
    {
      value: "ตะแบกบาน ครบุรี นครราชสีมา 30250",
      label: "ตะแบกบาน ครบุรี นครราชสีมา 30250",
    },
    {
      value: "บ้านใหม่ ครบุรี นครราชสีมา 30250",
      label: "บ้านใหม่ ครบุรี นครราชสีมา 30250",
    },
    {
      value: "มาบตะโกเอน ครบุรี นครราชสีมา 30250",
      label: "มาบตะโกเอน ครบุรี นครราชสีมา 30250",
    },
    {
      value: "ลำเพียก ครบุรี นครราชสีมา 30250",
      label: "ลำเพียก ครบุรี นครราชสีมา 30250",
    },
    {
      value: "สระว่านพระยา ครบุรี นครราชสีมา 30250",
      label: "สระว่านพระยา ครบุรี นครราชสีมา 30250",
    },
    {
      value: "อรพิมพ์ ครบุรี นครราชสีมา 30250",
      label: "อรพิมพ์ ครบุรี นครราชสีมา 30250",
    },
    {
      value: "เฉลียง ครบุรี นครราชสีมา 30250",
      label: "เฉลียง ครบุรี นครราชสีมา 30250",
    },
    {
      value: "แชะ ครบุรี นครราชสีมา 30250",
      label: "แชะ ครบุรี นครราชสีมา 30250",
    },
    {
      value: "โคกกระชาย ครบุรี นครราชสีมา 30250",
      label: "โคกกระชาย ครบุรี นครราชสีมา 30250",
    },
    {
      value: "คลองเมือง จักราช นครราชสีมา 30230",
      label: "คลองเมือง จักราช นครราชสีมา 30230",
    },
    {
      value: "จักราช จักราช นครราชสีมา 30230",
      label: "จักราช จักราช นครราชสีมา 30230",
    },
    {
      value: "ทองหลาง จักราช นครราชสีมา 30230",
      label: "ทองหลาง จักราช นครราชสีมา 30230",
    },
    {
      value: "ศรีละกอ จักราช นครราชสีมา 30230",
      label: "ศรีละกอ จักราช นครราชสีมา 30230",
    },
    {
      value: "สีสุก จักราช นครราชสีมา 30230",
      label: "สีสุก จักราช นครราชสีมา 30230",
    },
    {
      value: "หนองขาม จักราช นครราชสีมา 30230",
      label: "หนองขาม จักราช นครราชสีมา 30230",
    },
    {
      value: "หนองพลวง จักราช นครราชสีมา 30230",
      label: "หนองพลวง จักราช นครราชสีมา 30230",
    },
    {
      value: "หินโคน จักราช นครราชสีมา 30230",
      label: "หินโคน จักราช นครราชสีมา 30230",
    },
    {
      value: "ชุมพวง ชุมพวง นครราชสีมา 30270",
      label: "ชุมพวง ชุมพวง นครราชสีมา 30270",
    },
    {
      value: "ตลาดไทร ชุมพวง นครราชสีมา 30270",
      label: "ตลาดไทร ชุมพวง นครราชสีมา 30270",
    },
    {
      value: "ท่าลาด ชุมพวง นครราชสีมา 30270",
      label: "ท่าลาด ชุมพวง นครราชสีมา 30270",
    },
    {
      value: "ประสุข ชุมพวง นครราชสีมา 30270",
      label: "ประสุข ชุมพวง นครราชสีมา 30270",
    },
    {
      value: "สาหร่าย ชุมพวง นครราชสีมา 30270",
      label: "สาหร่าย ชุมพวง นครราชสีมา 30270",
    },
    {
      value: "หนองหลัก ชุมพวง นครราชสีมา 30270",
      label: "หนองหลัก ชุมพวง นครราชสีมา 30270",
    },
    {
      value: "โนนตูม ชุมพวง นครราชสีมา 30270",
      label: "โนนตูม ชุมพวง นครราชสีมา 30270",
    },
    {
      value: "โนนยอ ชุมพวง นครราชสีมา 30270",
      label: "โนนยอ ชุมพวง นครราชสีมา 30270",
    },
    {
      value: "โนนรัง ชุมพวง นครราชสีมา 30270",
      label: "โนนรัง ชุมพวง นครราชสีมา 30270",
    },
    {
      value: "กุดพิมาน ด่านขุนทด นครราชสีมา 30210",
      label: "กุดพิมาน ด่านขุนทด นครราชสีมา 30210",
    },
    {
      value: "ด่านขุนทด ด่านขุนทด นครราชสีมา 30210",
      label: "ด่านขุนทด ด่านขุนทด นครราชสีมา 30210",
    },
    {
      value: "ด่านนอก ด่านขุนทด นครราชสีมา 30210",
      label: "ด่านนอก ด่านขุนทด นครราชสีมา 30210",
    },
    {
      value: "ด่านใน ด่านขุนทด นครราชสีมา 30210",
      label: "ด่านใน ด่านขุนทด นครราชสีมา 30210",
    },
    {
      value: "ตะเคียน ด่านขุนทด นครราชสีมา 30210",
      label: "ตะเคียน ด่านขุนทด นครราชสีมา 30210",
    },
    {
      value: "บ้านเก่า ด่านขุนทด นครราชสีมา 30210",
      label: "บ้านเก่า ด่านขุนทด นครราชสีมา 30210",
    },
    {
      value: "บ้านแปรง ด่านขุนทด นครราชสีมา 36220",
      label: "บ้านแปรง ด่านขุนทด นครราชสีมา 36220",
    },
    {
      value: "พันชนะ ด่านขุนทด นครราชสีมา 30210",
      label: "พันชนะ ด่านขุนทด นครราชสีมา 30210",
    },
    {
      value: "สระจรเข้ ด่านขุนทด นครราชสีมา 30210",
      label: "สระจรเข้ ด่านขุนทด นครราชสีมา 30210",
    },
    {
      value: "หนองกราด ด่านขุนทด นครราชสีมา 30210",
      label: "หนองกราด ด่านขุนทด นครราชสีมา 30210",
    },
    {
      value: "หนองบัวตะเกียด ด่านขุนทด นครราชสีมา 30210",
      label: "หนองบัวตะเกียด ด่านขุนทด นครราชสีมา 30210",
    },
    {
      value: "หนองบัวละคร ด่านขุนทด นครราชสีมา 30210",
      label: "หนองบัวละคร ด่านขุนทด นครราชสีมา 30210",
    },
    {
      value: "หนองไทร ด่านขุนทด นครราชสีมา 36220",
      label: "หนองไทร ด่านขุนทด นครราชสีมา 36220",
    },
    {
      value: "หินดาด ด่านขุนทด นครราชสีมา 30210",
      label: "หินดาด ด่านขุนทด นครราชสีมา 30210",
    },
    {
      value: "ห้วยบง ด่านขุนทด นครราชสีมา 30210",
      label: "ห้วยบง ด่านขุนทด นครราชสีมา 30210",
    },
    {
      value: "โนนเมืองพัฒนา ด่านขุนทด นครราชสีมา 30210",
      label: "โนนเมืองพัฒนา ด่านขุนทด นครราชสีมา 30210",
    },
    {
      value: "บัวลาย บัวลาย นครราชสีมา 30120",
      label: "บัวลาย บัวลาย นครราชสีมา 30120",
    },
    {
      value: "หนองหว้า บัวลาย นครราชสีมา 30120",
      label: "หนองหว้า บัวลาย นครราชสีมา 30120",
    },
    {
      value: "เมืองพะไล บัวลาย นครราชสีมา 30120",
      label: "เมืองพะไล บัวลาย นครราชสีมา 30120",
    },
    {
      value: "โนนจาน บัวลาย นครราชสีมา 30120",
      label: "โนนจาน บัวลาย นครราชสีมา 30120",
    },
    {
      value: "กุดจอก บัวใหญ่ นครราชสีมา 30120",
      label: "กุดจอก บัวใหญ่ นครราชสีมา 30120",
    },
    {
      value: "ขุนทอง บัวใหญ่ นครราชสีมา 30120",
      label: "ขุนทอง บัวใหญ่ นครราชสีมา 30120",
    },
    {
      value: "ดอนตะหนิน บัวใหญ่ นครราชสีมา 30120",
      label: "ดอนตะหนิน บัวใหญ่ นครราชสีมา 30120",
    },
    {
      value: "ด่านช้าง บัวใหญ่ นครราชสีมา 30120",
      label: "ด่านช้าง บัวใหญ่ นครราชสีมา 30120",
    },
    {
      value: "บัวใหญ่ บัวใหญ่ นครราชสีมา 30120",
      label: "บัวใหญ่ บัวใหญ่ นครราชสีมา 30120",
    },
    {
      value: "หนองบัวสะอาด บัวใหญ่ นครราชสีมา 30120",
      label: "หนองบัวสะอาด บัวใหญ่ นครราชสีมา 30120",
    },
    {
      value: "หนองแจ้งใหญ่ บัวใหญ่ นครราชสีมา 30120",
      label: "หนองแจ้งใหญ่ บัวใหญ่ นครราชสีมา 30120",
    },
    {
      value: "ห้วยยาง บัวใหญ่ นครราชสีมา 30120",
      label: "ห้วยยาง บัวใหญ่ นครราชสีมา 30120",
    },
    {
      value: "เสมาใหญ่ บัวใหญ่ นครราชสีมา 30120",
      label: "เสมาใหญ่ บัวใหญ่ นครราชสีมา 30120",
    },
    {
      value: "โนนทองหลาง บัวใหญ่ นครราชสีมา 30120",
      label: "โนนทองหลาง บัวใหญ่ นครราชสีมา 30120",
    },
    {
      value: "ช่อระกา บ้านเหลื่อม นครราชสีมา 30350",
      label: "ช่อระกา บ้านเหลื่อม นครราชสีมา 30350",
    },
    {
      value: "บ้านเหลื่อม บ้านเหลื่อม นครราชสีมา 30350",
      label: "บ้านเหลื่อม บ้านเหลื่อม นครราชสีมา 30350",
    },
    {
      value: "วังโพธิ์ บ้านเหลื่อม นครราชสีมา 30350",
      label: "วังโพธิ์ บ้านเหลื่อม นครราชสีมา 30350",
    },
    {
      value: "โคกกระเบื้อง บ้านเหลื่อม นครราชสีมา 30350",
      label: "โคกกระเบื้อง บ้านเหลื่อม นครราชสีมา 30350",
    },
    {
      value: "กระทุ่มราย ประทาย นครราชสีมา 30180",
      label: "กระทุ่มราย ประทาย นครราชสีมา 30180",
    },
    {
      value: "ดอนมัน ประทาย นครราชสีมา 30180",
      label: "ดอนมัน ประทาย นครราชสีมา 30180",
    },
    {
      value: "ตลาดไทร ประทาย นครราชสีมา 30180",
      label: "ตลาดไทร ประทาย นครราชสีมา 30180",
    },
    {
      value: "ทุ่งสว่าง ประทาย นครราชสีมา 30180",
      label: "ทุ่งสว่าง ประทาย นครราชสีมา 30180",
    },
    {
      value: "นางรำ ประทาย นครราชสีมา 30180",
      label: "นางรำ ประทาย นครราชสีมา 30180",
    },
    {
      value: "ประทาย ประทาย นครราชสีมา 30180",
      label: "ประทาย ประทาย นครราชสีมา 30180",
    },
    {
      value: "วังไม้แดง ประทาย นครราชสีมา 30180",
      label: "วังไม้แดง ประทาย นครราชสีมา 30180",
    },
    {
      value: "หนองค่าย ประทาย นครราชสีมา 30180",
      label: "หนองค่าย ประทาย นครราชสีมา 30180",
    },
    {
      value: "หนองพลวง ประทาย นครราชสีมา 30180",
      label: "หนองพลวง ประทาย นครราชสีมา 30180",
    },
    {
      value: "หันห้วยทราย ประทาย นครราชสีมา 30180",
      label: "หันห้วยทราย ประทาย นครราชสีมา 30180",
    },
    {
      value: "เมืองโดน ประทาย นครราชสีมา 30180",
      label: "เมืองโดน ประทาย นครราชสีมา 30180",
    },
    {
      value: "โคกกลาง ประทาย นครราชสีมา 30180",
      label: "โคกกลาง ประทาย นครราชสีมา 30180",
    },
    {
      value: "โนนเพ็ด ประทาย นครราชสีมา 30180",
      label: "โนนเพ็ด ประทาย นครราชสีมา 30180",
    },
    {
      value: "งิ้ว ปักธงชัย นครราชสีมา 30150",
      label: "งิ้ว ปักธงชัย นครราชสีมา 30150",
    },
    {
      value: "ดอน ปักธงชัย นครราชสีมา 30150",
      label: "ดอน ปักธงชัย นครราชสีมา 30150",
    },
    {
      value: "ตะขบ ปักธงชัย นครราชสีมา 30150",
      label: "ตะขบ ปักธงชัย นครราชสีมา 30150",
    },
    {
      value: "ตะคุ ปักธงชัย นครราชสีมา 30150",
      label: "ตะคุ ปักธงชัย นครราชสีมา 30150",
    },
    {
      value: "ตูม ปักธงชัย นครราชสีมา 30150",
      label: "ตูม ปักธงชัย นครราชสีมา 30150",
    },
    {
      value: "ธงชัยเหนือ ปักธงชัย นครราชสีมา 30150",
      label: "ธงชัยเหนือ ปักธงชัย นครราชสีมา 30150",
    },
    {
      value: "นกออก ปักธงชัย นครราชสีมา 30150",
      label: "นกออก ปักธงชัย นครราชสีมา 30150",
    },
    {
      value: "บ่อปลาทอง ปักธงชัย นครราชสีมา 30150",
      label: "บ่อปลาทอง ปักธงชัย นครราชสีมา 30150",
    },
    {
      value: "ภูหลวง ปักธงชัย นครราชสีมา 30150",
      label: "ภูหลวง ปักธงชัย นครราชสีมา 30150",
    },
    {
      value: "ลำนางแก้ว ปักธงชัย นครราชสีมา 30150",
      label: "ลำนางแก้ว ปักธงชัย นครราชสีมา 30150",
    },
    {
      value: "สะแกราช ปักธงชัย นครราชสีมา 30150",
      label: "สะแกราช ปักธงชัย นครราชสีมา 30150",
    },
    {
      value: "สำโรง ปักธงชัย นครราชสีมา 30150",
      label: "สำโรง ปักธงชัย นครราชสีมา 30150",
    },
    {
      value: "สุขเกษม ปักธงชัย นครราชสีมา 30150",
      label: "สุขเกษม ปักธงชัย นครราชสีมา 30150",
    },
    {
      value: "เกษมทรัพย์ ปักธงชัย นครราชสีมา 30150",
      label: "เกษมทรัพย์ ปักธงชัย นครราชสีมา 30150",
    },
    {
      value: "เมืองปัก ปักธงชัย นครราชสีมา 30150",
      label: "เมืองปัก ปักธงชัย นครราชสีมา 30150",
    },
    {
      value: "โคกไทย ปักธงชัย นครราชสีมา 30150",
      label: "โคกไทย ปักธงชัย นครราชสีมา 30150",
    },
    {
      value: "กลางดง ปากช่อง นครราชสีมา 30320",
      label: "กลางดง ปากช่อง นครราชสีมา 30320",
    },
    {
      value: "ขนงพระ ปากช่อง นครราชสีมา 30130",
      label: "ขนงพระ ปากช่อง นครราชสีมา 30130",
    },
    {
      value: "คลองม่วง ปากช่อง นครราชสีมา 30130",
      label: "คลองม่วง ปากช่อง นครราชสีมา 30130",
    },
    {
      value: "จันทึก ปากช่อง นครราชสีมา 30130",
      label: "จันทึก ปากช่อง นครราชสีมา 30130",
    },
    {
      value: "ปากช่อง ปากช่อง นครราชสีมา 30130",
      label: "ปากช่อง ปากช่อง นครราชสีมา 30130",
    },
    {
      value: "พญาเย็น ปากช่อง นครราชสีมา 30320",
      label: "พญาเย็น ปากช่อง นครราชสีมา 30320",
    },
    {
      value: "วังกะทะ ปากช่อง นครราชสีมา 30130",
      label: "วังกะทะ ปากช่อง นครราชสีมา 30130",
    },
    {
      value: "วังไทร ปากช่อง นครราชสีมา 30130",
      label: "วังไทร ปากช่อง นครราชสีมา 30130",
    },
    {
      value: "หนองน้ำแดง ปากช่อง นครราชสีมา 30130",
      label: "หนองน้ำแดง ปากช่อง นครราชสีมา 30130",
    },
    {
      value: "หนองสาหร่าย ปากช่อง นครราชสีมา 30130",
      label: "หนองสาหร่าย ปากช่อง นครราชสีมา 30130",
    },
    {
      value: "หมูสี ปากช่อง นครราชสีมา 30130",
      label: "หมูสี ปากช่อง นครราชสีมา 30130",
    },
    {
      value: "โป่งตาลอง ปากช่อง นครราชสีมา 30130",
      label: "โป่งตาลอง ปากช่อง นครราชสีมา 30130",
    },
    {
      value: "ทัพรั้ง พระทองคำ นครราชสีมา 30220",
      label: "ทัพรั้ง พระทองคำ นครราชสีมา 30220",
    },
    {
      value: "พังเทียม พระทองคำ นครราชสีมา 30220",
      label: "พังเทียม พระทองคำ นครราชสีมา 30220",
    },
    {
      value: "มาบกราด พระทองคำ นครราชสีมา 30220",
      label: "มาบกราด พระทองคำ นครราชสีมา 30220",
    },
    {
      value: "สระพระ พระทองคำ นครราชสีมา 30220",
      label: "สระพระ พระทองคำ นครราชสีมา 30220",
    },
    {
      value: "หนองหอย พระทองคำ นครราชสีมา 30220",
      label: "หนองหอย พระทองคำ นครราชสีมา 30220",
    },
    {
      value: "กระชอน พิมาย นครราชสีมา 30110",
      label: "กระชอน พิมาย นครราชสีมา 30110",
    },
    {
      value: "กระเบื้องใหญ่ พิมาย นครราชสีมา 30110",
      label: "กระเบื้องใหญ่ พิมาย นครราชสีมา 30110",
    },
    {
      value: "ชีวาน พิมาย นครราชสีมา 30110",
      label: "ชีวาน พิมาย นครราชสีมา 30110",
    },
    {
      value: "ดงใหญ่ พิมาย นครราชสีมา 30110",
      label: "ดงใหญ่ พิมาย นครราชสีมา 30110",
    },
    {
      value: "ท่าหลวง พิมาย นครราชสีมา 30110",
      label: "ท่าหลวง พิมาย นครราชสีมา 30110",
    },
    {
      value: "ธารละหลอด พิมาย นครราชสีมา 30110",
      label: "ธารละหลอด พิมาย นครราชสีมา 30110",
    },
    {
      value: "นิคมสร้างตนเอง พิมาย นครราชสีมา 30110",
      label: "นิคมสร้างตนเอง พิมาย นครราชสีมา 30110",
    },
    {
      value: "รังกาใหญ่ พิมาย นครราชสีมา 30110",
      label: "รังกาใหญ่ พิมาย นครราชสีมา 30110",
    },
    {
      value: "สัมฤทธิ์ พิมาย นครราชสีมา 30110",
      label: "สัมฤทธิ์ พิมาย นครราชสีมา 30110",
    },
    {
      value: "หนองระเวียง พิมาย นครราชสีมา 30110",
      label: "หนองระเวียง พิมาย นครราชสีมา 30110",
    },
    {
      value: "โบสถ์ พิมาย นครราชสีมา 30110",
      label: "โบสถ์ พิมาย นครราชสีมา 30110",
    },
    {
      value: "ในเมือง พิมาย นครราชสีมา 30110",
      label: "ในเมือง พิมาย นครราชสีมา 30110",
    },
    {
      value: "ขุย ลำทะเมนชัย นครราชสีมา 30270",
      label: "ขุย ลำทะเมนชัย นครราชสีมา 30270",
    },
    {
      value: "ช่องแมว ลำทะเมนชัย นครราชสีมา 30270",
      label: "ช่องแมว ลำทะเมนชัย นครราชสีมา 30270",
    },
    {
      value: "บ้านยาง ลำทะเมนชัย นครราชสีมา 30270",
      label: "บ้านยาง ลำทะเมนชัย นครราชสีมา 30270",
    },
    {
      value: "ไพล ลำทะเมนชัย นครราชสีมา 30270",
      label: "ไพล ลำทะเมนชัย นครราชสีมา 30270",
    },
    {
      value: "ระเริง วังน้ำเขียว นครราชสีมา 30150",
      label: "ระเริง วังน้ำเขียว นครราชสีมา 30150",
    },
    {
      value: "วังน้ำเขียว วังน้ำเขียว นครราชสีมา 30370",
      label: "วังน้ำเขียว วังน้ำเขียว นครราชสีมา 30370",
    },
    {
      value: "วังหมี วังน้ำเขียว นครราชสีมา 30370",
      label: "วังหมี วังน้ำเขียว นครราชสีมา 30370",
    },
    {
      value: "อุดมทรัพย์ วังน้ำเขียว นครราชสีมา 30370",
      label: "อุดมทรัพย์ วังน้ำเขียว นครราชสีมา 30370",
    },
    {
      value: "ไทยสามัคคี วังน้ำเขียว นครราชสีมา 30370",
      label: "ไทยสามัคคี วังน้ำเขียว นครราชสีมา 30370",
    },
    {
      value: "กฤษณา สีคิ้ว นครราชสีมา 30140",
      label: "กฤษณา สีคิ้ว นครราชสีมา 30140",
    },
    {
      value: "กุดน้อย สีคิ้ว นครราชสีมา 30140",
      label: "กุดน้อย สีคิ้ว นครราชสีมา 30140",
    },
    {
      value: "คลองไผ่ สีคิ้ว นครราชสีมา 30340",
      label: "คลองไผ่ สีคิ้ว นครราชสีมา 30340",
    },
    {
      value: "ดอนเมือง สีคิ้ว นครราชสีมา 30140",
      label: "ดอนเมือง สีคิ้ว นครราชสีมา 30140",
    },
    {
      value: "บ้านหัน สีคิ้ว นครราชสีมา 30140",
      label: "บ้านหัน สีคิ้ว นครราชสีมา 30140",
    },
    {
      value: "มิตรภาพ สีคิ้ว นครราชสีมา 30140",
      label: "มิตรภาพ สีคิ้ว นครราชสีมา 30140",
    },
    {
      value: "ลาดบัวขาว สีคิ้ว นครราชสีมา 30340",
      label: "ลาดบัวขาว สีคิ้ว นครราชสีมา 30340",
    },
    {
      value: "วังโรงใหญ่ สีคิ้ว นครราชสีมา 30140",
      label: "วังโรงใหญ่ สีคิ้ว นครราชสีมา 30140",
    },
    {
      value: "สีคิ้ว สีคิ้ว นครราชสีมา 30140",
      label: "สีคิ้ว สีคิ้ว นครราชสีมา 30140",
    },
    {
      value: "หนองน้ำใส สีคิ้ว นครราชสีมา 30140",
      label: "หนองน้ำใส สีคิ้ว นครราชสีมา 30140",
    },
    {
      value: "หนองบัวน้อย สีคิ้ว นครราชสีมา 30140",
      label: "หนองบัวน้อย สีคิ้ว นครราชสีมา 30140",
    },
    {
      value: "หนองหญ้าขาว สีคิ้ว นครราชสีมา 30140",
      label: "หนองหญ้าขาว สีคิ้ว นครราชสีมา 30140",
    },
    {
      value: "สามเมือง สีดา นครราชสีมา 30430",
      label: "สามเมือง สีดา นครราชสีมา 30430",
    },
    { value: "สีดา สีดา นครราชสีมา 30430", label: "สีดา สีดา นครราชสีมา 30430" },
    {
      value: "หนองตาดใหญ่ สีดา นครราชสีมา 30430",
      label: "หนองตาดใหญ่ สีดา นครราชสีมา 30430",
    },
    {
      value: "โนนประดู่ สีดา นครราชสีมา 30430",
      label: "โนนประดู่ สีดา นครราชสีมา 30430",
    },
    {
      value: "โพนทอง สีดา นครราชสีมา 30430",
      label: "โพนทอง สีดา นครราชสีมา 30430",
    },
    {
      value: "กุดจิก สูงเนิน นครราชสีมา 30380",
      label: "กุดจิก สูงเนิน นครราชสีมา 30380",
    },
    {
      value: "นากลาง สูงเนิน นครราชสีมา 30380",
      label: "นากลาง สูงเนิน นครราชสีมา 30380",
    },
    {
      value: "บุ่งขี้เหล็ก สูงเนิน นครราชสีมา 30170",
      label: "บุ่งขี้เหล็ก สูงเนิน นครราชสีมา 30170",
    },
    {
      value: "มะเกลือเก่า สูงเนิน นครราชสีมา 30170",
      label: "มะเกลือเก่า สูงเนิน นครราชสีมา 30170",
    },
    {
      value: "มะเกลือใหม่ สูงเนิน นครราชสีมา 30170",
      label: "มะเกลือใหม่ สูงเนิน นครราชสีมา 30170",
    },
    {
      value: "สูงเนิน สูงเนิน นครราชสีมา 30170",
      label: "สูงเนิน สูงเนิน นครราชสีมา 30170",
    },
    {
      value: "หนองตะไก้ สูงเนิน นครราชสีมา 30380",
      label: "หนองตะไก้ สูงเนิน นครราชสีมา 30380",
    },
    {
      value: "เสมา สูงเนิน นครราชสีมา 30170",
      label: "เสมา สูงเนิน นครราชสีมา 30170",
    },
    {
      value: "โคราช สูงเนิน นครราชสีมา 30170",
      label: "โคราช สูงเนิน นครราชสีมา 30170",
    },
    {
      value: "โค้งยาง สูงเนิน นครราชสีมา 30170",
      label: "โค้งยาง สูงเนิน นครราชสีมา 30170",
    },
    {
      value: "โนนค่า สูงเนิน นครราชสีมา 30170",
      label: "โนนค่า สูงเนิน นครราชสีมา 30170",
    },
    {
      value: "บ้านใหม่ หนองบุญมาก นครราชสีมา 30410",
      label: "บ้านใหม่ หนองบุญมาก นครราชสีมา 30410",
    },
    {
      value: "ลุงเขว้า หนองบุญมาก นครราชสีมา 30410",
      label: "ลุงเขว้า หนองบุญมาก นครราชสีมา 30410",
    },
    {
      value: "สารภี หนองบุญมาก นครราชสีมา 30410",
      label: "สารภี หนองบุญมาก นครราชสีมา 30410",
    },
    {
      value: "หนองตะไก้ หนองบุญมาก นครราชสีมา 30410",
      label: "หนองตะไก้ หนองบุญมาก นครราชสีมา 30410",
    },
    {
      value: "หนองบุนนาก หนองบุญมาก นครราชสีมา 30410",
      label: "หนองบุนนาก หนองบุญมาก นครราชสีมา 30410",
    },
    {
      value: "หนองหัวแรต หนองบุญมาก นครราชสีมา 30410",
      label: "หนองหัวแรต หนองบุญมาก นครราชสีมา 30410",
    },
    {
      value: "หนองไม้ไผ่ หนองบุญมาก นครราชสีมา 30410",
      label: "หนองไม้ไผ่ หนองบุญมาก นครราชสีมา 30410",
    },
    {
      value: "แหลมทอง หนองบุญมาก นครราชสีมา 30410",
      label: "แหลมทอง หนองบุญมาก นครราชสีมา 30410",
    },
    {
      value: "ไทยเจริญ หนองบุญมาก นครราชสีมา 30410",
      label: "ไทยเจริญ หนองบุญมาก นครราชสีมา 30410",
    },
    {
      value: "กงรถ ห้วยแถลง นครราชสีมา 30240",
      label: "กงรถ ห้วยแถลง นครราชสีมา 30240",
    },
    {
      value: "งิ้ว ห้วยแถลง นครราชสีมา 30240",
      label: "งิ้ว ห้วยแถลง นครราชสีมา 30240",
    },
    {
      value: "ตะโก ห้วยแถลง นครราชสีมา 30240",
      label: "ตะโก ห้วยแถลง นครราชสีมา 30240",
    },
    {
      value: "ทับสวาย ห้วยแถลง นครราชสีมา 30240",
      label: "ทับสวาย ห้วยแถลง นครราชสีมา 30240",
    },
    {
      value: "หลุ่งตะเคียน ห้วยแถลง นครราชสีมา 30240",
      label: "หลุ่งตะเคียน ห้วยแถลง นครราชสีมา 30240",
    },
    {
      value: "หลุ่งประดู่ ห้วยแถลง นครราชสีมา 30240",
      label: "หลุ่งประดู่ ห้วยแถลง นครราชสีมา 30240",
    },
    {
      value: "หินดาด ห้วยแถลง นครราชสีมา 30240",
      label: "หินดาด ห้วยแถลง นครราชสีมา 30240",
    },
    {
      value: "ห้วยแคน ห้วยแถลง นครราชสีมา 30240",
      label: "ห้วยแคน ห้วยแถลง นครราชสีมา 30240",
    },
    {
      value: "ห้วยแถลง ห้วยแถลง นครราชสีมา 30240",
      label: "ห้วยแถลง ห้วยแถลง นครราชสีมา 30240",
    },
    {
      value: "เมืองพลับพลา ห้วยแถลง นครราชสีมา 30240",
      label: "เมืองพลับพลา ห้วยแถลง นครราชสีมา 30240",
    },
    {
      value: "ช้างทอง เฉลิมพระเกียรติ นครราชสีมา 30230",
      label: "ช้างทอง เฉลิมพระเกียรติ นครราชสีมา 30230",
    },
    {
      value: "ท่าช้าง เฉลิมพระเกียรติ นครราชสีมา 30230",
      label: "ท่าช้าง เฉลิมพระเกียรติ นครราชสีมา 30230",
    },
    {
      value: "พระพุทธ เฉลิมพระเกียรติ นครราชสีมา 30230",
      label: "พระพุทธ เฉลิมพระเกียรติ นครราชสีมา 30230",
    },
    {
      value: "หนองงูเหลือม เฉลิมพระเกียรติ นครราชสีมา 30000",
      label: "หนองงูเหลือม เฉลิมพระเกียรติ นครราชสีมา 30000",
    },
    {
      value: "หนองยาง เฉลิมพระเกียรติ นครราชสีมา 30230",
      label: "หนองยาง เฉลิมพระเกียรติ นครราชสีมา 30230",
    },
    {
      value: "บึงปรือ เทพารักษ์ นครราชสีมา 30210",
      label: "บึงปรือ เทพารักษ์ นครราชสีมา 30210",
    },
    {
      value: "วังยายทอง เทพารักษ์ นครราชสีมา 30210",
      label: "วังยายทอง เทพารักษ์ นครราชสีมา 30210",
    },
    {
      value: "สำนักตะคร้อ เทพารักษ์ นครราชสีมา 30210",
      label: "สำนักตะคร้อ เทพารักษ์ นครราชสีมา 30210",
    },
    {
      value: "หนองแวง เทพารักษ์ นครราชสีมา 30210",
      label: "หนองแวง เทพารักษ์ นครราชสีมา 30210",
    },
    {
      value: "จอหอ เมืองนครราชสีมา นครราชสีมา 30310",
      label: "จอหอ เมืองนครราชสีมา นครราชสีมา 30310",
    },
    {
      value: "ตลาด เมืองนครราชสีมา นครราชสีมา 30310",
      label: "ตลาด เมืองนครราชสีมา นครราชสีมา 30310",
    },
    {
      value: "บ้านเกาะ เมืองนครราชสีมา นครราชสีมา 30000",
      label: "บ้านเกาะ เมืองนครราชสีมา นครราชสีมา 30000",
    },
    {
      value: "บ้านโพธิ์ เมืองนครราชสีมา นครราชสีมา 30310",
      label: "บ้านโพธิ์ เมืองนครราชสีมา นครราชสีมา 30310",
    },
    {
      value: "บ้านใหม่ เมืองนครราชสีมา นครราชสีมา 30000",
      label: "บ้านใหม่ เมืองนครราชสีมา นครราชสีมา 30000",
    },
    {
      value: "ปรุใหญ่ เมืองนครราชสีมา นครราชสีมา 30000",
      label: "ปรุใหญ่ เมืองนครราชสีมา นครราชสีมา 30000",
    },
    {
      value: "พลกรัง เมืองนครราชสีมา นครราชสีมา 30000",
      label: "พลกรัง เมืองนครราชสีมา นครราชสีมา 30000",
    },
    {
      value: "พะเนา เมืองนครราชสีมา นครราชสีมา 30000",
      label: "พะเนา เมืองนครราชสีมา นครราชสีมา 30000",
    },
    {
      value: "พุดซา เมืองนครราชสีมา นครราชสีมา 30000",
      label: "พุดซา เมืองนครราชสีมา นครราชสีมา 30000",
    },
    {
      value: "มะเริง เมืองนครราชสีมา นครราชสีมา 30000",
      label: "มะเริง เมืองนครราชสีมา นครราชสีมา 30000",
    },
    {
      value: "สีมุม เมืองนครราชสีมา นครราชสีมา 30000",
      label: "สีมุม เมืองนครราชสีมา นครราชสีมา 30000",
    },
    {
      value: "สุรนารี เมืองนครราชสีมา นครราชสีมา 30000",
      label: "สุรนารี เมืองนครราชสีมา นครราชสีมา 30000",
    },
    {
      value: "หนองกระทุ่ม เมืองนครราชสีมา นครราชสีมา 30000",
      label: "หนองกระทุ่ม เมืองนครราชสีมา นครราชสีมา 30000",
    },
    {
      value: "หนองจะบก เมืองนครราชสีมา นครราชสีมา 30000",
      label: "หนองจะบก เมืองนครราชสีมา นครราชสีมา 30000",
    },
    {
      value: "หนองบัวศาลา เมืองนครราชสีมา นครราชสีมา 30000",
      label: "หนองบัวศาลา เมืองนครราชสีมา นครราชสีมา 30000",
    },
    {
      value: "หนองระเวียง เมืองนครราชสีมา นครราชสีมา 30000",
      label: "หนองระเวียง เมืองนครราชสีมา นครราชสีมา 30000",
    },
    {
      value: "หนองไข่น้ำ เมืองนครราชสีมา นครราชสีมา 30310",
      label: "หนองไข่น้ำ เมืองนครราชสีมา นครราชสีมา 30310",
    },
    {
      value: "หนองไผ่ล้อม เมืองนครราชสีมา นครราชสีมา 30000",
      label: "หนองไผ่ล้อม เมืองนครราชสีมา นครราชสีมา 30000",
    },
    {
      value: "หมื่นไวย เมืองนครราชสีมา นครราชสีมา 30000",
      label: "หมื่นไวย เมืองนครราชสีมา นครราชสีมา 30000",
    },
    {
      value: "หัวทะเล เมืองนครราชสีมา นครราชสีมา 30000",
      label: "หัวทะเล เมืองนครราชสีมา นครราชสีมา 30000",
    },
    {
      value: "โคกกรวด เมืองนครราชสีมา นครราชสีมา 30280",
      label: "โคกกรวด เมืองนครราชสีมา นครราชสีมา 30280",
    },
    {
      value: "โคกสูง เมืองนครราชสีมา นครราชสีมา 30310",
      label: "โคกสูง เมืองนครราชสีมา นครราชสีมา 30310",
    },
    {
      value: "โพธิ์กลาง เมืองนครราชสีมา นครราชสีมา 30000",
      label: "โพธิ์กลาง เมืองนครราชสีมา นครราชสีมา 30000",
    },
    {
      value: "ในเมือง เมืองนครราชสีมา นครราชสีมา 30000",
      label: "ในเมือง เมืองนครราชสีมา นครราชสีมา 30000",
    },
    {
      value: "ไชยมงคล เมืองนครราชสีมา นครราชสีมา 30000",
      label: "ไชยมงคล เมืองนครราชสีมา นครราชสีมา 30000",
    },
    {
      value: "กระเบื้องนอก เมืองยาง นครราชสีมา 30270",
      label: "กระเบื้องนอก เมืองยาง นครราชสีมา 30270",
    },
    {
      value: "ละหานปลาค้าว เมืองยาง นครราชสีมา 30270",
      label: "ละหานปลาค้าว เมืองยาง นครราชสีมา 30270",
    },
    {
      value: "เมืองยาง เมืองยาง นครราชสีมา 30270",
      label: "เมืองยาง เมืองยาง นครราชสีมา 30270",
    },
    {
      value: "โนนอุดม เมืองยาง นครราชสีมา 30270",
      label: "โนนอุดม เมืองยาง นครราชสีมา 30270",
    },
    {
      value: "กุดโบสถ์ เสิงสาง นครราชสีมา 30330",
      label: "กุดโบสถ์ เสิงสาง นครราชสีมา 30330",
    },
    {
      value: "บ้านราษฎร์ เสิงสาง นครราชสีมา 30330",
      label: "บ้านราษฎร์ เสิงสาง นครราชสีมา 30330",
    },
    {
      value: "สระตะเคียน เสิงสาง นครราชสีมา 30330",
      label: "สระตะเคียน เสิงสาง นครราชสีมา 30330",
    },
    {
      value: "สุขไพบูลย์ เสิงสาง นครราชสีมา 30330",
      label: "สุขไพบูลย์ เสิงสาง นครราชสีมา 30330",
    },
    {
      value: "เสิงสาง เสิงสาง นครราชสีมา 30330",
      label: "เสิงสาง เสิงสาง นครราชสีมา 30330",
    },
    {
      value: "โนนสมบูรณ์ เสิงสาง นครราชสีมา 30330",
      label: "โนนสมบูรณ์ เสิงสาง นครราชสีมา 30330",
    },
    {
      value: "บึงพะไล แก้งสนามนาง นครราชสีมา 30440",
      label: "บึงพะไล แก้งสนามนาง นครราชสีมา 30440",
    },
    {
      value: "บึงสำโรง แก้งสนามนาง นครราชสีมา 30440",
      label: "บึงสำโรง แก้งสนามนาง นครราชสีมา 30440",
    },
    {
      value: "สีสุก แก้งสนามนาง นครราชสีมา 30440",
      label: "สีสุก แก้งสนามนาง นครราชสีมา 30440",
    },
    {
      value: "แก้งสนามนาง แก้งสนามนาง นครราชสีมา 30440",
      label: "แก้งสนามนาง แก้งสนามนาง นครราชสีมา 30440",
    },
    {
      value: "โนนสำราญ แก้งสนามนาง นครราชสีมา 30440",
      label: "โนนสำราญ แก้งสนามนาง นครราชสีมา 30440",
    },
    {
      value: "กระโทก โชคชัย นครราชสีมา 30190",
      label: "กระโทก โชคชัย นครราชสีมา 30190",
    },
    {
      value: "ด่านเกวียน โชคชัย นครราชสีมา 30190",
      label: "ด่านเกวียน โชคชัย นครราชสีมา 30190",
    },
    {
      value: "ทุ่งอรุณ โชคชัย นครราชสีมา 30190",
      label: "ทุ่งอรุณ โชคชัย นครราชสีมา 30190",
    },
    {
      value: "ท่าจะหลุง โชคชัย นครราชสีมา 30190",
      label: "ท่าจะหลุง โชคชัย นครราชสีมา 30190",
    },
    {
      value: "ท่าลาดขาว โชคชัย นครราชสีมา 30190",
      label: "ท่าลาดขาว โชคชัย นครราชสีมา 30190",
    },
    {
      value: "ท่าอ่าง โชคชัย นครราชสีมา 30190",
      label: "ท่าอ่าง โชคชัย นครราชสีมา 30190",
    },
    {
      value: "ท่าเยี่ยม โชคชัย นครราชสีมา 30190",
      label: "ท่าเยี่ยม โชคชัย นครราชสีมา 30190",
    },
    {
      value: "พลับพลา โชคชัย นครราชสีมา 30190",
      label: "พลับพลา โชคชัย นครราชสีมา 30190",
    },
    {
      value: "ละลมใหม่พัฒนา โชคชัย นครราชสีมา 30190",
      label: "ละลมใหม่พัฒนา โชคชัย นครราชสีมา 30190",
    },
    {
      value: "โชคชัย โชคชัย นครราชสีมา 30190",
      label: "โชคชัย โชคชัย นครราชสีมา 30190",
    },
    {
      value: "ขามเฒ่า โนนสูง นครราชสีมา 30160",
      label: "ขามเฒ่า โนนสูง นครราชสีมา 30160",
    },
    {
      value: "จันอัด โนนสูง นครราชสีมา 30160",
      label: "จันอัด โนนสูง นครราชสีมา 30160",
    },
    {
      value: "ดอนชมพู โนนสูง นครราชสีมา 30160",
      label: "ดอนชมพู โนนสูง นครราชสีมา 30160",
    },
    {
      value: "ดอนหวาย โนนสูง นครราชสีมา 30160",
      label: "ดอนหวาย โนนสูง นครราชสีมา 30160",
    },
    {
      value: "ด่านคล้า โนนสูง นครราชสีมา 30160",
      label: "ด่านคล้า โนนสูง นครราชสีมา 30160",
    },
    {
      value: "ธารปราสาท โนนสูง นครราชสีมา 30420",
      label: "ธารปราสาท โนนสูง นครราชสีมา 30420",
    },
    {
      value: "บิง โนนสูง นครราชสีมา 30160",
      label: "บิง โนนสูง นครราชสีมา 30160",
    },
    {
      value: "พลสงคราม โนนสูง นครราชสีมา 30160",
      label: "พลสงคราม โนนสูง นครราชสีมา 30160",
    },
    {
      value: "มะค่า โนนสูง นครราชสีมา 30160",
      label: "มะค่า โนนสูง นครราชสีมา 30160",
    },
    {
      value: "ลำคอหงษ์ โนนสูง นครราชสีมา 30160",
      label: "ลำคอหงษ์ โนนสูง นครราชสีมา 30160",
    },
    {
      value: "ลำมูล โนนสูง นครราชสีมา 30160",
      label: "ลำมูล โนนสูง นครราชสีมา 30160",
    },
    {
      value: "หลุมข้าว โนนสูง นครราชสีมา 30160",
      label: "หลุมข้าว โนนสูง นครราชสีมา 30160",
    },
    {
      value: "เมืองปราสาท โนนสูง นครราชสีมา 30160",
      label: "เมืองปราสาท โนนสูง นครราชสีมา 30160",
    },
    {
      value: "โตนด โนนสูง นครราชสีมา 30160",
      label: "โตนด โนนสูง นครราชสีมา 30160",
    },
    {
      value: "โนนสูง โนนสูง นครราชสีมา 30160",
      label: "โนนสูง โนนสูง นครราชสีมา 30160",
    },
    {
      value: "ใหม่ โนนสูง นครราชสีมา 30160",
      label: "ใหม่ โนนสูง นครราชสีมา 30160",
    },
    {
      value: "ดอนยาวใหญ่ โนนแดง นครราชสีมา 30360",
      label: "ดอนยาวใหญ่ โนนแดง นครราชสีมา 30360",
    },
    {
      value: "วังหิน โนนแดง นครราชสีมา 30360",
      label: "วังหิน โนนแดง นครราชสีมา 30360",
    },
    {
      value: "สำพะเนียง โนนแดง นครราชสีมา 30360",
      label: "สำพะเนียง โนนแดง นครราชสีมา 30360",
    },
    {
      value: "โนนตาเถร โนนแดง นครราชสีมา 30360",
      label: "โนนตาเถร โนนแดง นครราชสีมา 30360",
    },
    {
      value: "โนนแดง โนนแดง นครราชสีมา 30360",
      label: "โนนแดง โนนแดง นครราชสีมา 30360",
    },
    {
      value: "กำปัง โนนไทย นครราชสีมา 30220",
      label: "กำปัง โนนไทย นครราชสีมา 30220",
    },
    {
      value: "ค้างพลู โนนไทย นครราชสีมา 30220",
      label: "ค้างพลู โนนไทย นครราชสีมา 30220",
    },
    {
      value: "ด่านจาก โนนไทย นครราชสีมา 30220",
      label: "ด่านจาก โนนไทย นครราชสีมา 30220",
    },
    {
      value: "ถนนโพธิ์ โนนไทย นครราชสีมา 30220",
      label: "ถนนโพธิ์ โนนไทย นครราชสีมา 30220",
    },
    {
      value: "บัลลังก์ โนนไทย นครราชสีมา 30220",
      label: "บัลลังก์ โนนไทย นครราชสีมา 30220",
    },
    {
      value: "บ้านวัง โนนไทย นครราชสีมา 30220",
      label: "บ้านวัง โนนไทย นครราชสีมา 30220",
    },
    {
      value: "มะค่า โนนไทย นครราชสีมา 30220",
      label: "มะค่า โนนไทย นครราชสีมา 30220",
    },
    {
      value: "สายออ โนนไทย นครราชสีมา 30220",
      label: "สายออ โนนไทย นครราชสีมา 30220",
    },
    {
      value: "สำโรง โนนไทย นครราชสีมา 30220",
      label: "สำโรง โนนไทย นครราชสีมา 30220",
    },
    {
      value: "โนนไทย โนนไทย นครราชสีมา 30220",
      label: "โนนไทย โนนไทย นครราชสีมา 30220",
    },
    {
      value: "ดงบัง บึงโขงหลง บึงกาฬ 38220",
      label: "ดงบัง บึงโขงหลง บึงกาฬ 38220",
    },
    {
      value: "ท่าดอกคำ บึงโขงหลง บึงกาฬ 38220",
      label: "ท่าดอกคำ บึงโขงหลง บึงกาฬ 38220",
    },
    {
      value: "บึงโขงหลง บึงโขงหลง บึงกาฬ 38220",
      label: "บึงโขงหลง บึงโขงหลง บึงกาฬ 38220",
    },
    {
      value: "โพธิ์หมากแข้ง บึงโขงหลง บึงกาฬ 38220",
      label: "โพธิ์หมากแข้ง บึงโขงหลง บึงกาฬ 38220",
    },
    {
      value: "บุ่งคล้า บุ่งคล้า บึงกาฬ 38000",
      label: "บุ่งคล้า บุ่งคล้า บึงกาฬ 38000",
    },
    {
      value: "หนองเดิ่น บุ่งคล้า บึงกาฬ 38000",
      label: "หนองเดิ่น บุ่งคล้า บึงกาฬ 38000",
    },
    {
      value: "โคกกว้าง บุ่งคล้า บึงกาฬ 38000",
      label: "โคกกว้าง บุ่งคล้า บึงกาฬ 38000",
    },
    { value: "นากั้ง ปากคาด บึงกาฬ 38190", label: "นากั้ง ปากคาด บึงกาฬ 38190" },
    { value: "นาดง ปากคาด บึงกาฬ 38190", label: "นาดง ปากคาด บึงกาฬ 38190" },
    { value: "ปากคาด ปากคาด บึงกาฬ 38190", label: "ปากคาด ปากคาด บึงกาฬ 38190" },
    { value: "สมสนุก ปากคาด บึงกาฬ 38190", label: "สมสนุก ปากคาด บึงกาฬ 38190" },
    {
      value: "หนองยอง ปากคาด บึงกาฬ 38190",
      label: "หนองยอง ปากคาด บึงกาฬ 38190",
    },
    {
      value: "โนนศิลา ปากคาด บึงกาฬ 38190",
      label: "โนนศิลา ปากคาด บึงกาฬ 38190",
    },
    {
      value: "ดอนหญ้านาง พรเจริญ บึงกาฬ 38180",
      label: "ดอนหญ้านาง พรเจริญ บึงกาฬ 38180",
    },
    {
      value: "ป่าแฝก พรเจริญ บึงกาฬ 38180",
      label: "ป่าแฝก พรเจริญ บึงกาฬ 38180",
    },
    {
      value: "พรเจริญ พรเจริญ บึงกาฬ 38180",
      label: "พรเจริญ พรเจริญ บึงกาฬ 38180",
    },
    {
      value: "วังชมภู พรเจริญ บึงกาฬ 38180",
      label: "วังชมภู พรเจริญ บึงกาฬ 38180",
    },
    {
      value: "ศรีชมภู พรเจริญ บึงกาฬ 38180",
      label: "ศรีชมภู พรเจริญ บึงกาฬ 38180",
    },
    {
      value: "ศรีสำราญ พรเจริญ บึงกาฬ 38180",
      label: "ศรีสำราญ พรเจริญ บึงกาฬ 38180",
    },
    {
      value: "หนองหัวช้าง พรเจริญ บึงกาฬ 38180",
      label: "หนองหัวช้าง พรเจริญ บึงกาฬ 38180",
    },
    {
      value: "ชุมภูพร ศรีวิไล บึงกาฬ 38210",
      label: "ชุมภูพร ศรีวิไล บึงกาฬ 38210",
    },
    {
      value: "นาสะแบง ศรีวิไล บึงกาฬ 38210",
      label: "นาสะแบง ศรีวิไล บึงกาฬ 38210",
    },
    {
      value: "นาสิงห์ ศรีวิไล บึงกาฬ 38210",
      label: "นาสิงห์ ศรีวิไล บึงกาฬ 38210",
    },
    { value: "นาแสง ศรีวิไล บึงกาฬ 38210", label: "นาแสง ศรีวิไล บึงกาฬ 38210" },
    {
      value: "ศรีวิไล ศรีวิไล บึงกาฬ 38210",
      label: "ศรีวิไล ศรีวิไล บึงกาฬ 38210",
    },
    { value: "ซาง เซกา บึงกาฬ 38150", label: "ซาง เซกา บึงกาฬ 38150" },
    { value: "ท่ากกแดง เซกา บึงกาฬ 38150", label: "ท่ากกแดง เซกา บึงกาฬ 38150" },
    { value: "ท่าสะอาด เซกา บึงกาฬ 38150", label: "ท่าสะอาด เซกา บึงกาฬ 38150" },
    { value: "น้ำจั้น เซกา บึงกาฬ 38150", label: "น้ำจั้น เซกา บึงกาฬ 38150" },
    { value: "บ้านต้อง เซกา บึงกาฬ 38150", label: "บ้านต้อง เซกา บึงกาฬ 38150" },
    { value: "ป่งไฮ เซกา บึงกาฬ 38150", label: "ป่งไฮ เซกา บึงกาฬ 38150" },
    { value: "หนองทุ่ม เซกา บึงกาฬ 38150", label: "หนองทุ่ม เซกา บึงกาฬ 38150" },
    { value: "เซกา เซกา บึงกาฬ 38150", label: "เซกา เซกา บึงกาฬ 38150" },
    { value: "โสกก่าม เซกา บึงกาฬ 38150", label: "โสกก่าม เซกา บึงกาฬ 38150" },
    {
      value: "คำนาดี เมืองบึงกาฬ บึงกาฬ 38000",
      label: "คำนาดี เมืองบึงกาฬ บึงกาฬ 38000",
    },
    {
      value: "ชัยพร เมืองบึงกาฬ บึงกาฬ 38000",
      label: "ชัยพร เมืองบึงกาฬ บึงกาฬ 38000",
    },
    {
      value: "นาสวรรค์ เมืองบึงกาฬ บึงกาฬ 38000",
      label: "นาสวรรค์ เมืองบึงกาฬ บึงกาฬ 38000",
    },
    {
      value: "บึงกาฬ เมืองบึงกาฬ บึงกาฬ 38000",
      label: "บึงกาฬ เมืองบึงกาฬ บึงกาฬ 38000",
    },
    {
      value: "วิศิษฐ์ เมืองบึงกาฬ บึงกาฬ 38000",
      label: "วิศิษฐ์ เมืองบึงกาฬ บึงกาฬ 38000",
    },
    {
      value: "โนนสว่าง เมืองบึงกาฬ บึงกาฬ 38000",
      label: "โนนสว่าง เมืองบึงกาฬ บึงกาฬ 38000",
    },
    {
      value: "หนองเลิง เมืองบึงกาฬ บึงกาฬ 38000",
      label: "หนองเลิง เมืองบึงกาฬ บึงกาฬ 38000",
    },
    {
      value: "หอคำ เมืองบึงกาฬ บึงกาฬ 38000",
      label: "หอคำ เมืองบึงกาฬ บึงกาฬ 38000",
    },
    {
      value: "โคกก่อง เมืองบึงกาฬ บึงกาฬ 38000",
      label: "โคกก่อง เมืองบึงกาฬ บึงกาฬ 38000",
    },
    {
      value: "โนนสมบูรณ์ เมืองบึงกาฬ บึงกาฬ 38000",
      label: "โนนสมบูรณ์ เมืองบึงกาฬ บึงกาฬ 38000",
    },
    {
      value: "โป่งเปือย เมืองบึงกาฬ บึงกาฬ 38000",
      label: "โป่งเปือย เมืองบึงกาฬ บึงกาฬ 38000",
    },
    {
      value: "ไคสี เมืองบึงกาฬ บึงกาฬ 38000",
      label: "ไคสี เมืองบึงกาฬ บึงกาฬ 38000",
    },
    {
      value: "คำแก้ว โซ่พิสัย บึงกาฬ 38170",
      label: "คำแก้ว โซ่พิสัย บึงกาฬ 38170",
    },
    {
      value: "ถ้ำเจริญ โซ่พิสัย บึงกาฬ 38170",
      label: "ถ้ำเจริญ โซ่พิสัย บึงกาฬ 38170",
    },
    {
      value: "บัวตูม โซ่พิสัย บึงกาฬ 38170",
      label: "บัวตูม โซ่พิสัย บึงกาฬ 38170",
    },
    {
      value: "ศรีชมภู โซ่พิสัย บึงกาฬ 38170",
      label: "ศรีชมภู โซ่พิสัย บึงกาฬ 38170",
    },
    {
      value: "หนองพันทา โซ่พิสัย บึงกาฬ 38170",
      label: "หนองพันทา โซ่พิสัย บึงกาฬ 38170",
    },
    {
      value: "เหล่าทอง โซ่พิสัย บึงกาฬ 38170",
      label: "เหล่าทอง โซ่พิสัย บึงกาฬ 38170",
    },
    { value: "โซ่ โซ่พิสัย บึงกาฬ 38170", label: "โซ่ โซ่พิสัย บึงกาฬ 38170" },
    {
      value: "กระสัง กระสัง บุรีรัมย์ 31160",
      label: "กระสัง กระสัง บุรีรัมย์ 31160",
    },
    {
      value: "กันทรารมย์ กระสัง บุรีรัมย์ 31160",
      label: "กันทรารมย์ กระสัง บุรีรัมย์ 31160",
    },
    {
      value: "ชุมแสง กระสัง บุรีรัมย์ 31160",
      label: "ชุมแสง กระสัง บุรีรัมย์ 31160",
    },
    {
      value: "บ้านปรือ กระสัง บุรีรัมย์ 31160",
      label: "บ้านปรือ กระสัง บุรีรัมย์ 31160",
    },
    {
      value: "ลำดวน กระสัง บุรีรัมย์ 31160",
      label: "ลำดวน กระสัง บุรีรัมย์ 31160",
    },
    {
      value: "ศรีภูมิ กระสัง บุรีรัมย์ 31160",
      label: "ศรีภูมิ กระสัง บุรีรัมย์ 31160",
    },
    {
      value: "สองชั้น กระสัง บุรีรัมย์ 31160",
      label: "สองชั้น กระสัง บุรีรัมย์ 31160",
    },
    {
      value: "สูงเนิน กระสัง บุรีรัมย์ 31160",
      label: "สูงเนิน กระสัง บุรีรัมย์ 31160",
    },
    {
      value: "หนองเต็ง กระสัง บุรีรัมย์ 31160",
      label: "หนองเต็ง กระสัง บุรีรัมย์ 31160",
    },
    {
      value: "ห้วยสำราญ กระสัง บุรีรัมย์ 31160",
      label: "ห้วยสำราญ กระสัง บุรีรัมย์ 31160",
    },
    {
      value: "เมืองไผ่ กระสัง บุรีรัมย์ 31160",
      label: "เมืองไผ่ กระสัง บุรีรัมย์ 31160",
    },
    {
      value: "คูเมือง คูเมือง บุรีรัมย์ 31190",
      label: "คูเมือง คูเมือง บุรีรัมย์ 31190",
    },
    {
      value: "ตูมใหญ่ คูเมือง บุรีรัมย์ 31190",
      label: "ตูมใหญ่ คูเมือง บุรีรัมย์ 31190",
    },
    {
      value: "บ้านแพ คูเมือง บุรีรัมย์ 31190",
      label: "บ้านแพ คูเมือง บุรีรัมย์ 31190",
    },
    {
      value: "ปะเคียบ คูเมือง บุรีรัมย์ 31190",
      label: "ปะเคียบ คูเมือง บุรีรัมย์ 31190",
    },
    {
      value: "พรสำราญ คูเมือง บุรีรัมย์ 31190",
      label: "พรสำราญ คูเมือง บุรีรัมย์ 31190",
    },
    {
      value: "หนองขมาร คูเมือง บุรีรัมย์ 31190",
      label: "หนองขมาร คูเมือง บุรีรัมย์ 31190",
    },
    {
      value: "หินเหล็กไฟ คูเมือง บุรีรัมย์ 31190",
      label: "หินเหล็กไฟ คูเมือง บุรีรัมย์ 31190",
    },
    { value: "ชำนิ ชำนิ บุรีรัมย์ 31110", label: "ชำนิ ชำนิ บุรีรัมย์ 31110" },
    {
      value: "ช่อผกา ชำนิ บุรีรัมย์ 31110",
      label: "ช่อผกา ชำนิ บุรีรัมย์ 31110",
    },
    { value: "ละลวด ชำนิ บุรีรัมย์ 31110", label: "ละลวด ชำนิ บุรีรัมย์ 31110" },
    {
      value: "หนองปล่อง ชำนิ บุรีรัมย์ 31110",
      label: "หนองปล่อง ชำนิ บุรีรัมย์ 31110",
    },
    {
      value: "เมืองยาง ชำนิ บุรีรัมย์ 31110",
      label: "เมืองยาง ชำนิ บุรีรัมย์ 31110",
    },
    {
      value: "โคกสนวน ชำนิ บุรีรัมย์ 31110",
      label: "โคกสนวน ชำนิ บุรีรัมย์ 31110",
    },
    {
      value: "ก้านเหลือง นางรอง บุรีรัมย์ 31110",
      label: "ก้านเหลือง นางรอง บุรีรัมย์ 31110",
    },
    {
      value: "ชุมแสง นางรอง บุรีรัมย์ 31110",
      label: "ชุมแสง นางรอง บุรีรัมย์ 31110",
    },
    {
      value: "ถนนหัก นางรอง บุรีรัมย์ 31110",
      label: "ถนนหัก นางรอง บุรีรัมย์ 31110",
    },
    {
      value: "ทรัพย์พระยา นางรอง บุรีรัมย์ 31110",
      label: "ทรัพย์พระยา นางรอง บุรีรัมย์ 31110",
    },
    {
      value: "ทุ่งแสงทอง นางรอง บุรีรัมย์ 31110",
      label: "ทุ่งแสงทอง นางรอง บุรีรัมย์ 31110",
    },
    {
      value: "นางรอง นางรอง บุรีรัมย์ 31110",
      label: "นางรอง นางรอง บุรีรัมย์ 31110",
    },
    {
      value: "บ้านสิงห์ นางรอง บุรีรัมย์ 31110",
      label: "บ้านสิงห์ นางรอง บุรีรัมย์ 31110",
    },
    {
      value: "ลำไทรโยง นางรอง บุรีรัมย์ 31110",
      label: "ลำไทรโยง นางรอง บุรีรัมย์ 31110",
    },
    {
      value: "สะเดา นางรอง บุรีรัมย์ 31110",
      label: "สะเดา นางรอง บุรีรัมย์ 31110",
    },
    {
      value: "หนองกง นางรอง บุรีรัมย์ 31110",
      label: "หนองกง นางรอง บุรีรัมย์ 31110",
    },
    {
      value: "หนองยายพิมพ์ นางรอง บุรีรัมย์ 31110",
      label: "หนองยายพิมพ์ นางรอง บุรีรัมย์ 31110",
    },
    {
      value: "หนองโบสถ์ นางรอง บุรีรัมย์ 31110",
      label: "หนองโบสถ์ นางรอง บุรีรัมย์ 31110",
    },
    {
      value: "หนองโสน นางรอง บุรีรัมย์ 31110",
      label: "หนองโสน นางรอง บุรีรัมย์ 31110",
    },
    {
      value: "หนองไทร นางรอง บุรีรัมย์ 31110",
      label: "หนองไทร นางรอง บุรีรัมย์ 31110",
    },
    {
      value: "หัวถนน นางรอง บุรีรัมย์ 31110",
      label: "หัวถนน นางรอง บุรีรัมย์ 31110",
    },
    {
      value: "ดอนกอก นาโพธิ์ บุรีรัมย์ 31230",
      label: "ดอนกอก นาโพธิ์ บุรีรัมย์ 31230",
    },
    {
      value: "นาโพธิ์ นาโพธิ์ บุรีรัมย์ 31230",
      label: "นาโพธิ์ นาโพธิ์ บุรีรัมย์ 31230",
    },
    {
      value: "บ้านคู นาโพธิ์ บุรีรัมย์ 31230",
      label: "บ้านคู นาโพธิ์ บุรีรัมย์ 31230",
    },
    {
      value: "บ้านดู่ นาโพธิ์ บุรีรัมย์ 31230",
      label: "บ้านดู่ นาโพธิ์ บุรีรัมย์ 31230",
    },
    {
      value: "ศรีสว่าง นาโพธิ์ บุรีรัมย์ 31230",
      label: "ศรีสว่าง นาโพธิ์ บุรีรัมย์ 31230",
    },
    {
      value: "จันทบเพชร บ้านกรวด บุรีรัมย์ 31180",
      label: "จันทบเพชร บ้านกรวด บุรีรัมย์ 31180",
    },
    {
      value: "บึงเจริญ บ้านกรวด บุรีรัมย์ 31180",
      label: "บึงเจริญ บ้านกรวด บุรีรัมย์ 31180",
    },
    {
      value: "บ้านกรวด บ้านกรวด บุรีรัมย์ 31180",
      label: "บ้านกรวด บ้านกรวด บุรีรัมย์ 31180",
    },
    {
      value: "ปราสาท บ้านกรวด บุรีรัมย์ 31180",
      label: "ปราสาท บ้านกรวด บุรีรัมย์ 31180",
    },
    {
      value: "สายตะกู บ้านกรวด บุรีรัมย์ 31180",
      label: "สายตะกู บ้านกรวด บุรีรัมย์ 31180",
    },
    {
      value: "หนองไม้งาม บ้านกรวด บุรีรัมย์ 31180",
      label: "หนองไม้งาม บ้านกรวด บุรีรัมย์ 31180",
    },
    {
      value: "หินลาด บ้านกรวด บุรีรัมย์ 31180",
      label: "หินลาด บ้านกรวด บุรีรัมย์ 31180",
    },
    {
      value: "เขาดินเหนือ บ้านกรวด บุรีรัมย์ 31180",
      label: "เขาดินเหนือ บ้านกรวด บุรีรัมย์ 31180",
    },
    {
      value: "โนนเจริญ บ้านกรวด บุรีรัมย์ 31180",
      label: "โนนเจริญ บ้านกรวด บุรีรัมย์ 31180",
    },
    {
      value: "บ้านด่าน บ้านด่าน บุรีรัมย์ 31000",
      label: "บ้านด่าน บ้านด่าน บุรีรัมย์ 31000",
    },
    {
      value: "ปราสาท บ้านด่าน บุรีรัมย์ 31000",
      label: "ปราสาท บ้านด่าน บุรีรัมย์ 31000",
    },
    {
      value: "วังเหนือ บ้านด่าน บุรีรัมย์ 31000",
      label: "วังเหนือ บ้านด่าน บุรีรัมย์ 31000",
    },
    {
      value: "โนนขวาง บ้านด่าน บุรีรัมย์ 31000",
      label: "โนนขวาง บ้านด่าน บุรีรัมย์ 31000",
    },
    {
      value: "กู่สวนแตง บ้านใหม่ไชยพจน์ บุรีรัมย์ 31120",
      label: "กู่สวนแตง บ้านใหม่ไชยพจน์ บุรีรัมย์ 31120",
    },
    {
      value: "ทองหลาง บ้านใหม่ไชยพจน์ บุรีรัมย์ 31120",
      label: "ทองหลาง บ้านใหม่ไชยพจน์ บุรีรัมย์ 31120",
    },
    {
      value: "หนองเยือง บ้านใหม่ไชยพจน์ บุรีรัมย์ 31120",
      label: "หนองเยือง บ้านใหม่ไชยพจน์ บุรีรัมย์ 31120",
    },
    {
      value: "หนองแวง บ้านใหม่ไชยพจน์ บุรีรัมย์ 31120",
      label: "หนองแวง บ้านใหม่ไชยพจน์ บุรีรัมย์ 31120",
    },
    {
      value: "แดงใหญ่ บ้านใหม่ไชยพจน์ บุรีรัมย์ 31120",
      label: "แดงใหญ่ บ้านใหม่ไชยพจน์ บุรีรัมย์ 31120",
    },
    {
      value: "จรเข้มาก ประโคนชัย บุรีรัมย์ 31140",
      label: "จรเข้มาก ประโคนชัย บุรีรัมย์ 31140",
    },
    {
      value: "ตะโกตาพิ ประโคนชัย บุรีรัมย์ 31140",
      label: "ตะโกตาพิ ประโคนชัย บุรีรัมย์ 31140",
    },
    {
      value: "บ้านไทร ประโคนชัย บุรีรัมย์ 31140",
      label: "บ้านไทร ประโคนชัย บุรีรัมย์ 31140",
    },
    {
      value: "ประทัดบุ ประโคนชัย บุรีรัมย์ 31140",
      label: "ประทัดบุ ประโคนชัย บุรีรัมย์ 31140",
    },
    {
      value: "ประโคนชัย ประโคนชัย บุรีรัมย์ 31140",
      label: "ประโคนชัย ประโคนชัย บุรีรัมย์ 31140",
    },
    {
      value: "ปังกู ประโคนชัย บุรีรัมย์ 31140",
      label: "ปังกู ประโคนชัย บุรีรัมย์ 31140",
    },
    {
      value: "ละเวี้ย ประโคนชัย บุรีรัมย์ 31140",
      label: "ละเวี้ย ประโคนชัย บุรีรัมย์ 31140",
    },
    {
      value: "สี่เหลี่ยม ประโคนชัย บุรีรัมย์ 31140",
      label: "สี่เหลี่ยม ประโคนชัย บุรีรัมย์ 31140",
    },
    {
      value: "หนองบอน ประโคนชัย บุรีรัมย์ 31140",
      label: "หนองบอน ประโคนชัย บุรีรัมย์ 31140",
    },
    {
      value: "เขาคอก ประโคนชัย บุรีรัมย์ 31140",
      label: "เขาคอก ประโคนชัย บุรีรัมย์ 31140",
    },
    {
      value: "แสลงโทน ประโคนชัย บุรีรัมย์ 31140",
      label: "แสลงโทน ประโคนชัย บุรีรัมย์ 31140",
    },
    {
      value: "โคกตูม ประโคนชัย บุรีรัมย์ 31140",
      label: "โคกตูม ประโคนชัย บุรีรัมย์ 31140",
    },
    {
      value: "โคกมะขาม ประโคนชัย บุรีรัมย์ 31140",
      label: "โคกมะขาม ประโคนชัย บุรีรัมย์ 31140",
    },
    {
      value: "โคกม้า ประโคนชัย บุรีรัมย์ 31140",
      label: "โคกม้า ประโคนชัย บุรีรัมย์ 31140",
    },
    {
      value: "โคกย่าง ประโคนชัย บุรีรัมย์ 31140",
      label: "โคกย่าง ประโคนชัย บุรีรัมย์ 31140",
    },
    {
      value: "ไพศาล ประโคนชัย บุรีรัมย์ 31140",
      label: "ไพศาล ประโคนชัย บุรีรัมย์ 31140",
    },
    { value: "ปะคำ ปะคำ บุรีรัมย์ 31220", label: "ปะคำ ปะคำ บุรีรัมย์ 31220" },
    {
      value: "หนองบัว ปะคำ บุรีรัมย์ 31220",
      label: "หนองบัว ปะคำ บุรีรัมย์ 31220",
    },
    {
      value: "หูทำนบ ปะคำ บุรีรัมย์ 31220",
      label: "หูทำนบ ปะคำ บุรีรัมย์ 31220",
    },
    {
      value: "โคกมะม่วง ปะคำ บุรีรัมย์ 31220",
      label: "โคกมะม่วง ปะคำ บุรีรัมย์ 31220",
    },
    {
      value: "ไทยเจริญ ปะคำ บุรีรัมย์ 31220",
      label: "ไทยเจริญ ปะคำ บุรีรัมย์ 31220",
    },
    {
      value: "จันดุม พลับพลาชัย บุรีรัมย์ 31250",
      label: "จันดุม พลับพลาชัย บุรีรัมย์ 31250",
    },
    {
      value: "ป่าชัน พลับพลาชัย บุรีรัมย์ 31250",
      label: "ป่าชัน พลับพลาชัย บุรีรัมย์ 31250",
    },
    {
      value: "สะเดา พลับพลาชัย บุรีรัมย์ 31250",
      label: "สะเดา พลับพลาชัย บุรีรัมย์ 31250",
    },
    {
      value: "สำโรง พลับพลาชัย บุรีรัมย์ 31250",
      label: "สำโรง พลับพลาชัย บุรีรัมย์ 31250",
    },
    {
      value: "โคกขมิ้น พลับพลาชัย บุรีรัมย์ 31250",
      label: "โคกขมิ้น พลับพลาชัย บุรีรัมย์ 31250",
    },
    {
      value: "บ้านจาน พุทไธสง บุรีรัมย์ 31120",
      label: "บ้านจาน พุทไธสง บุรีรัมย์ 31120",
    },
    {
      value: "บ้านยาง พุทไธสง บุรีรัมย์ 31120",
      label: "บ้านยาง พุทไธสง บุรีรัมย์ 31120",
    },
    {
      value: "บ้านเป้า พุทไธสง บุรีรัมย์ 31120",
      label: "บ้านเป้า พุทไธสง บุรีรัมย์ 31120",
    },
    {
      value: "บ้านแวง พุทไธสง บุรีรัมย์ 31120",
      label: "บ้านแวง พุทไธสง บุรีรัมย์ 31120",
    },
    {
      value: "พุทไธสง พุทไธสง บุรีรัมย์ 31120",
      label: "พุทไธสง พุทไธสง บุรีรัมย์ 31120",
    },
    {
      value: "มะเฟือง พุทไธสง บุรีรัมย์ 31120",
      label: "มะเฟือง พุทไธสง บุรีรัมย์ 31120",
    },
    {
      value: "หายโศก พุทไธสง บุรีรัมย์ 31120",
      label: "หายโศก พุทไธสง บุรีรัมย์ 31120",
    },
    {
      value: "ตาจง ละหานทราย บุรีรัมย์ 31170",
      label: "ตาจง ละหานทราย บุรีรัมย์ 31170",
    },
    {
      value: "ละหานทราย ละหานทราย บุรีรัมย์ 31170",
      label: "ละหานทราย ละหานทราย บุรีรัมย์ 31170",
    },
    {
      value: "สำโรงใหม่ ละหานทราย บุรีรัมย์ 31170",
      label: "สำโรงใหม่ ละหานทราย บุรีรัมย์ 31170",
    },
    {
      value: "หนองตะครอง ละหานทราย บุรีรัมย์ 31170",
      label: "หนองตะครอง ละหานทราย บุรีรัมย์ 31170",
    },
    {
      value: "หนองแวง ละหานทราย บุรีรัมย์ 31170",
      label: "หนองแวง ละหานทราย บุรีรัมย์ 31170",
    },
    {
      value: "โคกว่าน ละหานทราย บุรีรัมย์ 31170",
      label: "โคกว่าน ละหานทราย บุรีรัมย์ 31170",
    },
    {
      value: "ตลาดโพธิ์ ลำปลายมาศ บุรีรัมย์ 31130",
      label: "ตลาดโพธิ์ ลำปลายมาศ บุรีรัมย์ 31130",
    },
    {
      value: "ทะเมนชัย ลำปลายมาศ บุรีรัมย์ 31130",
      label: "ทะเมนชัย ลำปลายมาศ บุรีรัมย์ 31130",
    },
    {
      value: "บุโพธิ์ ลำปลายมาศ บุรีรัมย์ 31130",
      label: "บุโพธิ์ ลำปลายมาศ บุรีรัมย์ 31130",
    },
    {
      value: "บ้านยาง ลำปลายมาศ บุรีรัมย์ 31130",
      label: "บ้านยาง ลำปลายมาศ บุรีรัมย์ 31130",
    },
    {
      value: "ผไทรินทร์ ลำปลายมาศ บุรีรัมย์ 31130",
      label: "ผไทรินทร์ ลำปลายมาศ บุรีรัมย์ 31130",
    },
    {
      value: "ลำปลายมาศ ลำปลายมาศ บุรีรัมย์ 31130",
      label: "ลำปลายมาศ ลำปลายมาศ บุรีรัมย์ 31130",
    },
    {
      value: "หนองกะทิง ลำปลายมาศ บุรีรัมย์ 31130",
      label: "หนองกะทิง ลำปลายมาศ บุรีรัมย์ 31130",
    },
    {
      value: "หนองคู ลำปลายมาศ บุรีรัมย์ 31130",
      label: "หนองคู ลำปลายมาศ บุรีรัมย์ 31130",
    },
    {
      value: "หนองบัวโคก ลำปลายมาศ บุรีรัมย์ 31130",
      label: "หนองบัวโคก ลำปลายมาศ บุรีรัมย์ 31130",
    },
    {
      value: "หนองโดน ลำปลายมาศ บุรีรัมย์ 31130",
      label: "หนองโดน ลำปลายมาศ บุรีรัมย์ 31130",
    },
    {
      value: "หินโคน ลำปลายมาศ บุรีรัมย์ 31130",
      label: "หินโคน ลำปลายมาศ บุรีรัมย์ 31130",
    },
    {
      value: "เมืองแฝก ลำปลายมาศ บุรีรัมย์ 31130",
      label: "เมืองแฝก ลำปลายมาศ บุรีรัมย์ 31130",
    },
    {
      value: "แสลงพัน ลำปลายมาศ บุรีรัมย์ 31130",
      label: "แสลงพัน ลำปลายมาศ บุรีรัมย์ 31130",
    },
    {
      value: "โคกกลาง ลำปลายมาศ บุรีรัมย์ 31130",
      label: "โคกกลาง ลำปลายมาศ บุรีรัมย์ 31130",
    },
    {
      value: "โคกล่าม ลำปลายมาศ บุรีรัมย์ 31130",
      label: "โคกล่าม ลำปลายมาศ บุรีรัมย์ 31130",
    },
    {
      value: "โคกสะอาด ลำปลายมาศ บุรีรัมย์ 31130",
      label: "โคกสะอาด ลำปลายมาศ บุรีรัมย์ 31130",
    },
    {
      value: "กระสัง สตึก บุรีรัมย์ 31150",
      label: "กระสัง สตึก บุรีรัมย์ 31150",
    },
    {
      value: "ชุมแสง สตึก บุรีรัมย์ 31150",
      label: "ชุมแสง สตึก บุรีรัมย์ 31150",
    },
    {
      value: "ดอนมนต์ สตึก บุรีรัมย์ 31150",
      label: "ดอนมนต์ สตึก บุรีรัมย์ 31150",
    },
    {
      value: "ทุ่งวัง สตึก บุรีรัมย์ 31150",
      label: "ทุ่งวัง สตึก บุรีรัมย์ 31150",
    },
    {
      value: "ท่าม่วง สตึก บุรีรัมย์ 31150",
      label: "ท่าม่วง สตึก บุรีรัมย์ 31150",
    },
    { value: "นิคม สตึก บุรีรัมย์ 31150", label: "นิคม สตึก บุรีรัมย์ 31150" },
    {
      value: "ร่อนทอง สตึก บุรีรัมย์ 31150",
      label: "ร่อนทอง สตึก บุรีรัมย์ 31150",
    },
    { value: "สตึก สตึก บุรีรัมย์ 31150", label: "สตึก สตึก บุรีรัมย์ 31150" },
    {
      value: "สนามชัย สตึก บุรีรัมย์ 31150",
      label: "สนามชัย สตึก บุรีรัมย์ 31150",
    },
    { value: "สะแก สตึก บุรีรัมย์ 31150", label: "สะแก สตึก บุรีรัมย์ 31150" },
    {
      value: "หนองใหญ่ สตึก บุรีรัมย์ 31150",
      label: "หนองใหญ่ สตึก บุรีรัมย์ 31150",
    },
    {
      value: "เมืองแก สตึก บุรีรัมย์ 31150",
      label: "เมืองแก สตึก บุรีรัมย์ 31150",
    },
    {
      value: "ดอนอะราง หนองกี่ บุรีรัมย์ 31210",
      label: "ดอนอะราง หนองกี่ บุรีรัมย์ 31210",
    },
    {
      value: "ทุ่งกระตาดพัฒนา หนองกี่ บุรีรัมย์ 31210",
      label: "ทุ่งกระตาดพัฒนา หนองกี่ บุรีรัมย์ 31210",
    },
    {
      value: "ทุ่งกระเต็น หนองกี่ บุรีรัมย์ 31210",
      label: "ทุ่งกระเต็น หนองกี่ บุรีรัมย์ 31210",
    },
    {
      value: "ท่าโพธิ์ชัย หนองกี่ บุรีรัมย์ 31210",
      label: "ท่าโพธิ์ชัย หนองกี่ บุรีรัมย์ 31210",
    },
    {
      value: "บุกระสัง หนองกี่ บุรีรัมย์ 31210",
      label: "บุกระสัง หนองกี่ บุรีรัมย์ 31210",
    },
    {
      value: "หนองกี่ หนองกี่ บุรีรัมย์ 31210",
      label: "หนองกี่ หนองกี่ บุรีรัมย์ 31210",
    },
    {
      value: "เมืองไผ่ หนองกี่ บุรีรัมย์ 31210",
      label: "เมืองไผ่ หนองกี่ บุรีรัมย์ 31210",
    },
    {
      value: "เย้ยปราสาท หนองกี่ บุรีรัมย์ 31210",
      label: "เย้ยปราสาท หนองกี่ บุรีรัมย์ 31210",
    },
    {
      value: "โคกสว่าง หนองกี่ บุรีรัมย์ 31210",
      label: "โคกสว่าง หนองกี่ บุรีรัมย์ 31210",
    },
    {
      value: "โคกสูง หนองกี่ บุรีรัมย์ 31210",
      label: "โคกสูง หนองกี่ บุรีรัมย์ 31210",
    },
    {
      value: "สระทอง หนองหงส์ บุรีรัมย์ 31240",
      label: "สระทอง หนองหงส์ บุรีรัมย์ 31240",
    },
    {
      value: "สระแก้ว หนองหงส์ บุรีรัมย์ 31240",
      label: "สระแก้ว หนองหงส์ บุรีรัมย์ 31240",
    },
    {
      value: "หนองชัยศรี หนองหงส์ บุรีรัมย์ 31240",
      label: "หนองชัยศรี หนองหงส์ บุรีรัมย์ 31240",
    },
    {
      value: "ห้วยหิน หนองหงส์ บุรีรัมย์ 31240",
      label: "ห้วยหิน หนองหงส์ บุรีรัมย์ 31240",
    },
    {
      value: "เมืองฝ้าย หนองหงส์ บุรีรัมย์ 31240",
      label: "เมืองฝ้าย หนองหงส์ บุรีรัมย์ 31240",
    },
    {
      value: "เสาเดียว หนองหงส์ บุรีรัมย์ 31240",
      label: "เสาเดียว หนองหงส์ บุรีรัมย์ 31240",
    },
    {
      value: "ไทยสามัคคี หนองหงส์ บุรีรัมย์ 31240",
      label: "ไทยสามัคคี หนองหงส์ บุรีรัมย์ 31240",
    },
    {
      value: "ตาเสา ห้วยราช บุรีรัมย์ 31000",
      label: "ตาเสา ห้วยราช บุรีรัมย์ 31000",
    },
    {
      value: "บ้านตะโก ห้วยราช บุรีรัมย์ 31000",
      label: "บ้านตะโก ห้วยราช บุรีรัมย์ 31000",
    },
    {
      value: "สนวน ห้วยราช บุรีรัมย์ 31000",
      label: "สนวน ห้วยราช บุรีรัมย์ 31000",
    },
    {
      value: "สามแวง ห้วยราช บุรีรัมย์ 31000",
      label: "สามแวง ห้วยราช บุรีรัมย์ 31000",
    },
    {
      value: "ห้วยราช ห้วยราช บุรีรัมย์ 31000",
      label: "ห้วยราช ห้วยราช บุรีรัมย์ 31000",
    },
    {
      value: "ห้วยราชา ห้วยราช บุรีรัมย์ 31000",
      label: "ห้วยราชา ห้วยราช บุรีรัมย์ 31000",
    },
    {
      value: "เมืองโพธิ์ ห้วยราช บุรีรัมย์ 31000",
      label: "เมืองโพธิ์ ห้วยราช บุรีรัมย์ 31000",
    },
    {
      value: "โคกเหล็ก ห้วยราช บุรีรัมย์ 31000",
      label: "โคกเหล็ก ห้วยราช บุรีรัมย์ 31000",
    },
    {
      value: "ตาเป๊ก เฉลิมพระเกียรติ บุรีรัมย์ 31110",
      label: "ตาเป๊ก เฉลิมพระเกียรติ บุรีรัมย์ 31110",
    },
    {
      value: "ถาวร เฉลิมพระเกียรติ บุรีรัมย์ 31170",
      label: "ถาวร เฉลิมพระเกียรติ บุรีรัมย์ 31170",
    },
    {
      value: "ยายแย้มวัฒนา เฉลิมพระเกียรติ บุรีรัมย์ 31170",
      label: "ยายแย้มวัฒนา เฉลิมพระเกียรติ บุรีรัมย์ 31170",
    },
    {
      value: "อีสานเขต เฉลิมพระเกียรติ บุรีรัมย์ 31110",
      label: "อีสานเขต เฉลิมพระเกียรติ บุรีรัมย์ 31110",
    },
    {
      value: "เจริญสุข เฉลิมพระเกียรติ บุรีรัมย์ 31110",
      label: "เจริญสุข เฉลิมพระเกียรติ บุรีรัมย์ 31110",
    },
    {
      value: "กระสัง เมืองบุรีรัมย์ บุรีรัมย์ 31000",
      label: "กระสัง เมืองบุรีรัมย์ บุรีรัมย์ 31000",
    },
    {
      value: "กลันทา เมืองบุรีรัมย์ บุรีรัมย์ 31000",
      label: "กลันทา เมืองบุรีรัมย์ บุรีรัมย์ 31000",
    },
    {
      value: "ชุมเห็ด เมืองบุรีรัมย์ บุรีรัมย์ 31000",
      label: "ชุมเห็ด เมืองบุรีรัมย์ บุรีรัมย์ 31000",
    },
    {
      value: "ถลุงเหล็ก เมืองบุรีรัมย์ บุรีรัมย์ 31000",
      label: "ถลุงเหล็ก เมืองบุรีรัมย์ บุรีรัมย์ 31000",
    },
    {
      value: "บัวทอง เมืองบุรีรัมย์ บุรีรัมย์ 31000",
      label: "บัวทอง เมืองบุรีรัมย์ บุรีรัมย์ 31000",
    },
    {
      value: "บ้านบัว เมืองบุรีรัมย์ บุรีรัมย์ 31000",
      label: "บ้านบัว เมืองบุรีรัมย์ บุรีรัมย์ 31000",
    },
    {
      value: "บ้านยาง เมืองบุรีรัมย์ บุรีรัมย์ 31000",
      label: "บ้านยาง เมืองบุรีรัมย์ บุรีรัมย์ 31000",
    },
    {
      value: "พระครู เมืองบุรีรัมย์ บุรีรัมย์ 31000",
      label: "พระครู เมืองบุรีรัมย์ บุรีรัมย์ 31000",
    },
    {
      value: "ลุมปุ๊ก เมืองบุรีรัมย์ บุรีรัมย์ 31000",
      label: "ลุมปุ๊ก เมืองบุรีรัมย์ บุรีรัมย์ 31000",
    },
    {
      value: "สวายจีก เมืองบุรีรัมย์ บุรีรัมย์ 31000",
      label: "สวายจีก เมืองบุรีรัมย์ บุรีรัมย์ 31000",
    },
    {
      value: "สองห้อง เมืองบุรีรัมย์ บุรีรัมย์ 31000",
      label: "สองห้อง เมืองบุรีรัมย์ บุรีรัมย์ 31000",
    },
    {
      value: "สะแกซำ เมืองบุรีรัมย์ บุรีรัมย์ 31000",
      label: "สะแกซำ เมืองบุรีรัมย์ บุรีรัมย์ 31000",
    },
    {
      value: "สะแกโพรง เมืองบุรีรัมย์ บุรีรัมย์ 31000",
      label: "สะแกโพรง เมืองบุรีรัมย์ บุรีรัมย์ 31000",
    },
    {
      value: "หนองตาด เมืองบุรีรัมย์ บุรีรัมย์ 31000",
      label: "หนองตาด เมืองบุรีรัมย์ บุรีรัมย์ 31000",
    },
    {
      value: "หลักเขต เมืองบุรีรัมย์ บุรีรัมย์ 31000",
      label: "หลักเขต เมืองบุรีรัมย์ บุรีรัมย์ 31000",
    },
    {
      value: "อิสาณ เมืองบุรีรัมย์ บุรีรัมย์ 31000",
      label: "อิสาณ เมืองบุรีรัมย์ บุรีรัมย์ 31000",
    },
    {
      value: "เมืองฝาง เมืองบุรีรัมย์ บุรีรัมย์ 31000",
      label: "เมืองฝาง เมืองบุรีรัมย์ บุรีรัมย์ 31000",
    },
    {
      value: "เสม็ด เมืองบุรีรัมย์ บุรีรัมย์ 31000",
      label: "เสม็ด เมืองบุรีรัมย์ บุรีรัมย์ 31000",
    },
    {
      value: "ในเมือง เมืองบุรีรัมย์ บุรีรัมย์ 31000",
      label: "ในเมือง เมืองบุรีรัมย์ บุรีรัมย์ 31000",
    },
    {
      value: "ดงพลอง แคนดง บุรีรัมย์ 31150",
      label: "ดงพลอง แคนดง บุรีรัมย์ 31150",
    },
    {
      value: "สระบัว แคนดง บุรีรัมย์ 31150",
      label: "สระบัว แคนดง บุรีรัมย์ 31150",
    },
    {
      value: "หัวฝาย แคนดง บุรีรัมย์ 31150",
      label: "หัวฝาย แคนดง บุรีรัมย์ 31150",
    },
    {
      value: "แคนดง แคนดง บุรีรัมย์ 31150",
      label: "แคนดง แคนดง บุรีรัมย์ 31150",
    },
    {
      value: "ลำนางรอง โนนดินแดง บุรีรัมย์ 31260",
      label: "ลำนางรอง โนนดินแดง บุรีรัมย์ 31260",
    },
    {
      value: "ส้มป่อย โนนดินแดง บุรีรัมย์ 31260",
      label: "ส้มป่อย โนนดินแดง บุรีรัมย์ 31260",
    },
    {
      value: "โนนดินแดง โนนดินแดง บุรีรัมย์ 31260",
      label: "โนนดินแดง โนนดินแดง บุรีรัมย์ 31260",
    },
    {
      value: "ดงอีจาน โนนสุวรรณ บุรีรัมย์ 31110",
      label: "ดงอีจาน โนนสุวรรณ บุรีรัมย์ 31110",
    },
    {
      value: "ทุ่งจังหัน โนนสุวรรณ บุรีรัมย์ 31110",
      label: "ทุ่งจังหัน โนนสุวรรณ บุรีรัมย์ 31110",
    },
    {
      value: "โกรกแก้ว โนนสุวรรณ บุรีรัมย์ 31110",
      label: "โกรกแก้ว โนนสุวรรณ บุรีรัมย์ 31110",
    },
    {
      value: "โนนสุวรรณ โนนสุวรรณ บุรีรัมย์ 31110",
      label: "โนนสุวรรณ โนนสุวรรณ บุรีรัมย์ 31110",
    },
    {
      value: "กุดใส้จ่อ กันทรวิชัย มหาสารคาม 44150",
      label: "กุดใส้จ่อ กันทรวิชัย มหาสารคาม 44150",
    },
    {
      value: "ขามเฒ่าพัฒนา กันทรวิชัย มหาสารคาม 44150",
      label: "ขามเฒ่าพัฒนา กันทรวิชัย มหาสารคาม 44150",
    },
    {
      value: "ขามเรียง กันทรวิชัย มหาสารคาม 44150",
      label: "ขามเรียง กันทรวิชัย มหาสารคาม 44150",
    },
    {
      value: "คันธารราษฎร์ กันทรวิชัย มหาสารคาม 44150",
      label: "คันธารราษฎร์ กันทรวิชัย มหาสารคาม 44150",
    },
    {
      value: "ท่าขอนยาง กันทรวิชัย มหาสารคาม 44150",
      label: "ท่าขอนยาง กันทรวิชัย มหาสารคาม 44150",
    },
    {
      value: "นาสีนวน กันทรวิชัย มหาสารคาม 44150",
      label: "นาสีนวน กันทรวิชัย มหาสารคาม 44150",
    },
    {
      value: "มะค่า กันทรวิชัย มหาสารคาม 44150",
      label: "มะค่า กันทรวิชัย มหาสารคาม 44150",
    },
    {
      value: "ศรีสุข กันทรวิชัย มหาสารคาม 44150",
      label: "ศรีสุข กันทรวิชัย มหาสารคาม 44150",
    },
    {
      value: "เขวาใหญ่ กันทรวิชัย มหาสารคาม 44150",
      label: "เขวาใหญ่ กันทรวิชัย มหาสารคาม 44150",
    },
    {
      value: "โคกพระ กันทรวิชัย มหาสารคาม 44150",
      label: "โคกพระ กันทรวิชัย มหาสารคาม 44150",
    },
    {
      value: "กุดรัง กุดรัง มหาสารคาม 44130",
      label: "กุดรัง กุดรัง มหาสารคาม 44130",
    },
    {
      value: "นาโพธิ์ กุดรัง มหาสารคาม 44130",
      label: "นาโพธิ์ กุดรัง มหาสารคาม 44130",
    },
    {
      value: "หนองแวง กุดรัง มหาสารคาม 44130",
      label: "หนองแวง กุดรัง มหาสารคาม 44130",
    },
    {
      value: "ห้วยเตย กุดรัง มหาสารคาม 44130",
      label: "ห้วยเตย กุดรัง มหาสารคาม 44130",
    },
    {
      value: "เลิงแฝก กุดรัง มหาสารคาม 44130",
      label: "เลิงแฝก กุดรัง มหาสารคาม 44130",
    },
    {
      value: "กุดปลาดุก ชื่นชม มหาสารคาม 44160",
      label: "กุดปลาดุก ชื่นชม มหาสารคาม 44160",
    },
    {
      value: "ชื่นชม ชื่นชม มหาสารคาม 44160",
      label: "ชื่นชม ชื่นชม มหาสารคาม 44160",
    },
    {
      value: "หนองกุง ชื่นชม มหาสารคาม 44160",
      label: "หนองกุง ชื่นชม มหาสารคาม 44160",
    },
    {
      value: "เหล่าดอกไม้ ชื่นชม มหาสารคาม 44160",
      label: "เหล่าดอกไม้ ชื่นชม มหาสารคาม 44160",
    },
    {
      value: "กู่สันตรัตน์ นาดูน มหาสารคาม 44180",
      label: "กู่สันตรัตน์ นาดูน มหาสารคาม 44180",
    },
    {
      value: "ดงดวน นาดูน มหาสารคาม 44180",
      label: "ดงดวน นาดูน มหาสารคาม 44180",
    },
    {
      value: "ดงบัง นาดูน มหาสารคาม 44180",
      label: "ดงบัง นาดูน มหาสารคาม 44180",
    },
    {
      value: "ดงยาง นาดูน มหาสารคาม 44180",
      label: "ดงยาง นาดูน มหาสารคาม 44180",
    },
    {
      value: "นาดูน นาดูน มหาสารคาม 44180",
      label: "นาดูน นาดูน มหาสารคาม 44180",
    },
    {
      value: "พระธาตุ นาดูน มหาสารคาม 44180",
      label: "พระธาตุ นาดูน มหาสารคาม 44180",
    },
    {
      value: "หนองคู นาดูน มหาสารคาม 44180",
      label: "หนองคู นาดูน มหาสารคาม 44180",
    },
    {
      value: "หนองไผ่ นาดูน มหาสารคาม 44180",
      label: "หนองไผ่ นาดูน มหาสารคาม 44180",
    },
    {
      value: "หัวดง นาดูน มหาสารคาม 44180",
      label: "หัวดง นาดูน มหาสารคาม 44180",
    },
    {
      value: "นาเชือก นาเชือก มหาสารคาม 44170",
      label: "นาเชือก นาเชือก มหาสารคาม 44170",
    },
    {
      value: "ปอพาน นาเชือก มหาสารคาม 44170",
      label: "ปอพาน นาเชือก มหาสารคาม 44170",
    },
    {
      value: "สันป่าตอง นาเชือก มหาสารคาม 44170",
      label: "สันป่าตอง นาเชือก มหาสารคาม 44170",
    },
    {
      value: "สำโรง นาเชือก มหาสารคาม 44170",
      label: "สำโรง นาเชือก มหาสารคาม 44170",
    },
    {
      value: "หนองกุง นาเชือก มหาสารคาม 44170",
      label: "หนองกุง นาเชือก มหาสารคาม 44170",
    },
    {
      value: "หนองเม็ก นาเชือก มหาสารคาม 44170",
      label: "หนองเม็ก นาเชือก มหาสารคาม 44170",
    },
    {
      value: "หนองเรือ นาเชือก มหาสารคาม 44170",
      label: "หนองเรือ นาเชือก มหาสารคาม 44170",
    },
    {
      value: "หนองแดง นาเชือก มหาสารคาม 44170",
      label: "หนองแดง นาเชือก มหาสารคาม 44170",
    },
    {
      value: "หนองโพธิ์ นาเชือก มหาสารคาม 44170",
      label: "หนองโพธิ์ นาเชือก มหาสารคาม 44170",
    },
    {
      value: "เขวาไร่ นาเชือก มหาสารคาม 44170",
      label: "เขวาไร่ นาเชือก มหาสารคาม 44170",
    },
    {
      value: "กำพี้ บรบือ มหาสารคาม 44130",
      label: "กำพี้ บรบือ มหาสารคาม 44130",
    },
    {
      value: "ดอนงัว บรบือ มหาสารคาม 44130",
      label: "ดอนงัว บรบือ มหาสารคาม 44130",
    },
    {
      value: "บรบือ บรบือ มหาสารคาม 44130",
      label: "บรบือ บรบือ มหาสารคาม 44130",
    },
    {
      value: "บัวมาศ บรบือ มหาสารคาม 44130",
      label: "บัวมาศ บรบือ มหาสารคาม 44130",
    },
    {
      value: "บ่อใหญ่ บรบือ มหาสารคาม 44130",
      label: "บ่อใหญ่ บรบือ มหาสารคาม 44130",
    },
    { value: "ยาง บรบือ มหาสารคาม 44130", label: "ยาง บรบือ มหาสารคาม 44130" },
    {
      value: "วังใหม่ บรบือ มหาสารคาม 44130",
      label: "วังใหม่ บรบือ มหาสารคาม 44130",
    },
    {
      value: "วังไชย บรบือ มหาสารคาม 44130",
      label: "วังไชย บรบือ มหาสารคาม 44130",
    },
    {
      value: "หนองคูขาด บรบือ มหาสารคาม 44130",
      label: "หนองคูขาด บรบือ มหาสารคาม 44130",
    },
    {
      value: "หนองจิก บรบือ มหาสารคาม 44130",
      label: "หนองจิก บรบือ มหาสารคาม 44130",
    },
    {
      value: "หนองม่วง บรบือ มหาสารคาม 44130",
      label: "หนองม่วง บรบือ มหาสารคาม 44130",
    },
    {
      value: "หนองสิม บรบือ มหาสารคาม 44130",
      label: "หนองสิม บรบือ มหาสารคาม 44130",
    },
    {
      value: "หนองโก บรบือ มหาสารคาม 44130",
      label: "หนองโก บรบือ มหาสารคาม 44130",
    },
    {
      value: "โนนราษี บรบือ มหาสารคาม 44130",
      label: "โนนราษี บรบือ มหาสารคาม 44130",
    },
    {
      value: "โนนแดง บรบือ มหาสารคาม 44130",
      label: "โนนแดง บรบือ มหาสารคาม 44130",
    },
    {
      value: "ก้ามปู พยัคฆภูมิพิสัย มหาสารคาม 44110",
      label: "ก้ามปู พยัคฆภูมิพิสัย มหาสารคาม 44110",
    },
    {
      value: "นาสีนวล พยัคฆภูมิพิสัย มหาสารคาม 44110",
      label: "นาสีนวล พยัคฆภูมิพิสัย มหาสารคาม 44110",
    },
    {
      value: "ปะหลาน พยัคฆภูมิพิสัย มหาสารคาม 44110",
      label: "ปะหลาน พยัคฆภูมิพิสัย มหาสารคาม 44110",
    },
    {
      value: "ภารแอ่น พยัคฆภูมิพิสัย มหาสารคาม 44110",
      label: "ภารแอ่น พยัคฆภูมิพิสัย มหาสารคาม 44110",
    },
    {
      value: "ราษฎร์พัฒนา พยัคฆภูมิพิสัย มหาสารคาม 44110",
      label: "ราษฎร์พัฒนา พยัคฆภูมิพิสัย มหาสารคาม 44110",
    },
    {
      value: "ราษฎร์เจริญ พยัคฆภูมิพิสัย มหาสารคาม 44110",
      label: "ราษฎร์เจริญ พยัคฆภูมิพิสัย มหาสารคาม 44110",
    },
    {
      value: "ลานสะแก พยัคฆภูมิพิสัย มหาสารคาม 44110",
      label: "ลานสะแก พยัคฆภูมิพิสัย มหาสารคาม 44110",
    },
    {
      value: "หนองบัว พยัคฆภูมิพิสัย มหาสารคาม 44110",
      label: "หนองบัว พยัคฆภูมิพิสัย มหาสารคาม 44110",
    },
    {
      value: "หนองบัวแก้ว พยัคฆภูมิพิสัย มหาสารคาม 44110",
      label: "หนองบัวแก้ว พยัคฆภูมิพิสัย มหาสารคาม 44110",
    },
    {
      value: "เมืองเตา พยัคฆภูมิพิสัย มหาสารคาม 44110",
      label: "เมืองเตา พยัคฆภูมิพิสัย มหาสารคาม 44110",
    },
    {
      value: "เมืองเสือ พยัคฆภูมิพิสัย มหาสารคาม 44110",
      label: "เมืองเสือ พยัคฆภูมิพิสัย มหาสารคาม 44110",
    },
    {
      value: "เม็กดำ พยัคฆภูมิพิสัย มหาสารคาม 44110",
      label: "เม็กดำ พยัคฆภูมิพิสัย มหาสารคาม 44110",
    },
    {
      value: "เวียงชัย พยัคฆภูมิพิสัย มหาสารคาม 44110",
      label: "เวียงชัย พยัคฆภูมิพิสัย มหาสารคาม 44110",
    },
    {
      value: "เวียงสะอาด พยัคฆภูมิพิสัย มหาสารคาม 44110",
      label: "เวียงสะอาด พยัคฆภูมิพิสัย มหาสารคาม 44110",
    },
    {
      value: "ขามเรียน ยางสีสุราช มหาสารคาม 44210",
      label: "ขามเรียน ยางสีสุราช มหาสารคาม 44210",
    },
    {
      value: "ดงเมือง ยางสีสุราช มหาสารคาม 44210",
      label: "ดงเมือง ยางสีสุราช มหาสารคาม 44210",
    },
    {
      value: "นาภู ยางสีสุราช มหาสารคาม 44210",
      label: "นาภู ยางสีสุราช มหาสารคาม 44210",
    },
    {
      value: "บ้านกู่ ยางสีสุราช มหาสารคาม 44210",
      label: "บ้านกู่ ยางสีสุราช มหาสารคาม 44210",
    },
    {
      value: "ยางสีสุราช ยางสีสุราช มหาสารคาม 44210",
      label: "ยางสีสุราช ยางสีสุราช มหาสารคาม 44210",
    },
    {
      value: "หนองบัวสันตุ ยางสีสุราช มหาสารคาม 44210",
      label: "หนองบัวสันตุ ยางสีสุราช มหาสารคาม 44210",
    },
    {
      value: "แวงดง ยางสีสุราช มหาสารคาม 44210",
      label: "แวงดง ยางสีสุราช มหาสารคาม 44210",
    },
    {
      value: "ขามป้อม วาปีปทุม มหาสารคาม 44120",
      label: "ขามป้อม วาปีปทุม มหาสารคาม 44120",
    },
    {
      value: "งัวบา วาปีปทุม มหาสารคาม 44120",
      label: "งัวบา วาปีปทุม มหาสารคาม 44120",
    },
    {
      value: "ดงใหญ่ วาปีปทุม มหาสารคาม 44120",
      label: "ดงใหญ่ วาปีปทุม มหาสารคาม 44120",
    },
    {
      value: "นาข่า วาปีปทุม มหาสารคาม 44120",
      label: "นาข่า วาปีปทุม มหาสารคาม 44120",
    },
    {
      value: "บ้านหวาย วาปีปทุม มหาสารคาม 44120",
      label: "บ้านหวาย วาปีปทุม มหาสารคาม 44120",
    },
    {
      value: "ประชาพัฒนา วาปีปทุม มหาสารคาม 44120",
      label: "ประชาพัฒนา วาปีปทุม มหาสารคาม 44120",
    },
    {
      value: "หนองทุ่ม วาปีปทุม มหาสารคาม 44120",
      label: "หนองทุ่ม วาปีปทุม มหาสารคาม 44120",
    },
    {
      value: "หนองแสง วาปีปทุม มหาสารคาม 44120",
      label: "หนองแสง วาปีปทุม มหาสารคาม 44120",
    },
    {
      value: "หนองแสน วาปีปทุม มหาสารคาม 44120",
      label: "หนองแสน วาปีปทุม มหาสารคาม 44120",
    },
    {
      value: "หนองไฮ วาปีปทุม มหาสารคาม 44120",
      label: "หนองไฮ วาปีปทุม มหาสารคาม 44120",
    },
    {
      value: "หัวเรือ วาปีปทุม มหาสารคาม 44120",
      label: "หัวเรือ วาปีปทุม มหาสารคาม 44120",
    },
    {
      value: "เสือโก้ก วาปีปทุม มหาสารคาม 44120",
      label: "เสือโก้ก วาปีปทุม มหาสารคาม 44120",
    },
    {
      value: "แคน วาปีปทุม มหาสารคาม 44120",
      label: "แคน วาปีปทุม มหาสารคาม 44120",
    },
    {
      value: "โคกสีทองหลาง วาปีปทุม มหาสารคาม 44120",
      label: "โคกสีทองหลาง วาปีปทุม มหาสารคาม 44120",
    },
    {
      value: "โพธิ์ชัย วาปีปทุม มหาสารคาม 44120",
      label: "โพธิ์ชัย วาปีปทุม มหาสารคาม 44120",
    },
    {
      value: "กู่ทอง เชียงยืน มหาสารคาม 44160",
      label: "กู่ทอง เชียงยืน มหาสารคาม 44160",
    },
    {
      value: "ดอนเงิน เชียงยืน มหาสารคาม 44160",
      label: "ดอนเงิน เชียงยืน มหาสารคาม 44160",
    },
    {
      value: "นาทอง เชียงยืน มหาสารคาม 44160",
      label: "นาทอง เชียงยืน มหาสารคาม 44160",
    },
    {
      value: "หนองซอน เชียงยืน มหาสารคาม 44160",
      label: "หนองซอน เชียงยืน มหาสารคาม 44160",
    },
    {
      value: "เชียงยืน เชียงยืน มหาสารคาม 44160",
      label: "เชียงยืน เชียงยืน มหาสารคาม 44160",
    },
    {
      value: "เสือเฒ่า เชียงยืน มหาสารคาม 44160",
      label: "เสือเฒ่า เชียงยืน มหาสารคาม 44160",
    },
    {
      value: "เหล่าบัวบาน เชียงยืน มหาสารคาม 44160",
      label: "เหล่าบัวบาน เชียงยืน มหาสารคาม 44160",
    },
    {
      value: "โพนทอง เชียงยืน มหาสารคาม 44160",
      label: "โพนทอง เชียงยืน มหาสารคาม 44160",
    },
    {
      value: "ดอนหว่าน เมืองมหาสารคาม มหาสารคาม 44000",
      label: "ดอนหว่าน เมืองมหาสารคาม มหาสารคาม 44000",
    },
    {
      value: "ตลาด เมืองมหาสารคาม มหาสารคาม 44000",
      label: "ตลาด เมืองมหาสารคาม มหาสารคาม 44000",
    },
    {
      value: "ท่าตูม เมืองมหาสารคาม มหาสารคาม 44000",
      label: "ท่าตูม เมืองมหาสารคาม มหาสารคาม 44000",
    },
    {
      value: "ท่าสองคอน เมืองมหาสารคาม มหาสารคาม 44000",
      label: "ท่าสองคอน เมืองมหาสารคาม มหาสารคาม 44000",
    },
    {
      value: "บัวค้อ เมืองมหาสารคาม มหาสารคาม 44000",
      label: "บัวค้อ เมืองมหาสารคาม มหาสารคาม 44000",
    },
    {
      value: "ลาดพัฒนา เมืองมหาสารคาม มหาสารคาม 44000",
      label: "ลาดพัฒนา เมืองมหาสารคาม มหาสารคาม 44000",
    },
    {
      value: "หนองปลิง เมืองมหาสารคาม มหาสารคาม 44000",
      label: "หนองปลิง เมืองมหาสารคาม มหาสารคาม 44000",
    },
    {
      value: "หนองโน เมืองมหาสารคาม มหาสารคาม 44000",
      label: "หนองโน เมืองมหาสารคาม มหาสารคาม 44000",
    },
    {
      value: "ห้วยแอ่ง เมืองมหาสารคาม มหาสารคาม 44000",
      label: "ห้วยแอ่ง เมืองมหาสารคาม มหาสารคาม 44000",
    },
    {
      value: "เกิ้ง เมืองมหาสารคาม มหาสารคาม 44000",
      label: "เกิ้ง เมืองมหาสารคาม มหาสารคาม 44000",
    },
    {
      value: "เขวา เมืองมหาสารคาม มหาสารคาม 44000",
      label: "เขวา เมืองมหาสารคาม มหาสารคาม 44000",
    },
    {
      value: "แก่งเลิงจาน เมืองมหาสารคาม มหาสารคาม 44000",
      label: "แก่งเลิงจาน เมืองมหาสารคาม มหาสารคาม 44000",
    },
    {
      value: "แวงน่าง เมืองมหาสารคาม มหาสารคาม 44000",
      label: "แวงน่าง เมืองมหาสารคาม มหาสารคาม 44000",
    },
    {
      value: "โคกก่อ เมืองมหาสารคาม มหาสารคาม 44000",
      label: "โคกก่อ เมืองมหาสารคาม มหาสารคาม 44000",
    },
    {
      value: "มิตรภาพ แกดำ มหาสารคาม 44190",
      label: "มิตรภาพ แกดำ มหาสารคาม 44190",
    },
    {
      value: "วังแสง แกดำ มหาสารคาม 44190",
      label: "วังแสง แกดำ มหาสารคาม 44190",
    },
    {
      value: "หนองกุง แกดำ มหาสารคาม 44190",
      label: "หนองกุง แกดำ มหาสารคาม 44190",
    },
    { value: "แกดำ แกดำ มหาสารคาม 44190", label: "แกดำ แกดำ มหาสารคาม 44190" },
    {
      value: "โนนภิบาล แกดำ มหาสารคาม 44190",
      label: "โนนภิบาล แกดำ มหาสารคาม 44190",
    },
    {
      value: "ดอนกลาง โกสุมพิสัย มหาสารคาม 44140",
      label: "ดอนกลาง โกสุมพิสัย มหาสารคาม 44140",
    },
    {
      value: "ยางท่าแจ้ง โกสุมพิสัย มหาสารคาม 44140",
      label: "ยางท่าแจ้ง โกสุมพิสัย มหาสารคาม 44140",
    },
    {
      value: "ยางน้อย โกสุมพิสัย มหาสารคาม 44140",
      label: "ยางน้อย โกสุมพิสัย มหาสารคาม 44140",
    },
    {
      value: "วังยาว โกสุมพิสัย มหาสารคาม 44140",
      label: "วังยาว โกสุมพิสัย มหาสารคาม 44140",
    },
    {
      value: "หนองกุงสวรรค์ โกสุมพิสัย มหาสารคาม 44140",
      label: "หนองกุงสวรรค์ โกสุมพิสัย มหาสารคาม 44140",
    },
    {
      value: "หนองบอน โกสุมพิสัย มหาสารคาม 44140",
      label: "หนองบอน โกสุมพิสัย มหาสารคาม 44140",
    },
    {
      value: "หนองบัว โกสุมพิสัย มหาสารคาม 44140",
      label: "หนองบัว โกสุมพิสัย มหาสารคาม 44140",
    },
    {
      value: "หนองเหล็ก โกสุมพิสัย มหาสารคาม 44140",
      label: "หนองเหล็ก โกสุมพิสัย มหาสารคาม 44140",
    },
    {
      value: "หัวขวาง โกสุมพิสัย มหาสารคาม 44140",
      label: "หัวขวาง โกสุมพิสัย มหาสารคาม 44140",
    },
    {
      value: "เขวาไร่ โกสุมพิสัย มหาสารคาม 44140",
      label: "เขวาไร่ โกสุมพิสัย มหาสารคาม 44140",
    },
    {
      value: "เขื่อน โกสุมพิสัย มหาสารคาม 44140",
      label: "เขื่อน โกสุมพิสัย มหาสารคาม 44140",
    },
    {
      value: "เลิงใต้ โกสุมพิสัย มหาสารคาม 44140",
      label: "เลิงใต้ โกสุมพิสัย มหาสารคาม 44140",
    },
    {
      value: "เหล่า โกสุมพิสัย มหาสารคาม 44140",
      label: "เหล่า โกสุมพิสัย มหาสารคาม 44140",
    },
    {
      value: "แก้งแก โกสุมพิสัย มหาสารคาม 44140",
      label: "แก้งแก โกสุมพิสัย มหาสารคาม 44140",
    },
    {
      value: "แพง โกสุมพิสัย มหาสารคาม 44140",
      label: "แพง โกสุมพิสัย มหาสารคาม 44140",
    },
    {
      value: "แห่ใต้ โกสุมพิสัย มหาสารคาม 44140",
      label: "แห่ใต้ โกสุมพิสัย มหาสารคาม 44140",
    },
    {
      value: "โพนงาม โกสุมพิสัย มหาสารคาม 44140",
      label: "โพนงาม โกสุมพิสัย มหาสารคาม 44140",
    },
    {
      value: "คำชะอี คำชะอี มุกดาหาร 49110",
      label: "คำชะอี คำชะอี มุกดาหาร 49110",
    },
    { value: "คำบก คำชะอี มุกดาหาร 49110", label: "คำบก คำชะอี มุกดาหาร 49110" },
    {
      value: "น้ำเที่ยง คำชะอี มุกดาหาร 49110",
      label: "น้ำเที่ยง คำชะอี มุกดาหาร 49110",
    },
    {
      value: "บ้านค้อ คำชะอี มุกดาหาร 49110",
      label: "บ้านค้อ คำชะอี มุกดาหาร 49110",
    },
    {
      value: "บ้านซ่ง คำชะอี มุกดาหาร 49110",
      label: "บ้านซ่ง คำชะอี มุกดาหาร 49110",
    },
    {
      value: "บ้านเหล่า คำชะอี มุกดาหาร 49110",
      label: "บ้านเหล่า คำชะอี มุกดาหาร 49110",
    },
    {
      value: "หนองเอี่ยน คำชะอี มุกดาหาร 49110",
      label: "หนองเอี่ยน คำชะอี มุกดาหาร 49110",
    },
    {
      value: "เหล่าสร้างถ่อ คำชะอี มุกดาหาร 49110",
      label: "เหล่าสร้างถ่อ คำชะอี มุกดาหาร 49110",
    },
    {
      value: "โพนงาม คำชะอี มุกดาหาร 49110",
      label: "โพนงาม คำชะอี มุกดาหาร 49110",
    },
    {
      value: "กกตูม ดงหลวง มุกดาหาร 49140",
      label: "กกตูม ดงหลวง มุกดาหาร 49140",
    },
    {
      value: "ชะโนดน้อย ดงหลวง มุกดาหาร 49140",
      label: "ชะโนดน้อย ดงหลวง มุกดาหาร 49140",
    },
    {
      value: "ดงหลวง ดงหลวง มุกดาหาร 49140",
      label: "ดงหลวง ดงหลวง มุกดาหาร 49140",
    },
    {
      value: "พังแดง ดงหลวง มุกดาหาร 49140",
      label: "พังแดง ดงหลวง มุกดาหาร 49140",
    },
    {
      value: "หนองบัว ดงหลวง มุกดาหาร 49140",
      label: "หนองบัว ดงหลวง มุกดาหาร 49140",
    },
    {
      value: "หนองแคน ดงหลวง มุกดาหาร 49140",
      label: "หนองแคน ดงหลวง มุกดาหาร 49140",
    },
    {
      value: "ดอนตาล ดอนตาล มุกดาหาร 49120",
      label: "ดอนตาล ดอนตาล มุกดาหาร 49120",
    },
    {
      value: "นาสะเม็ง ดอนตาล มุกดาหาร 49120",
      label: "นาสะเม็ง ดอนตาล มุกดาหาร 49120",
    },
    {
      value: "บ้านบาก ดอนตาล มุกดาหาร 49120",
      label: "บ้านบาก ดอนตาล มุกดาหาร 49120",
    },
    {
      value: "บ้านแก้ง ดอนตาล มุกดาหาร 49120",
      label: "บ้านแก้ง ดอนตาล มุกดาหาร 49120",
    },
    {
      value: "ป่าไร่ ดอนตาล มุกดาหาร 49120",
      label: "ป่าไร่ ดอนตาล มุกดาหาร 49120",
    },
    {
      value: "เหล่าหมี ดอนตาล มุกดาหาร 49120",
      label: "เหล่าหมี ดอนตาล มุกดาหาร 49120",
    },
    {
      value: "โพธิ์ไทร ดอนตาล มุกดาหาร 49120",
      label: "โพธิ์ไทร ดอนตาล มุกดาหาร 49120",
    },
    {
      value: "กกแดง นิคมคำสร้อย มุกดาหาร 49130",
      label: "กกแดง นิคมคำสร้อย มุกดาหาร 49130",
    },
    {
      value: "นากอก นิคมคำสร้อย มุกดาหาร 49130",
      label: "นากอก นิคมคำสร้อย มุกดาหาร 49130",
    },
    {
      value: "นาอุดม นิคมคำสร้อย มุกดาหาร 49130",
      label: "นาอุดม นิคมคำสร้อย มุกดาหาร 49130",
    },
    {
      value: "นิคมคำสร้อย นิคมคำสร้อย มุกดาหาร 49130",
      label: "นิคมคำสร้อย นิคมคำสร้อย มุกดาหาร 49130",
    },
    {
      value: "ร่มเกล้า นิคมคำสร้อย มุกดาหาร 49130",
      label: "ร่มเกล้า นิคมคำสร้อย มุกดาหาร 49130",
    },
    {
      value: "หนองแวง นิคมคำสร้อย มุกดาหาร 49130",
      label: "หนองแวง นิคมคำสร้อย มุกดาหาร 49130",
    },
    {
      value: "โชคชัย นิคมคำสร้อย มุกดาหาร 49130",
      label: "โชคชัย นิคมคำสร้อย มุกดาหาร 49130",
    },
    {
      value: "บ้านเป้า หนองสูง มุกดาหาร 49160",
      label: "บ้านเป้า หนองสูง มุกดาหาร 49160",
    },
    {
      value: "ภูวง หนองสูง มุกดาหาร 49160",
      label: "ภูวง หนองสูง มุกดาหาร 49160",
    },
    {
      value: "หนองสูง หนองสูง มุกดาหาร 49160",
      label: "หนองสูง หนองสูง มุกดาหาร 49160",
    },
    {
      value: "หนองสูงเหนือ หนองสูง มุกดาหาร 49160",
      label: "หนองสูงเหนือ หนองสูง มุกดาหาร 49160",
    },
    {
      value: "หนองสูงใต้ หนองสูง มุกดาหาร 49160",
      label: "หนองสูงใต้ หนองสูง มุกดาหาร 49160",
    },
    {
      value: "โนนยาง หนองสูง มุกดาหาร 49160",
      label: "โนนยาง หนองสูง มุกดาหาร 49160",
    },
    {
      value: "ชะโนด หว้านใหญ่ มุกดาหาร 49150",
      label: "ชะโนด หว้านใหญ่ มุกดาหาร 49150",
    },
    {
      value: "ดงหมู หว้านใหญ่ มุกดาหาร 49150",
      label: "ดงหมู หว้านใหญ่ มุกดาหาร 49150",
    },
    {
      value: "บางทรายน้อย หว้านใหญ่ มุกดาหาร 49150",
      label: "บางทรายน้อย หว้านใหญ่ มุกดาหาร 49150",
    },
    {
      value: "ป่งขาม หว้านใหญ่ มุกดาหาร 49150",
      label: "ป่งขาม หว้านใหญ่ มุกดาหาร 49150",
    },
    {
      value: "หว้านใหญ่ หว้านใหญ่ มุกดาหาร 49150",
      label: "หว้านใหญ่ หว้านใหญ่ มุกดาหาร 49150",
    },
    {
      value: "กุดแข้ เมืองมุกดาหาร มุกดาหาร 49000",
      label: "กุดแข้ เมืองมุกดาหาร มุกดาหาร 49000",
    },
    {
      value: "คำป่าหลาย เมืองมุกดาหาร มุกดาหาร 49000",
      label: "คำป่าหลาย เมืองมุกดาหาร มุกดาหาร 49000",
    },
    {
      value: "คำอาฮวน เมืองมุกดาหาร มุกดาหาร 49000",
      label: "คำอาฮวน เมืองมุกดาหาร มุกดาหาร 49000",
    },
    {
      value: "ดงมอน เมืองมุกดาหาร มุกดาหาร 49000",
      label: "ดงมอน เมืองมุกดาหาร มุกดาหาร 49000",
    },
    {
      value: "ดงเย็น เมืองมุกดาหาร มุกดาหาร 49000",
      label: "ดงเย็น เมืองมุกดาหาร มุกดาหาร 49000",
    },
    {
      value: "นาสีนวน เมืองมุกดาหาร มุกดาหาร 49000",
      label: "นาสีนวน เมืองมุกดาหาร มุกดาหาร 49000",
    },
    {
      value: "นาโสก เมืองมุกดาหาร มุกดาหาร 49000",
      label: "นาโสก เมืองมุกดาหาร มุกดาหาร 49000",
    },
    {
      value: "บางทรายใหญ่ เมืองมุกดาหาร มุกดาหาร 49000",
      label: "บางทรายใหญ่ เมืองมุกดาหาร มุกดาหาร 49000",
    },
    {
      value: "บ้านโคก เมืองมุกดาหาร มุกดาหาร 49000",
      label: "บ้านโคก เมืองมุกดาหาร มุกดาหาร 49000",
    },
    {
      value: "ผึ่งแดด เมืองมุกดาหาร มุกดาหาร 49000",
      label: "ผึ่งแดด เมืองมุกดาหาร มุกดาหาร 49000",
    },
    {
      value: "มุกดาหาร เมืองมุกดาหาร มุกดาหาร 49000",
      label: "มุกดาหาร เมืองมุกดาหาร มุกดาหาร 49000",
    },
    {
      value: "ศรีบุญเรือง เมืองมุกดาหาร มุกดาหาร 49000",
      label: "ศรีบุญเรือง เมืองมุกดาหาร มุกดาหาร 49000",
    },
    {
      value: "โพนทราย เมืองมุกดาหาร มุกดาหาร 49000",
      label: "โพนทราย เมืองมุกดาหาร มุกดาหาร 49000",
    },
    { value: "กำแมด กุดชุม ยโสธร 35140", label: "กำแมด กุดชุม ยโสธร 35140" },
    { value: "กุดชุม กุดชุม ยโสธร 35140", label: "กุดชุม กุดชุม ยโสธร 35140" },
    {
      value: "คำน้ำสร้าง กุดชุม ยโสธร 35140",
      label: "คำน้ำสร้าง กุดชุม ยโสธร 35140",
    },
    { value: "นาโส่ กุดชุม ยโสธร 35140", label: "นาโส่ กุดชุม ยโสธร 35140" },
    { value: "หนองหมี กุดชุม ยโสธร 35140", label: "หนองหมี กุดชุม ยโสธร 35140" },
    { value: "หนองแหน กุดชุม ยโสธร 35140", label: "หนองแหน กุดชุม ยโสธร 35140" },
    {
      value: "ห้วยแก้ง กุดชุม ยโสธร 35140",
      label: "ห้วยแก้ง กุดชุม ยโสธร 35140",
    },
    {
      value: "โนนเปือย กุดชุม ยโสธร 35140",
      label: "โนนเปือย กุดชุม ยโสธร 35140",
    },
    { value: "โพนงาม กุดชุม ยโสธร 35140", label: "โพนงาม กุดชุม ยโสธร 35140" },
    {
      value: "กุดกุง คำเขื่อนแก้ว ยโสธร 35110",
      label: "กุดกุง คำเขื่อนแก้ว ยโสธร 35110",
    },
    {
      value: "กู่จาน คำเขื่อนแก้ว ยโสธร 35110",
      label: "กู่จาน คำเขื่อนแก้ว ยโสธร 35110",
    },
    {
      value: "ดงเจริญ คำเขื่อนแก้ว ยโสธร 35110",
      label: "ดงเจริญ คำเขื่อนแก้ว ยโสธร 35110",
    },
    {
      value: "ดงแคนใหญ่ คำเขื่อนแก้ว ยโสธร 35180",
      label: "ดงแคนใหญ่ คำเขื่อนแก้ว ยโสธร 35180",
    },
    {
      value: "ทุ่งมน คำเขื่อนแก้ว ยโสธร 35110",
      label: "ทุ่งมน คำเขื่อนแก้ว ยโสธร 35110",
    },
    {
      value: "นาคำ คำเขื่อนแก้ว ยโสธร 35180",
      label: "นาคำ คำเขื่อนแก้ว ยโสธร 35180",
    },
    {
      value: "นาแก คำเขื่อนแก้ว ยโสธร 35180",
      label: "นาแก คำเขื่อนแก้ว ยโสธร 35180",
    },
    {
      value: "ย่อ คำเขื่อนแก้ว ยโสธร 35110",
      label: "ย่อ คำเขื่อนแก้ว ยโสธร 35110",
    },
    {
      value: "ลุมพุก คำเขื่อนแก้ว ยโสธร 35110",
      label: "ลุมพุก คำเขื่อนแก้ว ยโสธร 35110",
    },
    {
      value: "สงเปือย คำเขื่อนแก้ว ยโสธร 35110",
      label: "สงเปือย คำเขื่อนแก้ว ยโสธร 35110",
    },
    {
      value: "เหล่าไฮ คำเขื่อนแก้ว ยโสธร 35110",
      label: "เหล่าไฮ คำเขื่อนแก้ว ยโสธร 35110",
    },
    {
      value: "แคนน้อย คำเขื่อนแก้ว ยโสธร 35180",
      label: "แคนน้อย คำเขื่อนแก้ว ยโสธร 35180",
    },
    {
      value: "โพนทัน คำเขื่อนแก้ว ยโสธร 35110",
      label: "โพนทัน คำเขื่อนแก้ว ยโสธร 35110",
    },
    {
      value: "กุดน้ำใส ค้อวัง ยโสธร 35160",
      label: "กุดน้ำใส ค้อวัง ยโสธร 35160",
    },
    { value: "ค้อวัง ค้อวัง ยโสธร 35160", label: "ค้อวัง ค้อวัง ยโสธร 35160" },
    { value: "น้ำอ้อม ค้อวัง ยโสธร 35160", label: "น้ำอ้อม ค้อวัง ยโสธร 35160" },
    { value: "ฟ้าห่วน ค้อวัง ยโสธร 35160", label: "ฟ้าห่วน ค้อวัง ยโสธร 35160" },
    { value: "ดงมะไฟ ทรายมูล ยโสธร 35170", label: "ดงมะไฟ ทรายมูล ยโสธร 35170" },
    { value: "ดู่ลาด ทรายมูล ยโสธร 35170", label: "ดู่ลาด ทรายมูล ยโสธร 35170" },
    {
      value: "ทรายมูล ทรายมูล ยโสธร 35170",
      label: "ทรายมูล ทรายมูล ยโสธร 35170",
    },
    {
      value: "นาเวียง ทรายมูล ยโสธร 35170",
      label: "นาเวียง ทรายมูล ยโสธร 35170",
    },
    { value: "ไผ่ ทรายมูล ยโสธร 35170", label: "ไผ่ ทรายมูล ยโสธร 35170" },
    { value: "กระจาย ป่าติ้ว ยโสธร 35150", label: "กระจาย ป่าติ้ว ยโสธร 35150" },
    { value: "ศรีฐาน ป่าติ้ว ยโสธร 35150", label: "ศรีฐาน ป่าติ้ว ยโสธร 35150" },
    {
      value: "เชียงเพ็ง ป่าติ้ว ยโสธร 35150",
      label: "เชียงเพ็ง ป่าติ้ว ยโสธร 35150",
    },
    {
      value: "โคกนาโก ป่าติ้ว ยโสธร 35150",
      label: "โคกนาโก ป่าติ้ว ยโสธร 35150",
    },
    {
      value: "โพธิ์ไทร ป่าติ้ว ยโสธร 35150",
      label: "โพธิ์ไทร ป่าติ้ว ยโสธร 35150",
    },
    {
      value: "คูเมือง มหาชนะชัย ยโสธร 35130",
      label: "คูเมือง มหาชนะชัย ยโสธร 35130",
    },
    {
      value: "บากเรือ มหาชนะชัย ยโสธร 35130",
      label: "บากเรือ มหาชนะชัย ยโสธร 35130",
    },
    {
      value: "บึงแก มหาชนะชัย ยโสธร 35130",
      label: "บึงแก มหาชนะชัย ยโสธร 35130",
    },
    {
      value: "ผือฮี มหาชนะชัย ยโสธร 35130",
      label: "ผือฮี มหาชนะชัย ยโสธร 35130",
    },
    {
      value: "พระเสาร์ มหาชนะชัย ยโสธร 35130",
      label: "พระเสาร์ มหาชนะชัย ยโสธร 35130",
    },
    {
      value: "ฟ้าหยาด มหาชนะชัย ยโสธร 35130",
      label: "ฟ้าหยาด มหาชนะชัย ยโสธร 35130",
    },
    { value: "ม่วง มหาชนะชัย ยโสธร 35130", label: "ม่วง มหาชนะชัย ยโสธร 35130" },
    {
      value: "สงยาง มหาชนะชัย ยโสธร 35130",
      label: "สงยาง มหาชนะชัย ยโสธร 35130",
    },
    {
      value: "หัวเมือง มหาชนะชัย ยโสธร 35130",
      label: "หัวเมือง มหาชนะชัย ยโสธร 35130",
    },
    {
      value: "โนนทราย มหาชนะชัย ยโสธร 35130",
      label: "โนนทราย มหาชนะชัย ยโสธร 35130",
    },
    {
      value: "ขั้นไดใหญ่ เมืองยโสธร ยโสธร 35000",
      label: "ขั้นไดใหญ่ เมืองยโสธร ยโสธร 35000",
    },
    {
      value: "ขุมเงิน เมืองยโสธร ยโสธร 35000",
      label: "ขุมเงิน เมืองยโสธร ยโสธร 35000",
    },
    {
      value: "ค้อเหนือ เมืองยโสธร ยโสธร 35000",
      label: "ค้อเหนือ เมืองยโสธร ยโสธร 35000",
    },
    {
      value: "ดู่ทุ่ง เมืองยโสธร ยโสธร 35000",
      label: "ดู่ทุ่ง เมืองยโสธร ยโสธร 35000",
    },
    {
      value: "ตาดทอง เมืองยโสธร ยโสธร 35000",
      label: "ตาดทอง เมืองยโสธร ยโสธร 35000",
    },
    {
      value: "ทุ่งนางโอก เมืองยโสธร ยโสธร 35000",
      label: "ทุ่งนางโอก เมืองยโสธร ยโสธร 35000",
    },
    {
      value: "ทุ่งแต้ เมืองยโสธร ยโสธร 35000",
      label: "ทุ่งแต้ เมืองยโสธร ยโสธร 35000",
    },
    {
      value: "นาสะไมย์ เมืองยโสธร ยโสธร 35000",
      label: "นาสะไมย์ เมืองยโสธร ยโสธร 35000",
    },
    {
      value: "น้ำคำใหญ่ เมืองยโสธร ยโสธร 35000",
      label: "น้ำคำใหญ่ เมืองยโสธร ยโสธร 35000",
    },
    {
      value: "สำราญ เมืองยโสธร ยโสธร 35000",
      label: "สำราญ เมืองยโสธร ยโสธร 35000",
    },
    {
      value: "สิงห์ เมืองยโสธร ยโสธร 35000",
      label: "สิงห์ เมืองยโสธร ยโสธร 35000",
    },
    {
      value: "หนองคู เมืองยโสธร ยโสธร 35000",
      label: "หนองคู เมืองยโสธร ยโสธร 35000",
    },
    {
      value: "หนองหิน เมืองยโสธร ยโสธร 35000",
      label: "หนองหิน เมืองยโสธร ยโสธร 35000",
    },
    {
      value: "หนองเป็ด เมืองยโสธร ยโสธร 35000",
      label: "หนองเป็ด เมืองยโสธร ยโสธร 35000",
    },
    {
      value: "หนองเรือ เมืองยโสธร ยโสธร 35000",
      label: "หนองเรือ เมืองยโสธร ยโสธร 35000",
    },
    {
      value: "เขื่องคำ เมืองยโสธร ยโสธร 35000",
      label: "เขื่องคำ เมืองยโสธร ยโสธร 35000",
    },
    {
      value: "เดิด เมืองยโสธร ยโสธร 35000",
      label: "เดิด เมืองยโสธร ยโสธร 35000",
    },
    {
      value: "ในเมือง เมืองยโสธร ยโสธร 35000",
      label: "ในเมือง เมืองยโสธร ยโสธร 35000",
    },
    {
      value: "กุดเชียงหมี เลิงนกทา ยโสธร 35120",
      label: "กุดเชียงหมี เลิงนกทา ยโสธร 35120",
    },
    {
      value: "กุดแห่ เลิงนกทา ยโสธร 35120",
      label: "กุดแห่ เลิงนกทา ยโสธร 35120",
    },
    {
      value: "บุ่งค้า เลิงนกทา ยโสธร 35120",
      label: "บุ่งค้า เลิงนกทา ยโสธร 35120",
    },
    {
      value: "ศรีแก้ว เลิงนกทา ยโสธร 35120",
      label: "ศรีแก้ว เลิงนกทา ยโสธร 35120",
    },
    {
      value: "สร้างมิ่ง เลิงนกทา ยโสธร 35120",
      label: "สร้างมิ่ง เลิงนกทา ยโสธร 35120",
    },
    { value: "สวาท เลิงนกทา ยโสธร 35120", label: "สวาท เลิงนกทา ยโสธร 35120" },
    {
      value: "สามัคคี เลิงนกทา ยโสธร 35120",
      label: "สามัคคี เลิงนกทา ยโสธร 35120",
    },
    {
      value: "สามแยก เลิงนกทา ยโสธร 35120",
      label: "สามแยก เลิงนกทา ยโสธร 35120",
    },
    {
      value: "ห้องแซง เลิงนกทา ยโสธร 35120",
      label: "ห้องแซง เลิงนกทา ยโสธร 35120",
    },
    {
      value: "โคกสำราญ เลิงนกทา ยโสธร 35120",
      label: "โคกสำราญ เลิงนกทา ยโสธร 35120",
    },
    { value: "คำเตย ไทยเจริญ ยโสธร 35120", label: "คำเตย ไทยเจริญ ยโสธร 35120" },
    { value: "คำไผ่ ไทยเจริญ ยโสธร 35120", label: "คำไผ่ ไทยเจริญ ยโสธร 35120" },
    { value: "น้ำคำ ไทยเจริญ ยโสธร 35120", label: "น้ำคำ ไทยเจริญ ยโสธร 35120" },
    {
      value: "ส้มผ่อ ไทยเจริญ ยโสธร 35120",
      label: "ส้มผ่อ ไทยเจริญ ยโสธร 35120",
    },
    {
      value: "ไทยเจริญ ไทยเจริญ ยโสธร 35120",
      label: "ไทยเจริญ ไทยเจริญ ยโสธร 35120",
    },
    {
      value: "ดงกลาง จตุรพักตรพิมาน ร้อยเอ็ด 45180",
      label: "ดงกลาง จตุรพักตรพิมาน ร้อยเอ็ด 45180",
    },
    {
      value: "ดงแดง จตุรพักตรพิมาน ร้อยเอ็ด 45180",
      label: "ดงแดง จตุรพักตรพิมาน ร้อยเอ็ด 45180",
    },
    {
      value: "ดู่น้อย จตุรพักตรพิมาน ร้อยเอ็ด 45180",
      label: "ดู่น้อย จตุรพักตรพิมาน ร้อยเอ็ด 45180",
    },
    {
      value: "น้ำใส จตุรพักตรพิมาน ร้อยเอ็ด 45180",
      label: "น้ำใส จตุรพักตรพิมาน ร้อยเอ็ด 45180",
    },
    {
      value: "ป่าสังข์ จตุรพักตรพิมาน ร้อยเอ็ด 45180",
      label: "ป่าสังข์ จตุรพักตรพิมาน ร้อยเอ็ด 45180",
    },
    {
      value: "ลิ้นฟ้า จตุรพักตรพิมาน ร้อยเอ็ด 45180",
      label: "ลิ้นฟ้า จตุรพักตรพิมาน ร้อยเอ็ด 45180",
    },
    {
      value: "ศรีโคตร จตุรพักตรพิมาน ร้อยเอ็ด 45180",
      label: "ศรีโคตร จตุรพักตรพิมาน ร้อยเอ็ด 45180",
    },
    {
      value: "หนองผือ จตุรพักตรพิมาน ร้อยเอ็ด 45180",
      label: "หนองผือ จตุรพักตรพิมาน ร้อยเอ็ด 45180",
    },
    {
      value: "หัวช้าง จตุรพักตรพิมาน ร้อยเอ็ด 45180",
      label: "หัวช้าง จตุรพักตรพิมาน ร้อยเอ็ด 45180",
    },
    {
      value: "อีง่อง จตุรพักตรพิมาน ร้อยเอ็ด 45180",
      label: "อีง่อง จตุรพักตรพิมาน ร้อยเอ็ด 45180",
    },
    {
      value: "เมืองหงส์ จตุรพักตรพิมาน ร้อยเอ็ด 45180",
      label: "เมืองหงส์ จตุรพักตรพิมาน ร้อยเอ็ด 45180",
    },
    {
      value: "โคกล่าม จตุรพักตรพิมาน ร้อยเอ็ด 45180",
      label: "โคกล่าม จตุรพักตรพิมาน ร้อยเอ็ด 45180",
    },
    {
      value: "จังหาร จังหาร ร้อยเอ็ด 45000",
      label: "จังหาร จังหาร ร้อยเอ็ด 45000",
    },
    {
      value: "ดงสิงห์ จังหาร ร้อยเอ็ด 45000",
      label: "ดงสิงห์ จังหาร ร้อยเอ็ด 45000",
    },
    {
      value: "ดินดำ จังหาร ร้อยเอ็ด 45000",
      label: "ดินดำ จังหาร ร้อยเอ็ด 45000",
    },
    { value: "ปาฝา จังหาร ร้อยเอ็ด 45000", label: "ปาฝา จังหาร ร้อยเอ็ด 45000" },
    {
      value: "ผักแว่น จังหาร ร้อยเอ็ด 45000",
      label: "ผักแว่น จังหาร ร้อยเอ็ด 45000",
    },
    {
      value: "ม่วงลาด จังหาร ร้อยเอ็ด 45000",
      label: "ม่วงลาด จังหาร ร้อยเอ็ด 45000",
    },
    {
      value: "ยางใหญ่ จังหาร ร้อยเอ็ด 45000",
      label: "ยางใหญ่ จังหาร ร้อยเอ็ด 45000",
    },
    {
      value: "แสนชาติ จังหาร ร้อยเอ็ด 45000",
      label: "แสนชาติ จังหาร ร้อยเอ็ด 45000",
    },
    {
      value: "ทุ่งเขาหลวง ทุ่งเขาหลวง ร้อยเอ็ด 45170",
      label: "ทุ่งเขาหลวง ทุ่งเขาหลวง ร้อยเอ็ด 45170",
    },
    {
      value: "บึงงาม ทุ่งเขาหลวง ร้อยเอ็ด 45170",
      label: "บึงงาม ทุ่งเขาหลวง ร้อยเอ็ด 45170",
    },
    {
      value: "มะบ้า ทุ่งเขาหลวง ร้อยเอ็ด 45170",
      label: "มะบ้า ทุ่งเขาหลวง ร้อยเอ็ด 45170",
    },
    {
      value: "เทอดไทย ทุ่งเขาหลวง ร้อยเอ็ด 45170",
      label: "เทอดไทย ทุ่งเขาหลวง ร้อยเอ็ด 45170",
    },
    {
      value: "เหล่า ทุ่งเขาหลวง ร้อยเอ็ด 45170",
      label: "เหล่า ทุ่งเขาหลวง ร้อยเอ็ด 45170",
    },
    {
      value: "ธงธานี ธวัชบุรี ร้อยเอ็ด 45170",
      label: "ธงธานี ธวัชบุรี ร้อยเอ็ด 45170",
    },
    {
      value: "ธวัชบุรี ธวัชบุรี ร้อยเอ็ด 45170",
      label: "ธวัชบุรี ธวัชบุรี ร้อยเอ็ด 45170",
    },
    {
      value: "นิเวศน์ ธวัชบุรี ร้อยเอ็ด 45170",
      label: "นิเวศน์ ธวัชบุรี ร้อยเอ็ด 45170",
    },
    {
      value: "บึงนคร ธวัชบุรี ร้อยเอ็ด 45170",
      label: "บึงนคร ธวัชบุรี ร้อยเอ็ด 45170",
    },
    {
      value: "มะอึ ธวัชบุรี ร้อยเอ็ด 45170",
      label: "มะอึ ธวัชบุรี ร้อยเอ็ด 45170",
    },
    {
      value: "ราชธานี ธวัชบุรี ร้อยเอ็ด 45170",
      label: "ราชธานี ธวัชบุรี ร้อยเอ็ด 45170",
    },
    {
      value: "หนองพอก ธวัชบุรี ร้อยเอ็ด 45170",
      label: "หนองพอก ธวัชบุรี ร้อยเอ็ด 45170",
    },
    {
      value: "หนองไผ่ ธวัชบุรี ร้อยเอ็ด 45170",
      label: "หนองไผ่ ธวัชบุรี ร้อยเอ็ด 45170",
    },
    {
      value: "อุ่มเม้า ธวัชบุรี ร้อยเอ็ด 45170",
      label: "อุ่มเม้า ธวัชบุรี ร้อยเอ็ด 45170",
    },
    {
      value: "เขวาทุ่ง ธวัชบุรี ร้อยเอ็ด 45170",
      label: "เขวาทุ่ง ธวัชบุรี ร้อยเอ็ด 45170",
    },
    {
      value: "เมืองน้อย ธวัชบุรี ร้อยเอ็ด 45170",
      label: "เมืองน้อย ธวัชบุรี ร้อยเอ็ด 45170",
    },
    {
      value: "ไพศาล ธวัชบุรี ร้อยเอ็ด 45170",
      label: "ไพศาล ธวัชบุรี ร้อยเอ็ด 45170",
    },
    {
      value: "ขี้เหล็ก ปทุมรัตต์ ร้อยเอ็ด 45190",
      label: "ขี้เหล็ก ปทุมรัตต์ ร้อยเอ็ด 45190",
    },
    {
      value: "ดอกล้ำ ปทุมรัตต์ ร้อยเอ็ด 45190",
      label: "ดอกล้ำ ปทุมรัตต์ ร้อยเอ็ด 45190",
    },
    {
      value: "บัวแดง ปทุมรัตต์ ร้อยเอ็ด 45190",
      label: "บัวแดง ปทุมรัตต์ ร้อยเอ็ด 45190",
    },
    {
      value: "สระบัว ปทุมรัตต์ ร้อยเอ็ด 45190",
      label: "สระบัว ปทุมรัตต์ ร้อยเอ็ด 45190",
    },
    {
      value: "หนองแคน ปทุมรัตต์ ร้อยเอ็ด 45190",
      label: "หนองแคน ปทุมรัตต์ ร้อยเอ็ด 45190",
    },
    {
      value: "โนนสง่า ปทุมรัตต์ ร้อยเอ็ด 45190",
      label: "โนนสง่า ปทุมรัตต์ ร้อยเอ็ด 45190",
    },
    {
      value: "โนนสวรรค์ ปทุมรัตต์ ร้อยเอ็ด 45190",
      label: "โนนสวรรค์ ปทุมรัตต์ ร้อยเอ็ด 45190",
    },
    {
      value: "โพนสูง ปทุมรัตต์ ร้อยเอ็ด 45190",
      label: "โพนสูง ปทุมรัตต์ ร้อยเอ็ด 45190",
    },
    {
      value: "กุดน้ำใส พนมไพร ร้อยเอ็ด 45140",
      label: "กุดน้ำใส พนมไพร ร้อยเอ็ด 45140",
    },
    { value: "คำไฮ พนมไพร ร้อยเอ็ด 45140", label: "คำไฮ พนมไพร ร้อยเอ็ด 45140" },
    {
      value: "ค้อใหญ่ พนมไพร ร้อยเอ็ด 45140",
      label: "ค้อใหญ่ พนมไพร ร้อยเอ็ด 45140",
    },
    {
      value: "ชานุวรรณ พนมไพร ร้อยเอ็ด 45140",
      label: "ชานุวรรณ พนมไพร ร้อยเอ็ด 45140",
    },
    {
      value: "นานวล พนมไพร ร้อยเอ็ด 45140",
      label: "นานวล พนมไพร ร้อยเอ็ด 45140",
    },
    {
      value: "พนมไพร พนมไพร ร้อยเอ็ด 45140",
      label: "พนมไพร พนมไพร ร้อยเอ็ด 45140",
    },
    {
      value: "วารีสวัสดิ์ พนมไพร ร้อยเอ็ด 45140",
      label: "วารีสวัสดิ์ พนมไพร ร้อยเอ็ด 45140",
    },
    {
      value: "สระแก้ว พนมไพร ร้อยเอ็ด 45140",
      label: "สระแก้ว พนมไพร ร้อยเอ็ด 45140",
    },
    {
      value: "หนองทัพไทย พนมไพร ร้อยเอ็ด 45140",
      label: "หนองทัพไทย พนมไพร ร้อยเอ็ด 45140",
    },
    {
      value: "แสนสุข พนมไพร ร้อยเอ็ด 45140",
      label: "แสนสุข พนมไพร ร้อยเอ็ด 45140",
    },
    {
      value: "โคกสว่าง พนมไพร ร้อยเอ็ด 45140",
      label: "โคกสว่าง พนมไพร ร้อยเอ็ด 45140",
    },
    {
      value: "โพธิ์ชัย พนมไพร ร้อยเอ็ด 45140",
      label: "โพธิ์ชัย พนมไพร ร้อยเอ็ด 45140",
    },
    {
      value: "โพธิ์ใหญ่ พนมไพร ร้อยเอ็ด 45140",
      label: "โพธิ์ใหญ่ พนมไพร ร้อยเอ็ด 45140",
    },
    {
      value: "บ้านบาก ศรีสมเด็จ ร้อยเอ็ด 45000",
      label: "บ้านบาก ศรีสมเด็จ ร้อยเอ็ด 45000",
    },
    {
      value: "ศรีสมเด็จ ศรีสมเด็จ ร้อยเอ็ด 45000",
      label: "ศรีสมเด็จ ศรีสมเด็จ ร้อยเอ็ด 45000",
    },
    {
      value: "สวนจิก ศรีสมเด็จ ร้อยเอ็ด 45280",
      label: "สวนจิก ศรีสมเด็จ ร้อยเอ็ด 45280",
    },
    {
      value: "หนองแวงควง ศรีสมเด็จ ร้อยเอ็ด 45000",
      label: "หนองแวงควง ศรีสมเด็จ ร้อยเอ็ด 45000",
    },
    {
      value: "หนองใหญ่ ศรีสมเด็จ ร้อยเอ็ด 45000",
      label: "หนองใหญ่ ศรีสมเด็จ ร้อยเอ็ด 45000",
    },
    {
      value: "เมืองเปลือย ศรีสมเด็จ ร้อยเอ็ด 45000",
      label: "เมืองเปลือย ศรีสมเด็จ ร้อยเอ็ด 45000",
    },
    {
      value: "โพธิ์ทอง ศรีสมเด็จ ร้อยเอ็ด 45000",
      label: "โพธิ์ทอง ศรีสมเด็จ ร้อยเอ็ด 45000",
    },
    {
      value: "โพธิ์สัย ศรีสมเด็จ ร้อยเอ็ด 45280",
      label: "โพธิ์สัย ศรีสมเด็จ ร้อยเอ็ด 45280",
    },
    {
      value: "จำปาขัน สุวรรณภูมิ ร้อยเอ็ด 45130",
      label: "จำปาขัน สุวรรณภูมิ ร้อยเอ็ด 45130",
    },
    {
      value: "ช้างเผือก สุวรรณภูมิ ร้อยเอ็ด 45130",
      label: "ช้างเผือก สุวรรณภูมิ ร้อยเอ็ด 45130",
    },
    {
      value: "ดอกไม้ สุวรรณภูมิ ร้อยเอ็ด 45130",
      label: "ดอกไม้ สุวรรณภูมิ ร้อยเอ็ด 45130",
    },
    {
      value: "ทุ่งกุลา สุวรรณภูมิ ร้อยเอ็ด 45130",
      label: "ทุ่งกุลา สุวรรณภูมิ ร้อยเอ็ด 45130",
    },
    {
      value: "ทุ่งศรีเมือง สุวรรณภูมิ ร้อยเอ็ด 45130",
      label: "ทุ่งศรีเมือง สุวรรณภูมิ ร้อยเอ็ด 45130",
    },
    {
      value: "ทุ่งหลวง สุวรรณภูมิ ร้อยเอ็ด 45130",
      label: "ทุ่งหลวง สุวรรณภูมิ ร้อยเอ็ด 45130",
    },
    {
      value: "นาใหญ่ สุวรรณภูมิ ร้อยเอ็ด 45130",
      label: "นาใหญ่ สุวรรณภูมิ ร้อยเอ็ด 45130",
    },
    {
      value: "น้ำคำ สุวรรณภูมิ ร้อยเอ็ด 45130",
      label: "น้ำคำ สุวรรณภูมิ ร้อยเอ็ด 45130",
    },
    {
      value: "บ่อพันขัน สุวรรณภูมิ ร้อยเอ็ด 45130",
      label: "บ่อพันขัน สุวรรณภูมิ ร้อยเอ็ด 45130",
    },
    {
      value: "สระคู สุวรรณภูมิ ร้อยเอ็ด 45130",
      label: "สระคู สุวรรณภูมิ ร้อยเอ็ด 45130",
    },
    {
      value: "หัวช้าง สุวรรณภูมิ ร้อยเอ็ด 45130",
      label: "หัวช้าง สุวรรณภูมิ ร้อยเอ็ด 45130",
    },
    {
      value: "หัวโทน สุวรรณภูมิ ร้อยเอ็ด 45130",
      label: "หัวโทน สุวรรณภูมิ ร้อยเอ็ด 45130",
    },
    {
      value: "หินกอง สุวรรณภูมิ ร้อยเอ็ด 45130",
      label: "หินกอง สุวรรณภูมิ ร้อยเอ็ด 45130",
    },
    {
      value: "ห้วยหินลาด สุวรรณภูมิ ร้อยเอ็ด 45130",
      label: "ห้วยหินลาด สุวรรณภูมิ ร้อยเอ็ด 45130",
    },
    {
      value: "เมืองทุ่ง สุวรรณภูมิ ร้อยเอ็ด 45130",
      label: "เมืองทุ่ง สุวรรณภูมิ ร้อยเอ็ด 45130",
    },
    {
      value: "กกโพธิ์ หนองพอก ร้อยเอ็ด 45210",
      label: "กกโพธิ์ หนองพอก ร้อยเอ็ด 45210",
    },
    {
      value: "ท่าสีดา หนองพอก ร้อยเอ็ด 45210",
      label: "ท่าสีดา หนองพอก ร้อยเอ็ด 45210",
    },
    {
      value: "บึงงาม หนองพอก ร้อยเอ็ด 45210",
      label: "บึงงาม หนองพอก ร้อยเอ็ด 45210",
    },
    {
      value: "ผาน้ำย้อย หนองพอก ร้อยเอ็ด 45210",
      label: "ผาน้ำย้อย หนองพอก ร้อยเอ็ด 45210",
    },
    {
      value: "ภูเขาทอง หนองพอก ร้อยเอ็ด 45210",
      label: "ภูเขาทอง หนองพอก ร้อยเอ็ด 45210",
    },
    {
      value: "รอบเมือง หนองพอก ร้อยเอ็ด 45210",
      label: "รอบเมือง หนองพอก ร้อยเอ็ด 45210",
    },
    {
      value: "หนองขุ่นใหญ่ หนองพอก ร้อยเอ็ด 45210",
      label: "หนองขุ่นใหญ่ หนองพอก ร้อยเอ็ด 45210",
    },
    {
      value: "หนองพอก หนองพอก ร้อยเอ็ด 45210",
      label: "หนองพอก หนองพอก ร้อยเอ็ด 45210",
    },
    {
      value: "โคกสว่าง หนองพอก ร้อยเอ็ด 45210",
      label: "โคกสว่าง หนองพอก ร้อยเอ็ด 45210",
    },
    {
      value: "ดูกอึ่ง หนองฮี ร้อยเอ็ด 45140",
      label: "ดูกอึ่ง หนองฮี ร้อยเอ็ด 45140",
    },
    {
      value: "สาวแห หนองฮี ร้อยเอ็ด 45140",
      label: "สาวแห หนองฮี ร้อยเอ็ด 45140",
    },
    {
      value: "หนองฮี หนองฮี ร้อยเอ็ด 45140",
      label: "หนองฮี หนองฮี ร้อยเอ็ด 45140",
    },
    {
      value: "เด่นราษฎร์ หนองฮี ร้อยเอ็ด 45140",
      label: "เด่นราษฎร์ หนองฮี ร้อยเอ็ด 45140",
    },
    {
      value: "ขี้เหล็ก อาจสามารถ ร้อยเอ็ด 45160",
      label: "ขี้เหล็ก อาจสามารถ ร้อยเอ็ด 45160",
    },
    {
      value: "บ้านดู่ อาจสามารถ ร้อยเอ็ด 45160",
      label: "บ้านดู่ อาจสามารถ ร้อยเอ็ด 45160",
    },
    {
      value: "บ้านแจ้ง อาจสามารถ ร้อยเอ็ด 45160",
      label: "บ้านแจ้ง อาจสามารถ ร้อยเอ็ด 45160",
    },
    {
      value: "หนองขาม อาจสามารถ ร้อยเอ็ด 45160",
      label: "หนองขาม อาจสามารถ ร้อยเอ็ด 45160",
    },
    {
      value: "หนองบัว อาจสามารถ ร้อยเอ็ด 45160",
      label: "หนองบัว อาจสามารถ ร้อยเอ็ด 45160",
    },
    {
      value: "หนองหมื่นถ่าน อาจสามารถ ร้อยเอ็ด 45160",
      label: "หนองหมื่นถ่าน อาจสามารถ ร้อยเอ็ด 45160",
    },
    {
      value: "หน่อม อาจสามารถ ร้อยเอ็ด 45160",
      label: "หน่อม อาจสามารถ ร้อยเอ็ด 45160",
    },
    {
      value: "อาจสามารถ อาจสามารถ ร้อยเอ็ด 45160",
      label: "อาจสามารถ อาจสามารถ ร้อยเอ็ด 45160",
    },
    {
      value: "โพนเมือง อาจสามารถ ร้อยเอ็ด 45160",
      label: "โพนเมือง อาจสามารถ ร้อยเอ็ด 45160",
    },
    {
      value: "โหรา อาจสามารถ ร้อยเอ็ด 45160",
      label: "โหรา อาจสามารถ ร้อยเอ็ด 45160",
    },
    {
      value: "กำแพง เกษตรวิสัย ร้อยเอ็ด 45150",
      label: "กำแพง เกษตรวิสัย ร้อยเอ็ด 45150",
    },
    {
      value: "กู่กาสิงห์ เกษตรวิสัย ร้อยเอ็ด 45150",
      label: "กู่กาสิงห์ เกษตรวิสัย ร้อยเอ็ด 45150",
    },
    {
      value: "ดงครั่งน้อย เกษตรวิสัย ร้อยเอ็ด 45150",
      label: "ดงครั่งน้อย เกษตรวิสัย ร้อยเอ็ด 45150",
    },
    {
      value: "ดงครั่งใหญ่ เกษตรวิสัย ร้อยเอ็ด 45150",
      label: "ดงครั่งใหญ่ เกษตรวิสัย ร้อยเอ็ด 45150",
    },
    {
      value: "ทุ่งทอง เกษตรวิสัย ร้อยเอ็ด 45150",
      label: "ทุ่งทอง เกษตรวิสัย ร้อยเอ็ด 45150",
    },
    {
      value: "น้ำอ้อม เกษตรวิสัย ร้อยเอ็ด 45150",
      label: "น้ำอ้อม เกษตรวิสัย ร้อยเอ็ด 45150",
    },
    {
      value: "บ้านฝาง เกษตรวิสัย ร้อยเอ็ด 45150",
      label: "บ้านฝาง เกษตรวิสัย ร้อยเอ็ด 45150",
    },
    {
      value: "สิงห์โคก เกษตรวิสัย ร้อยเอ็ด 45150",
      label: "สิงห์โคก เกษตรวิสัย ร้อยเอ็ด 45150",
    },
    {
      value: "หนองแวง เกษตรวิสัย ร้อยเอ็ด 45150",
      label: "หนองแวง เกษตรวิสัย ร้อยเอ็ด 45150",
    },
    {
      value: "เกษตรวิสัย เกษตรวิสัย ร้อยเอ็ด 45150",
      label: "เกษตรวิสัย เกษตรวิสัย ร้อยเอ็ด 45150",
    },
    {
      value: "เมืองบัว เกษตรวิสัย ร้อยเอ็ด 45150",
      label: "เมืองบัว เกษตรวิสัย ร้อยเอ็ด 45150",
    },
    {
      value: "เหล่าหลวง เกษตรวิสัย ร้อยเอ็ด 45150",
      label: "เหล่าหลวง เกษตรวิสัย ร้อยเอ็ด 45150",
    },
    {
      value: "โนนสว่าง เกษตรวิสัย ร้อยเอ็ด 45150",
      label: "โนนสว่าง เกษตรวิสัย ร้อยเอ็ด 45150",
    },
    {
      value: "บ้านเขือง เชียงขวัญ ร้อยเอ็ด 45000",
      label: "บ้านเขือง เชียงขวัญ ร้อยเอ็ด 45000",
    },
    {
      value: "พระธาตุ เชียงขวัญ ร้อยเอ็ด 45000",
      label: "พระธาตุ เชียงขวัญ ร้อยเอ็ด 45000",
    },
    {
      value: "พระเจ้า เชียงขวัญ ร้อยเอ็ด 45000",
      label: "พระเจ้า เชียงขวัญ ร้อยเอ็ด 45000",
    },
    {
      value: "พลับพลา เชียงขวัญ ร้อยเอ็ด 45170",
      label: "พลับพลา เชียงขวัญ ร้อยเอ็ด 45170",
    },
    {
      value: "หมูม้น เชียงขวัญ ร้อยเอ็ด 45170",
      label: "หมูม้น เชียงขวัญ ร้อยเอ็ด 45170",
    },
    {
      value: "เชียงขวัญ เชียงขวัญ ร้อยเอ็ด 45000",
      label: "เชียงขวัญ เชียงขวัญ ร้อยเอ็ด 45000",
    },
    {
      value: "ชมสะอาด เมยวดี ร้อยเอ็ด 45250",
      label: "ชมสะอาด เมยวดี ร้อยเอ็ด 45250",
    },
    {
      value: "ชุมพร เมยวดี ร้อยเอ็ด 45250",
      label: "ชุมพร เมยวดี ร้อยเอ็ด 45250",
    },
    {
      value: "บุ่งเลิศ เมยวดี ร้อยเอ็ด 45250",
      label: "บุ่งเลิศ เมยวดี ร้อยเอ็ด 45250",
    },
    {
      value: "เมยวดี เมยวดี ร้อยเอ็ด 45250",
      label: "เมยวดี เมยวดี ร้อยเอ็ด 45250",
    },
    {
      value: "ขอนแก่น เมืองร้อยเอ็ด ร้อยเอ็ด 45000",
      label: "ขอนแก่น เมืองร้อยเอ็ด ร้อยเอ็ด 45000",
    },
    {
      value: "ดงลาน เมืองร้อยเอ็ด ร้อยเอ็ด 45000",
      label: "ดงลาน เมืองร้อยเอ็ด ร้อยเอ็ด 45000",
    },
    {
      value: "นาโพธิ์ เมืองร้อยเอ็ด ร้อยเอ็ด 45000",
      label: "นาโพธิ์ เมืองร้อยเอ็ด ร้อยเอ็ด 45000",
    },
    {
      value: "ปอภาร เมืองร้อยเอ็ด ร้อยเอ็ด 45000",
      label: "ปอภาร เมืองร้อยเอ็ด ร้อยเอ็ด 45000",
    },
    {
      value: "รอบเมือง เมืองร้อยเอ็ด ร้อยเอ็ด 45000",
      label: "รอบเมือง เมืองร้อยเอ็ด ร้อยเอ็ด 45000",
    },
    {
      value: "สะอาดสมบูรณ์ เมืองร้อยเอ็ด ร้อยเอ็ด 45000",
      label: "สะอาดสมบูรณ์ เมืองร้อยเอ็ด ร้อยเอ็ด 45000",
    },
    {
      value: "สีแก้ว เมืองร้อยเอ็ด ร้อยเอ็ด 45000",
      label: "สีแก้ว เมืองร้อยเอ็ด ร้อยเอ็ด 45000",
    },
    {
      value: "หนองแก้ว เมืองร้อยเอ็ด ร้อยเอ็ด 45000",
      label: "หนองแก้ว เมืองร้อยเอ็ด ร้อยเอ็ด 45000",
    },
    {
      value: "หนองแวง เมืองร้อยเอ็ด ร้อยเอ็ด 45000",
      label: "หนองแวง เมืองร้อยเอ็ด ร้อยเอ็ด 45000",
    },
    {
      value: "เมืองทอง เมืองร้อยเอ็ด ร้อยเอ็ด 45000",
      label: "เมืองทอง เมืองร้อยเอ็ด ร้อยเอ็ด 45000",
    },
    {
      value: "เหนือเมือง เมืองร้อยเอ็ด ร้อยเอ็ด 45000",
      label: "เหนือเมือง เมืองร้อยเอ็ด ร้อยเอ็ด 45000",
    },
    {
      value: "แคนใหญ่ เมืองร้อยเอ็ด ร้อยเอ็ด 45000",
      label: "แคนใหญ่ เมืองร้อยเอ็ด ร้อยเอ็ด 45000",
    },
    {
      value: "โนนตาล เมืองร้อยเอ็ด ร้อยเอ็ด 45000",
      label: "โนนตาล เมืองร้อยเอ็ด ร้อยเอ็ด 45000",
    },
    {
      value: "โนนรัง เมืองร้อยเอ็ด ร้อยเอ็ด 45000",
      label: "โนนรัง เมืองร้อยเอ็ด ร้อยเอ็ด 45000",
    },
    {
      value: "ในเมือง เมืองร้อยเอ็ด ร้อยเอ็ด 45000",
      label: "ในเมือง เมืองร้อยเอ็ด ร้อยเอ็ด 45000",
    },
    {
      value: "กกกุง เมืองสรวง ร้อยเอ็ด 45220",
      label: "กกกุง เมืองสรวง ร้อยเอ็ด 45220",
    },
    {
      value: "คูเมือง เมืองสรวง ร้อยเอ็ด 45220",
      label: "คูเมือง เมืองสรวง ร้อยเอ็ด 45220",
    },
    {
      value: "หนองผือ เมืองสรวง ร้อยเอ็ด 45220",
      label: "หนองผือ เมืองสรวง ร้อยเอ็ด 45220",
    },
    {
      value: "หนองหิน เมืองสรวง ร้อยเอ็ด 45220",
      label: "หนองหิน เมืองสรวง ร้อยเอ็ด 45220",
    },
    {
      value: "เมืองสรวง เมืองสรวง ร้อยเอ็ด 45220",
      label: "เมืองสรวง เมืองสรวง ร้อยเอ็ด 45220",
    },
    {
      value: "กลาง เสลภูมิ ร้อยเอ็ด 45120",
      label: "กลาง เสลภูมิ ร้อยเอ็ด 45120",
    },
    {
      value: "ขวัญเมือง เสลภูมิ ร้อยเอ็ด 45120",
      label: "ขวัญเมือง เสลภูมิ ร้อยเอ็ด 45120",
    },
    {
      value: "ขวาว เสลภูมิ ร้อยเอ็ด 45120",
      label: "ขวาว เสลภูมิ ร้อยเอ็ด 45120",
    },
    {
      value: "ท่าม่วง เสลภูมิ ร้อยเอ็ด 45120",
      label: "ท่าม่วง เสลภูมิ ร้อยเอ็ด 45120",
    },
    {
      value: "นางาม เสลภูมิ ร้อยเอ็ด 45120",
      label: "นางาม เสลภูมิ ร้อยเอ็ด 45120",
    },
    {
      value: "นาเมือง เสลภูมิ ร้อยเอ็ด 45120",
      label: "นาเมือง เสลภูมิ ร้อยเอ็ด 45120",
    },
    {
      value: "นาเลิง เสลภูมิ ร้อยเอ็ด 45120",
      label: "นาเลิง เสลภูมิ ร้อยเอ็ด 45120",
    },
    {
      value: "นาแซง เสลภูมิ ร้อยเอ็ด 45120",
      label: "นาแซง เสลภูมิ ร้อยเอ็ด 45120",
    },
    {
      value: "บึงเกลือ เสลภูมิ ร้อยเอ็ด 45120",
      label: "บึงเกลือ เสลภูมิ ร้อยเอ็ด 45120",
    },
    {
      value: "พรสวรรค์ เสลภูมิ ร้อยเอ็ด 45120",
      label: "พรสวรรค์ เสลภูมิ ร้อยเอ็ด 45120",
    },
    {
      value: "ภูเงิน เสลภูมิ ร้อยเอ็ด 45120",
      label: "ภูเงิน เสลภูมิ ร้อยเอ็ด 45120",
    },
    {
      value: "วังหลวง เสลภูมิ ร้อยเอ็ด 45120",
      label: "วังหลวง เสลภูมิ ร้อยเอ็ด 45120",
    },
    {
      value: "ศรีวิลัย เสลภูมิ ร้อยเอ็ด 45120",
      label: "ศรีวิลัย เสลภูมิ ร้อยเอ็ด 45120",
    },
    {
      value: "หนองหลวง เสลภูมิ ร้อยเอ็ด 45120",
      label: "หนองหลวง เสลภูมิ ร้อยเอ็ด 45120",
    },
    {
      value: "เกาะแก้ว เสลภูมิ ร้อยเอ็ด 45120",
      label: "เกาะแก้ว เสลภูมิ ร้อยเอ็ด 45120",
    },
    {
      value: "เมืองไพร เสลภูมิ ร้อยเอ็ด 45120",
      label: "เมืองไพร เสลภูมิ ร้อยเอ็ด 45120",
    },
    {
      value: "เหล่าน้อย เสลภูมิ ร้อยเอ็ด 45120",
      label: "เหล่าน้อย เสลภูมิ ร้อยเอ็ด 45120",
    },
    {
      value: "โพธิ์ทอง เสลภูมิ ร้อยเอ็ด 45120",
      label: "โพธิ์ทอง เสลภูมิ ร้อยเอ็ด 45120",
    },
    {
      value: "ขามเปี้ย โพธิ์ชัย ร้อยเอ็ด 45230",
      label: "ขามเปี้ย โพธิ์ชัย ร้อยเอ็ด 45230",
    },
    {
      value: "คำพอุง โพธิ์ชัย ร้อยเอ็ด 45230",
      label: "คำพอุง โพธิ์ชัย ร้อยเอ็ด 45230",
    },
    {
      value: "ดอนโอง โพธิ์ชัย ร้อยเอ็ด 45230",
      label: "ดอนโอง โพธิ์ชัย ร้อยเอ็ด 45230",
    },
    {
      value: "บัวคำ โพธิ์ชัย ร้อยเอ็ด 45230",
      label: "บัวคำ โพธิ์ชัย ร้อยเอ็ด 45230",
    },
    {
      value: "สะอาด โพธิ์ชัย ร้อยเอ็ด 45230",
      label: "สะอาด โพธิ์ชัย ร้อยเอ็ด 45230",
    },
    {
      value: "หนองตาไก้ โพธิ์ชัย ร้อยเอ็ด 45230",
      label: "หนองตาไก้ โพธิ์ชัย ร้อยเอ็ด 45230",
    },
    {
      value: "อัคคะคำ โพธิ์ชัย ร้อยเอ็ด 45230",
      label: "อัคคะคำ โพธิ์ชัย ร้อยเอ็ด 45230",
    },
    {
      value: "เชียงใหม่ โพธิ์ชัย ร้อยเอ็ด 45230",
      label: "เชียงใหม่ โพธิ์ชัย ร้อยเอ็ด 45230",
    },
    {
      value: "โพธิ์ศรี โพธิ์ชัย ร้อยเอ็ด 45230",
      label: "โพธิ์ศรี โพธิ์ชัย ร้อยเอ็ด 45230",
    },
    {
      value: "ท่าหาดยาว โพนทราย ร้อยเอ็ด 45240",
      label: "ท่าหาดยาว โพนทราย ร้อยเอ็ด 45240",
    },
    {
      value: "ยางคำ โพนทราย ร้อยเอ็ด 45240",
      label: "ยางคำ โพนทราย ร้อยเอ็ด 45240",
    },
    {
      value: "ศรีสว่าง โพนทราย ร้อยเอ็ด 45240",
      label: "ศรีสว่าง โพนทราย ร้อยเอ็ด 45240",
    },
    {
      value: "สามขา โพนทราย ร้อยเอ็ด 45240",
      label: "สามขา โพนทราย ร้อยเอ็ด 45240",
    },
    {
      value: "โพนทราย โพนทราย ร้อยเอ็ด 45240",
      label: "โพนทราย โพนทราย ร้อยเอ็ด 45240",
    },
    {
      value: "คำนาดี โพนทอง ร้อยเอ็ด 45110",
      label: "คำนาดี โพนทอง ร้อยเอ็ด 45110",
    },
    {
      value: "นาอุดม โพนทอง ร้อยเอ็ด 45110",
      label: "นาอุดม โพนทอง ร้อยเอ็ด 45110",
    },
    {
      value: "พรมสวรรค์ โพนทอง ร้อยเอ็ด 45110",
      label: "พรมสวรรค์ โพนทอง ร้อยเอ็ด 45110",
    },
    {
      value: "วังสามัคคี โพนทอง ร้อยเอ็ด 45110",
      label: "วังสามัคคี โพนทอง ร้อยเอ็ด 45110",
    },
    {
      value: "สระนกแก้ว โพนทอง ร้อยเอ็ด 45110",
      label: "สระนกแก้ว โพนทอง ร้อยเอ็ด 45110",
    },
    {
      value: "สว่าง โพนทอง ร้อยเอ็ด 45110",
      label: "สว่าง โพนทอง ร้อยเอ็ด 45110",
    },
    {
      value: "หนองใหญ่ โพนทอง ร้อยเอ็ด 45110",
      label: "หนองใหญ่ โพนทอง ร้อยเอ็ด 45110",
    },
    {
      value: "อุ่มเม่า โพนทอง ร้อยเอ็ด 45110",
      label: "อุ่มเม่า โพนทอง ร้อยเอ็ด 45110",
    },
    { value: "แวง โพนทอง ร้อยเอ็ด 45110", label: "แวง โพนทอง ร้อยเอ็ด 45110" },
    {
      value: "โคกกกม่วง โพนทอง ร้อยเอ็ด 45110",
      label: "โคกกกม่วง โพนทอง ร้อยเอ็ด 45110",
    },
    {
      value: "โคกสูง โพนทอง ร้อยเอ็ด 45110",
      label: "โคกสูง โพนทอง ร้อยเอ็ด 45110",
    },
    {
      value: "โนนชัยศรี โพนทอง ร้อยเอ็ด 45110",
      label: "โนนชัยศรี โพนทอง ร้อยเอ็ด 45110",
    },
    {
      value: "โพธิ์ทอง โพนทอง ร้อยเอ็ด 45110",
      label: "โพธิ์ทอง โพนทอง ร้อยเอ็ด 45110",
    },
    {
      value: "โพธิ์ศรีสว่าง โพนทอง ร้อยเอ็ด 45110",
      label: "โพธิ์ศรีสว่าง โพนทอง ร้อยเอ็ด 45110",
    },
    { value: "กกสะทอน ด่านซ้าย เลย 42120", label: "กกสะทอน ด่านซ้าย เลย 42120" },
    {
      value: "ด่านซ้าย ด่านซ้าย เลย 42120",
      label: "ด่านซ้าย ด่านซ้าย เลย 42120",
    },
    { value: "นาดี ด่านซ้าย เลย 42120", label: "นาดี ด่านซ้าย เลย 42120" },
    { value: "นาหอ ด่านซ้าย เลย 42120", label: "นาหอ ด่านซ้าย เลย 42120" },
    { value: "ปากหมัน ด่านซ้าย เลย 42120", label: "ปากหมัน ด่านซ้าย เลย 42120" },
    { value: "วังยาว ด่านซ้าย เลย 42120", label: "วังยาว ด่านซ้าย เลย 42120" },
    { value: "อิปุ่ม ด่านซ้าย เลย 42120", label: "อิปุ่ม ด่านซ้าย เลย 42120" },
    { value: "โคกงาม ด่านซ้าย เลย 42120", label: "โคกงาม ด่านซ้าย เลย 42120" },
    { value: "โป่ง ด่านซ้าย เลย 42120", label: "โป่ง ด่านซ้าย เลย 42120" },
    { value: "โพนสูง ด่านซ้าย เลย 42120", label: "โพนสูง ด่านซ้าย เลย 42120" },
    { value: "ท่าลี่ ท่าลี่ เลย 42140", label: "ท่าลี่ ท่าลี่ เลย 42140" },
    { value: "น้ำทูน ท่าลี่ เลย 42140", label: "น้ำทูน ท่าลี่ เลย 42140" },
    { value: "น้ำแคม ท่าลี่ เลย 42140", label: "น้ำแคม ท่าลี่ เลย 42140" },
    { value: "หนองผือ ท่าลี่ เลย 42140", label: "หนองผือ ท่าลี่ เลย 42140" },
    { value: "อาฮี ท่าลี่ เลย 42140", label: "อาฮี ท่าลี่ เลย 42140" },
    { value: "โคกใหญ่ ท่าลี่ เลย 42140", label: "โคกใหญ่ ท่าลี่ เลย 42140" },
    { value: "ท่าสวรรค์ นาด้วง เลย 42210", label: "ท่าสวรรค์ นาด้วง เลย 42210" },
    { value: "ท่าสะอาด นาด้วง เลย 42210", label: "ท่าสะอาด นาด้วง เลย 42210" },
    { value: "นาดอกคำ นาด้วง เลย 42210", label: "นาดอกคำ นาด้วง เลย 42210" },
    { value: "นาด้วง นาด้วง เลย 42210", label: "นาด้วง นาด้วง เลย 42210" },
    { value: "นาพึง นาแห้ว เลย 42170", label: "นาพึง นาแห้ว เลย 42170" },
    { value: "นามาลา นาแห้ว เลย 42170", label: "นามาลา นาแห้ว เลย 42170" },
    { value: "นาแห้ว นาแห้ว เลย 42170", label: "นาแห้ว นาแห้ว เลย 42170" },
    { value: "เหล่ากอหก นาแห้ว เลย 42170", label: "เหล่ากอหก นาแห้ว เลย 42170" },
    { value: "แสงภา นาแห้ว เลย 42170", label: "แสงภา นาแห้ว เลย 42170" },
    { value: "ชมเจริญ ปากชม เลย 42150", label: "ชมเจริญ ปากชม เลย 42150" },
    { value: "ปากชม ปากชม เลย 42150", label: "ปากชม ปากชม เลย 42150" },
    { value: "หาดคัมภีร์ ปากชม เลย 42150", label: "หาดคัมภีร์ ปากชม เลย 42150" },
    { value: "ห้วยบ่อซืน ปากชม เลย 42150", label: "ห้วยบ่อซืน ปากชม เลย 42150" },
    { value: "ห้วยพิชัย ปากชม เลย 42150", label: "ห้วยพิชัย ปากชม เลย 42150" },
    { value: "เชียงกลม ปากชม เลย 42150", label: "เชียงกลม ปากชม เลย 42150" },
    {
      value: "ท่าช้างคล้อง ผาขาว เลย 42240",
      label: "ท่าช้างคล้อง ผาขาว เลย 42240",
    },
    { value: "บ้านเพิ่ม ผาขาว เลย 42240", label: "บ้านเพิ่ม ผาขาว เลย 42240" },
    { value: "ผาขาว ผาขาว เลย 42240", label: "ผาขาว ผาขาว เลย 42240" },
    { value: "โนนปอแดง ผาขาว เลย 42240", label: "โนนปอแดง ผาขาว เลย 42240" },
    { value: "โนนป่าซาง ผาขาว เลย 42240", label: "โนนป่าซาง ผาขาว เลย 42240" },
    {
      value: "ผานกเค้า ภูกระดึง เลย 42180",
      label: "ผานกเค้า ภูกระดึง เลย 42180",
    },
    {
      value: "ภูกระดึง ภูกระดึง เลย 42180",
      label: "ภูกระดึง ภูกระดึง เลย 42180",
    },
    { value: "ศรีฐาน ภูกระดึง เลย 42180", label: "ศรีฐาน ภูกระดึง เลย 42180" },
    { value: "ห้วยส้ม ภูกระดึง เลย 42180", label: "ห้วยส้ม ภูกระดึง เลย 42180" },
    { value: "ภูหอ ภูหลวง เลย 42230", label: "ภูหอ ภูหลวง เลย 42230" },
    { value: "หนองคัน ภูหลวง เลย 42230", label: "หนองคัน ภูหลวง เลย 42230" },
    {
      value: "ห้วยสีเสียด ภูหลวง เลย 42230",
      label: "ห้วยสีเสียด ภูหลวง เลย 42230",
    },
    {
      value: "เลยวังไสย์ ภูหลวง เลย 42230",
      label: "เลยวังไสย์ ภูหลวง เลย 42230",
    },
    {
      value: "แก่งศรีภูมิ ภูหลวง เลย 42230",
      label: "แก่งศรีภูมิ ภูหลวง เลย 42230",
    },
    { value: "ท่าศาลา ภูเรือ เลย 42160", label: "ท่าศาลา ภูเรือ เลย 42160" },
    { value: "ปลาบ่า ภูเรือ เลย 42160", label: "ปลาบ่า ภูเรือ เลย 42160" },
    { value: "ร่องจิก ภูเรือ เลย 42160", label: "ร่องจิก ภูเรือ เลย 42160" },
    { value: "ลาดค่าง ภูเรือ เลย 42160", label: "ลาดค่าง ภูเรือ เลย 42160" },
    { value: "สานตม ภูเรือ เลย 42160", label: "สานตม ภูเรือ เลย 42160" },
    { value: "หนองบัว ภูเรือ เลย 42160", label: "หนองบัว ภูเรือ เลย 42160" },
    { value: "ทรายขาว วังสะพุง เลย 42130", label: "ทรายขาว วังสะพุง เลย 42130" },
    { value: "ปากปวน วังสะพุง เลย 42130", label: "ปากปวน วังสะพุง เลย 42130" },
    { value: "ผาน้อย วังสะพุง เลย 42130", label: "ผาน้อย วังสะพุง เลย 42130" },
    { value: "ผาบิ้ง วังสะพุง เลย 42130", label: "ผาบิ้ง วังสะพุง เลย 42130" },
    {
      value: "วังสะพุง วังสะพุง เลย 42130",
      label: "วังสะพุง วังสะพุง เลย 42130",
    },
    {
      value: "ศรีสงคราม วังสะพุง เลย 42130",
      label: "ศรีสงคราม วังสะพุง เลย 42130",
    },
    {
      value: "หนองงิ้ว วังสะพุง เลย 42130",
      label: "หนองงิ้ว วังสะพุง เลย 42130",
    },
    {
      value: "หนองหญ้าปล้อง วังสะพุง เลย 42130",
      label: "หนองหญ้าปล้อง วังสะพุง เลย 42130",
    },
    { value: "เขาหลวง วังสะพุง เลย 42130", label: "เขาหลวง วังสะพุง เลย 42130" },
    {
      value: "โคกขมิ้น วังสะพุง เลย 42130",
      label: "โคกขมิ้น วังสะพุง เลย 42130",
    },
    { value: "ตาดข่า หนองหิน เลย 42190", label: "ตาดข่า หนองหิน เลย 42190" },
    { value: "ปวนพุ หนองหิน เลย 42190", label: "ปวนพุ หนองหิน เลย 42190" },
    { value: "หนองหิน หนองหิน เลย 42190", label: "หนองหิน หนองหิน เลย 42190" },
    { value: "จอมศรี เชียงคาน เลย 42110", label: "จอมศรี เชียงคาน เลย 42110" },
    { value: "ธาตุ เชียงคาน เลย 42110", label: "ธาตุ เชียงคาน เลย 42110" },
    { value: "นาซ่าว เชียงคาน เลย 42110", label: "นาซ่าว เชียงคาน เลย 42110" },
    { value: "บุฮม เชียงคาน เลย 42110", label: "บุฮม เชียงคาน เลย 42110" },
    { value: "ปากตม เชียงคาน เลย 42110", label: "ปากตม เชียงคาน เลย 42110" },
    {
      value: "หาดทรายขาว เชียงคาน เลย 42110",
      label: "หาดทรายขาว เชียงคาน เลย 42110",
    },
    { value: "เขาแก้ว เชียงคาน เลย 42110", label: "เขาแก้ว เชียงคาน เลย 42110" },
    {
      value: "เชียงคาน เชียงคาน เลย 42110",
      label: "เชียงคาน เชียงคาน เลย 42110",
    },
    { value: "กกดู่ เมืองเลย เลย 42000", label: "กกดู่ เมืองเลย เลย 42000" },
    { value: "กกทอง เมืองเลย เลย 42000", label: "กกทอง เมืองเลย เลย 42000" },
    { value: "กุดป่อง เมืองเลย เลย 42000", label: "กุดป่อง เมืองเลย เลย 42000" },
    {
      value: "ชัยพฤกษ์ เมืองเลย เลย 42000",
      label: "ชัยพฤกษ์ เมืองเลย เลย 42000",
    },
    { value: "นาดินดำ เมืองเลย เลย 42000", label: "นาดินดำ เมืองเลย เลย 42000" },
    { value: "นาอาน เมืองเลย เลย 42000", label: "นาอาน เมืองเลย เลย 42000" },
    { value: "นาอ้อ เมืองเลย เลย 42100", label: "นาอ้อ เมืองเลย เลย 42100" },
    { value: "นาแขม เมืองเลย เลย 42000", label: "นาแขม เมืองเลย เลย 42000" },
    { value: "นาโป่ง เมืองเลย เลย 42000", label: "นาโป่ง เมืองเลย เลย 42000" },
    { value: "น้ำสวย เมืองเลย เลย 42000", label: "น้ำสวย เมืองเลย เลย 42000" },
    { value: "น้ำหมาน เมืองเลย เลย 42000", label: "น้ำหมาน เมืองเลย เลย 42000" },
    {
      value: "ศรีสองรัก เมืองเลย เลย 42100",
      label: "ศรีสองรัก เมืองเลย เลย 42100",
    },
    { value: "เมือง เมืองเลย เลย 42000", label: "เมือง เมืองเลย เลย 42000" },
    { value: "เสี้ยว เมืองเลย เลย 42000", label: "เสี้ยว เมืองเลย เลย 42000" },
    {
      value: "ทรัพย์ไพวัลย์ เอราวัณ เลย 42220",
      label: "ทรัพย์ไพวัลย์ เอราวัณ เลย 42220",
    },
    { value: "ผาสามยอด เอราวัณ เลย 42220", label: "ผาสามยอด เอราวัณ เลย 42220" },
    {
      value: "ผาอินทร์แปลง เอราวัณ เลย 42220",
      label: "ผาอินทร์แปลง เอราวัณ เลย 42220",
    },
    { value: "เอราวัณ เอราวัณ เลย 42220", label: "เอราวัณ เอราวัณ เลย 42220" },
    { value: "กุดบาก กุดบาก สกลนคร 47180", label: "กุดบาก กุดบาก สกลนคร 47180" },
    { value: "กุดไห กุดบาก สกลนคร 47180", label: "กุดไห กุดบาก สกลนคร 47180" },
    { value: "นาม่อง กุดบาก สกลนคร 47180", label: "นาม่อง กุดบาก สกลนคร 47180" },
    {
      value: "กุสุมาลย์ กุสุมาลย์ สกลนคร 47210",
      label: "กุสุมาลย์ กุสุมาลย์ สกลนคร 47210",
    },
    {
      value: "นาเพียง กุสุมาลย์ สกลนคร 47230",
      label: "นาเพียง กุสุมาลย์ สกลนคร 47230",
    },
    {
      value: "นาโพธิ์ กุสุมาลย์ สกลนคร 47210",
      label: "นาโพธิ์ กุสุมาลย์ สกลนคร 47210",
    },
    {
      value: "อุ่มจาน กุสุมาลย์ สกลนคร 47230",
      label: "อุ่มจาน กุสุมาลย์ สกลนคร 47230",
    },
    {
      value: "โพธิ์ไพศาล กุสุมาลย์ สกลนคร 47210",
      label: "โพธิ์ไพศาล กุสุมาลย์ สกลนคร 47210",
    },
    {
      value: "คำตากล้า คำตากล้า สกลนคร 47250",
      label: "คำตากล้า คำตากล้า สกลนคร 47250",
    },
    {
      value: "นาแต้ คำตากล้า สกลนคร 47250",
      label: "นาแต้ คำตากล้า สกลนคร 47250",
    },
    {
      value: "หนองบัวสิม คำตากล้า สกลนคร 47250",
      label: "หนองบัวสิม คำตากล้า สกลนคร 47250",
    },
    { value: "แพด คำตากล้า สกลนคร 47250", label: "แพด คำตากล้า สกลนคร 47250" },
    {
      value: "นิคมน้ำอูน นิคมน้ำอูน สกลนคร 47270",
      label: "นิคมน้ำอูน นิคมน้ำอูน สกลนคร 47270",
    },
    {
      value: "สุวรรณคาม นิคมน้ำอูน สกลนคร 47270",
      label: "สุวรรณคาม นิคมน้ำอูน สกลนคร 47270",
    },
    {
      value: "หนองบัว นิคมน้ำอูน สกลนคร 47270",
      label: "หนองบัว นิคมน้ำอูน สกลนคร 47270",
    },
    {
      value: "หนองปลิง นิคมน้ำอูน สกลนคร 47270",
      label: "หนองปลิง นิคมน้ำอูน สกลนคร 47270",
    },
    {
      value: "ดงหม้อทอง บ้านม่วง สกลนคร 47140",
      label: "ดงหม้อทอง บ้านม่วง สกลนคร 47140",
    },
    {
      value: "ดงหม้อทองใต้ บ้านม่วง สกลนคร 47140",
      label: "ดงหม้อทองใต้ บ้านม่วง สกลนคร 47140",
    },
    {
      value: "ดงเหนือ บ้านม่วง สกลนคร 47140",
      label: "ดงเหนือ บ้านม่วง สกลนคร 47140",
    },
    {
      value: "บ่อแก้ว บ้านม่วง สกลนคร 47140",
      label: "บ่อแก้ว บ้านม่วง สกลนคร 47140",
    },
    { value: "มาย บ้านม่วง สกลนคร 47140", label: "มาย บ้านม่วง สกลนคร 47140" },
    { value: "ม่วง บ้านม่วง สกลนคร 47140", label: "ม่วง บ้านม่วง สกลนคร 47140" },
    {
      value: "หนองกวั่ง บ้านม่วง สกลนคร 47140",
      label: "หนองกวั่ง บ้านม่วง สกลนคร 47140",
    },
    {
      value: "ห้วยหลัว บ้านม่วง สกลนคร 47140",
      label: "ห้วยหลัว บ้านม่วง สกลนคร 47140",
    },
    {
      value: "โนนสะอาด บ้านม่วง สกลนคร 47140",
      label: "โนนสะอาด บ้านม่วง สกลนคร 47140",
    },
    {
      value: "ช้างมิ่ง พรรณานิคม สกลนคร 47130",
      label: "ช้างมิ่ง พรรณานิคม สกลนคร 47130",
    },
    {
      value: "นาหัวบ่อ พรรณานิคม สกลนคร 47220",
      label: "นาหัวบ่อ พรรณานิคม สกลนคร 47220",
    },
    {
      value: "นาใน พรรณานิคม สกลนคร 47130",
      label: "นาใน พรรณานิคม สกลนคร 47130",
    },
    {
      value: "บะฮี พรรณานิคม สกลนคร 47130",
      label: "บะฮี พรรณานิคม สกลนคร 47130",
    },
    {
      value: "พรรณา พรรณานิคม สกลนคร 47130",
      label: "พรรณา พรรณานิคม สกลนคร 47130",
    },
    {
      value: "พอกน้อย พรรณานิคม สกลนคร 47220",
      label: "พอกน้อย พรรณานิคม สกลนคร 47220",
    },
    {
      value: "วังยาง พรรณานิคม สกลนคร 47130",
      label: "วังยาง พรรณานิคม สกลนคร 47130",
    },
    {
      value: "สว่าง พรรณานิคม สกลนคร 47130",
      label: "สว่าง พรรณานิคม สกลนคร 47130",
    },
    {
      value: "เชิงชุม พรรณานิคม สกลนคร 47130",
      label: "เชิงชุม พรรณานิคม สกลนคร 47130",
    },
    { value: "ไร่ พรรณานิคม สกลนคร 47130", label: "ไร่ พรรณานิคม สกลนคร 47130" },
    {
      value: "ต้นผึ้ง พังโคน สกลนคร 47160",
      label: "ต้นผึ้ง พังโคน สกลนคร 47160",
    },
    { value: "พังโคน พังโคน สกลนคร 47160", label: "พังโคน พังโคน สกลนคร 47160" },
    {
      value: "ม่วงไข่ พังโคน สกลนคร 47160",
      label: "ม่วงไข่ พังโคน สกลนคร 47160",
    },
    { value: "แร่ พังโคน สกลนคร 47160", label: "แร่ พังโคน สกลนคร 47160" },
    {
      value: "ไฮหย่อง พังโคน สกลนคร 47160",
      label: "ไฮหย่อง พังโคน สกลนคร 47160",
    },
    {
      value: "กกปลาซิว ภูพาน สกลนคร 47180",
      label: "กกปลาซิว ภูพาน สกลนคร 47180",
    },
    {
      value: "สร้างค้อ ภูพาน สกลนคร 47180",
      label: "สร้างค้อ ภูพาน สกลนคร 47180",
    },
    { value: "หลุบเลา ภูพาน สกลนคร 47180", label: "หลุบเลา ภูพาน สกลนคร 47180" },
    { value: "โคกภู ภูพาน สกลนคร 47180", label: "โคกภู ภูพาน สกลนคร 47180" },
    {
      value: "กุดเรือคำ วานรนิวาส สกลนคร 47120",
      label: "กุดเรือคำ วานรนิวาส สกลนคร 47120",
    },
    {
      value: "ขัวก่าย วานรนิวาส สกลนคร 47120",
      label: "ขัวก่าย วานรนิวาส สกลนคร 47120",
    },
    {
      value: "คอนสวรรค์ วานรนิวาส สกลนคร 47120",
      label: "คอนสวรรค์ วานรนิวาส สกลนคร 47120",
    },
    {
      value: "คูสะคาม วานรนิวาส สกลนคร 47120",
      label: "คูสะคาม วานรนิวาส สกลนคร 47120",
    },
    {
      value: "ธาตุ วานรนิวาส สกลนคร 47120",
      label: "ธาตุ วานรนิวาส สกลนคร 47120",
    },
    {
      value: "นาคำ วานรนิวาส สกลนคร 47120",
      label: "นาคำ วานรนิวาส สกลนคร 47120",
    },
    {
      value: "นาซอ วานรนิวาส สกลนคร 47120",
      label: "นาซอ วานรนิวาส สกลนคร 47120",
    },
    {
      value: "วานรนิวาส วานรนิวาส สกลนคร 47120",
      label: "วานรนิวาส วานรนิวาส สกลนคร 47120",
    },
    {
      value: "ศรีวิชัย วานรนิวาส สกลนคร 47120",
      label: "ศรีวิชัย วานรนิวาส สกลนคร 47120",
    },
    {
      value: "หนองสนม วานรนิวาส สกลนคร 47120",
      label: "หนองสนม วานรนิวาส สกลนคร 47120",
    },
    {
      value: "หนองแวง วานรนิวาส สกลนคร 47120",
      label: "หนองแวง วานรนิวาส สกลนคร 47120",
    },
    {
      value: "หนองแวงใต้ วานรนิวาส สกลนคร 47120",
      label: "หนองแวงใต้ วานรนิวาส สกลนคร 47120",
    },
    {
      value: "อินทร์แปลง วานรนิวาส สกลนคร 47120",
      label: "อินทร์แปลง วานรนิวาส สกลนคร 47120",
    },
    {
      value: "เดื่อศรีคันไชย วานรนิวาส สกลนคร 47120",
      label: "เดื่อศรีคันไชย วานรนิวาส สกลนคร 47120",
    },
    {
      value: "คำบ่อ วาริชภูมิ สกลนคร 47150",
      label: "คำบ่อ วาริชภูมิ สกลนคร 47150",
    },
    {
      value: "ค้อเขียว วาริชภูมิ สกลนคร 47150",
      label: "ค้อเขียว วาริชภูมิ สกลนคร 47150",
    },
    {
      value: "ปลาโหล วาริชภูมิ สกลนคร 47150",
      label: "ปลาโหล วาริชภูมิ สกลนคร 47150",
    },
    {
      value: "วาริชภูมิ วาริชภูมิ สกลนคร 47150",
      label: "วาริชภูมิ วาริชภูมิ สกลนคร 47150",
    },
    {
      value: "หนองลาด วาริชภูมิ สกลนคร 47150",
      label: "หนองลาด วาริชภูมิ สกลนคร 47150",
    },
    {
      value: "คำสะอาด สว่างแดนดิน สกลนคร 47110",
      label: "คำสะอาด สว่างแดนดิน สกลนคร 47110",
    },
    {
      value: "ค้อใต้ สว่างแดนดิน สกลนคร 47110",
      label: "ค้อใต้ สว่างแดนดิน สกลนคร 47110",
    },
    {
      value: "ตาลเนิ้ง สว่างแดนดิน สกลนคร 47240",
      label: "ตาลเนิ้ง สว่างแดนดิน สกลนคร 47240",
    },
    {
      value: "ตาลโกน สว่างแดนดิน สกลนคร 47240",
      label: "ตาลโกน สว่างแดนดิน สกลนคร 47240",
    },
    {
      value: "ทรายมูล สว่างแดนดิน สกลนคร 47110",
      label: "ทรายมูล สว่างแดนดิน สกลนคร 47110",
    },
    {
      value: "ธาตุทอง สว่างแดนดิน สกลนคร 47240",
      label: "ธาตุทอง สว่างแดนดิน สกลนคร 47240",
    },
    {
      value: "บงเหนือ สว่างแดนดิน สกลนคร 47110",
      label: "บงเหนือ สว่างแดนดิน สกลนคร 47110",
    },
    {
      value: "บงใต้ สว่างแดนดิน สกลนคร 47110",
      label: "บงใต้ สว่างแดนดิน สกลนคร 47110",
    },
    {
      value: "บ้านต้าย สว่างแดนดิน สกลนคร 47110",
      label: "บ้านต้าย สว่างแดนดิน สกลนคร 47110",
    },
    {
      value: "บ้านถ่อน สว่างแดนดิน สกลนคร 47110",
      label: "บ้านถ่อน สว่างแดนดิน สกลนคร 47110",
    },
    {
      value: "พันนา สว่างแดนดิน สกลนคร 47240",
      label: "พันนา สว่างแดนดิน สกลนคร 47240",
    },
    {
      value: "สว่างแดนดิน สว่างแดนดิน สกลนคร 47110",
      label: "สว่างแดนดิน สว่างแดนดิน สกลนคร 47110",
    },
    {
      value: "หนองหลวง สว่างแดนดิน สกลนคร 47110",
      label: "หนองหลวง สว่างแดนดิน สกลนคร 47110",
    },
    {
      value: "แวง สว่างแดนดิน สกลนคร 47240",
      label: "แวง สว่างแดนดิน สกลนคร 47240",
    },
    {
      value: "โคกสี สว่างแดนดิน สกลนคร 47110",
      label: "โคกสี สว่างแดนดิน สกลนคร 47110",
    },
    {
      value: "โพนสูง สว่างแดนดิน สกลนคร 47110",
      label: "โพนสูง สว่างแดนดิน สกลนคร 47110",
    },
    {
      value: "ท่าศิลา ส่องดาว สกลนคร 47190",
      label: "ท่าศิลา ส่องดาว สกลนคร 47190",
    },
    {
      value: "ปทุมวาปี ส่องดาว สกลนคร 47190",
      label: "ปทุมวาปี ส่องดาว สกลนคร 47190",
    },
    { value: "วัฒนา ส่องดาว สกลนคร 47190", label: "วัฒนา ส่องดาว สกลนคร 47190" },
    {
      value: "ส่องดาว ส่องดาว สกลนคร 47190",
      label: "ส่องดาว ส่องดาว สกลนคร 47190",
    },
    {
      value: "ท่าก้อน อากาศอำนวย สกลนคร 47170",
      label: "ท่าก้อน อากาศอำนวย สกลนคร 47170",
    },
    {
      value: "นาฮี อากาศอำนวย สกลนคร 47170",
      label: "นาฮี อากาศอำนวย สกลนคร 47170",
    },
    {
      value: "บะหว้า อากาศอำนวย สกลนคร 47170",
      label: "บะหว้า อากาศอำนวย สกลนคร 47170",
    },
    {
      value: "วาใหญ่ อากาศอำนวย สกลนคร 47170",
      label: "วาใหญ่ อากาศอำนวย สกลนคร 47170",
    },
    {
      value: "สามัคคีพัฒนา อากาศอำนวย สกลนคร 47170",
      label: "สามัคคีพัฒนา อากาศอำนวย สกลนคร 47170",
    },
    {
      value: "อากาศ อากาศอำนวย สกลนคร 47170",
      label: "อากาศ อากาศอำนวย สกลนคร 47170",
    },
    {
      value: "โพนงาม อากาศอำนวย สกลนคร 47170",
      label: "โพนงาม อากาศอำนวย สกลนคร 47170",
    },
    {
      value: "โพนแพง อากาศอำนวย สกลนคร 47170",
      label: "โพนแพง อากาศอำนวย สกลนคร 47170",
    },
    {
      value: "ทุ่งแก เจริญศิลป์ สกลนคร 47290",
      label: "ทุ่งแก เจริญศิลป์ สกลนคร 47290",
    },
    {
      value: "บ้านเหล่า เจริญศิลป์ สกลนคร 47290",
      label: "บ้านเหล่า เจริญศิลป์ สกลนคร 47290",
    },
    {
      value: "หนองแปน เจริญศิลป์ สกลนคร 47290",
      label: "หนองแปน เจริญศิลป์ สกลนคร 47290",
    },
    {
      value: "เจริญศิลป์ เจริญศิลป์ สกลนคร 47290",
      label: "เจริญศิลป์ เจริญศิลป์ สกลนคร 47290",
    },
    {
      value: "โคกศิลา เจริญศิลป์ สกลนคร 47290",
      label: "โคกศิลา เจริญศิลป์ สกลนคร 47290",
    },
    {
      value: "จันทร์เพ็ญ เต่างอย สกลนคร 47260",
      label: "จันทร์เพ็ญ เต่างอย สกลนคร 47260",
    },
    { value: "นาตาล เต่างอย สกลนคร 47260", label: "นาตาล เต่างอย สกลนคร 47260" },
    {
      value: "บึงทวาย เต่างอย สกลนคร 47260",
      label: "บึงทวาย เต่างอย สกลนคร 47260",
    },
    {
      value: "เต่างอย เต่างอย สกลนคร 47260",
      label: "เต่างอย เต่างอย สกลนคร 47260",
    },
    {
      value: "ขมิ้น เมืองสกลนคร สกลนคร 47220",
      label: "ขมิ้น เมืองสกลนคร สกลนคร 47220",
    },
    {
      value: "งิ้วด่อน เมืองสกลนคร สกลนคร 47000",
      label: "งิ้วด่อน เมืองสกลนคร สกลนคร 47000",
    },
    {
      value: "ดงชน เมืองสกลนคร สกลนคร 47000",
      label: "ดงชน เมืองสกลนคร สกลนคร 47000",
    },
    {
      value: "ดงมะไฟ เมืองสกลนคร สกลนคร 47000",
      label: "ดงมะไฟ เมืองสกลนคร สกลนคร 47000",
    },
    {
      value: "ท่าแร่ เมืองสกลนคร สกลนคร 47230",
      label: "ท่าแร่ เมืองสกลนคร สกลนคร 47230",
    },
    {
      value: "ธาตุนาเวง เมืองสกลนคร สกลนคร 47000",
      label: "ธาตุนาเวง เมืองสกลนคร สกลนคร 47000",
    },
    {
      value: "ธาตุเชิงชุม เมืองสกลนคร สกลนคร 47000",
      label: "ธาตุเชิงชุม เมืองสกลนคร สกลนคร 47000",
    },
    {
      value: "พังขว้าง เมืองสกลนคร สกลนคร 47000",
      label: "พังขว้าง เมืองสกลนคร สกลนคร 47000",
    },
    {
      value: "ม่วงลาย เมืองสกลนคร สกลนคร 47000",
      label: "ม่วงลาย เมืองสกลนคร สกลนคร 47000",
    },
    {
      value: "หนองลาด เมืองสกลนคร สกลนคร 47220",
      label: "หนองลาด เมืองสกลนคร สกลนคร 47220",
    },
    {
      value: "ห้วยยาง เมืองสกลนคร สกลนคร 47000",
      label: "ห้วยยาง เมืองสกลนคร สกลนคร 47000",
    },
    {
      value: "ฮางโฮง เมืองสกลนคร สกลนคร 47000",
      label: "ฮางโฮง เมืองสกลนคร สกลนคร 47000",
    },
    {
      value: "เชียงเครือ เมืองสกลนคร สกลนคร 47000",
      label: "เชียงเครือ เมืองสกลนคร สกลนคร 47000",
    },
    {
      value: "เหล่าปอแดง เมืองสกลนคร สกลนคร 47000",
      label: "เหล่าปอแดง เมืองสกลนคร สกลนคร 47000",
    },
    {
      value: "โคกก่อง เมืองสกลนคร สกลนคร 47000",
      label: "โคกก่อง เมืองสกลนคร สกลนคร 47000",
    },
    {
      value: "โนนหอม เมืองสกลนคร สกลนคร 47000",
      label: "โนนหอม เมืองสกลนคร สกลนคร 47000",
    },
    {
      value: "ด่านม่วงคำ โคกศรีสุพรรณ สกลนคร 47280",
      label: "ด่านม่วงคำ โคกศรีสุพรรณ สกลนคร 47280",
    },
    {
      value: "ตองโขบ โคกศรีสุพรรณ สกลนคร 47280",
      label: "ตองโขบ โคกศรีสุพรรณ สกลนคร 47280",
    },
    {
      value: "เหล่าโพนค้อ โคกศรีสุพรรณ สกลนคร 47280",
      label: "เหล่าโพนค้อ โคกศรีสุพรรณ สกลนคร 47280",
    },
    {
      value: "แมดนาท่ม โคกศรีสุพรรณ สกลนคร 47280",
      label: "แมดนาท่ม โคกศรีสุพรรณ สกลนคร 47280",
    },
    {
      value: "นาตงวัฒนา โพนนาแก้ว สกลนคร 47230",
      label: "นาตงวัฒนา โพนนาแก้ว สกลนคร 47230",
    },
    {
      value: "นาแก้ว โพนนาแก้ว สกลนคร 47230",
      label: "นาแก้ว โพนนาแก้ว สกลนคร 47230",
    },
    {
      value: "บ้านแป้น โพนนาแก้ว สกลนคร 47230",
      label: "บ้านแป้น โพนนาแก้ว สกลนคร 47230",
    },
    {
      value: "บ้านโพน โพนนาแก้ว สกลนคร 47230",
      label: "บ้านโพน โพนนาแก้ว สกลนคร 47230",
    },
    {
      value: "เชียงสือ โพนนาแก้ว สกลนคร 47230",
      label: "เชียงสือ โพนนาแก้ว สกลนคร 47230",
    },
    {
      value: "กาบเชิง กาบเชิง สุรินทร์ 32210",
      label: "กาบเชิง กาบเชิง สุรินทร์ 32210",
    },
    {
      value: "คูตัน กาบเชิง สุรินทร์ 32210",
      label: "คูตัน กาบเชิง สุรินทร์ 32210",
    },
    {
      value: "ด่าน กาบเชิง สุรินทร์ 32210",
      label: "ด่าน กาบเชิง สุรินทร์ 32210",
    },
    {
      value: "ตะเคียน กาบเชิง สุรินทร์ 32210",
      label: "ตะเคียน กาบเชิง สุรินทร์ 32210",
    },
    {
      value: "แนงมุด กาบเชิง สุรินทร์ 32210",
      label: "แนงมุด กาบเชิง สุรินทร์ 32210",
    },
    {
      value: "โคกตะเคียน กาบเชิง สุรินทร์ 32210",
      label: "โคกตะเคียน กาบเชิง สุรินทร์ 32210",
    },
    {
      value: "กระหาด จอมพระ สุรินทร์ 32180",
      label: "กระหาด จอมพระ สุรินทร์ 32180",
    },
    {
      value: "จอมพระ จอมพระ สุรินทร์ 32180",
      label: "จอมพระ จอมพระ สุรินทร์ 32180",
    },
    {
      value: "ชุมแสง จอมพระ สุรินทร์ 32180",
      label: "ชุมแสง จอมพระ สุรินทร์ 32180",
    },
    {
      value: "บุแกรง จอมพระ สุรินทร์ 32180",
      label: "บุแกรง จอมพระ สุรินทร์ 32180",
    },
    {
      value: "บ้านผือ จอมพระ สุรินทร์ 32180",
      label: "บ้านผือ จอมพระ สุรินทร์ 32180",
    },
    {
      value: "ลุ่มระวี จอมพระ สุรินทร์ 32180",
      label: "ลุ่มระวี จอมพระ สุรินทร์ 32180",
    },
    {
      value: "หนองสนิท จอมพระ สุรินทร์ 32180",
      label: "หนองสนิท จอมพระ สุรินทร์ 32180",
    },
    {
      value: "เป็นสุข จอมพระ สุรินทร์ 32180",
      label: "เป็นสุข จอมพระ สุรินทร์ 32180",
    },
    {
      value: "เมืองลีง จอมพระ สุรินทร์ 32180",
      label: "เมืองลีง จอมพระ สุรินทร์ 32180",
    },
    {
      value: "กระเบื้อง ชุมพลบุรี สุรินทร์ 32190",
      label: "กระเบื้อง ชุมพลบุรี สุรินทร์ 32190",
    },
    {
      value: "ชุมพลบุรี ชุมพลบุรี สุรินทร์ 32190",
      label: "ชุมพลบุรี ชุมพลบุรี สุรินทร์ 32190",
    },
    {
      value: "นาหนองไผ่ ชุมพลบุรี สุรินทร์ 32190",
      label: "นาหนองไผ่ ชุมพลบุรี สุรินทร์ 32190",
    },
    {
      value: "ยะวึก ชุมพลบุรี สุรินทร์ 32190",
      label: "ยะวึก ชุมพลบุรี สุรินทร์ 32190",
    },
    {
      value: "ศรีณรงค์ ชุมพลบุรี สุรินทร์ 32190",
      label: "ศรีณรงค์ ชุมพลบุรี สุรินทร์ 32190",
    },
    {
      value: "สระขุด ชุมพลบุรี สุรินทร์ 32190",
      label: "สระขุด ชุมพลบุรี สุรินทร์ 32190",
    },
    {
      value: "หนองเรือ ชุมพลบุรี สุรินทร์ 32190",
      label: "หนองเรือ ชุมพลบุรี สุรินทร์ 32190",
    },
    {
      value: "เมืองบัว ชุมพลบุรี สุรินทร์ 32190",
      label: "เมืองบัว ชุมพลบุรี สุรินทร์ 32190",
    },
    {
      value: "ไพรขลา ชุมพลบุรี สุรินทร์ 32190",
      label: "ไพรขลา ชุมพลบุรี สุรินทร์ 32190",
    },
    {
      value: "กระโพ ท่าตูม สุรินทร์ 32120",
      label: "กระโพ ท่าตูม สุรินทร์ 32120",
    },
    {
      value: "ทุ่งกุลา ท่าตูม สุรินทร์ 32120",
      label: "ทุ่งกุลา ท่าตูม สุรินทร์ 32120",
    },
    {
      value: "ท่าตูม ท่าตูม สุรินทร์ 32120",
      label: "ท่าตูม ท่าตูม สุรินทร์ 32120",
    },
    { value: "บะ ท่าตูม สุรินทร์ 32120", label: "บะ ท่าตูม สุรินทร์ 32120" },
    {
      value: "บัวโคก ท่าตูม สุรินทร์ 32120",
      label: "บัวโคก ท่าตูม สุรินทร์ 32120",
    },
    {
      value: "พรมเทพ ท่าตูม สุรินทร์ 32120",
      label: "พรมเทพ ท่าตูม สุรินทร์ 32120",
    },
    {
      value: "หนองบัว ท่าตูม สุรินทร์ 32120",
      label: "หนองบัว ท่าตูม สุรินทร์ 32120",
    },
    {
      value: "หนองเมธี ท่าตูม สุรินทร์ 32120",
      label: "หนองเมธี ท่าตูม สุรินทร์ 32120",
    },
    {
      value: "เมืองแก ท่าตูม สุรินทร์ 32120",
      label: "เมืองแก ท่าตูม สุรินทร์ 32120",
    },
    {
      value: "โพนครก ท่าตูม สุรินทร์ 32120",
      label: "โพนครก ท่าตูม สุรินทร์ 32120",
    },
    { value: "จรัส บัวเชด สุรินทร์ 32230", label: "จรัส บัวเชด สุรินทร์ 32230" },
    {
      value: "ตาวัง บัวเชด สุรินทร์ 32230",
      label: "ตาวัง บัวเชด สุรินทร์ 32230",
    },
    {
      value: "บัวเชด บัวเชด สุรินทร์ 32230",
      label: "บัวเชด บัวเชด สุรินทร์ 32230",
    },
    {
      value: "สะเดา บัวเชด สุรินทร์ 32230",
      label: "สะเดา บัวเชด สุรินทร์ 32230",
    },
    {
      value: "สำเภาลูน บัวเชด สุรินทร์ 32230",
      label: "สำเภาลูน บัวเชด สุรินทร์ 32230",
    },
    {
      value: "อาโพน บัวเชด สุรินทร์ 32230",
      label: "อาโพน บัวเชด สุรินทร์ 32230",
    },
    {
      value: "กังแอน ปราสาท สุรินทร์ 32140",
      label: "กังแอน ปราสาท สุรินทร์ 32140",
    },
    {
      value: "กันตวจระมวล ปราสาท สุรินทร์ 32140",
      label: "กันตวจระมวล ปราสาท สุรินทร์ 32140",
    },
    { value: "ตานี ปราสาท สุรินทร์ 32140", label: "ตานี ปราสาท สุรินทร์ 32140" },
    {
      value: "ตาเบา ปราสาท สุรินทร์ 32140",
      label: "ตาเบา ปราสาท สุรินทร์ 32140",
    },
    { value: "ทมอ ปราสาท สุรินทร์ 32140", label: "ทมอ ปราสาท สุรินทร์ 32140" },
    {
      value: "ทุ่งมน ปราสาท สุรินทร์ 32140",
      label: "ทุ่งมน ปราสาท สุรินทร์ 32140",
    },
    {
      value: "บ้านพลวง ปราสาท สุรินทร์ 32140",
      label: "บ้านพลวง ปราสาท สุรินทร์ 32140",
    },
    {
      value: "บ้านไทร ปราสาท สุรินทร์ 32140",
      label: "บ้านไทร ปราสาท สุรินทร์ 32140",
    },
    {
      value: "ประทัดบุ ปราสาท สุรินทร์ 32140",
      label: "ประทัดบุ ปราสาท สุรินทร์ 32140",
    },
    {
      value: "ปราสาททนง ปราสาท สุรินทร์ 32140",
      label: "ปราสาททนง ปราสาท สุรินทร์ 32140",
    },
    { value: "ปรือ ปราสาท สุรินทร์ 32140", label: "ปรือ ปราสาท สุรินทร์ 32140" },
    { value: "สมุด ปราสาท สุรินทร์ 32140", label: "สมุด ปราสาท สุรินทร์ 32140" },
    {
      value: "หนองใหญ่ ปราสาท สุรินทร์ 32140",
      label: "หนองใหญ่ ปราสาท สุรินทร์ 32140",
    },
    {
      value: "เชื้อเพลิง ปราสาท สุรินทร์ 32140",
      label: "เชื้อเพลิง ปราสาท สุรินทร์ 32140",
    },
    {
      value: "โคกยาง ปราสาท สุรินทร์ 32140",
      label: "โคกยาง ปราสาท สุรินทร์ 32140",
    },
    {
      value: "โคกสะอาด ปราสาท สุรินทร์ 32140",
      label: "โคกสะอาด ปราสาท สุรินทร์ 32140",
    },
    {
      value: "โชคนาสาม ปราสาท สุรินทร์ 32140",
      label: "โชคนาสาม ปราสาท สุรินทร์ 32140",
    },
    { value: "ไพล ปราสาท สุรินทร์ 32140", label: "ไพล ปราสาท สุรินทร์ 32140" },
    {
      value: "จีกแดก พนมดงรัก สุรินทร์ 32140",
      label: "จีกแดก พนมดงรัก สุรินทร์ 32140",
    },
    {
      value: "ตาเมียง พนมดงรัก สุรินทร์ 32140",
      label: "ตาเมียง พนมดงรัก สุรินทร์ 32140",
    },
    {
      value: "บักได พนมดงรัก สุรินทร์ 32140",
      label: "บักได พนมดงรัก สุรินทร์ 32140",
    },
    {
      value: "โคกกลาง พนมดงรัก สุรินทร์ 32140",
      label: "โคกกลาง พนมดงรัก สุรินทร์ 32140",
    },
    {
      value: "กุดขาคีม รัตนบุรี สุรินทร์ 32130",
      label: "กุดขาคีม รัตนบุรี สุรินทร์ 32130",
    },
    {
      value: "ดอนแรด รัตนบุรี สุรินทร์ 32130",
      label: "ดอนแรด รัตนบุรี สุรินทร์ 32130",
    },
    {
      value: "ทับใหญ่ รัตนบุรี สุรินทร์ 32130",
      label: "ทับใหญ่ รัตนบุรี สุรินทร์ 32130",
    },
    {
      value: "ธาตุ รัตนบุรี สุรินทร์ 32130",
      label: "ธาตุ รัตนบุรี สุรินทร์ 32130",
    },
    {
      value: "น้ำเขียว รัตนบุรี สุรินทร์ 32130",
      label: "น้ำเขียว รัตนบุรี สุรินทร์ 32130",
    },
    {
      value: "ยางสว่าง รัตนบุรี สุรินทร์ 32130",
      label: "ยางสว่าง รัตนบุรี สุรินทร์ 32130",
    },
    {
      value: "รัตนบุรี รัตนบุรี สุรินทร์ 32130",
      label: "รัตนบุรี รัตนบุรี สุรินทร์ 32130",
    },
    {
      value: "หนองบัวทอง รัตนบุรี สุรินทร์ 32130",
      label: "หนองบัวทอง รัตนบุรี สุรินทร์ 32130",
    },
    {
      value: "หนองบัวบาน รัตนบุรี สุรินทร์ 32130",
      label: "หนองบัวบาน รัตนบุรี สุรินทร์ 32130",
    },
    {
      value: "เบิด รัตนบุรี สุรินทร์ 32130",
      label: "เบิด รัตนบุรี สุรินทร์ 32130",
    },
    { value: "แก รัตนบุรี สุรินทร์ 32130", label: "แก รัตนบุรี สุรินทร์ 32130" },
    {
      value: "ไผ่ รัตนบุรี สุรินทร์ 32130",
      label: "ไผ่ รัตนบุรี สุรินทร์ 32130",
    },
    {
      value: "ตระเปียงเตีย ลำดวน สุรินทร์ 32220",
      label: "ตระเปียงเตีย ลำดวน สุรินทร์ 32220",
    },
    { value: "ตรำดม ลำดวน สุรินทร์ 32220", label: "ตรำดม ลำดวน สุรินทร์ 32220" },
    { value: "ลำดวน ลำดวน สุรินทร์ 32220", label: "ลำดวน ลำดวน สุรินทร์ 32220" },
    {
      value: "อู่โลก ลำดวน สุรินทร์ 32220",
      label: "อู่โลก ลำดวน สุรินทร์ 32220",
    },
    {
      value: "โชคเหนือ ลำดวน สุรินทร์ 32220",
      label: "โชคเหนือ ลำดวน สุรินทร์ 32220",
    },
    {
      value: "ณรงค์ ศรีณรงค์ สุรินทร์ 32150",
      label: "ณรงค์ ศรีณรงค์ สุรินทร์ 32150",
    },
    {
      value: "ตรวจ ศรีณรงค์ สุรินทร์ 32150",
      label: "ตรวจ ศรีณรงค์ สุรินทร์ 32150",
    },
    {
      value: "ศรีสุข ศรีณรงค์ สุรินทร์ 32150",
      label: "ศรีสุข ศรีณรงค์ สุรินทร์ 32150",
    },
    {
      value: "หนองแวง ศรีณรงค์ สุรินทร์ 32150",
      label: "หนองแวง ศรีณรงค์ สุรินทร์ 32150",
    },
    {
      value: "แจนแวน ศรีณรงค์ สุรินทร์ 32150",
      label: "แจนแวน ศรีณรงค์ สุรินทร์ 32150",
    },
    {
      value: "กุดหวาย ศีขรภูมิ สุรินทร์ 32110",
      label: "กุดหวาย ศีขรภูมิ สุรินทร์ 32110",
    },
    {
      value: "ขวาวใหญ่ ศีขรภูมิ สุรินทร์ 32110",
      label: "ขวาวใหญ่ ศีขรภูมิ สุรินทร์ 32110",
    },
    {
      value: "คาละแมะ ศีขรภูมิ สุรินทร์ 32110",
      label: "คาละแมะ ศีขรภูมิ สุรินทร์ 32110",
    },
    {
      value: "จารพัต ศีขรภูมิ สุรินทร์ 32110",
      label: "จารพัต ศีขรภูมิ สุรินทร์ 32110",
    },
    {
      value: "ช่างปี่ ศีขรภูมิ สุรินทร์ 32110",
      label: "ช่างปี่ ศีขรภูมิ สุรินทร์ 32110",
    },
    {
      value: "ตรมไพร ศีขรภูมิ สุรินทร์ 32110",
      label: "ตรมไพร ศีขรภูมิ สุรินทร์ 32110",
    },
    {
      value: "ตรึม ศีขรภูมิ สุรินทร์ 32110",
      label: "ตรึม ศีขรภูมิ สุรินทร์ 32110",
    },
    {
      value: "นารุ่ง ศีขรภูมิ สุรินทร์ 32110",
      label: "นารุ่ง ศีขรภูมิ สุรินทร์ 32110",
    },
    {
      value: "ผักไหม ศีขรภูมิ สุรินทร์ 32110",
      label: "ผักไหม ศีขรภูมิ สุรินทร์ 32110",
    },
    {
      value: "ยาง ศีขรภูมิ สุรินทร์ 32110",
      label: "ยาง ศีขรภูมิ สุรินทร์ 32110",
    },
    {
      value: "ระแงง ศีขรภูมิ สุรินทร์ 32110",
      label: "ระแงง ศีขรภูมิ สุรินทร์ 32110",
    },
    {
      value: "หนองขวาว ศีขรภูมิ สุรินทร์ 32110",
      label: "หนองขวาว ศีขรภูมิ สุรินทร์ 32110",
    },
    {
      value: "หนองบัว ศีขรภูมิ สุรินทร์ 32110",
      label: "หนองบัว ศีขรภูมิ สุรินทร์ 32110",
    },
    {
      value: "หนองเหล็ก ศีขรภูมิ สุรินทร์ 32110",
      label: "หนองเหล็ก ศีขรภูมิ สุรินทร์ 32110",
    },
    {
      value: "แตล ศีขรภูมิ สุรินทร์ 32110",
      label: "แตล ศีขรภูมิ สุรินทร์ 32110",
    },
    { value: "นานวน สนม สุรินทร์ 32160", label: "นานวน สนม สุรินทร์ 32160" },
    { value: "สนม สนม สุรินทร์ 32160", label: "สนม สนม สุรินทร์ 32160" },
    {
      value: "หนองระฆัง สนม สุรินทร์ 32160",
      label: "หนองระฆัง สนม สุรินทร์ 32160",
    },
    {
      value: "หนองอียอ สนม สุรินทร์ 32160",
      label: "หนองอียอ สนม สุรินทร์ 32160",
    },
    { value: "หัวงัว สนม สุรินทร์ 32160", label: "หัวงัว สนม สุรินทร์ 32160" },
    { value: "แคน สนม สุรินทร์ 32160", label: "แคน สนม สุรินทร์ 32160" },
    { value: "โพนโก สนม สุรินทร์ 32160", label: "โพนโก สนม สุรินทร์ 32160" },
    {
      value: "กระเทียม สังขะ สุรินทร์ 32150",
      label: "กระเทียม สังขะ สุรินทร์ 32150",
    },
    {
      value: "ขอนแตก สังขะ สุรินทร์ 32150",
      label: "ขอนแตก สังขะ สุรินทร์ 32150",
    },
    { value: "ดม สังขะ สุรินทร์ 32150", label: "ดม สังขะ สุรินทร์ 32150" },
    { value: "ตาคง สังขะ สุรินทร์ 32150", label: "ตาคง สังขะ สุรินทร์ 32150" },
    { value: "ตาตุม สังขะ สุรินทร์ 32150", label: "ตาตุม สังขะ สุรินทร์ 32150" },
    {
      value: "ทับทัน สังขะ สุรินทร์ 32150",
      label: "ทับทัน สังขะ สุรินทร์ 32150",
    },
    {
      value: "บ้านจารย์ สังขะ สุรินทร์ 32150",
      label: "บ้านจารย์ สังขะ สุรินทร์ 32150",
    },
    {
      value: "บ้านชบ สังขะ สุรินทร์ 32150",
      label: "บ้านชบ สังขะ สุรินทร์ 32150",
    },
    {
      value: "พระแก้ว สังขะ สุรินทร์ 32150",
      label: "พระแก้ว สังขะ สุรินทร์ 32150",
    },
    { value: "สะกาด สังขะ สุรินทร์ 32150", label: "สะกาด สังขะ สุรินทร์ 32150" },
    { value: "สังขะ สังขะ สุรินทร์ 32150", label: "สังขะ สังขะ สุรินทร์ 32150" },
    {
      value: "เทพรักษา สังขะ สุรินทร์ 32150",
      label: "เทพรักษา สังขะ สุรินทร์ 32150",
    },
    {
      value: "กระออม สำโรงทาบ สุรินทร์ 32170",
      label: "กระออม สำโรงทาบ สุรินทร์ 32170",
    },
    {
      value: "ประดู่ สำโรงทาบ สุรินทร์ 32170",
      label: "ประดู่ สำโรงทาบ สุรินทร์ 32170",
    },
    {
      value: "ศรีสุข สำโรงทาบ สุรินทร์ 32170",
      label: "ศรีสุข สำโรงทาบ สุรินทร์ 32170",
    },
    {
      value: "สะโน สำโรงทาบ สุรินทร์ 32170",
      label: "สะโน สำโรงทาบ สุรินทร์ 32170",
    },
    {
      value: "สำโรงทาบ สำโรงทาบ สุรินทร์ 32170",
      label: "สำโรงทาบ สำโรงทาบ สุรินทร์ 32170",
    },
    {
      value: "หนองฮะ สำโรงทาบ สุรินทร์ 32170",
      label: "หนองฮะ สำโรงทาบ สุรินทร์ 32170",
    },
    {
      value: "หนองไผ่ล้อม สำโรงทาบ สุรินทร์ 32170",
      label: "หนองไผ่ล้อม สำโรงทาบ สุรินทร์ 32170",
    },
    {
      value: "หมื่นศรี สำโรงทาบ สุรินทร์ 32170",
      label: "หมื่นศรี สำโรงทาบ สุรินทร์ 32170",
    },
    {
      value: "เกาะแก้ว สำโรงทาบ สุรินทร์ 32170",
      label: "เกาะแก้ว สำโรงทาบ สุรินทร์ 32170",
    },
    {
      value: "เสม็จ สำโรงทาบ สุรินทร์ 32170",
      label: "เสม็จ สำโรงทาบ สุรินทร์ 32170",
    },
    {
      value: "ตากูก เขวาสินรินทร์ สุรินทร์ 32000",
      label: "ตากูก เขวาสินรินทร์ สุรินทร์ 32000",
    },
    {
      value: "บึง เขวาสินรินทร์ สุรินทร์ 32000",
      label: "บึง เขวาสินรินทร์ สุรินทร์ 32000",
    },
    {
      value: "บ้านแร่ เขวาสินรินทร์ สุรินทร์ 32000",
      label: "บ้านแร่ เขวาสินรินทร์ สุรินทร์ 32000",
    },
    {
      value: "ปราสาททอง เขวาสินรินทร์ สุรินทร์ 32000",
      label: "ปราสาททอง เขวาสินรินทร์ สุรินทร์ 32000",
    },
    {
      value: "เขวาสินรินทร์ เขวาสินรินทร์ สุรินทร์ 32000",
      label: "เขวาสินรินทร์ เขวาสินรินทร์ สุรินทร์ 32000",
    },
    {
      value: "กาเกาะ เมืองสุรินทร์ สุรินทร์ 32000",
      label: "กาเกาะ เมืองสุรินทร์ สุรินทร์ 32000",
    },
    {
      value: "คอโค เมืองสุรินทร์ สุรินทร์ 32000",
      label: "คอโค เมืองสุรินทร์ สุรินทร์ 32000",
    },
    {
      value: "ตระแสง เมืองสุรินทร์ สุรินทร์ 32000",
      label: "ตระแสง เมืองสุรินทร์ สุรินทร์ 32000",
    },
    {
      value: "ตั้งใจ เมืองสุรินทร์ สุรินทร์ 32000",
      label: "ตั้งใจ เมืองสุรินทร์ สุรินทร์ 32000",
    },
    {
      value: "ตาอ็อง เมืองสุรินทร์ สุรินทร์ 32000",
      label: "ตาอ็อง เมืองสุรินทร์ สุรินทร์ 32000",
    },
    {
      value: "ท่าสว่าง เมืองสุรินทร์ สุรินทร์ 32000",
      label: "ท่าสว่าง เมืองสุรินทร์ สุรินทร์ 32000",
    },
    {
      value: "นอกเมือง เมืองสุรินทร์ สุรินทร์ 32000",
      label: "นอกเมือง เมืองสุรินทร์ สุรินทร์ 32000",
    },
    {
      value: "นาดี เมืองสุรินทร์ สุรินทร์ 32000",
      label: "นาดี เมืองสุรินทร์ สุรินทร์ 32000",
    },
    {
      value: "นาบัว เมืองสุรินทร์ สุรินทร์ 32000",
      label: "นาบัว เมืองสุรินทร์ สุรินทร์ 32000",
    },
    {
      value: "บุฤาษี เมืองสุรินทร์ สุรินทร์ 32000",
      label: "บุฤาษี เมืองสุรินทร์ สุรินทร์ 32000",
    },
    {
      value: "ราม เมืองสุรินทร์ สุรินทร์ 32000",
      label: "ราม เมืองสุรินทร์ สุรินทร์ 32000",
    },
    {
      value: "สลักได เมืองสุรินทร์ สุรินทร์ 32000",
      label: "สลักได เมืองสุรินทร์ สุรินทร์ 32000",
    },
    {
      value: "สวาย เมืองสุรินทร์ สุรินทร์ 32000",
      label: "สวาย เมืองสุรินทร์ สุรินทร์ 32000",
    },
    {
      value: "สำโรง เมืองสุรินทร์ สุรินทร์ 32000",
      label: "สำโรง เมืองสุรินทร์ สุรินทร์ 32000",
    },
    {
      value: "เฉนียง เมืองสุรินทร์ สุรินทร์ 32000",
      label: "เฉนียง เมืองสุรินทร์ สุรินทร์ 32000",
    },
    {
      value: "เทนมีย์ เมืองสุรินทร์ สุรินทร์ 32000",
      label: "เทนมีย์ เมืองสุรินทร์ สุรินทร์ 32000",
    },
    {
      value: "เพี้ยราม เมืองสุรินทร์ สุรินทร์ 32000",
      label: "เพี้ยราม เมืองสุรินทร์ สุรินทร์ 32000",
    },
    {
      value: "เมืองที เมืองสุรินทร์ สุรินทร์ 32000",
      label: "เมืองที เมืองสุรินทร์ สุรินทร์ 32000",
    },
    {
      value: "แกใหญ่ เมืองสุรินทร์ สุรินทร์ 32000",
      label: "แกใหญ่ เมืองสุรินทร์ สุรินทร์ 32000",
    },
    {
      value: "แสลงพันธ์ เมืองสุรินทร์ สุรินทร์ 32000",
      label: "แสลงพันธ์ เมืองสุรินทร์ สุรินทร์ 32000",
    },
    {
      value: "ในเมือง เมืองสุรินทร์ สุรินทร์ 32000",
      label: "ในเมือง เมืองสุรินทร์ สุรินทร์ 32000",
    },
    {
      value: "คำผง โนนนารายณ์ สุรินทร์ 32130",
      label: "คำผง โนนนารายณ์ สุรินทร์ 32130",
    },
    {
      value: "ระเวียง โนนนารายณ์ สุรินทร์ 32130",
      label: "ระเวียง โนนนารายณ์ สุรินทร์ 32130",
    },
    {
      value: "หนองหลวง โนนนารายณ์ สุรินทร์ 32130",
      label: "หนองหลวง โนนนารายณ์ สุรินทร์ 32130",
    },
    {
      value: "หนองเทพ โนนนารายณ์ สุรินทร์ 32130",
      label: "หนองเทพ โนนนารายณ์ สุรินทร์ 32130",
    },
    {
      value: "โนน โนนนารายณ์ สุรินทร์ 32130",
      label: "โนน โนนนารายณ์ สุรินทร์ 32130",
    },
    {
      value: "กองนาง ท่าบ่อ หนองคาย 43110",
      label: "กองนาง ท่าบ่อ หนองคาย 43110",
    },
    {
      value: "ท่าบ่อ ท่าบ่อ หนองคาย 43110",
      label: "ท่าบ่อ ท่าบ่อ หนองคาย 43110",
    },
    { value: "นาข่า ท่าบ่อ หนองคาย 43110", label: "นาข่า ท่าบ่อ หนองคาย 43110" },
    {
      value: "น้ำโมง ท่าบ่อ หนองคาย 43110",
      label: "น้ำโมง ท่าบ่อ หนองคาย 43110",
    },
    {
      value: "บ้านถ่อน ท่าบ่อ หนองคาย 43110",
      label: "บ้านถ่อน ท่าบ่อ หนองคาย 43110",
    },
    {
      value: "บ้านว่าน ท่าบ่อ หนองคาย 43110",
      label: "บ้านว่าน ท่าบ่อ หนองคาย 43110",
    },
    {
      value: "บ้านเดื่อ ท่าบ่อ หนองคาย 43110",
      label: "บ้านเดื่อ ท่าบ่อ หนองคาย 43110",
    },
    {
      value: "หนองนาง ท่าบ่อ หนองคาย 43110",
      label: "หนองนาง ท่าบ่อ หนองคาย 43110",
    },
    {
      value: "โคกคอน ท่าบ่อ หนองคาย 43110",
      label: "โคกคอน ท่าบ่อ หนองคาย 43110",
    },
    { value: "โพนสา ท่าบ่อ หนองคาย 43110", label: "โพนสา ท่าบ่อ หนองคาย 43110" },
    {
      value: "นาทับไฮ รัตนวาปี หนองคาย 43120",
      label: "นาทับไฮ รัตนวาปี หนองคาย 43120",
    },
    {
      value: "บ้านต้อน รัตนวาปี หนองคาย 43120",
      label: "บ้านต้อน รัตนวาปี หนองคาย 43120",
    },
    {
      value: "พระบาทนาสิงห์ รัตนวาปี หนองคาย 43120",
      label: "พระบาทนาสิงห์ รัตนวาปี หนองคาย 43120",
    },
    {
      value: "รัตนวาปี รัตนวาปี หนองคาย 43120",
      label: "รัตนวาปี รัตนวาปี หนองคาย 43120",
    },
    {
      value: "โพนแพง รัตนวาปี หนองคาย 43120",
      label: "โพนแพง รัตนวาปี หนองคาย 43120",
    },
    {
      value: "บ้านหม้อ ศรีเชียงใหม่ หนองคาย 43130",
      label: "บ้านหม้อ ศรีเชียงใหม่ หนองคาย 43130",
    },
    {
      value: "พระพุทธบาท ศรีเชียงใหม่ หนองคาย 43130",
      label: "พระพุทธบาท ศรีเชียงใหม่ หนองคาย 43130",
    },
    {
      value: "พานพร้าว ศรีเชียงใหม่ หนองคาย 43130",
      label: "พานพร้าว ศรีเชียงใหม่ หนองคาย 43130",
    },
    {
      value: "หนองปลาปาก ศรีเชียงใหม่ หนองคาย 43130",
      label: "หนองปลาปาก ศรีเชียงใหม่ หนองคาย 43130",
    },
    {
      value: "คอกช้าง สระใคร หนองคาย 43100",
      label: "คอกช้าง สระใคร หนองคาย 43100",
    },
    {
      value: "บ้านฝาง สระใคร หนองคาย 43100",
      label: "บ้านฝาง สระใคร หนองคาย 43100",
    },
    {
      value: "สระใคร สระใคร หนองคาย 43100",
      label: "สระใคร สระใคร หนองคาย 43100",
    },
    { value: "นางิ้ว สังคม หนองคาย 43160", label: "นางิ้ว สังคม หนองคาย 43160" },
    {
      value: "บ้านม่วง สังคม หนองคาย 43160",
      label: "บ้านม่วง สังคม หนองคาย 43160",
    },
    { value: "ผาตั้ง สังคม หนองคาย 43160", label: "ผาตั้ง สังคม หนองคาย 43160" },
    { value: "สังคม สังคม หนองคาย 43160", label: "สังคม สังคม หนองคาย 43160" },
    {
      value: "แก้งไก่ สังคม หนองคาย 43160",
      label: "แก้งไก่ สังคม หนองคาย 43160",
    },
    { value: "นาดี เฝ้าไร่ หนองคาย 43120", label: "นาดี เฝ้าไร่ หนองคาย 43120" },
    {
      value: "วังหลวง เฝ้าไร่ หนองคาย 43120",
      label: "วังหลวง เฝ้าไร่ หนองคาย 43120",
    },
    {
      value: "หนองหลวง เฝ้าไร่ หนองคาย 43120",
      label: "หนองหลวง เฝ้าไร่ หนองคาย 43120",
    },
    {
      value: "อุดมพร เฝ้าไร่ หนองคาย 43120",
      label: "อุดมพร เฝ้าไร่ หนองคาย 43120",
    },
    {
      value: "เฝ้าไร่ เฝ้าไร่ หนองคาย 43120",
      label: "เฝ้าไร่ เฝ้าไร่ หนองคาย 43120",
    },
    {
      value: "กวนวัน เมืองหนองคาย หนองคาย 43000",
      label: "กวนวัน เมืองหนองคาย หนองคาย 43000",
    },
    {
      value: "ค่ายบกหวาน เมืองหนองคาย หนองคาย 43100",
      label: "ค่ายบกหวาน เมืองหนองคาย หนองคาย 43100",
    },
    {
      value: "บ้านเดื่อ เมืองหนองคาย หนองคาย 43000",
      label: "บ้านเดื่อ เมืองหนองคาย หนองคาย 43000",
    },
    {
      value: "ปะโค เมืองหนองคาย หนองคาย 43000",
      label: "ปะโค เมืองหนองคาย หนองคาย 43000",
    },
    {
      value: "พระธาตุบังพวน เมืองหนองคาย หนองคาย 43100",
      label: "พระธาตุบังพวน เมืองหนองคาย หนองคาย 43100",
    },
    {
      value: "มีชัย เมืองหนองคาย หนองคาย 43000",
      label: "มีชัย เมืองหนองคาย หนองคาย 43000",
    },
    {
      value: "วัดธาตุ เมืองหนองคาย หนองคาย 43000",
      label: "วัดธาตุ เมืองหนองคาย หนองคาย 43000",
    },
    {
      value: "สองห้อง เมืองหนองคาย หนองคาย 43100",
      label: "สองห้อง เมืองหนองคาย หนองคาย 43100",
    },
    {
      value: "สีกาย เมืองหนองคาย หนองคาย 43000",
      label: "สีกาย เมืองหนองคาย หนองคาย 43000",
    },
    {
      value: "หนองกอมเกาะ เมืองหนองคาย หนองคาย 43000",
      label: "หนองกอมเกาะ เมืองหนองคาย หนองคาย 43000",
    },
    {
      value: "หาดคำ เมืองหนองคาย หนองคาย 43000",
      label: "หาดคำ เมืองหนองคาย หนองคาย 43000",
    },
    {
      value: "หินโงม เมืองหนองคาย หนองคาย 43000",
      label: "หินโงม เมืองหนองคาย หนองคาย 43000",
    },
    {
      value: "เมืองหมี เมืองหนองคาย หนองคาย 43000",
      label: "เมืองหมี เมืองหนองคาย หนองคาย 43000",
    },
    {
      value: "เวียงคุก เมืองหนองคาย หนองคาย 43000",
      label: "เวียงคุก เมืองหนองคาย หนองคาย 43000",
    },
    {
      value: "โพธิ์ชัย เมืองหนองคาย หนองคาย 43000",
      label: "โพธิ์ชัย เมืองหนองคาย หนองคาย 43000",
    },
    {
      value: "ในเมือง เมืองหนองคาย หนองคาย 43000",
      label: "ในเมือง เมืองหนองคาย หนองคาย 43000",
    },
    {
      value: "ด่านศรีสุข โพธิ์ตาก หนองคาย 43130",
      label: "ด่านศรีสุข โพธิ์ตาก หนองคาย 43130",
    },
    {
      value: "โพธิ์ตาก โพธิ์ตาก หนองคาย 43130",
      label: "โพธิ์ตาก โพธิ์ตาก หนองคาย 43130",
    },
    {
      value: "โพนทอง โพธิ์ตาก หนองคาย 43130",
      label: "โพนทอง โพธิ์ตาก หนองคาย 43130",
    },
    {
      value: "กุดบง โพนพิสัย หนองคาย 43120",
      label: "กุดบง โพนพิสัย หนองคาย 43120",
    },
    {
      value: "จุมพล โพนพิสัย หนองคาย 43120",
      label: "จุมพล โพนพิสัย หนองคาย 43120",
    },
    {
      value: "ชุมช้าง โพนพิสัย หนองคาย 43120",
      label: "ชุมช้าง โพนพิสัย หนองคาย 43120",
    },
    {
      value: "ทุ่งหลวง โพนพิสัย หนองคาย 43120",
      label: "ทุ่งหลวง โพนพิสัย หนองคาย 43120",
    },
    {
      value: "นาหนัง โพนพิสัย หนองคาย 43120",
      label: "นาหนัง โพนพิสัย หนองคาย 43120",
    },
    {
      value: "บ้านผือ โพนพิสัย หนองคาย 43120",
      label: "บ้านผือ โพนพิสัย หนองคาย 43120",
    },
    {
      value: "บ้านโพธิ์ โพนพิสัย หนองคาย 43120",
      label: "บ้านโพธิ์ โพนพิสัย หนองคาย 43120",
    },
    {
      value: "วัดหลวง โพนพิสัย หนองคาย 43120",
      label: "วัดหลวง โพนพิสัย หนองคาย 43120",
    },
    {
      value: "สร้างนางขาว โพนพิสัย หนองคาย 43120",
      label: "สร้างนางขาว โพนพิสัย หนองคาย 43120",
    },
    {
      value: "เซิม โพนพิสัย หนองคาย 43120",
      label: "เซิม โพนพิสัย หนองคาย 43120",
    },
    {
      value: "เหล่าต่างคำ โพนพิสัย หนองคาย 43120",
      label: "เหล่าต่างคำ โพนพิสัย หนองคาย 43120",
    },
    {
      value: "กุดดินจี่ นากลาง หนองบัวลำภู 39350",
      label: "กุดดินจี่ นากลาง หนองบัวลำภู 39350",
    },
    {
      value: "กุดแห่ นากลาง หนองบัวลำภู 39170",
      label: "กุดแห่ นากลาง หนองบัวลำภู 39170",
    },
    {
      value: "ดงสวรรค์ นากลาง หนองบัวลำภู 39350",
      label: "ดงสวรรค์ นากลาง หนองบัวลำภู 39350",
    },
    {
      value: "ด่านช้าง นากลาง หนองบัวลำภู 39170",
      label: "ด่านช้าง นากลาง หนองบัวลำภู 39170",
    },
    {
      value: "นากลาง นากลาง หนองบัวลำภู 39170",
      label: "นากลาง นากลาง หนองบัวลำภู 39170",
    },
    {
      value: "ฝั่งแดง นากลาง หนองบัวลำภู 39170",
      label: "ฝั่งแดง นากลาง หนองบัวลำภู 39170",
    },
    {
      value: "อุทัยสวรรค์ นากลาง หนองบัวลำภู 39170",
      label: "อุทัยสวรรค์ นากลาง หนองบัวลำภู 39170",
    },
    {
      value: "เก่ากลอย นากลาง หนองบัวลำภู 39350",
      label: "เก่ากลอย นากลาง หนองบัวลำภู 39350",
    },
    {
      value: "โนนเมือง นากลาง หนองบัวลำภู 39170",
      label: "โนนเมือง นากลาง หนองบัวลำภู 39170",
    },
    {
      value: "นาเหล่า นาวัง หนองบัวลำภู 39170",
      label: "นาเหล่า นาวัง หนองบัวลำภู 39170",
    },
    {
      value: "นาแก นาวัง หนองบัวลำภู 39170",
      label: "นาแก นาวัง หนองบัวลำภู 39170",
    },
    {
      value: "วังทอง นาวัง หนองบัวลำภู 39170",
      label: "วังทอง นาวัง หนองบัวลำภู 39170",
    },
    {
      value: "วังปลาป้อม นาวัง หนองบัวลำภู 39170",
      label: "วังปลาป้อม นาวัง หนองบัวลำภู 39170",
    },
    {
      value: "เทพคีรี นาวัง หนองบัวลำภู 39170",
      label: "เทพคีรี นาวัง หนองบัวลำภู 39170",
    },
    {
      value: "กุดสะเทียน ศรีบุญเรือง หนองบัวลำภู 39180",
      label: "กุดสะเทียน ศรีบุญเรือง หนองบัวลำภู 39180",
    },
    {
      value: "ทรายทอง ศรีบุญเรือง หนองบัวลำภู 39180",
      label: "ทรายทอง ศรีบุญเรือง หนองบัวลำภู 39180",
    },
    {
      value: "นากอก ศรีบุญเรือง หนองบัวลำภู 39180",
      label: "นากอก ศรีบุญเรือง หนองบัวลำภู 39180",
    },
    {
      value: "ยางหล่อ ศรีบุญเรือง หนองบัวลำภู 39180",
      label: "ยางหล่อ ศรีบุญเรือง หนองบัวลำภู 39180",
    },
    {
      value: "ศรีบุญเรือง ศรีบุญเรือง หนองบัวลำภู 39180",
      label: "ศรีบุญเรือง ศรีบุญเรือง หนองบัวลำภู 39180",
    },
    {
      value: "หนองกุงแก้ว ศรีบุญเรือง หนองบัวลำภู 39180",
      label: "หนองกุงแก้ว ศรีบุญเรือง หนองบัวลำภู 39180",
    },
    {
      value: "หนองบัวใต้ ศรีบุญเรือง หนองบัวลำภู 39180",
      label: "หนองบัวใต้ ศรีบุญเรือง หนองบัวลำภู 39180",
    },
    {
      value: "หนองแก ศรีบุญเรือง หนองบัวลำภู 39180",
      label: "หนองแก ศรีบุญเรือง หนองบัวลำภู 39180",
    },
    {
      value: "หันนางาม ศรีบุญเรือง หนองบัวลำภู 39180",
      label: "หันนางาม ศรีบุญเรือง หนองบัวลำภู 39180",
    },
    {
      value: "เมืองใหม่ ศรีบุญเรือง หนองบัวลำภู 39180",
      label: "เมืองใหม่ ศรีบุญเรือง หนองบัวลำภู 39180",
    },
    {
      value: "โนนม่วง ศรีบุญเรือง หนองบัวลำภู 39180",
      label: "โนนม่วง ศรีบุญเรือง หนองบัวลำภู 39180",
    },
    {
      value: "โนนสะอาด ศรีบุญเรือง หนองบัวลำภู 39180",
      label: "โนนสะอาด ศรีบุญเรือง หนองบัวลำภู 39180",
    },
    {
      value: "กุดผึ้ง สุวรรณคูหา หนองบัวลำภู 39270",
      label: "กุดผึ้ง สุวรรณคูหา หนองบัวลำภู 39270",
    },
    {
      value: "ดงมะไฟ สุวรรณคูหา หนองบัวลำภู 39270",
      label: "ดงมะไฟ สุวรรณคูหา หนองบัวลำภู 39270",
    },
    {
      value: "นาดี สุวรรณคูหา หนองบัวลำภู 39270",
      label: "นาดี สุวรรณคูหา หนองบัวลำภู 39270",
    },
    {
      value: "นาด่าน สุวรรณคูหา หนองบัวลำภู 39270",
      label: "นาด่าน สุวรรณคูหา หนองบัวลำภู 39270",
    },
    {
      value: "นาสี สุวรรณคูหา หนองบัวลำภู 39270",
      label: "นาสี สุวรรณคูหา หนองบัวลำภู 39270",
    },
    {
      value: "บุญทัน สุวรรณคูหา หนองบัวลำภู 39270",
      label: "บุญทัน สุวรรณคูหา หนองบัวลำภู 39270",
    },
    {
      value: "บ้านโคก สุวรรณคูหา หนองบัวลำภู 39270",
      label: "บ้านโคก สุวรรณคูหา หนองบัวลำภู 39270",
    },
    {
      value: "สุวรรณคูหา สุวรรณคูหา หนองบัวลำภู 39270",
      label: "สุวรรณคูหา สุวรรณคูหา หนองบัวลำภู 39270",
    },
    {
      value: "กุดจิก เมืองหนองบัวลำภู หนองบัวลำภู 39000",
      label: "กุดจิก เมืองหนองบัวลำภู หนองบัวลำภู 39000",
    },
    {
      value: "นาคำไฮ เมืองหนองบัวลำภู หนองบัวลำภู 39000",
      label: "นาคำไฮ เมืองหนองบัวลำภู หนองบัวลำภู 39000",
    },
    {
      value: "นามะเฟือง เมืองหนองบัวลำภู หนองบัวลำภู 39000",
      label: "นามะเฟือง เมืองหนองบัวลำภู หนองบัวลำภู 39000",
    },
    {
      value: "บ้านขาม เมืองหนองบัวลำภู หนองบัวลำภู 39000",
      label: "บ้านขาม เมืองหนองบัวลำภู หนองบัวลำภู 39000",
    },
    {
      value: "บ้านพร้าว เมืองหนองบัวลำภู หนองบัวลำภู 39000",
      label: "บ้านพร้าว เมืองหนองบัวลำภู หนองบัวลำภู 39000",
    },
    {
      value: "ป่าไม้งาม เมืองหนองบัวลำภู หนองบัวลำภู 39000",
      label: "ป่าไม้งาม เมืองหนองบัวลำภู หนองบัวลำภู 39000",
    },
    {
      value: "ลำภู เมืองหนองบัวลำภู หนองบัวลำภู 39000",
      label: "ลำภู เมืองหนองบัวลำภู หนองบัวลำภู 39000",
    },
    {
      value: "หนองบัว เมืองหนองบัวลำภู หนองบัวลำภู 39000",
      label: "หนองบัว เมืองหนองบัวลำภู หนองบัวลำภู 39000",
    },
    {
      value: "หนองภัยศูนย์ เมืองหนองบัวลำภู หนองบัวลำภู 39000",
      label: "หนองภัยศูนย์ เมืองหนองบัวลำภู หนองบัวลำภู 39000",
    },
    {
      value: "หนองสวรรค์ เมืองหนองบัวลำภู หนองบัวลำภู 39000",
      label: "หนองสวรรค์ เมืองหนองบัวลำภู หนองบัวลำภู 39000",
    },
    {
      value: "หนองหว้า เมืองหนองบัวลำภู หนองบัวลำภู 39000",
      label: "หนองหว้า เมืองหนองบัวลำภู หนองบัวลำภู 39000",
    },
    {
      value: "หัวนา เมืองหนองบัวลำภู หนองบัวลำภู 39000",
      label: "หัวนา เมืองหนองบัวลำภู หนองบัวลำภู 39000",
    },
    {
      value: "โนนขมิ้น เมืองหนองบัวลำภู หนองบัวลำภู 39000",
      label: "โนนขมิ้น เมืองหนองบัวลำภู หนองบัวลำภู 39000",
    },
    {
      value: "โนนทัน เมืองหนองบัวลำภู หนองบัวลำภู 39000",
      label: "โนนทัน เมืองหนองบัวลำภู หนองบัวลำภู 39000",
    },
    {
      value: "โพธิ์ชัย เมืองหนองบัวลำภู หนองบัวลำภู 39000",
      label: "โพธิ์ชัย เมืองหนองบัวลำภู หนองบัวลำภู 39000",
    },
    {
      value: "กุดดู่ โนนสัง หนองบัวลำภู 39140",
      label: "กุดดู่ โนนสัง หนองบัวลำภู 39140",
    },
    {
      value: "นิคมพัฒนา โนนสัง หนองบัวลำภู 39140",
      label: "นิคมพัฒนา โนนสัง หนองบัวลำภู 39140",
    },
    {
      value: "บ้านค้อ โนนสัง หนองบัวลำภู 39140",
      label: "บ้านค้อ โนนสัง หนองบัวลำภู 39140",
    },
    {
      value: "บ้านถิ่น โนนสัง หนองบัวลำภู 39140",
      label: "บ้านถิ่น โนนสัง หนองบัวลำภู 39140",
    },
    {
      value: "ปางกู่ โนนสัง หนองบัวลำภู 39140",
      label: "ปางกู่ โนนสัง หนองบัวลำภู 39140",
    },
    {
      value: "หนองเรือ โนนสัง หนองบัวลำภู 39140",
      label: "หนองเรือ โนนสัง หนองบัวลำภู 39140",
    },
    {
      value: "โคกม่วง โนนสัง หนองบัวลำภู 39140",
      label: "โคกม่วง โนนสัง หนองบัวลำภู 39140",
    },
    {
      value: "โคกใหญ่ โนนสัง หนองบัวลำภู 39140",
      label: "โคกใหญ่ โนนสัง หนองบัวลำภู 39140",
    },
    {
      value: "โนนสัง โนนสัง หนองบัวลำภู 39140",
      label: "โนนสัง โนนสัง หนองบัวลำภู 39140",
    },
    {
      value: "โนนเมือง โนนสัง หนองบัวลำภู 39140",
      label: "โนนเมือง โนนสัง หนองบัวลำภู 39140",
    },
    {
      value: "กุดจับ กุดจับ อุดรธานี 41250",
      label: "กุดจับ กุดจับ อุดรธานี 41250",
    },
    {
      value: "ขอนยูง กุดจับ อุดรธานี 41250",
      label: "ขอนยูง กุดจับ อุดรธานี 41250",
    },
    {
      value: "ตาลเลียน กุดจับ อุดรธานี 41250",
      label: "ตาลเลียน กุดจับ อุดรธานี 41250",
    },
    { value: "ปะโค กุดจับ อุดรธานี 41250", label: "ปะโค กุดจับ อุดรธานี 41250" },
    {
      value: "สร้างก่อ กุดจับ อุดรธานี 41250",
      label: "สร้างก่อ กุดจับ อุดรธานี 41250",
    },
    {
      value: "เชียงเพ็ง กุดจับ อุดรธานี 41250",
      label: "เชียงเพ็ง กุดจับ อุดรธานี 41250",
    },
    {
      value: "เมืองเพีย กุดจับ อุดรธานี 41250",
      label: "เมืองเพีย กุดจับ อุดรธานี 41250",
    },
    {
      value: "กุมภวาปี กุมภวาปี อุดรธานี 41110",
      label: "กุมภวาปี กุมภวาปี อุดรธานี 41110",
    },
    {
      value: "ตูมใต้ กุมภวาปี อุดรธานี 41110",
      label: "ตูมใต้ กุมภวาปี อุดรธานี 41110",
    },
    {
      value: "ท่าลี่ กุมภวาปี อุดรธานี 41110",
      label: "ท่าลี่ กุมภวาปี อุดรธานี 41110",
    },
    {
      value: "ปะโค กุมภวาปี อุดรธานี 41370",
      label: "ปะโค กุมภวาปี อุดรธานี 41370",
    },
    {
      value: "ผาสุก กุมภวาปี อุดรธานี 41370",
      label: "ผาสุก กุมภวาปี อุดรธานี 41370",
    },
    {
      value: "พันดอน กุมภวาปี อุดรธานี 41370",
      label: "พันดอน กุมภวาปี อุดรธานี 41370",
    },
    {
      value: "สีออ กุมภวาปี อุดรธานี 41110",
      label: "สีออ กุมภวาปี อุดรธานี 41110",
    },
    {
      value: "หนองหว้า กุมภวาปี อุดรธานี 41110",
      label: "หนองหว้า กุมภวาปี อุดรธานี 41110",
    },
    {
      value: "ห้วยเกิ้ง กุมภวาปี อุดรธานี 41110",
      label: "ห้วยเกิ้ง กุมภวาปี อุดรธานี 41110",
    },
    {
      value: "เชียงแหว กุมภวาปี อุดรธานี 41110",
      label: "เชียงแหว กุมภวาปี อุดรธานี 41110",
    },
    {
      value: "เวียงคำ กุมภวาปี อุดรธานี 41110",
      label: "เวียงคำ กุมภวาปี อุดรธานี 41110",
    },
    {
      value: "เสอเพลอ กุมภวาปี อุดรธานี 41370",
      label: "เสอเพลอ กุมภวาปี อุดรธานี 41370",
    },
    {
      value: "แชแล กุมภวาปี อุดรธานี 41110",
      label: "แชแล กุมภวาปี อุดรธานี 41110",
    },
    {
      value: "คอนสาย กู่แก้ว อุดรธานี 41130",
      label: "คอนสาย กู่แก้ว อุดรธานี 41130",
    },
    {
      value: "ค้อใหญ่ กู่แก้ว อุดรธานี 41130",
      label: "ค้อใหญ่ กู่แก้ว อุดรธานี 41130",
    },
    {
      value: "บ้านจีต กู่แก้ว อุดรธานี 41130",
      label: "บ้านจีต กู่แก้ว อุดรธานี 41130",
    },
    {
      value: "โนนทองอินทร์ กู่แก้ว อุดรธานี 41130",
      label: "โนนทองอินทร์ กู่แก้ว อุดรธานี 41130",
    },
    {
      value: "ทุ่งฝน ทุ่งฝน อุดรธานี 41310",
      label: "ทุ่งฝน ทุ่งฝน อุดรธานี 41310",
    },
    {
      value: "ทุ่งใหญ่ ทุ่งฝน อุดรธานี 41310",
      label: "ทุ่งใหญ่ ทุ่งฝน อุดรธานี 41310",
    },
    {
      value: "นาชุมแสง ทุ่งฝน อุดรธานี 41310",
      label: "นาชุมแสง ทุ่งฝน อุดรธานี 41310",
    },
    { value: "นาทม ทุ่งฝน อุดรธานี 41310", label: "นาทม ทุ่งฝน อุดรธานี 41310" },
    { value: "นายูง นายูง อุดรธานี 41380", label: "นายูง นายูง อุดรธานี 41380" },
    { value: "นาแค นายูง อุดรธานี 41380", label: "นาแค นายูง อุดรธานี 41380" },
    {
      value: "บ้านก้อง นายูง อุดรธานี 41380",
      label: "บ้านก้อง นายูง อุดรธานี 41380",
    },
    {
      value: "โนนทอง นายูง อุดรธานี 41380",
      label: "โนนทอง นายูง อุดรธานี 41380",
    },
    {
      value: "นางัว น้ำโสม อุดรธานี 41210",
      label: "นางัว น้ำโสม อุดรธานี 41210",
    },
    {
      value: "น้ำโสม น้ำโสม อุดรธานี 41210",
      label: "น้ำโสม น้ำโสม อุดรธานี 41210",
    },
    {
      value: "บ้านหยวก น้ำโสม อุดรธานี 41210",
      label: "บ้านหยวก น้ำโสม อุดรธานี 41210",
    },
    {
      value: "ศรีสำราญ น้ำโสม อุดรธานี 41210",
      label: "ศรีสำราญ น้ำโสม อุดรธานี 41210",
    },
    {
      value: "สามัคคี น้ำโสม อุดรธานี 41210",
      label: "สามัคคี น้ำโสม อุดรธานี 41210",
    },
    {
      value: "หนองแวง น้ำโสม อุดรธานี 41210",
      label: "หนองแวง น้ำโสม อุดรธานี 41210",
    },
    {
      value: "โสมเยี่ยม น้ำโสม อุดรธานี 41210",
      label: "โสมเยี่ยม น้ำโสม อุดรธานี 41210",
    },
    {
      value: "ดงเย็น บ้านดุง อุดรธานี 41190",
      label: "ดงเย็น บ้านดุง อุดรธานี 41190",
    },
    {
      value: "ถ่อนนาลับ บ้านดุง อุดรธานี 41190",
      label: "ถ่อนนาลับ บ้านดุง อุดรธานี 41190",
    },
    {
      value: "นาคำ บ้านดุง อุดรธานี 41190",
      label: "นาคำ บ้านดุง อุดรธานี 41190",
    },
    {
      value: "นาไหม บ้านดุง อุดรธานี 41190",
      label: "นาไหม บ้านดุง อุดรธานี 41190",
    },
    {
      value: "บ้านจันทร์ บ้านดุง อุดรธานี 41190",
      label: "บ้านจันทร์ บ้านดุง อุดรธานี 41190",
    },
    {
      value: "บ้านชัย บ้านดุง อุดรธานี 41190",
      label: "บ้านชัย บ้านดุง อุดรธานี 41190",
    },
    {
      value: "บ้านดุง บ้านดุง อุดรธานี 41190",
      label: "บ้านดุง บ้านดุง อุดรธานี 41190",
    },
    {
      value: "บ้านตาด บ้านดุง อุดรธานี 41190",
      label: "บ้านตาด บ้านดุง อุดรธานี 41190",
    },
    {
      value: "บ้านม่วง บ้านดุง อุดรธานี 41190",
      label: "บ้านม่วง บ้านดุง อุดรธานี 41190",
    },
    {
      value: "วังทอง บ้านดุง อุดรธานี 41190",
      label: "วังทอง บ้านดุง อุดรธานี 41190",
    },
    {
      value: "ศรีสุทโธ บ้านดุง อุดรธานี 41190",
      label: "ศรีสุทโธ บ้านดุง อุดรธานี 41190",
    },
    {
      value: "อ้อมกอ บ้านดุง อุดรธานี 41190",
      label: "อ้อมกอ บ้านดุง อุดรธานี 41190",
    },
    {
      value: "โพนสูง บ้านดุง อุดรธานี 41190",
      label: "โพนสูง บ้านดุง อุดรธานี 41190",
    },
    {
      value: "กลางใหญ่ บ้านผือ อุดรธานี 41160",
      label: "กลางใหญ่ บ้านผือ อุดรธานี 41160",
    },
    {
      value: "ข้าวสาร บ้านผือ อุดรธานี 41160",
      label: "ข้าวสาร บ้านผือ อุดรธานี 41160",
    },
    {
      value: "คำด้วง บ้านผือ อุดรธานี 41160",
      label: "คำด้วง บ้านผือ อุดรธานี 41160",
    },
    {
      value: "คำบง บ้านผือ อุดรธานี 41160",
      label: "คำบง บ้านผือ อุดรธานี 41160",
    },
    {
      value: "จำปาโมง บ้านผือ อุดรธานี 41160",
      label: "จำปาโมง บ้านผือ อุดรธานี 41160",
    },
    {
      value: "บ้านค้อ บ้านผือ อุดรธานี 41160",
      label: "บ้านค้อ บ้านผือ อุดรธานี 41160",
    },
    {
      value: "บ้านผือ บ้านผือ อุดรธานี 41160",
      label: "บ้านผือ บ้านผือ อุดรธานี 41160",
    },
    {
      value: "หนองหัวคู บ้านผือ อุดรธานี 41160",
      label: "หนองหัวคู บ้านผือ อุดรธานี 41160",
    },
    {
      value: "หนองแวง บ้านผือ อุดรธานี 41160",
      label: "หนองแวง บ้านผือ อุดรธานี 41160",
    },
    {
      value: "หายโศก บ้านผือ อุดรธานี 41160",
      label: "หายโศก บ้านผือ อุดรธานี 41160",
    },
    {
      value: "เขือน้ำ บ้านผือ อุดรธานี 41160",
      label: "เขือน้ำ บ้านผือ อุดรธานี 41160",
    },
    {
      value: "เมืองพาน บ้านผือ อุดรธานี 41160",
      label: "เมืองพาน บ้านผือ อุดรธานี 41160",
    },
    {
      value: "โนนทอง บ้านผือ อุดรธานี 41160",
      label: "โนนทอง บ้านผือ อุดรธานี 41160",
    },
    {
      value: "นาม่วง ประจักษ์ศิลปาคม อุดรธานี 41110",
      label: "นาม่วง ประจักษ์ศิลปาคม อุดรธานี 41110",
    },
    {
      value: "ห้วยสามพาด ประจักษ์ศิลปาคม อุดรธานี 41110",
      label: "ห้วยสามพาด ประจักษ์ศิลปาคม อุดรธานี 41110",
    },
    {
      value: "อุ่มจาน ประจักษ์ศิลปาคม อุดรธานี 41110",
      label: "อุ่มจาน ประจักษ์ศิลปาคม อุดรธานี 41110",
    },
    {
      value: "ดอนกลอย พิบูลย์รักษ์ อุดรธานี 41130",
      label: "ดอนกลอย พิบูลย์รักษ์ อุดรธานี 41130",
    },
    {
      value: "นาทราย พิบูลย์รักษ์ อุดรธานี 41130",
      label: "นาทราย พิบูลย์รักษ์ อุดรธานี 41130",
    },
    {
      value: "บ้านแดง พิบูลย์รักษ์ อุดรธานี 41130",
      label: "บ้านแดง พิบูลย์รักษ์ อุดรธานี 41130",
    },
    {
      value: "คำโคกสูง วังสามหมอ อุดรธานี 41280",
      label: "คำโคกสูง วังสามหมอ อุดรธานี 41280",
    },
    {
      value: "บะยาว วังสามหมอ อุดรธานี 41280",
      label: "บะยาว วังสามหมอ อุดรธานี 41280",
    },
    {
      value: "ผาสุก วังสามหมอ อุดรธานี 41280",
      label: "ผาสุก วังสามหมอ อุดรธานี 41280",
    },
    {
      value: "วังสามหมอ วังสามหมอ อุดรธานี 41280",
      label: "วังสามหมอ วังสามหมอ อุดรธานี 41280",
    },
    {
      value: "หนองกุงทับม้า วังสามหมอ อุดรธานี 41280",
      label: "หนองกุงทับม้า วังสามหมอ อุดรธานี 41280",
    },
    {
      value: "หนองหญ้าไซ วังสามหมอ อุดรธานี 41280",
      label: "หนองหญ้าไซ วังสามหมอ อุดรธานี 41280",
    },
    {
      value: "จำปี ศรีธาตุ อุดรธานี 41230",
      label: "จำปี ศรีธาตุ อุดรธานี 41230",
    },
    {
      value: "ตาดทอง ศรีธาตุ อุดรธานี 41230",
      label: "ตาดทอง ศรีธาตุ อุดรธานี 41230",
    },
    {
      value: "นายูง ศรีธาตุ อุดรธานี 41230",
      label: "นายูง ศรีธาตุ อุดรธานี 41230",
    },
    {
      value: "บ้านโปร่ง ศรีธาตุ อุดรธานี 41230",
      label: "บ้านโปร่ง ศรีธาตุ อุดรธานี 41230",
    },
    {
      value: "ศรีธาตุ ศรีธาตุ อุดรธานี 41230",
      label: "ศรีธาตุ ศรีธาตุ อุดรธานี 41230",
    },
    {
      value: "หนองนกเขียน ศรีธาตุ อุดรธานี 41230",
      label: "หนองนกเขียน ศรีธาตุ อุดรธานี 41230",
    },
    {
      value: "หัวนาคำ ศรีธาตุ อุดรธานี 41230",
      label: "หัวนาคำ ศรีธาตุ อุดรธานี 41230",
    },
    {
      value: "นาสะอาด สร้างคอม อุดรธานี 41260",
      label: "นาสะอาด สร้างคอม อุดรธานี 41260",
    },
    {
      value: "บ้านยวด สร้างคอม อุดรธานี 41260",
      label: "บ้านยวด สร้างคอม อุดรธานี 41260",
    },
    {
      value: "บ้านหินโงม สร้างคอม อุดรธานี 41260",
      label: "บ้านหินโงม สร้างคอม อุดรธานี 41260",
    },
    {
      value: "บ้านโคก สร้างคอม อุดรธานี 41260",
      label: "บ้านโคก สร้างคอม อุดรธานี 41260",
    },
    {
      value: "สร้างคอม สร้างคอม อุดรธานี 41260",
      label: "สร้างคอม สร้างคอม อุดรธานี 41260",
    },
    {
      value: "เชียงดา สร้างคอม อุดรธานี 41260",
      label: "เชียงดา สร้างคอม อุดรธานี 41260",
    },
    {
      value: "กุดหมากไฟ หนองวัวซอ อุดรธานี 41220",
      label: "กุดหมากไฟ หนองวัวซอ อุดรธานี 41220",
    },
    {
      value: "น้ำพ่น หนองวัวซอ อุดรธานี 41360",
      label: "น้ำพ่น หนองวัวซอ อุดรธานี 41360",
    },
    {
      value: "หนองบัวบาน หนองวัวซอ อุดรธานี 41360",
      label: "หนองบัวบาน หนองวัวซอ อุดรธานี 41360",
    },
    {
      value: "หนองวัวซอ หนองวัวซอ อุดรธานี 41360",
      label: "หนองวัวซอ หนองวัวซอ อุดรธานี 41360",
    },
    {
      value: "หนองอ้อ หนองวัวซอ อุดรธานี 41220",
      label: "หนองอ้อ หนองวัวซอ อุดรธานี 41220",
    },
    {
      value: "หมากหญ้า หนองวัวซอ อุดรธานี 41360",
      label: "หมากหญ้า หนองวัวซอ อุดรธานี 41360",
    },
    {
      value: "อูบมุง หนองวัวซอ อุดรธานี 41220",
      label: "อูบมุง หนองวัวซอ อุดรธานี 41220",
    },
    {
      value: "โนนหวาย หนองวัวซอ อุดรธานี 41220",
      label: "โนนหวาย หนองวัวซอ อุดรธานี 41220",
    },
    {
      value: "ดอนหายโศก หนองหาน อุดรธานี 41130",
      label: "ดอนหายโศก หนองหาน อุดรธานี 41130",
    },
    {
      value: "บ้านยา หนองหาน อุดรธานี 41320",
      label: "บ้านยา หนองหาน อุดรธานี 41320",
    },
    {
      value: "บ้านเชียง หนองหาน อุดรธานี 41320",
      label: "บ้านเชียง หนองหาน อุดรธานี 41320",
    },
    {
      value: "ผักตบ หนองหาน อุดรธานี 41130",
      label: "ผักตบ หนองหาน อุดรธานี 41130",
    },
    {
      value: "พังงู หนองหาน อุดรธานี 41130",
      label: "พังงู หนองหาน อุดรธานี 41130",
    },
    {
      value: "สร้อยพร้าว หนองหาน อุดรธานี 41130",
      label: "สร้อยพร้าว หนองหาน อุดรธานี 41130",
    },
    {
      value: "สะแบง หนองหาน อุดรธานี 41130",
      label: "สะแบง หนองหาน อุดรธานี 41130",
    },
    {
      value: "หนองสระปลา หนองหาน อุดรธานี 41320",
      label: "หนองสระปลา หนองหาน อุดรธานี 41320",
    },
    {
      value: "หนองหาน หนองหาน อุดรธานี 41130",
      label: "หนองหาน หนองหาน อุดรธานี 41130",
    },
    {
      value: "หนองเม็ก หนองหาน อุดรธานี 41130",
      label: "หนองเม็ก หนองหาน อุดรธานี 41130",
    },
    {
      value: "หนองไผ่ หนองหาน อุดรธานี 41130",
      label: "หนองไผ่ หนองหาน อุดรธานี 41130",
    },
    {
      value: "โพนงาม หนองหาน อุดรธานี 41130",
      label: "โพนงาม หนองหาน อุดรธานี 41130",
    },
    {
      value: "ทับกุง หนองแสง อุดรธานี 41340",
      label: "ทับกุง หนองแสง อุดรธานี 41340",
    },
    {
      value: "นาดี หนองแสง อุดรธานี 41340",
      label: "นาดี หนองแสง อุดรธานี 41340",
    },
    {
      value: "หนองแสง หนองแสง อุดรธานี 41340",
      label: "หนองแสง หนองแสง อุดรธานี 41340",
    },
    {
      value: "แสงสว่าง หนองแสง อุดรธานี 41340",
      label: "แสงสว่าง หนองแสง อุดรธานี 41340",
    },
    { value: "จอมศรี เพ็ญ อุดรธานี 41150", label: "จอมศรี เพ็ญ อุดรธานี 41150" },
    { value: "นาบัว เพ็ญ อุดรธานี 41150", label: "นาบัว เพ็ญ อุดรธานี 41150" },
    { value: "นาพู่ เพ็ญ อุดรธานี 41150", label: "นาพู่ เพ็ญ อุดรธานี 41150" },
    {
      value: "บ้านธาตุ เพ็ญ อุดรธานี 41150",
      label: "บ้านธาตุ เพ็ญ อุดรธานี 41150",
    },
    {
      value: "บ้านเหล่า เพ็ญ อุดรธานี 41150",
      label: "บ้านเหล่า เพ็ญ อุดรธานี 41150",
    },
    {
      value: "สร้างแป้น เพ็ญ อุดรธานี 41150",
      label: "สร้างแป้น เพ็ญ อุดรธานี 41150",
    },
    {
      value: "สุมเส้า เพ็ญ อุดรธานี 41150",
      label: "สุมเส้า เพ็ญ อุดรธานี 41150",
    },
    {
      value: "เชียงหวาง เพ็ญ อุดรธานี 41150",
      label: "เชียงหวาง เพ็ญ อุดรธานี 41150",
    },
    { value: "เตาไห เพ็ญ อุดรธานี 41150", label: "เตาไห เพ็ญ อุดรธานี 41150" },
    { value: "เพ็ญ เพ็ญ อุดรธานี 41150", label: "เพ็ญ เพ็ญ อุดรธานี 41150" },
    {
      value: "โคกกลาง เพ็ญ อุดรธานี 41150",
      label: "โคกกลาง เพ็ญ อุดรธานี 41150",
    },
    {
      value: "กุดสระ เมืองอุดรธานี อุดรธานี 41000",
      label: "กุดสระ เมืองอุดรธานี อุดรธานี 41000",
    },
    {
      value: "นากว้าง เมืองอุดรธานี อุดรธานี 41000",
      label: "นากว้าง เมืองอุดรธานี อุดรธานี 41000",
    },
    {
      value: "นาข่า เมืองอุดรธานี อุดรธานี 41000",
      label: "นาข่า เมืองอุดรธานี อุดรธานี 41000",
    },
    {
      value: "นาดี เมืองอุดรธานี อุดรธานี 41000",
      label: "นาดี เมืองอุดรธานี อุดรธานี 41000",
    },
    {
      value: "นิคมสงเคราะห์ เมืองอุดรธานี อุดรธานี 41000",
      label: "นิคมสงเคราะห์ เมืองอุดรธานี อุดรธานี 41000",
    },
    {
      value: "บ้านขาว เมืองอุดรธานี อุดรธานี 41000",
      label: "บ้านขาว เมืองอุดรธานี อุดรธานี 41000",
    },
    {
      value: "บ้านจั่น เมืองอุดรธานี อุดรธานี 41000",
      label: "บ้านจั่น เมืองอุดรธานี อุดรธานี 41000",
    },
    {
      value: "บ้านตาด เมืองอุดรธานี อุดรธานี 41000",
      label: "บ้านตาด เมืองอุดรธานี อุดรธานี 41000",
    },
    {
      value: "บ้านเลื่อม เมืองอุดรธานี อุดรธานี 41000",
      label: "บ้านเลื่อม เมืองอุดรธานี อุดรธานี 41000",
    },
    {
      value: "สามพร้าว เมืองอุดรธานี อุดรธานี 41000",
      label: "สามพร้าว เมืองอุดรธานี อุดรธานี 41000",
    },
    {
      value: "หนองขอนกว้าง เมืองอุดรธานี อุดรธานี 41000",
      label: "หนองขอนกว้าง เมืองอุดรธานี อุดรธานี 41000",
    },
    {
      value: "หนองนาคำ เมืองอุดรธานี อุดรธานี 41000",
      label: "หนองนาคำ เมืองอุดรธานี อุดรธานี 41000",
    },
    {
      value: "หนองบัว เมืองอุดรธานี อุดรธานี 41000",
      label: "หนองบัว เมืองอุดรธานี อุดรธานี 41000",
    },
    {
      value: "หนองไผ่ เมืองอุดรธานี อุดรธานี 41330",
      label: "หนองไผ่ เมืองอุดรธานี อุดรธานี 41330",
    },
    {
      value: "หนองไฮ เมืองอุดรธานี อุดรธานี 41000",
      label: "หนองไฮ เมืองอุดรธานี อุดรธานี 41000",
    },
    {
      value: "หมากแข้ง เมืองอุดรธานี อุดรธานี 41000",
      label: "หมากแข้ง เมืองอุดรธานี อุดรธานี 41000",
    },
    {
      value: "หมูม่น เมืองอุดรธานี อุดรธานี 41000",
      label: "หมูม่น เมืองอุดรธานี อุดรธานี 41000",
    },
    {
      value: "เชียงพิณ เมืองอุดรธานี อุดรธานี 41000",
      label: "เชียงพิณ เมืองอุดรธานี อุดรธานี 41000",
    },
    {
      value: "เชียงยืน เมืองอุดรธานี อุดรธานี 41000",
      label: "เชียงยืน เมืองอุดรธานี อุดรธานี 41000",
    },
    {
      value: "โคกสะอาด เมืองอุดรธานี อุดรธานี 41000",
      label: "โคกสะอาด เมืองอุดรธานี อุดรธานี 41000",
    },
    {
      value: "โนนสูง เมืองอุดรธานี อุดรธานี 41330",
      label: "โนนสูง เมืองอุดรธานี อุดรธานี 41330",
    },
    {
      value: "ทมนางาม โนนสะอาด อุดรธานี 41240",
      label: "ทมนางาม โนนสะอาด อุดรธานี 41240",
    },
    {
      value: "บุ่งแก้ว โนนสะอาด อุดรธานี 41240",
      label: "บุ่งแก้ว โนนสะอาด อุดรธานี 41240",
    },
    {
      value: "หนองกุงศรี โนนสะอาด อุดรธานี 41240",
      label: "หนองกุงศรี โนนสะอาด อุดรธานี 41240",
    },
    {
      value: "โคกกลาง โนนสะอาด อุดรธานี 41240",
      label: "โคกกลาง โนนสะอาด อุดรธานี 41240",
    },
    {
      value: "โนนสะอาด โนนสะอาด อุดรธานี 41240",
      label: "โนนสะอาด โนนสะอาด อุดรธานี 41240",
    },
    {
      value: "โพธิ์ศรีสำราญ โนนสะอาด อุดรธานี 41240",
      label: "โพธิ์ศรีสำราญ โนนสะอาด อุดรธานี 41240",
    },
    {
      value: "คำเลาะ ไชยวาน อุดรธานี 41290",
      label: "คำเลาะ ไชยวาน อุดรธานี 41290",
    },
    {
      value: "หนองหลัก ไชยวาน อุดรธานี 41290",
      label: "หนองหลัก ไชยวาน อุดรธานี 41290",
    },
    {
      value: "โพนสูง ไชยวาน อุดรธานี 41290",
      label: "โพนสูง ไชยวาน อุดรธานี 41290",
    },
    {
      value: "ไชยวาน ไชยวาน อุดรธานี 41290",
      label: "ไชยวาน ไชยวาน อุดรธานี 41290",
    },
    {
      value: "กาบิน กุดข้าวปุ้น อุบลราชธานี 34270",
      label: "กาบิน กุดข้าวปุ้น อุบลราชธานี 34270",
    },
    {
      value: "ข้าวปุ้น กุดข้าวปุ้น อุบลราชธานี 34270",
      label: "ข้าวปุ้น กุดข้าวปุ้น อุบลราชธานี 34270",
    },
    {
      value: "หนองทันน้ำ กุดข้าวปุ้น อุบลราชธานี 34270",
      label: "หนองทันน้ำ กุดข้าวปุ้น อุบลราชธานี 34270",
    },
    {
      value: "แก่งเค็ง กุดข้าวปุ้น อุบลราชธานี 34270",
      label: "แก่งเค็ง กุดข้าวปุ้น อุบลราชธานี 34270",
    },
    {
      value: "โนนสวาง กุดข้าวปุ้น อุบลราชธานี 34270",
      label: "โนนสวาง กุดข้าวปุ้น อุบลราชธานี 34270",
    },
    {
      value: "คำไฮใหญ่ ดอนมดแดง อุบลราชธานี 34000",
      label: "คำไฮใหญ่ ดอนมดแดง อุบลราชธานี 34000",
    },
    {
      value: "ดอนมดแดง ดอนมดแดง อุบลราชธานี 34000",
      label: "ดอนมดแดง ดอนมดแดง อุบลราชธานี 34000",
    },
    {
      value: "ท่าเมือง ดอนมดแดง อุบลราชธานี 34000",
      label: "ท่าเมือง ดอนมดแดง อุบลราชธานี 34000",
    },
    {
      value: "เหล่าแดง ดอนมดแดง อุบลราชธานี 34000",
      label: "เหล่าแดง ดอนมดแดง อุบลราชธานี 34000",
    },
    {
      value: "กระเดียน ตระการพืชผล อุบลราชธานี 34130",
      label: "กระเดียน ตระการพืชผล อุบลราชธานี 34130",
    },
    {
      value: "กุดยาลวน ตระการพืชผล อุบลราชธานี 34130",
      label: "กุดยาลวน ตระการพืชผล อุบลราชธานี 34130",
    },
    {
      value: "กุศกร ตระการพืชผล อุบลราชธานี 34130",
      label: "กุศกร ตระการพืชผล อุบลราชธานี 34130",
    },
    {
      value: "ขามเปี้ย ตระการพืชผล อุบลราชธานี 34130",
      label: "ขามเปี้ย ตระการพืชผล อุบลราชธานี 34130",
    },
    {
      value: "ขุหลุ ตระการพืชผล อุบลราชธานี 34130",
      label: "ขุหลุ ตระการพืชผล อุบลราชธานี 34130",
    },
    {
      value: "คอนสาย ตระการพืชผล อุบลราชธานี 34130",
      label: "คอนสาย ตระการพืชผล อุบลราชธานี 34130",
    },
    {
      value: "คำเจริญ ตระการพืชผล อุบลราชธานี 34130",
      label: "คำเจริญ ตระการพืชผล อุบลราชธานี 34130",
    },
    {
      value: "ตระการ ตระการพืชผล อุบลราชธานี 34130",
      label: "ตระการ ตระการพืชผล อุบลราชธานี 34130",
    },
    {
      value: "ตากแดด ตระการพืชผล อุบลราชธานี 34130",
      label: "ตากแดด ตระการพืชผล อุบลราชธานี 34130",
    },
    {
      value: "ถ้ำแข้ ตระการพืชผล อุบลราชธานี 34130",
      label: "ถ้ำแข้ ตระการพืชผล อุบลราชธานี 34130",
    },
    {
      value: "ท่าหลวง ตระการพืชผล อุบลราชธานี 34130",
      label: "ท่าหลวง ตระการพืชผล อุบลราชธานี 34130",
    },
    {
      value: "นาพิน ตระการพืชผล อุบลราชธานี 34130",
      label: "นาพิน ตระการพืชผล อุบลราชธานี 34130",
    },
    {
      value: "นาสะไม ตระการพืชผล อุบลราชธานี 34130",
      label: "นาสะไม ตระการพืชผล อุบลราชธานี 34130",
    },
    {
      value: "บ้านแดง ตระการพืชผล อุบลราชธานี 34130",
      label: "บ้านแดง ตระการพืชผล อุบลราชธานี 34130",
    },
    {
      value: "สะพือ ตระการพืชผล อุบลราชธานี 34130",
      label: "สะพือ ตระการพืชผล อุบลราชธานี 34130",
    },
    {
      value: "หนองเต่า ตระการพืชผล อุบลราชธานี 34130",
      label: "หนองเต่า ตระการพืชผล อุบลราชธานี 34130",
    },
    {
      value: "ห้วยฝ้ายพัฒนา ตระการพืชผล อุบลราชธานี 34130",
      label: "ห้วยฝ้ายพัฒนา ตระการพืชผล อุบลราชธานี 34130",
    },
    {
      value: "เกษม ตระการพืชผล อุบลราชธานี 34130",
      label: "เกษม ตระการพืชผล อุบลราชธานี 34130",
    },
    {
      value: "เซเป็ด ตระการพืชผล อุบลราชธานี 34130",
      label: "เซเป็ด ตระการพืชผล อุบลราชธานี 34130",
    },
    {
      value: "เป้า ตระการพืชผล อุบลราชธานี 34130",
      label: "เป้า ตระการพืชผล อุบลราชธานี 34130",
    },
    {
      value: "โคกจาน ตระการพืชผล อุบลราชธานี 34130",
      label: "โคกจาน ตระการพืชผล อุบลราชธานี 34130",
    },
    {
      value: "โนนกุง ตระการพืชผล อุบลราชธานี 34130",
      label: "โนนกุง ตระการพืชผล อุบลราชธานี 34130",
    },
    {
      value: "ไหล่ทุ่ง ตระการพืชผล อุบลราชธานี 34130",
      label: "ไหล่ทุ่ง ตระการพืชผล อุบลราชธานี 34130",
    },
    {
      value: "คำหว้า ตาลสุม อุบลราชธานี 34330",
      label: "คำหว้า ตาลสุม อุบลราชธานี 34330",
    },
    {
      value: "จิกเทิง ตาลสุม อุบลราชธานี 34330",
      label: "จิกเทิง ตาลสุม อุบลราชธานี 34330",
    },
    {
      value: "ตาลสุม ตาลสุม อุบลราชธานี 34330",
      label: "ตาลสุม ตาลสุม อุบลราชธานี 34330",
    },
    {
      value: "นาคาย ตาลสุม อุบลราชธานี 34330",
      label: "นาคาย ตาลสุม อุบลราชธานี 34330",
    },
    {
      value: "สำโรง ตาลสุม อุบลราชธานี 34330",
      label: "สำโรง ตาลสุม อุบลราชธานี 34330",
    },
    {
      value: "หนองกุง ตาลสุม อุบลราชธานี 34330",
      label: "หนองกุง ตาลสุม อุบลราชธานี 34330",
    },
    {
      value: "กุดเรือ ทุ่งศรีอุดม อุบลราชธานี 34160",
      label: "กุดเรือ ทุ่งศรีอุดม อุบลราชธานี 34160",
    },
    {
      value: "นาห่อม ทุ่งศรีอุดม อุบลราชธานี 34160",
      label: "นาห่อม ทุ่งศรีอุดม อุบลราชธานี 34160",
    },
    {
      value: "นาเกษม ทุ่งศรีอุดม อุบลราชธานี 34160",
      label: "นาเกษม ทุ่งศรีอุดม อุบลราชธานี 34160",
    },
    {
      value: "หนองอ้ม ทุ่งศรีอุดม อุบลราชธานี 34160",
      label: "หนองอ้ม ทุ่งศรีอุดม อุบลราชธานี 34160",
    },
    {
      value: "โคกชำแระ ทุ่งศรีอุดม อุบลราชธานี 34160",
      label: "โคกชำแระ ทุ่งศรีอุดม อุบลราชธานี 34160",
    },
    {
      value: "นาจะหลวย นาจะหลวย อุบลราชธานี 34280",
      label: "นาจะหลวย นาจะหลวย อุบลราชธานี 34280",
    },
    {
      value: "บ้านตูม นาจะหลวย อุบลราชธานี 34280",
      label: "บ้านตูม นาจะหลวย อุบลราชธานี 34280",
    },
    {
      value: "พรสวรรค์ นาจะหลวย อุบลราชธานี 34280",
      label: "พรสวรรค์ นาจะหลวย อุบลราชธานี 34280",
    },
    {
      value: "โนนสมบูรณ์ นาจะหลวย อุบลราชธานี 34280",
      label: "โนนสมบูรณ์ นาจะหลวย อุบลราชธานี 34280",
    },
    {
      value: "โนนสวรรค์ นาจะหลวย อุบลราชธานี 34280",
      label: "โนนสวรรค์ นาจะหลวย อุบลราชธานี 34280",
    },
    {
      value: "โสกแสง นาจะหลวย อุบลราชธานี 34280",
      label: "โสกแสง นาจะหลวย อุบลราชธานี 34280",
    },
    {
      value: "กองโพน นาตาล อุบลราชธานี 34170",
      label: "กองโพน นาตาล อุบลราชธานี 34170",
    },
    {
      value: "นาตาล นาตาล อุบลราชธานี 34170",
      label: "นาตาล นาตาล อุบลราชธานี 34170",
    },
    {
      value: "พะลาน นาตาล อุบลราชธานี 34170",
      label: "พะลาน นาตาล อุบลราชธานี 34170",
    },
    {
      value: "พังเคน นาตาล อุบลราชธานี 34170",
      label: "พังเคน นาตาล อุบลราชธานี 34170",
    },
    {
      value: "นาดี นาเยีย อุบลราชธานี 34160",
      label: "นาดี นาเยีย อุบลราชธานี 34160",
    },
    {
      value: "นาเยีย นาเยีย อุบลราชธานี 34160",
      label: "นาเยีย นาเยีย อุบลราชธานี 34160",
    },
    {
      value: "นาเรือง นาเยีย อุบลราชธานี 34160",
      label: "นาเรือง นาเยีย อุบลราชธานี 34160",
    },
    {
      value: "ขี้เหล็ก น้ำขุ่น อุบลราชธานี 34260",
      label: "ขี้เหล็ก น้ำขุ่น อุบลราชธานี 34260",
    },
    {
      value: "ตาเกา น้ำขุ่น อุบลราชธานี 34260",
      label: "ตาเกา น้ำขุ่น อุบลราชธานี 34260",
    },
    {
      value: "โคกสะอาด น้ำขุ่น อุบลราชธานี 34260",
      label: "โคกสะอาด น้ำขุ่น อุบลราชธานี 34260",
    },
    {
      value: "ไพบูลย์ น้ำขุ่น อุบลราชธานี 34260",
      label: "ไพบูลย์ น้ำขุ่น อุบลราชธานี 34260",
    },
    {
      value: "บุเปือย น้ำยืน อุบลราชธานี 34260",
      label: "บุเปือย น้ำยืน อุบลราชธานี 34260",
    },
    {
      value: "ยาง น้ำยืน อุบลราชธานี 34260",
      label: "ยาง น้ำยืน อุบลราชธานี 34260",
    },
    {
      value: "ยางใหญ่ น้ำยืน อุบลราชธานี 34260",
      label: "ยางใหญ่ น้ำยืน อุบลราชธานี 34260",
    },
    {
      value: "สีวิเชียร น้ำยืน อุบลราชธานี 34260",
      label: "สีวิเชียร น้ำยืน อุบลราชธานี 34260",
    },
    {
      value: "เก่าขาม น้ำยืน อุบลราชธานี 34260",
      label: "เก่าขาม น้ำยืน อุบลราชธานี 34260",
    },
    {
      value: "โซง น้ำยืน อุบลราชธานี 34260",
      label: "โซง น้ำยืน อุบลราชธานี 34260",
    },
    {
      value: "โดมประดิษฐ์ น้ำยืน อุบลราชธานี 34260",
      label: "โดมประดิษฐ์ น้ำยืน อุบลราชธานี 34260",
    },
    {
      value: "คอแลน บุณฑริก อุบลราชธานี 34230",
      label: "คอแลน บุณฑริก อุบลราชธานี 34230",
    },
    {
      value: "นาโพธิ์ บุณฑริก อุบลราชธานี 34230",
      label: "นาโพธิ์ บุณฑริก อุบลราชธานี 34230",
    },
    {
      value: "บัวงาม บุณฑริก อุบลราชธานี 34230",
      label: "บัวงาม บุณฑริก อุบลราชธานี 34230",
    },
    {
      value: "บ้านแมด บุณฑริก อุบลราชธานี 34230",
      label: "บ้านแมด บุณฑริก อุบลราชธานี 34230",
    },
    {
      value: "หนองสะโน บุณฑริก อุบลราชธานี 34230",
      label: "หนองสะโน บุณฑริก อุบลราชธานี 34230",
    },
    {
      value: "ห้วยข่า บุณฑริก อุบลราชธานี 34230",
      label: "ห้วยข่า บุณฑริก อุบลราชธานี 34230",
    },
    {
      value: "โนนค้อ บุณฑริก อุบลราชธานี 34230",
      label: "โนนค้อ บุณฑริก อุบลราชธานี 34230",
    },
    {
      value: "โพนงาม บุณฑริก อุบลราชธานี 34230",
      label: "โพนงาม บุณฑริก อุบลราชธานี 34230",
    },
    {
      value: "กุดชมภู พิบูลมังสาหาร อุบลราชธานี 34110",
      label: "กุดชมภู พิบูลมังสาหาร อุบลราชธานี 34110",
    },
    {
      value: "ดอนจิก พิบูลมังสาหาร อุบลราชธานี 34110",
      label: "ดอนจิก พิบูลมังสาหาร อุบลราชธานี 34110",
    },
    {
      value: "ทรายมูล พิบูลมังสาหาร อุบลราชธานี 34110",
      label: "ทรายมูล พิบูลมังสาหาร อุบลราชธานี 34110",
    },
    {
      value: "นาโพธิ์ พิบูลมังสาหาร อุบลราชธานี 34110",
      label: "นาโพธิ์ พิบูลมังสาหาร อุบลราชธานี 34110",
    },
    {
      value: "บ้านแขม พิบูลมังสาหาร อุบลราชธานี 34110",
      label: "บ้านแขม พิบูลมังสาหาร อุบลราชธานี 34110",
    },
    {
      value: "พิบูล พิบูลมังสาหาร อุบลราชธานี 34110",
      label: "พิบูล พิบูลมังสาหาร อุบลราชธานี 34110",
    },
    {
      value: "ระเว พิบูลมังสาหาร อุบลราชธานี 34110",
      label: "ระเว พิบูลมังสาหาร อุบลราชธานี 34110",
    },
    {
      value: "หนองบัวฮี พิบูลมังสาหาร อุบลราชธานี 34110",
      label: "หนองบัวฮี พิบูลมังสาหาร อุบลราชธานี 34110",
    },
    {
      value: "อ่างศิลา พิบูลมังสาหาร อุบลราชธานี 34110",
      label: "อ่างศิลา พิบูลมังสาหาร อุบลราชธานี 34110",
    },
    {
      value: "โนนกลาง พิบูลมังสาหาร อุบลราชธานี 34110",
      label: "โนนกลาง พิบูลมังสาหาร อุบลราชธานี 34110",
    },
    {
      value: "โนนกาหลง พิบูลมังสาหาร อุบลราชธานี 34110",
      label: "โนนกาหลง พิบูลมังสาหาร อุบลราชธานี 34110",
    },
    {
      value: "โพธิ์ศรี พิบูลมังสาหาร อุบลราชธานี 34110",
      label: "โพธิ์ศรี พิบูลมังสาหาร อุบลราชธานี 34110",
    },
    {
      value: "โพธิ์ไทร พิบูลมังสาหาร อุบลราชธานี 34110",
      label: "โพธิ์ไทร พิบูลมังสาหาร อุบลราชธานี 34110",
    },
    {
      value: "ไร่ใต้ พิบูลมังสาหาร อุบลราชธานี 34110",
      label: "ไร่ใต้ พิบูลมังสาหาร อุบลราชธานี 34110",
    },
    {
      value: "ดุมใหญ่ ม่วงสามสิบ อุบลราชธานี 34140",
      label: "ดุมใหญ่ ม่วงสามสิบ อุบลราชธานี 34140",
    },
    {
      value: "นาเลิง ม่วงสามสิบ อุบลราชธานี 34140",
      label: "นาเลิง ม่วงสามสิบ อุบลราชธานี 34140",
    },
    {
      value: "ม่วงสามสิบ ม่วงสามสิบ อุบลราชธานี 34140",
      label: "ม่วงสามสิบ ม่วงสามสิบ อุบลราชธานี 34140",
    },
    {
      value: "ยางสักกระโพหลุ่ม ม่วงสามสิบ อุบลราชธานี 34140",
      label: "ยางสักกระโพหลุ่ม ม่วงสามสิบ อุบลราชธานี 34140",
    },
    {
      value: "ยางโยภาพ ม่วงสามสิบ อุบลราชธานี 34140",
      label: "ยางโยภาพ ม่วงสามสิบ อุบลราชธานี 34140",
    },
    {
      value: "หนองช้างใหญ่ ม่วงสามสิบ อุบลราชธานี 34140",
      label: "หนองช้างใหญ่ ม่วงสามสิบ อุบลราชธานี 34140",
    },
    {
      value: "หนองฮาง ม่วงสามสิบ อุบลราชธานี 34140",
      label: "หนองฮาง ม่วงสามสิบ อุบลราชธานี 34140",
    },
    {
      value: "หนองเมือง ม่วงสามสิบ อุบลราชธานี 34140",
      label: "หนองเมือง ม่วงสามสิบ อุบลราชธานี 34140",
    },
    {
      value: "หนองเหล่า ม่วงสามสิบ อุบลราชธานี 34140",
      label: "หนองเหล่า ม่วงสามสิบ อุบลราชธานี 34140",
    },
    {
      value: "หนองไข่นก ม่วงสามสิบ อุบลราชธานี 34140",
      label: "หนองไข่นก ม่วงสามสิบ อุบลราชธานี 34140",
    },
    {
      value: "เตย ม่วงสามสิบ อุบลราชธานี 34140",
      label: "เตย ม่วงสามสิบ อุบลราชธานี 34140",
    },
    {
      value: "เหล่าบก ม่วงสามสิบ อุบลราชธานี 34140",
      label: "เหล่าบก ม่วงสามสิบ อุบลราชธานี 34140",
    },
    {
      value: "โพนแพง ม่วงสามสิบ อุบลราชธานี 34140",
      label: "โพนแพง ม่วงสามสิบ อุบลราชธานี 34140",
    },
    {
      value: "ไผ่ใหญ่ ม่วงสามสิบ อุบลราชธานี 34140",
      label: "ไผ่ใหญ่ ม่วงสามสิบ อุบลราชธานี 34140",
    },
    {
      value: "คำขวาง วารินชำราบ อุบลราชธานี 34190",
      label: "คำขวาง วารินชำราบ อุบลราชธานี 34190",
    },
    {
      value: "คำน้ำแซบ วารินชำราบ อุบลราชธานี 34190",
      label: "คำน้ำแซบ วารินชำราบ อุบลราชธานี 34190",
    },
    {
      value: "คูเมือง วารินชำราบ อุบลราชธานี 34190",
      label: "คูเมือง วารินชำราบ อุบลราชธานี 34190",
    },
    {
      value: "ท่าลาด วารินชำราบ อุบลราชธานี 34310",
      label: "ท่าลาด วารินชำราบ อุบลราชธานี 34310",
    },
    {
      value: "ธาตุ วารินชำราบ อุบลราชธานี 34190",
      label: "ธาตุ วารินชำราบ อุบลราชธานี 34190",
    },
    {
      value: "บุ่งหวาย วารินชำราบ อุบลราชธานี 34310",
      label: "บุ่งหวาย วารินชำราบ อุบลราชธานี 34310",
    },
    {
      value: "บุ่งไหม วารินชำราบ อุบลราชธานี 34190",
      label: "บุ่งไหม วารินชำราบ อุบลราชธานี 34190",
    },
    {
      value: "วารินชำราบ วารินชำราบ อุบลราชธานี 34190",
      label: "วารินชำราบ วารินชำราบ อุบลราชธานี 34190",
    },
    {
      value: "สระสมิง วารินชำราบ อุบลราชธานี 34190",
      label: "สระสมิง วารินชำราบ อุบลราชธานี 34190",
    },
    {
      value: "หนองกินเพล วารินชำราบ อุบลราชธานี 34190",
      label: "หนองกินเพล วารินชำราบ อุบลราชธานี 34190",
    },
    {
      value: "ห้วยขะยูง วารินชำราบ อุบลราชธานี 34310",
      label: "ห้วยขะยูง วารินชำราบ อุบลราชธานี 34310",
    },
    {
      value: "เมืองศรีไค วารินชำราบ อุบลราชธานี 34190",
      label: "เมืองศรีไค วารินชำราบ อุบลราชธานี 34190",
    },
    {
      value: "แสนสุข วารินชำราบ อุบลราชธานี 34190",
      label: "แสนสุข วารินชำราบ อุบลราชธานี 34190",
    },
    {
      value: "โนนผึ้ง วารินชำราบ อุบลราชธานี 34190",
      label: "โนนผึ้ง วารินชำราบ อุบลราชธานี 34190",
    },
    {
      value: "โนนโหนน วารินชำราบ อุบลราชธานี 34190",
      label: "โนนโหนน วารินชำราบ อุบลราชธานี 34190",
    },
    {
      value: "โพธิ์ใหญ่ วารินชำราบ อุบลราชธานี 34190",
      label: "โพธิ์ใหญ่ วารินชำราบ อุบลราชธานี 34190",
    },
    {
      value: "คำไหล ศรีเมืองใหม่ อุบลราชธานี 34250",
      label: "คำไหล ศรีเมืองใหม่ อุบลราชธานี 34250",
    },
    {
      value: "ดอนใหญ่ ศรีเมืองใหม่ อุบลราชธานี 34250",
      label: "ดอนใหญ่ ศรีเมืองใหม่ อุบลราชธานี 34250",
    },
    {
      value: "ตะบ่าย ศรีเมืองใหม่ อุบลราชธานี 34250",
      label: "ตะบ่าย ศรีเมืองใหม่ อุบลราชธานี 34250",
    },
    {
      value: "นาคำ ศรีเมืองใหม่ อุบลราชธานี 34250",
      label: "นาคำ ศรีเมืองใหม่ อุบลราชธานี 34250",
    },
    {
      value: "นาเลิน ศรีเมืองใหม่ อุบลราชธานี 34250",
      label: "นาเลิน ศรีเมืองใหม่ อุบลราชธานี 34250",
    },
    {
      value: "ลาดควาย ศรีเมืองใหม่ อุบลราชธานี 34250",
      label: "ลาดควาย ศรีเมืองใหม่ อุบลราชธานี 34250",
    },
    {
      value: "วาริน ศรีเมืองใหม่ อุบลราชธานี 34250",
      label: "วาริน ศรีเมืองใหม่ อุบลราชธานี 34250",
    },
    {
      value: "สงยาง ศรีเมืองใหม่ อุบลราชธานี 34250",
      label: "สงยาง ศรีเมืองใหม่ อุบลราชธานี 34250",
    },
    {
      value: "หนามแท่ง ศรีเมืองใหม่ อุบลราชธานี 34250",
      label: "หนามแท่ง ศรีเมืองใหม่ อุบลราชธานี 34250",
    },
    {
      value: "เอือดใหญ่ ศรีเมืองใหม่ อุบลราชธานี 34250",
      label: "เอือดใหญ่ ศรีเมืองใหม่ อุบลราชธานี 34250",
    },
    {
      value: "แก้งกอก ศรีเมืองใหม่ อุบลราชธานี 34250",
      label: "แก้งกอก ศรีเมืองใหม่ อุบลราชธานี 34250",
    },
    {
      value: "ท่าช้าง สว่างวีระวงศ์ อุบลราชธานี 34190",
      label: "ท่าช้าง สว่างวีระวงศ์ อุบลราชธานี 34190",
    },
    {
      value: "บุ่งมะแลง สว่างวีระวงศ์ อุบลราชธานี 34190",
      label: "บุ่งมะแลง สว่างวีระวงศ์ อุบลราชธานี 34190",
    },
    {
      value: "สว่าง สว่างวีระวงศ์ อุบลราชธานี 34190",
      label: "สว่าง สว่างวีระวงศ์ อุบลราชธานี 34190",
    },
    {
      value: "แก่งโดม สว่างวีระวงศ์ อุบลราชธานี 34190",
      label: "แก่งโดม สว่างวีระวงศ์ อุบลราชธานี 34190",
    },
    {
      value: "ขามป้อม สำโรง อุบลราชธานี 34360",
      label: "ขามป้อม สำโรง อุบลราชธานี 34360",
    },
    {
      value: "ค้อน้อย สำโรง อุบลราชธานี 34360",
      label: "ค้อน้อย สำโรง อุบลราชธานี 34360",
    },
    {
      value: "บอน สำโรง อุบลราชธานี 34360",
      label: "บอน สำโรง อุบลราชธานี 34360",
    },
    {
      value: "สำโรง สำโรง อุบลราชธานี 34360",
      label: "สำโรง สำโรง อุบลราชธานี 34360",
    },
    {
      value: "หนองไฮ สำโรง อุบลราชธานี 34360",
      label: "หนองไฮ สำโรง อุบลราชธานี 34360",
    },
    {
      value: "โคกก่อง สำโรง อุบลราชธานี 34360",
      label: "โคกก่อง สำโรง อุบลราชธานี 34360",
    },
    {
      value: "โคกสว่าง สำโรง อุบลราชธานี 34360",
      label: "โคกสว่าง สำโรง อุบลราชธานี 34360",
    },
    {
      value: "โนนกลาง สำโรง อุบลราชธานี 34360",
      label: "โนนกลาง สำโรง อุบลราชธานี 34360",
    },
    {
      value: "โนนกาเล็น สำโรง อุบลราชธานี 34360",
      label: "โนนกาเล็น สำโรง อุบลราชธานี 34360",
    },
    {
      value: "คันไร่ สิรินธร อุบลราชธานี 34350",
      label: "คันไร่ สิรินธร อุบลราชธานี 34350",
    },
    {
      value: "คำเขื่อนแก้ว สิรินธร อุบลราชธานี 34350",
      label: "คำเขื่อนแก้ว สิรินธร อุบลราชธานี 34350",
    },
    {
      value: "ช่องเม็ก สิรินธร อุบลราชธานี 34350",
      label: "ช่องเม็ก สิรินธร อุบลราชธานี 34350",
    },
    {
      value: "นิคมสร้างตนเองลำโดมน้อย สิรินธร อุบลราชธานี 34350",
      label: "นิคมสร้างตนเองลำโดมน้อย สิรินธร อุบลราชธานี 34350",
    },
    {
      value: "ฝางคำ สิรินธร อุบลราชธานี 34350",
      label: "ฝางคำ สิรินธร อุบลราชธานี 34350",
    },
    {
      value: "โนนก่อ สิรินธร อุบลราชธานี 34350",
      label: "โนนก่อ สิรินธร อุบลราชธานี 34350",
    },
    {
      value: "ขามป้อม เขมราฐ อุบลราชธานี 34170",
      label: "ขามป้อม เขมราฐ อุบลราชธานี 34170",
    },
    {
      value: "นาแวง เขมราฐ อุบลราชธานี 34170",
      label: "นาแวง เขมราฐ อุบลราชธานี 34170",
    },
    {
      value: "หนองนกทา เขมราฐ อุบลราชธานี 34170",
      label: "หนองนกทา เขมราฐ อุบลราชธานี 34170",
    },
    {
      value: "หนองผือ เขมราฐ อุบลราชธานี 34170",
      label: "หนองผือ เขมราฐ อุบลราชธานี 34170",
    },
    {
      value: "หนองสิม เขมราฐ อุบลราชธานี 34170",
      label: "หนองสิม เขมราฐ อุบลราชธานี 34170",
    },
    {
      value: "หัวนา เขมราฐ อุบลราชธานี 34170",
      label: "หัวนา เขมราฐ อุบลราชธานี 34170",
    },
    {
      value: "เขมราฐ เขมราฐ อุบลราชธานี 34170",
      label: "เขมราฐ เขมราฐ อุบลราชธานี 34170",
    },
    {
      value: "เจียด เขมราฐ อุบลราชธานี 34170",
      label: "เจียด เขมราฐ อุบลราชธานี 34170",
    },
    {
      value: "แก้งเหนือ เขมราฐ อุบลราชธานี 34170",
      label: "แก้งเหนือ เขมราฐ อุบลราชธานี 34170",
    },
    {
      value: "กลางใหญ่ เขื่องใน อุบลราชธานี 34320",
      label: "กลางใหญ่ เขื่องใน อุบลราชธานี 34320",
    },
    {
      value: "ก่อเอ้ เขื่องใน อุบลราชธานี 34150",
      label: "ก่อเอ้ เขื่องใน อุบลราชธานี 34150",
    },
    {
      value: "ค้อทอง เขื่องใน อุบลราชธานี 34150",
      label: "ค้อทอง เขื่องใน อุบลราชธานี 34150",
    },
    {
      value: "ชีทวน เขื่องใน อุบลราชธานี 34150",
      label: "ชีทวน เขื่องใน อุบลราชธานี 34150",
    },
    {
      value: "ท่าไห เขื่องใน อุบลราชธานี 34150",
      label: "ท่าไห เขื่องใน อุบลราชธานี 34150",
    },
    {
      value: "ธาตุน้อย เขื่องใน อุบลราชธานี 34150",
      label: "ธาตุน้อย เขื่องใน อุบลราชธานี 34150",
    },
    {
      value: "นาคำใหญ่ เขื่องใน อุบลราชธานี 34150",
      label: "นาคำใหญ่ เขื่องใน อุบลราชธานี 34150",
    },
    {
      value: "บ้านกอก เขื่องใน อุบลราชธานี 34320",
      label: "บ้านกอก เขื่องใน อุบลราชธานี 34320",
    },
    {
      value: "บ้านไทย เขื่องใน อุบลราชธานี 34320",
      label: "บ้านไทย เขื่องใน อุบลราชธานี 34320",
    },
    {
      value: "ยางขี้นก เขื่องใน อุบลราชธานี 34150",
      label: "ยางขี้นก เขื่องใน อุบลราชธานี 34150",
    },
    {
      value: "ศรีสุข เขื่องใน อุบลราชธานี 34150",
      label: "ศรีสุข เขื่องใน อุบลราชธานี 34150",
    },
    {
      value: "สร้างถ่อ เขื่องใน อุบลราชธานี 34150",
      label: "สร้างถ่อ เขื่องใน อุบลราชธานี 34150",
    },
    {
      value: "สหธาตุ เขื่องใน อุบลราชธานี 34150",
      label: "สหธาตุ เขื่องใน อุบลราชธานี 34150",
    },
    {
      value: "หนองเหล่า เขื่องใน อุบลราชธานี 34150",
      label: "หนองเหล่า เขื่องใน อุบลราชธานี 34150",
    },
    {
      value: "หัวดอน เขื่องใน อุบลราชธานี 34150",
      label: "หัวดอน เขื่องใน อุบลราชธานี 34150",
    },
    {
      value: "เขื่องใน เขื่องใน อุบลราชธานี 34150",
      label: "เขื่องใน เขื่องใน อุบลราชธานี 34150",
    },
    {
      value: "แดงหม้อ เขื่องใน อุบลราชธานี 34150",
      label: "แดงหม้อ เขื่องใน อุบลราชธานี 34150",
    },
    {
      value: "โนนรัง เขื่องใน อุบลราชธานี 34320",
      label: "โนนรัง เขื่องใน อุบลราชธานี 34320",
    },
    {
      value: "กลาง เดชอุดม อุบลราชธานี 34160",
      label: "กลาง เดชอุดม อุบลราชธานี 34160",
    },
    {
      value: "กุดประทาย เดชอุดม อุบลราชธานี 34160",
      label: "กุดประทาย เดชอุดม อุบลราชธานี 34160",
    },
    {
      value: "คำครั่ง เดชอุดม อุบลราชธานี 34160",
      label: "คำครั่ง เดชอุดม อุบลราชธานี 34160",
    },
    {
      value: "ตบหู เดชอุดม อุบลราชธานี 34160",
      label: "ตบหู เดชอุดม อุบลราชธานี 34160",
    },
    {
      value: "ทุ่งเทิง เดชอุดม อุบลราชธานี 34160",
      label: "ทุ่งเทิง เดชอุดม อุบลราชธานี 34160",
    },
    {
      value: "ท่าโพธิ์ศรี เดชอุดม อุบลราชธานี 34160",
      label: "ท่าโพธิ์ศรี เดชอุดม อุบลราชธานี 34160",
    },
    {
      value: "นากระแซง เดชอุดม อุบลราชธานี 34160",
      label: "นากระแซง เดชอุดม อุบลราชธานี 34160",
    },
    {
      value: "นาส่วง เดชอุดม อุบลราชธานี 34160",
      label: "นาส่วง เดชอุดม อุบลราชธานี 34160",
    },
    {
      value: "นาเจริญ เดชอุดม อุบลราชธานี 34160",
      label: "นาเจริญ เดชอุดม อุบลราชธานี 34160",
    },
    {
      value: "บัวงาม เดชอุดม อุบลราชธานี 34160",
      label: "บัวงาม เดชอุดม อุบลราชธานี 34160",
    },
    {
      value: "ป่าโมง เดชอุดม อุบลราชธานี 34160",
      label: "ป่าโมง เดชอุดม อุบลราชธานี 34160",
    },
    {
      value: "สมสะอาด เดชอุดม อุบลราชธานี 34160",
      label: "สมสะอาด เดชอุดม อุบลราชธานี 34160",
    },
    {
      value: "เมืองเดช เดชอุดม อุบลราชธานี 34160",
      label: "เมืองเดช เดชอุดม อุบลราชธานี 34160",
    },
    {
      value: "แก้ง เดชอุดม อุบลราชธานี 34160",
      label: "แก้ง เดชอุดม อุบลราชธานี 34160",
    },
    {
      value: "โนนสมบูรณ์ เดชอุดม อุบลราชธานี 34160",
      label: "โนนสมบูรณ์ เดชอุดม อุบลราชธานี 34160",
    },
    {
      value: "โพนงาม เดชอุดม อุบลราชธานี 34160",
      label: "โพนงาม เดชอุดม อุบลราชธานี 34160",
    },
    {
      value: "กระโสบ เมืองอุบลราชธานี อุบลราชธานี 34000",
      label: "กระโสบ เมืองอุบลราชธานี อุบลราชธานี 34000",
    },
    {
      value: "กุดลาด เมืองอุบลราชธานี อุบลราชธานี 34000",
      label: "กุดลาด เมืองอุบลราชธานี อุบลราชธานี 34000",
    },
    {
      value: "ขามใหญ่ เมืองอุบลราชธานี อุบลราชธานี 34000",
      label: "ขามใหญ่ เมืองอุบลราชธานี อุบลราชธานี 34000",
    },
    {
      value: "ขี้เหล็ก เมืองอุบลราชธานี อุบลราชธานี 34000",
      label: "ขี้เหล็ก เมืองอุบลราชธานี อุบลราชธานี 34000",
    },
    {
      value: "ปทุม เมืองอุบลราชธานี อุบลราชธานี 34000",
      label: "ปทุม เมืองอุบลราชธานี อุบลราชธานี 34000",
    },
    {
      value: "ปะอาว เมืองอุบลราชธานี อุบลราชธานี 34000",
      label: "ปะอาว เมืองอุบลราชธานี อุบลราชธานี 34000",
    },
    {
      value: "หนองขอน เมืองอุบลราชธานี อุบลราชธานี 34000",
      label: "หนองขอน เมืองอุบลราชธานี อุบลราชธานี 34000",
    },
    {
      value: "หนองบ่อ เมืองอุบลราชธานี อุบลราชธานี 34000",
      label: "หนองบ่อ เมืองอุบลราชธานี อุบลราชธานี 34000",
    },
    {
      value: "หัวเรือ เมืองอุบลราชธานี อุบลราชธานี 34000",
      label: "หัวเรือ เมืองอุบลราชธานี อุบลราชธานี 34000",
    },
    {
      value: "แจระแม เมืองอุบลราชธานี อุบลราชธานี 34000",
      label: "แจระแม เมืองอุบลราชธานี อุบลราชธานี 34000",
    },
    {
      value: "ในเมือง เมืองอุบลราชธานี อุบลราชธานี 34000",
      label: "ในเมือง เมืองอุบลราชธานี อุบลราชธานี 34000",
    },
    {
      value: "ไร่น้อย เมืองอุบลราชธานี อุบลราชธานี 34000",
      label: "ไร่น้อย เมืองอุบลราชธานี อุบลราชธานี 34000",
    },
    {
      value: "หนองบก เหล่าเสือโก้ก อุบลราชธานี 34000",
      label: "หนองบก เหล่าเสือโก้ก อุบลราชธานี 34000",
    },
    {
      value: "เหล่าเสือโก้ก เหล่าเสือโก้ก อุบลราชธานี 34000",
      label: "เหล่าเสือโก้ก เหล่าเสือโก้ก อุบลราชธานี 34000",
    },
    {
      value: "แพงใหญ่ เหล่าเสือโก้ก อุบลราชธานี 34000",
      label: "แพงใหญ่ เหล่าเสือโก้ก อุบลราชธานี 34000",
    },
    {
      value: "โพนเมือง เหล่าเสือโก้ก อุบลราชธานี 34000",
      label: "โพนเมือง เหล่าเสือโก้ก อุบลราชธานี 34000",
    },
    {
      value: "นาโพธิ์กลาง โขงเจียม อุบลราชธานี 34220",
      label: "นาโพธิ์กลาง โขงเจียม อุบลราชธานี 34220",
    },
    {
      value: "หนองแสงใหญ่ โขงเจียม อุบลราชธานี 34220",
      label: "หนองแสงใหญ่ โขงเจียม อุบลราชธานี 34220",
    },
    {
      value: "ห้วยยาง โขงเจียม อุบลราชธานี 34220",
      label: "ห้วยยาง โขงเจียม อุบลราชธานี 34220",
    },
    {
      value: "ห้วยไผ่ โขงเจียม อุบลราชธานี 34220",
      label: "ห้วยไผ่ โขงเจียม อุบลราชธานี 34220",
    },
    {
      value: "โขงเจียม โขงเจียม อุบลราชธานี 34220",
      label: "โขงเจียม โขงเจียม อุบลราชธานี 34220",
    },
    {
      value: "ม่วงใหญ่ โพธิ์ไทร อุบลราชธานี 34340",
      label: "ม่วงใหญ่ โพธิ์ไทร อุบลราชธานี 34340",
    },
    {
      value: "สองคอน โพธิ์ไทร อุบลราชธานี 34340",
      label: "สองคอน โพธิ์ไทร อุบลราชธานี 34340",
    },
    {
      value: "สารภี โพธิ์ไทร อุบลราชธานี 34340",
      label: "สารภี โพธิ์ไทร อุบลราชธานี 34340",
    },
    {
      value: "สำโรง โพธิ์ไทร อุบลราชธานี 34340",
      label: "สำโรง โพธิ์ไทร อุบลราชธานี 34340",
    },
    {
      value: "เหล่างาม โพธิ์ไทร อุบลราชธานี 34340",
      label: "เหล่างาม โพธิ์ไทร อุบลราชธานี 34340",
    },
    {
      value: "โพธิ์ไทร โพธิ์ไทร อุบลราชธานี 34340",
      label: "โพธิ์ไทร โพธิ์ไทร อุบลราชธานี 34340",
    },
    {
      value: "คำเขื่อนแก้ว ชานุมาน อำนาจเจริญ 37210",
      label: "คำเขื่อนแก้ว ชานุมาน อำนาจเจริญ 37210",
    },
    {
      value: "ชานุมาน ชานุมาน อำนาจเจริญ 37210",
      label: "ชานุมาน ชานุมาน อำนาจเจริญ 37210",
    },
    {
      value: "ป่าก่อ ชานุมาน อำนาจเจริญ 37210",
      label: "ป่าก่อ ชานุมาน อำนาจเจริญ 37210",
    },
    {
      value: "โคกก่ง ชานุมาน อำนาจเจริญ 37210",
      label: "โคกก่ง ชานุมาน อำนาจเจริญ 37210",
    },
    {
      value: "โคกสาร ชานุมาน อำนาจเจริญ 37210",
      label: "โคกสาร ชานุมาน อำนาจเจริญ 37210",
    },
    {
      value: "คำโพน ปทุมราชวงศา อำนาจเจริญ 37110",
      label: "คำโพน ปทุมราชวงศา อำนาจเจริญ 37110",
    },
    {
      value: "นาป่าแซง ปทุมราชวงศา อำนาจเจริญ 37110",
      label: "นาป่าแซง ปทุมราชวงศา อำนาจเจริญ 37110",
    },
    {
      value: "นาหว้า ปทุมราชวงศา อำนาจเจริญ 37110",
      label: "นาหว้า ปทุมราชวงศา อำนาจเจริญ 37110",
    },
    {
      value: "ลือ ปทุมราชวงศา อำนาจเจริญ 37110",
      label: "ลือ ปทุมราชวงศา อำนาจเจริญ 37110",
    },
    {
      value: "หนองข่า ปทุมราชวงศา อำนาจเจริญ 37110",
      label: "หนองข่า ปทุมราชวงศา อำนาจเจริญ 37110",
    },
    {
      value: "ห้วย ปทุมราชวงศา อำนาจเจริญ 37110",
      label: "ห้วย ปทุมราชวงศา อำนาจเจริญ 37110",
    },
    {
      value: "โนนงาม ปทุมราชวงศา อำนาจเจริญ 37110",
      label: "โนนงาม ปทุมราชวงศา อำนาจเจริญ 37110",
    },
    {
      value: "จานลาน พนา อำนาจเจริญ 37180",
      label: "จานลาน พนา อำนาจเจริญ 37180",
    },
    { value: "พนา พนา อำนาจเจริญ 37180", label: "พนา พนา อำนาจเจริญ 37180" },
    {
      value: "พระเหลา พนา อำนาจเจริญ 37180",
      label: "พระเหลา พนา อำนาจเจริญ 37180",
    },
    {
      value: "ไม้กลอน พนา อำนาจเจริญ 37180",
      label: "ไม้กลอน พนา อำนาจเจริญ 37180",
    },
    {
      value: "ดงบัง ลืออำนาจ อำนาจเจริญ 37120",
      label: "ดงบัง ลืออำนาจ อำนาจเจริญ 37120",
    },
    {
      value: "ดงมะยาง ลืออำนาจ อำนาจเจริญ 37120",
      label: "ดงมะยาง ลืออำนาจ อำนาจเจริญ 37120",
    },
    {
      value: "อำนาจ ลืออำนาจ อำนาจเจริญ 37120",
      label: "อำนาจ ลืออำนาจ อำนาจเจริญ 37120",
    },
    {
      value: "เปือย ลืออำนาจ อำนาจเจริญ 37120",
      label: "เปือย ลืออำนาจ อำนาจเจริญ 37120",
    },
    {
      value: "แมด ลืออำนาจ อำนาจเจริญ 37120",
      label: "แมด ลืออำนาจ อำนาจเจริญ 37120",
    },
    {
      value: "โคกกลาง ลืออำนาจ อำนาจเจริญ 37120",
      label: "โคกกลาง ลืออำนาจ อำนาจเจริญ 37120",
    },
    {
      value: "ไร่ขี ลืออำนาจ อำนาจเจริญ 37120",
      label: "ไร่ขี ลืออำนาจ อำนาจเจริญ 37120",
    },
    {
      value: "คำพระ หัวตะพาน อำนาจเจริญ 37240",
      label: "คำพระ หัวตะพาน อำนาจเจริญ 37240",
    },
    {
      value: "จิกดู่ หัวตะพาน อำนาจเจริญ 37240",
      label: "จิกดู่ หัวตะพาน อำนาจเจริญ 37240",
    },
    {
      value: "รัตนวารี หัวตะพาน อำนาจเจริญ 37240",
      label: "รัตนวารี หัวตะพาน อำนาจเจริญ 37240",
    },
    {
      value: "สร้างถ่อน้อย หัวตะพาน อำนาจเจริญ 37240",
      label: "สร้างถ่อน้อย หัวตะพาน อำนาจเจริญ 37240",
    },
    {
      value: "หนองแก้ว หัวตะพาน อำนาจเจริญ 37240",
      label: "หนองแก้ว หัวตะพาน อำนาจเจริญ 37240",
    },
    {
      value: "หัวตะพาน หัวตะพาน อำนาจเจริญ 37240",
      label: "หัวตะพาน หัวตะพาน อำนาจเจริญ 37240",
    },
    {
      value: "เค็งใหญ่ หัวตะพาน อำนาจเจริญ 37240",
      label: "เค็งใหญ่ หัวตะพาน อำนาจเจริญ 37240",
    },
    {
      value: "โพนเมืองน้อย หัวตะพาน อำนาจเจริญ 37240",
      label: "โพนเมืองน้อย หัวตะพาน อำนาจเจริญ 37240",
    },
    {
      value: "กุดปลาดุก เมืองอำนาจเจริญ อำนาจเจริญ 37000",
      label: "กุดปลาดุก เมืองอำนาจเจริญ อำนาจเจริญ 37000",
    },
    {
      value: "คึมใหญ่ เมืองอำนาจเจริญ อำนาจเจริญ 37000",
      label: "คึมใหญ่ เมืองอำนาจเจริญ อำนาจเจริญ 37000",
    },
    {
      value: "ดอนเมย เมืองอำนาจเจริญ อำนาจเจริญ 37000",
      label: "ดอนเมย เมืองอำนาจเจริญ อำนาจเจริญ 37000",
    },
    {
      value: "นาจิก เมืองอำนาจเจริญ อำนาจเจริญ 37000",
      label: "นาจิก เมืองอำนาจเจริญ อำนาจเจริญ 37000",
    },
    {
      value: "นาผือ เมืองอำนาจเจริญ อำนาจเจริญ 37000",
      label: "นาผือ เมืองอำนาจเจริญ อำนาจเจริญ 37000",
    },
    {
      value: "นายม เมืองอำนาจเจริญ อำนาจเจริญ 37000",
      label: "นายม เมืองอำนาจเจริญ อำนาจเจริญ 37000",
    },
    {
      value: "นาวัง เมืองอำนาจเจริญ อำนาจเจริญ 37000",
      label: "นาวัง เมืองอำนาจเจริญ อำนาจเจริญ 37000",
    },
    {
      value: "นาหมอม้า เมืองอำนาจเจริญ อำนาจเจริญ 37000",
      label: "นาหมอม้า เมืองอำนาจเจริญ อำนาจเจริญ 37000",
    },
    {
      value: "นาแต้ เมืองอำนาจเจริญ อำนาจเจริญ 37000",
      label: "นาแต้ เมืองอำนาจเจริญ อำนาจเจริญ 37000",
    },
    {
      value: "น้ำปลีก เมืองอำนาจเจริญ อำนาจเจริญ 37000",
      label: "น้ำปลีก เมืองอำนาจเจริญ อำนาจเจริญ 37000",
    },
    {
      value: "บุ่ง เมืองอำนาจเจริญ อำนาจเจริญ 37000",
      label: "บุ่ง เมืองอำนาจเจริญ อำนาจเจริญ 37000",
    },
    {
      value: "ปลาค้าว เมืองอำนาจเจริญ อำนาจเจริญ 37000",
      label: "ปลาค้าว เมืองอำนาจเจริญ อำนาจเจริญ 37000",
    },
    {
      value: "สร้างนกทา เมืองอำนาจเจริญ อำนาจเจริญ 37000",
      label: "สร้างนกทา เมืองอำนาจเจริญ อำนาจเจริญ 37000",
    },
    {
      value: "หนองมะแซว เมืองอำนาจเจริญ อำนาจเจริญ 37000",
      label: "หนองมะแซว เมืองอำนาจเจริญ อำนาจเจริญ 37000",
    },
    {
      value: "ห้วยไร่ เมืองอำนาจเจริญ อำนาจเจริญ 37000",
      label: "ห้วยไร่ เมืองอำนาจเจริญ อำนาจเจริญ 37000",
    },
    {
      value: "เหล่าพรวน เมืองอำนาจเจริญ อำนาจเจริญ 37000",
      label: "เหล่าพรวน เมืองอำนาจเจริญ อำนาจเจริญ 37000",
    },
    {
      value: "โนนหนามแท่ง เมืองอำนาจเจริญ อำนาจเจริญ 37000",
      label: "โนนหนามแท่ง เมืองอำนาจเจริญ อำนาจเจริญ 37000",
    },
    {
      value: "โนนโพธิ์ เมืองอำนาจเจริญ อำนาจเจริญ 37000",
      label: "โนนโพธิ์ เมืองอำนาจเจริญ อำนาจเจริญ 37000",
    },
    {
      value: "ไก่คำ เมืองอำนาจเจริญ อำนาจเจริญ 37000",
      label: "ไก่คำ เมืองอำนาจเจริญ อำนาจเจริญ 37000",
    },
    {
      value: "นาเวียง เสนางคนิคม อำนาจเจริญ 37290",
      label: "นาเวียง เสนางคนิคม อำนาจเจริญ 37290",
    },
    {
      value: "หนองสามสี เสนางคนิคม อำนาจเจริญ 37290",
      label: "หนองสามสี เสนางคนิคม อำนาจเจริญ 37290",
    },
    {
      value: "หนองไฮ เสนางคนิคม อำนาจเจริญ 37290",
      label: "หนองไฮ เสนางคนิคม อำนาจเจริญ 37290",
    },
    {
      value: "เสนางคนิคม เสนางคนิคม อำนาจเจริญ 37290",
      label: "เสนางคนิคม เสนางคนิคม อำนาจเจริญ 37290",
    },
    {
      value: "โพนทอง เสนางคนิคม อำนาจเจริญ 37290",
      label: "โพนทอง เสนางคนิคม อำนาจเจริญ 37290",
    },
    {
      value: "ไร่สีสุก เสนางคนิคม อำนาจเจริญ 37290",
      label: "ไร่สีสุก เสนางคนิคม อำนาจเจริญ 37290",
    },
    {
      value: "คลองต้นไทร คลองสาน กรุงเทพมหานคร 10600",
      label: "คลองต้นไทร คลองสาน กรุงเทพมหานคร 10600",
    },
    {
      value: "คลองสาน คลองสาน กรุงเทพมหานคร 10600",
      label: "คลองสาน คลองสาน กรุงเทพมหานคร 10600",
    },
    {
      value: "บางลำภูล่าง คลองสาน กรุงเทพมหานคร 10600",
      label: "บางลำภูล่าง คลองสาน กรุงเทพมหานคร 10600",
    },
    {
      value: "สมเด็จเจ้าพระยา คลองสาน กรุงเทพมหานคร 10600",
      label: "สมเด็จเจ้าพระยา คลองสาน กรุงเทพมหานคร 10600",
    },
    {
      value: "ทรายกองดิน คลองสามวา กรุงเทพมหานคร 10510",
      label: "ทรายกองดิน คลองสามวา กรุงเทพมหานคร 10510",
    },
    {
      value: "ทรายกองดินใต้ คลองสามวา กรุงเทพมหานคร 10510",
      label: "ทรายกองดินใต้ คลองสามวา กรุงเทพมหานคร 10510",
    },
    {
      value: "บางชัน คลองสามวา กรุงเทพมหานคร 10510",
      label: "บางชัน คลองสามวา กรุงเทพมหานคร 10510",
    },
    {
      value: "สามวาตะวันตก คลองสามวา กรุงเทพมหานคร 10510",
      label: "สามวาตะวันตก คลองสามวา กรุงเทพมหานคร 10510",
    },
    {
      value: "สามวาตะวันออก คลองสามวา กรุงเทพมหานคร 10510",
      label: "สามวาตะวันออก คลองสามวา กรุงเทพมหานคร 10510",
    },
    {
      value: "คลองตัน คลองเตย กรุงเทพมหานคร 10110",
      label: "คลองตัน คลองเตย กรุงเทพมหานคร 10110",
    },
    {
      value: "คลองเตย คลองเตย กรุงเทพมหานคร 10110",
      label: "คลองเตย คลองเตย กรุงเทพมหานคร 10110",
    },
    {
      value: "พระโขนง คลองเตย กรุงเทพมหานคร 10110",
      label: "พระโขนง คลองเตย กรุงเทพมหานคร 10110",
    },
    {
      value: "พระโขนง คลองเตย กรุงเทพมหานคร 10260",
      label: "พระโขนง คลองเตย กรุงเทพมหานคร 10260",
    },
    {
      value: "คันนายาว คันนายาว กรุงเทพมหานคร 10230",
      label: "คันนายาว คันนายาว กรุงเทพมหานคร 10230",
    },
    {
      value: "รามอินทรา คันนายาว กรุงเทพมหานคร 10230",
      label: "รามอินทรา คันนายาว กรุงเทพมหานคร 10230",
    },
    {
      value: "จตุจักร จตุจักร กรุงเทพมหานคร 10900",
      label: "จตุจักร จตุจักร กรุงเทพมหานคร 10900",
    },
    {
      value: "จอมพล จตุจักร กรุงเทพมหานคร 10900",
      label: "จอมพล จตุจักร กรุงเทพมหานคร 10900",
    },
    {
      value: "จันทรเกษม จตุจักร กรุงเทพมหานคร 10900",
      label: "จันทรเกษม จตุจักร กรุงเทพมหานคร 10900",
    },
    {
      value: "ลาดยาว จตุจักร กรุงเทพมหานคร 10900",
      label: "ลาดยาว จตุจักร กรุงเทพมหานคร 10900",
    },
    {
      value: "เสนานิคม จตุจักร กรุงเทพมหานคร 10900",
      label: "เสนานิคม จตุจักร กรุงเทพมหานคร 10900",
    },
    {
      value: "จอมทอง จอมทอง กรุงเทพมหานคร 10150",
      label: "จอมทอง จอมทอง กรุงเทพมหานคร 10150",
    },
    {
      value: "บางขุนเทียน จอมทอง กรุงเทพมหานคร 10150",
      label: "บางขุนเทียน จอมทอง กรุงเทพมหานคร 10150",
    },
    {
      value: "บางค้อ จอมทอง กรุงเทพมหานคร 10150",
      label: "บางค้อ จอมทอง กรุงเทพมหานคร 10150",
    },
    {
      value: "บางมด จอมทอง กรุงเทพมหานคร 10150",
      label: "บางมด จอมทอง กรุงเทพมหานคร 10150",
    },
    {
      value: "ดอนเมือง ดอนเมือง กรุงเทพมหานคร 10210",
      label: "ดอนเมือง ดอนเมือง กรุงเทพมหานคร 10210",
    },
    {
      value: "สนามบิน ดอนเมือง กรุงเทพมหานคร 10210",
      label: "สนามบิน ดอนเมือง กรุงเทพมหานคร 10210",
    },
    {
      value: "สีกัน ดอนเมือง กรุงเทพมหานคร 10210",
      label: "สีกัน ดอนเมือง กรุงเทพมหานคร 10210",
    },
    {
      value: "ดินแดง ดินแดง กรุงเทพมหานคร 10400",
      label: "ดินแดง ดินแดง กรุงเทพมหานคร 10400",
    },
    {
      value: "ดุสิต ดุสิต กรุงเทพมหานคร 10300",
      label: "ดุสิต ดุสิต กรุงเทพมหานคร 10300",
    },
    {
      value: "ถนนนครไชยศรี ดุสิต กรุงเทพมหานคร 10300",
      label: "ถนนนครไชยศรี ดุสิต กรุงเทพมหานคร 10300",
    },
    {
      value: "วชิรพยาบาล ดุสิต กรุงเทพมหานคร 10300",
      label: "วชิรพยาบาล ดุสิต กรุงเทพมหานคร 10300",
    },
    {
      value: "สวนจิตรลดา ดุสิต กรุงเทพมหานคร 10300",
      label: "สวนจิตรลดา ดุสิต กรุงเทพมหานคร 10300",
    },
    {
      value: "สวนจิตรลดา ดุสิต กรุงเทพมหานคร 10303",
      label: "สวนจิตรลดา ดุสิต กรุงเทพมหานคร 10303",
    },
    {
      value: "สี่แยกมหานาค ดุสิต กรุงเทพมหานคร 10300",
      label: "สี่แยกมหานาค ดุสิต กรุงเทพมหานคร 10300",
    },
    {
      value: "คลองชักพระ ตลิ่งชัน กรุงเทพมหานคร 10170",
      label: "คลองชักพระ ตลิ่งชัน กรุงเทพมหานคร 10170",
    },
    {
      value: "ฉิมพลี ตลิ่งชัน กรุงเทพมหานคร 10170",
      label: "ฉิมพลี ตลิ่งชัน กรุงเทพมหานคร 10170",
    },
    {
      value: "ตลิ่งชัน ตลิ่งชัน กรุงเทพมหานคร 10170",
      label: "ตลิ่งชัน ตลิ่งชัน กรุงเทพมหานคร 10170",
    },
    {
      value: "บางพรม ตลิ่งชัน กรุงเทพมหานคร 10170",
      label: "บางพรม ตลิ่งชัน กรุงเทพมหานคร 10170",
    },
    {
      value: "บางระมาด ตลิ่งชัน กรุงเทพมหานคร 10170",
      label: "บางระมาด ตลิ่งชัน กรุงเทพมหานคร 10170",
    },
    {
      value: "บางเชือกหนัง ตลิ่งชัน กรุงเทพมหานคร 10170",
      label: "บางเชือกหนัง ตลิ่งชัน กรุงเทพมหานคร 10170",
    },
    {
      value: "ทวีวัฒนา ทวีวัฒนา กรุงเทพมหานคร 10170",
      label: "ทวีวัฒนา ทวีวัฒนา กรุงเทพมหานคร 10170",
    },
    {
      value: "ศาลาธรรมสพน์ ทวีวัฒนา กรุงเทพมหานคร 10170",
      label: "ศาลาธรรมสพน์ ทวีวัฒนา กรุงเทพมหานคร 10170",
    },
    {
      value: "ทุ่งครุ ทุ่งครุ กรุงเทพมหานคร 10140",
      label: "ทุ่งครุ ทุ่งครุ กรุงเทพมหานคร 10140",
    },
    {
      value: "บางมด ทุ่งครุ กรุงเทพมหานคร 10140",
      label: "บางมด ทุ่งครุ กรุงเทพมหานคร 10140",
    },
    {
      value: "ดาวคะนอง ธนบุรี กรุงเทพมหานคร 10600",
      label: "ดาวคะนอง ธนบุรี กรุงเทพมหานคร 10600",
    },
    {
      value: "ตลาดพลู ธนบุรี กรุงเทพมหานคร 10600",
      label: "ตลาดพลู ธนบุรี กรุงเทพมหานคร 10600",
    },
    {
      value: "บางยี่เรือ ธนบุรี กรุงเทพมหานคร 10600",
      label: "บางยี่เรือ ธนบุรี กรุงเทพมหานคร 10600",
    },
    {
      value: "บุคคโล ธนบุรี กรุงเทพมหานคร 10600",
      label: "บุคคโล ธนบุรี กรุงเทพมหานคร 10600",
    },
    {
      value: "วัดกัลยาณ์ ธนบุรี กรุงเทพมหานคร 10600",
      label: "วัดกัลยาณ์ ธนบุรี กรุงเทพมหานคร 10600",
    },
    {
      value: "สำเหร่ ธนบุรี กรุงเทพมหานคร 10600",
      label: "สำเหร่ ธนบุรี กรุงเทพมหานคร 10600",
    },
    {
      value: "หิรัญรูจี ธนบุรี กรุงเทพมหานคร 10600",
      label: "หิรัญรูจี ธนบุรี กรุงเทพมหานคร 10600",
    },
    {
      value: "บางขุนนนท์ บางกอกน้อย กรุงเทพมหานคร 10700",
      label: "บางขุนนนท์ บางกอกน้อย กรุงเทพมหานคร 10700",
    },
    {
      value: "บางขุนศรี บางกอกน้อย กรุงเทพมหานคร 10700",
      label: "บางขุนศรี บางกอกน้อย กรุงเทพมหานคร 10700",
    },
    {
      value: "บ้านช่างหล่อ บางกอกน้อย กรุงเทพมหานคร 10700",
      label: "บ้านช่างหล่อ บางกอกน้อย กรุงเทพมหานคร 10700",
    },
    {
      value: "ศิริราช บางกอกน้อย กรุงเทพมหานคร 10700",
      label: "ศิริราช บางกอกน้อย กรุงเทพมหานคร 10700",
    },
    {
      value: "อรุณอัมรินทร์ บางกอกน้อย กรุงเทพมหานคร 10700",
      label: "อรุณอัมรินทร์ บางกอกน้อย กรุงเทพมหานคร 10700",
    },
    {
      value: "วัดท่าพระ บางกอกใหญ่ กรุงเทพมหานคร 10600",
      label: "วัดท่าพระ บางกอกใหญ่ กรุงเทพมหานคร 10600",
    },
    {
      value: "วัดอรุณ บางกอกใหญ่ กรุงเทพมหานคร 10600",
      label: "วัดอรุณ บางกอกใหญ่ กรุงเทพมหานคร 10600",
    },
    {
      value: "คลองจั่น บางกะปิ กรุงเทพมหานคร 10240",
      label: "คลองจั่น บางกะปิ กรุงเทพมหานคร 10240",
    },
    {
      value: "หัวหมาก บางกะปิ กรุงเทพมหานคร 10240",
      label: "หัวหมาก บางกะปิ กรุงเทพมหานคร 10240",
    },
    {
      value: "หัวหมาก บางกะปิ กรุงเทพมหานคร 10250",
      label: "หัวหมาก บางกะปิ กรุงเทพมหานคร 10250",
    },
    {
      value: "ท่าข้าม บางขุนเทียน กรุงเทพมหานคร 10150",
      label: "ท่าข้าม บางขุนเทียน กรุงเทพมหานคร 10150",
    },
    {
      value: "แสมดำ บางขุนเทียน กรุงเทพมหานคร 10150",
      label: "แสมดำ บางขุนเทียน กรุงเทพมหานคร 10150",
    },
    {
      value: "บางคอแหลม บางคอแหลม กรุงเทพมหานคร 10120",
      label: "บางคอแหลม บางคอแหลม กรุงเทพมหานคร 10120",
    },
    {
      value: "บางโคล่ บางคอแหลม กรุงเทพมหานคร 10120",
      label: "บางโคล่ บางคอแหลม กรุงเทพมหานคร 10120",
    },
    {
      value: "วัดพระยาไกร บางคอแหลม กรุงเทพมหานคร 10120",
      label: "วัดพระยาไกร บางคอแหลม กรุงเทพมหานคร 10120",
    },
    {
      value: "บางซื่อ บางซื่อ กรุงเทพมหานคร 10800",
      label: "บางซื่อ บางซื่อ กรุงเทพมหานคร 10800",
    },
    {
      value: "วงศ์สว่าง บางซื่อ กรุงเทพมหานคร 10800",
      label: "วงศ์สว่าง บางซื่อ กรุงเทพมหานคร 10800",
    },
    {
      value: "บางนา บางนา กรุงเทพมหานคร 10260",
      label: "บางนา บางนา กรุงเทพมหานคร 10260",
    },
    {
      value: "บางบอน บางบอน กรุงเทพมหานคร 10150",
      label: "บางบอน บางบอน กรุงเทพมหานคร 10150",
    },
    {
      value: "บางบำหรุ บางพลัด กรุงเทพมหานคร 10700",
      label: "บางบำหรุ บางพลัด กรุงเทพมหานคร 10700",
    },
    {
      value: "บางพลัด บางพลัด กรุงเทพมหานคร 10700",
      label: "บางพลัด บางพลัด กรุงเทพมหานคร 10700",
    },
    {
      value: "บางยี่ขัน บางพลัด กรุงเทพมหานคร 10700",
      label: "บางยี่ขัน บางพลัด กรุงเทพมหานคร 10700",
    },
    {
      value: "บางอ้อ บางพลัด กรุงเทพมหานคร 10700",
      label: "บางอ้อ บางพลัด กรุงเทพมหานคร 10700",
    },
    {
      value: "บางรัก บางรัก กรุงเทพมหานคร 10500",
      label: "บางรัก บางรัก กรุงเทพมหานคร 10500",
    },
    {
      value: "มหาพฤฒาราม บางรัก กรุงเทพมหานคร 10500",
      label: "มหาพฤฒาราม บางรัก กรุงเทพมหานคร 10500",
    },
    {
      value: "สีลม บางรัก กรุงเทพมหานคร 10500",
      label: "สีลม บางรัก กรุงเทพมหานคร 10500",
    },
    {
      value: "สี่พระยา บางรัก กรุงเทพมหานคร 10500",
      label: "สี่พระยา บางรัก กรุงเทพมหานคร 10500",
    },
    {
      value: "สุริยวงศ์ บางรัก กรุงเทพมหานคร 10500",
      label: "สุริยวงศ์ บางรัก กรุงเทพมหานคร 10500",
    },
    {
      value: "ท่าแร้ง บางเขน กรุงเทพมหานคร 10220",
      label: "ท่าแร้ง บางเขน กรุงเทพมหานคร 10220",
    },
    {
      value: "ท่าแร้ง บางเขน กรุงเทพมหานคร 10230",
      label: "ท่าแร้ง บางเขน กรุงเทพมหานคร 10230",
    },
    {
      value: "อนุสาวรีย์ บางเขน กรุงเทพมหานคร 10220",
      label: "อนุสาวรีย์ บางเขน กรุงเทพมหานคร 10220",
    },
    {
      value: "บางแค บางแค กรุงเทพมหานคร 10160",
      label: "บางแค บางแค กรุงเทพมหานคร 10160",
    },
    {
      value: "บางแคเหนือ บางแค กรุงเทพมหานคร 10160",
      label: "บางแคเหนือ บางแค กรุงเทพมหานคร 10160",
    },
    {
      value: "บางไผ่ บางแค กรุงเทพมหานคร 10160",
      label: "บางไผ่ บางแค กรุงเทพมหานคร 10160",
    },
    {
      value: "หลักสอง บางแค กรุงเทพมหานคร 10160",
      label: "หลักสอง บางแค กรุงเทพมหานคร 10160",
    },
    {
      value: "คลองกุ่ม บึงกุ่ม กรุงเทพมหานคร 10230",
      label: "คลองกุ่ม บึงกุ่ม กรุงเทพมหานคร 10230",
    },
    {
      value: "คลองกุ่ม บึงกุ่ม กรุงเทพมหานคร 10240",
      label: "คลองกุ่ม บึงกุ่ม กรุงเทพมหานคร 10240",
    },
    {
      value: "นวมินทร์ บึงกุ่ม กรุงเทพมหานคร 10230",
      label: "นวมินทร์ บึงกุ่ม กรุงเทพมหานคร 10230",
    },
    {
      value: "นวลจันทร์ บึงกุ่ม กรุงเทพมหานคร 10230",
      label: "นวลจันทร์ บึงกุ่ม กรุงเทพมหานคร 10230",
    },
    {
      value: "ปทุมวัน ปทุมวัน กรุงเทพมหานคร 10330",
      label: "ปทุมวัน ปทุมวัน กรุงเทพมหานคร 10330",
    },
    {
      value: "รองเมือง ปทุมวัน กรุงเทพมหานคร 10330",
      label: "รองเมือง ปทุมวัน กรุงเทพมหานคร 10330",
    },
    {
      value: "ลุมพินี ปทุมวัน กรุงเทพมหานคร 10330",
      label: "ลุมพินี ปทุมวัน กรุงเทพมหานคร 10330",
    },
    {
      value: "วังใหม่ ปทุมวัน กรุงเทพมหานคร 10330",
      label: "วังใหม่ ปทุมวัน กรุงเทพมหานคร 10330",
    },
    {
      value: "วังใหม่ ปทุมวัน กรุงเทพมหานคร 10110",
      label: "วังใหม่ ปทุมวัน กรุงเทพมหานคร 10110",
    },
    {
      value: "วังใหม่ ปทุมวัน กรุงเทพมหานคร 10120",
      label: "วังใหม่ ปทุมวัน กรุงเทพมหานคร 10120",
    },
    {
      value: "วังใหม่ ปทุมวัน กรุงเทพมหานคร 10400",
      label: "วังใหม่ ปทุมวัน กรุงเทพมหานคร 10400",
    },
    {
      value: "วังใหม่ ปทุมวัน กรุงเทพมหานคร 10500",
      label: "วังใหม่ ปทุมวัน กรุงเทพมหานคร 10500",
    },
    {
      value: "ดอกไม้ ประเวศ กรุงเทพมหานคร 10250",
      label: "ดอกไม้ ประเวศ กรุงเทพมหานคร 10250",
    },
    {
      value: "ประเวศ ประเวศ กรุงเทพมหานคร 10250",
      label: "ประเวศ ประเวศ กรุงเทพมหานคร 10250",
    },
    {
      value: "หนองบอน ประเวศ กรุงเทพมหานคร 10250",
      label: "หนองบอน ประเวศ กรุงเทพมหานคร 10250",
    },
    {
      value: "คลองมหานาค ป้อมปราบศัตรูพ่าย กรุงเทพมหานคร 10100",
      label: "คลองมหานาค ป้อมปราบศัตรูพ่าย กรุงเทพมหานคร 10100",
    },
    {
      value: "บ้านบาตร ป้อมปราบศัตรูพ่าย กรุงเทพมหานคร 10100",
      label: "บ้านบาตร ป้อมปราบศัตรูพ่าย กรุงเทพมหานคร 10100",
    },
    {
      value: "ป้อมปราบ ป้อมปราบศัตรูพ่าย กรุงเทพมหานคร 10100",
      label: "ป้อมปราบ ป้อมปราบศัตรูพ่าย กรุงเทพมหานคร 10100",
    },
    {
      value: "วัดเทพศิรินทร์ ป้อมปราบศัตรูพ่าย กรุงเทพมหานคร 10100",
      label: "วัดเทพศิรินทร์ ป้อมปราบศัตรูพ่าย กรุงเทพมหานคร 10100",
    },
    {
      value: "วัดโสมนัส ป้อมปราบศัตรูพ่าย กรุงเทพมหานคร 10100",
      label: "วัดโสมนัส ป้อมปราบศัตรูพ่าย กรุงเทพมหานคร 10100",
    },
    {
      value: "สามเสนใน พญาไท กรุงเทพมหานคร 10400",
      label: "สามเสนใน พญาไท กรุงเทพมหานคร 10400",
    },
    {
      value: "ชนะสงคราม พระนคร กรุงเทพมหานคร 10200",
      label: "ชนะสงคราม พระนคร กรุงเทพมหานคร 10200",
    },
    {
      value: "ตลาดยอด พระนคร กรุงเทพมหานคร 10200",
      label: "ตลาดยอด พระนคร กรุงเทพมหานคร 10200",
    },
    {
      value: "บวรนิเวศ พระนคร กรุงเทพมหานคร 10200",
      label: "บวรนิเวศ พระนคร กรุงเทพมหานคร 10200",
    },
    {
      value: "บางขุนพรหม พระนคร กรุงเทพมหานคร 10200",
      label: "บางขุนพรหม พระนคร กรุงเทพมหานคร 10200",
    },
    {
      value: "บ้านพานถม พระนคร กรุงเทพมหานคร 10200",
      label: "บ้านพานถม พระนคร กรุงเทพมหานคร 10200",
    },
    {
      value: "พระบรมมหาราชวัง พระนคร กรุงเทพมหานคร 10200",
      label: "พระบรมมหาราชวัง พระนคร กรุงเทพมหานคร 10200",
    },
    {
      value: "วังบูรพาภิรมย์ พระนคร กรุงเทพมหานคร 10200",
      label: "วังบูรพาภิรมย์ พระนคร กรุงเทพมหานคร 10200",
    },
    {
      value: "วัดราชบพิธ พระนคร กรุงเทพมหานคร 10200",
      label: "วัดราชบพิธ พระนคร กรุงเทพมหานคร 10200",
    },
    {
      value: "วัดสามพระยา พระนคร กรุงเทพมหานคร 10200",
      label: "วัดสามพระยา พระนคร กรุงเทพมหานคร 10200",
    },
    {
      value: "ศาลเจ้าพ่อเสือ พระนคร กรุงเทพมหานคร 10200",
      label: "ศาลเจ้าพ่อเสือ พระนคร กรุงเทพมหานคร 10200",
    },
    {
      value: "สำราญราษฎร์ พระนคร กรุงเทพมหานคร 10200",
      label: "สำราญราษฎร์ พระนคร กรุงเทพมหานคร 10200",
    },
    {
      value: "เสาชิงช้า พระนคร กรุงเทพมหานคร 10200",
      label: "เสาชิงช้า พระนคร กรุงเทพมหานคร 10200",
    },
    {
      value: "บางจาก พระโขนง กรุงเทพมหานคร 10260",
      label: "บางจาก พระโขนง กรุงเทพมหานคร 10260",
    },
    {
      value: "คลองขวาง ภาษีเจริญ กรุงเทพมหานคร 10160",
      label: "คลองขวาง ภาษีเจริญ กรุงเทพมหานคร 10160",
    },
    {
      value: "คูหาสวรรค์ ภาษีเจริญ กรุงเทพมหานคร 10160",
      label: "คูหาสวรรค์ ภาษีเจริญ กรุงเทพมหานคร 10160",
    },
    {
      value: "บางจาก ภาษีเจริญ กรุงเทพมหานคร 10160",
      label: "บางจาก ภาษีเจริญ กรุงเทพมหานคร 10160",
    },
    {
      value: "บางด้วน ภาษีเจริญ กรุงเทพมหานคร 10160",
      label: "บางด้วน ภาษีเจริญ กรุงเทพมหานคร 10160",
    },
    {
      value: "บางหว้า ภาษีเจริญ กรุงเทพมหานคร 10160",
      label: "บางหว้า ภาษีเจริญ กรุงเทพมหานคร 10160",
    },
    {
      value: "บางแวก ภาษีเจริญ กรุงเทพมหานคร 10160",
      label: "บางแวก ภาษีเจริญ กรุงเทพมหานคร 10160",
    },
    {
      value: "ปากคลองภาษีเจริญ ภาษีเจริญ กรุงเทพมหานคร 10160",
      label: "ปากคลองภาษีเจริญ ภาษีเจริญ กรุงเทพมหานคร 10160",
    },
    {
      value: "มีนบุรี มีนบุรี กรุงเทพมหานคร 10510",
      label: "มีนบุรี มีนบุรี กรุงเทพมหานคร 10510",
    },
    {
      value: "แสนแสบ มีนบุรี กรุงเทพมหานคร 10510",
      label: "แสนแสบ มีนบุรี กรุงเทพมหานคร 10510",
    },
    {
      value: "ช่องนนทรี ยานนาวา กรุงเทพมหานคร 10120",
      label: "ช่องนนทรี ยานนาวา กรุงเทพมหานคร 10120",
    },
    {
      value: "บางโพงพาง ยานนาวา กรุงเทพมหานคร 10120",
      label: "บางโพงพาง ยานนาวา กรุงเทพมหานคร 10120",
    },
    {
      value: "ถนนพญาไท ราชเทวี กรุงเทพมหานคร 10400",
      label: "ถนนพญาไท ราชเทวี กรุงเทพมหานคร 10400",
    },
    {
      value: "ถนนเพชรบุรี ราชเทวี กรุงเทพมหานคร 10400",
      label: "ถนนเพชรบุรี ราชเทวี กรุงเทพมหานคร 10400",
    },
    {
      value: "ทุ่งพญาไท ราชเทวี กรุงเทพมหานคร 10400",
      label: "ทุ่งพญาไท ราชเทวี กรุงเทพมหานคร 10400",
    },
    {
      value: "มักกะสัน ราชเทวี กรุงเทพมหานคร 10400",
      label: "มักกะสัน ราชเทวี กรุงเทพมหานคร 10400",
    },
    {
      value: "บางปะกอก ราษฎร์บูรณะ กรุงเทพมหานคร 10140",
      label: "บางปะกอก ราษฎร์บูรณะ กรุงเทพมหานคร 10140",
    },
    {
      value: "ราษฎร์บูรณะ ราษฎร์บูรณะ กรุงเทพมหานคร 10140",
      label: "ราษฎร์บูรณะ ราษฎร์บูรณะ กรุงเทพมหานคร 10140",
    },
    {
      value: "ขุมทอง ลาดกระบัง กรุงเทพมหานคร 10520",
      label: "ขุมทอง ลาดกระบัง กรุงเทพมหานคร 10520",
    },
    {
      value: "คลองสองต้นนุ่น ลาดกระบัง กรุงเทพมหานคร 10520",
      label: "คลองสองต้นนุ่น ลาดกระบัง กรุงเทพมหานคร 10520",
    },
    {
      value: "คลองสามประเวศ ลาดกระบัง กรุงเทพมหานคร 10520",
      label: "คลองสามประเวศ ลาดกระบัง กรุงเทพมหานคร 10520",
    },
    {
      value: "ทับยาว ลาดกระบัง กรุงเทพมหานคร 10520",
      label: "ทับยาว ลาดกระบัง กรุงเทพมหานคร 10520",
    },
    {
      value: "ลาดกระบัง ลาดกระบัง กรุงเทพมหานคร 10520",
      label: "ลาดกระบัง ลาดกระบัง กรุงเทพมหานคร 10520",
    },
    {
      value: "ลำปลาทิว ลาดกระบัง กรุงเทพมหานคร 10520",
      label: "ลำปลาทิว ลาดกระบัง กรุงเทพมหานคร 10520",
    },
    {
      value: "จรเข้บัว ลาดพร้าว กรุงเทพมหานคร 10230",
      label: "จรเข้บัว ลาดพร้าว กรุงเทพมหานคร 10230",
    },
    {
      value: "ลาดพร้าว ลาดพร้าว กรุงเทพมหานคร 10230",
      label: "ลาดพร้าว ลาดพร้าว กรุงเทพมหานคร 10230",
    },
    {
      value: "คลองเจ้าคุณสิงห์ วังทองหลาง กรุงเทพมหานคร 10310",
      label: "คลองเจ้าคุณสิงห์ วังทองหลาง กรุงเทพมหานคร 10310",
    },
    {
      value: "พลับพลา วังทองหลาง กรุงเทพมหานคร 10310",
      label: "พลับพลา วังทองหลาง กรุงเทพมหานคร 10310",
    },
    {
      value: "วังทองหลาง วังทองหลาง กรุงเทพมหานคร 10310",
      label: "วังทองหลาง วังทองหลาง กรุงเทพมหานคร 10310",
    },
    {
      value: "สะพานสอง วังทองหลาง กรุงเทพมหานคร 10310",
      label: "สะพานสอง วังทองหลาง กรุงเทพมหานคร 10310",
    },
    {
      value: "คลองตันเหนือ วัฒนา กรุงเทพมหานคร 10110",
      label: "คลองตันเหนือ วัฒนา กรุงเทพมหานคร 10110",
    },
    {
      value: "คลองเตยเหนือ วัฒนา กรุงเทพมหานคร 10110",
      label: "คลองเตยเหนือ วัฒนา กรุงเทพมหานคร 10110",
    },
    {
      value: "พระโขนงเหนือ วัฒนา กรุงเทพมหานคร 10110",
      label: "พระโขนงเหนือ วัฒนา กรุงเทพมหานคร 10110",
    },
    {
      value: "พระโขนงเหนือ วัฒนา กรุงเทพมหานคร 10260",
      label: "พระโขนงเหนือ วัฒนา กรุงเทพมหานคร 10260",
    },
    {
      value: "สวนหลวง สวนหลวง กรุงเทพมหานคร 10250",
      label: "สวนหลวง สวนหลวง กรุงเทพมหานคร 10250",
    },
    {
      value: "สะพานสูง สะพานสูง กรุงเทพมหานคร 10240",
      label: "สะพานสูง สะพานสูง กรุงเทพมหานคร 10240",
    },
    {
      value: "สะพานสูง สะพานสูง กรุงเทพมหานคร 10250",
      label: "สะพานสูง สะพานสูง กรุงเทพมหานคร 10250",
    },
    {
      value: "ราษฎร์พัฒนา สะพานสูง กรุงเทพมหานคร 10240",
      label: "ราษฎร์พัฒนา สะพานสูง กรุงเทพมหานคร 10240",
    },
    {
      value: "ทับช้าง สะพานสูง กรุงเทพมหานคร 10250",
      label: "ทับช้าง สะพานสูง กรุงเทพมหานคร 10250",
    },
    {
      value: "จักรวรรดิ์ สัมพันธวงศ์ กรุงเทพมหานคร 10100",
      label: "จักรวรรดิ์ สัมพันธวงศ์ กรุงเทพมหานคร 10100",
    },
    {
      value: "ตลาดน้อย สัมพันธวงศ์ กรุงเทพมหานคร 10100",
      label: "ตลาดน้อย สัมพันธวงศ์ กรุงเทพมหานคร 10100",
    },
    {
      value: "สัมพันธวงศ์ สัมพันธวงศ์ กรุงเทพมหานคร 10100",
      label: "สัมพันธวงศ์ สัมพันธวงศ์ กรุงเทพมหานคร 10100",
    },
    {
      value: "ทุ่งมหาเมฆ สาทร กรุงเทพมหานคร 10120",
      label: "ทุ่งมหาเมฆ สาทร กรุงเทพมหานคร 10120",
    },
    {
      value: "ทุ่งวัดดอน สาทร กรุงเทพมหานคร 10120",
      label: "ทุ่งวัดดอน สาทร กรุงเทพมหานคร 10120",
    },
    {
      value: "ยานนาวา สาทร กรุงเทพมหานคร 10120",
      label: "ยานนาวา สาทร กรุงเทพมหานคร 10120",
    },
    {
      value: "คลองถนน สายไหม กรุงเทพมหานคร 10220",
      label: "คลองถนน สายไหม กรุงเทพมหานคร 10220",
    },
    {
      value: "สายไหม สายไหม กรุงเทพมหานคร 10220",
      label: "สายไหม สายไหม กรุงเทพมหานคร 10220",
    },
    {
      value: "ออเงิน สายไหม กรุงเทพมหานคร 10220",
      label: "ออเงิน สายไหม กรุงเทพมหานคร 10220",
    },
    {
      value: "กระทุ่มราย หนองจอก กรุงเทพมหานคร 10530",
      label: "กระทุ่มราย หนองจอก กรุงเทพมหานคร 10530",
    },
    {
      value: "คลองสิบ หนองจอก กรุงเทพมหานคร 10530",
      label: "คลองสิบ หนองจอก กรุงเทพมหานคร 10530",
    },
    {
      value: "คลองสิบสอง หนองจอก กรุงเทพมหานคร 10530",
      label: "คลองสิบสอง หนองจอก กรุงเทพมหานคร 10530",
    },
    {
      value: "คู้ฝั่งเหนือ หนองจอก กรุงเทพมหานคร 10530",
      label: "คู้ฝั่งเหนือ หนองจอก กรุงเทพมหานคร 10530",
    },
    {
      value: "ลำต้อยติ่ง หนองจอก กรุงเทพมหานคร 10530",
      label: "ลำต้อยติ่ง หนองจอก กรุงเทพมหานคร 10530",
    },
    {
      value: "ลำผักชี หนองจอก กรุงเทพมหานคร 10530",
      label: "ลำผักชี หนองจอก กรุงเทพมหานคร 10530",
    },
    {
      value: "หนองจอก หนองจอก กรุงเทพมหานคร 10530",
      label: "หนองจอก หนองจอก กรุงเทพมหานคร 10530",
    },
    {
      value: "โคกแฝด หนองจอก กรุงเทพมหานคร 10530",
      label: "โคกแฝด หนองจอก กรุงเทพมหานคร 10530",
    },
    {
      value: "หนองค้างพลู หนองแขม กรุงเทพมหานคร 10160",
      label: "หนองค้างพลู หนองแขม กรุงเทพมหานคร 10160",
    },
    {
      value: "หนองแขม หนองแขม กรุงเทพมหานคร 10160",
      label: "หนองแขม หนองแขม กรุงเทพมหานคร 10160",
    },
    {
      value: "ตลาดบางเขน หลักสี่ กรุงเทพมหานคร 10210",
      label: "ตลาดบางเขน หลักสี่ กรุงเทพมหานคร 10210",
    },
    {
      value: "ทุ่งสองห้อง หลักสี่ กรุงเทพมหานคร 10210",
      label: "ทุ่งสองห้อง หลักสี่ กรุงเทพมหานคร 10210",
    },
    {
      value: "บางกะปิ ห้วยขวาง กรุงเทพมหานคร 10310",
      label: "บางกะปิ ห้วยขวาง กรุงเทพมหานคร 10310",
    },
    {
      value: "สามเสนนอก ห้วยขวาง กรุงเทพมหานคร 10310",
      label: "สามเสนนอก ห้วยขวาง กรุงเทพมหานคร 10310",
    },
    {
      value: "ห้วยขวาง ห้วยขวาง กรุงเทพมหานคร 10310",
      label: "ห้วยขวาง ห้วยขวาง กรุงเทพมหานคร 10310",
    },
    {
      value: "ดอนแตง ขาณุวรลักษบุรี กำแพงเพชร 62140",
      label: "ดอนแตง ขาณุวรลักษบุรี กำแพงเพชร 62140",
    },
    {
      value: "บ่อถ้ำ ขาณุวรลักษบุรี กำแพงเพชร 62140",
      label: "บ่อถ้ำ ขาณุวรลักษบุรี กำแพงเพชร 62140",
    },
    {
      value: "ปางมะค่า ขาณุวรลักษบุรี กำแพงเพชร 62140",
      label: "ปางมะค่า ขาณุวรลักษบุรี กำแพงเพชร 62140",
    },
    {
      value: "ป่าพุทรา ขาณุวรลักษบุรี กำแพงเพชร 62130",
      label: "ป่าพุทรา ขาณุวรลักษบุรี กำแพงเพชร 62130",
    },
    {
      value: "ยางสูง ขาณุวรลักษบุรี กำแพงเพชร 62130",
      label: "ยางสูง ขาณุวรลักษบุรี กำแพงเพชร 62130",
    },
    {
      value: "วังชะพลู ขาณุวรลักษบุรี กำแพงเพชร 62140",
      label: "วังชะพลู ขาณุวรลักษบุรี กำแพงเพชร 62140",
    },
    {
      value: "วังหามแห ขาณุวรลักษบุรี กำแพงเพชร 62140",
      label: "วังหามแห ขาณุวรลักษบุรี กำแพงเพชร 62140",
    },
    {
      value: "สลกบาตร ขาณุวรลักษบุรี กำแพงเพชร 62140",
      label: "สลกบาตร ขาณุวรลักษบุรี กำแพงเพชร 62140",
    },
    {
      value: "เกาะตาล ขาณุวรลักษบุรี กำแพงเพชร 62130",
      label: "เกาะตาล ขาณุวรลักษบุรี กำแพงเพชร 62130",
    },
    {
      value: "แสนตอ ขาณุวรลักษบุรี กำแพงเพชร 62130",
      label: "แสนตอ ขาณุวรลักษบุรี กำแพงเพชร 62130",
    },
    {
      value: "โค้งไผ่ ขาณุวรลักษบุรี กำแพงเพชร 62140",
      label: "โค้งไผ่ ขาณุวรลักษบุรี กำแพงเพชร 62140",
    },
    {
      value: "คลองขลุง คลองขลุง กำแพงเพชร 62120",
      label: "คลองขลุง คลองขลุง กำแพงเพชร 62120",
    },
    {
      value: "คลองสมบูรณ์ คลองขลุง กำแพงเพชร 62120",
      label: "คลองสมบูรณ์ คลองขลุง กำแพงเพชร 62120",
    },
    {
      value: "ท่าพุทรา คลองขลุง กำแพงเพชร 62120",
      label: "ท่าพุทรา คลองขลุง กำแพงเพชร 62120",
    },
    {
      value: "ท่ามะเขือ คลองขลุง กำแพงเพชร 62120",
      label: "ท่ามะเขือ คลองขลุง กำแพงเพชร 62120",
    },
    {
      value: "วังบัว คลองขลุง กำแพงเพชร 62120",
      label: "วังบัว คลองขลุง กำแพงเพชร 62120",
    },
    {
      value: "วังยาง คลองขลุง กำแพงเพชร 62120",
      label: "วังยาง คลองขลุง กำแพงเพชร 62120",
    },
    {
      value: "วังแขม คลองขลุง กำแพงเพชร 62120",
      label: "วังแขม คลองขลุง กำแพงเพชร 62120",
    },
    {
      value: "วังไทร คลองขลุง กำแพงเพชร 62120",
      label: "วังไทร คลองขลุง กำแพงเพชร 62120",
    },
    {
      value: "หัวถนน คลองขลุง กำแพงเพชร 62120",
      label: "หัวถนน คลองขลุง กำแพงเพชร 62120",
    },
    {
      value: "แม่ลาด คลองขลุง กำแพงเพชร 62120",
      label: "แม่ลาด คลองขลุง กำแพงเพชร 62120",
    },
    {
      value: "คลองน้ำไหล คลองลาน กำแพงเพชร 62180",
      label: "คลองน้ำไหล คลองลาน กำแพงเพชร 62180",
    },
    {
      value: "คลองลานพัฒนา คลองลาน กำแพงเพชร 62180",
      label: "คลองลานพัฒนา คลองลาน กำแพงเพชร 62180",
    },
    {
      value: "สักงาม คลองลาน กำแพงเพชร 62180",
      label: "สักงาม คลองลาน กำแพงเพชร 62180",
    },
    {
      value: "โป่งน้ำร้อน คลองลาน กำแพงเพชร 62180",
      label: "โป่งน้ำร้อน คลองลาน กำแพงเพชร 62180",
    },
    {
      value: "ถาวรวัฒนา ทรายทองวัฒนา กำแพงเพชร 62190",
      label: "ถาวรวัฒนา ทรายทองวัฒนา กำแพงเพชร 62190",
    },
    {
      value: "ทุ่งทราย ทรายทองวัฒนา กำแพงเพชร 62190",
      label: "ทุ่งทราย ทรายทองวัฒนา กำแพงเพชร 62190",
    },
    {
      value: "ทุ่งทอง ทรายทองวัฒนา กำแพงเพชร 62190",
      label: "ทุ่งทอง ทรายทองวัฒนา กำแพงเพชร 62190",
    },
    {
      value: "บึงสามัคคี บึงสามัคคี กำแพงเพชร 62210",
      label: "บึงสามัคคี บึงสามัคคี กำแพงเพชร 62210",
    },
    {
      value: "ระหาน บึงสามัคคี กำแพงเพชร 62210",
      label: "ระหาน บึงสามัคคี กำแพงเพชร 62210",
    },
    {
      value: "วังชะโอน บึงสามัคคี กำแพงเพชร 62210",
      label: "วังชะโอน บึงสามัคคี กำแพงเพชร 62210",
    },
    {
      value: "เทพนิมิต บึงสามัคคี กำแพงเพชร 62210",
      label: "เทพนิมิต บึงสามัคคี กำแพงเพชร 62210",
    },
    {
      value: "ปางตาไว ปางศิลาทอง กำแพงเพชร 62120",
      label: "ปางตาไว ปางศิลาทอง กำแพงเพชร 62120",
    },
    {
      value: "หินดาต ปางศิลาทอง กำแพงเพชร 62120",
      label: "หินดาต ปางศิลาทอง กำแพงเพชร 62120",
    },
    {
      value: "โพธิ์ทอง ปางศิลาทอง กำแพงเพชร 62120",
      label: "โพธิ์ทอง ปางศิลาทอง กำแพงเพชร 62120",
    },
    {
      value: "คลองพิไกร พรานกระต่าย กำแพงเพชร 62110",
      label: "คลองพิไกร พรานกระต่าย กำแพงเพชร 62110",
    },
    {
      value: "คุยบ้านโอง พรานกระต่าย กำแพงเพชร 62110",
      label: "คุยบ้านโอง พรานกระต่าย กำแพงเพชร 62110",
    },
    {
      value: "ถ้ำกระต่ายทอง พรานกระต่าย กำแพงเพชร 62110",
      label: "ถ้ำกระต่ายทอง พรานกระต่าย กำแพงเพชร 62110",
    },
    {
      value: "ท่าไม้ พรานกระต่าย กำแพงเพชร 62110",
      label: "ท่าไม้ พรานกระต่าย กำแพงเพชร 62110",
    },
    {
      value: "พรานกระต่าย พรานกระต่าย กำแพงเพชร 62110",
      label: "พรานกระต่าย พรานกระต่าย กำแพงเพชร 62110",
    },
    {
      value: "วังควง พรานกระต่าย กำแพงเพชร 62110",
      label: "วังควง พรานกระต่าย กำแพงเพชร 62110",
    },
    {
      value: "วังตะแบก พรานกระต่าย กำแพงเพชร 62110",
      label: "วังตะแบก พรานกระต่าย กำแพงเพชร 62110",
    },
    {
      value: "หนองหัววัว พรานกระต่าย กำแพงเพชร 62110",
      label: "หนองหัววัว พรานกระต่าย กำแพงเพชร 62110",
    },
    {
      value: "ห้วยยั้ง พรานกระต่าย กำแพงเพชร 62110",
      label: "ห้วยยั้ง พรานกระต่าย กำแพงเพชร 62110",
    },
    {
      value: "เขาคีริส พรานกระต่าย กำแพงเพชร 62110",
      label: "เขาคีริส พรานกระต่าย กำแพงเพชร 62110",
    },
    {
      value: "จันทิมา ลานกระบือ กำแพงเพชร 62170",
      label: "จันทิมา ลานกระบือ กำแพงเพชร 62170",
    },
    {
      value: "ช่องลม ลานกระบือ กำแพงเพชร 62170",
      label: "ช่องลม ลานกระบือ กำแพงเพชร 62170",
    },
    {
      value: "บึงทับแรต ลานกระบือ กำแพงเพชร 62170",
      label: "บึงทับแรต ลานกระบือ กำแพงเพชร 62170",
    },
    {
      value: "ประชาสุขสันต์ ลานกระบือ กำแพงเพชร 62170",
      label: "ประชาสุขสันต์ ลานกระบือ กำแพงเพชร 62170",
    },
    {
      value: "ลานกระบือ ลานกระบือ กำแพงเพชร 62170",
      label: "ลานกระบือ ลานกระบือ กำแพงเพชร 62170",
    },
    {
      value: "หนองหลวง ลานกระบือ กำแพงเพชร 62170",
      label: "หนองหลวง ลานกระบือ กำแพงเพชร 62170",
    },
    {
      value: "โนนพลวง ลานกระบือ กำแพงเพชร 62170",
      label: "โนนพลวง ลานกระบือ กำแพงเพชร 62170",
    },
    {
      value: "คณฑี เมืองกำแพงเพชร กำแพงเพชร 62000",
      label: "คณฑี เมืองกำแพงเพชร กำแพงเพชร 62000",
    },
    {
      value: "คลองแม่ลาย เมืองกำแพงเพชร กำแพงเพชร 62000",
      label: "คลองแม่ลาย เมืองกำแพงเพชร กำแพงเพชร 62000",
    },
    {
      value: "ทรงธรรม เมืองกำแพงเพชร กำแพงเพชร 62000",
      label: "ทรงธรรม เมืองกำแพงเพชร กำแพงเพชร 62000",
    },
    {
      value: "ท่าขุนราม เมืองกำแพงเพชร กำแพงเพชร 62000",
      label: "ท่าขุนราม เมืองกำแพงเพชร กำแพงเพชร 62000",
    },
    {
      value: "ธำมรงค์ เมืองกำแพงเพชร กำแพงเพชร 62160",
      label: "ธำมรงค์ เมืองกำแพงเพชร กำแพงเพชร 62160",
    },
    {
      value: "นครชุม เมืองกำแพงเพชร กำแพงเพชร 62000",
      label: "นครชุม เมืองกำแพงเพชร กำแพงเพชร 62000",
    },
    {
      value: "นาบ่อคำ เมืองกำแพงเพชร กำแพงเพชร 62000",
      label: "นาบ่อคำ เมืองกำแพงเพชร กำแพงเพชร 62000",
    },
    {
      value: "นิคมทุ่งโพธิ์ทะเล เมืองกำแพงเพชร กำแพงเพชร 62000",
      label: "นิคมทุ่งโพธิ์ทะเล เมืองกำแพงเพชร กำแพงเพชร 62000",
    },
    {
      value: "ลานดอกไม้ เมืองกำแพงเพชร กำแพงเพชร 62000",
      label: "ลานดอกไม้ เมืองกำแพงเพชร กำแพงเพชร 62000",
    },
    {
      value: "วังทอง เมืองกำแพงเพชร กำแพงเพชร 62000",
      label: "วังทอง เมืองกำแพงเพชร กำแพงเพชร 62000",
    },
    {
      value: "สระแก้ว เมืองกำแพงเพชร กำแพงเพชร 62000",
      label: "สระแก้ว เมืองกำแพงเพชร กำแพงเพชร 62000",
    },
    {
      value: "หนองปลิง เมืองกำแพงเพชร กำแพงเพชร 62000",
      label: "หนองปลิง เมืองกำแพงเพชร กำแพงเพชร 62000",
    },
    {
      value: "อ่างทอง เมืองกำแพงเพชร กำแพงเพชร 62000",
      label: "อ่างทอง เมืองกำแพงเพชร กำแพงเพชร 62000",
    },
    {
      value: "เทพนคร เมืองกำแพงเพชร กำแพงเพชร 62000",
      label: "เทพนคร เมืองกำแพงเพชร กำแพงเพชร 62000",
    },
    {
      value: "ในเมือง เมืองกำแพงเพชร กำแพงเพชร 62000",
      label: "ในเมือง เมืองกำแพงเพชร กำแพงเพชร 62000",
    },
    {
      value: "ไตรตรึงษ์ เมืองกำแพงเพชร กำแพงเพชร 62160",
      label: "ไตรตรึงษ์ เมืองกำแพงเพชร กำแพงเพชร 62160",
    },
    {
      value: "ลานดอกไม้ตก โกสัมพีนคร กำแพงเพชร 62000",
      label: "ลานดอกไม้ตก โกสัมพีนคร กำแพงเพชร 62000",
    },
    {
      value: "เพชรชมภู โกสัมพีนคร กำแพงเพชร 62000",
      label: "เพชรชมภู โกสัมพีนคร กำแพงเพชร 62000",
    },
    {
      value: "โกสัมพี โกสัมพีนคร กำแพงเพชร 62000",
      label: "โกสัมพี โกสัมพีนคร กำแพงเพชร 62000",
    },
    {
      value: "พานทอง ไทรงาม กำแพงเพชร 62150",
      label: "พานทอง ไทรงาม กำแพงเพชร 62150",
    },
    {
      value: "มหาชัย ไทรงาม กำแพงเพชร 62150",
      label: "มหาชัย ไทรงาม กำแพงเพชร 62150",
    },
    {
      value: "หนองคล้า ไทรงาม กำแพงเพชร 62150",
      label: "หนองคล้า ไทรงาม กำแพงเพชร 62150",
    },
    {
      value: "หนองทอง ไทรงาม กำแพงเพชร 62150",
      label: "หนองทอง ไทรงาม กำแพงเพชร 62150",
    },
    {
      value: "หนองแม่แตง ไทรงาม กำแพงเพชร 62150",
      label: "หนองแม่แตง ไทรงาม กำแพงเพชร 62150",
    },
    {
      value: "หนองไม้กอง ไทรงาม กำแพงเพชร 62150",
      label: "หนองไม้กอง ไทรงาม กำแพงเพชร 62150",
    },
    {
      value: "ไทรงาม ไทรงาม กำแพงเพชร 62150",
      label: "ไทรงาม ไทรงาม กำแพงเพชร 62150",
    },
    {
      value: "คุ้งสำเภา มโนรมย์ ชัยนาท 17110",
      label: "คุ้งสำเภา มโนรมย์ ชัยนาท 17110",
    },
    {
      value: "ท่าฉนวน มโนรมย์ ชัยนาท 17110",
      label: "ท่าฉนวน มโนรมย์ ชัยนาท 17110",
    },
    {
      value: "วัดโคก มโนรมย์ ชัยนาท 17110",
      label: "วัดโคก มโนรมย์ ชัยนาท 17110",
    },
    {
      value: "ศิลาดาน มโนรมย์ ชัยนาท 17110",
      label: "ศิลาดาน มโนรมย์ ชัยนาท 17110",
    },
    {
      value: "หางน้ำสาคร มโนรมย์ ชัยนาท 17170",
      label: "หางน้ำสาคร มโนรมย์ ชัยนาท 17170",
    },
    {
      value: "อู่ตะเภา มโนรมย์ ชัยนาท 17170",
      label: "อู่ตะเภา มโนรมย์ ชัยนาท 17170",
    },
    {
      value: "ไร่พัฒนา มโนรมย์ ชัยนาท 17170",
      label: "ไร่พัฒนา มโนรมย์ ชัยนาท 17170",
    },
    {
      value: "บ่อแร่ วัดสิงห์ ชัยนาท 17120",
      label: "บ่อแร่ วัดสิงห์ ชัยนาท 17120",
    },
    {
      value: "มะขามเฒ่า วัดสิงห์ ชัยนาท 17120",
      label: "มะขามเฒ่า วัดสิงห์ ชัยนาท 17120",
    },
    {
      value: "วังหมัน วัดสิงห์ ชัยนาท 17120",
      label: "วังหมัน วัดสิงห์ ชัยนาท 17120",
    },
    {
      value: "วัดสิงห์ วัดสิงห์ ชัยนาท 17120",
      label: "วัดสิงห์ วัดสิงห์ ชัยนาท 17120",
    },
    {
      value: "หนองขุ่น วัดสิงห์ ชัยนาท 17120",
      label: "หนองขุ่น วัดสิงห์ ชัยนาท 17120",
    },
    {
      value: "หนองน้อย วัดสิงห์ ชัยนาท 17120",
      label: "หนองน้อย วัดสิงห์ ชัยนาท 17120",
    },
    {
      value: "หนองบัว วัดสิงห์ ชัยนาท 17120",
      label: "หนองบัว วัดสิงห์ ชัยนาท 17120",
    },
    {
      value: "ดงคอน สรรคบุรี ชัยนาท 17140",
      label: "ดงคอน สรรคบุรี ชัยนาท 17140",
    },
    {
      value: "ดอนกำ สรรคบุรี ชัยนาท 17140",
      label: "ดอนกำ สรรคบุรี ชัยนาท 17140",
    },
    {
      value: "บางขุด สรรคบุรี ชัยนาท 17140",
      label: "บางขุด สรรคบุรี ชัยนาท 17140",
    },
    {
      value: "ห้วยกรด สรรคบุรี ชัยนาท 17140",
      label: "ห้วยกรด สรรคบุรี ชัยนาท 17140",
    },
    {
      value: "ห้วยกรดพัฒนา สรรคบุรี ชัยนาท 17140",
      label: "ห้วยกรดพัฒนา สรรคบุรี ชัยนาท 17140",
    },
    {
      value: "เที่ยงแท้ สรรคบุรี ชัยนาท 17140",
      label: "เที่ยงแท้ สรรคบุรี ชัยนาท 17140",
    },
    {
      value: "แพรกศรีราชา สรรคบุรี ชัยนาท 17140",
      label: "แพรกศรีราชา สรรคบุรี ชัยนาท 17140",
    },
    {
      value: "โพงาม สรรคบุรี ชัยนาท 17140",
      label: "โพงาม สรรคบุรี ชัยนาท 17140",
    },
    { value: "ตลุก สรรพยา ชัยนาท 17150", label: "ตลุก สรรพยา ชัยนาท 17150" },
    {
      value: "บางหลวง สรรพยา ชัยนาท 17150",
      label: "บางหลวง สรรพยา ชัยนาท 17150",
    },
    { value: "สรรพยา สรรพยา ชัยนาท 17150", label: "สรรพยา สรรพยา ชัยนาท 17150" },
    {
      value: "หาดอาษา สรรพยา ชัยนาท 17150",
      label: "หาดอาษา สรรพยา ชัยนาท 17150",
    },
    {
      value: "เขาแก้ว สรรพยา ชัยนาท 17150",
      label: "เขาแก้ว สรรพยา ชัยนาท 17150",
    },
    {
      value: "โพนางดำตก สรรพยา ชัยนาท 17150",
      label: "โพนางดำตก สรรพยา ชัยนาท 17150",
    },
    {
      value: "โพนางดำออก สรรพยา ชัยนาท 17150",
      label: "โพนางดำออก สรรพยา ชัยนาท 17150",
    },
    {
      value: "กุดจอก หนองมะโมง ชัยนาท 17120",
      label: "กุดจอก หนองมะโมง ชัยนาท 17120",
    },
    {
      value: "วังตะเคียน หนองมะโมง ชัยนาท 17120",
      label: "วังตะเคียน หนองมะโมง ชัยนาท 17120",
    },
    {
      value: "สะพานหิน หนองมะโมง ชัยนาท 17120",
      label: "สะพานหิน หนองมะโมง ชัยนาท 17120",
    },
    {
      value: "หนองมะโมง หนองมะโมง ชัยนาท 17120",
      label: "หนองมะโมง หนองมะโมง ชัยนาท 17120",
    },
    {
      value: "บ้านเชี่ยน หันคา ชัยนาท 17130",
      label: "บ้านเชี่ยน หันคา ชัยนาท 17130",
    },
    {
      value: "วังไก่เถื่อน หันคา ชัยนาท 17130",
      label: "วังไก่เถื่อน หันคา ชัยนาท 17130",
    },
    {
      value: "สามง่ามท่าโบสถ์ หันคา ชัยนาท 17160",
      label: "สามง่ามท่าโบสถ์ หันคา ชัยนาท 17160",
    },
    { value: "หนองแซง หันคา ชัยนาท 17160", label: "หนองแซง หันคา ชัยนาท 17160" },
    { value: "หันคา หันคา ชัยนาท 17130", label: "หันคา หันคา ชัยนาท 17130" },
    { value: "ห้วยงู หันคา ชัยนาท 17160", label: "ห้วยงู หันคา ชัยนาท 17160" },
    {
      value: "เด่นใหญ่ หันคา ชัยนาท 17130",
      label: "เด่นใหญ่ หันคา ชัยนาท 17130",
    },
    {
      value: "ไพรนกยูง หันคา ชัยนาท 17130",
      label: "ไพรนกยูง หันคา ชัยนาท 17130",
    },
    {
      value: "กะบกเตี้ย เนินขาม ชัยนาท 17130",
      label: "กะบกเตี้ย เนินขาม ชัยนาท 17130",
    },
    {
      value: "สุขเดือนห้า เนินขาม ชัยนาท 17130",
      label: "สุขเดือนห้า เนินขาม ชัยนาท 17130",
    },
    {
      value: "เนินขาม เนินขาม ชัยนาท 17130",
      label: "เนินขาม เนินขาม ชัยนาท 17130",
    },
    {
      value: "ชัยนาท เมืองชัยนาท ชัยนาท 17000",
      label: "ชัยนาท เมืองชัยนาท ชัยนาท 17000",
    },
    {
      value: "ท่าชัย เมืองชัยนาท ชัยนาท 17000",
      label: "ท่าชัย เมืองชัยนาท ชัยนาท 17000",
    },
    {
      value: "ธรรมามูล เมืองชัยนาท ชัยนาท 17000",
      label: "ธรรมามูล เมืองชัยนาท ชัยนาท 17000",
    },
    {
      value: "นางลือ เมืองชัยนาท ชัยนาท 17000",
      label: "นางลือ เมืองชัยนาท ชัยนาท 17000",
    },
    {
      value: "บ้านกล้วย เมืองชัยนาท ชัยนาท 17000",
      label: "บ้านกล้วย เมืองชัยนาท ชัยนาท 17000",
    },
    {
      value: "หาดท่าเสา เมืองชัยนาท ชัยนาท 17120",
      label: "หาดท่าเสา เมืองชัยนาท ชัยนาท 17120",
    },
    {
      value: "เขาท่าพระ เมืองชัยนาท ชัยนาท 17000",
      label: "เขาท่าพระ เมืองชัยนาท ชัยนาท 17000",
    },
    {
      value: "เสือโฮก เมืองชัยนาท ชัยนาท 17000",
      label: "เสือโฮก เมืองชัยนาท ชัยนาท 17000",
    },
    {
      value: "ในเมือง เมืองชัยนาท ชัยนาท 17000",
      label: "ในเมือง เมืองชัยนาท ชัยนาท 17000",
    },
    {
      value: "ทองหลาง บ้านนา นครนายก 26110",
      label: "ทองหลาง บ้านนา นครนายก 26110",
    },
    {
      value: "บางอ้อ บ้านนา นครนายก 26110",
      label: "บางอ้อ บ้านนา นครนายก 26110",
    },
    {
      value: "บ้านนา บ้านนา นครนายก 26110",
      label: "บ้านนา บ้านนา นครนายก 26110",
    },
    {
      value: "บ้านพริก บ้านนา นครนายก 26110",
      label: "บ้านพริก บ้านนา นครนายก 26110",
    },
    {
      value: "บ้านพร้าว บ้านนา นครนายก 26110",
      label: "บ้านพร้าว บ้านนา นครนายก 26110",
    },
    { value: "ป่าขะ บ้านนา นครนายก 26110", label: "ป่าขะ บ้านนา นครนายก 26110" },
    {
      value: "พิกุลออก บ้านนา นครนายก 26110",
      label: "พิกุลออก บ้านนา นครนายก 26110",
    },
    {
      value: "ศรีกะอาง บ้านนา นครนายก 26110",
      label: "ศรีกะอาง บ้านนา นครนายก 26110",
    },
    { value: "อาษา บ้านนา นครนายก 26110", label: "อาษา บ้านนา นครนายก 26110" },
    {
      value: "เขาเพิ่ม บ้านนา นครนายก 26110",
      label: "เขาเพิ่ม บ้านนา นครนายก 26110",
    },
    {
      value: "ท่าเรือ ปากพลี นครนายก 26130",
      label: "ท่าเรือ ปากพลี นครนายก 26130",
    },
    {
      value: "นาหินลาด ปากพลี นครนายก 26130",
      label: "นาหินลาด ปากพลี นครนายก 26130",
    },
    {
      value: "ปากพลี ปากพลี นครนายก 26130",
      label: "ปากพลี ปากพลี นครนายก 26130",
    },
    {
      value: "หนองแสง ปากพลี นครนายก 26130",
      label: "หนองแสง ปากพลี นครนายก 26130",
    },
    {
      value: "เกาะหวาย ปากพลี นครนายก 26130",
      label: "เกาะหวาย ปากพลี นครนายก 26130",
    },
    {
      value: "เกาะโพธิ์ ปากพลี นครนายก 26130",
      label: "เกาะโพธิ์ ปากพลี นครนายก 26130",
    },
    {
      value: "โคกกรวด ปากพลี นครนายก 26130",
      label: "โคกกรวด ปากพลี นครนายก 26130",
    },
    {
      value: "คลองใหญ่ องครักษ์ นครนายก 26120",
      label: "คลองใหญ่ องครักษ์ นครนายก 26120",
    },
    {
      value: "ชุมพล องครักษ์ นครนายก 26120",
      label: "ชุมพล องครักษ์ นครนายก 26120",
    },
    {
      value: "ทรายมูล องครักษ์ นครนายก 26120",
      label: "ทรายมูล องครักษ์ นครนายก 26120",
    },
    {
      value: "บางปลากด องครักษ์ นครนายก 26120",
      label: "บางปลากด องครักษ์ นครนายก 26120",
    },
    {
      value: "บางลูกเสือ องครักษ์ นครนายก 26120",
      label: "บางลูกเสือ องครักษ์ นครนายก 26120",
    },
    {
      value: "บางสมบูรณ์ องครักษ์ นครนายก 26120",
      label: "บางสมบูรณ์ องครักษ์ นครนายก 26120",
    },
    {
      value: "บึงศาล องครักษ์ นครนายก 26120",
      label: "บึงศาล องครักษ์ นครนายก 26120",
    },
    {
      value: "พระอาจารย์ องครักษ์ นครนายก 26120",
      label: "พระอาจารย์ องครักษ์ นครนายก 26120",
    },
    {
      value: "ศีรษะกระบือ องครักษ์ นครนายก 26120",
      label: "ศีรษะกระบือ องครักษ์ นครนายก 26120",
    },
    {
      value: "องครักษ์ องครักษ์ นครนายก 26120",
      label: "องครักษ์ องครักษ์ นครนายก 26120",
    },
    {
      value: "โพธิ์แทน องครักษ์ นครนายก 26120",
      label: "โพธิ์แทน องครักษ์ นครนายก 26120",
    },
    {
      value: "ดงละคร เมืองนครนายก นครนายก 26000",
      label: "ดงละคร เมืองนครนายก นครนายก 26000",
    },
    {
      value: "ดอนยอ เมืองนครนายก นครนายก 26000",
      label: "ดอนยอ เมืองนครนายก นครนายก 26000",
    },
    {
      value: "ท่าช้าง เมืองนครนายก นครนายก 26000",
      label: "ท่าช้าง เมืองนครนายก นครนายก 26000",
    },
    {
      value: "ท่าทราย เมืองนครนายก นครนายก 26000",
      label: "ท่าทราย เมืองนครนายก นครนายก 26000",
    },
    {
      value: "นครนายก เมืองนครนายก นครนายก 26000",
      label: "นครนายก เมืองนครนายก นครนายก 26000",
    },
    {
      value: "บ้านใหญ่ เมืองนครนายก นครนายก 26000",
      label: "บ้านใหญ่ เมืองนครนายก นครนายก 26000",
    },
    {
      value: "พรหมณี เมืองนครนายก นครนายก 26000",
      label: "พรหมณี เมืองนครนายก นครนายก 26000",
    },
    {
      value: "วังกระโจม เมืองนครนายก นครนายก 26000",
      label: "วังกระโจม เมืองนครนายก นครนายก 26000",
    },
    {
      value: "ศรีจุฬา เมืองนครนายก นครนายก 26000",
      label: "ศรีจุฬา เมืองนครนายก นครนายก 26000",
    },
    {
      value: "ศรีนาวา เมืองนครนายก นครนายก 26000",
      label: "ศรีนาวา เมืองนครนายก นครนายก 26000",
    },
    {
      value: "สาริกา เมืองนครนายก นครนายก 26000",
      label: "สาริกา เมืองนครนายก นครนายก 26000",
    },
    {
      value: "หินตั้ง เมืองนครนายก นครนายก 26000",
      label: "หินตั้ง เมืองนครนายก นครนายก 26000",
    },
    {
      value: "เขาพระ เมืองนครนายก นครนายก 26000",
      label: "เขาพระ เมืองนครนายก นครนายก 26000",
    },
    {
      value: "กระตีบ กำแพงแสน นครปฐม 73180",
      label: "กระตีบ กำแพงแสน นครปฐม 73180",
    },
    {
      value: "กำแพงแสน กำแพงแสน นครปฐม 73140",
      label: "กำแพงแสน กำแพงแสน นครปฐม 73140",
    },
    {
      value: "ดอนข่อย กำแพงแสน นครปฐม 73140",
      label: "ดอนข่อย กำแพงแสน นครปฐม 73140",
    },
    {
      value: "ทุ่งกระพังโหม กำแพงแสน นครปฐม 73140",
      label: "ทุ่งกระพังโหม กำแพงแสน นครปฐม 73140",
    },
    {
      value: "ทุ่งขวาง กำแพงแสน นครปฐม 73140",
      label: "ทุ่งขวาง กำแพงแสน นครปฐม 73140",
    },
    {
      value: "ทุ่งบัว กำแพงแสน นครปฐม 73140",
      label: "ทุ่งบัว กำแพงแสน นครปฐม 73140",
    },
    {
      value: "ทุ่งลูกนก กำแพงแสน นครปฐม 73140",
      label: "ทุ่งลูกนก กำแพงแสน นครปฐม 73140",
    },
    {
      value: "รางพิกุล กำแพงแสน นครปฐม 73140",
      label: "รางพิกุล กำแพงแสน นครปฐม 73140",
    },
    {
      value: "วังน้ำเขียว กำแพงแสน นครปฐม 73140",
      label: "วังน้ำเขียว กำแพงแสน นครปฐม 73140",
    },
    {
      value: "สระพัฒนา กำแพงแสน นครปฐม 73180",
      label: "สระพัฒนา กำแพงแสน นครปฐม 73180",
    },
    {
      value: "สระสี่มุม กำแพงแสน นครปฐม 73140",
      label: "สระสี่มุม กำแพงแสน นครปฐม 73140",
    },
    {
      value: "หนองกระทุ่ม กำแพงแสน นครปฐม 73140",
      label: "หนองกระทุ่ม กำแพงแสน นครปฐม 73140",
    },
    {
      value: "ห้วยขวาง กำแพงแสน นครปฐม 73140",
      label: "ห้วยขวาง กำแพงแสน นครปฐม 73140",
    },
    {
      value: "ห้วยม่วง กำแพงแสน นครปฐม 73180",
      label: "ห้วยม่วง กำแพงแสน นครปฐม 73180",
    },
    {
      value: "ห้วยหมอนทอง กำแพงแสน นครปฐม 73140",
      label: "ห้วยหมอนทอง กำแพงแสน นครปฐม 73140",
    },
    {
      value: "ดอนพุทรา ดอนตูม นครปฐม 73150",
      label: "ดอนพุทรา ดอนตูม นครปฐม 73150",
    },
    { value: "ดอนรวก ดอนตูม นครปฐม 73150", label: "ดอนรวก ดอนตูม นครปฐม 73150" },
    {
      value: "บ้านหลวง ดอนตูม นครปฐม 73150",
      label: "บ้านหลวง ดอนตูม นครปฐม 73150",
    },
    {
      value: "ลำลูกบัว ดอนตูม นครปฐม 73150",
      label: "ลำลูกบัว ดอนตูม นครปฐม 73150",
    },
    { value: "ลำเหย ดอนตูม นครปฐม 73150", label: "ลำเหย ดอนตูม นครปฐม 73150" },
    {
      value: "สามง่าม ดอนตูม นครปฐม 73150",
      label: "สามง่าม ดอนตูม นครปฐม 73150",
    },
    {
      value: "ห้วยด้วน ดอนตูม นครปฐม 73150",
      label: "ห้วยด้วน ดอนตูม นครปฐม 73150",
    },
    {
      value: "ห้วยพระ ดอนตูม นครปฐม 73150",
      label: "ห้วยพระ ดอนตูม นครปฐม 73150",
    },
    {
      value: "ขุนแก้ว นครชัยศรี นครปฐม 73120",
      label: "ขุนแก้ว นครชัยศรี นครปฐม 73120",
    },
    {
      value: "งิ้วราย นครชัยศรี นครปฐม 73120",
      label: "งิ้วราย นครชัยศรี นครปฐม 73120",
    },
    {
      value: "ดอนแฝก นครชัยศรี นครปฐม 73120",
      label: "ดอนแฝก นครชัยศรี นครปฐม 73120",
    },
    {
      value: "ท่ากระชับ นครชัยศรี นครปฐม 73120",
      label: "ท่ากระชับ นครชัยศรี นครปฐม 73120",
    },
    {
      value: "ท่าตำหนัก นครชัยศรี นครปฐม 73120",
      label: "ท่าตำหนัก นครชัยศรี นครปฐม 73120",
    },
    {
      value: "ท่าพระยา นครชัยศรี นครปฐม 73120",
      label: "ท่าพระยา นครชัยศรี นครปฐม 73120",
    },
    {
      value: "นครชัยศรี นครชัยศรี นครปฐม 73120",
      label: "นครชัยศรี นครชัยศรี นครปฐม 73120",
    },
    {
      value: "บางกระเบา นครชัยศรี นครปฐม 73120",
      label: "บางกระเบา นครชัยศรี นครปฐม 73120",
    },
    {
      value: "บางพระ นครชัยศรี นครปฐม 73120",
      label: "บางพระ นครชัยศรี นครปฐม 73120",
    },
    {
      value: "บางระกำ นครชัยศรี นครปฐม 73120",
      label: "บางระกำ นครชัยศรี นครปฐม 73120",
    },
    {
      value: "บางแก้ว นครชัยศรี นครปฐม 73120",
      label: "บางแก้ว นครชัยศรี นครปฐม 73120",
    },
    {
      value: "บางแก้วฟ้า นครชัยศรี นครปฐม 73120",
      label: "บางแก้วฟ้า นครชัยศรี นครปฐม 73120",
    },
    {
      value: "พะเนียด นครชัยศรี นครปฐม 73120",
      label: "พะเนียด นครชัยศรี นครปฐม 73120",
    },
    {
      value: "ลานตากฟ้า นครชัยศรี นครปฐม 73120",
      label: "ลานตากฟ้า นครชัยศรี นครปฐม 73120",
    },
    {
      value: "วัดละมุด นครชัยศรี นครปฐม 73120",
      label: "วัดละมุด นครชัยศรี นครปฐม 73120",
    },
    {
      value: "วัดสำโรง นครชัยศรี นครปฐม 73120",
      label: "วัดสำโรง นครชัยศรี นครปฐม 73120",
    },
    {
      value: "วัดแค นครชัยศรี นครปฐม 73120",
      label: "วัดแค นครชัยศรี นครปฐม 73120",
    },
    {
      value: "ศรีมหาโพธิ์ นครชัยศรี นครปฐม 73120",
      label: "ศรีมหาโพธิ์ นครชัยศรี นครปฐม 73120",
    },
    {
      value: "ศรีษะทอง นครชัยศรี นครปฐม 73120",
      label: "ศรีษะทอง นครชัยศรี นครปฐม 73120",
    },
    {
      value: "สัมปทวน นครชัยศรี นครปฐม 73120",
      label: "สัมปทวน นครชัยศรี นครปฐม 73120",
    },
    {
      value: "ห้วยพลู นครชัยศรี นครปฐม 73120",
      label: "ห้วยพลู นครชัยศรี นครปฐม 73120",
    },
    {
      value: "แหลมบัว นครชัยศรี นครปฐม 73120",
      label: "แหลมบัว นครชัยศรี นครปฐม 73120",
    },
    {
      value: "โคกพระเจดีย์ นครชัยศรี นครปฐม 73120",
      label: "โคกพระเจดีย์ นครชัยศรี นครปฐม 73120",
    },
    {
      value: "ไทยาวาส นครชัยศรี นครปฐม 73120",
      label: "ไทยาวาส นครชัยศรี นครปฐม 73120",
    },
    {
      value: "คลองนกกระทุง บางเลน นครปฐม 73130",
      label: "คลองนกกระทุง บางเลน นครปฐม 73130",
    },
    { value: "ดอนตูม บางเลน นครปฐม 73130", label: "ดอนตูม บางเลน นครปฐม 73130" },
    {
      value: "นราภิรมย์ บางเลน นครปฐม 73130",
      label: "นราภิรมย์ บางเลน นครปฐม 73130",
    },
    {
      value: "นิลเพชร บางเลน นครปฐม 73130",
      label: "นิลเพชร บางเลน นครปฐม 73130",
    },
    {
      value: "บัวปากท่า บางเลน นครปฐม 73130",
      label: "บัวปากท่า บางเลน นครปฐม 73130",
    },
    { value: "บางปลา บางเลน นครปฐม 73130", label: "บางปลา บางเลน นครปฐม 73130" },
    {
      value: "บางภาษี บางเลน นครปฐม 73130",
      label: "บางภาษี บางเลน นครปฐม 73130",
    },
    {
      value: "บางระกำ บางเลน นครปฐม 73130",
      label: "บางระกำ บางเลน นครปฐม 73130",
    },
    {
      value: "บางหลวง บางเลน นครปฐม 73190",
      label: "บางหลวง บางเลน นครปฐม 73190",
    },
    { value: "บางเลน บางเลน นครปฐม 73130", label: "บางเลน บางเลน นครปฐม 73130" },
    {
      value: "บางไทรป่า บางเลน นครปฐม 73130",
      label: "บางไทรป่า บางเลน นครปฐม 73130",
    },
    { value: "ลำพญา บางเลน นครปฐม 73130", label: "ลำพญา บางเลน นครปฐม 73130" },
    { value: "หินมูล บางเลน นครปฐม 73190", label: "หินมูล บางเลน นครปฐม 73190" },
    { value: "ไทรงาม บางเลน นครปฐม 73130", label: "ไทรงาม บางเลน นครปฐม 73130" },
    {
      value: "ไผ่หูช้าง บางเลน นครปฐม 73130",
      label: "ไผ่หูช้าง บางเลน นครปฐม 73130",
    },
    {
      value: "คลองโยง พุทธมณฑล นครปฐม 73170",
      label: "คลองโยง พุทธมณฑล นครปฐม 73170",
    },
    {
      value: "มหาสวัสดิ์ พุทธมณฑล นครปฐม 73170",
      label: "มหาสวัสดิ์ พุทธมณฑล นครปฐม 73170",
    },
    {
      value: "ศาลายา พุทธมณฑล นครปฐม 73170",
      label: "ศาลายา พุทธมณฑล นครปฐม 73170",
    },
    {
      value: "กระทุ่มล้ม สามพราน นครปฐม 73220",
      label: "กระทุ่มล้ม สามพราน นครปฐม 73220",
    },
    {
      value: "คลองจินดา สามพราน นครปฐม 73110",
      label: "คลองจินดา สามพราน นครปฐม 73110",
    },
    {
      value: "คลองใหม่ สามพราน นครปฐม 73110",
      label: "คลองใหม่ สามพราน นครปฐม 73110",
    },
    {
      value: "ตลาดจินดา สามพราน นครปฐม 73110",
      label: "ตลาดจินดา สามพราน นครปฐม 73110",
    },
    {
      value: "ทรงคนอง สามพราน นครปฐม 73210",
      label: "ทรงคนอง สามพราน นครปฐม 73210",
    },
    {
      value: "ท่าข้าม สามพราน นครปฐม 73110",
      label: "ท่าข้าม สามพราน นครปฐม 73110",
    },
    {
      value: "ท่าตลาด สามพราน นครปฐม 73110",
      label: "ท่าตลาด สามพราน นครปฐม 73110",
    },
    {
      value: "บางกระทึก สามพราน นครปฐม 73210",
      label: "บางกระทึก สามพราน นครปฐม 73210",
    },
    {
      value: "บางช้าง สามพราน นครปฐม 73110",
      label: "บางช้าง สามพราน นครปฐม 73110",
    },
    {
      value: "บางเตย สามพราน นครปฐม 73210",
      label: "บางเตย สามพราน นครปฐม 73210",
    },
    {
      value: "บ้านใหม่ สามพราน นครปฐม 73110",
      label: "บ้านใหม่ สามพราน นครปฐม 73110",
    },
    { value: "ยายชา สามพราน นครปฐม 73110", label: "ยายชา สามพราน นครปฐม 73110" },
    {
      value: "สามพราน สามพราน นครปฐม 73110",
      label: "สามพราน สามพราน นครปฐม 73110",
    },
    {
      value: "หอมเกร็ด สามพราน นครปฐม 73110",
      label: "หอมเกร็ด สามพราน นครปฐม 73110",
    },
    {
      value: "อ้อมใหญ่ สามพราน นครปฐม 73160",
      label: "อ้อมใหญ่ สามพราน นครปฐม 73160",
    },
    {
      value: "ไร่ขิง สามพราน นครปฐม 73210",
      label: "ไร่ขิง สามพราน นครปฐม 73210",
    },
    {
      value: "ดอนยายหอม เมืองนครปฐม นครปฐม 73000",
      label: "ดอนยายหอม เมืองนครปฐม นครปฐม 73000",
    },
    {
      value: "ตาก้อง เมืองนครปฐม นครปฐม 73000",
      label: "ตาก้อง เมืองนครปฐม นครปฐม 73000",
    },
    {
      value: "ถนนขาด เมืองนครปฐม นครปฐม 73000",
      label: "ถนนขาด เมืองนครปฐม นครปฐม 73000",
    },
    {
      value: "ทัพหลวง เมืองนครปฐม นครปฐม 73000",
      label: "ทัพหลวง เมืองนครปฐม นครปฐม 73000",
    },
    {
      value: "ทุ่งน้อย เมืองนครปฐม นครปฐม 73000",
      label: "ทุ่งน้อย เมืองนครปฐม นครปฐม 73000",
    },
    {
      value: "ธรรมศาลา เมืองนครปฐม นครปฐม 73000",
      label: "ธรรมศาลา เมืองนครปฐม นครปฐม 73000",
    },
    {
      value: "นครปฐม เมืองนครปฐม นครปฐม 73000",
      label: "นครปฐม เมืองนครปฐม นครปฐม 73000",
    },
    {
      value: "บางแขม เมืองนครปฐม นครปฐม 73000",
      label: "บางแขม เมืองนครปฐม นครปฐม 73000",
    },
    {
      value: "บ่อพลับ เมืองนครปฐม นครปฐม 73000",
      label: "บ่อพลับ เมืองนครปฐม นครปฐม 73000",
    },
    {
      value: "บ้านยาง เมืองนครปฐม นครปฐม 73000",
      label: "บ้านยาง เมืองนครปฐม นครปฐม 73000",
    },
    {
      value: "พระปฐมเจดีย์ เมืองนครปฐม นครปฐม 73000",
      label: "พระปฐมเจดีย์ เมืองนครปฐม นครปฐม 73000",
    },
    {
      value: "พระประโทน เมืองนครปฐม นครปฐม 73000",
      label: "พระประโทน เมืองนครปฐม นครปฐม 73000",
    },
    {
      value: "มาบแค เมืองนครปฐม นครปฐม 73000",
      label: "มาบแค เมืองนครปฐม นครปฐม 73000",
    },
    {
      value: "ลำพยา เมืองนครปฐม นครปฐม 73000",
      label: "ลำพยา เมืองนครปฐม นครปฐม 73000",
    },
    {
      value: "วังตะกู เมืองนครปฐม นครปฐม 73000",
      label: "วังตะกู เมืองนครปฐม นครปฐม 73000",
    },
    {
      value: "วังเย็น เมืองนครปฐม นครปฐม 73000",
      label: "วังเย็น เมืองนครปฐม นครปฐม 73000",
    },
    {
      value: "สนามจันทร์ เมืองนครปฐม นครปฐม 73000",
      label: "สนามจันทร์ เมืองนครปฐม นครปฐม 73000",
    },
    {
      value: "สระกะเทียม เมืองนครปฐม นครปฐม 73000",
      label: "สระกะเทียม เมืองนครปฐม นครปฐม 73000",
    },
    {
      value: "สวนป่าน เมืองนครปฐม นครปฐม 73000",
      label: "สวนป่าน เมืองนครปฐม นครปฐม 73000",
    },
    {
      value: "สามควายเผือก เมืองนครปฐม นครปฐม 73000",
      label: "สามควายเผือก เมืองนครปฐม นครปฐม 73000",
    },
    {
      value: "หนองงูเหลือม เมืองนครปฐม นครปฐม 73000",
      label: "หนองงูเหลือม เมืองนครปฐม นครปฐม 73000",
    },
    {
      value: "หนองดินแดง เมืองนครปฐม นครปฐม 73000",
      label: "หนองดินแดง เมืองนครปฐม นครปฐม 73000",
    },
    {
      value: "หนองปากโลง เมืองนครปฐม นครปฐม 73000",
      label: "หนองปากโลง เมืองนครปฐม นครปฐม 73000",
    },
    {
      value: "ห้วยจรเข้ เมืองนครปฐม นครปฐม 73000",
      label: "ห้วยจรเข้ เมืองนครปฐม นครปฐม 73000",
    },
    {
      value: "โพรงมะเดื่อ เมืองนครปฐม นครปฐม 73000",
      label: "โพรงมะเดื่อ เมืองนครปฐม นครปฐม 73000",
    },
    {
      value: "ชุมตาบง ชุมตาบง นครสวรรค์ 60150",
      label: "ชุมตาบง ชุมตาบง นครสวรรค์ 60150",
    },
    {
      value: "ปางสวรรค์ ชุมตาบง นครสวรรค์ 60150",
      label: "ปางสวรรค์ ชุมตาบง นครสวรรค์ 60150",
    },
    {
      value: "ฆะมัง ชุมแสง นครสวรรค์ 60120",
      label: "ฆะมัง ชุมแสง นครสวรรค์ 60120",
    },
    {
      value: "ชุมแสง ชุมแสง นครสวรรค์ 60120",
      label: "ชุมแสง ชุมแสง นครสวรรค์ 60120",
    },
    {
      value: "ทับกฤช ชุมแสง นครสวรรค์ 60250",
      label: "ทับกฤช ชุมแสง นครสวรรค์ 60250",
    },
    {
      value: "ทับกฤชใต้ ชุมแสง นครสวรรค์ 60250",
      label: "ทับกฤชใต้ ชุมแสง นครสวรรค์ 60250",
    },
    {
      value: "ท่าไม้ ชุมแสง นครสวรรค์ 60120",
      label: "ท่าไม้ ชุมแสง นครสวรรค์ 60120",
    },
    {
      value: "บางเคียน ชุมแสง นครสวรรค์ 60120",
      label: "บางเคียน ชุมแสง นครสวรรค์ 60120",
    },
    {
      value: "พันลาน ชุมแสง นครสวรรค์ 60250",
      label: "พันลาน ชุมแสง นครสวรรค์ 60250",
    },
    {
      value: "พิกุล ชุมแสง นครสวรรค์ 60120",
      label: "พิกุล ชุมแสง นครสวรรค์ 60120",
    },
    {
      value: "หนองกระเจา ชุมแสง นครสวรรค์ 60120",
      label: "หนองกระเจา ชุมแสง นครสวรรค์ 60120",
    },
    {
      value: "เกยไชย ชุมแสง นครสวรรค์ 60120",
      label: "เกยไชย ชุมแสง นครสวรรค์ 60120",
    },
    {
      value: "โคกหม้อ ชุมแสง นครสวรรค์ 60120",
      label: "โคกหม้อ ชุมแสง นครสวรรค์ 60120",
    },
    {
      value: "ไผ่สิงห์ ชุมแสง นครสวรรค์ 60120",
      label: "ไผ่สิงห์ ชุมแสง นครสวรรค์ 60120",
    },
    {
      value: "ตากฟ้า ตากฟ้า นครสวรรค์ 60190",
      label: "ตากฟ้า ตากฟ้า นครสวรรค์ 60190",
    },
    {
      value: "พุนกยูง ตากฟ้า นครสวรรค์ 60190",
      label: "พุนกยูง ตากฟ้า นครสวรรค์ 60190",
    },
    {
      value: "ลำพยนต์ ตากฟ้า นครสวรรค์ 60190",
      label: "ลำพยนต์ ตากฟ้า นครสวรรค์ 60190",
    },
    {
      value: "สุขสำราญ ตากฟ้า นครสวรรค์ 60190",
      label: "สุขสำราญ ตากฟ้า นครสวรรค์ 60190",
    },
    {
      value: "หนองพิกุล ตากฟ้า นครสวรรค์ 60190",
      label: "หนองพิกุล ตากฟ้า นครสวรรค์ 60190",
    },
    {
      value: "อุดมธัญญา ตากฟ้า นครสวรรค์ 60190",
      label: "อุดมธัญญา ตากฟ้า นครสวรรค์ 60190",
    },
    {
      value: "เขาชายธง ตากฟ้า นครสวรรค์ 60190",
      label: "เขาชายธง ตากฟ้า นครสวรรค์ 60190",
    },
    {
      value: "จันเสน ตาคลี นครสวรรค์ 60260",
      label: "จันเสน ตาคลี นครสวรรค์ 60260",
    },
    {
      value: "ช่องแค ตาคลี นครสวรรค์ 60210",
      label: "ช่องแค ตาคลี นครสวรรค์ 60210",
    },
    {
      value: "ตาคลี ตาคลี นครสวรรค์ 60140",
      label: "ตาคลี ตาคลี นครสวรรค์ 60140",
    },
    {
      value: "พรหมนิมิต ตาคลี นครสวรรค์ 60210",
      label: "พรหมนิมิต ตาคลี นครสวรรค์ 60210",
    },
    {
      value: "ลาดทิพรส ตาคลี นครสวรรค์ 60260",
      label: "ลาดทิพรส ตาคลี นครสวรรค์ 60260",
    },
    {
      value: "สร้อยทอง ตาคลี นครสวรรค์ 60210",
      label: "สร้อยทอง ตาคลี นครสวรรค์ 60210",
    },
    {
      value: "หนองหม้อ ตาคลี นครสวรรค์ 60140",
      label: "หนองหม้อ ตาคลี นครสวรรค์ 60140",
    },
    {
      value: "หนองโพ ตาคลี นครสวรรค์ 60140",
      label: "หนองโพ ตาคลี นครสวรรค์ 60140",
    },
    {
      value: "หัวหวาย ตาคลี นครสวรรค์ 60140",
      label: "หัวหวาย ตาคลี นครสวรรค์ 60140",
    },
    {
      value: "ห้วยหอม ตาคลี นครสวรรค์ 60210",
      label: "ห้วยหอม ตาคลี นครสวรรค์ 60210",
    },
    {
      value: "ดอนคา ท่าตะโก นครสวรรค์ 60160",
      label: "ดอนคา ท่าตะโก นครสวรรค์ 60160",
    },
    {
      value: "ทำนบ ท่าตะโก นครสวรรค์ 60160",
      label: "ทำนบ ท่าตะโก นครสวรรค์ 60160",
    },
    {
      value: "ท่าตะโก ท่าตะโก นครสวรรค์ 60160",
      label: "ท่าตะโก ท่าตะโก นครสวรรค์ 60160",
    },
    {
      value: "พนมรอก ท่าตะโก นครสวรรค์ 60160",
      label: "พนมรอก ท่าตะโก นครสวรรค์ 60160",
    },
    {
      value: "พนมเศษ ท่าตะโก นครสวรรค์ 60160",
      label: "พนมเศษ ท่าตะโก นครสวรรค์ 60160",
    },
    {
      value: "วังมหากร ท่าตะโก นครสวรรค์ 60160",
      label: "วังมหากร ท่าตะโก นครสวรรค์ 60160",
    },
    {
      value: "วังใหญ่ ท่าตะโก นครสวรรค์ 60160",
      label: "วังใหญ่ ท่าตะโก นครสวรรค์ 60160",
    },
    {
      value: "สายลำโพง ท่าตะโก นครสวรรค์ 60160",
      label: "สายลำโพง ท่าตะโก นครสวรรค์ 60160",
    },
    {
      value: "หนองหลวง ท่าตะโก นครสวรรค์ 60160",
      label: "หนองหลวง ท่าตะโก นครสวรรค์ 60160",
    },
    {
      value: "หัวถนน ท่าตะโก นครสวรรค์ 60160",
      label: "หัวถนน ท่าตะโก นครสวรรค์ 60160",
    },
    {
      value: "ด่านช้าง บรรพตพิสัย นครสวรรค์ 60180",
      label: "ด่านช้าง บรรพตพิสัย นครสวรรค์ 60180",
    },
    {
      value: "ตาขีด บรรพตพิสัย นครสวรรค์ 60180",
      label: "ตาขีด บรรพตพิสัย นครสวรรค์ 60180",
    },
    {
      value: "ตาสัง บรรพตพิสัย นครสวรรค์ 60180",
      label: "ตาสัง บรรพตพิสัย นครสวรรค์ 60180",
    },
    {
      value: "ท่างิ้ว บรรพตพิสัย นครสวรรค์ 60180",
      label: "ท่างิ้ว บรรพตพิสัย นครสวรรค์ 60180",
    },
    {
      value: "บางตาหงาย บรรพตพิสัย นครสวรรค์ 60180",
      label: "บางตาหงาย บรรพตพิสัย นครสวรรค์ 60180",
    },
    {
      value: "บางแก้ว บรรพตพิสัย นครสวรรค์ 60180",
      label: "บางแก้ว บรรพตพิสัย นครสวรรค์ 60180",
    },
    {
      value: "บึงปลาทู บรรพตพิสัย นครสวรรค์ 60180",
      label: "บึงปลาทู บรรพตพิสัย นครสวรรค์ 60180",
    },
    {
      value: "บ้านแดน บรรพตพิสัย นครสวรรค์ 60180",
      label: "บ้านแดน บรรพตพิสัย นครสวรรค์ 60180",
    },
    {
      value: "หนองกรด บรรพตพิสัย นครสวรรค์ 60180",
      label: "หนองกรด บรรพตพิสัย นครสวรรค์ 60180",
    },
    {
      value: "หนองตางู บรรพตพิสัย นครสวรรค์ 60180",
      label: "หนองตางู บรรพตพิสัย นครสวรรค์ 60180",
    },
    {
      value: "หูกวาง บรรพตพิสัย นครสวรรค์ 60180",
      label: "หูกวาง บรรพตพิสัย นครสวรรค์ 60180",
    },
    {
      value: "อ่างทอง บรรพตพิสัย นครสวรรค์ 60180",
      label: "อ่างทอง บรรพตพิสัย นครสวรรค์ 60180",
    },
    {
      value: "เจริญผล บรรพตพิสัย นครสวรรค์ 60180",
      label: "เจริญผล บรรพตพิสัย นครสวรรค์ 60180",
    },
    {
      value: "ท่าน้ำอ้อย พยุหะคีรี นครสวรรค์ 60130",
      label: "ท่าน้ำอ้อย พยุหะคีรี นครสวรรค์ 60130",
    },
    {
      value: "นิคมเขาบ่อแก้ว พยุหะคีรี นครสวรรค์ 60130",
      label: "นิคมเขาบ่อแก้ว พยุหะคีรี นครสวรรค์ 60130",
    },
    {
      value: "น้ำทรง พยุหะคีรี นครสวรรค์ 60130",
      label: "น้ำทรง พยุหะคีรี นครสวรรค์ 60130",
    },
    {
      value: "พยุหะ พยุหะคีรี นครสวรรค์ 60130",
      label: "พยุหะ พยุหะคีรี นครสวรรค์ 60130",
    },
    {
      value: "ม่วงหัก พยุหะคีรี นครสวรรค์ 60130",
      label: "ม่วงหัก พยุหะคีรี นครสวรรค์ 60130",
    },
    {
      value: "ยางขาว พยุหะคีรี นครสวรรค์ 60130",
      label: "ยางขาว พยุหะคีรี นครสวรรค์ 60130",
    },
    {
      value: "ย่านมัทรี พยุหะคีรี นครสวรรค์ 60130",
      label: "ย่านมัทรี พยุหะคีรี นครสวรรค์ 60130",
    },
    {
      value: "สระทะเล พยุหะคีรี นครสวรรค์ 60130",
      label: "สระทะเล พยุหะคีรี นครสวรรค์ 60130",
    },
    {
      value: "เขากะลา พยุหะคีรี นครสวรรค์ 60130",
      label: "เขากะลา พยุหะคีรี นครสวรรค์ 60130",
    },
    {
      value: "เขาทอง พยุหะคีรี นครสวรรค์ 60130",
      label: "เขาทอง พยุหะคีรี นครสวรรค์ 60130",
    },
    {
      value: "เนินมะกอก พยุหะคีรี นครสวรรค์ 60130",
      label: "เนินมะกอก พยุหะคีรี นครสวรรค์ 60130",
    },
    {
      value: "บ้านไร่ ลาดยาว นครสวรรค์ 60150",
      label: "บ้านไร่ ลาดยาว นครสวรรค์ 60150",
    },
    {
      value: "มาบแก ลาดยาว นครสวรรค์ 60150",
      label: "มาบแก ลาดยาว นครสวรรค์ 60150",
    },
    {
      value: "ลาดยาว ลาดยาว นครสวรรค์ 60150",
      label: "ลาดยาว ลาดยาว นครสวรรค์ 60150",
    },
    {
      value: "วังม้า ลาดยาว นครสวรรค์ 60150",
      label: "วังม้า ลาดยาว นครสวรรค์ 60150",
    },
    {
      value: "วังเมือง ลาดยาว นครสวรรค์ 60150",
      label: "วังเมือง ลาดยาว นครสวรรค์ 60150",
    },
    {
      value: "ศาลเจ้าไก่ต่อ ลาดยาว นครสวรรค์ 60150",
      label: "ศาลเจ้าไก่ต่อ ลาดยาว นครสวรรค์ 60150",
    },
    {
      value: "สระแก้ว ลาดยาว นครสวรรค์ 60150",
      label: "สระแก้ว ลาดยาว นครสวรรค์ 60150",
    },
    {
      value: "สร้อยละคร ลาดยาว นครสวรรค์ 60150",
      label: "สร้อยละคร ลาดยาว นครสวรรค์ 60150",
    },
    {
      value: "หนองนมวัว ลาดยาว นครสวรรค์ 60150",
      label: "หนองนมวัว ลาดยาว นครสวรรค์ 60150",
    },
    {
      value: "หนองยาว ลาดยาว นครสวรรค์ 60150",
      label: "หนองยาว ลาดยาว นครสวรรค์ 60150",
    },
    {
      value: "ห้วยน้ำหอม ลาดยาว นครสวรรค์ 60150",
      label: "ห้วยน้ำหอม ลาดยาว นครสวรรค์ 60150",
    },
    {
      value: "เนินขี้เหล็ก ลาดยาว นครสวรรค์ 60150",
      label: "เนินขี้เหล็ก ลาดยาว นครสวรรค์ 60150",
    },
    {
      value: "ทุ่งทอง หนองบัว นครสวรรค์ 60110",
      label: "ทุ่งทอง หนองบัว นครสวรรค์ 60110",
    },
    {
      value: "ธารทหาร หนองบัว นครสวรรค์ 60110",
      label: "ธารทหาร หนองบัว นครสวรรค์ 60110",
    },
    {
      value: "วังบ่อ หนองบัว นครสวรรค์ 60110",
      label: "วังบ่อ หนองบัว นครสวรรค์ 60110",
    },
    {
      value: "หนองกลับ หนองบัว นครสวรรค์ 60110",
      label: "หนองกลับ หนองบัว นครสวรรค์ 60110",
    },
    {
      value: "หนองบัว หนองบัว นครสวรรค์ 60110",
      label: "หนองบัว หนองบัว นครสวรรค์ 60110",
    },
    {
      value: "ห้วยถั่วเหนือ หนองบัว นครสวรรค์ 60110",
      label: "ห้วยถั่วเหนือ หนองบัว นครสวรรค์ 60110",
    },
    {
      value: "ห้วยถั่วใต้ หนองบัว นครสวรรค์ 60110",
      label: "ห้วยถั่วใต้ หนองบัว นครสวรรค์ 60110",
    },
    {
      value: "ห้วยร่วม หนองบัว นครสวรรค์ 60110",
      label: "ห้วยร่วม หนองบัว นครสวรรค์ 60110",
    },
    {
      value: "ห้วยใหญ่ หนองบัว นครสวรรค์ 60110",
      label: "ห้วยใหญ่ หนองบัว นครสวรรค์ 60110",
    },
    {
      value: "มหาโพธิ เก้าเลี้ยว นครสวรรค์ 60230",
      label: "มหาโพธิ เก้าเลี้ยว นครสวรรค์ 60230",
    },
    {
      value: "หนองเต่า เก้าเลี้ยว นครสวรรค์ 60230",
      label: "หนองเต่า เก้าเลี้ยว นครสวรรค์ 60230",
    },
    {
      value: "หัวดง เก้าเลี้ยว นครสวรรค์ 60230",
      label: "หัวดง เก้าเลี้ยว นครสวรรค์ 60230",
    },
    {
      value: "เก้าเลี้ยว เก้าเลี้ยว นครสวรรค์ 60230",
      label: "เก้าเลี้ยว เก้าเลี้ยว นครสวรรค์ 60230",
    },
    {
      value: "เขาดิน เก้าเลี้ยว นครสวรรค์ 60230",
      label: "เขาดิน เก้าเลี้ยว นครสวรรค์ 60230",
    },
    {
      value: "กลางแดด เมืองนครสวรรค์ นครสวรรค์ 60000",
      label: "กลางแดด เมืองนครสวรรค์ นครสวรรค์ 60000",
    },
    {
      value: "ตะเคียนเลื่อน เมืองนครสวรรค์ นครสวรรค์ 60000",
      label: "ตะเคียนเลื่อน เมืองนครสวรรค์ นครสวรรค์ 60000",
    },
    {
      value: "นครสวรรค์ตก เมืองนครสวรรค์ นครสวรรค์ 60000",
      label: "นครสวรรค์ตก เมืองนครสวรรค์ นครสวรรค์ 60000",
    },
    {
      value: "นครสวรรค์ออก เมืองนครสวรรค์ นครสวรรค์ 60000",
      label: "นครสวรรค์ออก เมืองนครสวรรค์ นครสวรรค์ 60000",
    },
    {
      value: "บางพระหลวง เมืองนครสวรรค์ นครสวรรค์ 60000",
      label: "บางพระหลวง เมืองนครสวรรค์ นครสวรรค์ 60000",
    },
    {
      value: "บางม่วง เมืองนครสวรรค์ นครสวรรค์ 60000",
      label: "บางม่วง เมืองนครสวรรค์ นครสวรรค์ 60000",
    },
    {
      value: "บึงเสนาท เมืองนครสวรรค์ นครสวรรค์ 60000",
      label: "บึงเสนาท เมืองนครสวรรค์ นครสวรรค์ 60000",
    },
    {
      value: "บ้านมะเกลือ เมืองนครสวรรค์ นครสวรรค์ 60000",
      label: "บ้านมะเกลือ เมืองนครสวรรค์ นครสวรรค์ 60000",
    },
    {
      value: "บ้านแก่ง เมืองนครสวรรค์ นครสวรรค์ 60000",
      label: "บ้านแก่ง เมืองนครสวรรค์ นครสวรรค์ 60000",
    },
    {
      value: "ปากน้ำโพ เมืองนครสวรรค์ นครสวรรค์ 60000",
      label: "ปากน้ำโพ เมืองนครสวรรค์ นครสวรรค์ 60000",
    },
    {
      value: "พระนอน เมืองนครสวรรค์ นครสวรรค์ 60000",
      label: "พระนอน เมืองนครสวรรค์ นครสวรรค์ 60000",
    },
    {
      value: "วัดไทรย์ เมืองนครสวรรค์ นครสวรรค์ 60000",
      label: "วัดไทรย์ เมืองนครสวรรค์ นครสวรรค์ 60000",
    },
    {
      value: "หนองกรด เมืองนครสวรรค์ นครสวรรค์ 60240",
      label: "หนองกรด เมืองนครสวรรค์ นครสวรรค์ 60240",
    },
    {
      value: "หนองกระโดน เมืองนครสวรรค์ นครสวรรค์ 60240",
      label: "หนองกระโดน เมืองนครสวรรค์ นครสวรรค์ 60240",
    },
    {
      value: "หนองปลิง เมืองนครสวรรค์ นครสวรรค์ 60000",
      label: "หนองปลิง เมืองนครสวรรค์ นครสวรรค์ 60000",
    },
    {
      value: "เกรียงไกร เมืองนครสวรรค์ นครสวรรค์ 60000",
      label: "เกรียงไกร เมืองนครสวรรค์ นครสวรรค์ 60000",
    },
    {
      value: "แควใหญ่ เมืองนครสวรรค์ นครสวรรค์ 60000",
      label: "แควใหญ่ เมืองนครสวรรค์ นครสวรรค์ 60000",
    },
    {
      value: "วังซ่าน แม่วงก์ นครสวรรค์ 60150",
      label: "วังซ่าน แม่วงก์ นครสวรรค์ 60150",
    },
    {
      value: "เขาชนกัน แม่วงก์ นครสวรรค์ 60150",
      label: "เขาชนกัน แม่วงก์ นครสวรรค์ 60150",
    },
    {
      value: "แม่วงก์ แม่วงก์ นครสวรรค์ 60150",
      label: "แม่วงก์ แม่วงก์ นครสวรรค์ 60150",
    },
    {
      value: "แม่เล่ย์ แม่วงก์ นครสวรรค์ 60150",
      label: "แม่เล่ย์ แม่วงก์ นครสวรรค์ 60150",
    },
    {
      value: "แม่เปิน แม่เปิน นครสวรรค์ 60150",
      label: "แม่เปิน แม่เปิน นครสวรรค์ 60150",
    },
    {
      value: "นากลาง โกรกพระ นครสวรรค์ 60170",
      label: "นากลาง โกรกพระ นครสวรรค์ 60170",
    },
    {
      value: "บางประมุง โกรกพระ นครสวรรค์ 60170",
      label: "บางประมุง โกรกพระ นครสวรรค์ 60170",
    },
    {
      value: "บางมะฝ่อ โกรกพระ นครสวรรค์ 60170",
      label: "บางมะฝ่อ โกรกพระ นครสวรรค์ 60170",
    },
    {
      value: "ยางตาล โกรกพระ นครสวรรค์ 60170",
      label: "ยางตาล โกรกพระ นครสวรรค์ 60170",
    },
    {
      value: "ศาลาแดง โกรกพระ นครสวรรค์ 60170",
      label: "ศาลาแดง โกรกพระ นครสวรรค์ 60170",
    },
    {
      value: "หาดสูง โกรกพระ นครสวรรค์ 60170",
      label: "หาดสูง โกรกพระ นครสวรรค์ 60170",
    },
    {
      value: "เนินกว้าว โกรกพระ นครสวรรค์ 60170",
      label: "เนินกว้าว โกรกพระ นครสวรรค์ 60170",
    },
    {
      value: "เนินศาลา โกรกพระ นครสวรรค์ 60170",
      label: "เนินศาลา โกรกพระ นครสวรรค์ 60170",
    },
    {
      value: "โกรกพระ โกรกพระ นครสวรรค์ 60170",
      label: "โกรกพระ โกรกพระ นครสวรรค์ 60170",
    },
    {
      value: "ตะคร้อ ไพศาลี นครสวรรค์ 60220",
      label: "ตะคร้อ ไพศาลี นครสวรรค์ 60220",
    },
    {
      value: "นาขอม ไพศาลี นครสวรรค์ 60220",
      label: "นาขอม ไพศาลี นครสวรรค์ 60220",
    },
    {
      value: "วังข่อย ไพศาลี นครสวรรค์ 60220",
      label: "วังข่อย ไพศาลี นครสวรรค์ 60220",
    },
    {
      value: "วังน้ำลัด ไพศาลี นครสวรรค์ 60220",
      label: "วังน้ำลัด ไพศาลี นครสวรรค์ 60220",
    },
    {
      value: "สำโรงชัย ไพศาลี นครสวรรค์ 60220",
      label: "สำโรงชัย ไพศาลี นครสวรรค์ 60220",
    },
    {
      value: "โคกเดื่อ ไพศาลี นครสวรรค์ 60220",
      label: "โคกเดื่อ ไพศาลี นครสวรรค์ 60220",
    },
    {
      value: "โพธิ์ประสาท ไพศาลี นครสวรรค์ 60220",
      label: "โพธิ์ประสาท ไพศาลี นครสวรรค์ 60220",
    },
    {
      value: "ไพศาลี ไพศาลี นครสวรรค์ 60220",
      label: "ไพศาลี ไพศาลี นครสวรรค์ 60220",
    },
    {
      value: "บางกรวย บางกรวย นนทบุรี 11130",
      label: "บางกรวย บางกรวย นนทบุรี 11130",
    },
    {
      value: "บางขนุน บางกรวย นนทบุรี 11130",
      label: "บางขนุน บางกรวย นนทบุรี 11130",
    },
    {
      value: "บางขุนกอง บางกรวย นนทบุรี 11130",
      label: "บางขุนกอง บางกรวย นนทบุรี 11130",
    },
    {
      value: "บางคูเวียง บางกรวย นนทบุรี 11130",
      label: "บางคูเวียง บางกรวย นนทบุรี 11130",
    },
    {
      value: "บางสีทอง บางกรวย นนทบุรี 11130",
      label: "บางสีทอง บางกรวย นนทบุรี 11130",
    },
    {
      value: "ปลายบาง บางกรวย นนทบุรี 11130",
      label: "ปลายบาง บางกรวย นนทบุรี 11130",
    },
    {
      value: "มหาสวัสดิ์ บางกรวย นนทบุรี 11130",
      label: "มหาสวัสดิ์ บางกรวย นนทบุรี 11130",
    },
    {
      value: "วัดชลอ บางกรวย นนทบุรี 11130",
      label: "วัดชลอ บางกรวย นนทบุรี 11130",
    },
    {
      value: "ศาลากลาง บางกรวย นนทบุรี 11130",
      label: "ศาลากลาง บางกรวย นนทบุรี 11130",
    },
    {
      value: "บางคูรัด บางบัวทอง นนทบุรี 11110",
      label: "บางคูรัด บางบัวทอง นนทบุรี 11110",
    },
    {
      value: "บางบัวทอง บางบัวทอง นนทบุรี 11110",
      label: "บางบัวทอง บางบัวทอง นนทบุรี 11110",
    },
    {
      value: "บางรักพัฒนา บางบัวทอง นนทบุรี 11110",
      label: "บางรักพัฒนา บางบัวทอง นนทบุรี 11110",
    },
    {
      value: "บางรักใหญ่ บางบัวทอง นนทบุรี 11110",
      label: "บางรักใหญ่ บางบัวทอง นนทบุรี 11110",
    },
    {
      value: "พิมลราช บางบัวทอง นนทบุรี 11110",
      label: "พิมลราช บางบัวทอง นนทบุรี 11110",
    },
    {
      value: "ละหาร บางบัวทอง นนทบุรี 11110",
      label: "ละหาร บางบัวทอง นนทบุรี 11110",
    },
    {
      value: "ลำโพ บางบัวทอง นนทบุรี 11110",
      label: "ลำโพ บางบัวทอง นนทบุรี 11110",
    },
    {
      value: "โสนลอย บางบัวทอง นนทบุรี 11110",
      label: "โสนลอย บางบัวทอง นนทบุรี 11110",
    },
    {
      value: "บางม่วง บางใหญ่ นนทบุรี 11140",
      label: "บางม่วง บางใหญ่ นนทบุรี 11140",
    },
    {
      value: "บางเลน บางใหญ่ นนทบุรี 11140",
      label: "บางเลน บางใหญ่ นนทบุรี 11140",
    },
    {
      value: "บางแม่นาง บางใหญ่ นนทบุรี 11140",
      label: "บางแม่นาง บางใหญ่ นนทบุรี 11140",
    },
    {
      value: "บางใหญ่ บางใหญ่ นนทบุรี 11140",
      label: "บางใหญ่ บางใหญ่ นนทบุรี 11140",
    },
    {
      value: "บ้านใหม่ บางใหญ่ นนทบุรี 11140",
      label: "บ้านใหม่ บางใหญ่ นนทบุรี 11140",
    },
    {
      value: "เสาธงหิน บางใหญ่ นนทบุรี 11140",
      label: "เสาธงหิน บางใหญ่ นนทบุรี 11140",
    },
    {
      value: "คลองข่อย ปากเกร็ด นนทบุรี 11120",
      label: "คลองข่อย ปากเกร็ด นนทบุรี 11120",
    },
    {
      value: "คลองพระอุดม ปากเกร็ด นนทบุรี 11120",
      label: "คลองพระอุดม ปากเกร็ด นนทบุรี 11120",
    },
    {
      value: "คลองเกลือ ปากเกร็ด นนทบุรี 11120",
      label: "คลองเกลือ ปากเกร็ด นนทบุรี 11120",
    },
    {
      value: "ท่าอิฐ ปากเกร็ด นนทบุรี 11120",
      label: "ท่าอิฐ ปากเกร็ด นนทบุรี 11120",
    },
    {
      value: "บางตลาด ปากเกร็ด นนทบุรี 11120",
      label: "บางตลาด ปากเกร็ด นนทบุรี 11120",
    },
    {
      value: "บางตะไนย์ ปากเกร็ด นนทบุรี 11120",
      label: "บางตะไนย์ ปากเกร็ด นนทบุรี 11120",
    },
    {
      value: "บางพลับ ปากเกร็ด นนทบุรี 11120",
      label: "บางพลับ ปากเกร็ด นนทบุรี 11120",
    },
    {
      value: "บางพูด ปากเกร็ด นนทบุรี 11120",
      label: "บางพูด ปากเกร็ด นนทบุรี 11120",
    },
    {
      value: "บ้านใหม่ ปากเกร็ด นนทบุรี 11120",
      label: "บ้านใหม่ ปากเกร็ด นนทบุรี 11120",
    },
    {
      value: "ปากเกร็ด ปากเกร็ด นนทบุรี 11120",
      label: "ปากเกร็ด ปากเกร็ด นนทบุรี 11120",
    },
    {
      value: "อ้อมเกร็ด ปากเกร็ด นนทบุรี 11120",
      label: "อ้อมเกร็ด ปากเกร็ด นนทบุรี 11120",
    },
    {
      value: "เกาะเกร็ด ปากเกร็ด นนทบุรี 11120",
      label: "เกาะเกร็ด ปากเกร็ด นนทบุรี 11120",
    },
    {
      value: "ตลาดขวัญ เมืองนนทบุรี นนทบุรี 11000",
      label: "ตลาดขวัญ เมืองนนทบุรี นนทบุรี 11000",
    },
    {
      value: "ท่าทราย เมืองนนทบุรี นนทบุรี 11000",
      label: "ท่าทราย เมืองนนทบุรี นนทบุรี 11000",
    },
    {
      value: "บางกระสอ เมืองนนทบุรี นนทบุรี 11000",
      label: "บางกระสอ เมืองนนทบุรี นนทบุรี 11000",
    },
    {
      value: "บางกร่าง เมืองนนทบุรี นนทบุรี 11000",
      label: "บางกร่าง เมืองนนทบุรี นนทบุรี 11000",
    },
    {
      value: "บางรักน้อย เมืองนนทบุรี นนทบุรี 11000",
      label: "บางรักน้อย เมืองนนทบุรี นนทบุรี 11000",
    },
    {
      value: "บางศรีเมือง เมืองนนทบุรี นนทบุรี 11000",
      label: "บางศรีเมือง เมืองนนทบุรี นนทบุรี 11000",
    },
    {
      value: "บางเขน เมืองนนทบุรี นนทบุรี 11000",
      label: "บางเขน เมืองนนทบุรี นนทบุรี 11000",
    },
    {
      value: "บางไผ่ เมืองนนทบุรี นนทบุรี 11000",
      label: "บางไผ่ เมืองนนทบุรี นนทบุรี 11000",
    },
    {
      value: "สวนใหญ่ เมืองนนทบุรี นนทบุรี 11000",
      label: "สวนใหญ่ เมืองนนทบุรี นนทบุรี 11000",
    },
    {
      value: "ไทรม้า เมืองนนทบุรี นนทบุรี 11000",
      label: "ไทรม้า เมืองนนทบุรี นนทบุรี 11000",
    },
    {
      value: "ขุนศรี ไทรน้อย นนทบุรี 11150",
      label: "ขุนศรี ไทรน้อย นนทบุรี 11150",
    },
    {
      value: "คลองขวาง ไทรน้อย นนทบุรี 11150",
      label: "คลองขวาง ไทรน้อย นนทบุรี 11150",
    },
    {
      value: "ทวีวัฒนา ไทรน้อย นนทบุรี 11150",
      label: "ทวีวัฒนา ไทรน้อย นนทบุรี 11150",
    },
    {
      value: "ราษฎร์นิยม ไทรน้อย นนทบุรี 11150",
      label: "ราษฎร์นิยม ไทรน้อย นนทบุรี 11150",
    },
    {
      value: "หนองเพรางาย ไทรน้อย นนทบุรี 11150",
      label: "หนองเพรางาย ไทรน้อย นนทบุรี 11150",
    },
    {
      value: "ไทรน้อย ไทรน้อย นนทบุรี 11150",
      label: "ไทรน้อย ไทรน้อย นนทบุรี 11150",
    },
    {
      value: "ไทรใหญ่ ไทรน้อย นนทบุรี 11150",
      label: "ไทรใหญ่ ไทรน้อย นนทบุรี 11150",
    },
    {
      value: "คลองสอง คลองหลวง ปทุมธานี 12120",
      label: "คลองสอง คลองหลวง ปทุมธานี 12120",
    },
    {
      value: "คลองสาม คลองหลวง ปทุมธานี 12120",
      label: "คลองสาม คลองหลวง ปทุมธานี 12120",
    },
    {
      value: "คลองสี่ คลองหลวง ปทุมธานี 12120",
      label: "คลองสี่ คลองหลวง ปทุมธานี 12120",
    },
    {
      value: "คลองหก คลองหลวง ปทุมธานี 12120",
      label: "คลองหก คลองหลวง ปทุมธานี 12120",
    },
    {
      value: "คลองหนึ่ง คลองหลวง ปทุมธานี 12120",
      label: "คลองหนึ่ง คลองหลวง ปทุมธานี 12120",
    },
    {
      value: "คลองห้า คลองหลวง ปทุมธานี 12120",
      label: "คลองห้า คลองหลวง ปทุมธานี 12120",
    },
    {
      value: "คลองเจ็ด คลองหลวง ปทุมธานี 12120",
      label: "คลองเจ็ด คลองหลวง ปทุมธานี 12120",
    },
    {
      value: "บึงน้ำรักษ์ ธัญบุรี ปทุมธานี 12110",
      label: "บึงน้ำรักษ์ ธัญบุรี ปทุมธานี 12110",
    },
    {
      value: "บึงยี่โถ ธัญบุรี ปทุมธานี 12130",
      label: "บึงยี่โถ ธัญบุรี ปทุมธานี 12130",
    },
    {
      value: "บึงสนั่น ธัญบุรี ปทุมธานี 12110",
      label: "บึงสนั่น ธัญบุรี ปทุมธานี 12110",
    },
    {
      value: "ประชาธิปัตย์ ธัญบุรี ปทุมธานี 12130",
      label: "ประชาธิปัตย์ ธัญบุรี ปทุมธานี 12130",
    },
    {
      value: "รังสิต ธัญบุรี ปทุมธานี 12110",
      label: "รังสิต ธัญบุรี ปทุมธานี 12110",
    },
    {
      value: "ลำผักกูด ธัญบุรี ปทุมธานี 12110",
      label: "ลำผักกูด ธัญบุรี ปทุมธานี 12110",
    },
    {
      value: "คลองพระอุดม ลาดหลุมแก้ว ปทุมธานี 12140",
      label: "คลองพระอุดม ลาดหลุมแก้ว ปทุมธานี 12140",
    },
    {
      value: "คูขวาง ลาดหลุมแก้ว ปทุมธานี 12140",
      label: "คูขวาง ลาดหลุมแก้ว ปทุมธานี 12140",
    },
    {
      value: "คูบางหลวง ลาดหลุมแก้ว ปทุมธานี 12140",
      label: "คูบางหลวง ลาดหลุมแก้ว ปทุมธานี 12140",
    },
    {
      value: "บ่อเงิน ลาดหลุมแก้ว ปทุมธานี 12140",
      label: "บ่อเงิน ลาดหลุมแก้ว ปทุมธานี 12140",
    },
    {
      value: "ระแหง ลาดหลุมแก้ว ปทุมธานี 12140",
      label: "ระแหง ลาดหลุมแก้ว ปทุมธานี 12140",
    },
    {
      value: "ลาดหลุมแก้ว ลาดหลุมแก้ว ปทุมธานี 12140",
      label: "ลาดหลุมแก้ว ลาดหลุมแก้ว ปทุมธานี 12140",
    },
    {
      value: "หน้าไม้ ลาดหลุมแก้ว ปทุมธานี 12140",
      label: "หน้าไม้ ลาดหลุมแก้ว ปทุมธานี 12140",
    },
    {
      value: "คูคต ลำลูกกา ปทุมธานี 12130",
      label: "คูคต ลำลูกกา ปทุมธานี 12130",
    },
    {
      value: "บึงคอไห ลำลูกกา ปทุมธานี 12150",
      label: "บึงคอไห ลำลูกกา ปทุมธานี 12150",
    },
    {
      value: "บึงคำพร้อย ลำลูกกา ปทุมธานี 12150",
      label: "บึงคำพร้อย ลำลูกกา ปทุมธานี 12150",
    },
    {
      value: "บึงทองหลาง ลำลูกกา ปทุมธานี 12150",
      label: "บึงทองหลาง ลำลูกกา ปทุมธานี 12150",
    },
    {
      value: "พืชอุดม ลำลูกกา ปทุมธานี 12150",
      label: "พืชอุดม ลำลูกกา ปทุมธานี 12150",
    },
    {
      value: "ลาดสวาย ลำลูกกา ปทุมธานี 12150",
      label: "ลาดสวาย ลำลูกกา ปทุมธานี 12150",
    },
    {
      value: "ลำลูกกา ลำลูกกา ปทุมธานี 12150",
      label: "ลำลูกกา ลำลูกกา ปทุมธานี 12150",
    },
    {
      value: "ลำไทร ลำลูกกา ปทุมธานี 12150",
      label: "ลำไทร ลำลูกกา ปทุมธานี 12150",
    },
    {
      value: "กระแชง สามโคก ปทุมธานี 12160",
      label: "กระแชง สามโคก ปทุมธานี 12160",
    },
    {
      value: "คลองควาย สามโคก ปทุมธานี 12160",
      label: "คลองควาย สามโคก ปทุมธานี 12160",
    },
    {
      value: "ท้ายเกาะ สามโคก ปทุมธานี 12160",
      label: "ท้ายเกาะ สามโคก ปทุมธานี 12160",
    },
    {
      value: "บางกระบือ สามโคก ปทุมธานี 12160",
      label: "บางกระบือ สามโคก ปทุมธานี 12160",
    },
    {
      value: "บางเตย สามโคก ปทุมธานี 12160",
      label: "บางเตย สามโคก ปทุมธานี 12160",
    },
    {
      value: "บางโพธิ์เหนือ สามโคก ปทุมธานี 12160",
      label: "บางโพธิ์เหนือ สามโคก ปทุมธานี 12160",
    },
    {
      value: "บ้านงิ้ว สามโคก ปทุมธานี 12160",
      label: "บ้านงิ้ว สามโคก ปทุมธานี 12160",
    },
    {
      value: "บ้านปทุม สามโคก ปทุมธานี 12160",
      label: "บ้านปทุม สามโคก ปทุมธานี 12160",
    },
    {
      value: "สามโคก สามโคก ปทุมธานี 12160",
      label: "สามโคก สามโคก ปทุมธานี 12160",
    },
    {
      value: "เชียงรากน้อย สามโคก ปทุมธานี 12160",
      label: "เชียงรากน้อย สามโคก ปทุมธานี 12160",
    },
    {
      value: "เชียงรากใหญ่ สามโคก ปทุมธานี 12160",
      label: "เชียงรากใหญ่ สามโคก ปทุมธานี 12160",
    },
    {
      value: "นพรัตน์ หนองเสือ ปทุมธานี 12170",
      label: "นพรัตน์ หนองเสือ ปทุมธานี 12170",
    },
    {
      value: "บึงกาสาม หนองเสือ ปทุมธานี 12170",
      label: "บึงกาสาม หนองเสือ ปทุมธานี 12170",
    },
    {
      value: "บึงชำอ้อ หนองเสือ ปทุมธานี 12170",
      label: "บึงชำอ้อ หนองเสือ ปทุมธานี 12170",
    },
    {
      value: "บึงบอน หนองเสือ ปทุมธานี 12170",
      label: "บึงบอน หนองเสือ ปทุมธานี 12170",
    },
    {
      value: "บึงบา หนองเสือ ปทุมธานี 12170",
      label: "บึงบา หนองเสือ ปทุมธานี 12170",
    },
    {
      value: "ศาลาครุ หนองเสือ ปทุมธานี 12170",
      label: "ศาลาครุ หนองเสือ ปทุมธานี 12170",
    },
    {
      value: "หนองสามวัง หนองเสือ ปทุมธานี 12170",
      label: "หนองสามวัง หนองเสือ ปทุมธานี 12170",
    },
    {
      value: "บางกะดี เมืองปทุมธานี ปทุมธานี 12000",
      label: "บางกะดี เมืองปทุมธานี ปทุมธานี 12000",
    },
    {
      value: "บางขะแยง เมืองปทุมธานี ปทุมธานี 12000",
      label: "บางขะแยง เมืองปทุมธานี ปทุมธานี 12000",
    },
    {
      value: "บางคูวัด เมืองปทุมธานี ปทุมธานี 12000",
      label: "บางคูวัด เมืองปทุมธานี ปทุมธานี 12000",
    },
    {
      value: "บางปรอก เมืองปทุมธานี ปทุมธานี 12000",
      label: "บางปรอก เมืองปทุมธานี ปทุมธานี 12000",
    },
    {
      value: "บางพูด เมืองปทุมธานี ปทุมธานี 12000",
      label: "บางพูด เมืองปทุมธานี ปทุมธานี 12000",
    },
    {
      value: "บางพูน เมืองปทุมธานี ปทุมธานี 12000",
      label: "บางพูน เมืองปทุมธานี ปทุมธานี 12000",
    },
    {
      value: "บางหลวง เมืองปทุมธานี ปทุมธานี 12000",
      label: "บางหลวง เมืองปทุมธานี ปทุมธานี 12000",
    },
    {
      value: "บางเดื่อ เมืองปทุมธานี ปทุมธานี 12000",
      label: "บางเดื่อ เมืองปทุมธานี ปทุมธานี 12000",
    },
    {
      value: "บ้านกระแชง เมืองปทุมธานี ปทุมธานี 12000",
      label: "บ้านกระแชง เมืองปทุมธานี ปทุมธานี 12000",
    },
    {
      value: "บ้านกลาง เมืองปทุมธานี ปทุมธานี 12000",
      label: "บ้านกลาง เมืองปทุมธานี ปทุมธานี 12000",
    },
    {
      value: "บ้านฉาง เมืองปทุมธานี ปทุมธานี 12000",
      label: "บ้านฉาง เมืองปทุมธานี ปทุมธานี 12000",
    },
    {
      value: "บ้านใหม่ เมืองปทุมธานี ปทุมธานี 12000",
      label: "บ้านใหม่ เมืองปทุมธานี ปทุมธานี 12000",
    },
    {
      value: "สวนพริกไทย เมืองปทุมธานี ปทุมธานี 12000",
      label: "สวนพริกไทย เมืองปทุมธานี ปทุมธานี 12000",
    },
    {
      value: "หลักหก เมืองปทุมธานี ปทุมธานี 12000",
      label: "หลักหก เมืองปทุมธานี ปทุมธานี 12000",
    },
    {
      value: "จำปา ท่าเรือ พระนครศรีอยุธยา 13130",
      label: "จำปา ท่าเรือ พระนครศรีอยุธยา 13130",
    },
    {
      value: "ท่าหลวง ท่าเรือ พระนครศรีอยุธยา 18270",
      label: "ท่าหลวง ท่าเรือ พระนครศรีอยุธยา 18270",
    },
    {
      value: "ท่าเจ้าสนุก ท่าเรือ พระนครศรีอยุธยา 13130",
      label: "ท่าเจ้าสนุก ท่าเรือ พระนครศรีอยุธยา 13130",
    },
    {
      value: "ท่าเรือ ท่าเรือ พระนครศรีอยุธยา 13130",
      label: "ท่าเรือ ท่าเรือ พระนครศรีอยุธยา 13130",
    },
    {
      value: "บ้านร่อม ท่าเรือ พระนครศรีอยุธยา 13130",
      label: "บ้านร่อม ท่าเรือ พระนครศรีอยุธยา 13130",
    },
    {
      value: "ปากท่า ท่าเรือ พระนครศรีอยุธยา 13130",
      label: "ปากท่า ท่าเรือ พระนครศรีอยุธยา 13130",
    },
    {
      value: "วังแดง ท่าเรือ พระนครศรีอยุธยา 13130",
      label: "วังแดง ท่าเรือ พระนครศรีอยุธยา 13130",
    },
    {
      value: "ศาลาลอย ท่าเรือ พระนครศรีอยุธยา 13130",
      label: "ศาลาลอย ท่าเรือ พระนครศรีอยุธยา 13130",
    },
    {
      value: "หนองขนาก ท่าเรือ พระนครศรีอยุธยา 13130",
      label: "หนองขนาก ท่าเรือ พระนครศรีอยุธยา 13130",
    },
    {
      value: "โพธิ์เอน ท่าเรือ พระนครศรีอยุธยา 13130",
      label: "โพธิ์เอน ท่าเรือ พระนครศรีอยุธยา 13130",
    },
    {
      value: "คลองสะแก นครหลวง พระนครศรีอยุธยา 13260",
      label: "คลองสะแก นครหลวง พระนครศรีอยุธยา 13260",
    },
    {
      value: "ท่าช้าง นครหลวง พระนครศรีอยุธยา 13260",
      label: "ท่าช้าง นครหลวง พระนครศรีอยุธยา 13260",
    },
    {
      value: "นครหลวง นครหลวง พระนครศรีอยุธยา 13260",
      label: "นครหลวง นครหลวง พระนครศรีอยุธยา 13260",
    },
    {
      value: "บางพระครู นครหลวง พระนครศรีอยุธยา 13260",
      label: "บางพระครู นครหลวง พระนครศรีอยุธยา 13260",
    },
    {
      value: "บางระกำ นครหลวง พระนครศรีอยุธยา 13260",
      label: "บางระกำ นครหลวง พระนครศรีอยุธยา 13260",
    },
    {
      value: "บ่อโพง นครหลวง พระนครศรีอยุธยา 13260",
      label: "บ่อโพง นครหลวง พระนครศรีอยุธยา 13260",
    },
    {
      value: "บ้านชุ้ง นครหลวง พระนครศรีอยุธยา 13260",
      label: "บ้านชุ้ง นครหลวง พระนครศรีอยุธยา 13260",
    },
    {
      value: "ปากจั่น นครหลวง พระนครศรีอยุธยา 13260",
      label: "ปากจั่น นครหลวง พระนครศรีอยุธยา 13260",
    },
    {
      value: "พระนอน นครหลวง พระนครศรีอยุธยา 13260",
      label: "พระนอน นครหลวง พระนครศรีอยุธยา 13260",
    },
    {
      value: "สามไถ นครหลวง พระนครศรีอยุธยา 13260",
      label: "สามไถ นครหลวง พระนครศรีอยุธยา 13260",
    },
    {
      value: "หนองปลิง นครหลวง พระนครศรีอยุธยา 13260",
      label: "หนองปลิง นครหลวง พระนครศรีอยุธยา 13260",
    },
    {
      value: "แม่ลา นครหลวง พระนครศรีอยุธยา 13260",
      label: "แม่ลา นครหลวง พระนครศรีอยุธยา 13260",
    },
    {
      value: "บางซ้าย บางซ้าย พระนครศรีอยุธยา 13270",
      label: "บางซ้าย บางซ้าย พระนครศรีอยุธยา 13270",
    },
    {
      value: "ปลายกลัด บางซ้าย พระนครศรีอยุธยา 13270",
      label: "ปลายกลัด บางซ้าย พระนครศรีอยุธยา 13270",
    },
    {
      value: "วังพัฒนา บางซ้าย พระนครศรีอยุธยา 13270",
      label: "วังพัฒนา บางซ้าย พระนครศรีอยุธยา 13270",
    },
    {
      value: "เต่าเล่า บางซ้าย พระนครศรีอยุธยา 13270",
      label: "เต่าเล่า บางซ้าย พระนครศรีอยุธยา 13270",
    },
    {
      value: "เทพมงคล บางซ้าย พระนครศรีอยุธยา 13270",
      label: "เทพมงคล บางซ้าย พระนครศรีอยุธยา 13270",
    },
    {
      value: "แก้วฟ้า บางซ้าย พระนครศรีอยุธยา 13270",
      label: "แก้วฟ้า บางซ้าย พระนครศรีอยุธยา 13270",
    },
    {
      value: "กบเจา บางบาล พระนครศรีอยุธยา 13250",
      label: "กบเจา บางบาล พระนครศรีอยุธยา 13250",
    },
    {
      value: "ทางช้าง บางบาล พระนครศรีอยุธยา 13250",
      label: "ทางช้าง บางบาล พระนครศรีอยุธยา 13250",
    },
    {
      value: "น้ำเต้า บางบาล พระนครศรีอยุธยา 13250",
      label: "น้ำเต้า บางบาล พระนครศรีอยุธยา 13250",
    },
    {
      value: "บางชะนี บางบาล พระนครศรีอยุธยา 13250",
      label: "บางชะนี บางบาล พระนครศรีอยุธยา 13250",
    },
    {
      value: "บางบาล บางบาล พระนครศรีอยุธยา 13250",
      label: "บางบาล บางบาล พระนครศรีอยุธยา 13250",
    },
    {
      value: "บางหลวง บางบาล พระนครศรีอยุธยา 13250",
      label: "บางหลวง บางบาล พระนครศรีอยุธยา 13250",
    },
    {
      value: "บางหลวงโดด บางบาล พระนครศรีอยุธยา 13250",
      label: "บางหลวงโดด บางบาล พระนครศรีอยุธยา 13250",
    },
    {
      value: "บางหัก บางบาล พระนครศรีอยุธยา 13250",
      label: "บางหัก บางบาล พระนครศรีอยุธยา 13250",
    },
    {
      value: "บ้านกุ่ม บางบาล พระนครศรีอยุธยา 13250",
      label: "บ้านกุ่ม บางบาล พระนครศรีอยุธยา 13250",
    },
    {
      value: "บ้านคลัง บางบาล พระนครศรีอยุธยา 13250",
      label: "บ้านคลัง บางบาล พระนครศรีอยุธยา 13250",
    },
    {
      value: "พระขาว บางบาล พระนครศรีอยุธยา 13250",
      label: "พระขาว บางบาล พระนครศรีอยุธยา 13250",
    },
    {
      value: "มหาพราหมณ์ บางบาล พระนครศรีอยุธยา 13250",
      label: "มหาพราหมณ์ บางบาล พระนครศรีอยุธยา 13250",
    },
    {
      value: "วัดตะกู บางบาล พระนครศรีอยุธยา 13250",
      label: "วัดตะกู บางบาล พระนครศรีอยุธยา 13250",
    },
    {
      value: "วัดยม บางบาล พระนครศรีอยุธยา 13250",
      label: "วัดยม บางบาล พระนครศรีอยุธยา 13250",
    },
    {
      value: "สะพานไทย บางบาล พระนครศรีอยุธยา 13250",
      label: "สะพานไทย บางบาล พระนครศรีอยุธยา 13250",
    },
    {
      value: "ไทรน้อย บางบาล พระนครศรีอยุธยา 13250",
      label: "ไทรน้อย บางบาล พระนครศรีอยุธยา 13250",
    },
    {
      value: "ขยาย บางปะหัน พระนครศรีอยุธยา 13220",
      label: "ขยาย บางปะหัน พระนครศรีอยุธยา 13220",
    },
    {
      value: "ขวัญเมือง บางปะหัน พระนครศรีอยุธยา 13220",
      label: "ขวัญเมือง บางปะหัน พระนครศรีอยุธยา 13220",
    },
    {
      value: "ตานิม บางปะหัน พระนครศรีอยุธยา 13220",
      label: "ตานิม บางปะหัน พระนครศรีอยุธยา 13220",
    },
    {
      value: "ตาลเอน บางปะหัน พระนครศรีอยุธยา 13220",
      label: "ตาลเอน บางปะหัน พระนครศรีอยุธยา 13220",
    },
    {
      value: "ทับน้ำ บางปะหัน พระนครศรีอยุธยา 13220",
      label: "ทับน้ำ บางปะหัน พระนครศรีอยุธยา 13220",
    },
    {
      value: "ทางกลาง บางปะหัน พระนครศรีอยุธยา 13220",
      label: "ทางกลาง บางปะหัน พระนครศรีอยุธยา 13220",
    },
    {
      value: "บางนางร้า บางปะหัน พระนครศรีอยุธยา 13220",
      label: "บางนางร้า บางปะหัน พระนครศรีอยุธยา 13220",
    },
    {
      value: "บางปะหัน บางปะหัน พระนครศรีอยุธยา 13220",
      label: "บางปะหัน บางปะหัน พระนครศรีอยุธยา 13220",
    },
    {
      value: "บางเดื่อ บางปะหัน พระนครศรีอยุธยา 13220",
      label: "บางเดื่อ บางปะหัน พระนครศรีอยุธยา 13220",
    },
    {
      value: "บางเพลิง บางปะหัน พระนครศรีอยุธยา 13220",
      label: "บางเพลิง บางปะหัน พระนครศรีอยุธยา 13220",
    },
    {
      value: "บ้านขล้อ บางปะหัน พระนครศรีอยุธยา 13220",
      label: "บ้านขล้อ บางปะหัน พระนครศรีอยุธยา 13220",
    },
    {
      value: "บ้านม้า บางปะหัน พระนครศรีอยุธยา 13220",
      label: "บ้านม้า บางปะหัน พระนครศรีอยุธยา 13220",
    },
    {
      value: "บ้านลี่ บางปะหัน พระนครศรีอยุธยา 13220",
      label: "บ้านลี่ บางปะหัน พระนครศรีอยุธยา 13220",
    },
    {
      value: "พุทเลา บางปะหัน พระนครศรีอยุธยา 13220",
      label: "พุทเลา บางปะหัน พระนครศรีอยุธยา 13220",
    },
    {
      value: "หันสัง บางปะหัน พระนครศรีอยุธยา 13220",
      label: "หันสัง บางปะหัน พระนครศรีอยุธยา 13220",
    },
    {
      value: "เสาธง บางปะหัน พระนครศรีอยุธยา 13220",
      label: "เสาธง บางปะหัน พระนครศรีอยุธยา 13220",
    },
    {
      value: "โพธิ์สามต้น บางปะหัน พระนครศรีอยุธยา 13220",
      label: "โพธิ์สามต้น บางปะหัน พระนครศรีอยุธยา 13220",
    },
    {
      value: "ขนอนหลวง บางปะอิน พระนครศรีอยุธยา 13160",
      label: "ขนอนหลวง บางปะอิน พระนครศรีอยุธยา 13160",
    },
    {
      value: "คลองจิก บางปะอิน พระนครศรีอยุธยา 13160",
      label: "คลองจิก บางปะอิน พระนครศรีอยุธยา 13160",
    },
    {
      value: "คุ้งลาน บางปะอิน พระนครศรีอยุธยา 13160",
      label: "คุ้งลาน บางปะอิน พระนครศรีอยุธยา 13160",
    },
    {
      value: "ตลาดเกรียบ บางปะอิน พระนครศรีอยุธยา 13160",
      label: "ตลาดเกรียบ บางปะอิน พระนครศรีอยุธยา 13160",
    },
    {
      value: "ตลิ่งชัน บางปะอิน พระนครศรีอยุธยา 13160",
      label: "ตลิ่งชัน บางปะอิน พระนครศรีอยุธยา 13160",
    },
    {
      value: "บางกระสั้น บางปะอิน พระนครศรีอยุธยา 13160",
      label: "บางกระสั้น บางปะอิน พระนครศรีอยุธยา 13160",
    },
    {
      value: "บางประแดง บางปะอิน พระนครศรีอยุธยา 13160",
      label: "บางประแดง บางปะอิน พระนครศรีอยุธยา 13160",
    },
    {
      value: "บ้านกรด บางปะอิน พระนครศรีอยุธยา 13160",
      label: "บ้านกรด บางปะอิน พระนครศรีอยุธยา 13160",
    },
    {
      value: "บ้านพลับ บางปะอิน พระนครศรีอยุธยา 13160",
      label: "บ้านพลับ บางปะอิน พระนครศรีอยุธยา 13160",
    },
    {
      value: "บ้านสร้าง บางปะอิน พระนครศรีอยุธยา 13170",
      label: "บ้านสร้าง บางปะอิน พระนครศรีอยุธยา 13170",
    },
    {
      value: "บ้านหว้า บางปะอิน พระนครศรีอยุธยา 13160",
      label: "บ้านหว้า บางปะอิน พระนครศรีอยุธยา 13160",
    },
    {
      value: "บ้านเลน บางปะอิน พระนครศรีอยุธยา 13160",
      label: "บ้านเลน บางปะอิน พระนครศรีอยุธยา 13160",
    },
    {
      value: "บ้านแป้ง บางปะอิน พระนครศรีอยุธยา 13160",
      label: "บ้านแป้ง บางปะอิน พระนครศรีอยุธยา 13160",
    },
    {
      value: "บ้านโพ บางปะอิน พระนครศรีอยุธยา 13160",
      label: "บ้านโพ บางปะอิน พระนครศรีอยุธยา 13160",
    },
    {
      value: "วัดยม บางปะอิน พระนครศรีอยุธยา 13160",
      label: "วัดยม บางปะอิน พระนครศรีอยุธยา 13160",
    },
    {
      value: "สามเรือน บางปะอิน พระนครศรีอยุธยา 13160",
      label: "สามเรือน บางปะอิน พระนครศรีอยุธยา 13160",
    },
    {
      value: "เกาะเกิด บางปะอิน พระนครศรีอยุธยา 13160",
      label: "เกาะเกิด บางปะอิน พระนครศรีอยุธยา 13160",
    },
    {
      value: "เชียงรากน้อย บางปะอิน พระนครศรีอยุธยา 13180",
      label: "เชียงรากน้อย บางปะอิน พระนครศรีอยุธยา 13180",
    },
    {
      value: "กกแก้วบูรพา บางไทร พระนครศรีอยุธยา 13190",
      label: "กกแก้วบูรพา บางไทร พระนครศรีอยุธยา 13190",
    },
    {
      value: "กระแชง บางไทร พระนครศรีอยุธยา 13190",
      label: "กระแชง บางไทร พระนครศรีอยุธยา 13190",
    },
    {
      value: "ช่างเหล็ก บางไทร พระนครศรีอยุธยา 13190",
      label: "ช่างเหล็ก บางไทร พระนครศรีอยุธยา 13190",
    },
    {
      value: "ช้างน้อย บางไทร พระนครศรีอยุธยา 13190",
      label: "ช้างน้อย บางไทร พระนครศรีอยุธยา 13190",
    },
    {
      value: "ช้างใหญ่ บางไทร พระนครศรีอยุธยา 13290",
      label: "ช้างใหญ่ บางไทร พระนครศรีอยุธยา 13290",
    },
    {
      value: "บางพลี บางไทร พระนครศรีอยุธยา 13190",
      label: "บางพลี บางไทร พระนครศรีอยุธยา 13190",
    },
    {
      value: "บางยี่โท บางไทร พระนครศรีอยุธยา 13190",
      label: "บางยี่โท บางไทร พระนครศรีอยุธยา 13190",
    },
    {
      value: "บางไทร บางไทร พระนครศรีอยุธยา 13190",
      label: "บางไทร บางไทร พระนครศรีอยุธยา 13190",
    },
    {
      value: "บ้านกลึง บางไทร พระนครศรีอยุธยา 13190",
      label: "บ้านกลึง บางไทร พระนครศรีอยุธยา 13190",
    },
    {
      value: "บ้านม้า บางไทร พระนครศรีอยุธยา 13190",
      label: "บ้านม้า บางไทร พระนครศรีอยุธยา 13190",
    },
    {
      value: "บ้านเกาะ บางไทร พระนครศรีอยุธยา 13190",
      label: "บ้านเกาะ บางไทร พระนครศรีอยุธยา 13190",
    },
    {
      value: "บ้านแป้ง บางไทร พระนครศรีอยุธยา 13190",
      label: "บ้านแป้ง บางไทร พระนครศรีอยุธยา 13190",
    },
    {
      value: "ราชคราม บางไทร พระนครศรีอยุธยา 13290",
      label: "ราชคราม บางไทร พระนครศรีอยุธยา 13290",
    },
    {
      value: "สนามชัย บางไทร พระนครศรีอยุธยา 13190",
      label: "สนามชัย บางไทร พระนครศรีอยุธยา 13190",
    },
    {
      value: "หน้าไม้ บางไทร พระนครศรีอยุธยา 13190",
      label: "หน้าไม้ บางไทร พระนครศรีอยุธยา 13190",
    },
    {
      value: "ห่อหมก บางไทร พระนครศรีอยุธยา 13190",
      label: "ห่อหมก บางไทร พระนครศรีอยุธยา 13190",
    },
    {
      value: "เชียงรากน้อย บางไทร พระนครศรีอยุธยา 13290",
      label: "เชียงรากน้อย บางไทร พระนครศรีอยุธยา 13290",
    },
    {
      value: "แคตก บางไทร พระนครศรีอยุธยา 13190",
      label: "แคตก บางไทร พระนครศรีอยุธยา 13190",
    },
    {
      value: "แคออก บางไทร พระนครศรีอยุธยา 13190",
      label: "แคออก บางไทร พระนครศรีอยุธยา 13190",
    },
    {
      value: "โคกช้าง บางไทร พระนครศรีอยุธยา 13190",
      label: "โคกช้าง บางไทร พระนครศรีอยุธยา 13190",
    },
    {
      value: "โพแตง บางไทร พระนครศรีอยุธยา 13290",
      label: "โพแตง บางไทร พระนครศรีอยุธยา 13290",
    },
    {
      value: "ไผ่พระ บางไทร พระนครศรีอยุธยา 13190",
      label: "ไผ่พระ บางไทร พระนครศรีอยุธยา 13190",
    },
    {
      value: "ไม้ตรา บางไทร พระนครศรีอยุธยา 13190",
      label: "ไม้ตรา บางไทร พระนครศรีอยุธยา 13190",
    },
    {
      value: "คลองน้อย บ้านแพรก พระนครศรีอยุธยา 13240",
      label: "คลองน้อย บ้านแพรก พระนครศรีอยุธยา 13240",
    },
    {
      value: "บ้านแพรก บ้านแพรก พระนครศรีอยุธยา 13240",
      label: "บ้านแพรก บ้านแพรก พระนครศรีอยุธยา 13240",
    },
    {
      value: "บ้านใหม่ บ้านแพรก พระนครศรีอยุธยา 13240",
      label: "บ้านใหม่ บ้านแพรก พระนครศรีอยุธยา 13240",
    },
    {
      value: "สองห้อง บ้านแพรก พระนครศรีอยุธยา 13240",
      label: "สองห้อง บ้านแพรก พระนครศรีอยุธยา 13240",
    },
    {
      value: "สำพะเนียง บ้านแพรก พระนครศรีอยุธยา 13240",
      label: "สำพะเนียง บ้านแพรก พระนครศรีอยุธยา 13240",
    },
    {
      value: "กุฎี ผักไห่ พระนครศรีอยุธยา 13120",
      label: "กุฎี ผักไห่ พระนครศรีอยุธยา 13120",
    },
    {
      value: "จักราช ผักไห่ พระนครศรีอยุธยา 13280",
      label: "จักราช ผักไห่ พระนครศรีอยุธยา 13280",
    },
    {
      value: "ดอนลาน ผักไห่ พระนครศรีอยุธยา 13280",
      label: "ดอนลาน ผักไห่ พระนครศรีอยุธยา 13280",
    },
    {
      value: "ตาลาน ผักไห่ พระนครศรีอยุธยา 13120",
      label: "ตาลาน ผักไห่ พระนครศรีอยุธยา 13120",
    },
    {
      value: "ท่าดินแดง ผักไห่ พระนครศรีอยุธยา 13120",
      label: "ท่าดินแดง ผักไห่ พระนครศรีอยุธยา 13120",
    },
    {
      value: "นาคู ผักไห่ พระนครศรีอยุธยา 13280",
      label: "นาคู ผักไห่ พระนครศรีอยุธยา 13280",
    },
    {
      value: "บ้านแค ผักไห่ พระนครศรีอยุธยา 13120",
      label: "บ้านแค ผักไห่ พระนครศรีอยุธยา 13120",
    },
    {
      value: "บ้านใหญ่ ผักไห่ พระนครศรีอยุธยา 13120",
      label: "บ้านใหญ่ ผักไห่ พระนครศรีอยุธยา 13120",
    },
    {
      value: "ผักไห่ ผักไห่ พระนครศรีอยุธยา 13120",
      label: "ผักไห่ ผักไห่ พระนครศรีอยุธยา 13120",
    },
    {
      value: "ลาดชิด ผักไห่ พระนครศรีอยุธยา 13120",
      label: "ลาดชิด ผักไห่ พระนครศรีอยุธยา 13120",
    },
    {
      value: "ลาดน้ำเค็ม ผักไห่ พระนครศรีอยุธยา 13120",
      label: "ลาดน้ำเค็ม ผักไห่ พระนครศรีอยุธยา 13120",
    },
    {
      value: "ลำตะเคียน ผักไห่ พระนครศรีอยุธยา 13280",
      label: "ลำตะเคียน ผักไห่ พระนครศรีอยุธยา 13280",
    },
    {
      value: "หนองน้ำใหญ่ ผักไห่ พระนครศรีอยุธยา 13280",
      label: "หนองน้ำใหญ่ ผักไห่ พระนครศรีอยุธยา 13280",
    },
    {
      value: "หน้าโคก ผักไห่ พระนครศรีอยุธยา 13120",
      label: "หน้าโคก ผักไห่ พระนครศรีอยุธยา 13120",
    },
    {
      value: "อมฤต ผักไห่ พระนครศรีอยุธยา 13120",
      label: "อมฤต ผักไห่ พระนครศรีอยุธยา 13120",
    },
    {
      value: "โคกช้าง ผักไห่ พระนครศรีอยุธยา 13120",
      label: "โคกช้าง ผักไห่ พระนครศรีอยุธยา 13120",
    },
    {
      value: "กะมัง พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
      label: "กะมัง พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
    },
    {
      value: "คลองตะเคียน พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
      label: "คลองตะเคียน พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
    },
    {
      value: "คลองสระบัว พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
      label: "คลองสระบัว พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
    },
    {
      value: "คลองสวนพลู พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
      label: "คลองสวนพลู พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
    },
    {
      value: "ท่าวาสุกรี พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
      label: "ท่าวาสุกรี พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
    },
    {
      value: "บ้านป้อม พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
      label: "บ้านป้อม พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
    },
    {
      value: "บ้านรุน พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
      label: "บ้านรุน พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
    },
    {
      value: "บ้านเกาะ พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
      label: "บ้านเกาะ พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
    },
    {
      value: "บ้านใหม่ พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
      label: "บ้านใหม่ พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
    },
    {
      value: "ประตูชัย พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
      label: "ประตูชัย พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
    },
    {
      value: "ปากกราน พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
      label: "ปากกราน พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
    },
    {
      value: "ภูเขาทอง พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
      label: "ภูเขาทอง พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
    },
    {
      value: "ลุมพลี พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
      label: "ลุมพลี พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
    },
    {
      value: "วัดตูม พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
      label: "วัดตูม พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
    },
    {
      value: "สวนพริก พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
      label: "สวนพริก พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
    },
    {
      value: "สำเภาล่ม พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
      label: "สำเภาล่ม พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
    },
    {
      value: "หอรัตนไชย พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
      label: "หอรัตนไชย พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
    },
    {
      value: "หันตรา พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
      label: "หันตรา พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
    },
    {
      value: "หัวรอ พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
      label: "หัวรอ พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
    },
    {
      value: "เกาะเรียน พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
      label: "เกาะเรียน พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
    },
    {
      value: "ไผ่ลิง พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
      label: "ไผ่ลิง พระนครศรีอยุธยา พระนครศรีอยุธยา 13000",
    },
    {
      value: "กระจิว ภาชี พระนครศรีอยุธยา 13140",
      label: "กระจิว ภาชี พระนครศรีอยุธยา 13140",
    },
    {
      value: "ดอนหญ้านาง ภาชี พระนครศรีอยุธยา 13140",
      label: "ดอนหญ้านาง ภาชี พระนครศรีอยุธยา 13140",
    },
    {
      value: "พระแก้ว ภาชี พระนครศรีอยุธยา 13140",
      label: "พระแก้ว ภาชี พระนครศรีอยุธยา 13140",
    },
    {
      value: "ภาชี ภาชี พระนครศรีอยุธยา 13140",
      label: "ภาชี ภาชี พระนครศรีอยุธยา 13140",
    },
    {
      value: "ระโสม ภาชี พระนครศรีอยุธยา 13140",
      label: "ระโสม ภาชี พระนครศรีอยุธยา 13140",
    },
    {
      value: "หนองน้ำใส ภาชี พระนครศรีอยุธยา 13140",
      label: "หนองน้ำใส ภาชี พระนครศรีอยุธยา 13140",
    },
    {
      value: "โคกม่วง ภาชี พระนครศรีอยุธยา 13140",
      label: "โคกม่วง ภาชี พระนครศรีอยุธยา 13140",
    },
    {
      value: "ไผ่ล้อม ภาชี พระนครศรีอยุธยา 13140",
      label: "ไผ่ล้อม ภาชี พระนครศรีอยุธยา 13140",
    },
    {
      value: "กะทุ่ม มหาราช พระนครศรีอยุธยา 13150",
      label: "กะทุ่ม มหาราช พระนครศรีอยุธยา 13150",
    },
    {
      value: "ท่าตอ มหาราช พระนครศรีอยุธยา 13150",
      label: "ท่าตอ มหาราช พระนครศรีอยุธยา 13150",
    },
    {
      value: "น้ำเต้า มหาราช พระนครศรีอยุธยา 13150",
      label: "น้ำเต้า มหาราช พระนครศรีอยุธยา 13150",
    },
    {
      value: "บางนา มหาราช พระนครศรีอยุธยา 13150",
      label: "บางนา มหาราช พระนครศรีอยุธยา 13150",
    },
    {
      value: "บ้านขวาง มหาราช พระนครศรีอยุธยา 13150",
      label: "บ้านขวาง มหาราช พระนครศรีอยุธยา 13150",
    },
    {
      value: "บ้านนา มหาราช พระนครศรีอยุธยา 13150",
      label: "บ้านนา มหาราช พระนครศรีอยุธยา 13150",
    },
    {
      value: "บ้านใหม่ มหาราช พระนครศรีอยุธยา 13150",
      label: "บ้านใหม่ มหาราช พระนครศรีอยุธยา 13150",
    },
    {
      value: "พิตเพียน มหาราช พระนครศรีอยุธยา 13150",
      label: "พิตเพียน มหาราช พระนครศรีอยุธยา 13150",
    },
    {
      value: "มหาราช มหาราช พระนครศรีอยุธยา 13150",
      label: "มหาราช มหาราช พระนครศรีอยุธยา 13150",
    },
    {
      value: "หัวไผ่ มหาราช พระนครศรีอยุธยา 13150",
      label: "หัวไผ่ มหาราช พระนครศรีอยุธยา 13150",
    },
    {
      value: "เจ้าปลุก มหาราช พระนครศรีอยุธยา 13150",
      label: "เจ้าปลุก มหาราช พระนครศรีอยุธยา 13150",
    },
    {
      value: "โรงช้าง มหาราช พระนครศรีอยุธยา 13150",
      label: "โรงช้าง มหาราช พระนครศรีอยุธยา 13150",
    },
    {
      value: "คลองพระยาบันลือ ลาดบัวหลวง พระนครศรีอยุธยา 13230",
      label: "คลองพระยาบันลือ ลาดบัวหลวง พระนครศรีอยุธยา 13230",
    },
    {
      value: "คู้สลอด ลาดบัวหลวง พระนครศรีอยุธยา 13230",
      label: "คู้สลอด ลาดบัวหลวง พระนครศรีอยุธยา 13230",
    },
    {
      value: "พระยาบันลือ ลาดบัวหลวง พระนครศรีอยุธยา 13230",
      label: "พระยาบันลือ ลาดบัวหลวง พระนครศรีอยุธยา 13230",
    },
    {
      value: "ลาดบัวหลวง ลาดบัวหลวง พระนครศรีอยุธยา 13230",
      label: "ลาดบัวหลวง ลาดบัวหลวง พระนครศรีอยุธยา 13230",
    },
    {
      value: "สามเมือง ลาดบัวหลวง พระนครศรีอยุธยา 13230",
      label: "สามเมือง ลาดบัวหลวง พระนครศรีอยุธยา 13230",
    },
    {
      value: "สิงหนาท ลาดบัวหลวง พระนครศรีอยุธยา 13230",
      label: "สิงหนาท ลาดบัวหลวง พระนครศรีอยุธยา 13230",
    },
    {
      value: "หลักชัย ลาดบัวหลวง พระนครศรีอยุธยา 13230",
      label: "หลักชัย ลาดบัวหลวง พระนครศรีอยุธยา 13230",
    },
    {
      value: "ข้าวงาม วังน้อย พระนครศรีอยุธยา 13170",
      label: "ข้าวงาม วังน้อย พระนครศรีอยุธยา 13170",
    },
    {
      value: "ชะแมบ วังน้อย พระนครศรีอยุธยา 13170",
      label: "ชะแมบ วังน้อย พระนครศรีอยุธยา 13170",
    },
    {
      value: "บ่อตาโล่ วังน้อย พระนครศรีอยุธยา 13170",
      label: "บ่อตาโล่ วังน้อย พระนครศรีอยุธยา 13170",
    },
    {
      value: "พยอม วังน้อย พระนครศรีอยุธยา 13170",
      label: "พยอม วังน้อย พระนครศรีอยุธยา 13170",
    },
    {
      value: "ลำตาเสา วังน้อย พระนครศรีอยุธยา 13170",
      label: "ลำตาเสา วังน้อย พระนครศรีอยุธยา 13170",
    },
    {
      value: "ลำไทร วังน้อย พระนครศรีอยุธยา 13170",
      label: "ลำไทร วังน้อย พระนครศรีอยุธยา 13170",
    },
    {
      value: "วังจุฬา วังน้อย พระนครศรีอยุธยา 13170",
      label: "วังจุฬา วังน้อย พระนครศรีอยุธยา 13170",
    },
    {
      value: "วังน้อย วังน้อย พระนครศรีอยุธยา 13170",
      label: "วังน้อย วังน้อย พระนครศรีอยุธยา 13170",
    },
    {
      value: "สนับทึบ วังน้อย พระนครศรีอยุธยา 13170",
      label: "สนับทึบ วังน้อย พระนครศรีอยุธยา 13170",
    },
    {
      value: "หันตะเภา วังน้อย พระนครศรีอยุธยา 13170",
      label: "หันตะเภา วังน้อย พระนครศรีอยุธยา 13170",
    },
    {
      value: "ข้าวเม่า อุทัย พระนครศรีอยุธยา 13210",
      label: "ข้าวเม่า อุทัย พระนครศรีอยุธยา 13210",
    },
    {
      value: "คานหาม อุทัย พระนครศรีอยุธยา 13210",
      label: "คานหาม อุทัย พระนครศรีอยุธยา 13210",
    },
    {
      value: "ธนู อุทัย พระนครศรีอยุธยา 13210",
      label: "ธนู อุทัย พระนครศรีอยุธยา 13210",
    },
    {
      value: "บ้านช้าง อุทัย พระนครศรีอยุธยา 13210",
      label: "บ้านช้าง อุทัย พระนครศรีอยุธยา 13210",
    },
    {
      value: "บ้านหีบ อุทัย พระนครศรีอยุธยา 13210",
      label: "บ้านหีบ อุทัย พระนครศรีอยุธยา 13210",
    },
    {
      value: "สามบัณฑิต อุทัย พระนครศรีอยุธยา 13210",
      label: "สามบัณฑิต อุทัย พระนครศรีอยุธยา 13210",
    },
    {
      value: "หนองน้ำส้ม อุทัย พระนครศรีอยุธยา 13210",
      label: "หนองน้ำส้ม อุทัย พระนครศรีอยุธยา 13210",
    },
    {
      value: "หนองไม้ซุง อุทัย พระนครศรีอยุธยา 13210",
      label: "หนองไม้ซุง อุทัย พระนครศรีอยุธยา 13210",
    },
    {
      value: "อุทัย อุทัย พระนครศรีอยุธยา 13210",
      label: "อุทัย อุทัย พระนครศรีอยุธยา 13210",
    },
    {
      value: "เสนา อุทัย พระนครศรีอยุธยา 13210",
      label: "เสนา อุทัย พระนครศรีอยุธยา 13210",
    },
    {
      value: "โพสาวหาญ อุทัย พระนครศรีอยุธยา 13210",
      label: "โพสาวหาญ อุทัย พระนครศรีอยุธยา 13210",
    },
    {
      value: "ชายนา เสนา พระนครศรีอยุธยา 13110",
      label: "ชายนา เสนา พระนครศรีอยุธยา 13110",
    },
    {
      value: "ดอนทอง เสนา พระนครศรีอยุธยา 13110",
      label: "ดอนทอง เสนา พระนครศรีอยุธยา 13110",
    },
    {
      value: "บางนมโค เสนา พระนครศรีอยุธยา 13110",
      label: "บางนมโค เสนา พระนครศรีอยุธยา 13110",
    },
    {
      value: "บ้านกระทุ่ม เสนา พระนครศรีอยุธยา 13110",
      label: "บ้านกระทุ่ม เสนา พระนครศรีอยุธยา 13110",
    },
    {
      value: "บ้านหลวง เสนา พระนครศรีอยุธยา 13110",
      label: "บ้านหลวง เสนา พระนครศรีอยุธยา 13110",
    },
    {
      value: "บ้านแถว เสนา พระนครศรีอยุธยา 13110",
      label: "บ้านแถว เสนา พระนครศรีอยุธยา 13110",
    },
    {
      value: "บ้านแพน เสนา พระนครศรีอยุธยา 13110",
      label: "บ้านแพน เสนา พระนครศรีอยุธยา 13110",
    },
    {
      value: "บ้านโพธิ์ เสนา พระนครศรีอยุธยา 13110",
      label: "บ้านโพธิ์ เสนา พระนครศรีอยุธยา 13110",
    },
    {
      value: "มารวิชัย เสนา พระนครศรีอยุธยา 13110",
      label: "มารวิชัย เสนา พระนครศรีอยุธยา 13110",
    },
    {
      value: "รางจรเข้ เสนา พระนครศรีอยุธยา 13110",
      label: "รางจรเข้ เสนา พระนครศรีอยุธยา 13110",
    },
    {
      value: "ลาดงา เสนา พระนครศรีอยุธยา 13110",
      label: "ลาดงา เสนา พระนครศรีอยุธยา 13110",
    },
    {
      value: "สามกอ เสนา พระนครศรีอยุธยา 13110",
      label: "สามกอ เสนา พระนครศรีอยุธยา 13110",
    },
    {
      value: "สามตุ่ม เสนา พระนครศรีอยุธยา 13110",
      label: "สามตุ่ม เสนา พระนครศรีอยุธยา 13110",
    },
    {
      value: "หัวเวียง เสนา พระนครศรีอยุธยา 13110",
      label: "หัวเวียง เสนา พระนครศรีอยุธยา 13110",
    },
    {
      value: "เจ้าเจ็ด เสนา พระนครศรีอยุธยา 13110",
      label: "เจ้าเจ็ด เสนา พระนครศรีอยุธยา 13110",
    },
    {
      value: "เจ้าเสด็จ เสนา พระนครศรีอยุธยา 13110",
      label: "เจ้าเสด็จ เสนา พระนครศรีอยุธยา 13110",
    },
    {
      value: "เสนา เสนา พระนครศรีอยุธยา 13110",
      label: "เสนา เสนา พระนครศรีอยุธยา 13110",
    },
    {
      value: "วังงิ้ว ดงเจริญ พิจิตร 66210",
      label: "วังงิ้ว ดงเจริญ พิจิตร 66210",
    },
    {
      value: "วังงิ้วใต้ ดงเจริญ พิจิตร 66210",
      label: "วังงิ้วใต้ ดงเจริญ พิจิตร 66210",
    },
    {
      value: "สำนักขุนเณร ดงเจริญ พิจิตร 66210",
      label: "สำนักขุนเณร ดงเจริญ พิจิตร 66210",
    },
    {
      value: "ห้วยพุก ดงเจริญ พิจิตร 66210",
      label: "ห้วยพุก ดงเจริญ พิจิตร 66210",
    },
    {
      value: "ห้วยร่วม ดงเจริญ พิจิตร 66210",
      label: "ห้วยร่วม ดงเจริญ พิจิตร 66210",
    },
    {
      value: "คลองคูณ ตะพานหิน พิจิตร 66110",
      label: "คลองคูณ ตะพานหิน พิจิตร 66110",
    },
    {
      value: "งิ้วราย ตะพานหิน พิจิตร 66110",
      label: "งิ้วราย ตะพานหิน พิจิตร 66110",
    },
    {
      value: "ดงตะขบ ตะพานหิน พิจิตร 66110",
      label: "ดงตะขบ ตะพานหิน พิจิตร 66110",
    },
    {
      value: "ตะพานหิน ตะพานหิน พิจิตร 66110",
      label: "ตะพานหิน ตะพานหิน พิจิตร 66110",
    },
    {
      value: "ทับหมัน ตะพานหิน พิจิตร 66110",
      label: "ทับหมัน ตะพานหิน พิจิตร 66110",
    },
    {
      value: "ทุ่งโพธิ์ ตะพานหิน พิจิตร 66150",
      label: "ทุ่งโพธิ์ ตะพานหิน พิจิตร 66150",
    },
    {
      value: "วังสำโรง ตะพานหิน พิจิตร 66110",
      label: "วังสำโรง ตะพานหิน พิจิตร 66110",
    },
    {
      value: "วังหลุม ตะพานหิน พิจิตร 66150",
      label: "วังหลุม ตะพานหิน พิจิตร 66150",
    },
    {
      value: "วังหว้า ตะพานหิน พิจิตร 66110",
      label: "วังหว้า ตะพานหิน พิจิตร 66110",
    },
    {
      value: "หนองพยอม ตะพานหิน พิจิตร 66110",
      label: "หนองพยอม ตะพานหิน พิจิตร 66110",
    },
    {
      value: "ห้วยเกตุ ตะพานหิน พิจิตร 66110",
      label: "ห้วยเกตุ ตะพานหิน พิจิตร 66110",
    },
    {
      value: "ไทรโรงโขน ตะพานหิน พิจิตร 66110",
      label: "ไทรโรงโขน ตะพานหิน พิจิตร 66110",
    },
    {
      value: "ไผ่หลวง ตะพานหิน พิจิตร 66110",
      label: "ไผ่หลวง ตะพานหิน พิจิตร 66110",
    },
    {
      value: "ทับคล้อ ทับคล้อ พิจิตร 66150",
      label: "ทับคล้อ ทับคล้อ พิจิตร 66150",
    },
    {
      value: "ท้ายทุ่ง ทับคล้อ พิจิตร 66150",
      label: "ท้ายทุ่ง ทับคล้อ พิจิตร 66150",
    },
    {
      value: "เขาทราย ทับคล้อ พิจิตร 66230",
      label: "เขาทราย ทับคล้อ พิจิตร 66230",
    },
    {
      value: "เขาเจ็ดลูก ทับคล้อ พิจิตร 66230",
      label: "เขาเจ็ดลูก ทับคล้อ พิจิตร 66230",
    },
    {
      value: "บางมูลนาก บางมูลนาก พิจิตร 66120",
      label: "บางมูลนาก บางมูลนาก พิจิตร 66120",
    },
    {
      value: "บางไผ่ บางมูลนาก พิจิตร 66120",
      label: "บางไผ่ บางมูลนาก พิจิตร 66120",
    },
    {
      value: "ภูมิ บางมูลนาก พิจิตร 66120",
      label: "ภูมิ บางมูลนาก พิจิตร 66120",
    },
    {
      value: "ลำประดา บางมูลนาก พิจิตร 66120",
      label: "ลำประดา บางมูลนาก พิจิตร 66120",
    },
    {
      value: "วังกรด บางมูลนาก พิจิตร 66120",
      label: "วังกรด บางมูลนาก พิจิตร 66120",
    },
    {
      value: "วังตะกู บางมูลนาก พิจิตร 66210",
      label: "วังตะกู บางมูลนาก พิจิตร 66210",
    },
    {
      value: "วังสำโรง บางมูลนาก พิจิตร 66120",
      label: "วังสำโรง บางมูลนาก พิจิตร 66120",
    },
    {
      value: "หอไกร บางมูลนาก พิจิตร 66120",
      label: "หอไกร บางมูลนาก พิจิตร 66120",
    },
    {
      value: "ห้วยเขน บางมูลนาก พิจิตร 66120",
      label: "ห้วยเขน บางมูลนาก พิจิตร 66120",
    },
    {
      value: "เนินมะกอก บางมูลนาก พิจิตร 66120",
      label: "เนินมะกอก บางมูลนาก พิจิตร 66120",
    },
    {
      value: "บางลาย บึงนาราง พิจิตร 66130",
      label: "บางลาย บึงนาราง พิจิตร 66130",
    },
    {
      value: "บึงนาราง บึงนาราง พิจิตร 66130",
      label: "บึงนาราง บึงนาราง พิจิตร 66130",
    },
    {
      value: "ห้วยแก้ว บึงนาราง พิจิตร 66130",
      label: "ห้วยแก้ว บึงนาราง พิจิตร 66130",
    },
    {
      value: "แหลมรัง บึงนาราง พิจิตร 66130",
      label: "แหลมรัง บึงนาราง พิจิตร 66130",
    },
    {
      value: "โพธิ์ไทรงาม บึงนาราง พิจิตร 66130",
      label: "โพธิ์ไทรงาม บึงนาราง พิจิตร 66130",
    },
    {
      value: "บึงบัว วชิรบารมี พิจิตร 66140",
      label: "บึงบัว วชิรบารมี พิจิตร 66140",
    },
    {
      value: "บ้านนา วชิรบารมี พิจิตร 66140",
      label: "บ้านนา วชิรบารมี พิจิตร 66140",
    },
    {
      value: "วังโมกข์ วชิรบารมี พิจิตร 66140",
      label: "วังโมกข์ วชิรบารมี พิจิตร 66140",
    },
    {
      value: "หนองหลุม วชิรบารมี พิจิตร 66220",
      label: "หนองหลุม วชิรบารมี พิจิตร 66220",
    },
    {
      value: "วังทรายพูน วังทรายพูน พิจิตร 66180",
      label: "วังทรายพูน วังทรายพูน พิจิตร 66180",
    },
    {
      value: "หนองปลาไหล วังทรายพูน พิจิตร 66180",
      label: "หนองปลาไหล วังทรายพูน พิจิตร 66180",
    },
    {
      value: "หนองปล้อง วังทรายพูน พิจิตร 66180",
      label: "หนองปล้อง วังทรายพูน พิจิตร 66180",
    },
    {
      value: "หนองพระ วังทรายพูน พิจิตร 66180",
      label: "หนองพระ วังทรายพูน พิจิตร 66180",
    },
    {
      value: "คลองทราย สากเหล็ก พิจิตร 66160",
      label: "คลองทราย สากเหล็ก พิจิตร 66160",
    },
    {
      value: "ท่าเยี่ยม สากเหล็ก พิจิตร 66160",
      label: "ท่าเยี่ยม สากเหล็ก พิจิตร 66160",
    },
    {
      value: "วังทับไทร สากเหล็ก พิจิตร 66160",
      label: "วังทับไทร สากเหล็ก พิจิตร 66160",
    },
    {
      value: "สากเหล็ก สากเหล็ก พิจิตร 66160",
      label: "สากเหล็ก สากเหล็ก พิจิตร 66160",
    },
    {
      value: "หนองหญ้าไทร สากเหล็ก พิจิตร 66160",
      label: "หนองหญ้าไทร สากเหล็ก พิจิตร 66160",
    },
    {
      value: "กำแพงดิน สามง่าม พิจิตร 66220",
      label: "กำแพงดิน สามง่าม พิจิตร 66220",
    },
    { value: "รังนก สามง่าม พิจิตร 66140", label: "รังนก สามง่าม พิจิตร 66140" },
    {
      value: "สามง่าม สามง่าม พิจิตร 66140",
      label: "สามง่าม สามง่าม พิจิตร 66140",
    },
    {
      value: "หนองโสน สามง่าม พิจิตร 66140",
      label: "หนองโสน สามง่าม พิจิตร 66140",
    },
    {
      value: "เนินปอ สามง่าม พิจิตร 66140",
      label: "เนินปอ สามง่าม พิจิตร 66140",
    },
    {
      value: "คลองคะเชนทร์ เมืองพิจิตร พิจิตร 66000",
      label: "คลองคะเชนทร์ เมืองพิจิตร พิจิตร 66000",
    },
    {
      value: "ฆะมัง เมืองพิจิตร พิจิตร 66000",
      label: "ฆะมัง เมืองพิจิตร พิจิตร 66000",
    },
    {
      value: "ดงกลาง เมืองพิจิตร พิจิตร 66170",
      label: "ดงกลาง เมืองพิจิตร พิจิตร 66170",
    },
    {
      value: "ดงป่าคำ เมืองพิจิตร พิจิตร 66170",
      label: "ดงป่าคำ เมืองพิจิตร พิจิตร 66170",
    },
    {
      value: "ท่าหลวง เมืองพิจิตร พิจิตร 66000",
      label: "ท่าหลวง เมืองพิจิตร พิจิตร 66000",
    },
    {
      value: "ท่าฬ่อ เมืองพิจิตร พิจิตร 66000",
      label: "ท่าฬ่อ เมืองพิจิตร พิจิตร 66000",
    },
    {
      value: "บ้านบุ่ง เมืองพิจิตร พิจิตร 66000",
      label: "บ้านบุ่ง เมืองพิจิตร พิจิตร 66000",
    },
    {
      value: "ปากทาง เมืองพิจิตร พิจิตร 66000",
      label: "ปากทาง เมืองพิจิตร พิจิตร 66000",
    },
    {
      value: "ป่ามะคาบ เมืองพิจิตร พิจิตร 66000",
      label: "ป่ามะคาบ เมืองพิจิตร พิจิตร 66000",
    },
    {
      value: "ย่านยาว เมืองพิจิตร พิจิตร 66000",
      label: "ย่านยาว เมืองพิจิตร พิจิตร 66000",
    },
    {
      value: "สายคำโห้ เมืองพิจิตร พิจิตร 66000",
      label: "สายคำโห้ เมืองพิจิตร พิจิตร 66000",
    },
    {
      value: "หัวดง เมืองพิจิตร พิจิตร 66170",
      label: "หัวดง เมืองพิจิตร พิจิตร 66170",
    },
    {
      value: "เมืองเก่า เมืองพิจิตร พิจิตร 66000",
      label: "เมืองเก่า เมืองพิจิตร พิจิตร 66000",
    },
    {
      value: "โรงช้าง เมืองพิจิตร พิจิตร 66000",
      label: "โรงช้าง เมืองพิจิตร พิจิตร 66000",
    },
    {
      value: "ในเมือง เมืองพิจิตร พิจิตร 66000",
      label: "ในเมือง เมืองพิจิตร พิจิตร 66000",
    },
    {
      value: "ไผ่ขวาง เมืองพิจิตร พิจิตร 66000",
      label: "ไผ่ขวาง เมืองพิจิตร พิจิตร 66000",
    },
    { value: "ทะนง โพทะเล พิจิตร 66130", label: "ทะนง โพทะเล พิจิตร 66130" },
    {
      value: "ทุ่งน้อย โพทะเล พิจิตร 66130",
      label: "ทุ่งน้อย โพทะเล พิจิตร 66130",
    },
    {
      value: "ท่าขมิ้น โพทะเล พิจิตร 66130",
      label: "ท่าขมิ้น โพทะเล พิจิตร 66130",
    },
    {
      value: "ท่านั่ง โพทะเล พิจิตร 66130",
      label: "ท่านั่ง โพทะเล พิจิตร 66130",
    },
    { value: "ท่าบัว โพทะเล พิจิตร 66130", label: "ท่าบัว โพทะเล พิจิตร 66130" },
    { value: "ท่าเสา โพทะเล พิจิตร 66130", label: "ท่าเสา โพทะเล พิจิตร 66130" },
    {
      value: "ท้ายน้ำ โพทะเล พิจิตร 66130",
      label: "ท้ายน้ำ โพทะเล พิจิตร 66130",
    },
    {
      value: "บางคลาน โพทะเล พิจิตร 66130",
      label: "บางคลาน โพทะเล พิจิตร 66130",
    },
    {
      value: "บ้านน้อย โพทะเล พิจิตร 66130",
      label: "บ้านน้อย โพทะเล พิจิตร 66130",
    },
    {
      value: "วัดขวาง โพทะเล พิจิตร 66130",
      label: "วัดขวาง โพทะเล พิจิตร 66130",
    },
    { value: "โพทะเล โพทะเล พิจิตร 66130", label: "โพทะเล โพทะเล พิจิตร 66130" },
    {
      value: "ดงเสือเหลือง โพธิ์ประทับช้าง พิจิตร 66190",
      label: "ดงเสือเหลือง โพธิ์ประทับช้าง พิจิตร 66190",
    },
    {
      value: "ทุ่งใหญ่ โพธิ์ประทับช้าง พิจิตร 66190",
      label: "ทุ่งใหญ่ โพธิ์ประทับช้าง พิจิตร 66190",
    },
    {
      value: "วังจิก โพธิ์ประทับช้าง พิจิตร 66190",
      label: "วังจิก โพธิ์ประทับช้าง พิจิตร 66190",
    },
    {
      value: "เนินสว่าง โพธิ์ประทับช้าง พิจิตร 66190",
      label: "เนินสว่าง โพธิ์ประทับช้าง พิจิตร 66190",
    },
    {
      value: "โพธิ์ประทับช้าง โพธิ์ประทับช้าง พิจิตร 66190",
      label: "โพธิ์ประทับช้าง โพธิ์ประทับช้าง พิจิตร 66190",
    },
    {
      value: "ไผ่ท่าโพ โพธิ์ประทับช้าง พิจิตร 66190",
      label: "ไผ่ท่าโพ โพธิ์ประทับช้าง พิจิตร 66190",
    },
    {
      value: "ไผ่รอบ โพธิ์ประทับช้าง พิจิตร 66190",
      label: "ไผ่รอบ โพธิ์ประทับช้าง พิจิตร 66190",
    },
    {
      value: "ชาติตระการ ชาติตระการ พิษณุโลก 65170",
      label: "ชาติตระการ ชาติตระการ พิษณุโลก 65170",
    },
    {
      value: "ท่าสะแก ชาติตระการ พิษณุโลก 65170",
      label: "ท่าสะแก ชาติตระการ พิษณุโลก 65170",
    },
    {
      value: "บ่อภาค ชาติตระการ พิษณุโลก 65170",
      label: "บ่อภาค ชาติตระการ พิษณุโลก 65170",
    },
    {
      value: "บ้านดง ชาติตระการ พิษณุโลก 65170",
      label: "บ้านดง ชาติตระการ พิษณุโลก 65170",
    },
    {
      value: "ป่าแดง ชาติตระการ พิษณุโลก 65170",
      label: "ป่าแดง ชาติตระการ พิษณุโลก 65170",
    },
    {
      value: "สวนเมี่ยง ชาติตระการ พิษณุโลก 65170",
      label: "สวนเมี่ยง ชาติตระการ พิษณุโลก 65170",
    },
    {
      value: "นครชุม นครไทย พิษณุโลก 65120",
      label: "นครชุม นครไทย พิษณุโลก 65120",
    },
    {
      value: "นครไทย นครไทย พิษณุโลก 65120",
      label: "นครไทย นครไทย พิษณุโลก 65120",
    },
    {
      value: "นาบัว นครไทย พิษณุโลก 65120",
      label: "นาบัว นครไทย พิษณุโลก 65120",
    },
    {
      value: "น้ำกุ่ม นครไทย พิษณุโลก 65120",
      label: "น้ำกุ่ม นครไทย พิษณุโลก 65120",
    },
    {
      value: "บ่อโพธิ์ นครไทย พิษณุโลก 65120",
      label: "บ่อโพธิ์ นครไทย พิษณุโลก 65120",
    },
    {
      value: "บ้านพร้าว นครไทย พิษณุโลก 65120",
      label: "บ้านพร้าว นครไทย พิษณุโลก 65120",
    },
    {
      value: "บ้านแยง นครไทย พิษณุโลก 65120",
      label: "บ้านแยง นครไทย พิษณุโลก 65120",
    },
    {
      value: "ยางโกลน นครไทย พิษณุโลก 65120",
      label: "ยางโกลน นครไทย พิษณุโลก 65120",
    },
    {
      value: "หนองกะท้าว นครไทย พิษณุโลก 65120",
      label: "หนองกะท้าว นครไทย พิษณุโลก 65120",
    },
    {
      value: "ห้วยเฮี้ย นครไทย พิษณุโลก 65120",
      label: "ห้วยเฮี้ย นครไทย พิษณุโลก 65120",
    },
    {
      value: "เนินเพิ่ม นครไทย พิษณุโลก 65120",
      label: "เนินเพิ่ม นครไทย พิษณุโลก 65120",
    },
    {
      value: "ท่าตาล บางกระทุ่ม พิษณุโลก 65110",
      label: "ท่าตาล บางกระทุ่ม พิษณุโลก 65110",
    },
    {
      value: "นครป่าหมาก บางกระทุ่ม พิษณุโลก 65110",
      label: "นครป่าหมาก บางกระทุ่ม พิษณุโลก 65110",
    },
    {
      value: "บางกระทุ่ม บางกระทุ่ม พิษณุโลก 65110",
      label: "บางกระทุ่ม บางกระทุ่ม พิษณุโลก 65110",
    },
    {
      value: "บ้านไร่ บางกระทุ่ม พิษณุโลก 65110",
      label: "บ้านไร่ บางกระทุ่ม พิษณุโลก 65110",
    },
    {
      value: "วัดตายม บางกระทุ่ม พิษณุโลก 65210",
      label: "วัดตายม บางกระทุ่ม พิษณุโลก 65210",
    },
    {
      value: "สนามคลี บางกระทุ่ม พิษณุโลก 65110",
      label: "สนามคลี บางกระทุ่ม พิษณุโลก 65110",
    },
    {
      value: "เนินกุ่ม บางกระทุ่ม พิษณุโลก 65210",
      label: "เนินกุ่ม บางกระทุ่ม พิษณุโลก 65210",
    },
    {
      value: "โคกสลุด บางกระทุ่ม พิษณุโลก 65110",
      label: "โคกสลุด บางกระทุ่ม พิษณุโลก 65110",
    },
    {
      value: "ไผ่ล้อม บางกระทุ่ม พิษณุโลก 65110",
      label: "ไผ่ล้อม บางกระทุ่ม พิษณุโลก 65110",
    },
    {
      value: "คุยม่วง บางระกำ พิษณุโลก 65240",
      label: "คุยม่วง บางระกำ พิษณุโลก 65240",
    },
    {
      value: "ชุมแสงสงคราม บางระกำ พิษณุโลก 65240",
      label: "ชุมแสงสงคราม บางระกำ พิษณุโลก 65240",
    },
    {
      value: "ท่านางงาม บางระกำ พิษณุโลก 65140",
      label: "ท่านางงาม บางระกำ พิษณุโลก 65140",
    },
    {
      value: "นิคมพัฒนา บางระกำ พิษณุโลก 65140",
      label: "นิคมพัฒนา บางระกำ พิษณุโลก 65140",
    },
    {
      value: "บางระกำ บางระกำ พิษณุโลก 65140",
      label: "บางระกำ บางระกำ พิษณุโลก 65140",
    },
    {
      value: "บึงกอก บางระกำ พิษณุโลก 65140",
      label: "บึงกอก บางระกำ พิษณุโลก 65140",
    },
    {
      value: "บ่อทอง บางระกำ พิษณุโลก 65140",
      label: "บ่อทอง บางระกำ พิษณุโลก 65140",
    },
    {
      value: "ปลักแรด บางระกำ พิษณุโลก 65140",
      label: "ปลักแรด บางระกำ พิษณุโลก 65140",
    },
    {
      value: "พันเสา บางระกำ พิษณุโลก 65140",
      label: "พันเสา บางระกำ พิษณุโลก 65140",
    },
    {
      value: "วังอิทก บางระกำ พิษณุโลก 65140",
      label: "วังอิทก บางระกำ พิษณุโลก 65140",
    },
    {
      value: "หนองกุลา บางระกำ พิษณุโลก 65140",
      label: "หนองกุลา บางระกำ พิษณุโลก 65140",
    },
    {
      value: "ดงประคำ พรหมพิราม พิษณุโลก 65180",
      label: "ดงประคำ พรหมพิราม พิษณุโลก 65180",
    },
    {
      value: "ตลุกเทียม พรหมพิราม พิษณุโลก 65180",
      label: "ตลุกเทียม พรหมพิราม พิษณุโลก 65180",
    },
    {
      value: "ทับยายเชียง พรหมพิราม พิษณุโลก 65150",
      label: "ทับยายเชียง พรหมพิราม พิษณุโลก 65150",
    },
    {
      value: "ท่าช้าง พรหมพิราม พิษณุโลก 65150",
      label: "ท่าช้าง พรหมพิราม พิษณุโลก 65150",
    },
    {
      value: "พรหมพิราม พรหมพิราม พิษณุโลก 65150",
      label: "พรหมพิราม พรหมพิราม พิษณุโลก 65150",
    },
    {
      value: "มะตูม พรหมพิราม พิษณุโลก 65150",
      label: "มะตูม พรหมพิราม พิษณุโลก 65150",
    },
    {
      value: "มะต้อง พรหมพิราม พิษณุโลก 65180",
      label: "มะต้อง พรหมพิราม พิษณุโลก 65180",
    },
    {
      value: "วงฆ้อง พรหมพิราม พิษณุโลก 65180",
      label: "วงฆ้อง พรหมพิราม พิษณุโลก 65180",
    },
    {
      value: "วังวน พรหมพิราม พิษณุโลก 65150",
      label: "วังวน พรหมพิราม พิษณุโลก 65150",
    },
    {
      value: "ศรีภิรมย์ พรหมพิราม พิษณุโลก 65180",
      label: "ศรีภิรมย์ พรหมพิราม พิษณุโลก 65180",
    },
    {
      value: "หนองแขม พรหมพิราม พิษณุโลก 65150",
      label: "หนองแขม พรหมพิราม พิษณุโลก 65150",
    },
    {
      value: "หอกลอง พรหมพิราม พิษณุโลก 65150",
      label: "หอกลอง พรหมพิราม พิษณุโลก 65150",
    },
    {
      value: "ชัยนาม วังทอง พิษณุโลก 65130",
      label: "ชัยนาม วังทอง พิษณุโลก 65130",
    },
    {
      value: "ดินทอง วังทอง พิษณุโลก 65130",
      label: "ดินทอง วังทอง พิษณุโลก 65130",
    },
    {
      value: "ท่าหมื่นราม วังทอง พิษณุโลก 65130",
      label: "ท่าหมื่นราม วังทอง พิษณุโลก 65130",
    },
    {
      value: "บ้านกลาง วังทอง พิษณุโลก 65220",
      label: "บ้านกลาง วังทอง พิษณุโลก 65220",
    },
    {
      value: "พันชาลี วังทอง พิษณุโลก 65130",
      label: "พันชาลี วังทอง พิษณุโลก 65130",
    },
    {
      value: "วังทอง วังทอง พิษณุโลก 65130",
      label: "วังทอง วังทอง พิษณุโลก 65130",
    },
    {
      value: "วังนกแอ่น วังทอง พิษณุโลก 65130",
      label: "วังนกแอ่น วังทอง พิษณุโลก 65130",
    },
    {
      value: "วังพิกุล วังทอง พิษณุโลก 65130",
      label: "วังพิกุล วังทอง พิษณุโลก 65130",
    },
    {
      value: "หนองพระ วังทอง พิษณุโลก 65130",
      label: "หนองพระ วังทอง พิษณุโลก 65130",
    },
    {
      value: "แก่งโสภา วังทอง พิษณุโลก 65220",
      label: "แก่งโสภา วังทอง พิษณุโลก 65220",
    },
    {
      value: "แม่ระกา วังทอง พิษณุโลก 65130",
      label: "แม่ระกา วังทอง พิษณุโลก 65130",
    },
    {
      value: "คันโช้ง วัดโบสถ์ พิษณุโลก 65160",
      label: "คันโช้ง วัดโบสถ์ พิษณุโลก 65160",
    },
    {
      value: "ท่างาม วัดโบสถ์ พิษณุโลก 65160",
      label: "ท่างาม วัดโบสถ์ พิษณุโลก 65160",
    },
    {
      value: "ท้อแท้ วัดโบสถ์ พิษณุโลก 65160",
      label: "ท้อแท้ วัดโบสถ์ พิษณุโลก 65160",
    },
    {
      value: "บ้านยาง วัดโบสถ์ พิษณุโลก 65160",
      label: "บ้านยาง วัดโบสถ์ พิษณุโลก 65160",
    },
    {
      value: "วัดโบสถ์ วัดโบสถ์ พิษณุโลก 65160",
      label: "วัดโบสถ์ วัดโบสถ์ พิษณุโลก 65160",
    },
    {
      value: "หินลาด วัดโบสถ์ พิษณุโลก 65160",
      label: "หินลาด วัดโบสถ์ พิษณุโลก 65160",
    },
    {
      value: "ชมพู เนินมะปราง พิษณุโลก 65190",
      label: "ชมพู เนินมะปราง พิษณุโลก 65190",
    },
    {
      value: "บ้านน้อยซุ้มขี้เหล็ก เนินมะปราง พิษณุโลก 65190",
      label: "บ้านน้อยซุ้มขี้เหล็ก เนินมะปราง พิษณุโลก 65190",
    },
    {
      value: "บ้านมุง เนินมะปราง พิษณุโลก 65190",
      label: "บ้านมุง เนินมะปราง พิษณุโลก 65190",
    },
    {
      value: "วังยาง เนินมะปราง พิษณุโลก 65190",
      label: "วังยาง เนินมะปราง พิษณุโลก 65190",
    },
    {
      value: "วังโพรง เนินมะปราง พิษณุโลก 65190",
      label: "วังโพรง เนินมะปราง พิษณุโลก 65190",
    },
    {
      value: "เนินมะปราง เนินมะปราง พิษณุโลก 65190",
      label: "เนินมะปราง เนินมะปราง พิษณุโลก 65190",
    },
    {
      value: "ไทรย้อย เนินมะปราง พิษณุโลก 65190",
      label: "ไทรย้อย เนินมะปราง พิษณุโลก 65190",
    },
    {
      value: "งิ้วงาม เมืองพิษณุโลก พิษณุโลก 65230",
      label: "งิ้วงาม เมืองพิษณุโลก พิษณุโลก 65230",
    },
    {
      value: "จอมทอง เมืองพิษณุโลก พิษณุโลก 65000",
      label: "จอมทอง เมืองพิษณุโลก พิษณุโลก 65000",
    },
    {
      value: "ดอนทอง เมืองพิษณุโลก พิษณุโลก 65000",
      label: "ดอนทอง เมืองพิษณุโลก พิษณุโลก 65000",
    },
    {
      value: "ท่าทอง เมืองพิษณุโลก พิษณุโลก 65000",
      label: "ท่าทอง เมืองพิษณุโลก พิษณุโลก 65000",
    },
    {
      value: "ท่าโพธิ์ เมืองพิษณุโลก พิษณุโลก 65000",
      label: "ท่าโพธิ์ เมืองพิษณุโลก พิษณุโลก 65000",
    },
    {
      value: "บึงพระ เมืองพิษณุโลก พิษณุโลก 65000",
      label: "บึงพระ เมืองพิษณุโลก พิษณุโลก 65000",
    },
    {
      value: "บ้านกร่าง เมืองพิษณุโลก พิษณุโลก 65000",
      label: "บ้านกร่าง เมืองพิษณุโลก พิษณุโลก 65000",
    },
    {
      value: "บ้านคลอง เมืองพิษณุโลก พิษณุโลก 65000",
      label: "บ้านคลอง เมืองพิษณุโลก พิษณุโลก 65000",
    },
    {
      value: "บ้านป่า เมืองพิษณุโลก พิษณุโลก 65000",
      label: "บ้านป่า เมืองพิษณุโลก พิษณุโลก 65000",
    },
    {
      value: "ปากโทก เมืองพิษณุโลก พิษณุโลก 65000",
      label: "ปากโทก เมืองพิษณุโลก พิษณุโลก 65000",
    },
    {
      value: "พลายชุมพล เมืองพิษณุโลก พิษณุโลก 65000",
      label: "พลายชุมพล เมืองพิษณุโลก พิษณุโลก 65000",
    },
    {
      value: "มะขามสูง เมืองพิษณุโลก พิษณุโลก 65000",
      label: "มะขามสูง เมืองพิษณุโลก พิษณุโลก 65000",
    },
    {
      value: "วังน้ำคู้ เมืองพิษณุโลก พิษณุโลก 65230",
      label: "วังน้ำคู้ เมืองพิษณุโลก พิษณุโลก 65230",
    },
    {
      value: "วัดจันทร์ เมืองพิษณุโลก พิษณุโลก 65000",
      label: "วัดจันทร์ เมืองพิษณุโลก พิษณุโลก 65000",
    },
    {
      value: "วัดพริก เมืองพิษณุโลก พิษณุโลก 65230",
      label: "วัดพริก เมืองพิษณุโลก พิษณุโลก 65230",
    },
    {
      value: "สมอแข เมืองพิษณุโลก พิษณุโลก 65000",
      label: "สมอแข เมืองพิษณุโลก พิษณุโลก 65000",
    },
    {
      value: "หัวรอ เมืองพิษณุโลก พิษณุโลก 65000",
      label: "หัวรอ เมืองพิษณุโลก พิษณุโลก 65000",
    },
    {
      value: "อรัญญิก เมืองพิษณุโลก พิษณุโลก 65000",
      label: "อรัญญิก เมืองพิษณุโลก พิษณุโลก 65000",
    },
    {
      value: "ในเมือง เมืองพิษณุโลก พิษณุโลก 65000",
      label: "ในเมือง เมืองพิษณุโลก พิษณุโลก 65000",
    },
    {
      value: "ไผ่ขอดอน เมืองพิษณุโลก พิษณุโลก 65000",
      label: "ไผ่ขอดอน เมืองพิษณุโลก พิษณุโลก 65000",
    },
    {
      value: "ชนแดน ชนแดน เพชรบูรณ์ 67150",
      label: "ชนแดน ชนแดน เพชรบูรณ์ 67150",
    },
    {
      value: "ซับพุทรา ชนแดน เพชรบูรณ์ 67150",
      label: "ซับพุทรา ชนแดน เพชรบูรณ์ 67150",
    },
    {
      value: "ดงขุย ชนแดน เพชรบูรณ์ 67190",
      label: "ดงขุย ชนแดน เพชรบูรณ์ 67190",
    },
    {
      value: "ตะกุดไร ชนแดน เพชรบูรณ์ 67190",
      label: "ตะกุดไร ชนแดน เพชรบูรณ์ 67190",
    },
    {
      value: "ท่าข้าม ชนแดน เพชรบูรณ์ 67150",
      label: "ท่าข้าม ชนแดน เพชรบูรณ์ 67150",
    },
    {
      value: "บ้านกล้วย ชนแดน เพชรบูรณ์ 67190",
      label: "บ้านกล้วย ชนแดน เพชรบูรณ์ 67190",
    },
    {
      value: "พุทธบาท ชนแดน เพชรบูรณ์ 67150",
      label: "พุทธบาท ชนแดน เพชรบูรณ์ 67150",
    },
    {
      value: "ลาดแค ชนแดน เพชรบูรณ์ 67150",
      label: "ลาดแค ชนแดน เพชรบูรณ์ 67150",
    },
    {
      value: "ศาลาลาย ชนแดน เพชรบูรณ์ 67150",
      label: "ศาลาลาย ชนแดน เพชรบูรณ์ 67150",
    },
    {
      value: "น้ำหนาว น้ำหนาว เพชรบูรณ์ 67260",
      label: "น้ำหนาว น้ำหนาว เพชรบูรณ์ 67260",
    },
    {
      value: "วังกวาง น้ำหนาว เพชรบูรณ์ 67260",
      label: "วังกวาง น้ำหนาว เพชรบูรณ์ 67260",
    },
    {
      value: "หลักด่าน น้ำหนาว เพชรบูรณ์ 67260",
      label: "หลักด่าน น้ำหนาว เพชรบูรณ์ 67260",
    },
    {
      value: "โคกมน น้ำหนาว เพชรบูรณ์ 67260",
      label: "โคกมน น้ำหนาว เพชรบูรณ์ 67260",
    },
    {
      value: "กันจุ บึงสามพัน เพชรบูรณ์ 67160",
      label: "กันจุ บึงสามพัน เพชรบูรณ์ 67160",
    },
    {
      value: "ซับสมอทอด บึงสามพัน เพชรบูรณ์ 67160",
      label: "ซับสมอทอด บึงสามพัน เพชรบูรณ์ 67160",
    },
    {
      value: "ซับไม้แดง บึงสามพัน เพชรบูรณ์ 67160",
      label: "ซับไม้แดง บึงสามพัน เพชรบูรณ์ 67160",
    },
    {
      value: "บึงสามพัน บึงสามพัน เพชรบูรณ์ 67160",
      label: "บึงสามพัน บึงสามพัน เพชรบูรณ์ 67160",
    },
    {
      value: "พญาวัง บึงสามพัน เพชรบูรณ์ 67160",
      label: "พญาวัง บึงสามพัน เพชรบูรณ์ 67160",
    },
    {
      value: "วังพิกุล บึงสามพัน เพชรบูรณ์ 67230",
      label: "วังพิกุล บึงสามพัน เพชรบูรณ์ 67230",
    },
    {
      value: "ศรีมงคล บึงสามพัน เพชรบูรณ์ 67160",
      label: "ศรีมงคล บึงสามพัน เพชรบูรณ์ 67160",
    },
    {
      value: "สระแก้ว บึงสามพัน เพชรบูรณ์ 67160",
      label: "สระแก้ว บึงสามพัน เพชรบูรณ์ 67160",
    },
    {
      value: "หนองแจง บึงสามพัน เพชรบูรณ์ 67160",
      label: "หนองแจง บึงสามพัน เพชรบูรณ์ 67160",
    },
    {
      value: "ซับเปิบ วังโป่ง เพชรบูรณ์ 67240",
      label: "ซับเปิบ วังโป่ง เพชรบูรณ์ 67240",
    },
    {
      value: "ท้ายดง วังโป่ง เพชรบูรณ์ 67240",
      label: "ท้ายดง วังโป่ง เพชรบูรณ์ 67240",
    },
    {
      value: "วังศาล วังโป่ง เพชรบูรณ์ 67240",
      label: "วังศาล วังโป่ง เพชรบูรณ์ 67240",
    },
    {
      value: "วังหิน วังโป่ง เพชรบูรณ์ 67240",
      label: "วังหิน วังโป่ง เพชรบูรณ์ 67240",
    },
    {
      value: "วังโป่ง วังโป่ง เพชรบูรณ์ 67240",
      label: "วังโป่ง วังโป่ง เพชรบูรณ์ 67240",
    },
    {
      value: "ซับน้อย วิเชียรบุรี เพชรบูรณ์ 67180",
      label: "ซับน้อย วิเชียรบุรี เพชรบูรณ์ 67180",
    },
    {
      value: "ซับสมบูรณ์ วิเชียรบุรี เพชรบูรณ์ 67180",
      label: "ซับสมบูรณ์ วิเชียรบุรี เพชรบูรณ์ 67180",
    },
    {
      value: "ท่าโรง วิเชียรบุรี เพชรบูรณ์ 67130",
      label: "ท่าโรง วิเชียรบุรี เพชรบูรณ์ 67130",
    },
    {
      value: "น้ำร้อน วิเชียรบุรี เพชรบูรณ์ 67130",
      label: "น้ำร้อน วิเชียรบุรี เพชรบูรณ์ 67130",
    },
    {
      value: "บึงกระจับ วิเชียรบุรี เพชรบูรณ์ 67130",
      label: "บึงกระจับ วิเชียรบุรี เพชรบูรณ์ 67130",
    },
    {
      value: "บ่อรัง วิเชียรบุรี เพชรบูรณ์ 67130",
      label: "บ่อรัง วิเชียรบุรี เพชรบูรณ์ 67130",
    },
    {
      value: "พุขาม วิเชียรบุรี เพชรบูรณ์ 67180",
      label: "พุขาม วิเชียรบุรี เพชรบูรณ์ 67180",
    },
    {
      value: "พุเตย วิเชียรบุรี เพชรบูรณ์ 67180",
      label: "พุเตย วิเชียรบุรี เพชรบูรณ์ 67180",
    },
    {
      value: "ภูน้ำหยด วิเชียรบุรี เพชรบูรณ์ 67180",
      label: "ภูน้ำหยด วิเชียรบุรี เพชรบูรณ์ 67180",
    },
    {
      value: "ยางสาว วิเชียรบุรี เพชรบูรณ์ 67130",
      label: "ยางสาว วิเชียรบุรี เพชรบูรณ์ 67130",
    },
    {
      value: "วังใหญ่ วิเชียรบุรี เพชรบูรณ์ 67180",
      label: "วังใหญ่ วิเชียรบุรี เพชรบูรณ์ 67180",
    },
    {
      value: "สระประดู่ วิเชียรบุรี เพชรบูรณ์ 67130",
      label: "สระประดู่ วิเชียรบุรี เพชรบูรณ์ 67130",
    },
    {
      value: "สามแยก วิเชียรบุรี เพชรบูรณ์ 67130",
      label: "สามแยก วิเชียรบุรี เพชรบูรณ์ 67130",
    },
    {
      value: "โคกปรง วิเชียรบุรี เพชรบูรณ์ 67130",
      label: "โคกปรง วิเชียรบุรี เพชรบูรณ์ 67130",
    },
    {
      value: "คลองกระจัง ศรีเทพ เพชรบูรณ์ 67170",
      label: "คลองกระจัง ศรีเทพ เพชรบูรณ์ 67170",
    },
    {
      value: "นาสนุ่น ศรีเทพ เพชรบูรณ์ 67170",
      label: "นาสนุ่น ศรีเทพ เพชรบูรณ์ 67170",
    },
    {
      value: "ประดู่งาม ศรีเทพ เพชรบูรณ์ 67170",
      label: "ประดู่งาม ศรีเทพ เพชรบูรณ์ 67170",
    },
    {
      value: "ศรีเทพ ศรีเทพ เพชรบูรณ์ 67170",
      label: "ศรีเทพ ศรีเทพ เพชรบูรณ์ 67170",
    },
    {
      value: "สระกรวด ศรีเทพ เพชรบูรณ์ 67170",
      label: "สระกรวด ศรีเทพ เพชรบูรณ์ 67170",
    },
    {
      value: "หนองย่างทอย ศรีเทพ เพชรบูรณ์ 67170",
      label: "หนองย่างทอย ศรีเทพ เพชรบูรณ์ 67170",
    },
    {
      value: "โคกสะอาด ศรีเทพ เพชรบูรณ์ 67170",
      label: "โคกสะอาด ศรีเทพ เพชรบูรณ์ 67170",
    },
    {
      value: "กองทูล หนองไผ่ เพชรบูรณ์ 67140",
      label: "กองทูล หนองไผ่ เพชรบูรณ์ 67140",
    },
    {
      value: "ท่าด้วง หนองไผ่ เพชรบูรณ์ 67140",
      label: "ท่าด้วง หนองไผ่ เพชรบูรณ์ 67140",
    },
    {
      value: "ท่าแดง หนองไผ่ เพชรบูรณ์ 67140",
      label: "ท่าแดง หนองไผ่ เพชรบูรณ์ 67140",
    },
    {
      value: "นาเฉลียง หนองไผ่ เพชรบูรณ์ 67220",
      label: "นาเฉลียง หนองไผ่ เพชรบูรณ์ 67220",
    },
    {
      value: "บัววัฒนา หนองไผ่ เพชรบูรณ์ 67140",
      label: "บัววัฒนา หนองไผ่ เพชรบูรณ์ 67140",
    },
    {
      value: "บ่อไทย หนองไผ่ เพชรบูรณ์ 67140",
      label: "บ่อไทย หนองไผ่ เพชรบูรณ์ 67140",
    },
    {
      value: "บ้านโภชน์ หนองไผ่ เพชรบูรณ์ 67140",
      label: "บ้านโภชน์ หนองไผ่ เพชรบูรณ์ 67140",
    },
    {
      value: "ยางงาม หนองไผ่ เพชรบูรณ์ 67220",
      label: "ยางงาม หนองไผ่ เพชรบูรณ์ 67220",
    },
    {
      value: "วังท่าดี หนองไผ่ เพชรบูรณ์ 67140",
      label: "วังท่าดี หนองไผ่ เพชรบูรณ์ 67140",
    },
    {
      value: "วังโบสถ์ หนองไผ่ เพชรบูรณ์ 67140",
      label: "วังโบสถ์ หนองไผ่ เพชรบูรณ์ 67140",
    },
    {
      value: "หนองไผ่ หนองไผ่ เพชรบูรณ์ 67140",
      label: "หนองไผ่ หนองไผ่ เพชรบูรณ์ 67140",
    },
    {
      value: "ห้วยโป่ง หนองไผ่ เพชรบูรณ์ 67220",
      label: "ห้วยโป่ง หนองไผ่ เพชรบูรณ์ 67220",
    },
    {
      value: "เพชรละคร หนองไผ่ เพชรบูรณ์ 67140",
      label: "เพชรละคร หนองไผ่ เพชรบูรณ์ 67140",
    },
    {
      value: "ช้างตะลูด หล่มสัก เพชรบูรณ์ 67110",
      label: "ช้างตะลูด หล่มสัก เพชรบูรณ์ 67110",
    },
    {
      value: "ตาลเดี่ยว หล่มสัก เพชรบูรณ์ 67110",
      label: "ตาลเดี่ยว หล่มสัก เพชรบูรณ์ 67110",
    },
    {
      value: "ท่าอิบุญ หล่มสัก เพชรบูรณ์ 67110",
      label: "ท่าอิบุญ หล่มสัก เพชรบูรณ์ 67110",
    },
    {
      value: "น้ำก้อ หล่มสัก เพชรบูรณ์ 67110",
      label: "น้ำก้อ หล่มสัก เพชรบูรณ์ 67110",
    },
    {
      value: "น้ำชุน หล่มสัก เพชรบูรณ์ 67110",
      label: "น้ำชุน หล่มสัก เพชรบูรณ์ 67110",
    },
    {
      value: "น้ำเฮี้ย หล่มสัก เพชรบูรณ์ 67110",
      label: "น้ำเฮี้ย หล่มสัก เพชรบูรณ์ 67110",
    },
    {
      value: "บุ่งคล้า หล่มสัก เพชรบูรณ์ 67110",
      label: "บุ่งคล้า หล่มสัก เพชรบูรณ์ 67110",
    },
    {
      value: "บุ่งน้ำเต้า หล่มสัก เพชรบูรณ์ 67110",
      label: "บุ่งน้ำเต้า หล่มสัก เพชรบูรณ์ 67110",
    },
    {
      value: "บ้านกลาง หล่มสัก เพชรบูรณ์ 67110",
      label: "บ้านกลาง หล่มสัก เพชรบูรณ์ 67110",
    },
    {
      value: "บ้านติ้ว หล่มสัก เพชรบูรณ์ 67110",
      label: "บ้านติ้ว หล่มสัก เพชรบูรณ์ 67110",
    },
    {
      value: "บ้านหวาย หล่มสัก เพชรบูรณ์ 67110",
      label: "บ้านหวาย หล่มสัก เพชรบูรณ์ 67110",
    },
    {
      value: "บ้านโสก หล่มสัก เพชรบูรณ์ 67110",
      label: "บ้านโสก หล่มสัก เพชรบูรณ์ 67110",
    },
    {
      value: "บ้านไร่ หล่มสัก เพชรบูรณ์ 67110",
      label: "บ้านไร่ หล่มสัก เพชรบูรณ์ 67110",
    },
    {
      value: "ปากช่อง หล่มสัก เพชรบูรณ์ 67110",
      label: "ปากช่อง หล่มสัก เพชรบูรณ์ 67110",
    },
    {
      value: "ปากดุก หล่มสัก เพชรบูรณ์ 67110",
      label: "ปากดุก หล่มสัก เพชรบูรณ์ 67110",
    },
    {
      value: "ฝายนาแซง หล่มสัก เพชรบูรณ์ 67110",
      label: "ฝายนาแซง หล่มสัก เพชรบูรณ์ 67110",
    },
    {
      value: "ลานบ่า หล่มสัก เพชรบูรณ์ 67110",
      label: "ลานบ่า หล่มสัก เพชรบูรณ์ 67110",
    },
    {
      value: "วัดป่า หล่มสัก เพชรบูรณ์ 67110",
      label: "วัดป่า หล่มสัก เพชรบูรณ์ 67110",
    },
    {
      value: "สักหลง หล่มสัก เพชรบูรณ์ 67110",
      label: "สักหลง หล่มสัก เพชรบูรณ์ 67110",
    },
    {
      value: "หนองสว่าง หล่มสัก เพชรบูรณ์ 67110",
      label: "หนองสว่าง หล่มสัก เพชรบูรณ์ 67110",
    },
    {
      value: "หนองไขว่ หล่มสัก เพชรบูรณ์ 67110",
      label: "หนองไขว่ หล่มสัก เพชรบูรณ์ 67110",
    },
    {
      value: "หล่มสัก หล่มสัก เพชรบูรณ์ 67110",
      label: "หล่มสัก หล่มสัก เพชรบูรณ์ 67110",
    },
    {
      value: "ห้วยไร่ หล่มสัก เพชรบูรณ์ 67110",
      label: "ห้วยไร่ หล่มสัก เพชรบูรณ์ 67110",
    },
    {
      value: "ตาดกลอย หล่มเก่า เพชรบูรณ์ 67120",
      label: "ตาดกลอย หล่มเก่า เพชรบูรณ์ 67120",
    },
    {
      value: "นาซำ หล่มเก่า เพชรบูรณ์ 67120",
      label: "นาซำ หล่มเก่า เพชรบูรณ์ 67120",
    },
    {
      value: "นาเกาะ หล่มเก่า เพชรบูรณ์ 67120",
      label: "นาเกาะ หล่มเก่า เพชรบูรณ์ 67120",
    },
    {
      value: "นาแซง หล่มเก่า เพชรบูรณ์ 67120",
      label: "นาแซง หล่มเก่า เพชรบูรณ์ 67120",
    },
    {
      value: "บ้านเนิน หล่มเก่า เพชรบูรณ์ 67120",
      label: "บ้านเนิน หล่มเก่า เพชรบูรณ์ 67120",
    },
    {
      value: "วังบาล หล่มเก่า เพชรบูรณ์ 67120",
      label: "วังบาล หล่มเก่า เพชรบูรณ์ 67120",
    },
    {
      value: "ศิลา หล่มเก่า เพชรบูรณ์ 67120",
      label: "ศิลา หล่มเก่า เพชรบูรณ์ 67120",
    },
    {
      value: "หล่มเก่า หล่มเก่า เพชรบูรณ์ 67120",
      label: "หล่มเก่า หล่มเก่า เพชรบูรณ์ 67120",
    },
    {
      value: "หินฮาว หล่มเก่า เพชรบูรณ์ 67120",
      label: "หินฮาว หล่มเก่า เพชรบูรณ์ 67120",
    },
    {
      value: "ทุ่งสมอ เขาค้อ เพชรบูรณ์ 67270",
      label: "ทุ่งสมอ เขาค้อ เพชรบูรณ์ 67270",
    },
    {
      value: "ริมสีม่วง เขาค้อ เพชรบูรณ์ 67270",
      label: "ริมสีม่วง เขาค้อ เพชรบูรณ์ 67270",
    },
    {
      value: "สะเดาะพง เขาค้อ เพชรบูรณ์ 67270",
      label: "สะเดาะพง เขาค้อ เพชรบูรณ์ 67270",
    },
    {
      value: "หนองแม่นา เขาค้อ เพชรบูรณ์ 67270",
      label: "หนองแม่นา เขาค้อ เพชรบูรณ์ 67270",
    },
    {
      value: "เขาค้อ เขาค้อ เพชรบูรณ์ 67270",
      label: "เขาค้อ เขาค้อ เพชรบูรณ์ 67270",
    },
    {
      value: "เข็กน้อย เขาค้อ เพชรบูรณ์ 67280",
      label: "เข็กน้อย เขาค้อ เพชรบูรณ์ 67280",
    },
    {
      value: "แคมป์สน เขาค้อ เพชรบูรณ์ 67280",
      label: "แคมป์สน เขาค้อ เพชรบูรณ์ 67280",
    },
    {
      value: "ชอนไพร เมืองเพชรบูรณ์ เพชรบูรณ์ 67000",
      label: "ชอนไพร เมืองเพชรบูรณ์ เพชรบูรณ์ 67000",
    },
    {
      value: "ดงมูลเหล็ก เมืองเพชรบูรณ์ เพชรบูรณ์ 67000",
      label: "ดงมูลเหล็ก เมืองเพชรบูรณ์ เพชรบูรณ์ 67000",
    },
    {
      value: "ตะเบาะ เมืองเพชรบูรณ์ เพชรบูรณ์ 67000",
      label: "ตะเบาะ เมืองเพชรบูรณ์ เพชรบูรณ์ 67000",
    },
    {
      value: "ท่าพล เมืองเพชรบูรณ์ เพชรบูรณ์ 67250",
      label: "ท่าพล เมืองเพชรบูรณ์ เพชรบูรณ์ 67250",
    },
    {
      value: "นางั่ว เมืองเพชรบูรณ์ เพชรบูรณ์ 67000",
      label: "นางั่ว เมืองเพชรบูรณ์ เพชรบูรณ์ 67000",
    },
    {
      value: "นาป่า เมืองเพชรบูรณ์ เพชรบูรณ์ 67000",
      label: "นาป่า เมืองเพชรบูรณ์ เพชรบูรณ์ 67000",
    },
    {
      value: "นายม เมืองเพชรบูรณ์ เพชรบูรณ์ 67210",
      label: "นายม เมืองเพชรบูรณ์ เพชรบูรณ์ 67210",
    },
    {
      value: "น้ำร้อน เมืองเพชรบูรณ์ เพชรบูรณ์ 67000",
      label: "น้ำร้อน เมืองเพชรบูรณ์ เพชรบูรณ์ 67000",
    },
    {
      value: "บ้านโคก เมืองเพชรบูรณ์ เพชรบูรณ์ 67000",
      label: "บ้านโคก เมืองเพชรบูรณ์ เพชรบูรณ์ 67000",
    },
    {
      value: "บ้านโตก เมืองเพชรบูรณ์ เพชรบูรณ์ 67000",
      label: "บ้านโตก เมืองเพชรบูรณ์ เพชรบูรณ์ 67000",
    },
    {
      value: "ป่าเลา เมืองเพชรบูรณ์ เพชรบูรณ์ 67000",
      label: "ป่าเลา เมืองเพชรบูรณ์ เพชรบูรณ์ 67000",
    },
    {
      value: "ระวิง เมืองเพชรบูรณ์ เพชรบูรณ์ 67210",
      label: "ระวิง เมืองเพชรบูรณ์ เพชรบูรณ์ 67210",
    },
    {
      value: "วังชมภู เมืองเพชรบูรณ์ เพชรบูรณ์ 67210",
      label: "วังชมภู เมืองเพชรบูรณ์ เพชรบูรณ์ 67210",
    },
    {
      value: "สะเดียง เมืองเพชรบูรณ์ เพชรบูรณ์ 67000",
      label: "สะเดียง เมืองเพชรบูรณ์ เพชรบูรณ์ 67000",
    },
    {
      value: "ห้วยสะแก เมืองเพชรบูรณ์ เพชรบูรณ์ 67210",
      label: "ห้วยสะแก เมืองเพชรบูรณ์ เพชรบูรณ์ 67210",
    },
    {
      value: "ห้วยใหญ่ เมืองเพชรบูรณ์ เพชรบูรณ์ 67000",
      label: "ห้วยใหญ่ เมืองเพชรบูรณ์ เพชรบูรณ์ 67000",
    },
    {
      value: "ในเมือง เมืองเพชรบูรณ์ เพชรบูรณ์ 67000",
      label: "ในเมือง เมืองเพชรบูรณ์ เพชรบูรณ์ 67000",
    },
    {
      value: "ชัยนารายณ์ ชัยบาดาล ลพบุรี 15130",
      label: "ชัยนารายณ์ ชัยบาดาล ลพบุรี 15130",
    },
    {
      value: "ชัยบาดาล ชัยบาดาล ลพบุรี 15230",
      label: "ชัยบาดาล ชัยบาดาล ลพบุรี 15230",
    },
    {
      value: "ซับตะเคียน ชัยบาดาล ลพบุรี 15130",
      label: "ซับตะเคียน ชัยบาดาล ลพบุรี 15130",
    },
    {
      value: "ท่าดินดำ ชัยบาดาล ลพบุรี 15130",
      label: "ท่าดินดำ ชัยบาดาล ลพบุรี 15130",
    },
    {
      value: "ท่ามะนาว ชัยบาดาล ลพบุรี 15130",
      label: "ท่ามะนาว ชัยบาดาล ลพบุรี 15130",
    },
    {
      value: "นาโสม ชัยบาดาล ลพบุรี 15190",
      label: "นาโสม ชัยบาดาล ลพบุรี 15190",
    },
    {
      value: "นิคมลำนารายณ์ ชัยบาดาล ลพบุรี 15130",
      label: "นิคมลำนารายณ์ ชัยบาดาล ลพบุรี 15130",
    },
    {
      value: "บัวชุม ชัยบาดาล ลพบุรี 15130",
      label: "บัวชุม ชัยบาดาล ลพบุรี 15130",
    },
    {
      value: "บ้านใหม่สามัคคี ชัยบาดาล ลพบุรี 15130",
      label: "บ้านใหม่สามัคคี ชัยบาดาล ลพบุรี 15130",
    },
    {
      value: "มะกอกหวาน ชัยบาดาล ลพบุรี 15230",
      label: "มะกอกหวาน ชัยบาดาล ลพบุรี 15230",
    },
    {
      value: "ม่วงค่อม ชัยบาดาล ลพบุรี 15230",
      label: "ม่วงค่อม ชัยบาดาล ลพบุรี 15230",
    },
    {
      value: "ลำนารายณ์ ชัยบาดาล ลพบุรี 15130",
      label: "ลำนารายณ์ ชัยบาดาล ลพบุรี 15130",
    },
    {
      value: "ศิลาทิพย์ ชัยบาดาล ลพบุรี 15130",
      label: "ศิลาทิพย์ ชัยบาดาล ลพบุรี 15130",
    },
    {
      value: "หนองยายโต๊ะ ชัยบาดาล ลพบุรี 15130",
      label: "หนองยายโต๊ะ ชัยบาดาล ลพบุรี 15130",
    },
    {
      value: "ห้วยหิน ชัยบาดาล ลพบุรี 15130",
      label: "ห้วยหิน ชัยบาดาล ลพบุรี 15130",
    },
    {
      value: "เกาะรัง ชัยบาดาล ลพบุรี 15130",
      label: "เกาะรัง ชัยบาดาล ลพบุรี 15130",
    },
    {
      value: "เขาแหลม ชัยบาดาล ลพบุรี 15130",
      label: "เขาแหลม ชัยบาดาล ลพบุรี 15130",
    },
    {
      value: "ท่าวุ้ง ท่าวุ้ง ลพบุรี 15150",
      label: "ท่าวุ้ง ท่าวุ้ง ลพบุรี 15150",
    },
    {
      value: "บางคู้ ท่าวุ้ง ลพบุรี 15150",
      label: "บางคู้ ท่าวุ้ง ลพบุรี 15150",
    },
    { value: "บางงา ท่าวุ้ง ลพบุรี 15150", label: "บางงา ท่าวุ้ง ลพบุรี 15150" },
    {
      value: "บางลี่ ท่าวุ้ง ลพบุรี 15150",
      label: "บางลี่ ท่าวุ้ง ลพบุรี 15150",
    },
    {
      value: "บ้านเบิก ท่าวุ้ง ลพบุรี 15150",
      label: "บ้านเบิก ท่าวุ้ง ลพบุรี 15150",
    },
    {
      value: "มุจลินท์ ท่าวุ้ง ลพบุรี 15150",
      label: "มุจลินท์ ท่าวุ้ง ลพบุรี 15150",
    },
    {
      value: "ลาดสาลี่ ท่าวุ้ง ลพบุรี 15150",
      label: "ลาดสาลี่ ท่าวุ้ง ลพบุรี 15150",
    },
    {
      value: "หัวสำโรง ท่าวุ้ง ลพบุรี 15150",
      label: "หัวสำโรง ท่าวุ้ง ลพบุรี 15150",
    },
    {
      value: "เขาสมอคอน ท่าวุ้ง ลพบุรี 15180",
      label: "เขาสมอคอน ท่าวุ้ง ลพบุรี 15180",
    },
    {
      value: "โคกสลุด ท่าวุ้ง ลพบุรี 15150",
      label: "โคกสลุด ท่าวุ้ง ลพบุรี 15150",
    },
    {
      value: "โพตลาดแก้ว ท่าวุ้ง ลพบุรี 15150",
      label: "โพตลาดแก้ว ท่าวุ้ง ลพบุรี 15150",
    },
    {
      value: "ซับจำปา ท่าหลวง ลพบุรี 15230",
      label: "ซับจำปา ท่าหลวง ลพบุรี 15230",
    },
    {
      value: "ทะเลวังวัด ท่าหลวง ลพบุรี 15230",
      label: "ทะเลวังวัด ท่าหลวง ลพบุรี 15230",
    },
    {
      value: "ท่าหลวง ท่าหลวง ลพบุรี 15230",
      label: "ท่าหลวง ท่าหลวง ลพบุรี 15230",
    },
    {
      value: "หนองผักแว่น ท่าหลวง ลพบุรี 15230",
      label: "หนองผักแว่น ท่าหลวง ลพบุรี 15230",
    },
    { value: "หัวลำ ท่าหลวง ลพบุรี 15230", label: "หัวลำ ท่าหลวง ลพบุรี 15230" },
    {
      value: "แก่งผักกูด ท่าหลวง ลพบุรี 15230",
      label: "แก่งผักกูด ท่าหลวง ลพบุรี 15230",
    },
    {
      value: "ชอนม่วง บ้านหมี่ ลพบุรี 15110",
      label: "ชอนม่วง บ้านหมี่ ลพบุรี 15110",
    },
    {
      value: "ดงพลับ บ้านหมี่ ลพบุรี 15110",
      label: "ดงพลับ บ้านหมี่ ลพบุรี 15110",
    },
    {
      value: "ดอนดึง บ้านหมี่ ลพบุรี 15110",
      label: "ดอนดึง บ้านหมี่ ลพบุรี 15110",
    },
    {
      value: "บางกะพี้ บ้านหมี่ ลพบุรี 15110",
      label: "บางกะพี้ บ้านหมี่ ลพบุรี 15110",
    },
    {
      value: "บางขาม บ้านหมี่ ลพบุรี 15180",
      label: "บางขาม บ้านหมี่ ลพบุรี 15180",
    },
    {
      value: "บางพึ่ง บ้านหมี่ ลพบุรี 15110",
      label: "บางพึ่ง บ้านหมี่ ลพบุรี 15110",
    },
    {
      value: "บ้านกล้วย บ้านหมี่ ลพบุรี 15110",
      label: "บ้านกล้วย บ้านหมี่ ลพบุรี 15110",
    },
    {
      value: "บ้านชี บ้านหมี่ ลพบุรี 15180",
      label: "บ้านชี บ้านหมี่ ลพบุรี 15180",
    },
    {
      value: "บ้านทราย บ้านหมี่ ลพบุรี 15110",
      label: "บ้านทราย บ้านหมี่ ลพบุรี 15110",
    },
    {
      value: "บ้านหมี่ บ้านหมี่ ลพบุรี 15110",
      label: "บ้านหมี่ บ้านหมี่ ลพบุรี 15110",
    },
    { value: "พุคา บ้านหมี่ ลพบุรี 15110", label: "พุคา บ้านหมี่ ลพบุรี 15110" },
    {
      value: "มหาสอน บ้านหมี่ ลพบุรี 15110",
      label: "มหาสอน บ้านหมี่ ลพบุรี 15110",
    },
    {
      value: "สนามแจง บ้านหมี่ ลพบุรี 15110",
      label: "สนามแจง บ้านหมี่ ลพบุรี 15110",
    },
    {
      value: "สายห้วยแก้ว บ้านหมี่ ลพบุรี 15110",
      label: "สายห้วยแก้ว บ้านหมี่ ลพบุรี 15110",
    },
    {
      value: "หนองกระเบียน บ้านหมี่ ลพบุรี 15110",
      label: "หนองกระเบียน บ้านหมี่ ลพบุรี 15110",
    },
    {
      value: "หนองทรายขาว บ้านหมี่ ลพบุรี 15110",
      label: "หนองทรายขาว บ้านหมี่ ลพบุรี 15110",
    },
    {
      value: "หนองเต่า บ้านหมี่ ลพบุรี 15110",
      label: "หนองเต่า บ้านหมี่ ลพบุรี 15110",
    },
    {
      value: "หนองเมือง บ้านหมี่ ลพบุรี 15110",
      label: "หนองเมือง บ้านหมี่ ลพบุรี 15110",
    },
    {
      value: "หินปัก บ้านหมี่ ลพบุรี 15110",
      label: "หินปัก บ้านหมี่ ลพบุรี 15110",
    },
    {
      value: "เชียงงา บ้านหมี่ ลพบุรี 15110",
      label: "เชียงงา บ้านหมี่ ลพบุรี 15110",
    },
    {
      value: "โพนทอง บ้านหมี่ ลพบุรี 15110",
      label: "โพนทอง บ้านหมี่ ลพบุรี 15110",
    },
    {
      value: "ไผ่ใหญ่ บ้านหมี่ ลพบุรี 15110",
      label: "ไผ่ใหญ่ บ้านหมี่ ลพบุรี 15110",
    },
    {
      value: "ชอนน้อย พัฒนานิคม ลพบุรี 15140",
      label: "ชอนน้อย พัฒนานิคม ลพบุรี 15140",
    },
    {
      value: "ช่องสาริกา พัฒนานิคม ลพบุรี 15220",
      label: "ช่องสาริกา พัฒนานิคม ลพบุรี 15220",
    },
    {
      value: "ดีลัง พัฒนานิคม ลพบุรี 15220",
      label: "ดีลัง พัฒนานิคม ลพบุรี 15220",
    },
    {
      value: "น้ำสุด พัฒนานิคม ลพบุรี 15140",
      label: "น้ำสุด พัฒนานิคม ลพบุรี 15140",
    },
    {
      value: "พัฒนานิคม พัฒนานิคม ลพบุรี 15140",
      label: "พัฒนานิคม พัฒนานิคม ลพบุรี 15140",
    },
    {
      value: "มะนาวหวาน พัฒนานิคม ลพบุรี 15140",
      label: "มะนาวหวาน พัฒนานิคม ลพบุรี 15140",
    },
    {
      value: "หนองบัว พัฒนานิคม ลพบุรี 15140",
      label: "หนองบัว พัฒนานิคม ลพบุรี 15140",
    },
    {
      value: "ห้วยขุนราม พัฒนานิคม ลพบุรี 18220",
      label: "ห้วยขุนราม พัฒนานิคม ลพบุรี 18220",
    },
    {
      value: "โคกสลุง พัฒนานิคม ลพบุรี 15140",
      label: "โคกสลุง พัฒนานิคม ลพบุรี 15140",
    },
    {
      value: "กุดตาเพชร ลำสนธิ ลพบุรี 15190",
      label: "กุดตาเพชร ลำสนธิ ลพบุรี 15190",
    },
    {
      value: "ซับสมบูรณ์ ลำสนธิ ลพบุรี 15190",
      label: "ซับสมบูรณ์ ลำสนธิ ลพบุรี 15190",
    },
    { value: "ลำสนธิ ลำสนธิ ลพบุรี 15190", label: "ลำสนธิ ลำสนธิ ลพบุรี 15190" },
    { value: "หนองรี ลำสนธิ ลพบุรี 15190", label: "หนองรี ลำสนธิ ลพบุรี 15190" },
    {
      value: "เขาน้อย ลำสนธิ ลพบุรี 15130",
      label: "เขาน้อย ลำสนธิ ลพบุรี 15130",
    },
    { value: "เขารวก ลำสนธิ ลพบุรี 15190", label: "เขารวก ลำสนธิ ลพบุรี 15190" },
    {
      value: "ทุ่งท่าช้าง สระโบสถ์ ลพบุรี 15240",
      label: "ทุ่งท่าช้าง สระโบสถ์ ลพบุรี 15240",
    },
    {
      value: "นิยมชัย สระโบสถ์ ลพบุรี 15240",
      label: "นิยมชัย สระโบสถ์ ลพบุรี 15240",
    },
    {
      value: "มหาโพธิ สระโบสถ์ ลพบุรี 15240",
      label: "มหาโพธิ สระโบสถ์ ลพบุรี 15240",
    },
    {
      value: "สระโบสถ์ สระโบสถ์ ลพบุรี 15240",
      label: "สระโบสถ์ สระโบสถ์ ลพบุรี 15240",
    },
    {
      value: "ห้วยใหญ่ สระโบสถ์ ลพบุรี 15240",
      label: "ห้วยใหญ่ สระโบสถ์ ลพบุรี 15240",
    },
    {
      value: "ชอนสมบูรณ์ หนองม่วง ลพบุรี 15170",
      label: "ชอนสมบูรณ์ หนองม่วง ลพบุรี 15170",
    },
    {
      value: "ชอนสารเดช หนองม่วง ลพบุรี 15170",
      label: "ชอนสารเดช หนองม่วง ลพบุรี 15170",
    },
    {
      value: "ดงดินแดง หนองม่วง ลพบุรี 15170",
      label: "ดงดินแดง หนองม่วง ลพบุรี 15170",
    },
    {
      value: "บ่อทอง หนองม่วง ลพบุรี 15170",
      label: "บ่อทอง หนองม่วง ลพบุรี 15170",
    },
    {
      value: "ยางโทน หนองม่วง ลพบุรี 15170",
      label: "ยางโทน หนองม่วง ลพบุรี 15170",
    },
    {
      value: "หนองม่วง หนองม่วง ลพบุรี 15170",
      label: "หนองม่วง หนองม่วง ลพบุรี 15170",
    },
    {
      value: "กกโก เมืองลพบุรี ลพบุรี 15000",
      label: "กกโก เมืองลพบุรี ลพบุรี 15000",
    },
    {
      value: "งิ้วราย เมืองลพบุรี ลพบุรี 15000",
      label: "งิ้วราย เมืองลพบุรี ลพบุรี 15000",
    },
    {
      value: "ดอนโพธิ์ เมืองลพบุรี ลพบุรี 15000",
      label: "ดอนโพธิ์ เมืองลพบุรี ลพบุรี 15000",
    },
    {
      value: "ตะลุง เมืองลพบุรี ลพบุรี 15000",
      label: "ตะลุง เมืองลพบุรี ลพบุรี 15000",
    },
    {
      value: "ถนนใหญ่ เมืองลพบุรี ลพบุรี 15000",
      label: "ถนนใหญ่ เมืองลพบุรี ลพบุรี 15000",
    },
    {
      value: "ทะเลชุบศร เมืองลพบุรี ลพบุรี 15000",
      label: "ทะเลชุบศร เมืองลพบุรี ลพบุรี 15000",
    },
    {
      value: "ท่าศาลา เมืองลพบุรี ลพบุรี 15000",
      label: "ท่าศาลา เมืองลพบุรี ลพบุรี 15000",
    },
    {
      value: "ท่าหิน เมืองลพบุรี ลพบุรี 15000",
      label: "ท่าหิน เมืองลพบุรี ลพบุรี 15000",
    },
    {
      value: "ท่าแค เมืองลพบุรี ลพบุรี 15000",
      label: "ท่าแค เมืองลพบุรี ลพบุรี 15000",
    },
    {
      value: "ท้ายตลาด เมืองลพบุรี ลพบุรี 15000",
      label: "ท้ายตลาด เมืองลพบุรี ลพบุรี 15000",
    },
    {
      value: "นิคมสร้างตนเอง เมืองลพบุรี ลพบุรี 15000",
      label: "นิคมสร้างตนเอง เมืองลพบุรี ลพบุรี 15000",
    },
    {
      value: "บางขันหมาก เมืองลพบุรี ลพบุรี 15000",
      label: "บางขันหมาก เมืองลพบุรี ลพบุรี 15000",
    },
    {
      value: "บ้านข่อย เมืองลพบุรี ลพบุรี 15000",
      label: "บ้านข่อย เมืองลพบุรี ลพบุรี 15000",
    },
    {
      value: "ป่าตาล เมืองลพบุรี ลพบุรี 15000",
      label: "ป่าตาล เมืองลพบุรี ลพบุรี 15000",
    },
    {
      value: "พรหมมาสตร์ เมืองลพบุรี ลพบุรี 15000",
      label: "พรหมมาสตร์ เมืองลพบุรี ลพบุรี 15000",
    },
    {
      value: "สี่คลอง เมืองลพบุรี ลพบุรี 15000",
      label: "สี่คลอง เมืองลพบุรี ลพบุรี 15000",
    },
    {
      value: "เขาพระงาม เมืองลพบุรี ลพบุรี 15160",
      label: "เขาพระงาม เมืองลพบุรี ลพบุรี 15160",
    },
    {
      value: "เขาสามยอด เมืองลพบุรี ลพบุรี 15000",
      label: "เขาสามยอด เมืองลพบุรี ลพบุรี 15000",
    },
    {
      value: "โก่งธนู เมืองลพบุรี ลพบุรี 13240",
      label: "โก่งธนู เมืองลพบุรี ลพบุรี 13240",
    },
    {
      value: "โคกกะเทียม เมืองลพบุรี ลพบุรี 15160",
      label: "โคกกะเทียม เมืองลพบุรี ลพบุรี 15160",
    },
    {
      value: "โคกตูม เมืองลพบุรี ลพบุรี 15210",
      label: "โคกตูม เมืองลพบุรี ลพบุรี 15210",
    },
    {
      value: "โคกลำพาน เมืองลพบุรี ลพบุรี 15000",
      label: "โคกลำพาน เมืองลพบุรี ลพบุรี 15000",
    },
    {
      value: "โพธิ์ตรุ เมืองลพบุรี ลพบุรี 15000",
      label: "โพธิ์ตรุ เมืองลพบุรี ลพบุรี 15000",
    },
    {
      value: "โพธิ์เก้าต้น เมืองลพบุรี ลพบุรี 15000",
      label: "โพธิ์เก้าต้น เมืองลพบุรี ลพบุรี 15000",
    },
    {
      value: "คลองเกตุ โคกสำโรง ลพบุรี 15120",
      label: "คลองเกตุ โคกสำโรง ลพบุรี 15120",
    },
    {
      value: "ดงมะรุม โคกสำโรง ลพบุรี 15120",
      label: "ดงมะรุม โคกสำโรง ลพบุรี 15120",
    },
    {
      value: "ถลุงเหล็ก โคกสำโรง ลพบุรี 15120",
      label: "ถลุงเหล็ก โคกสำโรง ลพบุรี 15120",
    },
    {
      value: "วังขอนขว้าง โคกสำโรง ลพบุรี 15120",
      label: "วังขอนขว้าง โคกสำโรง ลพบุรี 15120",
    },
    {
      value: "วังจั่น โคกสำโรง ลพบุรี 15120",
      label: "วังจั่น โคกสำโรง ลพบุรี 15120",
    },
    {
      value: "วังเพลิง โคกสำโรง ลพบุรี 15120",
      label: "วังเพลิง โคกสำโรง ลพบุรี 15120",
    },
    {
      value: "สะแกราบ โคกสำโรง ลพบุรี 15120",
      label: "สะแกราบ โคกสำโรง ลพบุรี 15120",
    },
    {
      value: "หนองแขม โคกสำโรง ลพบุรี 15120",
      label: "หนองแขม โคกสำโรง ลพบุรี 15120",
    },
    {
      value: "หลุมข้าว โคกสำโรง ลพบุรี 15120",
      label: "หลุมข้าว โคกสำโรง ลพบุรี 15120",
    },
    {
      value: "ห้วยโป่ง โคกสำโรง ลพบุรี 15120",
      label: "ห้วยโป่ง โคกสำโรง ลพบุรี 15120",
    },
    {
      value: "เกาะแก้ว โคกสำโรง ลพบุรี 15120",
      label: "เกาะแก้ว โคกสำโรง ลพบุรี 15120",
    },
    {
      value: "เพนียด โคกสำโรง ลพบุรี 15120",
      label: "เพนียด โคกสำโรง ลพบุรี 15120",
    },
    {
      value: "โคกสำโรง โคกสำโรง ลพบุรี 15120",
      label: "โคกสำโรง โคกสำโรง ลพบุรี 15120",
    },
    {
      value: "ยางราก โคกเจริญ ลพบุรี 15250",
      label: "ยางราก โคกเจริญ ลพบุรี 15250",
    },
    {
      value: "วังทอง โคกเจริญ ลพบุรี 15250",
      label: "วังทอง โคกเจริญ ลพบุรี 15250",
    },
    {
      value: "หนองมะค่า โคกเจริญ ลพบุรี 15250",
      label: "หนองมะค่า โคกเจริญ ลพบุรี 15250",
    },
    {
      value: "โคกเจริญ โคกเจริญ ลพบุรี 15250",
      label: "โคกเจริญ โคกเจริญ ลพบุรี 15250",
    },
    {
      value: "โคกแสมสาร โคกเจริญ ลพบุรี 15250",
      label: "โคกแสมสาร โคกเจริญ ลพบุรี 15250",
    },
    {
      value: "คลองด่าน บางบ่อ สมุทรปราการ 10550",
      label: "คลองด่าน บางบ่อ สมุทรปราการ 10550",
    },
    {
      value: "คลองนิยมยาตรา บางบ่อ สมุทรปราการ 10560",
      label: "คลองนิยมยาตรา บางบ่อ สมุทรปราการ 10560",
    },
    {
      value: "คลองสวน บางบ่อ สมุทรปราการ 10560",
      label: "คลองสวน บางบ่อ สมุทรปราการ 10560",
    },
    {
      value: "บางบ่อ บางบ่อ สมุทรปราการ 10560",
      label: "บางบ่อ บางบ่อ สมุทรปราการ 10560",
    },
    {
      value: "บางพลีน้อย บางบ่อ สมุทรปราการ 10560",
      label: "บางพลีน้อย บางบ่อ สมุทรปราการ 10560",
    },
    {
      value: "บางเพรียง บางบ่อ สมุทรปราการ 10560",
      label: "บางเพรียง บางบ่อ สมุทรปราการ 10560",
    },
    {
      value: "บ้านระกาศ บางบ่อ สมุทรปราการ 10560",
      label: "บ้านระกาศ บางบ่อ สมุทรปราการ 10560",
    },
    {
      value: "เปร็ง บางบ่อ สมุทรปราการ 10560",
      label: "เปร็ง บางบ่อ สมุทรปราการ 10560",
    },
    {
      value: "บางปลา บางพลี สมุทรปราการ 10540",
      label: "บางปลา บางพลี สมุทรปราการ 10540",
    },
    {
      value: "บางพลีใหญ่ บางพลี สมุทรปราการ 10540",
      label: "บางพลีใหญ่ บางพลี สมุทรปราการ 10540",
    },
    {
      value: "บางแก้ว บางพลี สมุทรปราการ 10540",
      label: "บางแก้ว บางพลี สมุทรปราการ 10540",
    },
    {
      value: "บางโฉลง บางพลี สมุทรปราการ 10540",
      label: "บางโฉลง บางพลี สมุทรปราการ 10540",
    },
    {
      value: "ราชาเทวะ บางพลี สมุทรปราการ 10540",
      label: "ราชาเทวะ บางพลี สมุทรปราการ 10540",
    },
    {
      value: "หนองปรือ บางพลี สมุทรปราการ 10540",
      label: "หนองปรือ บางพลี สมุทรปราการ 10540",
    },
    {
      value: "บางเสาธง บางเสาธง สมุทรปราการ 10570",
      label: "บางเสาธง บางเสาธง สมุทรปราการ 10570",
    },
    {
      value: "ศีรษะจรเข้น้อย บางเสาธง สมุทรปราการ 10570",
      label: "ศีรษะจรเข้น้อย บางเสาธง สมุทรปราการ 10570",
    },
    {
      value: "ศีรษะจรเข้ใหญ่ บางเสาธง สมุทรปราการ 10570",
      label: "ศีรษะจรเข้ใหญ่ บางเสาธง สมุทรปราการ 10570",
    },
    {
      value: "ตลาด พระประแดง สมุทรปราการ 10130",
      label: "ตลาด พระประแดง สมุทรปราการ 10130",
    },
    {
      value: "ทรงคนอง พระประแดง สมุทรปราการ 10130",
      label: "ทรงคนอง พระประแดง สมุทรปราการ 10130",
    },
    {
      value: "บางกระสอบ พระประแดง สมุทรปราการ 10130",
      label: "บางกระสอบ พระประแดง สมุทรปราการ 10130",
    },
    {
      value: "บางกอบัว พระประแดง สมุทรปราการ 10130",
      label: "บางกอบัว พระประแดง สมุทรปราการ 10130",
    },
    {
      value: "บางกะเจ้า พระประแดง สมุทรปราการ 10130",
      label: "บางกะเจ้า พระประแดง สมุทรปราการ 10130",
    },
    {
      value: "บางครุ พระประแดง สมุทรปราการ 10130",
      label: "บางครุ พระประแดง สมุทรปราการ 10130",
    },
    {
      value: "บางจาก พระประแดง สมุทรปราการ 10130",
      label: "บางจาก พระประแดง สมุทรปราการ 10130",
    },
    {
      value: "บางน้ำผึ้ง พระประแดง สมุทรปราการ 10130",
      label: "บางน้ำผึ้ง พระประแดง สมุทรปราการ 10130",
    },
    {
      value: "บางพึ่ง พระประแดง สมุทรปราการ 10130",
      label: "บางพึ่ง พระประแดง สมุทรปราการ 10130",
    },
    {
      value: "บางยอ พระประแดง สมุทรปราการ 10130",
      label: "บางยอ พระประแดง สมุทรปราการ 10130",
    },
    {
      value: "บางหญ้าแพรก พระประแดง สมุทรปราการ 10130",
      label: "บางหญ้าแพรก พระประแดง สมุทรปราการ 10130",
    },
    {
      value: "บางหัวเสือ พระประแดง สมุทรปราการ 10130",
      label: "บางหัวเสือ พระประแดง สมุทรปราการ 10130",
    },
    {
      value: "สำโรง พระประแดง สมุทรปราการ 10130",
      label: "สำโรง พระประแดง สมุทรปราการ 10130",
    },
    {
      value: "สำโรงกลาง พระประแดง สมุทรปราการ 10130",
      label: "สำโรงกลาง พระประแดง สมุทรปราการ 10130",
    },
    {
      value: "สำโรงใต้ พระประแดง สมุทรปราการ 10130",
      label: "สำโรงใต้ พระประแดง สมุทรปราการ 10130",
    },
    {
      value: "นาเกลือ พระสมุทรเจดีย์ สมุทรปราการ 10290",
      label: "นาเกลือ พระสมุทรเจดีย์ สมุทรปราการ 10290",
    },
    {
      value: "บ้านคลองสวน พระสมุทรเจดีย์ สมุทรปราการ 10290",
      label: "บ้านคลองสวน พระสมุทรเจดีย์ สมุทรปราการ 10290",
    },
    {
      value: "ปากคลองบางปลากด พระสมุทรเจดีย์ สมุทรปราการ 10290",
      label: "ปากคลองบางปลากด พระสมุทรเจดีย์ สมุทรปราการ 10290",
    },
    {
      value: "แหลมฟ้าผ่า พระสมุทรเจดีย์ สมุทรปราการ 10290",
      label: "แหลมฟ้าผ่า พระสมุทรเจดีย์ สมุทรปราการ 10290",
    },
    {
      value: "ในคลองบางปลากด พระสมุทรเจดีย์ สมุทรปราการ 10290",
      label: "ในคลองบางปลากด พระสมุทรเจดีย์ สมุทรปราการ 10290",
    },
    {
      value: "ท้ายบ้าน เมืองสมุทรปราการ สมุทรปราการ 10280",
      label: "ท้ายบ้าน เมืองสมุทรปราการ สมุทรปราการ 10280",
    },
    {
      value: "ท้ายบ้านใหม่ เมืองสมุทรปราการ สมุทรปราการ 10280",
      label: "ท้ายบ้านใหม่ เมืองสมุทรปราการ สมุทรปราการ 10280",
    },
    {
      value: "บางด้วน เมืองสมุทรปราการ สมุทรปราการ 10270",
      label: "บางด้วน เมืองสมุทรปราการ สมุทรปราการ 10270",
    },
    {
      value: "บางปู เมืองสมุทรปราการ สมุทรปราการ 10280",
      label: "บางปู เมืองสมุทรปราการ สมุทรปราการ 10280",
    },
    {
      value: "บางปูใหม่ เมืองสมุทรปราการ สมุทรปราการ 10280",
      label: "บางปูใหม่ เมืองสมุทรปราการ สมุทรปราการ 10280",
    },
    {
      value: "บางเมือง เมืองสมุทรปราการ สมุทรปราการ 10270",
      label: "บางเมือง เมืองสมุทรปราการ สมุทรปราการ 10270",
    },
    {
      value: "บางเมืองใหม่ เมืองสมุทรปราการ สมุทรปราการ 10270",
      label: "บางเมืองใหม่ เมืองสมุทรปราการ สมุทรปราการ 10270",
    },
    {
      value: "บางโปรง เมืองสมุทรปราการ สมุทรปราการ 10270",
      label: "บางโปรง เมืองสมุทรปราการ สมุทรปราการ 10270",
    },
    {
      value: "ปากน้ำ เมืองสมุทรปราการ สมุทรปราการ 10270",
      label: "ปากน้ำ เมืองสมุทรปราการ สมุทรปราการ 10270",
    },
    {
      value: "สำโรงเหนือ เมืองสมุทรปราการ สมุทรปราการ 10270",
      label: "สำโรงเหนือ เมืองสมุทรปราการ สมุทรปราการ 10270",
    },
    {
      value: "เทพารักษ์ เมืองสมุทรปราการ สมุทรปราการ 10270",
      label: "เทพารักษ์ เมืองสมุทรปราการ สมุทรปราการ 10270",
    },
    {
      value: "แพรกษา เมืองสมุทรปราการ สมุทรปราการ 10280",
      label: "แพรกษา เมืองสมุทรปราการ สมุทรปราการ 10280",
    },
    {
      value: "แพรกษาใหม่ เมืองสมุทรปราการ สมุทรปราการ 10280",
      label: "แพรกษาใหม่ เมืองสมุทรปราการ สมุทรปราการ 10280",
    },
    {
      value: "กระดังงา บางคนที สมุทรสงคราม 75120",
      label: "กระดังงา บางคนที สมุทรสงคราม 75120",
    },
    {
      value: "จอมปลวก บางคนที สมุทรสงคราม 75120",
      label: "จอมปลวก บางคนที สมุทรสงคราม 75120",
    },
    {
      value: "ดอนมะโนรา บางคนที สมุทรสงคราม 75120",
      label: "ดอนมะโนรา บางคนที สมุทรสงคราม 75120",
    },
    {
      value: "บางกระบือ บางคนที สมุทรสงคราม 75120",
      label: "บางกระบือ บางคนที สมุทรสงคราม 75120",
    },
    {
      value: "บางกุ้ง บางคนที สมุทรสงคราม 75120",
      label: "บางกุ้ง บางคนที สมุทรสงคราม 75120",
    },
    {
      value: "บางคนที บางคนที สมุทรสงคราม 75120",
      label: "บางคนที บางคนที สมุทรสงคราม 75120",
    },
    {
      value: "บางนกแขวก บางคนที สมุทรสงคราม 75120",
      label: "บางนกแขวก บางคนที สมุทรสงคราม 75120",
    },
    {
      value: "บางพรม บางคนที สมุทรสงคราม 75120",
      label: "บางพรม บางคนที สมุทรสงคราม 75120",
    },
    {
      value: "บางยี่รงค์ บางคนที สมุทรสงคราม 75120",
      label: "บางยี่รงค์ บางคนที สมุทรสงคราม 75120",
    },
    {
      value: "บางสะแก บางคนที สมุทรสงคราม 75120",
      label: "บางสะแก บางคนที สมุทรสงคราม 75120",
    },
    {
      value: "บ้านปราโมทย์ บางคนที สมุทรสงคราม 75120",
      label: "บ้านปราโมทย์ บางคนที สมุทรสงคราม 75120",
    },
    {
      value: "ยายแพง บางคนที สมุทรสงคราม 75120",
      label: "ยายแพง บางคนที สมุทรสงคราม 75120",
    },
    {
      value: "โรงหีบ บางคนที สมุทรสงคราม 75120",
      label: "โรงหีบ บางคนที สมุทรสงคราม 75120",
    },
    {
      value: "ท่าคา อัมพวา สมุทรสงคราม 75110",
      label: "ท่าคา อัมพวา สมุทรสงคราม 75110",
    },
    {
      value: "บางช้าง อัมพวา สมุทรสงคราม 75110",
      label: "บางช้าง อัมพวา สมุทรสงคราม 75110",
    },
    {
      value: "บางนางลี่ อัมพวา สมุทรสงคราม 75110",
      label: "บางนางลี่ อัมพวา สมุทรสงคราม 75110",
    },
    {
      value: "บางแค อัมพวา สมุทรสงคราม 75110",
      label: "บางแค อัมพวา สมุทรสงคราม 75110",
    },
    {
      value: "ปลายโพงพาง อัมพวา สมุทรสงคราม 75110",
      label: "ปลายโพงพาง อัมพวา สมุทรสงคราม 75110",
    },
    {
      value: "ยี่สาร อัมพวา สมุทรสงคราม 75110",
      label: "ยี่สาร อัมพวา สมุทรสงคราม 75110",
    },
    {
      value: "วัดประดู่ อัมพวา สมุทรสงคราม 75110",
      label: "วัดประดู่ อัมพวา สมุทรสงคราม 75110",
    },
    {
      value: "สวนหลวง อัมพวา สมุทรสงคราม 75110",
      label: "สวนหลวง อัมพวา สมุทรสงคราม 75110",
    },
    {
      value: "อัมพวา อัมพวา สมุทรสงคราม 75110",
      label: "อัมพวา อัมพวา สมุทรสงคราม 75110",
    },
    {
      value: "เหมืองใหม่ อัมพวา สมุทรสงคราม 75110",
      label: "เหมืองใหม่ อัมพวา สมุทรสงคราม 75110",
    },
    {
      value: "แควอ้อม อัมพวา สมุทรสงคราม 75110",
      label: "แควอ้อม อัมพวา สมุทรสงคราม 75110",
    },
    {
      value: "แพรกหนามแดง อัมพวา สมุทรสงคราม 75110",
      label: "แพรกหนามแดง อัมพวา สมุทรสงคราม 75110",
    },
    {
      value: "คลองเขิน เมืองสมุทรสงคราม สมุทรสงคราม 75000",
      label: "คลองเขิน เมืองสมุทรสงคราม สมุทรสงคราม 75000",
    },
    {
      value: "คลองโคน เมืองสมุทรสงคราม สมุทรสงคราม 75000",
      label: "คลองโคน เมืองสมุทรสงคราม สมุทรสงคราม 75000",
    },
    {
      value: "ท้ายหาด เมืองสมุทรสงคราม สมุทรสงคราม 75000",
      label: "ท้ายหาด เมืองสมุทรสงคราม สมุทรสงคราม 75000",
    },
    {
      value: "นางตะเคียน เมืองสมุทรสงคราม สมุทรสงคราม 75000",
      label: "นางตะเคียน เมืองสมุทรสงคราม สมุทรสงคราม 75000",
    },
    {
      value: "บางขันแตก เมืองสมุทรสงคราม สมุทรสงคราม 75000",
      label: "บางขันแตก เมืองสมุทรสงคราม สมุทรสงคราม 75000",
    },
    {
      value: "บางจะเกร็ง เมืองสมุทรสงคราม สมุทรสงคราม 75000",
      label: "บางจะเกร็ง เมืองสมุทรสงคราม สมุทรสงคราม 75000",
    },
    {
      value: "บางแก้ว เมืองสมุทรสงคราม สมุทรสงคราม 75000",
      label: "บางแก้ว เมืองสมุทรสงคราม สมุทรสงคราม 75000",
    },
    {
      value: "บ้านปรก เมืองสมุทรสงคราม สมุทรสงคราม 75000",
      label: "บ้านปรก เมืองสมุทรสงคราม สมุทรสงคราม 75000",
    },
    {
      value: "ลาดใหญ่ เมืองสมุทรสงคราม สมุทรสงคราม 75000",
      label: "ลาดใหญ่ เมืองสมุทรสงคราม สมุทรสงคราม 75000",
    },
    {
      value: "แม่กลอง เมืองสมุทรสงคราม สมุทรสงคราม 75000",
      label: "แม่กลอง เมืองสมุทรสงคราม สมุทรสงคราม 75000",
    },
    {
      value: "แหลมใหญ่ เมืองสมุทรสงคราม สมุทรสงคราม 75000",
      label: "แหลมใหญ่ เมืองสมุทรสงคราม สมุทรสงคราม 75000",
    },
    {
      value: "คลองมะเดื่อ กระทุ่มแบน สมุทรสาคร 74110",
      label: "คลองมะเดื่อ กระทุ่มแบน สมุทรสาคร 74110",
    },
    {
      value: "ดอนไก่ดี กระทุ่มแบน สมุทรสาคร 74110",
      label: "ดอนไก่ดี กระทุ่มแบน สมุทรสาคร 74110",
    },
    {
      value: "ตลาดกระทุ่มแบน กระทุ่มแบน สมุทรสาคร 74110",
      label: "ตลาดกระทุ่มแบน กระทุ่มแบน สมุทรสาคร 74110",
    },
    {
      value: "ท่าเสา กระทุ่มแบน สมุทรสาคร 74110",
      label: "ท่าเสา กระทุ่มแบน สมุทรสาคร 74110",
    },
    {
      value: "ท่าไม้ กระทุ่มแบน สมุทรสาคร 74110",
      label: "ท่าไม้ กระทุ่มแบน สมุทรสาคร 74110",
    },
    {
      value: "บางยาง กระทุ่มแบน สมุทรสาคร 74110",
      label: "บางยาง กระทุ่มแบน สมุทรสาคร 74110",
    },
    {
      value: "สวนหลวง กระทุ่มแบน สมุทรสาคร 74110",
      label: "สวนหลวง กระทุ่มแบน สมุทรสาคร 74110",
    },
    {
      value: "หนองนกไข่ กระทุ่มแบน สมุทรสาคร 74110",
      label: "หนองนกไข่ กระทุ่มแบน สมุทรสาคร 74110",
    },
    {
      value: "อ้อมน้อย กระทุ่มแบน สมุทรสาคร 74130",
      label: "อ้อมน้อย กระทุ่มแบน สมุทรสาคร 74130",
    },
    {
      value: "แคราย กระทุ่มแบน สมุทรสาคร 74110",
      label: "แคราย กระทุ่มแบน สมุทรสาคร 74110",
    },
    {
      value: "คลองตัน บ้านแพ้ว สมุทรสาคร 74120",
      label: "คลองตัน บ้านแพ้ว สมุทรสาคร 74120",
    },
    {
      value: "บ้านแพ้ว บ้านแพ้ว สมุทรสาคร 74120",
      label: "บ้านแพ้ว บ้านแพ้ว สมุทรสาคร 74120",
    },
    {
      value: "ยกกระบัตร บ้านแพ้ว สมุทรสาคร 74120",
      label: "ยกกระบัตร บ้านแพ้ว สมุทรสาคร 74120",
    },
    {
      value: "สวนส้ม บ้านแพ้ว สมุทรสาคร 74120",
      label: "สวนส้ม บ้านแพ้ว สมุทรสาคร 74120",
    },
    {
      value: "หนองบัว บ้านแพ้ว สมุทรสาคร 74120",
      label: "หนองบัว บ้านแพ้ว สมุทรสาคร 74120",
    },
    {
      value: "หนองสองห้อง บ้านแพ้ว สมุทรสาคร 74120",
      label: "หนองสองห้อง บ้านแพ้ว สมุทรสาคร 74120",
    },
    {
      value: "หลักสอง บ้านแพ้ว สมุทรสาคร 74120",
      label: "หลักสอง บ้านแพ้ว สมุทรสาคร 74120",
    },
    {
      value: "หลักสาม บ้านแพ้ว สมุทรสาคร 74120",
      label: "หลักสาม บ้านแพ้ว สมุทรสาคร 74120",
    },
    {
      value: "อำแพง บ้านแพ้ว สมุทรสาคร 74120",
      label: "อำแพง บ้านแพ้ว สมุทรสาคร 74120",
    },
    {
      value: "เกษตรพัฒนา บ้านแพ้ว สมุทรสาคร 74120",
      label: "เกษตรพัฒนา บ้านแพ้ว สมุทรสาคร 74120",
    },
    {
      value: "เจ็ดริ้ว บ้านแพ้ว สมุทรสาคร 74120",
      label: "เจ็ดริ้ว บ้านแพ้ว สมุทรสาคร 74120",
    },
    {
      value: "โรงเข้ บ้านแพ้ว สมุทรสาคร 74120",
      label: "โรงเข้ บ้านแพ้ว สมุทรสาคร 74120",
    },
    {
      value: "กาหลง เมืองสมุทรสาคร สมุทรสาคร 74000",
      label: "กาหลง เมืองสมุทรสาคร สมุทรสาคร 74000",
    },
    {
      value: "คอกกระบือ เมืองสมุทรสาคร สมุทรสาคร 74000",
      label: "คอกกระบือ เมืองสมุทรสาคร สมุทรสาคร 74000",
    },
    {
      value: "ชัยมงคล เมืองสมุทรสาคร สมุทรสาคร 74000",
      label: "ชัยมงคล เมืองสมุทรสาคร สมุทรสาคร 74000",
    },
    {
      value: "ท่าจีน เมืองสมุทรสาคร สมุทรสาคร 74000",
      label: "ท่าจีน เมืองสมุทรสาคร สมุทรสาคร 74000",
    },
    {
      value: "ท่าฉลอม เมืองสมุทรสาคร สมุทรสาคร 74000",
      label: "ท่าฉลอม เมืองสมุทรสาคร สมุทรสาคร 74000",
    },
    {
      value: "ท่าทราย เมืองสมุทรสาคร สมุทรสาคร 74000",
      label: "ท่าทราย เมืองสมุทรสาคร สมุทรสาคร 74000",
    },
    {
      value: "นาดี เมืองสมุทรสาคร สมุทรสาคร 74000",
      label: "นาดี เมืองสมุทรสาคร สมุทรสาคร 74000",
    },
    {
      value: "นาโคก เมืองสมุทรสาคร สมุทรสาคร 74000",
      label: "นาโคก เมืองสมุทรสาคร สมุทรสาคร 74000",
    },
    {
      value: "บางกระเจ้า เมืองสมุทรสาคร สมุทรสาคร 74000",
      label: "บางกระเจ้า เมืองสมุทรสาคร สมุทรสาคร 74000",
    },
    {
      value: "บางน้ำจืด เมืองสมุทรสาคร สมุทรสาคร 74000",
      label: "บางน้ำจืด เมืองสมุทรสาคร สมุทรสาคร 74000",
    },
    {
      value: "บางหญ้าแพรก เมืองสมุทรสาคร สมุทรสาคร 74000",
      label: "บางหญ้าแพรก เมืองสมุทรสาคร สมุทรสาคร 74000",
    },
    {
      value: "บางโทรัด เมืองสมุทรสาคร สมุทรสาคร 74000",
      label: "บางโทรัด เมืองสมุทรสาคร สมุทรสาคร 74000",
    },
    {
      value: "บ้านบ่อ เมืองสมุทรสาคร สมุทรสาคร 74000",
      label: "บ้านบ่อ เมืองสมุทรสาคร สมุทรสาคร 74000",
    },
    {
      value: "บ้านเกาะ เมืองสมุทรสาคร สมุทรสาคร 74000",
      label: "บ้านเกาะ เมืองสมุทรสาคร สมุทรสาคร 74000",
    },
    {
      value: "พันท้ายนรสิงห์ เมืองสมุทรสาคร สมุทรสาคร 74000",
      label: "พันท้ายนรสิงห์ เมืองสมุทรสาคร สมุทรสาคร 74000",
    },
    {
      value: "มหาชัย เมืองสมุทรสาคร สมุทรสาคร 74000",
      label: "มหาชัย เมืองสมุทรสาคร สมุทรสาคร 74000",
    },
    {
      value: "โกรกกราก เมืองสมุทรสาคร สมุทรสาคร 74000",
      label: "โกรกกราก เมืองสมุทรสาคร สมุทรสาคร 74000",
    },
    {
      value: "โคกขาม เมืองสมุทรสาคร สมุทรสาคร 74000",
      label: "โคกขาม เมืองสมุทรสาคร สมุทรสาคร 74000",
    },
    {
      value: "ดงตะงาว ดอนพุด สระบุรี 18210",
      label: "ดงตะงาว ดอนพุด สระบุรี 18210",
    },
    {
      value: "ดอนพุด ดอนพุด สระบุรี 18210",
      label: "ดอนพุด ดอนพุด สระบุรี 18210",
    },
    {
      value: "บ้านหลวง ดอนพุด สระบุรี 18210",
      label: "บ้านหลวง ดอนพุด สระบุรี 18210",
    },
    {
      value: "ไผ่หลิ่ว ดอนพุด สระบุรี 18210",
      label: "ไผ่หลิ่ว ดอนพุด สระบุรี 18210",
    },
    {
      value: "ตลาดน้อย บ้านหมอ สระบุรี 18130",
      label: "ตลาดน้อย บ้านหมอ สระบุรี 18130",
    },
    {
      value: "บางโขมด บ้านหมอ สระบุรี 18130",
      label: "บางโขมด บ้านหมอ สระบุรี 18130",
    },
    {
      value: "บ้านครัว บ้านหมอ สระบุรี 18270",
      label: "บ้านครัว บ้านหมอ สระบุรี 18270",
    },
    {
      value: "บ้านหมอ บ้านหมอ สระบุรี 18130",
      label: "บ้านหมอ บ้านหมอ สระบุรี 18130",
    },
    {
      value: "สร่างโศก บ้านหมอ สระบุรี 18130",
      label: "สร่างโศก บ้านหมอ สระบุรี 18130",
    },
    {
      value: "หนองบัว บ้านหมอ สระบุรี 18130",
      label: "หนองบัว บ้านหมอ สระบุรี 18130",
    },
    {
      value: "หรเทพ บ้านหมอ สระบุรี 18130",
      label: "หรเทพ บ้านหมอ สระบุรี 18130",
    },
    {
      value: "โคกใหญ่ บ้านหมอ สระบุรี 18130",
      label: "โคกใหญ่ บ้านหมอ สระบุรี 18130",
    },
    {
      value: "ไผ่ขวาง บ้านหมอ สระบุรี 18130",
      label: "ไผ่ขวาง บ้านหมอ สระบุรี 18130",
    },
    {
      value: "ขุนโขลน พระพุทธบาท สระบุรี 18120",
      label: "ขุนโขลน พระพุทธบาท สระบุรี 18120",
    },
    {
      value: "ธารเกษม พระพุทธบาท สระบุรี 18120",
      label: "ธารเกษม พระพุทธบาท สระบุรี 18120",
    },
    {
      value: "นายาว พระพุทธบาท สระบุรี 18120",
      label: "นายาว พระพุทธบาท สระบุรี 18120",
    },
    {
      value: "พระพุทธบาท พระพุทธบาท สระบุรี 18120",
      label: "พระพุทธบาท พระพุทธบาท สระบุรี 18120",
    },
    {
      value: "พุกร่าง พระพุทธบาท สระบุรี 18120",
      label: "พุกร่าง พระพุทธบาท สระบุรี 18120",
    },
    {
      value: "พุคำจาน พระพุทธบาท สระบุรี 18120",
      label: "พุคำจาน พระพุทธบาท สระบุรี 18120",
    },
    {
      value: "หนองแก พระพุทธบาท สระบุรี 18120",
      label: "หนองแก พระพุทธบาท สระบุรี 18120",
    },
    {
      value: "ห้วยป่าหวาย พระพุทธบาท สระบุรี 18120",
      label: "ห้วยป่าหวาย พระพุทธบาท สระบุรี 18120",
    },
    {
      value: "เขาวง พระพุทธบาท สระบุรี 18120",
      label: "เขาวง พระพุทธบาท สระบุรี 18120",
    },
    {
      value: "ซับสนุ่น มวกเหล็ก สระบุรี 18220",
      label: "ซับสนุ่น มวกเหล็ก สระบุรี 18220",
    },
    {
      value: "มวกเหล็ก มวกเหล็ก สระบุรี 18180",
      label: "มวกเหล็ก มวกเหล็ก สระบุรี 18180",
    },
    {
      value: "มิตรภาพ มวกเหล็ก สระบุรี 18180",
      label: "มิตรภาพ มวกเหล็ก สระบุรี 18180",
    },
    {
      value: "ลำพญากลาง มวกเหล็ก สระบุรี 30130",
      label: "ลำพญากลาง มวกเหล็ก สระบุรี 30130",
    },
    {
      value: "ลำสมพุง มวกเหล็ก สระบุรี 30130",
      label: "ลำสมพุง มวกเหล็ก สระบุรี 30130",
    },
    {
      value: "หนองย่างเสือ มวกเหล็ก สระบุรี 18180",
      label: "หนองย่างเสือ มวกเหล็ก สระบุรี 18180",
    },
    {
      value: "คำพราน วังม่วง สระบุรี 18220",
      label: "คำพราน วังม่วง สระบุรี 18220",
    },
    {
      value: "วังม่วง วังม่วง สระบุรี 18220",
      label: "วังม่วง วังม่วง สระบุรี 18220",
    },
    {
      value: "แสลงพัน วังม่วง สระบุรี 18220",
      label: "แสลงพัน วังม่วง สระบุรี 18220",
    },
    {
      value: "คลองเรือ วิหารแดง สระบุรี 18150",
      label: "คลองเรือ วิหารแดง สระบุรี 18150",
    },
    {
      value: "บ้านลำ วิหารแดง สระบุรี 18150",
      label: "บ้านลำ วิหารแดง สระบุรี 18150",
    },
    {
      value: "วิหารแดง วิหารแดง สระบุรี 18150",
      label: "วิหารแดง วิหารแดง สระบุรี 18150",
    },
    {
      value: "หนองสรวง วิหารแดง สระบุรี 18150",
      label: "หนองสรวง วิหารแดง สระบุรี 18150",
    },
    {
      value: "หนองหมู วิหารแดง สระบุรี 18150",
      label: "หนองหมู วิหารแดง สระบุรี 18150",
    },
    {
      value: "เจริญธรรม วิหารแดง สระบุรี 18150",
      label: "เจริญธรรม วิหารแดง สระบุรี 18150",
    },
    {
      value: "กุ่มหัก หนองแค สระบุรี 18140",
      label: "กุ่มหัก หนองแค สระบุรี 18140",
    },
    {
      value: "คชสิทธิ์ หนองแค สระบุรี 18250",
      label: "คชสิทธิ์ หนองแค สระบุรี 18250",
    },
    {
      value: "บัวลอย หนองแค สระบุรี 18230",
      label: "บัวลอย หนองแค สระบุรี 18230",
    },
    {
      value: "หนองจรเข้ หนองแค สระบุรี 18140",
      label: "หนองจรเข้ หนองแค สระบุรี 18140",
    },
    {
      value: "หนองจิก หนองแค สระบุรี 18230",
      label: "หนองจิก หนองแค สระบุรี 18230",
    },
    {
      value: "หนองนาก หนองแค สระบุรี 18230",
      label: "หนองนาก หนองแค สระบุรี 18230",
    },
    {
      value: "หนองปลาหมอ หนองแค สระบุรี 18140",
      label: "หนองปลาหมอ หนองแค สระบุรี 18140",
    },
    {
      value: "หนองปลิง หนองแค สระบุรี 18140",
      label: "หนองปลิง หนองแค สระบุรี 18140",
    },
    {
      value: "หนองแขม หนองแค สระบุรี 18140",
      label: "หนองแขม หนองแค สระบุรี 18140",
    },
    {
      value: "หนองแค หนองแค สระบุรี 18140",
      label: "หนองแค หนองแค สระบุรี 18140",
    },
    {
      value: "หนองโรง หนองแค สระบุรี 18140",
      label: "หนองโรง หนองแค สระบุรี 18140",
    },
    {
      value: "หนองไข่น้ำ หนองแค สระบุรี 18140",
      label: "หนองไข่น้ำ หนองแค สระบุรี 18140",
    },
    {
      value: "ห้วยขมิ้น หนองแค สระบุรี 18230",
      label: "ห้วยขมิ้น หนองแค สระบุรี 18230",
    },
    {
      value: "ห้วยทราย หนองแค สระบุรี 18230",
      label: "ห้วยทราย หนองแค สระบุรี 18230",
    },
    {
      value: "โคกตูม หนองแค สระบุรี 18250",
      label: "โคกตูม หนองแค สระบุรี 18250",
    },
    {
      value: "โคกแย้ หนองแค สระบุรี 18230",
      label: "โคกแย้ หนองแค สระบุรี 18230",
    },
    {
      value: "โพนทอง หนองแค สระบุรี 18250",
      label: "โพนทอง หนองแค สระบุรี 18250",
    },
    {
      value: "ไผ่ต่ำ หนองแค สระบุรี 18140",
      label: "ไผ่ต่ำ หนองแค สระบุรี 18140",
    },
    {
      value: "ม่วงหวาน หนองแซง สระบุรี 18170",
      label: "ม่วงหวาน หนองแซง สระบุรี 18170",
    },
    {
      value: "หนองกบ หนองแซง สระบุรี 18170",
      label: "หนองกบ หนองแซง สระบุรี 18170",
    },
    {
      value: "หนองควายโซ หนองแซง สระบุรี 18170",
      label: "หนองควายโซ หนองแซง สระบุรี 18170",
    },
    {
      value: "หนองสีดา หนองแซง สระบุรี 18170",
      label: "หนองสีดา หนองแซง สระบุรี 18170",
    },
    {
      value: "หนองหัวโพ หนองแซง สระบุรี 18170",
      label: "หนองหัวโพ หนองแซง สระบุรี 18170",
    },
    {
      value: "หนองแซง หนองแซง สระบุรี 18170",
      label: "หนองแซง หนองแซง สระบุรี 18170",
    },
    {
      value: "เขาดิน หนองแซง สระบุรี 18170",
      label: "เขาดิน หนองแซง สระบุรี 18170",
    },
    {
      value: "โคกสะอาด หนองแซง สระบุรี 18170",
      label: "โคกสะอาด หนองแซง สระบุรี 18170",
    },
    {
      value: "ไก่เส่า หนองแซง สระบุรี 18170",
      label: "ไก่เส่า หนองแซง สระบุรี 18170",
    },
    {
      value: "ดอนทอง หนองโดน สระบุรี 18190",
      label: "ดอนทอง หนองโดน สระบุรี 18190",
    },
    {
      value: "บ้านกลับ หนองโดน สระบุรี 18190",
      label: "บ้านกลับ หนองโดน สระบุรี 18190",
    },
    {
      value: "บ้านโปร่ง หนองโดน สระบุรี 18190",
      label: "บ้านโปร่ง หนองโดน สระบุรี 18190",
    },
    {
      value: "หนองโดน หนองโดน สระบุรี 18190",
      label: "หนองโดน หนองโดน สระบุรี 18190",
    },
    {
      value: "บ้านแก้ง เฉลิมพระเกียรติ สระบุรี 18000",
      label: "บ้านแก้ง เฉลิมพระเกียรติ สระบุรี 18000",
    },
    {
      value: "ผึ้งรวง เฉลิมพระเกียรติ สระบุรี 18000",
      label: "ผึ้งรวง เฉลิมพระเกียรติ สระบุรี 18000",
    },
    {
      value: "พุแค เฉลิมพระเกียรติ สระบุรี 18240",
      label: "พุแค เฉลิมพระเกียรติ สระบุรี 18240",
    },
    {
      value: "หน้าพระลาน เฉลิมพระเกียรติ สระบุรี 18240",
      label: "หน้าพระลาน เฉลิมพระเกียรติ สระบุรี 18240",
    },
    {
      value: "ห้วยบง เฉลิมพระเกียรติ สระบุรี 18000",
      label: "ห้วยบง เฉลิมพระเกียรติ สระบุรี 18000",
    },
    {
      value: "เขาดินพัฒนา เฉลิมพระเกียรติ สระบุรี 18000",
      label: "เขาดินพัฒนา เฉลิมพระเกียรติ สระบุรี 18000",
    },
    {
      value: "กุดนกเปล้า เมืองสระบุรี สระบุรี 18000",
      label: "กุดนกเปล้า เมืองสระบุรี สระบุรี 18000",
    },
    {
      value: "ดาวเรือง เมืองสระบุรี สระบุรี 18000",
      label: "ดาวเรือง เมืองสระบุรี สระบุรี 18000",
    },
    {
      value: "ตลิ่งชัน เมืองสระบุรี สระบุรี 18000",
      label: "ตลิ่งชัน เมืองสระบุรี สระบุรี 18000",
    },
    {
      value: "ตะกุด เมืองสระบุรี สระบุรี 18000",
      label: "ตะกุด เมืองสระบุรี สระบุรี 18000",
    },
    {
      value: "นาโฉง เมืองสระบุรี สระบุรี 18000",
      label: "นาโฉง เมืองสระบุรี สระบุรี 18000",
    },
    {
      value: "ปากข้าวสาร เมืองสระบุรี สระบุรี 18000",
      label: "ปากข้าวสาร เมืองสระบุรี สระบุรี 18000",
    },
    {
      value: "ปากเพรียว เมืองสระบุรี สระบุรี 18000",
      label: "ปากเพรียว เมืองสระบุรี สระบุรี 18000",
    },
    {
      value: "หนองปลาไหล เมืองสระบุรี สระบุรี 18000",
      label: "หนองปลาไหล เมืองสระบุรี สระบุรี 18000",
    },
    {
      value: "หนองยาว เมืองสระบุรี สระบุรี 18000",
      label: "หนองยาว เมืองสระบุรี สระบุรี 18000",
    },
    {
      value: "หนองโน เมืองสระบุรี สระบุรี 18000",
      label: "หนองโน เมืองสระบุรี สระบุรี 18000",
    },
    {
      value: "โคกสว่าง เมืองสระบุรี สระบุรี 18000",
      label: "โคกสว่าง เมืองสระบุรี สระบุรี 18000",
    },
    {
      value: "งิ้วงาม เสาไห้ สระบุรี 18160",
      label: "งิ้วงาม เสาไห้ สระบุรี 18160",
    },
    {
      value: "ต้นตาล เสาไห้ สระบุรี 18160",
      label: "ต้นตาล เสาไห้ สระบุรี 18160",
    },
    {
      value: "ท่าช้าง เสาไห้ สระบุรี 18160",
      label: "ท่าช้าง เสาไห้ สระบุรี 18160",
    },
    {
      value: "บ้านยาง เสาไห้ สระบุรี 18160",
      label: "บ้านยาง เสาไห้ สระบุรี 18160",
    },
    {
      value: "พระยาทด เสาไห้ สระบุรี 18160",
      label: "พระยาทด เสาไห้ สระบุรี 18160",
    },
    {
      value: "ม่วงงาม เสาไห้ สระบุรี 18160",
      label: "ม่วงงาม เสาไห้ สระบุรี 18160",
    },
    {
      value: "ศาลารีไทย เสาไห้ สระบุรี 18160",
      label: "ศาลารีไทย เสาไห้ สระบุรี 18160",
    },
    {
      value: "สวนดอกไม้ เสาไห้ สระบุรี 18160",
      label: "สวนดอกไม้ เสาไห้ สระบุรี 18160",
    },
    {
      value: "หัวปลวก เสาไห้ สระบุรี 18160",
      label: "หัวปลวก เสาไห้ สระบุรี 18160",
    },
    {
      value: "เมืองเก่า เสาไห้ สระบุรี 18160",
      label: "เมืองเก่า เสาไห้ สระบุรี 18160",
    },
    {
      value: "เริงราง เสาไห้ สระบุรี 18160",
      label: "เริงราง เสาไห้ สระบุรี 18160",
    },
    {
      value: "เสาไห้ เสาไห้ สระบุรี 18160",
      label: "เสาไห้ เสาไห้ สระบุรี 18160",
    },
    { value: "ชะอม แก่งคอย สระบุรี 18110", label: "ชะอม แก่งคอย สระบุรี 18110" },
    {
      value: "ชำผักแพว แก่งคอย สระบุรี 18110",
      label: "ชำผักแพว แก่งคอย สระบุรี 18110",
    },
    {
      value: "ตาลเดี่ยว แก่งคอย สระบุรี 18110",
      label: "ตาลเดี่ยว แก่งคอย สระบุรี 18110",
    },
    {
      value: "ทับกวาง แก่งคอย สระบุรี 18260",
      label: "ทับกวาง แก่งคอย สระบุรี 18260",
    },
    {
      value: "ท่าคล้อ แก่งคอย สระบุรี 18110",
      label: "ท่าคล้อ แก่งคอย สระบุรี 18110",
    },
    {
      value: "ท่าตูม แก่งคอย สระบุรี 18110",
      label: "ท่าตูม แก่งคอย สระบุรี 18110",
    },
    {
      value: "ท่ามะปราง แก่งคอย สระบุรี 18110",
      label: "ท่ามะปราง แก่งคอย สระบุรี 18110",
    },
    {
      value: "บ้านธาตุ แก่งคอย สระบุรี 18110",
      label: "บ้านธาตุ แก่งคอย สระบุรี 18110",
    },
    {
      value: "บ้านป่า แก่งคอย สระบุรี 18110",
      label: "บ้านป่า แก่งคอย สระบุรี 18110",
    },
    {
      value: "สองคอน แก่งคอย สระบุรี 18110",
      label: "สองคอน แก่งคอย สระบุรี 18110",
    },
    {
      value: "หินซ้อน แก่งคอย สระบุรี 18110",
      label: "หินซ้อน แก่งคอย สระบุรี 18110",
    },
    {
      value: "ห้วยแห้ง แก่งคอย สระบุรี 18110",
      label: "ห้วยแห้ง แก่งคอย สระบุรี 18110",
    },
    {
      value: "เตาปูน แก่งคอย สระบุรี 18110",
      label: "เตาปูน แก่งคอย สระบุรี 18110",
    },
    {
      value: "แก่งคอย แก่งคอย สระบุรี 18110",
      label: "แก่งคอย แก่งคอย สระบุรี 18110",
    },
    {
      value: "คอทราย ค่ายบางระจัน สิงห์บุรี 16150",
      label: "คอทราย ค่ายบางระจัน สิงห์บุรี 16150",
    },
    {
      value: "ท่าข้าม ค่ายบางระจัน สิงห์บุรี 16150",
      label: "ท่าข้าม ค่ายบางระจัน สิงห์บุรี 16150",
    },
    {
      value: "บางระจัน ค่ายบางระจัน สิงห์บุรี 16150",
      label: "บางระจัน ค่ายบางระจัน สิงห์บุรี 16150",
    },
    {
      value: "หนองกระทุ่ม ค่ายบางระจัน สิงห์บุรี 16150",
      label: "หนองกระทุ่ม ค่ายบางระจัน สิงห์บุรี 16150",
    },
    {
      value: "โพทะเล ค่ายบางระจัน สิงห์บุรี 16150",
      label: "โพทะเล ค่ายบางระจัน สิงห์บุรี 16150",
    },
    {
      value: "โพสังโฆ ค่ายบางระจัน สิงห์บุรี 16150",
      label: "โพสังโฆ ค่ายบางระจัน สิงห์บุรี 16150",
    },
    {
      value: "ถอนสมอ ท่าช้าง สิงห์บุรี 16140",
      label: "ถอนสมอ ท่าช้าง สิงห์บุรี 16140",
    },
    {
      value: "พิกุลทอง ท่าช้าง สิงห์บุรี 16140",
      label: "พิกุลทอง ท่าช้าง สิงห์บุรี 16140",
    },
    {
      value: "วิหารขาว ท่าช้าง สิงห์บุรี 16140",
      label: "วิหารขาว ท่าช้าง สิงห์บุรี 16140",
    },
    {
      value: "โพประจักษ์ ท่าช้าง สิงห์บุรี 16140",
      label: "โพประจักษ์ ท่าช้าง สิงห์บุรี 16140",
    },
    {
      value: "บ้านจ่า บางระจัน สิงห์บุรี 16130",
      label: "บ้านจ่า บางระจัน สิงห์บุรี 16130",
    },
    {
      value: "พักทัน บางระจัน สิงห์บุรี 16130",
      label: "พักทัน บางระจัน สิงห์บุรี 16130",
    },
    {
      value: "สระแจง บางระจัน สิงห์บุรี 16130",
      label: "สระแจง บางระจัน สิงห์บุรี 16130",
    },
    {
      value: "สิงห์ บางระจัน สิงห์บุรี 16130",
      label: "สิงห์ บางระจัน สิงห์บุรี 16130",
    },
    {
      value: "เชิงกลัด บางระจัน สิงห์บุรี 16130",
      label: "เชิงกลัด บางระจัน สิงห์บุรี 16130",
    },
    {
      value: "แม่ลา บางระจัน สิงห์บุรี 16130",
      label: "แม่ลา บางระจัน สิงห์บุรี 16130",
    },
    {
      value: "โพชนไก่ บางระจัน สิงห์บุรี 16130",
      label: "โพชนไก่ บางระจัน สิงห์บุรี 16130",
    },
    {
      value: "ไม้ดัด บางระจัน สิงห์บุรี 16130",
      label: "ไม้ดัด บางระจัน สิงห์บุรี 16130",
    },
    {
      value: "บางน้ำเชี่ยว พรหมบุรี สิงห์บุรี 16120",
      label: "บางน้ำเชี่ยว พรหมบุรี สิงห์บุรี 16120",
    },
    {
      value: "บ้านหม้อ พรหมบุรี สิงห์บุรี 16120",
      label: "บ้านหม้อ พรหมบุรี สิงห์บุรี 16120",
    },
    {
      value: "บ้านแป้ง พรหมบุรี สิงห์บุรี 16120",
      label: "บ้านแป้ง พรหมบุรี สิงห์บุรี 16120",
    },
    {
      value: "พรหมบุรี พรหมบุรี สิงห์บุรี 16160",
      label: "พรหมบุรี พรหมบุรี สิงห์บุรี 16160",
    },
    {
      value: "พระงาม พรหมบุรี สิงห์บุรี 16120",
      label: "พระงาม พรหมบุรี สิงห์บุรี 16120",
    },
    {
      value: "หัวป่า พรหมบุรี สิงห์บุรี 16120",
      label: "หัวป่า พรหมบุรี สิงห์บุรี 16120",
    },
    {
      value: "โรงช้าง พรหมบุรี สิงห์บุรี 16120",
      label: "โรงช้าง พรหมบุรี สิงห์บุรี 16120",
    },
    {
      value: "งิ้วราย อินทร์บุรี สิงห์บุรี 16110",
      label: "งิ้วราย อินทร์บุรี สิงห์บุรี 16110",
    },
    {
      value: "ชีน้ำร้าย อินทร์บุรี สิงห์บุรี 16110",
      label: "ชีน้ำร้าย อินทร์บุรี สิงห์บุรี 16110",
    },
    {
      value: "ทองเอน อินทร์บุรี สิงห์บุรี 16110",
      label: "ทองเอน อินทร์บุรี สิงห์บุรี 16110",
    },
    {
      value: "ทับยา อินทร์บุรี สิงห์บุรี 16110",
      label: "ทับยา อินทร์บุรี สิงห์บุรี 16110",
    },
    {
      value: "ท่างาม อินทร์บุรี สิงห์บุรี 16110",
      label: "ท่างาม อินทร์บุรี สิงห์บุรี 16110",
    },
    {
      value: "น้ำตาล อินทร์บุรี สิงห์บุรี 16110",
      label: "น้ำตาล อินทร์บุรี สิงห์บุรี 16110",
    },
    {
      value: "ประศุก อินทร์บุรี สิงห์บุรี 16110",
      label: "ประศุก อินทร์บุรี สิงห์บุรี 16110",
    },
    {
      value: "ห้วยชัน อินทร์บุรี สิงห์บุรี 16110",
      label: "ห้วยชัน อินทร์บุรี สิงห์บุรี 16110",
    },
    {
      value: "อินทร์บุรี อินทร์บุรี สิงห์บุรี 16110",
      label: "อินทร์บุรี อินทร์บุรี สิงห์บุรี 16110",
    },
    {
      value: "โพธิ์ชัย อินทร์บุรี สิงห์บุรี 16110",
      label: "โพธิ์ชัย อินทร์บุรี สิงห์บุรี 16110",
    },
    {
      value: "จักรสีห์ เมืองสิงห์บุรี สิงห์บุรี 16000",
      label: "จักรสีห์ เมืองสิงห์บุรี สิงห์บุรี 16000",
    },
    {
      value: "ต้นโพธิ์ เมืองสิงห์บุรี สิงห์บุรี 16000",
      label: "ต้นโพธิ์ เมืองสิงห์บุรี สิงห์บุรี 16000",
    },
    {
      value: "บางกระบือ เมืองสิงห์บุรี สิงห์บุรี 16000",
      label: "บางกระบือ เมืองสิงห์บุรี สิงห์บุรี 16000",
    },
    {
      value: "บางพุทรา เมืองสิงห์บุรี สิงห์บุรี 16000",
      label: "บางพุทรา เมืองสิงห์บุรี สิงห์บุรี 16000",
    },
    {
      value: "บางมัญ เมืองสิงห์บุรี สิงห์บุรี 16000",
      label: "บางมัญ เมืองสิงห์บุรี สิงห์บุรี 16000",
    },
    {
      value: "ม่วงหมู่ เมืองสิงห์บุรี สิงห์บุรี 16000",
      label: "ม่วงหมู่ เมืองสิงห์บุรี สิงห์บุรี 16000",
    },
    {
      value: "หัวไผ่ เมืองสิงห์บุรี สิงห์บุรี 16000",
      label: "หัวไผ่ เมืองสิงห์บุรี สิงห์บุรี 16000",
    },
    {
      value: "โพกรวม เมืองสิงห์บุรี สิงห์บุรี 16000",
      label: "โพกรวม เมืองสิงห์บุรี สิงห์บุรี 16000",
    },
    {
      value: "กกแรต กงไกรลาศ สุโขทัย 64170",
      label: "กกแรต กงไกรลาศ สุโขทัย 64170",
    },
    { value: "กง กงไกรลาศ สุโขทัย 64170", label: "กง กงไกรลาศ สุโขทัย 64170" },
    {
      value: "ดงเดือย กงไกรลาศ สุโขทัย 64170",
      label: "ดงเดือย กงไกรลาศ สุโขทัย 64170",
    },
    {
      value: "ท่าฉนวน กงไกรลาศ สุโขทัย 64170",
      label: "ท่าฉนวน กงไกรลาศ สุโขทัย 64170",
    },
    {
      value: "บ้านกร่าง กงไกรลาศ สุโขทัย 64170",
      label: "บ้านกร่าง กงไกรลาศ สุโขทัย 64170",
    },
    {
      value: "บ้านใหม่สุขเกษม กงไกรลาศ สุโขทัย 64170",
      label: "บ้านใหม่สุขเกษม กงไกรลาศ สุโขทัย 64170",
    },
    {
      value: "ป่าแฝก กงไกรลาศ สุโขทัย 64170",
      label: "ป่าแฝก กงไกรลาศ สุโขทัย 64170",
    },
    {
      value: "หนองตูม กงไกรลาศ สุโขทัย 64170",
      label: "หนองตูม กงไกรลาศ สุโขทัย 64170",
    },
    {
      value: "ไกรกลาง กงไกรลาศ สุโขทัย 64170",
      label: "ไกรกลาง กงไกรลาศ สุโขทัย 64170",
    },
    {
      value: "ไกรนอก กงไกรลาศ สุโขทัย 64170",
      label: "ไกรนอก กงไกรลาศ สุโขทัย 64170",
    },
    {
      value: "ไกรใน กงไกรลาศ สุโขทัย 64170",
      label: "ไกรใน กงไกรลาศ สุโขทัย 64170",
    },
    {
      value: "ทุ่งยางเมือง คีรีมาศ สุโขทัย 64160",
      label: "ทุ่งยางเมือง คีรีมาศ สุโขทัย 64160",
    },
    {
      value: "ทุ่งหลวง คีรีมาศ สุโขทัย 64160",
      label: "ทุ่งหลวง คีรีมาศ สุโขทัย 64160",
    },
    {
      value: "นาเชิงคีรี คีรีมาศ สุโขทัย 64160",
      label: "นาเชิงคีรี คีรีมาศ สุโขทัย 64160",
    },
    {
      value: "บ้านน้ำพุ คีรีมาศ สุโขทัย 64160",
      label: "บ้านน้ำพุ คีรีมาศ สุโขทัย 64160",
    },
    {
      value: "บ้านป้อม คีรีมาศ สุโขทัย 64160",
      label: "บ้านป้อม คีรีมาศ สุโขทัย 64160",
    },
    {
      value: "ศรีคีรีมาศ คีรีมาศ สุโขทัย 64160",
      label: "ศรีคีรีมาศ คีรีมาศ สุโขทัย 64160",
    },
    {
      value: "สามพวง คีรีมาศ สุโขทัย 64160",
      label: "สามพวง คีรีมาศ สุโขทัย 64160",
    },
    {
      value: "หนองกระดิ่ง คีรีมาศ สุโขทัย 64160",
      label: "หนองกระดิ่ง คีรีมาศ สุโขทัย 64160",
    },
    {
      value: "หนองจิก คีรีมาศ สุโขทัย 64160",
      label: "หนองจิก คีรีมาศ สุโขทัย 64160",
    },
    { value: "โตนด คีรีมาศ สุโขทัย 64160", label: "โตนด คีรีมาศ สุโขทัย 64160" },
    {
      value: "กลางดง ทุ่งเสลี่ยม สุโขทัย 64150",
      label: "กลางดง ทุ่งเสลี่ยม สุโขทัย 64150",
    },
    {
      value: "ทุ่งเสลี่ยม ทุ่งเสลี่ยม สุโขทัย 64150",
      label: "ทุ่งเสลี่ยม ทุ่งเสลี่ยม สุโขทัย 64150",
    },
    {
      value: "บ้านใหม่ไชยมงคล ทุ่งเสลี่ยม สุโขทัย 64230",
      label: "บ้านใหม่ไชยมงคล ทุ่งเสลี่ยม สุโขทัย 64230",
    },
    {
      value: "เขาแก้วศรีสมบูรณ์ ทุ่งเสลี่ยม สุโขทัย 64230",
      label: "เขาแก้วศรีสมบูรณ์ ทุ่งเสลี่ยม สุโขทัย 64230",
    },
    {
      value: "ไทยชนะศึก ทุ่งเสลี่ยม สุโขทัย 64150",
      label: "ไทยชนะศึก ทุ่งเสลี่ยม สุโขทัย 64150",
    },
    {
      value: "ตลิ่งชัน บ้านด่านลานหอย สุโขทัย 64140",
      label: "ตลิ่งชัน บ้านด่านลานหอย สุโขทัย 64140",
    },
    {
      value: "บ้านด่าน บ้านด่านลานหอย สุโขทัย 64140",
      label: "บ้านด่าน บ้านด่านลานหอย สุโขทัย 64140",
    },
    {
      value: "ลานหอย บ้านด่านลานหอย สุโขทัย 64140",
      label: "ลานหอย บ้านด่านลานหอย สุโขทัย 64140",
    },
    {
      value: "วังตะคร้อ บ้านด่านลานหอย สุโขทัย 64140",
      label: "วังตะคร้อ บ้านด่านลานหอย สุโขทัย 64140",
    },
    {
      value: "วังน้ำขาว บ้านด่านลานหอย สุโขทัย 64140",
      label: "วังน้ำขาว บ้านด่านลานหอย สุโขทัย 64140",
    },
    {
      value: "วังลึก บ้านด่านลานหอย สุโขทัย 64140",
      label: "วังลึก บ้านด่านลานหอย สุโขทัย 64140",
    },
    {
      value: "หนองหญ้าปล้อง บ้านด่านลานหอย สุโขทัย 64140",
      label: "หนองหญ้าปล้อง บ้านด่านลานหอย สุโขทัย 64140",
    },
    {
      value: "คลองมะพลับ ศรีนคร สุโขทัย 64180",
      label: "คลองมะพลับ ศรีนคร สุโขทัย 64180",
    },
    {
      value: "นครเดิฐ ศรีนคร สุโขทัย 64180",
      label: "นครเดิฐ ศรีนคร สุโขทัย 64180",
    },
    {
      value: "น้ำขุม ศรีนคร สุโขทัย 64180",
      label: "น้ำขุม ศรีนคร สุโขทัย 64180",
    },
    {
      value: "ศรีนคร ศรีนคร สุโขทัย 64180",
      label: "ศรีนคร ศรีนคร สุโขทัย 64180",
    },
    {
      value: "หนองบัว ศรีนคร สุโขทัย 64180",
      label: "หนองบัว ศรีนคร สุโขทัย 64180",
    },
    {
      value: "ดงคู่ ศรีสัชนาลัย สุโขทัย 64130",
      label: "ดงคู่ ศรีสัชนาลัย สุโขทัย 64130",
    },
    {
      value: "ท่าชัย ศรีสัชนาลัย สุโขทัย 64190",
      label: "ท่าชัย ศรีสัชนาลัย สุโขทัย 64190",
    },
    {
      value: "บ้านตึก ศรีสัชนาลัย สุโขทัย 64130",
      label: "บ้านตึก ศรีสัชนาลัย สุโขทัย 64130",
    },
    {
      value: "บ้านแก่ง ศรีสัชนาลัย สุโขทัย 64130",
      label: "บ้านแก่ง ศรีสัชนาลัย สุโขทัย 64130",
    },
    {
      value: "ป่างิ้ว ศรีสัชนาลัย สุโขทัย 64130",
      label: "ป่างิ้ว ศรีสัชนาลัย สุโขทัย 64130",
    },
    {
      value: "ศรีสัชนาลัย ศรีสัชนาลัย สุโขทัย 64190",
      label: "ศรีสัชนาลัย ศรีสัชนาลัย สุโขทัย 64190",
    },
    {
      value: "สารจิตร ศรีสัชนาลัย สุโขทัย 64130",
      label: "สารจิตร ศรีสัชนาลัย สุโขทัย 64130",
    },
    {
      value: "หนองอ้อ ศรีสัชนาลัย สุโขทัย 64130",
      label: "หนองอ้อ ศรีสัชนาลัย สุโขทัย 64130",
    },
    {
      value: "หาดเสี้ยว ศรีสัชนาลัย สุโขทัย 64130",
      label: "หาดเสี้ยว ศรีสัชนาลัย สุโขทัย 64130",
    },
    {
      value: "แม่สำ ศรีสัชนาลัย สุโขทัย 64130",
      label: "แม่สำ ศรีสัชนาลัย สุโขทัย 64130",
    },
    {
      value: "แม่สิน ศรีสัชนาลัย สุโขทัย 64130",
      label: "แม่สิน ศรีสัชนาลัย สุโขทัย 64130",
    },
    {
      value: "คลองตาล ศรีสำโรง สุโขทัย 64120",
      label: "คลองตาล ศรีสำโรง สุโขทัย 64120",
    },
    {
      value: "ทับผึ้ง ศรีสำโรง สุโขทัย 64120",
      label: "ทับผึ้ง ศรีสำโรง สุโขทัย 64120",
    },
    {
      value: "นาขุนไกร ศรีสำโรง สุโขทัย 64120",
      label: "นาขุนไกร ศรีสำโรง สุโขทัย 64120",
    },
    {
      value: "บ้านซ่าน ศรีสำโรง สุโขทัย 64120",
      label: "บ้านซ่าน ศรีสำโรง สุโขทัย 64120",
    },
    {
      value: "บ้านนา ศรีสำโรง สุโขทัย 64120",
      label: "บ้านนา ศรีสำโรง สุโขทัย 64120",
    },
    {
      value: "บ้านไร่ ศรีสำโรง สุโขทัย 64120",
      label: "บ้านไร่ ศรีสำโรง สุโขทัย 64120",
    },
    {
      value: "ราวต้นจันทร์ ศรีสำโรง สุโขทัย 64120",
      label: "ราวต้นจันทร์ ศรีสำโรง สุโขทัย 64120",
    },
    {
      value: "วังทอง ศรีสำโรง สุโขทัย 64120",
      label: "วังทอง ศรีสำโรง สุโขทัย 64120",
    },
    {
      value: "วังลึก ศรีสำโรง สุโขทัย 64120",
      label: "วังลึก ศรีสำโรง สุโขทัย 64120",
    },
    {
      value: "วังใหญ่ ศรีสำโรง สุโขทัย 64120",
      label: "วังใหญ่ ศรีสำโรง สุโขทัย 64120",
    },
    {
      value: "วัดเกาะ ศรีสำโรง สุโขทัย 64120",
      label: "วัดเกาะ ศรีสำโรง สุโขทัย 64120",
    },
    {
      value: "สามเรือน ศรีสำโรง สุโขทัย 64120",
      label: "สามเรือน ศรีสำโรง สุโขทัย 64120",
    },
    {
      value: "เกาะตาเลี้ยง ศรีสำโรง สุโขทัย 64120",
      label: "เกาะตาเลี้ยง ศรีสำโรง สุโขทัย 64120",
    },
    {
      value: "คลองกระจง สวรรคโลก สุโขทัย 64110",
      label: "คลองกระจง สวรรคโลก สุโขทัย 64110",
    },
    {
      value: "คลองยาง สวรรคโลก สุโขทัย 64110",
      label: "คลองยาง สวรรคโลก สุโขทัย 64110",
    },
    {
      value: "ท่าทอง สวรรคโลก สุโขทัย 64110",
      label: "ท่าทอง สวรรคโลก สุโขทัย 64110",
    },
    {
      value: "นาทุ่ง สวรรคโลก สุโขทัย 64110",
      label: "นาทุ่ง สวรรคโลก สุโขทัย 64110",
    },
    {
      value: "ปากน้ำ สวรรคโลก สุโขทัย 64110",
      label: "ปากน้ำ สวรรคโลก สุโขทัย 64110",
    },
    {
      value: "ป่ากุมเกาะ สวรรคโลก สุโขทัย 64110",
      label: "ป่ากุมเกาะ สวรรคโลก สุโขทัย 64110",
    },
    {
      value: "ย่านยาว สวรรคโลก สุโขทัย 64110",
      label: "ย่านยาว สวรรคโลก สุโขทัย 64110",
    },
    {
      value: "วังพิณพาทย์ สวรรคโลก สุโขทัย 64110",
      label: "วังพิณพาทย์ สวรรคโลก สุโขทัย 64110",
    },
    {
      value: "วังไม้ขอน สวรรคโลก สุโขทัย 64110",
      label: "วังไม้ขอน สวรรคโลก สุโขทัย 64110",
    },
    {
      value: "หนองกลับ สวรรคโลก สุโขทัย 64110",
      label: "หนองกลับ สวรรคโลก สุโขทัย 64110",
    },
    {
      value: "เมืองบางขลัง สวรรคโลก สุโขทัย 64110",
      label: "เมืองบางขลัง สวรรคโลก สุโขทัย 64110",
    },
    {
      value: "เมืองบางยม สวรรคโลก สุโขทัย 64110",
      label: "เมืองบางยม สวรรคโลก สุโขทัย 64110",
    },
    {
      value: "เมืองสวรรคโลก สวรรคโลก สุโขทัย 64110",
      label: "เมืองสวรรคโลก สวรรคโลก สุโขทัย 64110",
    },
    {
      value: "ในเมือง สวรรคโลก สุโขทัย 64110",
      label: "ในเมือง สวรรคโลก สุโขทัย 64110",
    },
    {
      value: "ตาลเตี้ย เมืองสุโขทัย สุโขทัย 64220",
      label: "ตาลเตี้ย เมืองสุโขทัย สุโขทัย 64220",
    },
    {
      value: "ธานี เมืองสุโขทัย สุโขทัย 64000",
      label: "ธานี เมืองสุโขทัย สุโขทัย 64000",
    },
    {
      value: "บ้านกล้วย เมืองสุโขทัย สุโขทัย 64000",
      label: "บ้านกล้วย เมืองสุโขทัย สุโขทัย 64000",
    },
    {
      value: "บ้านสวน เมืองสุโขทัย สุโขทัย 64220",
      label: "บ้านสวน เมืองสุโขทัย สุโขทัย 64220",
    },
    {
      value: "บ้านหลุม เมืองสุโขทัย สุโขทัย 64000",
      label: "บ้านหลุม เมืองสุโขทัย สุโขทัย 64000",
    },
    {
      value: "ปากพระ เมืองสุโขทัย สุโขทัย 64000",
      label: "ปากพระ เมืองสุโขทัย สุโขทัย 64000",
    },
    {
      value: "ปากแคว เมืองสุโขทัย สุโขทัย 64000",
      label: "ปากแคว เมืองสุโขทัย สุโขทัย 64000",
    },
    {
      value: "ยางซ้าย เมืองสุโขทัย สุโขทัย 64000",
      label: "ยางซ้าย เมืองสุโขทัย สุโขทัย 64000",
    },
    {
      value: "วังทองแดง เมืองสุโขทัย สุโขทัย 64210",
      label: "วังทองแดง เมืองสุโขทัย สุโขทัย 64210",
    },
    {
      value: "เมืองเก่า เมืองสุโขทัย สุโขทัย 64210",
      label: "เมืองเก่า เมืองสุโขทัย สุโขทัย 64210",
    },
    {
      value: "ดอนเจดีย์ ดอนเจดีย์ สุพรรณบุรี 72170",
      label: "ดอนเจดีย์ ดอนเจดีย์ สุพรรณบุรี 72170",
    },
    {
      value: "ทะเลบก ดอนเจดีย์ สุพรรณบุรี 72250",
      label: "ทะเลบก ดอนเจดีย์ สุพรรณบุรี 72250",
    },
    {
      value: "สระกระโจม ดอนเจดีย์ สุพรรณบุรี 72250",
      label: "สระกระโจม ดอนเจดีย์ สุพรรณบุรี 72250",
    },
    {
      value: "หนองสาหร่าย ดอนเจดีย์ สุพรรณบุรี 72170",
      label: "หนองสาหร่าย ดอนเจดีย์ สุพรรณบุรี 72170",
    },
    {
      value: "ไร่รถ ดอนเจดีย์ สุพรรณบุรี 72170",
      label: "ไร่รถ ดอนเจดีย์ สุพรรณบุรี 72170",
    },
    {
      value: "ด่านช้าง ด่านช้าง สุพรรณบุรี 72180",
      label: "ด่านช้าง ด่านช้าง สุพรรณบุรี 72180",
    },
    {
      value: "นิคมกระเสียว ด่านช้าง สุพรรณบุรี 72180",
      label: "นิคมกระเสียว ด่านช้าง สุพรรณบุรี 72180",
    },
    {
      value: "วังคัน ด่านช้าง สุพรรณบุรี 72180",
      label: "วังคัน ด่านช้าง สุพรรณบุรี 72180",
    },
    {
      value: "วังยาว ด่านช้าง สุพรรณบุรี 72180",
      label: "วังยาว ด่านช้าง สุพรรณบุรี 72180",
    },
    {
      value: "หนองมะค่าโมง ด่านช้าง สุพรรณบุรี 72180",
      label: "หนองมะค่าโมง ด่านช้าง สุพรรณบุรี 72180",
    },
    {
      value: "ห้วยขมิ้น ด่านช้าง สุพรรณบุรี 72180",
      label: "ห้วยขมิ้น ด่านช้าง สุพรรณบุรี 72180",
    },
    {
      value: "องค์พระ ด่านช้าง สุพรรณบุรี 72180",
      label: "องค์พระ ด่านช้าง สุพรรณบุรี 72180",
    },
    {
      value: "กฤษณา บางปลาม้า สุพรรณบุรี 72150",
      label: "กฤษณา บางปลาม้า สุพรรณบุรี 72150",
    },
    {
      value: "จรเข้ใหญ่ บางปลาม้า สุพรรณบุรี 72150",
      label: "จรเข้ใหญ่ บางปลาม้า สุพรรณบุรี 72150",
    },
    {
      value: "ตะค่า บางปลาม้า สุพรรณบุรี 72150",
      label: "ตะค่า บางปลาม้า สุพรรณบุรี 72150",
    },
    {
      value: "บางปลาม้า บางปลาม้า สุพรรณบุรี 72150",
      label: "บางปลาม้า บางปลาม้า สุพรรณบุรี 72150",
    },
    {
      value: "บางใหญ่ บางปลาม้า สุพรรณบุรี 72150",
      label: "บางใหญ่ บางปลาม้า สุพรรณบุรี 72150",
    },
    {
      value: "บ้านแหลม บางปลาม้า สุพรรณบุรี 72150",
      label: "บ้านแหลม บางปลาม้า สุพรรณบุรี 72150",
    },
    {
      value: "มะขามล้ม บางปลาม้า สุพรรณบุรี 72150",
      label: "มะขามล้ม บางปลาม้า สุพรรณบุรี 72150",
    },
    {
      value: "วังน้ำเย็น บางปลาม้า สุพรรณบุรี 72150",
      label: "วังน้ำเย็น บางปลาม้า สุพรรณบุรี 72150",
    },
    {
      value: "วัดดาว บางปลาม้า สุพรรณบุรี 72150",
      label: "วัดดาว บางปลาม้า สุพรรณบุรี 72150",
    },
    {
      value: "วัดโบสถ์ บางปลาม้า สุพรรณบุรี 72150",
      label: "วัดโบสถ์ บางปลาม้า สุพรรณบุรี 72150",
    },
    {
      value: "สาลี บางปลาม้า สุพรรณบุรี 72150",
      label: "สาลี บางปลาม้า สุพรรณบุรี 72150",
    },
    {
      value: "องครักษ์ บางปลาม้า สุพรรณบุรี 72150",
      label: "องครักษ์ บางปลาม้า สุพรรณบุรี 72150",
    },
    {
      value: "โคกคราม บางปลาม้า สุพรรณบุรี 72150",
      label: "โคกคราม บางปลาม้า สุพรรณบุรี 72150",
    },
    {
      value: "ไผ่กองดิน บางปลาม้า สุพรรณบุรี 72150",
      label: "ไผ่กองดิน บางปลาม้า สุพรรณบุรี 72150",
    },
    {
      value: "ดอนปรู ศรีประจันต์ สุพรรณบุรี 72140",
      label: "ดอนปรู ศรีประจันต์ สุพรรณบุรี 72140",
    },
    {
      value: "บางงาม ศรีประจันต์ สุพรรณบุรี 72140",
      label: "บางงาม ศรีประจันต์ สุพรรณบุรี 72140",
    },
    {
      value: "บ้านกร่าง ศรีประจันต์ สุพรรณบุรี 72140",
      label: "บ้านกร่าง ศรีประจันต์ สุพรรณบุรี 72140",
    },
    {
      value: "ปลายนา ศรีประจันต์ สุพรรณบุรี 72140",
      label: "ปลายนา ศรีประจันต์ สุพรรณบุรี 72140",
    },
    {
      value: "มดแดง ศรีประจันต์ สุพรรณบุรี 72140",
      label: "มดแดง ศรีประจันต์ สุพรรณบุรี 72140",
    },
    {
      value: "วังน้ำซับ ศรีประจันต์ สุพรรณบุรี 72140",
      label: "วังน้ำซับ ศรีประจันต์ สุพรรณบุรี 72140",
    },
    {
      value: "วังยาง ศรีประจันต์ สุพรรณบุรี 72140",
      label: "วังยาง ศรีประจันต์ สุพรรณบุรี 72140",
    },
    {
      value: "วังหว้า ศรีประจันต์ สุพรรณบุรี 72140",
      label: "วังหว้า ศรีประจันต์ สุพรรณบุรี 72140",
    },
    {
      value: "ศรีประจันต์ ศรีประจันต์ สุพรรณบุรี 72140",
      label: "ศรีประจันต์ ศรีประจันต์ สุพรรณบุรี 72140",
    },
    {
      value: "ดอนมะนาว สองพี่น้อง สุพรรณบุรี 72110",
      label: "ดอนมะนาว สองพี่น้อง สุพรรณบุรี 72110",
    },
    {
      value: "ต้นตาล สองพี่น้อง สุพรรณบุรี 72110",
      label: "ต้นตาล สองพี่น้อง สุพรรณบุรี 72110",
    },
    {
      value: "ทุ่งคอก สองพี่น้อง สุพรรณบุรี 72110",
      label: "ทุ่งคอก สองพี่น้อง สุพรรณบุรี 72110",
    },
    {
      value: "บางตะเคียน สองพี่น้อง สุพรรณบุรี 72110",
      label: "บางตะเคียน สองพี่น้อง สุพรรณบุรี 72110",
    },
    {
      value: "บางตาเถร สองพี่น้อง สุพรรณบุรี 72110",
      label: "บางตาเถร สองพี่น้อง สุพรรณบุรี 72110",
    },
    {
      value: "บางพลับ สองพี่น้อง สุพรรณบุรี 72110",
      label: "บางพลับ สองพี่น้อง สุพรรณบุรี 72110",
    },
    {
      value: "บางเลน สองพี่น้อง สุพรรณบุรี 72110",
      label: "บางเลน สองพี่น้อง สุพรรณบุรี 72110",
    },
    {
      value: "บ่อสุพรรณ สองพี่น้อง สุพรรณบุรี 72190",
      label: "บ่อสุพรรณ สองพี่น้อง สุพรรณบุรี 72190",
    },
    {
      value: "บ้านกุ่ม สองพี่น้อง สุพรรณบุรี 72110",
      label: "บ้านกุ่ม สองพี่น้อง สุพรรณบุรี 72110",
    },
    {
      value: "บ้านช้าง สองพี่น้อง สุพรรณบุรี 72110",
      label: "บ้านช้าง สองพี่น้อง สุพรรณบุรี 72110",
    },
    {
      value: "ศรีสำราญ สองพี่น้อง สุพรรณบุรี 72110",
      label: "ศรีสำราญ สองพี่น้อง สุพรรณบุรี 72110",
    },
    {
      value: "สองพี่น้อง สองพี่น้อง สุพรรณบุรี 72110",
      label: "สองพี่น้อง สองพี่น้อง สุพรรณบุรี 72110",
    },
    {
      value: "หนองบ่อ สองพี่น้อง สุพรรณบุรี 72190",
      label: "หนองบ่อ สองพี่น้อง สุพรรณบุรี 72190",
    },
    {
      value: "หัวโพธิ์ สองพี่น้อง สุพรรณบุรี 72110",
      label: "หัวโพธิ์ สองพี่น้อง สุพรรณบุรี 72110",
    },
    {
      value: "เนินพระปรางค์ สองพี่น้อง สุพรรณบุรี 72110",
      label: "เนินพระปรางค์ สองพี่น้อง สุพรรณบุรี 72110",
    },
    {
      value: "กระเสียว สามชุก สุพรรณบุรี 72130",
      label: "กระเสียว สามชุก สุพรรณบุรี 72130",
    },
    {
      value: "บ้านสระ สามชุก สุพรรณบุรี 72130",
      label: "บ้านสระ สามชุก สุพรรณบุรี 72130",
    },
    {
      value: "ย่านยาว สามชุก สุพรรณบุรี 72130",
      label: "ย่านยาว สามชุก สุพรรณบุรี 72130",
    },
    {
      value: "วังลึก สามชุก สุพรรณบุรี 72130",
      label: "วังลึก สามชุก สุพรรณบุรี 72130",
    },
    {
      value: "สามชุก สามชุก สุพรรณบุรี 72130",
      label: "สามชุก สามชุก สุพรรณบุรี 72130",
    },
    {
      value: "หนองผักนาก สามชุก สุพรรณบุรี 72130",
      label: "หนองผักนาก สามชุก สุพรรณบุรี 72130",
    },
    {
      value: "หนองสะเดา สามชุก สุพรรณบุรี 72130",
      label: "หนองสะเดา สามชุก สุพรรณบุรี 72130",
    },
    {
      value: "ทัพหลวง หนองหญ้าไซ สุพรรณบุรี 72240",
      label: "ทัพหลวง หนองหญ้าไซ สุพรรณบุรี 72240",
    },
    {
      value: "หนองขาม หนองหญ้าไซ สุพรรณบุรี 72240",
      label: "หนองขาม หนองหญ้าไซ สุพรรณบุรี 72240",
    },
    {
      value: "หนองราชวัตร หนองหญ้าไซ สุพรรณบุรี 72240",
      label: "หนองราชวัตร หนองหญ้าไซ สุพรรณบุรี 72240",
    },
    {
      value: "หนองหญ้าไซ หนองหญ้าไซ สุพรรณบุรี 72240",
      label: "หนองหญ้าไซ หนองหญ้าไซ สุพรรณบุรี 72240",
    },
    {
      value: "หนองโพธิ์ หนองหญ้าไซ สุพรรณบุรี 72240",
      label: "หนองโพธิ์ หนองหญ้าไซ สุพรรณบุรี 72240",
    },
    {
      value: "แจงงาม หนองหญ้าไซ สุพรรณบุรี 72240",
      label: "แจงงาม หนองหญ้าไซ สุพรรณบุรี 72240",
    },
    {
      value: "กระจัน อู่ทอง สุพรรณบุรี 72160",
      label: "กระจัน อู่ทอง สุพรรณบุรี 72160",
    },
    {
      value: "จรเข้สามพัน อู่ทอง สุพรรณบุรี 71170",
      label: "จรเข้สามพัน อู่ทอง สุพรรณบุรี 71170",
    },
    {
      value: "ดอนคา อู่ทอง สุพรรณบุรี 72160",
      label: "ดอนคา อู่ทอง สุพรรณบุรี 72160",
    },
    {
      value: "ดอนมะเกลือ อู่ทอง สุพรรณบุรี 72220",
      label: "ดอนมะเกลือ อู่ทอง สุพรรณบุรี 72220",
    },
    {
      value: "บ้านดอน อู่ทอง สุพรรณบุรี 72160",
      label: "บ้านดอน อู่ทอง สุพรรณบุรี 72160",
    },
    {
      value: "บ้านโข้ง อู่ทอง สุพรรณบุรี 72160",
      label: "บ้านโข้ง อู่ทอง สุพรรณบุรี 72160",
    },
    {
      value: "พลับพลาไชย อู่ทอง สุพรรณบุรี 72160",
      label: "พลับพลาไชย อู่ทอง สุพรรณบุรี 72160",
    },
    {
      value: "ยุ้งทะลาย อู่ทอง สุพรรณบุรี 72160",
      label: "ยุ้งทะลาย อู่ทอง สุพรรณบุรี 72160",
    },
    {
      value: "สระพังลาน อู่ทอง สุพรรณบุรี 72220",
      label: "สระพังลาน อู่ทอง สุพรรณบุรี 72220",
    },
    {
      value: "สระยายโสม อู่ทอง สุพรรณบุรี 72220",
      label: "สระยายโสม อู่ทอง สุพรรณบุรี 72220",
    },
    {
      value: "หนองโอ่ง อู่ทอง สุพรรณบุรี 72160",
      label: "หนองโอ่ง อู่ทอง สุพรรณบุรี 72160",
    },
    {
      value: "อู่ทอง อู่ทอง สุพรรณบุรี 72160",
      label: "อู่ทอง อู่ทอง สุพรรณบุรี 72160",
    },
    {
      value: "เจดีย์ อู่ทอง สุพรรณบุรี 72160",
      label: "เจดีย์ อู่ทอง สุพรรณบุรี 72160",
    },
    {
      value: "ทุ่งคลี เดิมบางนางบวช สุพรรณบุรี 72120",
      label: "ทุ่งคลี เดิมบางนางบวช สุพรรณบุรี 72120",
    },
    {
      value: "นางบวช เดิมบางนางบวช สุพรรณบุรี 72120",
      label: "นางบวช เดิมบางนางบวช สุพรรณบุรี 72120",
    },
    {
      value: "บ่อกรุ เดิมบางนางบวช สุพรรณบุรี 72120",
      label: "บ่อกรุ เดิมบางนางบวช สุพรรณบุรี 72120",
    },
    {
      value: "ปากน้ำ เดิมบางนางบวช สุพรรณบุรี 72120",
      label: "ปากน้ำ เดิมบางนางบวช สุพรรณบุรี 72120",
    },
    {
      value: "ป่าสะแก เดิมบางนางบวช สุพรรณบุรี 72120",
      label: "ป่าสะแก เดิมบางนางบวช สุพรรณบุรี 72120",
    },
    {
      value: "ยางนอน เดิมบางนางบวช สุพรรณบุรี 72120",
      label: "ยางนอน เดิมบางนางบวช สุพรรณบุรี 72120",
    },
    {
      value: "วังศรีราช เดิมบางนางบวช สุพรรณบุรี 72120",
      label: "วังศรีราช เดิมบางนางบวช สุพรรณบุรี 72120",
    },
    {
      value: "หนองกระทุ่ม เดิมบางนางบวช สุพรรณบุรี 72120",
      label: "หนองกระทุ่ม เดิมบางนางบวช สุพรรณบุรี 72120",
    },
    {
      value: "หัวนา เดิมบางนางบวช สุพรรณบุรี 72120",
      label: "หัวนา เดิมบางนางบวช สุพรรณบุรี 72120",
    },
    {
      value: "หัวเขา เดิมบางนางบวช สุพรรณบุรี 72120",
      label: "หัวเขา เดิมบางนางบวช สุพรรณบุรี 72120",
    },
    {
      value: "เขาดิน เดิมบางนางบวช สุพรรณบุรี 72120",
      label: "เขาดิน เดิมบางนางบวช สุพรรณบุรี 72120",
    },
    {
      value: "เขาพระ เดิมบางนางบวช สุพรรณบุรี 72120",
      label: "เขาพระ เดิมบางนางบวช สุพรรณบุรี 72120",
    },
    {
      value: "เดิมบาง เดิมบางนางบวช สุพรรณบุรี 72120",
      label: "เดิมบาง เดิมบางนางบวช สุพรรณบุรี 72120",
    },
    {
      value: "โคกช้าง เดิมบางนางบวช สุพรรณบุรี 72120",
      label: "โคกช้าง เดิมบางนางบวช สุพรรณบุรี 72120",
    },
    {
      value: "ดอนกำยาน เมืองสุพรรณบุรี สุพรรณบุรี 72000",
      label: "ดอนกำยาน เมืองสุพรรณบุรี สุพรรณบุรี 72000",
    },
    {
      value: "ดอนตาล เมืองสุพรรณบุรี สุพรรณบุรี 72000",
      label: "ดอนตาล เมืองสุพรรณบุรี สุพรรณบุรี 72000",
    },
    {
      value: "ดอนมะสังข์ เมืองสุพรรณบุรี สุพรรณบุรี 72000",
      label: "ดอนมะสังข์ เมืองสุพรรณบุรี สุพรรณบุรี 72000",
    },
    {
      value: "ดอนโพธิ์ทอง เมืองสุพรรณบุรี สุพรรณบุรี 72000",
      label: "ดอนโพธิ์ทอง เมืองสุพรรณบุรี สุพรรณบุรี 72000",
    },
    {
      value: "ตลิ่งชัน เมืองสุพรรณบุรี สุพรรณบุรี 72230",
      label: "ตลิ่งชัน เมืองสุพรรณบุรี สุพรรณบุรี 72230",
    },
    {
      value: "ทับตีเหล็ก เมืองสุพรรณบุรี สุพรรณบุรี 72000",
      label: "ทับตีเหล็ก เมืองสุพรรณบุรี สุพรรณบุรี 72000",
    },
    {
      value: "ท่าพี่เลี้ยง เมืองสุพรรณบุรี สุพรรณบุรี 72000",
      label: "ท่าพี่เลี้ยง เมืองสุพรรณบุรี สุพรรณบุรี 72000",
    },
    {
      value: "ท่าระหัด เมืองสุพรรณบุรี สุพรรณบุรี 72000",
      label: "ท่าระหัด เมืองสุพรรณบุรี สุพรรณบุรี 72000",
    },
    {
      value: "บางกุ้ง เมืองสุพรรณบุรี สุพรรณบุรี 72210",
      label: "บางกุ้ง เมืองสุพรรณบุรี สุพรรณบุรี 72210",
    },
    {
      value: "บ้านโพธิ์ เมืองสุพรรณบุรี สุพรรณบุรี 72000",
      label: "บ้านโพธิ์ เมืองสุพรรณบุรี สุพรรณบุรี 72000",
    },
    {
      value: "พิหารแดง เมืองสุพรรณบุรี สุพรรณบุรี 72000",
      label: "พิหารแดง เมืองสุพรรณบุรี สุพรรณบุรี 72000",
    },
    {
      value: "รั้วใหญ่ เมืองสุพรรณบุรี สุพรรณบุรี 72000",
      label: "รั้วใหญ่ เมืองสุพรรณบุรี สุพรรณบุรี 72000",
    },
    {
      value: "ศาลาขาว เมืองสุพรรณบุรี สุพรรณบุรี 72210",
      label: "ศาลาขาว เมืองสุพรรณบุรี สุพรรณบุรี 72210",
    },
    {
      value: "สนามคลี เมืองสุพรรณบุรี สุพรรณบุรี 72230",
      label: "สนามคลี เมืองสุพรรณบุรี สุพรรณบุรี 72230",
    },
    {
      value: "สนามชัย เมืองสุพรรณบุรี สุพรรณบุรี 72000",
      label: "สนามชัย เมืองสุพรรณบุรี สุพรรณบุรี 72000",
    },
    {
      value: "สระแก้ว เมืองสุพรรณบุรี สุพรรณบุรี 72230",
      label: "สระแก้ว เมืองสุพรรณบุรี สุพรรณบุรี 72230",
    },
    {
      value: "สวนแตง เมืองสุพรรณบุรี สุพรรณบุรี 72210",
      label: "สวนแตง เมืองสุพรรณบุรี สุพรรณบุรี 72210",
    },
    {
      value: "โคกโคเฒ่า เมืองสุพรรณบุรี สุพรรณบุรี 72000",
      label: "โคกโคเฒ่า เมืองสุพรรณบุรี สุพรรณบุรี 72000",
    },
    {
      value: "โพธิ์พระยา เมืองสุพรรณบุรี สุพรรณบุรี 72000",
      label: "โพธิ์พระยา เมืองสุพรรณบุรี สุพรรณบุรี 72000",
    },
    {
      value: "ไผ่ขวาง เมืองสุพรรณบุรี สุพรรณบุรี 72000",
      label: "ไผ่ขวาง เมืองสุพรรณบุรี สุพรรณบุรี 72000",
    },
    {
      value: "นรสิงห์ ป่าโมก อ่างทอง 14130",
      label: "นรสิงห์ ป่าโมก อ่างทอง 14130",
    },
    {
      value: "บางปลากด ป่าโมก อ่างทอง 14130",
      label: "บางปลากด ป่าโมก อ่างทอง 14130",
    },
    {
      value: "บางเสด็จ ป่าโมก อ่างทอง 14130",
      label: "บางเสด็จ ป่าโมก อ่างทอง 14130",
    },
    {
      value: "ป่าโมก ป่าโมก อ่างทอง 14130",
      label: "ป่าโมก ป่าโมก อ่างทอง 14130",
    },
    {
      value: "สายทอง ป่าโมก อ่างทอง 14130",
      label: "สายทอง ป่าโมก อ่างทอง 14130",
    },
    {
      value: "เอกราช ป่าโมก อ่างทอง 14130",
      label: "เอกราช ป่าโมก อ่างทอง 14130",
    },
    {
      value: "โผงเผง ป่าโมก อ่างทอง 14130",
      label: "โผงเผง ป่าโมก อ่างทอง 14130",
    },
    {
      value: "โรงช้าง ป่าโมก อ่างทอง 14130",
      label: "โรงช้าง ป่าโมก อ่างทอง 14130",
    },
    {
      value: "คลองขนาก วิเศษชัยชาญ อ่างทอง 14110",
      label: "คลองขนาก วิเศษชัยชาญ อ่างทอง 14110",
    },
    {
      value: "ตลาดใหม่ วิเศษชัยชาญ อ่างทอง 14110",
      label: "ตลาดใหม่ วิเศษชัยชาญ อ่างทอง 14110",
    },
    {
      value: "ท่าช้าง วิเศษชัยชาญ อ่างทอง 14110",
      label: "ท่าช้าง วิเศษชัยชาญ อ่างทอง 14110",
    },
    {
      value: "บางจัก วิเศษชัยชาญ อ่างทอง 14110",
      label: "บางจัก วิเศษชัยชาญ อ่างทอง 14110",
    },
    {
      value: "ม่วงเตี้ย วิเศษชัยชาญ อ่างทอง 14110",
      label: "ม่วงเตี้ย วิเศษชัยชาญ อ่างทอง 14110",
    },
    {
      value: "ยี่ล้น วิเศษชัยชาญ อ่างทอง 14110",
      label: "ยี่ล้น วิเศษชัยชาญ อ่างทอง 14110",
    },
    {
      value: "ศาลเจ้าโรงทอง วิเศษชัยชาญ อ่างทอง 14110",
      label: "ศาลเจ้าโรงทอง วิเศษชัยชาญ อ่างทอง 14110",
    },
    {
      value: "สาวร้องไห้ วิเศษชัยชาญ อ่างทอง 14110",
      label: "สาวร้องไห้ วิเศษชัยชาญ อ่างทอง 14110",
    },
    {
      value: "สี่ร้อย วิเศษชัยชาญ อ่างทอง 14110",
      label: "สี่ร้อย วิเศษชัยชาญ อ่างทอง 14110",
    },
    {
      value: "หลักแก้ว วิเศษชัยชาญ อ่างทอง 14110",
      label: "หลักแก้ว วิเศษชัยชาญ อ่างทอง 14110",
    },
    {
      value: "หัวตะพาน วิเศษชัยชาญ อ่างทอง 14110",
      label: "หัวตะพาน วิเศษชัยชาญ อ่างทอง 14110",
    },
    {
      value: "ห้วยคันแหลน วิเศษชัยชาญ อ่างทอง 14110",
      label: "ห้วยคันแหลน วิเศษชัยชาญ อ่างทอง 14110",
    },
    {
      value: "ไผ่จำศีล วิเศษชัยชาญ อ่างทอง 14110",
      label: "ไผ่จำศีล วิเศษชัยชาญ อ่างทอง 14110",
    },
    {
      value: "ไผ่ดำพัฒนา วิเศษชัยชาญ อ่างทอง 14110",
      label: "ไผ่ดำพัฒนา วิเศษชัยชาญ อ่างทอง 14110",
    },
    {
      value: "ไผ่วง วิเศษชัยชาญ อ่างทอง 14110",
      label: "ไผ่วง วิเศษชัยชาญ อ่างทอง 14110",
    },
    {
      value: "มงคลธรรมนิมิต สามโก้ อ่างทอง 14160",
      label: "มงคลธรรมนิมิต สามโก้ อ่างทอง 14160",
    },
    {
      value: "ราษฎรพัฒนา สามโก้ อ่างทอง 14160",
      label: "ราษฎรพัฒนา สามโก้ อ่างทอง 14160",
    },
    {
      value: "สามโก้ สามโก้ อ่างทอง 14160",
      label: "สามโก้ สามโก้ อ่างทอง 14160",
    },
    { value: "อบทม สามโก้ อ่างทอง 14160", label: "อบทม สามโก้ อ่างทอง 14160" },
    {
      value: "โพธิ์ม่วงพันธ์ สามโก้ อ่างทอง 14160",
      label: "โพธิ์ม่วงพันธ์ สามโก้ อ่างทอง 14160",
    },
    {
      value: "คลองวัว เมืองอ่างทอง อ่างทอง 14000",
      label: "คลองวัว เมืองอ่างทอง อ่างทอง 14000",
    },
    {
      value: "จำปาหล่อ เมืองอ่างทอง อ่างทอง 14000",
      label: "จำปาหล่อ เมืองอ่างทอง อ่างทอง 14000",
    },
    {
      value: "ตลาดกรวด เมืองอ่างทอง อ่างทอง 14000",
      label: "ตลาดกรวด เมืองอ่างทอง อ่างทอง 14000",
    },
    {
      value: "ตลาดหลวง เมืองอ่างทอง อ่างทอง 14000",
      label: "ตลาดหลวง เมืองอ่างทอง อ่างทอง 14000",
    },
    {
      value: "บางแก้ว เมืองอ่างทอง อ่างทอง 14000",
      label: "บางแก้ว เมืองอ่างทอง อ่างทอง 14000",
    },
    {
      value: "บ้านรี เมืองอ่างทอง อ่างทอง 14000",
      label: "บ้านรี เมืองอ่างทอง อ่างทอง 14000",
    },
    {
      value: "บ้านอิฐ เมืองอ่างทอง อ่างทอง 14000",
      label: "บ้านอิฐ เมืองอ่างทอง อ่างทอง 14000",
    },
    {
      value: "บ้านแห เมืองอ่างทอง อ่างทอง 14000",
      label: "บ้านแห เมืองอ่างทอง อ่างทอง 14000",
    },
    {
      value: "ป่างิ้ว เมืองอ่างทอง อ่างทอง 14000",
      label: "ป่างิ้ว เมืองอ่างทอง อ่างทอง 14000",
    },
    {
      value: "มหาดไทย เมืองอ่างทอง อ่างทอง 14000",
      label: "มหาดไทย เมืองอ่างทอง อ่างทอง 14000",
    },
    {
      value: "ย่านซื่อ เมืองอ่างทอง อ่างทอง 14000",
      label: "ย่านซื่อ เมืองอ่างทอง อ่างทอง 14000",
    },
    {
      value: "ศาลาแดง เมืองอ่างทอง อ่างทอง 14000",
      label: "ศาลาแดง เมืองอ่างทอง อ่างทอง 14000",
    },
    {
      value: "หัวไผ่ เมืองอ่างทอง อ่างทอง 14000",
      label: "หัวไผ่ เมืองอ่างทอง อ่างทอง 14000",
    },
    {
      value: "โพสะ เมืองอ่างทอง อ่างทอง 14000",
      label: "โพสะ เมืองอ่างทอง อ่างทอง 14000",
    },
    { value: "จำลอง แสวงหา อ่างทอง 14150", label: "จำลอง แสวงหา อ่างทอง 14150" },
    {
      value: "บ้านพราน แสวงหา อ่างทอง 14150",
      label: "บ้านพราน แสวงหา อ่างทอง 14150",
    },
    {
      value: "วังน้ำเย็น แสวงหา อ่างทอง 14150",
      label: "วังน้ำเย็น แสวงหา อ่างทอง 14150",
    },
    {
      value: "ศรีพราน แสวงหา อ่างทอง 14150",
      label: "ศรีพราน แสวงหา อ่างทอง 14150",
    },
    {
      value: "สีบัวทอง แสวงหา อ่างทอง 14150",
      label: "สีบัวทอง แสวงหา อ่างทอง 14150",
    },
    {
      value: "ห้วยไผ่ แสวงหา อ่างทอง 14150",
      label: "ห้วยไผ่ แสวงหา อ่างทอง 14150",
    },
    {
      value: "แสวงหา แสวงหา อ่างทอง 14150",
      label: "แสวงหา แสวงหา อ่างทอง 14150",
    },
    {
      value: "คำหยาด โพธิ์ทอง อ่างทอง 14120",
      label: "คำหยาด โพธิ์ทอง อ่างทอง 14120",
    },
    {
      value: "ทางพระ โพธิ์ทอง อ่างทอง 14120",
      label: "ทางพระ โพธิ์ทอง อ่างทอง 14120",
    },
    {
      value: "บางพลับ โพธิ์ทอง อ่างทอง 14120",
      label: "บางพลับ โพธิ์ทอง อ่างทอง 14120",
    },
    {
      value: "บางระกำ โพธิ์ทอง อ่างทอง 14120",
      label: "บางระกำ โพธิ์ทอง อ่างทอง 14120",
    },
    {
      value: "บางเจ้าฉ่า โพธิ์ทอง อ่างทอง 14120",
      label: "บางเจ้าฉ่า โพธิ์ทอง อ่างทอง 14120",
    },
    {
      value: "บ่อแร่ โพธิ์ทอง อ่างทอง 14120",
      label: "บ่อแร่ โพธิ์ทอง อ่างทอง 14120",
    },
    {
      value: "ยางช้าย โพธิ์ทอง อ่างทอง 14120",
      label: "ยางช้าย โพธิ์ทอง อ่างทอง 14120",
    },
    {
      value: "รำมะสัก โพธิ์ทอง อ่างทอง 14120",
      label: "รำมะสัก โพธิ์ทอง อ่างทอง 14120",
    },
    {
      value: "สามง่าม โพธิ์ทอง อ่างทอง 14120",
      label: "สามง่าม โพธิ์ทอง อ่างทอง 14120",
    },
    {
      value: "หนองแม่ไก่ โพธิ์ทอง อ่างทอง 14120",
      label: "หนองแม่ไก่ โพธิ์ทอง อ่างทอง 14120",
    },
    {
      value: "องครักษ์ โพธิ์ทอง อ่างทอง 14120",
      label: "องครักษ์ โพธิ์ทอง อ่างทอง 14120",
    },
    {
      value: "อินทประมูล โพธิ์ทอง อ่างทอง 14120",
      label: "อินทประมูล โพธิ์ทอง อ่างทอง 14120",
    },
    {
      value: "อ่างแก้ว โพธิ์ทอง อ่างทอง 14120",
      label: "อ่างแก้ว โพธิ์ทอง อ่างทอง 14120",
    },
    {
      value: "โคกพุทรา โพธิ์ทอง อ่างทอง 14120",
      label: "โคกพุทรา โพธิ์ทอง อ่างทอง 14120",
    },
    {
      value: "โพธิ์รังนก โพธิ์ทอง อ่างทอง 14120",
      label: "โพธิ์รังนก โพธิ์ทอง อ่างทอง 14120",
    },
    {
      value: "จรเข้ร้อง ไชโย อ่างทอง 14140",
      label: "จรเข้ร้อง ไชโย อ่างทอง 14140",
    },
    { value: "ชะไว ไชโย อ่างทอง 14140", label: "ชะไว ไชโย อ่างทอง 14140" },
    {
      value: "ชัยฤทธิ์ ไชโย อ่างทอง 14140",
      label: "ชัยฤทธิ์ ไชโย อ่างทอง 14140",
    },
    {
      value: "ตรีณรงค์ ไชโย อ่างทอง 14140",
      label: "ตรีณรงค์ ไชโย อ่างทอง 14140",
    },
    {
      value: "ราชสถิตย์ ไชโย อ่างทอง 14140",
      label: "ราชสถิตย์ ไชโย อ่างทอง 14140",
    },
    { value: "หลักฟ้า ไชโย อ่างทอง 14140", label: "หลักฟ้า ไชโย อ่างทอง 14140" },
    { value: "เทวราช ไชโย อ่างทอง 14140", label: "เทวราช ไชโย อ่างทอง 14140" },
    { value: "ไชยภูมิ ไชโย อ่างทอง 14140", label: "ไชยภูมิ ไชโย อ่างทอง 14140" },
    { value: "ไชโย ไชโย อ่างทอง 14140", label: "ไชโย ไชโย อ่างทอง 14140" },
    {
      value: "ตลุกดู่ ทัพทัน อุทัยธานี 61120",
      label: "ตลุกดู่ ทัพทัน อุทัยธานี 61120",
    },
    {
      value: "ทัพทัน ทัพทัน อุทัยธานี 61120",
      label: "ทัพทัน ทัพทัน อุทัยธานี 61120",
    },
    {
      value: "ทุ่งนาไทย ทัพทัน อุทัยธานี 61120",
      label: "ทุ่งนาไทย ทัพทัน อุทัยธานี 61120",
    },
    {
      value: "หนองกระทุ่ม ทัพทัน อุทัยธานี 61120",
      label: "หนองกระทุ่ม ทัพทัน อุทัยธานี 61120",
    },
    {
      value: "หนองกลางดง ทัพทัน อุทัยธานี 61120",
      label: "หนองกลางดง ทัพทัน อุทัยธานี 61120",
    },
    {
      value: "หนองยายดา ทัพทัน อุทัยธานี 61120",
      label: "หนองยายดา ทัพทัน อุทัยธานี 61120",
    },
    {
      value: "หนองสระ ทัพทัน อุทัยธานี 61120",
      label: "หนองสระ ทัพทัน อุทัยธานี 61120",
    },
    {
      value: "หนองหญ้าปล้อง ทัพทัน อุทัยธานี 61120",
      label: "หนองหญ้าปล้อง ทัพทัน อุทัยธานี 61120",
    },
    {
      value: "เขาขี้ฝอย ทัพทัน อุทัยธานี 61120",
      label: "เขาขี้ฝอย ทัพทัน อุทัยธานี 61120",
    },
    {
      value: "โคกหม้อ ทัพทัน อุทัยธานี 61120",
      label: "โคกหม้อ ทัพทัน อุทัยธานี 61120",
    },
    {
      value: "คอกควาย บ้านไร่ อุทัยธานี 61140",
      label: "คอกควาย บ้านไร่ อุทัยธานี 61140",
    },
    {
      value: "ทัพหลวง บ้านไร่ อุทัยธานี 61140",
      label: "ทัพหลวง บ้านไร่ อุทัยธานี 61140",
    },
    {
      value: "บ้านบึง บ้านไร่ อุทัยธานี 61140",
      label: "บ้านบึง บ้านไร่ อุทัยธานี 61140",
    },
    {
      value: "บ้านใหม่คลองเคียน บ้านไร่ อุทัยธานี 61180",
      label: "บ้านใหม่คลองเคียน บ้านไร่ อุทัยธานี 61180",
    },
    {
      value: "บ้านไร่ บ้านไร่ อุทัยธานี 61140",
      label: "บ้านไร่ บ้านไร่ อุทัยธานี 61140",
    },
    {
      value: "วังหิน บ้านไร่ อุทัยธานี 61180",
      label: "วังหิน บ้านไร่ อุทัยธานี 61180",
    },
    {
      value: "หนองจอก บ้านไร่ อุทัยธานี 61180",
      label: "หนองจอก บ้านไร่ อุทัยธานี 61180",
    },
    {
      value: "หนองบ่มกล้วย บ้านไร่ อุทัยธานี 61180",
      label: "หนองบ่มกล้วย บ้านไร่ อุทัยธานี 61180",
    },
    {
      value: "หูช้าง บ้านไร่ อุทัยธานี 61180",
      label: "หูช้าง บ้านไร่ อุทัยธานี 61180",
    },
    {
      value: "ห้วยแห้ง บ้านไร่ อุทัยธานี 61140",
      label: "ห้วยแห้ง บ้านไร่ อุทัยธานี 61140",
    },
    {
      value: "เจ้าวัด บ้านไร่ อุทัยธานี 61150",
      label: "เจ้าวัด บ้านไร่ อุทัยธานี 61150",
    },
    {
      value: "เมืองการุ้ง บ้านไร่ อุทัยธานี 61180",
      label: "เมืองการุ้ง บ้านไร่ อุทัยธานี 61180",
    },
    {
      value: "แก่นมะกรูด บ้านไร่ อุทัยธานี 61140",
      label: "แก่นมะกรูด บ้านไร่ อุทัยธานี 61140",
    },
    {
      value: "ทุ่งนางาม ลานสัก อุทัยธานี 61160",
      label: "ทุ่งนางาม ลานสัก อุทัยธานี 61160",
    },
    {
      value: "น้ำรอบ ลานสัก อุทัยธานี 61160",
      label: "น้ำรอบ ลานสัก อุทัยธานี 61160",
    },
    {
      value: "ประดู่ยืน ลานสัก อุทัยธานี 61160",
      label: "ประดู่ยืน ลานสัก อุทัยธานี 61160",
    },
    {
      value: "ป่าอ้อ ลานสัก อุทัยธานี 61160",
      label: "ป่าอ้อ ลานสัก อุทัยธานี 61160",
    },
    {
      value: "ระบำ ลานสัก อุทัยธานี 61160",
      label: "ระบำ ลานสัก อุทัยธานี 61160",
    },
    {
      value: "ลานสัก ลานสัก อุทัยธานี 61160",
      label: "ลานสัก ลานสัก อุทัยธานี 61160",
    },
    {
      value: "บ่อยาง สว่างอารมณ์ อุทัยธานี 61150",
      label: "บ่อยาง สว่างอารมณ์ อุทัยธานี 61150",
    },
    {
      value: "พลวงสองนาง สว่างอารมณ์ อุทัยธานี 61150",
      label: "พลวงสองนาง สว่างอารมณ์ อุทัยธานี 61150",
    },
    {
      value: "สว่างอารมณ์ สว่างอารมณ์ อุทัยธานี 61150",
      label: "สว่างอารมณ์ สว่างอารมณ์ อุทัยธานี 61150",
    },
    {
      value: "หนองหลวง สว่างอารมณ์ อุทัยธานี 61150",
      label: "หนองหลวง สว่างอารมณ์ อุทัยธานี 61150",
    },
    {
      value: "ไผ่เขียว สว่างอารมณ์ อุทัยธานี 61150",
      label: "ไผ่เขียว สว่างอารมณ์ อุทัยธานี 61150",
    },
    {
      value: "ดงขวาง หนองขาหย่าง อุทัยธานี 61130",
      label: "ดงขวาง หนองขาหย่าง อุทัยธานี 61130",
    },
    {
      value: "ดอนกลอย หนองขาหย่าง อุทัยธานี 61130",
      label: "ดอนกลอย หนองขาหย่าง อุทัยธานี 61130",
    },
    {
      value: "ทุ่งพึ่ง หนองขาหย่าง อุทัยธานี 61130",
      label: "ทุ่งพึ่ง หนองขาหย่าง อุทัยธานี 61130",
    },
    {
      value: "ท่าโพ หนองขาหย่าง อุทัยธานี 61130",
      label: "ท่าโพ หนองขาหย่าง อุทัยธานี 61130",
    },
    {
      value: "หนองขาหย่าง หนองขาหย่าง อุทัยธานี 61130",
      label: "หนองขาหย่าง หนองขาหย่าง อุทัยธานี 61130",
    },
    {
      value: "หนองไผ่ หนองขาหย่าง อุทัยธานี 61130",
      label: "หนองไผ่ หนองขาหย่าง อุทัยธานี 61130",
    },
    {
      value: "หมกแถว หนองขาหย่าง อุทัยธานี 61130",
      label: "หมกแถว หนองขาหย่าง อุทัยธานี 61130",
    },
    {
      value: "หลุมเข้า หนองขาหย่าง อุทัยธานี 61130",
      label: "หลุมเข้า หนองขาหย่าง อุทัยธานี 61130",
    },
    {
      value: "ห้วยรอบ หนองขาหย่าง อุทัยธานี 61130",
      label: "ห้วยรอบ หนองขาหย่าง อุทัยธานี 61130",
    },
    {
      value: "ทุ่งพง หนองฉาง อุทัยธานี 61110",
      label: "ทุ่งพง หนองฉาง อุทัยธานี 61110",
    },
    {
      value: "ทุ่งโพ หนองฉาง อุทัยธานี 61110",
      label: "ทุ่งโพ หนองฉาง อุทัยธานี 61110",
    },
    {
      value: "บ้านเก่า หนองฉาง อุทัยธานี 61110",
      label: "บ้านเก่า หนองฉาง อุทัยธานี 61110",
    },
    {
      value: "หนองฉาง หนองฉาง อุทัยธานี 61110",
      label: "หนองฉาง หนองฉาง อุทัยธานี 61110",
    },
    {
      value: "หนองนางนวล หนองฉาง อุทัยธานี 61110",
      label: "หนองนางนวล หนองฉาง อุทัยธานี 61110",
    },
    {
      value: "หนองยาง หนองฉาง อุทัยธานี 61110",
      label: "หนองยาง หนองฉาง อุทัยธานี 61110",
    },
    {
      value: "หนองสรวง หนองฉาง อุทัยธานี 61110",
      label: "หนองสรวง หนองฉาง อุทัยธานี 61110",
    },
    {
      value: "อุทัยเก่า หนองฉาง อุทัยธานี 61110",
      label: "อุทัยเก่า หนองฉาง อุทัยธานี 61110",
    },
    {
      value: "เขากวางทอง หนองฉาง อุทัยธานี 61110",
      label: "เขากวางทอง หนองฉาง อุทัยธานี 61110",
    },
    {
      value: "เขาบางแกรก หนองฉาง อุทัยธานี 61170",
      label: "เขาบางแกรก หนองฉาง อุทัยธานี 61170",
    },
    {
      value: "ทองหลาง ห้วยคต อุทัยธานี 61170",
      label: "ทองหลาง ห้วยคต อุทัยธานี 61170",
    },
    {
      value: "สุขฤทัย ห้วยคต อุทัยธานี 61170",
      label: "สุขฤทัย ห้วยคต อุทัยธานี 61170",
    },
    {
      value: "ห้วยคต ห้วยคต อุทัยธานี 61170",
      label: "ห้วยคต ห้วยคต อุทัยธานี 61170",
    },
    {
      value: "ดอนขวาง เมืองอุทัยธานี อุทัยธานี 61000",
      label: "ดอนขวาง เมืองอุทัยธานี อุทัยธานี 61000",
    },
    {
      value: "ทุ่งใหญ่ เมืองอุทัยธานี อุทัยธานี 61000",
      label: "ทุ่งใหญ่ เมืองอุทัยธานี อุทัยธานี 61000",
    },
    {
      value: "ท่าซุง เมืองอุทัยธานี อุทัยธานี 61000",
      label: "ท่าซุง เมืองอุทัยธานี อุทัยธานี 61000",
    },
    {
      value: "น้ำซึม เมืองอุทัยธานี อุทัยธานี 61000",
      label: "น้ำซึม เมืองอุทัยธานี อุทัยธานี 61000",
    },
    {
      value: "สะแกกรัง เมืองอุทัยธานี อุทัยธานี 61000",
      label: "สะแกกรัง เมืองอุทัยธานี อุทัยธานี 61000",
    },
    {
      value: "หนองพังค่า เมืองอุทัยธานี อุทัยธานี 61000",
      label: "หนองพังค่า เมืองอุทัยธานี อุทัยธานี 61000",
    },
    {
      value: "หนองเต่า เมืองอุทัยธานี อุทัยธานี 61000",
      label: "หนองเต่า เมืองอุทัยธานี อุทัยธานี 61000",
    },
    {
      value: "หนองแก เมืองอุทัยธานี อุทัยธานี 61000",
      label: "หนองแก เมืองอุทัยธานี อุทัยธานี 61000",
    },
    {
      value: "หนองไผ่แบน เมืองอุทัยธานี อุทัยธานี 61000",
      label: "หนองไผ่แบน เมืองอุทัยธานี อุทัยธานี 61000",
    },
    {
      value: "หาดทนง เมืองอุทัยธานี อุทัยธานี 61000",
      label: "หาดทนง เมืองอุทัยธานี อุทัยธานี 61000",
    },
    {
      value: "อุทัยใหม่ เมืองอุทัยธานี อุทัยธานี 61000",
      label: "อุทัยใหม่ เมืองอุทัยธานี อุทัยธานี 61000",
    },
    {
      value: "เกาะเทโพ เมืองอุทัยธานี อุทัยธานี 61000",
      label: "เกาะเทโพ เมืองอุทัยธานี อุทัยธานี 61000",
    },
    {
      value: "เนินแจง เมืองอุทัยธานี อุทัยธานี 61000",
      label: "เนินแจง เมืองอุทัยธานี อุทัยธานี 61000",
    },
    {
      value: "โนนเหล็ก เมืองอุทัยธานี อุทัยธานี 61000",
      label: "โนนเหล็ก เมืองอุทัยธานี อุทัยธานี 61000",
    },
    { value: "ขลุง ขลุง จันทบุรี 22110", label: "ขลุง ขลุง จันทบุรี 22110" },
    { value: "ซึ้ง ขลุง จันทบุรี 22110", label: "ซึ้ง ขลุง จันทบุรี 22110" },
    { value: "ตกพรม ขลุง จันทบุรี 22110", label: "ตกพรม ขลุง จันทบุรี 22110" },
    {
      value: "ตรอกนอง ขลุง จันทบุรี 22110",
      label: "ตรอกนอง ขลุง จันทบุรี 22110",
    },
    { value: "ตะปอน ขลุง จันทบุรี 22110", label: "ตะปอน ขลุง จันทบุรี 22110" },
    { value: "บางชัน ขลุง จันทบุรี 22110", label: "บางชัน ขลุง จันทบุรี 22110" },
    { value: "บ่อ ขลุง จันทบุรี 22110", label: "บ่อ ขลุง จันทบุรี 22110" },
    {
      value: "บ่อเวฬุ ขลุง จันทบุรี 22150",
      label: "บ่อเวฬุ ขลุง จันทบุรี 22150",
    },
    { value: "มาบไพ ขลุง จันทบุรี 22110", label: "มาบไพ ขลุง จันทบุรี 22110" },
    {
      value: "วังสรรพรส ขลุง จันทบุรี 22110",
      label: "วังสรรพรส ขลุง จันทบุรี 22110",
    },
    { value: "วันยาว ขลุง จันทบุรี 22110", label: "วันยาว ขลุง จันทบุรี 22110" },
    {
      value: "เกวียนหัก ขลุง จันทบุรี 22110",
      label: "เกวียนหัก ขลุง จันทบุรี 22110",
    },
    {
      value: "คลองขุด ท่าใหม่ จันทบุรี 22120",
      label: "คลองขุด ท่าใหม่ จันทบุรี 22120",
    },
    {
      value: "ตะกาดเง้า ท่าใหม่ จันทบุรี 22120",
      label: "ตะกาดเง้า ท่าใหม่ จันทบุรี 22120",
    },
    {
      value: "ทุ่งเบญจา ท่าใหม่ จันทบุรี 22170",
      label: "ทุ่งเบญจา ท่าใหม่ จันทบุรี 22170",
    },
    {
      value: "ท่าใหม่ ท่าใหม่ จันทบุรี 22120",
      label: "ท่าใหม่ ท่าใหม่ จันทบุรี 22120",
    },
    {
      value: "บ่อพุ ท่าใหม่ จันทบุรี 22120",
      label: "บ่อพุ ท่าใหม่ จันทบุรี 22120",
    },
    {
      value: "พลอยแหวน ท่าใหม่ จันทบุรี 22120",
      label: "พลอยแหวน ท่าใหม่ จันทบุรี 22120",
    },
    {
      value: "ยายร้า ท่าใหม่ จันทบุรี 22120",
      label: "ยายร้า ท่าใหม่ จันทบุรี 22120",
    },
    {
      value: "รำพัน ท่าใหม่ จันทบุรี 22170",
      label: "รำพัน ท่าใหม่ จันทบุรี 22170",
    },
    {
      value: "สองพี่น้อง ท่าใหม่ จันทบุรี 22120",
      label: "สองพี่น้อง ท่าใหม่ จันทบุรี 22120",
    },
    {
      value: "สีพยา ท่าใหม่ จันทบุรี 22120",
      label: "สีพยา ท่าใหม่ จันทบุรี 22120",
    },
    {
      value: "เขาบายศรี ท่าใหม่ จันทบุรี 22120",
      label: "เขาบายศรี ท่าใหม่ จันทบุรี 22120",
    },
    {
      value: "เขาวัว ท่าใหม่ จันทบุรี 22120",
      label: "เขาวัว ท่าใหม่ จันทบุรี 22120",
    },
    {
      value: "เขาแก้ว ท่าใหม่ จันทบุรี 22170",
      label: "เขาแก้ว ท่าใหม่ จันทบุรี 22170",
    },
    {
      value: "โขมง ท่าใหม่ จันทบุรี 22170",
      label: "โขมง ท่าใหม่ จันทบุรี 22170",
    },
    {
      value: "กระแจะ นายายอาม จันทบุรี 22170",
      label: "กระแจะ นายายอาม จันทบุรี 22170",
    },
    {
      value: "ช้างข้าม นายายอาม จันทบุรี 22160",
      label: "ช้างข้าม นายายอาม จันทบุรี 22160",
    },
    {
      value: "นายายอาม นายายอาม จันทบุรี 22160",
      label: "นายายอาม นายายอาม จันทบุรี 22160",
    },
    {
      value: "วังโตนด นายายอาม จันทบุรี 22170",
      label: "วังโตนด นายายอาม จันทบุรี 22170",
    },
    {
      value: "วังใหม่ นายายอาม จันทบุรี 22170",
      label: "วังใหม่ นายายอาม จันทบุรี 22170",
    },
    {
      value: "สนามไชย นายายอาม จันทบุรี 22170",
      label: "สนามไชย นายายอาม จันทบุรี 22170",
    },
    { value: "ฉมัน มะขาม จันทบุรี 22150", label: "ฉมัน มะขาม จันทบุรี 22150" },
    {
      value: "ท่าหลวง มะขาม จันทบุรี 22150",
      label: "ท่าหลวง มะขาม จันทบุรี 22150",
    },
    { value: "ปัถวี มะขาม จันทบุรี 22150", label: "ปัถวี มะขาม จันทบุรี 22150" },
    { value: "มะขาม มะขาม จันทบุรี 22150", label: "มะขาม มะขาม จันทบุรี 22150" },
    {
      value: "วังแซ้ม มะขาม จันทบุรี 22150",
      label: "วังแซ้ม มะขาม จันทบุรี 22150",
    },
    {
      value: "อ่างคีรี มะขาม จันทบุรี 22150",
      label: "อ่างคีรี มะขาม จันทบุรี 22150",
    },
    {
      value: "ทรายขาว สอยดาว จันทบุรี 22180",
      label: "ทรายขาว สอยดาว จันทบุรี 22180",
    },
    {
      value: "ทับช้าง สอยดาว จันทบุรี 22180",
      label: "ทับช้าง สอยดาว จันทบุรี 22180",
    },
    {
      value: "ทุ่งขนาน สอยดาว จันทบุรี 22180",
      label: "ทุ่งขนาน สอยดาว จันทบุรี 22180",
    },
    { value: "ปะตง สอยดาว จันทบุรี 22180", label: "ปะตง สอยดาว จันทบุรี 22180" },
    {
      value: "สะตอน สอยดาว จันทบุรี 22180",
      label: "สะตอน สอยดาว จันทบุรี 22180",
    },
    {
      value: "คลองพลู เขาคิชฌกูฏ จันทบุรี 22210",
      label: "คลองพลู เขาคิชฌกูฏ จันทบุรี 22210",
    },
    {
      value: "จันทเขลม เขาคิชฌกูฏ จันทบุรี 22210",
      label: "จันทเขลม เขาคิชฌกูฏ จันทบุรี 22210",
    },
    {
      value: "ชากไทย เขาคิชฌกูฏ จันทบุรี 22210",
      label: "ชากไทย เขาคิชฌกูฏ จันทบุรี 22210",
    },
    {
      value: "ตะเคียนทอง เขาคิชฌกูฏ จันทบุรี 22210",
      label: "ตะเคียนทอง เขาคิชฌกูฏ จันทบุรี 22210",
    },
    {
      value: "พลวง เขาคิชฌกูฏ จันทบุรี 22210",
      label: "พลวง เขาคิชฌกูฏ จันทบุรี 22210",
    },
    {
      value: "คมบาง เมืองจันทบุรี จันทบุรี 22000",
      label: "คมบาง เมืองจันทบุรี จันทบุรี 22000",
    },
    {
      value: "คลองนารายณ์ เมืองจันทบุรี จันทบุรี 22000",
      label: "คลองนารายณ์ เมืองจันทบุรี จันทบุรี 22000",
    },
    {
      value: "จันทนิมิต เมืองจันทบุรี จันทบุรี 22000",
      label: "จันทนิมิต เมืองจันทบุรี จันทบุรี 22000",
    },
    {
      value: "ตลาด เมืองจันทบุรี จันทบุรี 22000",
      label: "ตลาด เมืองจันทบุรี จันทบุรี 22000",
    },
    {
      value: "ท่าช้าง เมืองจันทบุรี จันทบุรี 22000",
      label: "ท่าช้าง เมืองจันทบุรี จันทบุรี 22000",
    },
    {
      value: "บางกะจะ เมืองจันทบุรี จันทบุรี 22000",
      label: "บางกะจะ เมืองจันทบุรี จันทบุรี 22000",
    },
    {
      value: "พลับพลา เมืองจันทบุรี จันทบุรี 22000",
      label: "พลับพลา เมืองจันทบุรี จันทบุรี 22000",
    },
    {
      value: "วัดใหม่ เมืองจันทบุรี จันทบุรี 22000",
      label: "วัดใหม่ เมืองจันทบุรี จันทบุรี 22000",
    },
    {
      value: "หนองบัว เมืองจันทบุรี จันทบุรี 22000",
      label: "หนองบัว เมืองจันทบุรี จันทบุรี 22000",
    },
    {
      value: "เกาะขวาง เมืองจันทบุรี จันทบุรี 22000",
      label: "เกาะขวาง เมืองจันทบุรี จันทบุรี 22000",
    },
    {
      value: "แสลง เมืองจันทบุรี จันทบุรี 22000",
      label: "แสลง เมืองจันทบุรี จันทบุรี 22000",
    },
    {
      value: "ขุนซ่อง แก่งหางแมว จันทบุรี 22160",
      label: "ขุนซ่อง แก่งหางแมว จันทบุรี 22160",
    },
    {
      value: "พวา แก่งหางแมว จันทบุรี 22160",
      label: "พวา แก่งหางแมว จันทบุรี 22160",
    },
    {
      value: "สามพี่น้อง แก่งหางแมว จันทบุรี 22160",
      label: "สามพี่น้อง แก่งหางแมว จันทบุรี 22160",
    },
    {
      value: "เขาวงกต แก่งหางแมว จันทบุรี 22160",
      label: "เขาวงกต แก่งหางแมว จันทบุรี 22160",
    },
    {
      value: "แก่งหางแมว แก่งหางแมว จันทบุรี 22160",
      label: "แก่งหางแมว แก่งหางแมว จันทบุรี 22160",
    },
    {
      value: "คลองน้ำเค็ม แหลมสิงห์ จันทบุรี 22190",
      label: "คลองน้ำเค็ม แหลมสิงห์ จันทบุรี 22190",
    },
    {
      value: "บางกะไชย แหลมสิงห์ จันทบุรี 22120",
      label: "บางกะไชย แหลมสิงห์ จันทบุรี 22120",
    },
    {
      value: "บางสระเก้า แหลมสิงห์ จันทบุรี 22190",
      label: "บางสระเก้า แหลมสิงห์ จันทบุรี 22190",
    },
    {
      value: "ปากน้ำแหลมสิงห์ แหลมสิงห์ จันทบุรี 22130",
      label: "ปากน้ำแหลมสิงห์ แหลมสิงห์ จันทบุรี 22130",
    },
    {
      value: "พลิ้ว แหลมสิงห์ จันทบุรี 22190",
      label: "พลิ้ว แหลมสิงห์ จันทบุรี 22190",
    },
    {
      value: "หนองชิ่ม แหลมสิงห์ จันทบุรี 22130",
      label: "หนองชิ่ม แหลมสิงห์ จันทบุรี 22130",
    },
    {
      value: "เกาะเปริด แหลมสิงห์ จันทบุรี 22130",
      label: "เกาะเปริด แหลมสิงห์ จันทบุรี 22130",
    },
    {
      value: "คลองใหญ่ โป่งน้ำร้อน จันทบุรี 22140",
      label: "คลองใหญ่ โป่งน้ำร้อน จันทบุรี 22140",
    },
    {
      value: "ทับไทร โป่งน้ำร้อน จันทบุรี 22140",
      label: "ทับไทร โป่งน้ำร้อน จันทบุรี 22140",
    },
    {
      value: "หนองตาคง โป่งน้ำร้อน จันทบุรี 22140",
      label: "หนองตาคง โป่งน้ำร้อน จันทบุรี 22140",
    },
    {
      value: "เทพนิมิต โป่งน้ำร้อน จันทบุรี 22140",
      label: "เทพนิมิต โป่งน้ำร้อน จันทบุรี 22140",
    },
    {
      value: "โป่งน้ำร้อน โป่งน้ำร้อน จันทบุรี 22140",
      label: "โป่งน้ำร้อน โป่งน้ำร้อน จันทบุรี 22140",
    },
    {
      value: "ก้อนแก้ว คลองเขื่อน ฉะเชิงเทรา 24000",
      label: "ก้อนแก้ว คลองเขื่อน ฉะเชิงเทรา 24000",
    },
    {
      value: "คลองเขื่อน คลองเขื่อน ฉะเชิงเทรา 24000",
      label: "คลองเขื่อน คลองเขื่อน ฉะเชิงเทรา 24000",
    },
    {
      value: "บางตลาด คลองเขื่อน ฉะเชิงเทรา 24110",
      label: "บางตลาด คลองเขื่อน ฉะเชิงเทรา 24110",
    },
    {
      value: "บางเล่า คลองเขื่อน ฉะเชิงเทรา 24000",
      label: "บางเล่า คลองเขื่อน ฉะเชิงเทรา 24000",
    },
    {
      value: "บางโรง คลองเขื่อน ฉะเชิงเทรา 24000",
      label: "บางโรง คลองเขื่อน ฉะเชิงเทรา 24000",
    },
    {
      value: "คลองตะเกรา ท่าตะเกียบ ฉะเชิงเทรา 24160",
      label: "คลองตะเกรา ท่าตะเกียบ ฉะเชิงเทรา 24160",
    },
    {
      value: "ท่าตะเกียบ ท่าตะเกียบ ฉะเชิงเทรา 24160",
      label: "ท่าตะเกียบ ท่าตะเกียบ ฉะเชิงเทรา 24160",
    },
    {
      value: "ท่าทองหลาง บางคล้า ฉะเชิงเทรา 24110",
      label: "ท่าทองหลาง บางคล้า ฉะเชิงเทรา 24110",
    },
    {
      value: "บางกระเจ็ด บางคล้า ฉะเชิงเทรา 24110",
      label: "บางกระเจ็ด บางคล้า ฉะเชิงเทรา 24110",
    },
    {
      value: "บางคล้า บางคล้า ฉะเชิงเทรา 24110",
      label: "บางคล้า บางคล้า ฉะเชิงเทรา 24110",
    },
    {
      value: "บางสวน บางคล้า ฉะเชิงเทรา 24110",
      label: "บางสวน บางคล้า ฉะเชิงเทรา 24110",
    },
    {
      value: "ปากน้ำ บางคล้า ฉะเชิงเทรา 24110",
      label: "ปากน้ำ บางคล้า ฉะเชิงเทรา 24110",
    },
    {
      value: "สาวชะโงก บางคล้า ฉะเชิงเทรา 24110",
      label: "สาวชะโงก บางคล้า ฉะเชิงเทรา 24110",
    },
    {
      value: "หัวไทร บางคล้า ฉะเชิงเทรา 24110",
      label: "หัวไทร บางคล้า ฉะเชิงเทรา 24110",
    },
    {
      value: "เสม็ดเหนือ บางคล้า ฉะเชิงเทรา 24110",
      label: "เสม็ดเหนือ บางคล้า ฉะเชิงเทรา 24110",
    },
    {
      value: "เสม็ดใต้ บางคล้า ฉะเชิงเทรา 24110",
      label: "เสม็ดใต้ บางคล้า ฉะเชิงเทรา 24110",
    },
    {
      value: "ดอนฉิมพลี บางน้ำเปรี้ยว ฉะเชิงเทรา 24170",
      label: "ดอนฉิมพลี บางน้ำเปรี้ยว ฉะเชิงเทรา 24170",
    },
    {
      value: "ดอนเกาะกา บางน้ำเปรี้ยว ฉะเชิงเทรา 24170",
      label: "ดอนเกาะกา บางน้ำเปรี้ยว ฉะเชิงเทรา 24170",
    },
    {
      value: "บางขนาก บางน้ำเปรี้ยว ฉะเชิงเทรา 24150",
      label: "บางขนาก บางน้ำเปรี้ยว ฉะเชิงเทรา 24150",
    },
    {
      value: "บางน้ำเปรี้ยว บางน้ำเปรี้ยว ฉะเชิงเทรา 24150",
      label: "บางน้ำเปรี้ยว บางน้ำเปรี้ยว ฉะเชิงเทรา 24150",
    },
    {
      value: "บึงน้ำรักษ์ บางน้ำเปรี้ยว ฉะเชิงเทรา 24170",
      label: "บึงน้ำรักษ์ บางน้ำเปรี้ยว ฉะเชิงเทรา 24170",
    },
    {
      value: "ศาลาแดง บางน้ำเปรี้ยว ฉะเชิงเทรา 24000",
      label: "ศาลาแดง บางน้ำเปรี้ยว ฉะเชิงเทรา 24000",
    },
    {
      value: "สิงโตทอง บางน้ำเปรี้ยว ฉะเชิงเทรา 24150",
      label: "สิงโตทอง บางน้ำเปรี้ยว ฉะเชิงเทรา 24150",
    },
    {
      value: "หมอนทอง บางน้ำเปรี้ยว ฉะเชิงเทรา 24150",
      label: "หมอนทอง บางน้ำเปรี้ยว ฉะเชิงเทรา 24150",
    },
    {
      value: "โพรงอากาศ บางน้ำเปรี้ยว ฉะเชิงเทรา 24150",
      label: "โพรงอากาศ บางน้ำเปรี้ยว ฉะเชิงเทรา 24150",
    },
    {
      value: "โยธะกา บางน้ำเปรี้ยว ฉะเชิงเทรา 24150",
      label: "โยธะกา บางน้ำเปรี้ยว ฉะเชิงเทรา 24150",
    },
    {
      value: "ท่าข้าม บางปะกง ฉะเชิงเทรา 24130",
      label: "ท่าข้าม บางปะกง ฉะเชิงเทรา 24130",
    },
    {
      value: "ท่าสะอ้าน บางปะกง ฉะเชิงเทรา 24130",
      label: "ท่าสะอ้าน บางปะกง ฉะเชิงเทรา 24130",
    },
    {
      value: "บางปะกง บางปะกง ฉะเชิงเทรา 24130",
      label: "บางปะกง บางปะกง ฉะเชิงเทรา 24130",
    },
    {
      value: "บางผึ้ง บางปะกง ฉะเชิงเทรา 24130",
      label: "บางผึ้ง บางปะกง ฉะเชิงเทรา 24130",
    },
    {
      value: "บางวัว บางปะกง ฉะเชิงเทรา 24180",
      label: "บางวัว บางปะกง ฉะเชิงเทรา 24180",
    },
    {
      value: "บางสมัคร บางปะกง ฉะเชิงเทรา 24180",
      label: "บางสมัคร บางปะกง ฉะเชิงเทรา 24180",
    },
    {
      value: "บางเกลือ บางปะกง ฉะเชิงเทรา 24180",
      label: "บางเกลือ บางปะกง ฉะเชิงเทรา 24180",
    },
    {
      value: "พิมพา บางปะกง ฉะเชิงเทรา 24180",
      label: "พิมพา บางปะกง ฉะเชิงเทรา 24180",
    },
    {
      value: "สองคลอง บางปะกง ฉะเชิงเทรา 24130",
      label: "สองคลอง บางปะกง ฉะเชิงเทรา 24130",
    },
    {
      value: "หนองจอก บางปะกง ฉะเชิงเทรา 24130",
      label: "หนองจอก บางปะกง ฉะเชิงเทรา 24130",
    },
    {
      value: "หอมศีล บางปะกง ฉะเชิงเทรา 24180",
      label: "หอมศีล บางปะกง ฉะเชิงเทรา 24180",
    },
    {
      value: "เขาดิน บางปะกง ฉะเชิงเทรา 24130",
      label: "เขาดิน บางปะกง ฉะเชิงเทรา 24130",
    },
    {
      value: "คลองขุด บ้านโพธิ์ ฉะเชิงเทรา 24140",
      label: "คลองขุด บ้านโพธิ์ ฉะเชิงเทรา 24140",
    },
    {
      value: "คลองบ้านโพธิ์ บ้านโพธิ์ ฉะเชิงเทรา 24140",
      label: "คลองบ้านโพธิ์ บ้านโพธิ์ ฉะเชิงเทรา 24140",
    },
    {
      value: "คลองประเวศ บ้านโพธิ์ ฉะเชิงเทรา 24140",
      label: "คลองประเวศ บ้านโพธิ์ ฉะเชิงเทรา 24140",
    },
    {
      value: "ดอนทราย บ้านโพธิ์ ฉะเชิงเทรา 24140",
      label: "ดอนทราย บ้านโพธิ์ ฉะเชิงเทรา 24140",
    },
    {
      value: "ท่าพลับ บ้านโพธิ์ ฉะเชิงเทรา 24140",
      label: "ท่าพลับ บ้านโพธิ์ ฉะเชิงเทรา 24140",
    },
    {
      value: "บางกรูด บ้านโพธิ์ ฉะเชิงเทรา 24140",
      label: "บางกรูด บ้านโพธิ์ ฉะเชิงเทรา 24140",
    },
    {
      value: "บางซ่อน บ้านโพธิ์ ฉะเชิงเทรา 24140",
      label: "บางซ่อน บ้านโพธิ์ ฉะเชิงเทรา 24140",
    },
    {
      value: "บ้านโพธิ์ บ้านโพธิ์ ฉะเชิงเทรา 24140",
      label: "บ้านโพธิ์ บ้านโพธิ์ ฉะเชิงเทรา 24140",
    },
    {
      value: "ลาดขวาง บ้านโพธิ์ ฉะเชิงเทรา 24140",
      label: "ลาดขวาง บ้านโพธิ์ ฉะเชิงเทรา 24140",
    },
    {
      value: "สนามจันทร์ บ้านโพธิ์ ฉะเชิงเทรา 24140",
      label: "สนามจันทร์ บ้านโพธิ์ ฉะเชิงเทรา 24140",
    },
    {
      value: "สิบเอ็ดศอก บ้านโพธิ์ ฉะเชิงเทรา 24140",
      label: "สิบเอ็ดศอก บ้านโพธิ์ ฉะเชิงเทรา 24140",
    },
    {
      value: "หนองตีนนก บ้านโพธิ์ ฉะเชิงเทรา 24140",
      label: "หนองตีนนก บ้านโพธิ์ ฉะเชิงเทรา 24140",
    },
    {
      value: "หนองบัว บ้านโพธิ์ ฉะเชิงเทรา 24140",
      label: "หนองบัว บ้านโพธิ์ ฉะเชิงเทรา 24140",
    },
    {
      value: "เกาะไร่ บ้านโพธิ์ ฉะเชิงเทรา 24140",
      label: "เกาะไร่ บ้านโพธิ์ ฉะเชิงเทรา 24140",
    },
    {
      value: "เทพราช บ้านโพธิ์ ฉะเชิงเทรา 24140",
      label: "เทพราช บ้านโพธิ์ ฉะเชิงเทรา 24140",
    },
    {
      value: "แสนภูดาษ บ้านโพธิ์ ฉะเชิงเทรา 24140",
      label: "แสนภูดาษ บ้านโพธิ์ ฉะเชิงเทรา 24140",
    },
    {
      value: "แหลมประดู่ บ้านโพธิ์ ฉะเชิงเทรา 24140",
      label: "แหลมประดู่ บ้านโพธิ์ ฉะเชิงเทรา 24140",
    },
    {
      value: "ท่าถ่าน พนมสารคาม ฉะเชิงเทรา 24120",
      label: "ท่าถ่าน พนมสารคาม ฉะเชิงเทรา 24120",
    },
    {
      value: "บ้านซ่อง พนมสารคาม ฉะเชิงเทรา 24120",
      label: "บ้านซ่อง พนมสารคาม ฉะเชิงเทรา 24120",
    },
    {
      value: "พนมสารคาม พนมสารคาม ฉะเชิงเทรา 24120",
      label: "พนมสารคาม พนมสารคาม ฉะเชิงเทรา 24120",
    },
    {
      value: "หนองยาว พนมสารคาม ฉะเชิงเทรา 24120",
      label: "หนองยาว พนมสารคาม ฉะเชิงเทรา 24120",
    },
    {
      value: "หนองแหน พนมสารคาม ฉะเชิงเทรา 24120",
      label: "หนองแหน พนมสารคาม ฉะเชิงเทรา 24120",
    },
    {
      value: "เกาะขนุน พนมสารคาม ฉะเชิงเทรา 24120",
      label: "เกาะขนุน พนมสารคาม ฉะเชิงเทรา 24120",
    },
    {
      value: "เขาหินซ้อน พนมสารคาม ฉะเชิงเทรา 24120",
      label: "เขาหินซ้อน พนมสารคาม ฉะเชิงเทรา 24120",
    },
    {
      value: "เมืองเก่า พนมสารคาม ฉะเชิงเทรา 24120",
      label: "เมืองเก่า พนมสารคาม ฉะเชิงเทรา 24120",
    },
    {
      value: "ดงน้อย ราชสาส์น ฉะเชิงเทรา 24120",
      label: "ดงน้อย ราชสาส์น ฉะเชิงเทรา 24120",
    },
    {
      value: "บางคา ราชสาส์น ฉะเชิงเทรา 24120",
      label: "บางคา ราชสาส์น ฉะเชิงเทรา 24120",
    },
    {
      value: "เมืองใหม่ ราชสาส์น ฉะเชิงเทรา 24120",
      label: "เมืองใหม่ ราชสาส์น ฉะเชิงเทรา 24120",
    },
    {
      value: "คู้ยายหมี สนามชัยเขต ฉะเชิงเทรา 24160",
      label: "คู้ยายหมี สนามชัยเขต ฉะเชิงเทรา 24160",
    },
    {
      value: "ทุ่งพระยา สนามชัยเขต ฉะเชิงเทรา 24160",
      label: "ทุ่งพระยา สนามชัยเขต ฉะเชิงเทรา 24160",
    },
    {
      value: "ท่ากระดาน สนามชัยเขต ฉะเชิงเทรา 24160",
      label: "ท่ากระดาน สนามชัยเขต ฉะเชิงเทรา 24160",
    },
    {
      value: "ลาดกระทิง สนามชัยเขต ฉะเชิงเทรา 24160",
      label: "ลาดกระทิง สนามชัยเขต ฉะเชิงเทรา 24160",
    },
    {
      value: "คลองจุกกระเฌอ เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
      label: "คลองจุกกระเฌอ เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
    },
    {
      value: "คลองนครเนื่องเขต เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
      label: "คลองนครเนื่องเขต เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
    },
    {
      value: "คลองนา เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
      label: "คลองนา เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
    },
    {
      value: "คลองหลวงแพ่ง เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
      label: "คลองหลวงแพ่ง เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
    },
    {
      value: "คลองอุดมชลจร เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
      label: "คลองอุดมชลจร เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
    },
    {
      value: "คลองเปรง เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
      label: "คลองเปรง เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
    },
    {
      value: "ท่าไข่ เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
      label: "ท่าไข่ เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
    },
    {
      value: "บางกะไห เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
      label: "บางกะไห เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
    },
    {
      value: "บางขวัญ เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
      label: "บางขวัญ เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
    },
    {
      value: "บางตีนเป็ด เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
      label: "บางตีนเป็ด เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
    },
    {
      value: "บางพระ เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
      label: "บางพระ เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
    },
    {
      value: "บางเตย เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
      label: "บางเตย เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
    },
    {
      value: "บางแก้ว เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
      label: "บางแก้ว เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
    },
    {
      value: "บางไผ่ เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
      label: "บางไผ่ เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
    },
    {
      value: "บ้านใหม่ เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
      label: "บ้านใหม่ เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
    },
    {
      value: "วังตะเคียน เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
      label: "วังตะเคียน เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
    },
    {
      value: "หนามแดง เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
      label: "หนามแดง เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
    },
    {
      value: "หน้าเมือง เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
      label: "หน้าเมือง เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
    },
    {
      value: "โสธร เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
      label: "โสธร เมืองฉะเชิงเทรา ฉะเชิงเทรา 24000",
    },
    {
      value: "วังเย็น แปลงยาว ฉะเชิงเทรา 24190",
      label: "วังเย็น แปลงยาว ฉะเชิงเทรา 24190",
    },
    {
      value: "หนองไม้แก่น แปลงยาว ฉะเชิงเทรา 24190",
      label: "หนองไม้แก่น แปลงยาว ฉะเชิงเทรา 24190",
    },
    {
      value: "หัวสำโรง แปลงยาว ฉะเชิงเทรา 24190",
      label: "หัวสำโรง แปลงยาว ฉะเชิงเทรา 24190",
    },
    {
      value: "แปลงยาว แปลงยาว ฉะเชิงเทรา 24190",
      label: "แปลงยาว แปลงยาว ฉะเชิงเทรา 24190",
    },
    {
      value: "ตะเคียนเตี้ย บางละมุง ชลบุรี 20150",
      label: "ตะเคียนเตี้ย บางละมุง ชลบุรี 20150",
    },
    {
      value: "นาเกลือ บางละมุง ชลบุรี 20150",
      label: "นาเกลือ บางละมุง ชลบุรี 20150",
    },
    {
      value: "บางละมุง บางละมุง ชลบุรี 20150",
      label: "บางละมุง บางละมุง ชลบุรี 20150",
    },
    {
      value: "หนองปรือ บางละมุง ชลบุรี 20150",
      label: "หนองปรือ บางละมุง ชลบุรี 20150",
    },
    {
      value: "หนองปลาไหล บางละมุง ชลบุรี 20150",
      label: "หนองปลาไหล บางละมุง ชลบุรี 20150",
    },
    {
      value: "ห้วยใหญ่ บางละมุง ชลบุรี 20150",
      label: "ห้วยใหญ่ บางละมุง ชลบุรี 20150",
    },
    {
      value: "เขาไม้แก้ว บางละมุง ชลบุรี 20150",
      label: "เขาไม้แก้ว บางละมุง ชลบุรี 20150",
    },
    { value: "โป่ง บางละมุง ชลบุรี 20150", label: "โป่ง บางละมุง ชลบุรี 20150" },
    {
      value: "ธาตุทอง บ่อทอง ชลบุรี 20270",
      label: "ธาตุทอง บ่อทอง ชลบุรี 20270",
    },
    {
      value: "บ่อกวางทอง บ่อทอง ชลบุรี 20270",
      label: "บ่อกวางทอง บ่อทอง ชลบุรี 20270",
    },
    { value: "บ่อทอง บ่อทอง ชลบุรี 20270", label: "บ่อทอง บ่อทอง ชลบุรี 20270" },
    {
      value: "พลวงทอง บ่อทอง ชลบุรี 20270",
      label: "พลวงทอง บ่อทอง ชลบุรี 20270",
    },
    {
      value: "วัดสุวรรณ บ่อทอง ชลบุรี 20270",
      label: "วัดสุวรรณ บ่อทอง ชลบุรี 20270",
    },
    {
      value: "เกษตรสุวรรณ บ่อทอง ชลบุรี 20270",
      label: "เกษตรสุวรรณ บ่อทอง ชลบุรี 20270",
    },
    {
      value: "คลองกิ่ว บ้านบึง ชลบุรี 20220",
      label: "คลองกิ่ว บ้านบึง ชลบุรี 20220",
    },
    {
      value: "บ้านบึง บ้านบึง ชลบุรี 20170",
      label: "บ้านบึง บ้านบึง ชลบุรี 20170",
    },
    {
      value: "มาบไผ่ บ้านบึง ชลบุรี 20170",
      label: "มาบไผ่ บ้านบึง ชลบุรี 20170",
    },
    {
      value: "หนองชาก บ้านบึง ชลบุรี 20170",
      label: "หนองชาก บ้านบึง ชลบุรี 20170",
    },
    {
      value: "หนองซ้ำซาก บ้านบึง ชลบุรี 20170",
      label: "หนองซ้ำซาก บ้านบึง ชลบุรี 20170",
    },
    {
      value: "หนองบอนแดง บ้านบึง ชลบุรี 20170",
      label: "หนองบอนแดง บ้านบึง ชลบุรี 20170",
    },
    {
      value: "หนองอิรุณ บ้านบึง ชลบุรี 20220",
      label: "หนองอิรุณ บ้านบึง ชลบุรี 20220",
    },
    {
      value: "หนองไผ่แก้ว บ้านบึง ชลบุรี 20220",
      label: "หนองไผ่แก้ว บ้านบึง ชลบุรี 20220",
    },
    {
      value: "กุฎโง้ง พนัสนิคม ชลบุรี 20140",
      label: "กุฎโง้ง พนัสนิคม ชลบุรี 20140",
    },
    {
      value: "ทุ่งขวาง พนัสนิคม ชลบุรี 20140",
      label: "ทุ่งขวาง พนัสนิคม ชลบุรี 20140",
    },
    {
      value: "ท่าข้าม พนัสนิคม ชลบุรี 20140",
      label: "ท่าข้าม พนัสนิคม ชลบุรี 20140",
    },
    {
      value: "นามะตูม พนัสนิคม ชลบุรี 20140",
      label: "นามะตูม พนัสนิคม ชลบุรี 20140",
    },
    {
      value: "นาวังหิน พนัสนิคม ชลบุรี 20140",
      label: "นาวังหิน พนัสนิคม ชลบุรี 20140",
    },
    {
      value: "นาเริก พนัสนิคม ชลบุรี 20140",
      label: "นาเริก พนัสนิคม ชลบุรี 20140",
    },
    {
      value: "บ้านช้าง พนัสนิคม ชลบุรี 20140",
      label: "บ้านช้าง พนัสนิคม ชลบุรี 20140",
    },
    {
      value: "บ้านเซิด พนัสนิคม ชลบุรี 20140",
      label: "บ้านเซิด พนัสนิคม ชลบุรี 20140",
    },
    {
      value: "พนัสนิคม พนัสนิคม ชลบุรี 20140",
      label: "พนัสนิคม พนัสนิคม ชลบุรี 20140",
    },
    {
      value: "วัดหลวง พนัสนิคม ชลบุรี 20140",
      label: "วัดหลวง พนัสนิคม ชลบุรี 20140",
    },
    {
      value: "วัดโบสถ์ พนัสนิคม ชลบุรี 20140",
      label: "วัดโบสถ์ พนัสนิคม ชลบุรี 20140",
    },
    {
      value: "สระสี่เหลี่ยม พนัสนิคม ชลบุรี 20140",
      label: "สระสี่เหลี่ยม พนัสนิคม ชลบุรี 20140",
    },
    {
      value: "หนองขยาด พนัสนิคม ชลบุรี 20140",
      label: "หนองขยาด พนัสนิคม ชลบุรี 20140",
    },
    {
      value: "หนองปรือ พนัสนิคม ชลบุรี 20140",
      label: "หนองปรือ พนัสนิคม ชลบุรี 20140",
    },
    {
      value: "หนองเหียง พนัสนิคม ชลบุรี 20140",
      label: "หนองเหียง พนัสนิคม ชลบุรี 20140",
    },
    {
      value: "หน้าพระธาตุ พนัสนิคม ชลบุรี 20140",
      label: "หน้าพระธาตุ พนัสนิคม ชลบุรี 20140",
    },
    {
      value: "หมอนนาง พนัสนิคม ชลบุรี 20140",
      label: "หมอนนาง พนัสนิคม ชลบุรี 20140",
    },
    {
      value: "หัวถนน พนัสนิคม ชลบุรี 20140",
      label: "หัวถนน พนัสนิคม ชลบุรี 20140",
    },
    {
      value: "โคกเพลาะ พนัสนิคม ชลบุรี 20140",
      label: "โคกเพลาะ พนัสนิคม ชลบุรี 20140",
    },
    {
      value: "ไร่หลักทอง พนัสนิคม ชลบุรี 20140",
      label: "ไร่หลักทอง พนัสนิคม ชลบุรี 20140",
    },
    { value: "บางนาง พานทอง ชลบุรี 20160", label: "บางนาง พานทอง ชลบุรี 20160" },
    { value: "บางหัก พานทอง ชลบุรี 20160", label: "บางหัก พานทอง ชลบุรี 20160" },
    {
      value: "บ้านเก่า พานทอง ชลบุรี 20160",
      label: "บ้านเก่า พานทอง ชลบุรี 20160",
    },
    { value: "พานทอง พานทอง ชลบุรี 20160", label: "พานทอง พานทอง ชลบุรี 20160" },
    {
      value: "มาบโป่ง พานทอง ชลบุรี 20160",
      label: "มาบโป่ง พานทอง ชลบุรี 20160",
    },
    {
      value: "หนองกะขะ พานทอง ชลบุรี 20160",
      label: "หนองกะขะ พานทอง ชลบุรี 20160",
    },
    {
      value: "หนองตำลึง พานทอง ชลบุรี 20160",
      label: "หนองตำลึง พานทอง ชลบุรี 20160",
    },
    {
      value: "หนองหงษ์ พานทอง ชลบุรี 20160",
      label: "หนองหงษ์ พานทอง ชลบุรี 20160",
    },
    {
      value: "หน้าประดู่ พานทอง ชลบุรี 20160",
      label: "หน้าประดู่ พานทอง ชลบุรี 20160",
    },
    {
      value: "เกาะลอย พานทอง ชลบุรี 20160",
      label: "เกาะลอย พานทอง ชลบุรี 20160",
    },
    {
      value: "โคกขี้หนอน พานทอง ชลบุรี 20160",
      label: "โคกขี้หนอน พานทอง ชลบุรี 20160",
    },
    {
      value: "ทุ่งสุขลา ศรีราชา ชลบุรี 20230",
      label: "ทุ่งสุขลา ศรีราชา ชลบุรี 20230",
    },
    {
      value: "บางพระ ศรีราชา ชลบุรี 20110",
      label: "บางพระ ศรีราชา ชลบุรี 20110",
    },
    { value: "บึง ศรีราชา ชลบุรี 20230", label: "บึง ศรีราชา ชลบุรี 20230" },
    {
      value: "บ่อวิน ศรีราชา ชลบุรี 20230",
      label: "บ่อวิน ศรีราชา ชลบุรี 20230",
    },
    {
      value: "ศรีราชา ศรีราชา ชลบุรี 20110",
      label: "ศรีราชา ศรีราชา ชลบุรี 20110",
    },
    {
      value: "สุรศักดิ์ ศรีราชา ชลบุรี 20110",
      label: "สุรศักดิ์ ศรีราชา ชลบุรี 20110",
    },
    {
      value: "หนองขาม ศรีราชา ชลบุรี 20230",
      label: "หนองขาม ศรีราชา ชลบุรี 20230",
    },
    {
      value: "เขาคันทรง ศรีราชา ชลบุรี 20110",
      label: "เขาคันทรง ศรีราชา ชลบุรี 20110",
    },
    {
      value: "นาจอมเทียน สัตหีบ ชลบุรี 20250",
      label: "นาจอมเทียน สัตหีบ ชลบุรี 20250",
    },
    {
      value: "บางเสร่ สัตหีบ ชลบุรี 20250",
      label: "บางเสร่ สัตหีบ ชลบุรี 20250",
    },
    {
      value: "พลูตาหลวง สัตหีบ ชลบุรี 20180",
      label: "พลูตาหลวง สัตหีบ ชลบุรี 20180",
    },
    { value: "สัตหีบ สัตหีบ ชลบุรี 20180", label: "สัตหีบ สัตหีบ ชลบุรี 20180" },
    { value: "แสมสาร สัตหีบ ชลบุรี 20180", label: "แสมสาร สัตหีบ ชลบุรี 20180" },
    {
      value: "คลองพลู หนองใหญ่ ชลบุรี 20190",
      label: "คลองพลู หนองใหญ่ ชลบุรี 20190",
    },
    {
      value: "หนองเสือช้าง หนองใหญ่ ชลบุรี 20190",
      label: "หนองเสือช้าง หนองใหญ่ ชลบุรี 20190",
    },
    {
      value: "หนองใหญ่ หนองใหญ่ ชลบุรี 20190",
      label: "หนองใหญ่ หนองใหญ่ ชลบุรี 20190",
    },
    {
      value: "ห้างสูง หนองใหญ่ ชลบุรี 20190",
      label: "ห้างสูง หนองใหญ่ ชลบุรี 20190",
    },
    {
      value: "เขาซก หนองใหญ่ ชลบุรี 20190",
      label: "เขาซก หนองใหญ่ ชลบุรี 20190",
    },
    {
      value: "ท่าบุญมี เกาะจันทร์ ชลบุรี 20240",
      label: "ท่าบุญมี เกาะจันทร์ ชลบุรี 20240",
    },
    {
      value: "เกาะจันทร์ เกาะจันทร์ ชลบุรี 20240",
      label: "เกาะจันทร์ เกาะจันทร์ ชลบุรี 20240",
    },
    {
      value: "ท่าเทววงษ์ เกาะสีชัง ชลบุรี 20120",
      label: "ท่าเทววงษ์ เกาะสีชัง ชลบุรี 20120",
    },
    {
      value: "คลองตำหรุ เมืองชลบุรี ชลบุรี 20000",
      label: "คลองตำหรุ เมืองชลบุรี ชลบุรี 20000",
    },
    {
      value: "ดอนหัวฬ่อ เมืองชลบุรี ชลบุรี 20000",
      label: "ดอนหัวฬ่อ เมืองชลบุรี ชลบุรี 20000",
    },
    {
      value: "นาป่า เมืองชลบุรี ชลบุรี 20000",
      label: "นาป่า เมืองชลบุรี ชลบุรี 20000",
    },
    {
      value: "บางทราย เมืองชลบุรี ชลบุรี 20000",
      label: "บางทราย เมืองชลบุรี ชลบุรี 20000",
    },
    {
      value: "บางปลาสร้อย เมืองชลบุรี ชลบุรี 20000",
      label: "บางปลาสร้อย เมืองชลบุรี ชลบุรี 20000",
    },
    {
      value: "บ้านปึก เมืองชลบุรี ชลบุรี 20130",
      label: "บ้านปึก เมืองชลบุรี ชลบุรี 20130",
    },
    {
      value: "บ้านสวน เมืองชลบุรี ชลบุรี 20000",
      label: "บ้านสวน เมืองชลบุรี ชลบุรี 20000",
    },
    {
      value: "บ้านโขด เมืองชลบุรี ชลบุรี 20000",
      label: "บ้านโขด เมืองชลบุรี ชลบุรี 20000",
    },
    {
      value: "มะขามหย่ง เมืองชลบุรี ชลบุรี 20000",
      label: "มะขามหย่ง เมืองชลบุรี ชลบุรี 20000",
    },
    {
      value: "สำนักบก เมืองชลบุรี ชลบุรี 20000",
      label: "สำนักบก เมืองชลบุรี ชลบุรี 20000",
    },
    {
      value: "หนองข้างคอก เมืองชลบุรี ชลบุรี 20000",
      label: "หนองข้างคอก เมืองชลบุรี ชลบุรี 20000",
    },
    {
      value: "หนองรี เมืองชลบุรี ชลบุรี 20000",
      label: "หนองรี เมืองชลบุรี ชลบุรี 20000",
    },
    {
      value: "หนองไม้แดง เมืองชลบุรี ชลบุรี 20000",
      label: "หนองไม้แดง เมืองชลบุรี ชลบุรี 20000",
    },
    {
      value: "ห้วยกะปิ เมืองชลบุรี ชลบุรี 20000",
      label: "ห้วยกะปิ เมืองชลบุรี ชลบุรี 20000",
    },
    {
      value: "อ่างศิลา เมืองชลบุรี ชลบุรี 20000",
      label: "อ่างศิลา เมืองชลบุรี ชลบุรี 20000",
    },
    {
      value: "เสม็ด เมืองชลบุรี ชลบุรี 20000",
      label: "เสม็ด เมืองชลบุรี ชลบุรี 20000",
    },
    {
      value: "เหมือง เมืองชลบุรี ชลบุรี 20130",
      label: "เหมือง เมืองชลบุรี ชลบุรี 20130",
    },
    {
      value: "แสนสุข เมืองชลบุรี ชลบุรี 20130",
      label: "แสนสุข เมืองชลบุรี ชลบุรี 20130",
    },
    {
      value: "คลองใหญ่ คลองใหญ่ ตราด 23110",
      label: "คลองใหญ่ คลองใหญ่ ตราด 23110",
    },
    {
      value: "หาดเล็ก คลองใหญ่ ตราด 23110",
      label: "หาดเล็ก คลองใหญ่ ตราด 23110",
    },
    { value: "ไม้รูด คลองใหญ่ ตราด 23110", label: "ไม้รูด คลองใหญ่ ตราด 23110" },
    { value: "ช้างทูน บ่อไร่ ตราด 23140", label: "ช้างทูน บ่อไร่ ตราด 23140" },
    {
      value: "ด่านชุมพล บ่อไร่ ตราด 23140",
      label: "ด่านชุมพล บ่อไร่ ตราด 23140",
    },
    { value: "นนทรีย์ บ่อไร่ ตราด 23140", label: "นนทรีย์ บ่อไร่ ตราด 23140" },
    { value: "บ่อพลอย บ่อไร่ ตราด 23140", label: "บ่อพลอย บ่อไร่ ตราด 23140" },
    { value: "หนองบอน บ่อไร่ ตราด 23140", label: "หนองบอน บ่อไร่ ตราด 23140" },
    { value: "เกาะกูด เกาะกูด ตราด 23000", label: "เกาะกูด เกาะกูด ตราด 23000" },
    {
      value: "เกาะหมาก เกาะกูด ตราด 23000",
      label: "เกาะหมาก เกาะกูด ตราด 23000",
    },
    {
      value: "เกาะช้าง เกาะช้าง ตราด 23170",
      label: "เกาะช้าง เกาะช้าง ตราด 23170",
    },
    {
      value: "เกาะช้างใต้ เกาะช้าง ตราด 23170",
      label: "เกาะช้างใต้ เกาะช้าง ตราด 23170",
    },
    {
      value: "ทุ่งนนทรี เขาสมิง ตราด 23130",
      label: "ทุ่งนนทรี เขาสมิง ตราด 23130",
    },
    { value: "ท่าโสม เขาสมิง ตราด 23150", label: "ท่าโสม เขาสมิง ตราด 23150" },
    { value: "ประณีต เขาสมิง ตราด 23150", label: "ประณีต เขาสมิง ตราด 23150" },
    {
      value: "วังตะเคียน เขาสมิง ตราด 23130",
      label: "วังตะเคียน เขาสมิง ตราด 23130",
    },
    { value: "สะตอ เขาสมิง ตราด 23150", label: "สะตอ เขาสมิง ตราด 23150" },
    { value: "เขาสมิง เขาสมิง ตราด 23130", label: "เขาสมิง เขาสมิง ตราด 23130" },
    {
      value: "เทพนิมิต เขาสมิง ตราด 23150",
      label: "เทพนิมิต เขาสมิง ตราด 23150",
    },
    { value: "แสนตุ้ง เขาสมิง ตราด 23150", label: "แสนตุ้ง เขาสมิง ตราด 23150" },
    { value: "ชำราก เมืองตราด ตราด 23000", label: "ชำราก เมืองตราด ตราด 23000" },
    { value: "ตะกาง เมืองตราด ตราด 23000", label: "ตะกาง เมืองตราด ตราด 23000" },
    {
      value: "ท่ากุ่ม เมืองตราด ตราด 23000",
      label: "ท่ากุ่ม เมืองตราด ตราด 23000",
    },
    {
      value: "ท่าพริก เมืองตราด ตราด 23000",
      label: "ท่าพริก เมืองตราด ตราด 23000",
    },
    {
      value: "บางพระ เมืองตราด ตราด 23000",
      label: "บางพระ เมืองตราด ตราด 23000",
    },
    {
      value: "วังกระแจะ เมืองตราด ตราด 23000",
      label: "วังกระแจะ เมืองตราด ตราด 23000",
    },
    {
      value: "หนองคันทรง เมืองตราด ตราด 23000",
      label: "หนองคันทรง เมืองตราด ตราด 23000",
    },
    {
      value: "หนองเสม็ด เมืองตราด ตราด 23000",
      label: "หนองเสม็ด เมืองตราด ตราด 23000",
    },
    {
      value: "หนองโสน เมืองตราด ตราด 23000",
      label: "หนองโสน เมืองตราด ตราด 23000",
    },
    {
      value: "ห้วงน้ำขาว เมืองตราด ตราด 23000",
      label: "ห้วงน้ำขาว เมืองตราด ตราด 23000",
    },
    {
      value: "ห้วยแร้ง เมืองตราด ตราด 23000",
      label: "ห้วยแร้ง เมืองตราด ตราด 23000",
    },
    {
      value: "อ่าวใหญ่ เมืองตราด ตราด 23000",
      label: "อ่าวใหญ่ เมืองตราด ตราด 23000",
    },
    {
      value: "เนินทราย เมืองตราด ตราด 23000",
      label: "เนินทราย เมืองตราด ตราด 23000",
    },
    {
      value: "แหลมกลัด เมืองตราด ตราด 23000",
      label: "แหลมกลัด เมืองตราด ตราด 23000",
    },
    {
      value: "คลองใหญ่ แหลมงอบ ตราด 23120",
      label: "คลองใหญ่ แหลมงอบ ตราด 23120",
    },
    {
      value: "น้ำเชี่ยว แหลมงอบ ตราด 23120",
      label: "น้ำเชี่ยว แหลมงอบ ตราด 23120",
    },
    { value: "บางปิด แหลมงอบ ตราด 23120", label: "บางปิด แหลมงอบ ตราด 23120" },
    { value: "แหลมงอบ แหลมงอบ ตราด 23120", label: "แหลมงอบ แหลมงอบ ตราด 23120" },
    {
      value: "กบินทร์ กบินทร์บุรี ปราจีนบุรี 25110",
      label: "กบินทร์ กบินทร์บุรี ปราจีนบุรี 25110",
    },
    {
      value: "นนทรี กบินทร์บุรี ปราจีนบุรี 25110",
      label: "นนทรี กบินทร์บุรี ปราจีนบุรี 25110",
    },
    {
      value: "นาแขม กบินทร์บุรี ปราจีนบุรี 25110",
      label: "นาแขม กบินทร์บุรี ปราจีนบุรี 25110",
    },
    {
      value: "บ่อทอง กบินทร์บุรี ปราจีนบุรี 25110",
      label: "บ่อทอง กบินทร์บุรี ปราจีนบุรี 25110",
    },
    {
      value: "บ้านนา กบินทร์บุรี ปราจีนบุรี 25110",
      label: "บ้านนา กบินทร์บุรี ปราจีนบุรี 25110",
    },
    {
      value: "ย่านรี กบินทร์บุรี ปราจีนบุรี 25110",
      label: "ย่านรี กบินทร์บุรี ปราจีนบุรี 25110",
    },
    {
      value: "ลาดตะเคียน กบินทร์บุรี ปราจีนบุรี 25110",
      label: "ลาดตะเคียน กบินทร์บุรี ปราจีนบุรี 25110",
    },
    {
      value: "วังดาล กบินทร์บุรี ปราจีนบุรี 25110",
      label: "วังดาล กบินทร์บุรี ปราจีนบุรี 25110",
    },
    {
      value: "วังตะเคียน กบินทร์บุรี ปราจีนบุรี 25110",
      label: "วังตะเคียน กบินทร์บุรี ปราจีนบุรี 25110",
    },
    {
      value: "วังท่าช้าง กบินทร์บุรี ปราจีนบุรี 25110",
      label: "วังท่าช้าง กบินทร์บุรี ปราจีนบุรี 25110",
    },
    {
      value: "หนองกี่ กบินทร์บุรี ปราจีนบุรี 25110",
      label: "หนองกี่ กบินทร์บุรี ปราจีนบุรี 25110",
    },
    {
      value: "หาดนางแก้ว กบินทร์บุรี ปราจีนบุรี 25110",
      label: "หาดนางแก้ว กบินทร์บุรี ปราจีนบุรี 25110",
    },
    {
      value: "เขาไม้แก้ว กบินทร์บุรี ปราจีนบุรี 25110",
      label: "เขาไม้แก้ว กบินทร์บุรี ปราจีนบุรี 25110",
    },
    {
      value: "เมืองเก่า กบินทร์บุรี ปราจีนบุรี 25240",
      label: "เมืองเก่า กบินทร์บุรี ปราจีนบุรี 25240",
    },
    {
      value: "ทุ่งโพธิ์ นาดี ปราจีนบุรี 25220",
      label: "ทุ่งโพธิ์ นาดี ปราจีนบุรี 25220",
    },
    { value: "นาดี นาดี ปราจีนบุรี 25220", label: "นาดี นาดี ปราจีนบุรี 25220" },
    {
      value: "บุพราหมณ์ นาดี ปราจีนบุรี 25220",
      label: "บุพราหมณ์ นาดี ปราจีนบุรี 25220",
    },
    {
      value: "สะพานหิน นาดี ปราจีนบุรี 25220",
      label: "สะพานหิน นาดี ปราจีนบุรี 25220",
    },
    {
      value: "สำพันตา นาดี ปราจีนบุรี 25220",
      label: "สำพันตา นาดี ปราจีนบุรี 25220",
    },
    {
      value: "แก่งดินสอ นาดี ปราจีนบุรี 25220",
      label: "แก่งดินสอ นาดี ปราจีนบุรี 25220",
    },
    {
      value: "กระทุ่มแพ้ว บ้านสร้าง ปราจีนบุรี 25150",
      label: "กระทุ่มแพ้ว บ้านสร้าง ปราจีนบุรี 25150",
    },
    {
      value: "บางกระเบา บ้านสร้าง ปราจีนบุรี 25150",
      label: "บางกระเบา บ้านสร้าง ปราจีนบุรี 25150",
    },
    {
      value: "บางขาม บ้านสร้าง ปราจีนบุรี 25150",
      label: "บางขาม บ้านสร้าง ปราจีนบุรี 25150",
    },
    {
      value: "บางปลาร้า บ้านสร้าง ปราจีนบุรี 25150",
      label: "บางปลาร้า บ้านสร้าง ปราจีนบุรี 25150",
    },
    {
      value: "บางพลวง บ้านสร้าง ปราจีนบุรี 25150",
      label: "บางพลวง บ้านสร้าง ปราจีนบุรี 25150",
    },
    {
      value: "บางยาง บ้านสร้าง ปราจีนบุรี 25150",
      label: "บางยาง บ้านสร้าง ปราจีนบุรี 25150",
    },
    {
      value: "บางเตย บ้านสร้าง ปราจีนบุรี 25150",
      label: "บางเตย บ้านสร้าง ปราจีนบุรี 25150",
    },
    {
      value: "บางแตน บ้านสร้าง ปราจีนบุรี 25150",
      label: "บางแตน บ้านสร้าง ปราจีนบุรี 25150",
    },
    {
      value: "บ้านสร้าง บ้านสร้าง ปราจีนบุรี 25150",
      label: "บ้านสร้าง บ้านสร้าง ปราจีนบุรี 25150",
    },
    {
      value: "คำโตนด ประจันตคาม ปราจีนบุรี 25130",
      label: "คำโตนด ประจันตคาม ปราจีนบุรี 25130",
    },
    {
      value: "ดงบัง ประจันตคาม ปราจีนบุรี 25130",
      label: "ดงบัง ประจันตคาม ปราจีนบุรี 25130",
    },
    {
      value: "บุฝ้าย ประจันตคาม ปราจีนบุรี 25130",
      label: "บุฝ้าย ประจันตคาม ปราจีนบุรี 25130",
    },
    {
      value: "บ้านหอย ประจันตคาม ปราจีนบุรี 25130",
      label: "บ้านหอย ประจันตคาม ปราจีนบุรี 25130",
    },
    {
      value: "ประจันตคาม ประจันตคาม ปราจีนบุรี 25130",
      label: "ประจันตคาม ประจันตคาม ปราจีนบุรี 25130",
    },
    {
      value: "หนองแก้ว ประจันตคาม ปราจีนบุรี 25130",
      label: "หนองแก้ว ประจันตคาม ปราจีนบุรี 25130",
    },
    {
      value: "หนองแสง ประจันตคาม ปราจีนบุรี 25130",
      label: "หนองแสง ประจันตคาม ปราจีนบุรี 25130",
    },
    {
      value: "เกาะลอย ประจันตคาม ปราจีนบุรี 25130",
      label: "เกาะลอย ประจันตคาม ปราจีนบุรี 25130",
    },
    {
      value: "โพธิ์งาม ประจันตคาม ปราจีนบุรี 25130",
      label: "โพธิ์งาม ประจันตคาม ปราจีนบุรี 25130",
    },
    {
      value: "กรอกสมบูรณ์ ศรีมหาโพธิ ปราจีนบุรี 25140",
      label: "กรอกสมบูรณ์ ศรีมหาโพธิ ปราจีนบุรี 25140",
    },
    {
      value: "ดงกระทงยาม ศรีมหาโพธิ ปราจีนบุรี 25140",
      label: "ดงกระทงยาม ศรีมหาโพธิ ปราจีนบุรี 25140",
    },
    {
      value: "ท่าตูม ศรีมหาโพธิ ปราจีนบุรี 25140",
      label: "ท่าตูม ศรีมหาโพธิ ปราจีนบุรี 25140",
    },
    {
      value: "บางกุ้ง ศรีมหาโพธิ ปราจีนบุรี 25140",
      label: "บางกุ้ง ศรีมหาโพธิ ปราจีนบุรี 25140",
    },
    {
      value: "บ้านทาม ศรีมหาโพธิ ปราจีนบุรี 25140",
      label: "บ้านทาม ศรีมหาโพธิ ปราจีนบุรี 25140",
    },
    {
      value: "ศรีมหาโพธิ ศรีมหาโพธิ ปราจีนบุรี 25140",
      label: "ศรีมหาโพธิ ศรีมหาโพธิ ปราจีนบุรี 25140",
    },
    {
      value: "สัมพันธ์ ศรีมหาโพธิ ปราจีนบุรี 25140",
      label: "สัมพันธ์ ศรีมหาโพธิ ปราจีนบุรี 25140",
    },
    {
      value: "หนองโพรง ศรีมหาโพธิ ปราจีนบุรี 25140",
      label: "หนองโพรง ศรีมหาโพธิ ปราจีนบุรี 25140",
    },
    {
      value: "หัวหว้า ศรีมหาโพธิ ปราจีนบุรี 25140",
      label: "หัวหว้า ศรีมหาโพธิ ปราจีนบุรี 25140",
    },
    {
      value: "หาดยาง ศรีมหาโพธิ ปราจีนบุรี 25140",
      label: "หาดยาง ศรีมหาโพธิ ปราจีนบุรี 25140",
    },
    {
      value: "คู้ลำพัน ศรีมโหสถ ปราจีนบุรี 25190",
      label: "คู้ลำพัน ศรีมโหสถ ปราจีนบุรี 25190",
    },
    {
      value: "โคกปีบ ศรีมโหสถ ปราจีนบุรี 25190",
      label: "โคกปีบ ศรีมโหสถ ปราจีนบุรี 25190",
    },
    {
      value: "โคกไทย ศรีมโหสถ ปราจีนบุรี 25190",
      label: "โคกไทย ศรีมโหสถ ปราจีนบุรี 25190",
    },
    {
      value: "ไผ่ชะเลือด ศรีมโหสถ ปราจีนบุรี 25190",
      label: "ไผ่ชะเลือด ศรีมโหสถ ปราจีนบุรี 25190",
    },
    {
      value: "ดงขี้เหล็ก เมืองปราจีนบุรี ปราจีนบุรี 25000",
      label: "ดงขี้เหล็ก เมืองปราจีนบุรี ปราจีนบุรี 25000",
    },
    {
      value: "ดงพระราม เมืองปราจีนบุรี ปราจีนบุรี 25000",
      label: "ดงพระราม เมืองปราจีนบุรี ปราจีนบุรี 25000",
    },
    {
      value: "ท่างาม เมืองปราจีนบุรี ปราจีนบุรี 25000",
      label: "ท่างาม เมืองปราจีนบุรี ปราจีนบุรี 25000",
    },
    {
      value: "บางบริบูรณ์ เมืองปราจีนบุรี ปราจีนบุรี 25000",
      label: "บางบริบูรณ์ เมืองปราจีนบุรี ปราจีนบุรี 25000",
    },
    {
      value: "บางเดชะ เมืองปราจีนบุรี ปราจีนบุรี 25000",
      label: "บางเดชะ เมืองปราจีนบุรี ปราจีนบุรี 25000",
    },
    {
      value: "บ้านพระ เมืองปราจีนบุรี ปราจีนบุรี 25230",
      label: "บ้านพระ เมืองปราจีนบุรี ปราจีนบุรี 25230",
    },
    {
      value: "รอบเมือง เมืองปราจีนบุรี ปราจีนบุรี 25000",
      label: "รอบเมือง เมืองปราจีนบุรี ปราจีนบุรี 25000",
    },
    {
      value: "วัดโบสถ์ เมืองปราจีนบุรี ปราจีนบุรี 25000",
      label: "วัดโบสถ์ เมืองปราจีนบุรี ปราจีนบุรี 25000",
    },
    {
      value: "หน้าเมือง เมืองปราจีนบุรี ปราจีนบุรี 25000",
      label: "หน้าเมือง เมืองปราจีนบุรี ปราจีนบุรี 25000",
    },
    {
      value: "เนินหอม เมืองปราจีนบุรี ปราจีนบุรี 25230",
      label: "เนินหอม เมืองปราจีนบุรี ปราจีนบุรี 25230",
    },
    {
      value: "โคกไม้ลาย เมืองปราจีนบุรี ปราจีนบุรี 25230",
      label: "โคกไม้ลาย เมืองปราจีนบุรี ปราจีนบุรี 25230",
    },
    {
      value: "โนนห้อม เมืองปราจีนบุรี ปราจีนบุรี 25000",
      label: "โนนห้อม เมืองปราจีนบุรี ปราจีนบุรี 25000",
    },
    {
      value: "ไม้เค็ด เมืองปราจีนบุรี ปราจีนบุรี 25230",
      label: "ไม้เค็ด เมืองปราจีนบุรี ปราจีนบุรี 25230",
    },
    {
      value: "นิคมพัฒนา นิคมพัฒนา ระยอง 21180",
      label: "นิคมพัฒนา นิคมพัฒนา ระยอง 21180",
    },
    {
      value: "พนานิคม นิคมพัฒนา ระยอง 21180",
      label: "พนานิคม นิคมพัฒนา ระยอง 21180",
    },
    {
      value: "มะขามคู่ นิคมพัฒนา ระยอง 21180",
      label: "มะขามคู่ นิคมพัฒนา ระยอง 21180",
    },
    {
      value: "มาบข่า นิคมพัฒนา ระยอง 21180",
      label: "มาบข่า นิคมพัฒนา ระยอง 21180",
    },
    { value: "ชากบก บ้านค่าย ระยอง 21120", label: "ชากบก บ้านค่าย ระยอง 21120" },
    { value: "ตาขัน บ้านค่าย ระยอง 21120", label: "ตาขัน บ้านค่าย ระยอง 21120" },
    {
      value: "บางบุตร บ้านค่าย ระยอง 21120",
      label: "บางบุตร บ้านค่าย ระยอง 21120",
    },
    {
      value: "บ้านค่าย บ้านค่าย ระยอง 21120",
      label: "บ้านค่าย บ้านค่าย ระยอง 21120",
    },
    {
      value: "หนองตะพาน บ้านค่าย ระยอง 21120",
      label: "หนองตะพาน บ้านค่าย ระยอง 21120",
    },
    {
      value: "หนองบัว บ้านค่าย ระยอง 21120",
      label: "หนองบัว บ้านค่าย ระยอง 21120",
    },
    {
      value: "หนองละลอก บ้านค่าย ระยอง 21120",
      label: "หนองละลอก บ้านค่าย ระยอง 21120",
    },
    {
      value: "บ้านฉาง บ้านฉาง ระยอง 21130",
      label: "บ้านฉาง บ้านฉาง ระยอง 21130",
    },
    { value: "พลา บ้านฉาง ระยอง 21130", label: "พลา บ้านฉาง ระยอง 21130" },
    {
      value: "สำนักท้อน บ้านฉาง ระยอง 21130",
      label: "สำนักท้อน บ้านฉาง ระยอง 21130",
    },
    {
      value: "ตาสิทธิ์ ปลวกแดง ระยอง 21140",
      label: "ตาสิทธิ์ ปลวกแดง ระยอง 21140",
    },
    {
      value: "ปลวกแดง ปลวกแดง ระยอง 21140",
      label: "ปลวกแดง ปลวกแดง ระยอง 21140",
    },
    {
      value: "มาบยางพร ปลวกแดง ระยอง 21140",
      label: "มาบยางพร ปลวกแดง ระยอง 21140",
    },
    { value: "ละหาร ปลวกแดง ระยอง 21140", label: "ละหาร ปลวกแดง ระยอง 21140" },
    {
      value: "หนองไร่ ปลวกแดง ระยอง 21140",
      label: "หนองไร่ ปลวกแดง ระยอง 21140",
    },
    {
      value: "แม่น้ำคู้ ปลวกแดง ระยอง 21140",
      label: "แม่น้ำคู้ ปลวกแดง ระยอง 21140",
    },
    {
      value: "ชุมแสง วังจันทร์ ระยอง 21210",
      label: "ชุมแสง วังจันทร์ ระยอง 21210",
    },
    {
      value: "ป่ายุบใน วังจันทร์ ระยอง 21210",
      label: "ป่ายุบใน วังจันทร์ ระยอง 21210",
    },
    {
      value: "พลงตาเอี่ยม วังจันทร์ ระยอง 21210",
      label: "พลงตาเอี่ยม วังจันทร์ ระยอง 21210",
    },
    {
      value: "วังจันทร์ วังจันทร์ ระยอง 21210",
      label: "วังจันทร์ วังจันทร์ ระยอง 21210",
    },
    { value: "ชำฆ้อ เขาชะเมา ระยอง 21110", label: "ชำฆ้อ เขาชะเมา ระยอง 21110" },
    {
      value: "น้ำเป็น เขาชะเมา ระยอง 21110",
      label: "น้ำเป็น เขาชะเมา ระยอง 21110",
    },
    {
      value: "ห้วยทับมอญ เขาชะเมา ระยอง 21110",
      label: "ห้วยทับมอญ เขาชะเมา ระยอง 21110",
    },
    {
      value: "เขาน้อย เขาชะเมา ระยอง 21110",
      label: "เขาน้อย เขาชะเมา ระยอง 21110",
    },
    {
      value: "กะเฉด เมืองระยอง ระยอง 21100",
      label: "กะเฉด เมืองระยอง ระยอง 21100",
    },
    {
      value: "ตะพง เมืองระยอง ระยอง 21000",
      label: "ตะพง เมืองระยอง ระยอง 21000",
    },
    {
      value: "ทับมา เมืองระยอง ระยอง 21000",
      label: "ทับมา เมืองระยอง ระยอง 21000",
    },
    {
      value: "ท่าประดู่ เมืองระยอง ระยอง 21000",
      label: "ท่าประดู่ เมืองระยอง ระยอง 21000",
    },
    {
      value: "นาตาขวัญ เมืองระยอง ระยอง 21000",
      label: "นาตาขวัญ เมืองระยอง ระยอง 21000",
    },
    {
      value: "น้ำคอก เมืองระยอง ระยอง 21000",
      label: "น้ำคอก เมืองระยอง ระยอง 21000",
    },
    {
      value: "บ้านแลง เมืองระยอง ระยอง 21000",
      label: "บ้านแลง เมืองระยอง ระยอง 21000",
    },
    {
      value: "ปากน้ำ เมืองระยอง ระยอง 21000",
      label: "ปากน้ำ เมืองระยอง ระยอง 21000",
    },
    {
      value: "มาบตาพุด เมืองระยอง ระยอง 21150",
      label: "มาบตาพุด เมืองระยอง ระยอง 21150",
    },
    {
      value: "สำนักทอง เมืองระยอง ระยอง 21100",
      label: "สำนักทอง เมืองระยอง ระยอง 21100",
    },
    {
      value: "ห้วยโป่ง เมืองระยอง ระยอง 21150",
      label: "ห้วยโป่ง เมืองระยอง ระยอง 21150",
    },
    {
      value: "เชิงเนิน เมืองระยอง ระยอง 21000",
      label: "เชิงเนิน เมืองระยอง ระยอง 21000",
    },
    {
      value: "เนินพระ เมืองระยอง ระยอง 21000",
      label: "เนินพระ เมืองระยอง ระยอง 21000",
    },
    {
      value: "เนินพระ เมืองระยอง ระยอง 21150",
      label: "เนินพระ เมืองระยอง ระยอง 21150",
    },
    { value: "เพ เมืองระยอง ระยอง 21160", label: "เพ เมืองระยอง ระยอง 21160" },
    {
      value: "แกลง เมืองระยอง ระยอง 21160",
      label: "แกลง เมืองระยอง ระยอง 21160",
    },
    { value: "กระแสบน แกลง ระยอง 21110", label: "กระแสบน แกลง ระยอง 21110" },
    { value: "กร่ำ แกลง ระยอง 21190", label: "กร่ำ แกลง ระยอง 21190" },
    { value: "กองดิน แกลง ระยอง 22160", label: "กองดิน แกลง ระยอง 22160" },
    { value: "คลองปูน แกลง ระยอง 21170", label: "คลองปูน แกลง ระยอง 21170" },
    { value: "ชากพง แกลง ระยอง 21190", label: "ชากพง แกลง ระยอง 21190" },
    { value: "ชากโดน แกลง ระยอง 21110", label: "ชากโดน แกลง ระยอง 21110" },
    { value: "ทางเกวียน แกลง ระยอง 21110", label: "ทางเกวียน แกลง ระยอง 21110" },
    {
      value: "ทุ่งควายกิน แกลง ระยอง 21110",
      label: "ทุ่งควายกิน แกลง ระยอง 21110",
    },
    { value: "บ้านนา แกลง ระยอง 21110", label: "บ้านนา แกลง ระยอง 21110" },
    {
      value: "ปากน้ำกระแส แกลง ระยอง 21170",
      label: "ปากน้ำกระแส แกลง ระยอง 21170",
    },
    { value: "พังราด แกลง ระยอง 21170", label: "พังราด แกลง ระยอง 21170" },
    { value: "วังหว้า แกลง ระยอง 21110", label: "วังหว้า แกลง ระยอง 21110" },
    { value: "สองสลึง แกลง ระยอง 21110", label: "สองสลึง แกลง ระยอง 21110" },
    { value: "ห้วยยาง แกลง ระยอง 21110", label: "ห้วยยาง แกลง ระยอง 21110" },
    { value: "เนินฆ้อ แกลง ระยอง 21110", label: "เนินฆ้อ แกลง ระยอง 21110" },
    {
      value: "คลองหาด คลองหาด สระแก้ว 27260",
      label: "คลองหาด คลองหาด สระแก้ว 27260",
    },
    {
      value: "คลองไก่เถื่อน คลองหาด สระแก้ว 27260",
      label: "คลองไก่เถื่อน คลองหาด สระแก้ว 27260",
    },
    {
      value: "ซับมะกรูด คลองหาด สระแก้ว 27260",
      label: "ซับมะกรูด คลองหาด สระแก้ว 27260",
    },
    {
      value: "เบญจขร คลองหาด สระแก้ว 27260",
      label: "เบญจขร คลองหาด สระแก้ว 27260",
    },
    {
      value: "ไทยอุดม คลองหาด สระแก้ว 27260",
      label: "ไทยอุดม คลองหาด สระแก้ว 27260",
    },
    {
      value: "ไทรทอง คลองหาด สระแก้ว 27260",
      label: "ไทรทอง คลองหาด สระแก้ว 27260",
    },
    {
      value: "ไทรเดี่ยว คลองหาด สระแก้ว 27260",
      label: "ไทรเดี่ยว คลองหาด สระแก้ว 27260",
    },
    {
      value: "ตาพระยา ตาพระยา สระแก้ว 27180",
      label: "ตาพระยา ตาพระยา สระแก้ว 27180",
    },
    {
      value: "ทัพราช ตาพระยา สระแก้ว 27180",
      label: "ทัพราช ตาพระยา สระแก้ว 27180",
    },
    {
      value: "ทัพเสด็จ ตาพระยา สระแก้ว 27180",
      label: "ทัพเสด็จ ตาพระยา สระแก้ว 27180",
    },
    {
      value: "ทัพไทย ตาพระยา สระแก้ว 27180",
      label: "ทัพไทย ตาพระยา สระแก้ว 27180",
    },
    {
      value: "โคคลาน ตาพระยา สระแก้ว 27180",
      label: "โคคลาน ตาพระยา สระแก้ว 27180",
    },
    {
      value: "คลองหินปูน วังน้ำเย็น สระแก้ว 27210",
      label: "คลองหินปูน วังน้ำเย็น สระแก้ว 27210",
    },
    {
      value: "ตาหลังใน วังน้ำเย็น สระแก้ว 27210",
      label: "ตาหลังใน วังน้ำเย็น สระแก้ว 27210",
    },
    {
      value: "ทุ่งมหาเจริญ วังน้ำเย็น สระแก้ว 27210",
      label: "ทุ่งมหาเจริญ วังน้ำเย็น สระแก้ว 27210",
    },
    {
      value: "วังน้ำเย็น วังน้ำเย็น สระแก้ว 27210",
      label: "วังน้ำเย็น วังน้ำเย็น สระแก้ว 27210",
    },
    {
      value: "วังทอง วังสมบูรณ์ สระแก้ว 27250",
      label: "วังทอง วังสมบูรณ์ สระแก้ว 27250",
    },
    {
      value: "วังสมบูรณ์ วังสมบูรณ์ สระแก้ว 27250",
      label: "วังสมบูรณ์ วังสมบูรณ์ สระแก้ว 27250",
    },
    {
      value: "วังใหม่ วังสมบูรณ์ สระแก้ว 27250",
      label: "วังใหม่ วังสมบูรณ์ สระแก้ว 27250",
    },
    {
      value: "ช่องกุ่ม วัฒนานคร สระแก้ว 27160",
      label: "ช่องกุ่ม วัฒนานคร สระแก้ว 27160",
    },
    {
      value: "ท่าเกวียน วัฒนานคร สระแก้ว 27160",
      label: "ท่าเกวียน วัฒนานคร สระแก้ว 27160",
    },
    {
      value: "ผักขะ วัฒนานคร สระแก้ว 27160",
      label: "ผักขะ วัฒนานคร สระแก้ว 27160",
    },
    {
      value: "วัฒนานคร วัฒนานคร สระแก้ว 27160",
      label: "วัฒนานคร วัฒนานคร สระแก้ว 27160",
    },
    {
      value: "หนองตะเคียนบอน วัฒนานคร สระแก้ว 27160",
      label: "หนองตะเคียนบอน วัฒนานคร สระแก้ว 27160",
    },
    {
      value: "หนองน้ำใส วัฒนานคร สระแก้ว 27160",
      label: "หนองน้ำใส วัฒนานคร สระแก้ว 27160",
    },
    {
      value: "หนองหมากฝ้าย วัฒนานคร สระแก้ว 27160",
      label: "หนองหมากฝ้าย วัฒนานคร สระแก้ว 27160",
    },
    {
      value: "หนองแวง วัฒนานคร สระแก้ว 27160",
      label: "หนองแวง วัฒนานคร สระแก้ว 27160",
    },
    {
      value: "ห้วยโจด วัฒนานคร สระแก้ว 27160",
      label: "ห้วยโจด วัฒนานคร สระแก้ว 27160",
    },
    {
      value: "แซร์ออ วัฒนานคร สระแก้ว 27160",
      label: "แซร์ออ วัฒนานคร สระแก้ว 27160",
    },
    {
      value: "โนนหมากเค็ง วัฒนานคร สระแก้ว 27160",
      label: "โนนหมากเค็ง วัฒนานคร สระแก้ว 27160",
    },
    {
      value: "คลองทับจันทร์ อรัญประเทศ สระแก้ว 27120",
      label: "คลองทับจันทร์ อรัญประเทศ สระแก้ว 27120",
    },
    {
      value: "คลองน้ำใส อรัญประเทศ สระแก้ว 27120",
      label: "คลองน้ำใส อรัญประเทศ สระแก้ว 27120",
    },
    {
      value: "ทับพริก อรัญประเทศ สระแก้ว 27120",
      label: "ทับพริก อรัญประเทศ สระแก้ว 27120",
    },
    {
      value: "ท่าข้าม อรัญประเทศ สระแก้ว 27120",
      label: "ท่าข้าม อรัญประเทศ สระแก้ว 27120",
    },
    {
      value: "บ้านด่าน อรัญประเทศ สระแก้ว 27120",
      label: "บ้านด่าน อรัญประเทศ สระแก้ว 27120",
    },
    {
      value: "บ้านใหม่หนองไทร อรัญประเทศ สระแก้ว 27120",
      label: "บ้านใหม่หนองไทร อรัญประเทศ สระแก้ว 27120",
    },
    {
      value: "ป่าไร่ อรัญประเทศ สระแก้ว 27120",
      label: "ป่าไร่ อรัญประเทศ สระแก้ว 27120",
    },
    {
      value: "ผ่านศึก อรัญประเทศ สระแก้ว 27120",
      label: "ผ่านศึก อรัญประเทศ สระแก้ว 27120",
    },
    {
      value: "ฟากห้วย อรัญประเทศ สระแก้ว 27120",
      label: "ฟากห้วย อรัญประเทศ สระแก้ว 27120",
    },
    {
      value: "หนองสังข์ อรัญประเทศ สระแก้ว 27120",
      label: "หนองสังข์ อรัญประเทศ สระแก้ว 27120",
    },
    {
      value: "หันทราย อรัญประเทศ สระแก้ว 27120",
      label: "หันทราย อรัญประเทศ สระแก้ว 27120",
    },
    {
      value: "อรัญประเทศ อรัญประเทศ สระแก้ว 27120",
      label: "อรัญประเทศ อรัญประเทศ สระแก้ว 27120",
    },
    {
      value: "เมืองไผ่ อรัญประเทศ สระแก้ว 27120",
      label: "เมืองไผ่ อรัญประเทศ สระแก้ว 27120",
    },
    {
      value: "พระเพลิง เขาฉกรรจ์ สระแก้ว 27000",
      label: "พระเพลิง เขาฉกรรจ์ สระแก้ว 27000",
    },
    {
      value: "หนองหว้า เขาฉกรรจ์ สระแก้ว 27000",
      label: "หนองหว้า เขาฉกรรจ์ สระแก้ว 27000",
    },
    {
      value: "เขาฉกรรจ์ เขาฉกรรจ์ สระแก้ว 27000",
      label: "เขาฉกรรจ์ เขาฉกรรจ์ สระแก้ว 27000",
    },
    {
      value: "เขาสามสิบ เขาฉกรรจ์ สระแก้ว 27000",
      label: "เขาสามสิบ เขาฉกรรจ์ สระแก้ว 27000",
    },
    {
      value: "ท่าเกษม เมืองสระแก้ว สระแก้ว 27000",
      label: "ท่าเกษม เมืองสระแก้ว สระแก้ว 27000",
    },
    {
      value: "ท่าแยก เมืองสระแก้ว สระแก้ว 27000",
      label: "ท่าแยก เมืองสระแก้ว สระแก้ว 27000",
    },
    {
      value: "บ้านแก้ง เมืองสระแก้ว สระแก้ว 27000",
      label: "บ้านแก้ง เมืองสระแก้ว สระแก้ว 27000",
    },
    {
      value: "ศาลาลำดวน เมืองสระแก้ว สระแก้ว 27000",
      label: "ศาลาลำดวน เมืองสระแก้ว สระแก้ว 27000",
    },
    {
      value: "สระขวัญ เมืองสระแก้ว สระแก้ว 27000",
      label: "สระขวัญ เมืองสระแก้ว สระแก้ว 27000",
    },
    {
      value: "สระแก้ว เมืองสระแก้ว สระแก้ว 27000",
      label: "สระแก้ว เมืองสระแก้ว สระแก้ว 27000",
    },
    {
      value: "หนองบอน เมืองสระแก้ว สระแก้ว 27000",
      label: "หนองบอน เมืองสระแก้ว สระแก้ว 27000",
    },
    {
      value: "โคกปี่ฆ้อง เมืองสระแก้ว สระแก้ว 27000",
      label: "โคกปี่ฆ้อง เมืองสระแก้ว สระแก้ว 27000",
    },
    {
      value: "หนองม่วง โคกสูง สระแก้ว 27180",
      label: "หนองม่วง โคกสูง สระแก้ว 27180",
    },
    {
      value: "หนองแวง โคกสูง สระแก้ว 27180",
      label: "หนองแวง โคกสูง สระแก้ว 27180",
    },
    {
      value: "โคกสูง โคกสูง สระแก้ว 27120",
      label: "โคกสูง โคกสูง สระแก้ว 27120",
    },
    {
      value: "โนนหมากมุ่น โคกสูง สระแก้ว 27120",
      label: "โนนหมากมุ่น โคกสูง สระแก้ว 27120",
    },
    {
      value: "กลอนโด ด่านมะขามเตี้ย กาญจนบุรี 71260",
      label: "กลอนโด ด่านมะขามเตี้ย กาญจนบุรี 71260",
    },
    {
      value: "จรเข้เผือก ด่านมะขามเตี้ย กาญจนบุรี 71260",
      label: "จรเข้เผือก ด่านมะขามเตี้ย กาญจนบุรี 71260",
    },
    {
      value: "ด่านมะขามเตี้ย ด่านมะขามเตี้ย กาญจนบุรี 71260",
      label: "ด่านมะขามเตี้ย ด่านมะขามเตี้ย กาญจนบุรี 71260",
    },
    {
      value: "หนองไผ่ ด่านมะขามเตี้ย กาญจนบุรี 71260",
      label: "หนองไผ่ ด่านมะขามเตี้ย กาญจนบุรี 71260",
    },
    {
      value: "ชะแล ทองผาภูมิ กาญจนบุรี 71180",
      label: "ชะแล ทองผาภูมิ กาญจนบุรี 71180",
    },
    {
      value: "ท่าขนุน ทองผาภูมิ กาญจนบุรี 71180",
      label: "ท่าขนุน ทองผาภูมิ กาญจนบุรี 71180",
    },
    {
      value: "ปิล๊อก ทองผาภูมิ กาญจนบุรี 71180",
      label: "ปิล๊อก ทองผาภูมิ กาญจนบุรี 71180",
    },
    {
      value: "ลิ่นถิ่น ทองผาภูมิ กาญจนบุรี 71180",
      label: "ลิ่นถิ่น ทองผาภูมิ กาญจนบุรี 71180",
    },
    {
      value: "สหกรณ์นิคม ทองผาภูมิ กาญจนบุรี 71180",
      label: "สหกรณ์นิคม ทองผาภูมิ กาญจนบุรี 71180",
    },
    {
      value: "หินดาด ทองผาภูมิ กาญจนบุรี 71180",
      label: "หินดาด ทองผาภูมิ กาญจนบุรี 71180",
    },
    {
      value: "ห้วยเขย่ง ทองผาภูมิ กาญจนบุรี 71180",
      label: "ห้วยเขย่ง ทองผาภูมิ กาญจนบุรี 71180",
    },
    {
      value: "ดอนขมิ้น ท่ามะกา กาญจนบุรี 71120",
      label: "ดอนขมิ้น ท่ามะกา กาญจนบุรี 71120",
    },
    {
      value: "ดอนชะเอม ท่ามะกา กาญจนบุรี 71130",
      label: "ดอนชะเอม ท่ามะกา กาญจนบุรี 71130",
    },
    {
      value: "ตะคร้ำเอน ท่ามะกา กาญจนบุรี 71130",
      label: "ตะคร้ำเอน ท่ามะกา กาญจนบุรี 71130",
    },
    {
      value: "ท่ามะกา ท่ามะกา กาญจนบุรี 71120",
      label: "ท่ามะกา ท่ามะกา กาญจนบุรี 71120",
    },
    {
      value: "ท่าเรือ ท่ามะกา กาญจนบุรี 71130",
      label: "ท่าเรือ ท่ามะกา กาญจนบุรี 71130",
    },
    {
      value: "ท่าเสา ท่ามะกา กาญจนบุรี 71120",
      label: "ท่าเสา ท่ามะกา กาญจนบุรี 71120",
    },
    {
      value: "ท่าไม้ ท่ามะกา กาญจนบุรี 71120",
      label: "ท่าไม้ ท่ามะกา กาญจนบุรี 71120",
    },
    {
      value: "พงตึก ท่ามะกา กาญจนบุรี 71120",
      label: "พงตึก ท่ามะกา กาญจนบุรี 71120",
    },
    {
      value: "พระแท่น ท่ามะกา กาญจนบุรี 71130",
      label: "พระแท่น ท่ามะกา กาญจนบุรี 71130",
    },
    {
      value: "ยางม่วง ท่ามะกา กาญจนบุรี 71120",
      label: "ยางม่วง ท่ามะกา กาญจนบุรี 71120",
    },
    {
      value: "สนามแย้ ท่ามะกา กาญจนบุรี 70190",
      label: "สนามแย้ ท่ามะกา กาญจนบุรี 70190",
    },
    {
      value: "หนองลาน ท่ามะกา กาญจนบุรี 71130",
      label: "หนองลาน ท่ามะกา กาญจนบุรี 71130",
    },
    {
      value: "หวายเหนียว ท่ามะกา กาญจนบุรี 71120",
      label: "หวายเหนียว ท่ามะกา กาญจนบุรี 71120",
    },
    {
      value: "อุโลกสี่หมื่น ท่ามะกา กาญจนบุรี 71130",
      label: "อุโลกสี่หมื่น ท่ามะกา กาญจนบุรี 71130",
    },
    {
      value: "เขาสามสิบหาบ ท่ามะกา กาญจนบุรี 71120",
      label: "เขาสามสิบหาบ ท่ามะกา กาญจนบุรี 71120",
    },
    {
      value: "แสนตอ ท่ามะกา กาญจนบุรี 71130",
      label: "แสนตอ ท่ามะกา กาญจนบุรี 71130",
    },
    {
      value: "โคกตะบอง ท่ามะกา กาญจนบุรี 71120",
      label: "โคกตะบอง ท่ามะกา กาญจนบุรี 71120",
    },
    {
      value: "ทุ่งทอง ท่าม่วง กาญจนบุรี 71110",
      label: "ทุ่งทอง ท่าม่วง กาญจนบุรี 71110",
    },
    {
      value: "ท่าตะคร้อ ท่าม่วง กาญจนบุรี 71130",
      label: "ท่าตะคร้อ ท่าม่วง กาญจนบุรี 71130",
    },
    {
      value: "ท่าม่วง ท่าม่วง กาญจนบุรี 71110",
      label: "ท่าม่วง ท่าม่วง กาญจนบุรี 71110",
    },
    {
      value: "ท่าล้อ ท่าม่วง กาญจนบุรี 71000",
      label: "ท่าล้อ ท่าม่วง กาญจนบุรี 71000",
    },
    {
      value: "บ้านใหม่ ท่าม่วง กาญจนบุรี 71110",
      label: "บ้านใหม่ ท่าม่วง กาญจนบุรี 71110",
    },
    {
      value: "พังตรุ ท่าม่วง กาญจนบุรี 71110",
      label: "พังตรุ ท่าม่วง กาญจนบุรี 71110",
    },
    {
      value: "ม่วงชุม ท่าม่วง กาญจนบุรี 71110",
      label: "ม่วงชุม ท่าม่วง กาญจนบุรี 71110",
    },
    {
      value: "รางสาลี่ ท่าม่วง กาญจนบุรี 71110",
      label: "รางสาลี่ ท่าม่วง กาญจนบุรี 71110",
    },
    {
      value: "วังขนาย ท่าม่วง กาญจนบุรี 71110",
      label: "วังขนาย ท่าม่วง กาญจนบุรี 71110",
    },
    {
      value: "วังศาลา ท่าม่วง กาญจนบุรี 71110",
      label: "วังศาลา ท่าม่วง กาญจนบุรี 71110",
    },
    {
      value: "หนองขาว ท่าม่วง กาญจนบุรี 71110",
      label: "หนองขาว ท่าม่วง กาญจนบุรี 71110",
    },
    {
      value: "หนองตากยา ท่าม่วง กาญจนบุรี 71110",
      label: "หนองตากยา ท่าม่วง กาญจนบุรี 71110",
    },
    {
      value: "เขาน้อย ท่าม่วง กาญจนบุรี 71110",
      label: "เขาน้อย ท่าม่วง กาญจนบุรี 71110",
    },
    {
      value: "ช่องด่าน บ่อพลอย กาญจนบุรี 71160",
      label: "ช่องด่าน บ่อพลอย กาญจนบุรี 71160",
    },
    {
      value: "บ่อพลอย บ่อพลอย กาญจนบุรี 71160",
      label: "บ่อพลอย บ่อพลอย กาญจนบุรี 71160",
    },
    {
      value: "หนองกร่าง บ่อพลอย กาญจนบุรี 71220",
      label: "หนองกร่าง บ่อพลอย กาญจนบุรี 71220",
    },
    {
      value: "หนองกุ่ม บ่อพลอย กาญจนบุรี 71160",
      label: "หนองกุ่ม บ่อพลอย กาญจนบุรี 71160",
    },
    {
      value: "หนองรี บ่อพลอย กาญจนบุรี 71220",
      label: "หนองรี บ่อพลอย กาญจนบุรี 71220",
    },
    {
      value: "หลุมรัง บ่อพลอย กาญจนบุรี 71160",
      label: "หลุมรัง บ่อพลอย กาญจนบุรี 71160",
    },
    {
      value: "ดอนตาเพชร พนมทวน กาญจนบุรี 71140",
      label: "ดอนตาเพชร พนมทวน กาญจนบุรี 71140",
    },
    {
      value: "ดอนเจดีย์ พนมทวน กาญจนบุรี 71140",
      label: "ดอนเจดีย์ พนมทวน กาญจนบุรี 71140",
    },
    {
      value: "ทุ่งสมอ พนมทวน กาญจนบุรี 71140",
      label: "ทุ่งสมอ พนมทวน กาญจนบุรี 71140",
    },
    {
      value: "พนมทวน พนมทวน กาญจนบุรี 71140",
      label: "พนมทวน พนมทวน กาญจนบุรี 71140",
    },
    {
      value: "พังตรุ พนมทวน กาญจนบุรี 71140",
      label: "พังตรุ พนมทวน กาญจนบุรี 71140",
    },
    {
      value: "รางหวาย พนมทวน กาญจนบุรี 71170",
      label: "รางหวาย พนมทวน กาญจนบุรี 71170",
    },
    {
      value: "หนองสาหร่าย พนมทวน กาญจนบุรี 71140",
      label: "หนองสาหร่าย พนมทวน กาญจนบุรี 71140",
    },
    {
      value: "หนองโรง พนมทวน กาญจนบุรี 71140",
      label: "หนองโรง พนมทวน กาญจนบุรี 71140",
    },
    {
      value: "ด่านแม่แฉลบ ศรีสวัสดิ์ กาญจนบุรี 71250",
      label: "ด่านแม่แฉลบ ศรีสวัสดิ์ กาญจนบุรี 71250",
    },
    {
      value: "ท่ากระดาน ศรีสวัสดิ์ กาญจนบุรี 71250",
      label: "ท่ากระดาน ศรีสวัสดิ์ กาญจนบุรี 71250",
    },
    {
      value: "นาสวน ศรีสวัสดิ์ กาญจนบุรี 71250",
      label: "นาสวน ศรีสวัสดิ์ กาญจนบุรี 71250",
    },
    {
      value: "หนองเป็ด ศรีสวัสดิ์ กาญจนบุรี 71250",
      label: "หนองเป็ด ศรีสวัสดิ์ กาญจนบุรี 71250",
    },
    {
      value: "เขาโจด ศรีสวัสดิ์ กาญจนบุรี 71220",
      label: "เขาโจด ศรีสวัสดิ์ กาญจนบุรี 71220",
    },
    {
      value: "แม่กระบุง ศรีสวัสดิ์ กาญจนบุรี 71250",
      label: "แม่กระบุง ศรีสวัสดิ์ กาญจนบุรี 71250",
    },
    {
      value: "ปรังเผล สังขละบุรี กาญจนบุรี 71240",
      label: "ปรังเผล สังขละบุรี กาญจนบุรี 71240",
    },
    {
      value: "หนองลู สังขละบุรี กาญจนบุรี 71240",
      label: "หนองลู สังขละบุรี กาญจนบุรี 71240",
    },
    {
      value: "ไล่โว่ สังขละบุรี กาญจนบุรี 71240",
      label: "ไล่โว่ สังขละบุรี กาญจนบุรี 71240",
    },
    {
      value: "สมเด็จเจริญ หนองปรือ กาญจนบุรี 71220",
      label: "สมเด็จเจริญ หนองปรือ กาญจนบุรี 71220",
    },
    {
      value: "หนองปรือ หนองปรือ กาญจนบุรี 71220",
      label: "หนองปรือ หนองปรือ กาญจนบุรี 71220",
    },
    {
      value: "หนองปลาไหล หนองปรือ กาญจนบุรี 71220",
      label: "หนองปลาไหล หนองปรือ กาญจนบุรี 71220",
    },
    {
      value: "ดอนแสลบ ห้วยกระเจา กาญจนบุรี 71170",
      label: "ดอนแสลบ ห้วยกระเจา กาญจนบุรี 71170",
    },
    {
      value: "วังไผ่ ห้วยกระเจา กาญจนบุรี 71170",
      label: "วังไผ่ ห้วยกระเจา กาญจนบุรี 71170",
    },
    {
      value: "สระลงเรือ ห้วยกระเจา กาญจนบุรี 71170",
      label: "สระลงเรือ ห้วยกระเจา กาญจนบุรี 71170",
    },
    {
      value: "ห้วยกระเจา ห้วยกระเจา กาญจนบุรี 71170",
      label: "ห้วยกระเจา ห้วยกระเจา กาญจนบุรี 71170",
    },
    {
      value: "ช่องสะเดา เมืองกาญจนบุรี กาญจนบุรี 71190",
      label: "ช่องสะเดา เมืองกาญจนบุรี กาญจนบุรี 71190",
    },
    {
      value: "ท่ามะขาม เมืองกาญจนบุรี กาญจนบุรี 71000",
      label: "ท่ามะขาม เมืองกาญจนบุรี กาญจนบุรี 71000",
    },
    {
      value: "บ้านเก่า เมืองกาญจนบุรี กาญจนบุรี 71000",
      label: "บ้านเก่า เมืองกาญจนบุรี กาญจนบุรี 71000",
    },
    {
      value: "บ้านเหนือ เมืองกาญจนบุรี กาญจนบุรี 71000",
      label: "บ้านเหนือ เมืองกาญจนบุรี กาญจนบุรี 71000",
    },
    {
      value: "บ้านใต้ เมืองกาญจนบุรี กาญจนบุรี 71000",
      label: "บ้านใต้ เมืองกาญจนบุรี กาญจนบุรี 71000",
    },
    {
      value: "ปากแพรก เมืองกาญจนบุรี กาญจนบุรี 71000",
      label: "ปากแพรก เมืองกาญจนบุรี กาญจนบุรี 71000",
    },
    {
      value: "ลาดหญ้า เมืองกาญจนบุรี กาญจนบุรี 71190",
      label: "ลาดหญ้า เมืองกาญจนบุรี กาญจนบุรี 71190",
    },
    {
      value: "วังด้ง เมืองกาญจนบุรี กาญจนบุรี 71190",
      label: "วังด้ง เมืองกาญจนบุรี กาญจนบุรี 71190",
    },
    {
      value: "วังเย็น เมืองกาญจนบุรี กาญจนบุรี 71000",
      label: "วังเย็น เมืองกาญจนบุรี กาญจนบุรี 71000",
    },
    {
      value: "หนองบัว เมืองกาญจนบุรี กาญจนบุรี 71190",
      label: "หนองบัว เมืองกาญจนบุรี กาญจนบุรี 71190",
    },
    {
      value: "หนองหญ้า เมืองกาญจนบุรี กาญจนบุรี 71000",
      label: "หนองหญ้า เมืองกาญจนบุรี กาญจนบุรี 71000",
    },
    {
      value: "เกาะสำโรง เมืองกาญจนบุรี กาญจนบุรี 71000",
      label: "เกาะสำโรง เมืองกาญจนบุรี กาญจนบุรี 71000",
    },
    {
      value: "แก่งเสี้ยน เมืองกาญจนบุรี กาญจนบุรี 71000",
      label: "แก่งเสี้ยน เมืองกาญจนบุรี กาญจนบุรี 71000",
    },
    {
      value: "ทุ่งกระบ่ำ เลาขวัญ กาญจนบุรี 71210",
      label: "ทุ่งกระบ่ำ เลาขวัญ กาญจนบุรี 71210",
    },
    {
      value: "หนองนกแก้ว เลาขวัญ กาญจนบุรี 71210",
      label: "หนองนกแก้ว เลาขวัญ กาญจนบุรี 71210",
    },
    {
      value: "หนองประดู่ เลาขวัญ กาญจนบุรี 71210",
      label: "หนองประดู่ เลาขวัญ กาญจนบุรี 71210",
    },
    {
      value: "หนองปลิง เลาขวัญ กาญจนบุรี 71210",
      label: "หนองปลิง เลาขวัญ กาญจนบุรี 71210",
    },
    {
      value: "หนองฝ้าย เลาขวัญ กาญจนบุรี 71210",
      label: "หนองฝ้าย เลาขวัญ กาญจนบุรี 71210",
    },
    {
      value: "หนองโสน เลาขวัญ กาญจนบุรี 71210",
      label: "หนองโสน เลาขวัญ กาญจนบุรี 71210",
    },
    {
      value: "เลาขวัญ เลาขวัญ กาญจนบุรี 71210",
      label: "เลาขวัญ เลาขวัญ กาญจนบุรี 71210",
    },
    {
      value: "ท่าเสา ไทรโยค กาญจนบุรี 71150",
      label: "ท่าเสา ไทรโยค กาญจนบุรี 71150",
    },
    {
      value: "บ้องตี้ ไทรโยค กาญจนบุรี 71150",
      label: "บ้องตี้ ไทรโยค กาญจนบุรี 71150",
    },
    {
      value: "ลุ่มสุ่ม ไทรโยค กาญจนบุรี 71150",
      label: "ลุ่มสุ่ม ไทรโยค กาญจนบุรี 71150",
    },
    {
      value: "วังกระแจะ ไทรโยค กาญจนบุรี 71150",
      label: "วังกระแจะ ไทรโยค กาญจนบุรี 71150",
    },
    {
      value: "ศรีมงคล ไทรโยค กาญจนบุรี 71150",
      label: "ศรีมงคล ไทรโยค กาญจนบุรี 71150",
    },
    {
      value: "สิงห์ ไทรโยค กาญจนบุรี 71150",
      label: "สิงห์ ไทรโยค กาญจนบุรี 71150",
    },
    {
      value: "ไทรโยค ไทรโยค กาญจนบุรี 71150",
      label: "ไทรโยค ไทรโยค กาญจนบุรี 71150",
    },
    {
      value: "ท่าสองยาง ท่าสองยาง ตาก 63150",
      label: "ท่าสองยาง ท่าสองยาง ตาก 63150",
    },
    {
      value: "แม่ต้าน ท่าสองยาง ตาก 63150",
      label: "แม่ต้าน ท่าสองยาง ตาก 63150",
    },
    {
      value: "แม่วะหลวง ท่าสองยาง ตาก 63150",
      label: "แม่วะหลวง ท่าสองยาง ตาก 63150",
    },
    { value: "แม่สอง ท่าสองยาง ตาก 63150", label: "แม่สอง ท่าสองยาง ตาก 63150" },
    { value: "แม่หละ ท่าสองยาง ตาก 63150", label: "แม่หละ ท่าสองยาง ตาก 63150" },
    {
      value: "แม่อุสุ ท่าสองยาง ตาก 63150",
      label: "แม่อุสุ ท่าสองยาง ตาก 63150",
    },
    { value: "ตากตก บ้านตาก ตาก 63120", label: "ตากตก บ้านตาก ตาก 63120" },
    { value: "ตากออก บ้านตาก ตาก 63120", label: "ตากออก บ้านตาก ตาก 63120" },
    {
      value: "ทุ่งกระเชาะ บ้านตาก ตาก 63120",
      label: "ทุ่งกระเชาะ บ้านตาก ตาก 63120",
    },
    { value: "ท้องฟ้า บ้านตาก ตาก 63120", label: "ท้องฟ้า บ้านตาก ตาก 63120" },
    { value: "สมอโคน บ้านตาก ตาก 63120", label: "สมอโคน บ้านตาก ตาก 63120" },
    {
      value: "เกาะตะเภา บ้านตาก ตาก 63120",
      label: "เกาะตะเภา บ้านตาก ตาก 63120",
    },
    { value: "แม่สลิด บ้านตาก ตาก 63120", label: "แม่สลิด บ้านตาก ตาก 63120" },
    { value: "คีรีราษฎร์ พบพระ ตาก 63160", label: "คีรีราษฎร์ พบพระ ตาก 63160" },
    { value: "ช่องแคบ พบพระ ตาก 63160", label: "ช่องแคบ พบพระ ตาก 63160" },
    { value: "พบพระ พบพระ ตาก 63160", label: "พบพระ พบพระ ตาก 63160" },
    {
      value: "รวมไทยพัฒนา พบพระ ตาก 63160",
      label: "รวมไทยพัฒนา พบพระ ตาก 63160",
    },
    { value: "วาเล่ย์ พบพระ ตาก 63160", label: "วาเล่ย์ พบพระ ตาก 63160" },
    { value: "นาโบสถ์ วังเจ้า ตาก 63180", label: "นาโบสถ์ วังเจ้า ตาก 63180" },
    { value: "ประดาง วังเจ้า ตาก 63180", label: "ประดาง วังเจ้า ตาก 63180" },
    { value: "เชียงทอง วังเจ้า ตาก 63180", label: "เชียงทอง วังเจ้า ตาก 63180" },
    { value: "บ้านนา สามเงา ตาก 63130", label: "บ้านนา สามเงา ตาก 63130" },
    { value: "ยกกระบัตร สามเงา ตาก 63130", label: "ยกกระบัตร สามเงา ตาก 63130" },
    { value: "ย่านรี สามเงา ตาก 63130", label: "ย่านรี สามเงา ตาก 63130" },
    { value: "วังจันทร์ สามเงา ตาก 63130", label: "วังจันทร์ สามเงา ตาก 63130" },
    { value: "วังหมัน สามเงา ตาก 63130", label: "วังหมัน สามเงา ตาก 63130" },
    { value: "สามเงา สามเงา ตาก 63130", label: "สามเงา สามเงา ตาก 63130" },
    { value: "หนองหลวง อุ้มผาง ตาก 63170", label: "หนองหลวง อุ้มผาง ตาก 63170" },
    { value: "อุ้มผาง อุ้มผาง ตาก 63170", label: "อุ้มผาง อุ้มผาง ตาก 63170" },
    { value: "แม่กลอง อุ้มผาง ตาก 63170", label: "แม่กลอง อุ้มผาง ตาก 63170" },
    { value: "แม่จัน อุ้มผาง ตาก 63170", label: "แม่จัน อุ้มผาง ตาก 63170" },
    {
      value: "แม่ละมุ้ง อุ้มผาง ตาก 63170",
      label: "แม่ละมุ้ง อุ้มผาง ตาก 63170",
    },
    { value: "โมโกร อุ้มผาง ตาก 63170", label: "โมโกร อุ้มผาง ตาก 63170" },
    {
      value: "ตลุกกลางทุ่ง เมืองตาก ตาก 63000",
      label: "ตลุกกลางทุ่ง เมืองตาก ตาก 63000",
    },
    { value: "น้ำรึม เมืองตาก ตาก 63000", label: "น้ำรึม เมืองตาก ตาก 63000" },
    {
      value: "ป่ามะม่วง เมืองตาก ตาก 63000",
      label: "ป่ามะม่วง เมืองตาก ตาก 63000",
    },
    { value: "ระแหง เมืองตาก ตาก 63000", label: "ระแหง เมืองตาก ตาก 63000" },
    {
      value: "วังประจบ เมืองตาก ตาก 63000",
      label: "วังประจบ เมืองตาก ตาก 63000",
    },
    { value: "วังหิน เมืองตาก ตาก 63000", label: "วังหิน เมืองตาก ตาก 63000" },
    {
      value: "หนองบัวเหนือ เมืองตาก ตาก 63000",
      label: "หนองบัวเหนือ เมืองตาก ตาก 63000",
    },
    {
      value: "หนองบัวใต้ เมืองตาก ตาก 63000",
      label: "หนองบัวใต้ เมืองตาก ตาก 63000",
    },
    {
      value: "หนองหลวง เมืองตาก ตาก 63000",
      label: "หนองหลวง เมืองตาก ตาก 63000",
    },
    {
      value: "หัวเดียด เมืองตาก ตาก 63000",
      label: "หัวเดียด เมืองตาก ตาก 63000",
    },
    {
      value: "เชียงเงิน เมืองตาก ตาก 63000",
      label: "เชียงเงิน เมืองตาก ตาก 63000",
    },
    { value: "แม่ท้อ เมืองตาก ตาก 63000", label: "แม่ท้อ เมืองตาก ตาก 63000" },
    { value: "โป่งแดง เมืองตาก ตาก 63000", label: "โป่งแดง เมืองตาก ตาก 63000" },
    { value: "ไม้งาม เมืองตาก ตาก 63000", label: "ไม้งาม เมืองตาก ตาก 63000" },
    {
      value: "ขะเนจื้อ แม่ระมาด ตาก 63140",
      label: "ขะเนจื้อ แม่ระมาด ตาก 63140",
    },
    { value: "พระธาตุ แม่ระมาด ตาก 63140", label: "พระธาตุ แม่ระมาด ตาก 63140" },
    {
      value: "สามหมื่น แม่ระมาด ตาก 63140",
      label: "สามหมื่น แม่ระมาด ตาก 63140",
    },
    {
      value: "แม่จะเรา แม่ระมาด ตาก 63140",
      label: "แม่จะเรา แม่ระมาด ตาก 63140",
    },
    { value: "แม่ตื่น แม่ระมาด ตาก 63140", label: "แม่ตื่น แม่ระมาด ตาก 63140" },
    {
      value: "แม่ระมาด แม่ระมาด ตาก 63140",
      label: "แม่ระมาด แม่ระมาด ตาก 63140",
    },
    {
      value: "ด่านแม่ละเมา แม่สอด ตาก 63110",
      label: "ด่านแม่ละเมา แม่สอด ตาก 63110",
    },
    { value: "ท่าสายลวด แม่สอด ตาก 63110", label: "ท่าสายลวด แม่สอด ตาก 63110" },
    {
      value: "พระธาตุผาแดง แม่สอด ตาก 63110",
      label: "พระธาตุผาแดง แม่สอด ตาก 63110",
    },
    { value: "พะวอ แม่สอด ตาก 63110", label: "พะวอ แม่สอด ตาก 63110" },
    { value: "มหาวัน แม่สอด ตาก 63110", label: "มหาวัน แม่สอด ตาก 63110" },
    { value: "แม่กาษา แม่สอด ตาก 63110", label: "แม่กาษา แม่สอด ตาก 63110" },
    { value: "แม่กุ แม่สอด ตาก 63110", label: "แม่กุ แม่สอด ตาก 63110" },
    { value: "แม่ตาว แม่สอด ตาก 63110", label: "แม่ตาว แม่สอด ตาก 63110" },
    { value: "แม่ปะ แม่สอด ตาก 63110", label: "แม่ปะ แม่สอด ตาก 63110" },
    { value: "แม่สอด แม่สอด ตาก 63110", label: "แม่สอด แม่สอด ตาก 63110" },
    {
      value: "กุยบุรี กุยบุรี ประจวบคีรีขันธ์ 77150",
      label: "กุยบุรี กุยบุรี ประจวบคีรีขันธ์ 77150",
    },
    {
      value: "กุยเหนือ กุยบุรี ประจวบคีรีขันธ์ 77150",
      label: "กุยเหนือ กุยบุรี ประจวบคีรีขันธ์ 77150",
    },
    {
      value: "ดอนยายหนู กุยบุรี ประจวบคีรีขันธ์ 77150",
      label: "ดอนยายหนู กุยบุรี ประจวบคีรีขันธ์ 77150",
    },
    {
      value: "สามกระทาย กุยบุรี ประจวบคีรีขันธ์ 77150",
      label: "สามกระทาย กุยบุรี ประจวบคีรีขันธ์ 77150",
    },
    {
      value: "หาดขาม กุยบุรี ประจวบคีรีขันธ์ 77150",
      label: "หาดขาม กุยบุรี ประจวบคีรีขันธ์ 77150",
    },
    {
      value: "เขาแดง กุยบุรี ประจวบคีรีขันธ์ 77150",
      label: "เขาแดง กุยบุรี ประจวบคีรีขันธ์ 77150",
    },
    {
      value: "ทับสะแก ทับสะแก ประจวบคีรีขันธ์ 77130",
      label: "ทับสะแก ทับสะแก ประจวบคีรีขันธ์ 77130",
    },
    {
      value: "นาหูกวาง ทับสะแก ประจวบคีรีขันธ์ 77130",
      label: "นาหูกวาง ทับสะแก ประจวบคีรีขันธ์ 77130",
    },
    {
      value: "ห้วยยาง ทับสะแก ประจวบคีรีขันธ์ 77130",
      label: "ห้วยยาง ทับสะแก ประจวบคีรีขันธ์ 77130",
    },
    {
      value: "อ่างทอง ทับสะแก ประจวบคีรีขันธ์ 77130",
      label: "อ่างทอง ทับสะแก ประจวบคีรีขันธ์ 77130",
    },
    {
      value: "เขาล้าน ทับสะแก ประจวบคีรีขันธ์ 77130",
      label: "เขาล้าน ทับสะแก ประจวบคีรีขันธ์ 77130",
    },
    {
      value: "แสงอรุณ ทับสะแก ประจวบคีรีขันธ์ 77130",
      label: "แสงอรุณ ทับสะแก ประจวบคีรีขันธ์ 77130",
    },
    {
      value: "กำเนิดนพคุณ บางสะพาน ประจวบคีรีขันธ์ 77140",
      label: "กำเนิดนพคุณ บางสะพาน ประจวบคีรีขันธ์ 77140",
    },
    {
      value: "ชัยเกษม บางสะพาน ประจวบคีรีขันธ์ 77190",
      label: "ชัยเกษม บางสะพาน ประจวบคีรีขันธ์ 77190",
    },
    {
      value: "ทองมงคล บางสะพาน ประจวบคีรีขันธ์ 77230",
      label: "ทองมงคล บางสะพาน ประจวบคีรีขันธ์ 77230",
    },
    {
      value: "ธงชัย บางสะพาน ประจวบคีรีขันธ์ 77190",
      label: "ธงชัย บางสะพาน ประจวบคีรีขันธ์ 77190",
    },
    {
      value: "พงศ์ประศาสน์ บางสะพาน ประจวบคีรีขันธ์ 77140",
      label: "พงศ์ประศาสน์ บางสะพาน ประจวบคีรีขันธ์ 77140",
    },
    {
      value: "ร่อนทอง บางสะพาน ประจวบคีรีขันธ์ 77230",
      label: "ร่อนทอง บางสะพาน ประจวบคีรีขันธ์ 77230",
    },
    {
      value: "แม่รำพึง บางสะพาน ประจวบคีรีขันธ์ 77140",
      label: "แม่รำพึง บางสะพาน ประจวบคีรีขันธ์ 77140",
    },
    {
      value: "ช้างแรก บางสะพานน้อย ประจวบคีรีขันธ์ 77170",
      label: "ช้างแรก บางสะพานน้อย ประจวบคีรีขันธ์ 77170",
    },
    {
      value: "ทรายทอง บางสะพานน้อย ประจวบคีรีขันธ์ 77170",
      label: "ทรายทอง บางสะพานน้อย ประจวบคีรีขันธ์ 77170",
    },
    {
      value: "บางสะพาน บางสะพานน้อย ประจวบคีรีขันธ์ 77170",
      label: "บางสะพาน บางสะพานน้อย ประจวบคีรีขันธ์ 77170",
    },
    {
      value: "ปากแพรก บางสะพานน้อย ประจวบคีรีขันธ์ 77170",
      label: "ปากแพรก บางสะพานน้อย ประจวบคีรีขันธ์ 77170",
    },
    {
      value: "ไชยราช บางสะพานน้อย ประจวบคีรีขันธ์ 77170",
      label: "ไชยราช บางสะพานน้อย ประจวบคีรีขันธ์ 77170",
    },
    {
      value: "ปราณบุรี ปราณบุรี ประจวบคีรีขันธ์ 77120",
      label: "ปราณบุรี ปราณบุรี ประจวบคีรีขันธ์ 77120",
    },
    {
      value: "ปราณบุรี ปราณบุรี ประจวบคีรีขันธ์ 77160",
      label: "ปราณบุรี ปราณบุรี ประจวบคีรีขันธ์ 77160",
    },
    {
      value: "ปากน้ำปราณ ปราณบุรี ประจวบคีรีขันธ์ 77220",
      label: "ปากน้ำปราณ ปราณบุรี ประจวบคีรีขันธ์ 77220",
    },
    {
      value: "วังก์พง ปราณบุรี ประจวบคีรีขันธ์ 77120",
      label: "วังก์พง ปราณบุรี ประจวบคีรีขันธ์ 77120",
    },
    {
      value: "วังก์พง ปราณบุรี ประจวบคีรีขันธ์ 77160",
      label: "วังก์พง ปราณบุรี ประจวบคีรีขันธ์ 77160",
    },
    {
      value: "หนองตาแต้ม ปราณบุรี ประจวบคีรีขันธ์ 77120",
      label: "หนองตาแต้ม ปราณบุรี ประจวบคีรีขันธ์ 77120",
    },
    {
      value: "หนองตาแต้ม ปราณบุรี ประจวบคีรีขันธ์ 77160",
      label: "หนองตาแต้ม ปราณบุรี ประจวบคีรีขันธ์ 77160",
    },
    {
      value: "เขาจ้าว ปราณบุรี ประจวบคีรีขันธ์ 77120",
      label: "เขาจ้าว ปราณบุรี ประจวบคีรีขันธ์ 77120",
    },
    {
      value: "เขาจ้าว ปราณบุรี ประจวบคีรีขันธ์ 77160",
      label: "เขาจ้าว ปราณบุรี ประจวบคีรีขันธ์ 77160",
    },
    {
      value: "เขาน้อย ปราณบุรี ประจวบคีรีขันธ์ 77120",
      label: "เขาน้อย ปราณบุรี ประจวบคีรีขันธ์ 77120",
    },
    {
      value: "เขาน้อย ปราณบุรี ประจวบคีรีขันธ์ 77160",
      label: "เขาน้อย ปราณบุรี ประจวบคีรีขันธ์ 77160",
    },
    {
      value: "ศาลาลัย สามร้อยยอด ประจวบคีรีขันธ์ 77180",
      label: "ศาลาลัย สามร้อยยอด ประจวบคีรีขันธ์ 77180",
    },
    {
      value: "ศิลาลอย สามร้อยยอด ประจวบคีรีขันธ์ 77180",
      label: "ศิลาลอย สามร้อยยอด ประจวบคีรีขันธ์ 77180",
    },
    {
      value: "สามร้อยยอด สามร้อยยอด ประจวบคีรีขันธ์ 77120",
      label: "สามร้อยยอด สามร้อยยอด ประจวบคีรีขันธ์ 77120",
    },
    {
      value: "สามร้อยยอด สามร้อยยอด ประจวบคีรีขันธ์ 77160",
      label: "สามร้อยยอด สามร้อยยอด ประจวบคีรีขันธ์ 77160",
    },
    {
      value: "ไร่เก่า สามร้อยยอด ประจวบคีรีขันธ์ 77180",
      label: "ไร่เก่า สามร้อยยอด ประจวบคีรีขันธ์ 77180",
    },
    {
      value: "ไร่ใหม่ สามร้อยยอด ประจวบคีรีขันธ์ 77180",
      label: "ไร่ใหม่ สามร้อยยอด ประจวบคีรีขันธ์ 77180",
    },
    {
      value: "ทับใต้ หัวหิน ประจวบคีรีขันธ์ 77110",
      label: "ทับใต้ หัวหิน ประจวบคีรีขันธ์ 77110",
    },
    {
      value: "บึงนคร หัวหิน ประจวบคีรีขันธ์ 77110",
      label: "บึงนคร หัวหิน ประจวบคีรีขันธ์ 77110",
    },
    {
      value: "หนองพลับ หัวหิน ประจวบคีรีขันธ์ 77110",
      label: "หนองพลับ หัวหิน ประจวบคีรีขันธ์ 77110",
    },
    {
      value: "หนองแก หัวหิน ประจวบคีรีขันธ์ 77110",
      label: "หนองแก หัวหิน ประจวบคีรีขันธ์ 77110",
    },
    {
      value: "หัวหิน หัวหิน ประจวบคีรีขันธ์ 77110",
      label: "หัวหิน หัวหิน ประจวบคีรีขันธ์ 77110",
    },
    {
      value: "หินเหล็กไฟ หัวหิน ประจวบคีรีขันธ์ 77110",
      label: "หินเหล็กไฟ หัวหิน ประจวบคีรีขันธ์ 77110",
    },
    {
      value: "ห้วยสัตว์ใหญ่ หัวหิน ประจวบคีรีขันธ์ 77110",
      label: "ห้วยสัตว์ใหญ่ หัวหิน ประจวบคีรีขันธ์ 77110",
    },
    {
      value: "คลองวาฬ เมืองประจวบคีรีขันธ์ ประจวบคีรีขันธ์ 77000",
      label: "คลองวาฬ เมืองประจวบคีรีขันธ์ ประจวบคีรีขันธ์ 77000",
    },
    {
      value: "บ่อนอก เมืองประจวบคีรีขันธ์ ประจวบคีรีขันธ์ 77210",
      label: "บ่อนอก เมืองประจวบคีรีขันธ์ ประจวบคีรีขันธ์ 77210",
    },
    {
      value: "ประจวบคีรีขันธ์ เมืองประจวบคีรีขันธ์ ประจวบคีรีขันธ์ 77000",
      label: "ประจวบคีรีขันธ์ เมืองประจวบคีรีขันธ์ ประจวบคีรีขันธ์ 77000",
    },
    {
      value: "ห้วยทราย เมืองประจวบคีรีขันธ์ ประจวบคีรีขันธ์ 77000",
      label: "ห้วยทราย เมืองประจวบคีรีขันธ์ ประจวบคีรีขันธ์ 77000",
    },
    {
      value: "อ่าวน้อย เมืองประจวบคีรีขันธ์ ประจวบคีรีขันธ์ 77210",
      label: "อ่าวน้อย เมืองประจวบคีรีขันธ์ ประจวบคีรีขันธ์ 77210",
    },
    {
      value: "เกาะหลัก เมืองประจวบคีรีขันธ์ ประจวบคีรีขันธ์ 77000",
      label: "เกาะหลัก เมืองประจวบคีรีขันธ์ ประจวบคีรีขันธ์ 77000",
    },
    { value: "ชะอำ ชะอำ เพชรบุรี 76120", label: "ชะอำ ชะอำ เพชรบุรี 76120" },
    {
      value: "ดอนขุนห้วย ชะอำ เพชรบุรี 76120",
      label: "ดอนขุนห้วย ชะอำ เพชรบุรี 76120",
    },
    { value: "นายาง ชะอำ เพชรบุรี 76120", label: "นายาง ชะอำ เพชรบุรี 76120" },
    {
      value: "บางเก่า ชะอำ เพชรบุรี 76120",
      label: "บางเก่า ชะอำ เพชรบุรี 76120",
    },
    {
      value: "สามพระยา ชะอำ เพชรบุรี 76120",
      label: "สามพระยา ชะอำ เพชรบุรี 76120",
    },
    {
      value: "หนองศาลา ชะอำ เพชรบุรี 76120",
      label: "หนองศาลา ชะอำ เพชรบุรี 76120",
    },
    {
      value: "ห้วยทรายเหนือ ชะอำ เพชรบุรี 76120",
      label: "ห้วยทรายเหนือ ชะอำ เพชรบุรี 76120",
    },
    {
      value: "เขาใหญ่ ชะอำ เพชรบุรี 76120",
      label: "เขาใหญ่ ชะอำ เพชรบุรี 76120",
    },
    {
      value: "ไร่ใหม่พัฒนา ชะอำ เพชรบุรี 76120",
      label: "ไร่ใหม่พัฒนา ชะอำ เพชรบุรี 76120",
    },
    {
      value: "กลัดหลวง ท่ายาง เพชรบุรี 76130",
      label: "กลัดหลวง ท่ายาง เพชรบุรี 76130",
    },
    {
      value: "ท่าคอย ท่ายาง เพชรบุรี 76130",
      label: "ท่าคอย ท่ายาง เพชรบุรี 76130",
    },
    {
      value: "ท่ายาง ท่ายาง เพชรบุรี 76130",
      label: "ท่ายาง ท่ายาง เพชรบุรี 76130",
    },
    {
      value: "ท่าแลง ท่ายาง เพชรบุรี 76130",
      label: "ท่าแลง ท่ายาง เพชรบุรี 76130",
    },
    {
      value: "ท่าไม้รวก ท่ายาง เพชรบุรี 76130",
      label: "ท่าไม้รวก ท่ายาง เพชรบุรี 76130",
    },
    {
      value: "บ้านในดง ท่ายาง เพชรบุรี 76130",
      label: "บ้านในดง ท่ายาง เพชรบุรี 76130",
    },
    {
      value: "ปึกเตียน ท่ายาง เพชรบุรี 76130",
      label: "ปึกเตียน ท่ายาง เพชรบุรี 76130",
    },
    {
      value: "มาบปลาเค้า ท่ายาง เพชรบุรี 76130",
      label: "มาบปลาเค้า ท่ายาง เพชรบุรี 76130",
    },
    {
      value: "ยางหย่อง ท่ายาง เพชรบุรี 76130",
      label: "ยางหย่อง ท่ายาง เพชรบุรี 76130",
    },
    {
      value: "วังไคร้ ท่ายาง เพชรบุรี 76130",
      label: "วังไคร้ ท่ายาง เพชรบุรี 76130",
    },
    {
      value: "หนองจอก ท่ายาง เพชรบุรี 76130",
      label: "หนองจอก ท่ายาง เพชรบุรี 76130",
    },
    {
      value: "เขากระปุก ท่ายาง เพชรบุรี 76130",
      label: "เขากระปุก ท่ายาง เพชรบุรี 76130",
    },
    {
      value: "ตำหรุ บ้านลาด เพชรบุรี 76150",
      label: "ตำหรุ บ้านลาด เพชรบุรี 76150",
    },
    {
      value: "ถ้ำรงค์ บ้านลาด เพชรบุรี 76150",
      label: "ถ้ำรงค์ บ้านลาด เพชรบุรี 76150",
    },
    {
      value: "ท่าช้าง บ้านลาด เพชรบุรี 76150",
      label: "ท่าช้าง บ้านลาด เพชรบุรี 76150",
    },
    {
      value: "ท่าเสน บ้านลาด เพชรบุรี 76150",
      label: "ท่าเสน บ้านลาด เพชรบุรี 76150",
    },
    {
      value: "บ้านทาน บ้านลาด เพชรบุรี 76150",
      label: "บ้านทาน บ้านลาด เพชรบุรี 76150",
    },
    {
      value: "บ้านลาด บ้านลาด เพชรบุรี 76150",
      label: "บ้านลาด บ้านลาด เพชรบุรี 76150",
    },
    {
      value: "บ้านหาด บ้านลาด เพชรบุรี 76150",
      label: "บ้านหาด บ้านลาด เพชรบุรี 76150",
    },
    {
      value: "ลาดโพธิ์ บ้านลาด เพชรบุรี 76150",
      label: "ลาดโพธิ์ บ้านลาด เพชรบุรี 76150",
    },
    {
      value: "สมอพลือ บ้านลาด เพชรบุรี 76150",
      label: "สมอพลือ บ้านลาด เพชรบุรี 76150",
    },
    {
      value: "สะพานไกร บ้านลาด เพชรบุรี 76150",
      label: "สะพานไกร บ้านลาด เพชรบุรี 76150",
    },
    {
      value: "หนองกระเจ็ด บ้านลาด เพชรบุรี 76150",
      label: "หนองกระเจ็ด บ้านลาด เพชรบุรี 76150",
    },
    {
      value: "หนองกะปุ บ้านลาด เพชรบุรี 76150",
      label: "หนองกะปุ บ้านลาด เพชรบุรี 76150",
    },
    {
      value: "ห้วยข้อง บ้านลาด เพชรบุรี 76150",
      label: "ห้วยข้อง บ้านลาด เพชรบุรี 76150",
    },
    {
      value: "ห้วยลึก บ้านลาด เพชรบุรี 76150",
      label: "ห้วยลึก บ้านลาด เพชรบุรี 76150",
    },
    {
      value: "โรงเข้ บ้านลาด เพชรบุรี 76150",
      label: "โรงเข้ บ้านลาด เพชรบุรี 76150",
    },
    {
      value: "ไร่มะขาม บ้านลาด เพชรบุรี 76150",
      label: "ไร่มะขาม บ้านลาด เพชรบุรี 76150",
    },
    {
      value: "ไร่สะท้อน บ้านลาด เพชรบุรี 76150",
      label: "ไร่สะท้อน บ้านลาด เพชรบุรี 76150",
    },
    {
      value: "ไร่โคก บ้านลาด เพชรบุรี 76150",
      label: "ไร่โคก บ้านลาด เพชรบุรี 76150",
    },
    {
      value: "ท่าแร้ง บ้านแหลม เพชรบุรี 76110",
      label: "ท่าแร้ง บ้านแหลม เพชรบุรี 76110",
    },
    {
      value: "ท่าแร้งออก บ้านแหลม เพชรบุรี 76110",
      label: "ท่าแร้งออก บ้านแหลม เพชรบุรี 76110",
    },
    {
      value: "บางขุนไทร บ้านแหลม เพชรบุรี 76110",
      label: "บางขุนไทร บ้านแหลม เพชรบุรี 76110",
    },
    {
      value: "บางครก บ้านแหลม เพชรบุรี 76110",
      label: "บางครก บ้านแหลม เพชรบุรี 76110",
    },
    {
      value: "บางตะบูน บ้านแหลม เพชรบุรี 76110",
      label: "บางตะบูน บ้านแหลม เพชรบุรี 76110",
    },
    {
      value: "บางตะบูนออก บ้านแหลม เพชรบุรี 76110",
      label: "บางตะบูนออก บ้านแหลม เพชรบุรี 76110",
    },
    {
      value: "บางแก้ว บ้านแหลม เพชรบุรี 76110",
      label: "บางแก้ว บ้านแหลม เพชรบุรี 76110",
    },
    {
      value: "บ้านแหลม บ้านแหลม เพชรบุรี 76110",
      label: "บ้านแหลม บ้านแหลม เพชรบุรี 76110",
    },
    {
      value: "ปากทะเล บ้านแหลม เพชรบุรี 76110",
      label: "ปากทะเล บ้านแหลม เพชรบุรี 76110",
    },
    {
      value: "แหลมผักเบี้ย บ้านแหลม เพชรบุรี 76100",
      label: "แหลมผักเบี้ย บ้านแหลม เพชรบุรี 76100",
    },
    {
      value: "ท่าตะคร้อ หนองหญ้าปล้อง เพชรบุรี 76160",
      label: "ท่าตะคร้อ หนองหญ้าปล้อง เพชรบุรี 76160",
    },
    {
      value: "ยางน้ำกลัดเหนือ หนองหญ้าปล้อง เพชรบุรี 76160",
      label: "ยางน้ำกลัดเหนือ หนองหญ้าปล้อง เพชรบุรี 76160",
    },
    {
      value: "ยางน้ำกลัดใต้ หนองหญ้าปล้อง เพชรบุรี 76160",
      label: "ยางน้ำกลัดใต้ หนองหญ้าปล้อง เพชรบุรี 76160",
    },
    {
      value: "หนองหญ้าปล้อง หนองหญ้าปล้อง เพชรบุรี 76160",
      label: "หนองหญ้าปล้อง หนองหญ้าปล้อง เพชรบุรี 76160",
    },
    {
      value: "ทับคาง เขาย้อย เพชรบุรี 76140",
      label: "ทับคาง เขาย้อย เพชรบุรี 76140",
    },
    {
      value: "บางเค็ม เขาย้อย เพชรบุรี 76140",
      label: "บางเค็ม เขาย้อย เพชรบุรี 76140",
    },
    {
      value: "สระพัง เขาย้อย เพชรบุรี 76140",
      label: "สระพัง เขาย้อย เพชรบุรี 76140",
    },
    {
      value: "หนองชุมพล เขาย้อย เพชรบุรี 76140",
      label: "หนองชุมพล เขาย้อย เพชรบุรี 76140",
    },
    {
      value: "หนองชุมพลเหนือ เขาย้อย เพชรบุรี 76140",
      label: "หนองชุมพลเหนือ เขาย้อย เพชรบุรี 76140",
    },
    {
      value: "หนองปรง เขาย้อย เพชรบุรี 76140",
      label: "หนองปรง เขาย้อย เพชรบุรี 76140",
    },
    {
      value: "หนองปลาไหล เขาย้อย เพชรบุรี 76140",
      label: "หนองปลาไหล เขาย้อย เพชรบุรี 76140",
    },
    {
      value: "ห้วยท่าช้าง เขาย้อย เพชรบุรี 76140",
      label: "ห้วยท่าช้าง เขาย้อย เพชรบุรี 76140",
    },
    {
      value: "ห้วยโรง เขาย้อย เพชรบุรี 76140",
      label: "ห้วยโรง เขาย้อย เพชรบุรี 76140",
    },
    {
      value: "เขาย้อย เขาย้อย เพชรบุรี 76140",
      label: "เขาย้อย เขาย้อย เพชรบุรี 76140",
    },
    {
      value: "คลองกระแชง เมืองเพชรบุรี เพชรบุรี 76000",
      label: "คลองกระแชง เมืองเพชรบุรี เพชรบุรี 76000",
    },
    {
      value: "ช่องสะแก เมืองเพชรบุรี เพชรบุรี 76000",
      label: "ช่องสะแก เมืองเพชรบุรี เพชรบุรี 76000",
    },
    {
      value: "ดอนยาง เมืองเพชรบุรี เพชรบุรี 76000",
      label: "ดอนยาง เมืองเพชรบุรี เพชรบุรี 76000",
    },
    {
      value: "ต้นมะพร้าว เมืองเพชรบุรี เพชรบุรี 76000",
      label: "ต้นมะพร้าว เมืองเพชรบุรี เพชรบุรี 76000",
    },
    {
      value: "ต้นมะม่วง เมืองเพชรบุรี เพชรบุรี 76000",
      label: "ต้นมะม่วง เมืองเพชรบุรี เพชรบุรี 76000",
    },
    {
      value: "ท่าราบ เมืองเพชรบุรี เพชรบุรี 76000",
      label: "ท่าราบ เมืองเพชรบุรี เพชรบุรี 76000",
    },
    {
      value: "ธงชัย เมืองเพชรบุรี เพชรบุรี 76000",
      label: "ธงชัย เมืองเพชรบุรี เพชรบุรี 76000",
    },
    {
      value: "นาพันสาม เมืองเพชรบุรี เพชรบุรี 76000",
      label: "นาพันสาม เมืองเพชรบุรี เพชรบุรี 76000",
    },
    {
      value: "นาวุ้ง เมืองเพชรบุรี เพชรบุรี 76000",
      label: "นาวุ้ง เมืองเพชรบุรี เพชรบุรี 76000",
    },
    {
      value: "บางจาก เมืองเพชรบุรี เพชรบุรี 76000",
      label: "บางจาก เมืองเพชรบุรี เพชรบุรี 76000",
    },
    {
      value: "บางจาน เมืองเพชรบุรี เพชรบุรี 76000",
      label: "บางจาน เมืองเพชรบุรี เพชรบุรี 76000",
    },
    {
      value: "บ้านกุ่ม เมืองเพชรบุรี เพชรบุรี 76000",
      label: "บ้านกุ่ม เมืองเพชรบุรี เพชรบุรี 76000",
    },
    {
      value: "บ้านหม้อ เมืองเพชรบุรี เพชรบุรี 76000",
      label: "บ้านหม้อ เมืองเพชรบุรี เพชรบุรี 76000",
    },
    {
      value: "วังตะโก เมืองเพชรบุรี เพชรบุรี 76000",
      label: "วังตะโก เมืองเพชรบุรี เพชรบุรี 76000",
    },
    {
      value: "สำมะโรง เมืองเพชรบุรี เพชรบุรี 76000",
      label: "สำมะโรง เมืองเพชรบุรี เพชรบุรี 76000",
    },
    {
      value: "หนองขนาน เมืองเพชรบุรี เพชรบุรี 76000",
      label: "หนองขนาน เมืองเพชรบุรี เพชรบุรี 76000",
    },
    {
      value: "หนองพลับ เมืองเพชรบุรี เพชรบุรี 76000",
      label: "หนองพลับ เมืองเพชรบุรี เพชรบุรี 76000",
    },
    {
      value: "หนองโสน เมืองเพชรบุรี เพชรบุรี 76000",
      label: "หนองโสน เมืองเพชรบุรี เพชรบุรี 76000",
    },
    {
      value: "หัวสะพาน เมืองเพชรบุรี เพชรบุรี 76000",
      label: "หัวสะพาน เมืองเพชรบุรี เพชรบุรี 76000",
    },
    {
      value: "หาดเจ้าสำราญ เมืองเพชรบุรี เพชรบุรี 76100",
      label: "หาดเจ้าสำราญ เมืองเพชรบุรี เพชรบุรี 76100",
    },
    {
      value: "เวียงคอย เมืองเพชรบุรี เพชรบุรี 76000",
      label: "เวียงคอย เมืองเพชรบุรี เพชรบุรี 76000",
    },
    {
      value: "โพพระ เมืองเพชรบุรี เพชรบุรี 76000",
      label: "โพพระ เมืองเพชรบุรี เพชรบุรี 76000",
    },
    {
      value: "โพไร่หวาน เมืองเพชรบุรี เพชรบุรี 76000",
      label: "โพไร่หวาน เมืองเพชรบุรี เพชรบุรี 76000",
    },
    {
      value: "ไร่ส้ม เมืองเพชรบุรี เพชรบุรี 76000",
      label: "ไร่ส้ม เมืองเพชรบุรี เพชรบุรี 76000",
    },
    {
      value: "ป่าเด็ง แก่งกระจาน เพชรบุรี 76170",
      label: "ป่าเด็ง แก่งกระจาน เพชรบุรี 76170",
    },
    {
      value: "พุสวรรค์ แก่งกระจาน เพชรบุรี 76170",
      label: "พุสวรรค์ แก่งกระจาน เพชรบุรี 76170",
    },
    {
      value: "วังจันทร์ แก่งกระจาน เพชรบุรี 76170",
      label: "วังจันทร์ แก่งกระจาน เพชรบุรี 76170",
    },
    {
      value: "สองพี่น้อง แก่งกระจาน เพชรบุรี 76170",
      label: "สองพี่น้อง แก่งกระจาน เพชรบุรี 76170",
    },
    {
      value: "ห้วยแม่เพรียง แก่งกระจาน เพชรบุรี 76170",
      label: "ห้วยแม่เพรียง แก่งกระจาน เพชรบุรี 76170",
    },
    {
      value: "แก่งกระจาน แก่งกระจาน เพชรบุรี 76170",
      label: "แก่งกระจาน แก่งกระจาน เพชรบุรี 76170",
    },
    {
      value: "จอมบึง จอมบึง ราชบุรี 70150",
      label: "จอมบึง จอมบึง ราชบุรี 70150",
    },
    {
      value: "ด่านทับตะโก จอมบึง ราชบุรี 70150",
      label: "ด่านทับตะโก จอมบึง ราชบุรี 70150",
    },
    {
      value: "ปากช่อง จอมบึง ราชบุรี 70150",
      label: "ปากช่อง จอมบึง ราชบุรี 70150",
    },
    {
      value: "รางบัว จอมบึง ราชบุรี 70150",
      label: "รางบัว จอมบึง ราชบุรี 70150",
    },
    {
      value: "เบิกไพร จอมบึง ราชบุรี 70150",
      label: "เบิกไพร จอมบึง ราชบุรี 70150",
    },
    {
      value: "แก้มอ้น จอมบึง ราชบุรี 70150",
      label: "แก้มอ้น จอมบึง ราชบุรี 70150",
    },
    {
      value: "ขุนพิทักษ์ ดำเนินสะดวก ราชบุรี 70130",
      label: "ขุนพิทักษ์ ดำเนินสะดวก ราชบุรี 70130",
    },
    {
      value: "ดอนกรวย ดำเนินสะดวก ราชบุรี 70130",
      label: "ดอนกรวย ดำเนินสะดวก ราชบุรี 70130",
    },
    {
      value: "ดอนคลัง ดำเนินสะดวก ราชบุรี 70130",
      label: "ดอนคลัง ดำเนินสะดวก ราชบุรี 70130",
    },
    {
      value: "ดอนไผ่ ดำเนินสะดวก ราชบุรี 70130",
      label: "ดอนไผ่ ดำเนินสะดวก ราชบุรี 70130",
    },
    {
      value: "ดำเนินสะดวก ดำเนินสะดวก ราชบุรี 70130",
      label: "ดำเนินสะดวก ดำเนินสะดวก ราชบุรี 70130",
    },
    {
      value: "ตาหลวง ดำเนินสะดวก ราชบุรี 70130",
      label: "ตาหลวง ดำเนินสะดวก ราชบุรี 70130",
    },
    {
      value: "ท่านัด ดำเนินสะดวก ราชบุรี 70130",
      label: "ท่านัด ดำเนินสะดวก ราชบุรี 70130",
    },
    {
      value: "บัวงาม ดำเนินสะดวก ราชบุรี 70210",
      label: "บัวงาม ดำเนินสะดวก ราชบุรี 70210",
    },
    {
      value: "บ้านไร่ ดำเนินสะดวก ราชบุรี 70130",
      label: "บ้านไร่ ดำเนินสะดวก ราชบุรี 70130",
    },
    {
      value: "ประสาทสิทธิ์ ดำเนินสะดวก ราชบุรี 70210",
      label: "ประสาทสิทธิ์ ดำเนินสะดวก ราชบุรี 70210",
    },
    {
      value: "ศรีสุราษฎร์ ดำเนินสะดวก ราชบุรี 70130",
      label: "ศรีสุราษฎร์ ดำเนินสะดวก ราชบุรี 70130",
    },
    {
      value: "สี่หมื่น ดำเนินสะดวก ราชบุรี 70130",
      label: "สี่หมื่น ดำเนินสะดวก ราชบุรี 70130",
    },
    {
      value: "แพงพวย ดำเนินสะดวก ราชบุรี 70130",
      label: "แพงพวย ดำเนินสะดวก ราชบุรี 70130",
    },
    { value: "ดอนคา บางแพ ราชบุรี 70160", label: "ดอนคา บางแพ ราชบุรี 70160" },
    {
      value: "ดอนใหญ่ บางแพ ราชบุรี 70160",
      label: "ดอนใหญ่ บางแพ ราชบุรี 70160",
    },
    { value: "บางแพ บางแพ ราชบุรี 70160", label: "บางแพ บางแพ ราชบุรี 70160" },
    {
      value: "วังเย็น บางแพ ราชบุรี 70160",
      label: "วังเย็น บางแพ ราชบุรี 70160",
    },
    {
      value: "วัดแก้ว บางแพ ราชบุรี 70160",
      label: "วัดแก้ว บางแพ ราชบุรี 70160",
    },
    { value: "หัวโพ บางแพ ราชบุรี 70160", label: "หัวโพ บางแพ ราชบุรี 70160" },
    { value: "โพหัก บางแพ ราชบุรี 70160", label: "โพหัก บางแพ ราชบุรี 70160" },
    {
      value: "บ้านคา บ้านคา ราชบุรี 70180",
      label: "บ้านคา บ้านคา ราชบุรี 70180",
    },
    {
      value: "บ้านบึง บ้านคา ราชบุรี 70180",
      label: "บ้านบึง บ้านคา ราชบุรี 70180",
    },
    {
      value: "หนองพันจันทร์ บ้านคา ราชบุรี 70180",
      label: "หนองพันจันทร์ บ้านคา ราชบุรี 70180",
    },
    {
      value: "กรับใหญ่ บ้านโป่ง ราชบุรี 70190",
      label: "กรับใหญ่ บ้านโป่ง ราชบุรี 70190",
    },
    {
      value: "คุ้งพยอม บ้านโป่ง ราชบุรี 70110",
      label: "คุ้งพยอม บ้านโป่ง ราชบุรี 70110",
    },
    {
      value: "ดอนกระเบื้อง บ้านโป่ง ราชบุรี 70110",
      label: "ดอนกระเบื้อง บ้านโป่ง ราชบุรี 70110",
    },
    {
      value: "ท่าผา บ้านโป่ง ราชบุรี 70110",
      label: "ท่าผา บ้านโป่ง ราชบุรี 70110",
    },
    {
      value: "นครชุมน์ บ้านโป่ง ราชบุรี 70110",
      label: "นครชุมน์ บ้านโป่ง ราชบุรี 70110",
    },
    {
      value: "บ้านม่วง บ้านโป่ง ราชบุรี 70110",
      label: "บ้านม่วง บ้านโป่ง ราชบุรี 70110",
    },
    {
      value: "บ้านโป่ง บ้านโป่ง ราชบุรี 70110",
      label: "บ้านโป่ง บ้านโป่ง ราชบุรี 70110",
    },
    {
      value: "ปากแรต บ้านโป่ง ราชบุรี 70110",
      label: "ปากแรต บ้านโป่ง ราชบุรี 70110",
    },
    {
      value: "ลาดบัวขาว บ้านโป่ง ราชบุรี 70110",
      label: "ลาดบัวขาว บ้านโป่ง ราชบุรี 70110",
    },
    {
      value: "สวนกล้วย บ้านโป่ง ราชบุรี 70110",
      label: "สวนกล้วย บ้านโป่ง ราชบุรี 70110",
    },
    {
      value: "หนองกบ บ้านโป่ง ราชบุรี 70110",
      label: "หนองกบ บ้านโป่ง ราชบุรี 70110",
    },
    {
      value: "หนองปลาหมอ บ้านโป่ง ราชบุรี 70110",
      label: "หนองปลาหมอ บ้านโป่ง ราชบุรี 70110",
    },
    {
      value: "หนองอ้อ บ้านโป่ง ราชบุรี 70110",
      label: "หนองอ้อ บ้านโป่ง ราชบุรี 70110",
    },
    {
      value: "เขาขลุง บ้านโป่ง ราชบุรี 70110",
      label: "เขาขลุง บ้านโป่ง ราชบุรี 70110",
    },
    {
      value: "เบิกไพร บ้านโป่ง ราชบุรี 70110",
      label: "เบิกไพร บ้านโป่ง ราชบุรี 70110",
    },
    {
      value: "ดอนทราย ปากท่อ ราชบุรี 70140",
      label: "ดอนทราย ปากท่อ ราชบุรี 70140",
    },
    {
      value: "ทุ่งหลวง ปากท่อ ราชบุรี 70140",
      label: "ทุ่งหลวง ปากท่อ ราชบุรี 70140",
    },
    {
      value: "บ่อกระดาน ปากท่อ ราชบุรี 70140",
      label: "บ่อกระดาน ปากท่อ ราชบุรี 70140",
    },
    {
      value: "ปากท่อ ปากท่อ ราชบุรี 70140",
      label: "ปากท่อ ปากท่อ ราชบุรี 70140",
    },
    {
      value: "ป่าไก่ ปากท่อ ราชบุรี 70140",
      label: "ป่าไก่ ปากท่อ ราชบุรี 70140",
    },
    {
      value: "ยางหัก ปากท่อ ราชบุรี 70140",
      label: "ยางหัก ปากท่อ ราชบุรี 70140",
    },
    {
      value: "วังมะนาว ปากท่อ ราชบุรี 70140",
      label: "วังมะนาว ปากท่อ ราชบุรี 70140",
    },
    {
      value: "วัดยางงาม ปากท่อ ราชบุรี 70140",
      label: "วัดยางงาม ปากท่อ ราชบุรี 70140",
    },
    {
      value: "วันดาว ปากท่อ ราชบุรี 70140",
      label: "วันดาว ปากท่อ ราชบุรี 70140",
    },
    {
      value: "หนองกระทุ่ม ปากท่อ ราชบุรี 70140",
      label: "หนองกระทุ่ม ปากท่อ ราชบุรี 70140",
    },
    {
      value: "ห้วยยางโทน ปากท่อ ราชบุรี 70140",
      label: "ห้วยยางโทน ปากท่อ ราชบุรี 70140",
    },
    {
      value: "อ่างหิน ปากท่อ ราชบุรี 70140",
      label: "อ่างหิน ปากท่อ ราชบุรี 70140",
    },
    {
      value: "จอมประทัด วัดเพลง ราชบุรี 70170",
      label: "จอมประทัด วัดเพลง ราชบุรี 70170",
    },
    {
      value: "วัดเพลง วัดเพลง ราชบุรี 70170",
      label: "วัดเพลง วัดเพลง ราชบุรี 70170",
    },
    {
      value: "เกาะศาลพระ วัดเพลง ราชบุรี 70170",
      label: "เกาะศาลพระ วัดเพลง ราชบุรี 70170",
    },
    {
      value: "ตะนาวศรี สวนผึ้ง ราชบุรี 70180",
      label: "ตะนาวศรี สวนผึ้ง ราชบุรี 70180",
    },
    {
      value: "ท่าเคย สวนผึ้ง ราชบุรี 70180",
      label: "ท่าเคย สวนผึ้ง ราชบุรี 70180",
    },
    {
      value: "ป่าหวาย สวนผึ้ง ราชบุรี 70180",
      label: "ป่าหวาย สวนผึ้ง ราชบุรี 70180",
    },
    {
      value: "สวนผึ้ง สวนผึ้ง ราชบุรี 70180",
      label: "สวนผึ้ง สวนผึ้ง ราชบุรี 70180",
    },
    {
      value: "คุ้งกระถิน เมืองราชบุรี ราชบุรี 70000",
      label: "คุ้งกระถิน เมืองราชบุรี ราชบุรี 70000",
    },
    {
      value: "คุ้งน้ำวน เมืองราชบุรี ราชบุรี 70000",
      label: "คุ้งน้ำวน เมืองราชบุรี ราชบุรี 70000",
    },
    {
      value: "คูบัว เมืองราชบุรี ราชบุรี 70000",
      label: "คูบัว เมืองราชบุรี ราชบุรี 70000",
    },
    {
      value: "ดอนตะโก เมืองราชบุรี ราชบุรี 70000",
      label: "ดอนตะโก เมืองราชบุรี ราชบุรี 70000",
    },
    {
      value: "ดอนแร่ เมืองราชบุรี ราชบุรี 70000",
      label: "ดอนแร่ เมืองราชบุรี ราชบุรี 70000",
    },
    {
      value: "ท่าราบ เมืองราชบุรี ราชบุรี 70000",
      label: "ท่าราบ เมืองราชบุรี ราชบุรี 70000",
    },
    {
      value: "น้ำพุ เมืองราชบุรี ราชบุรี 70000",
      label: "น้ำพุ เมืองราชบุรี ราชบุรี 70000",
    },
    {
      value: "บางป่า เมืองราชบุรี ราชบุรี 70000",
      label: "บางป่า เมืองราชบุรี ราชบุรี 70000",
    },
    {
      value: "บ้านไร่ เมืองราชบุรี ราชบุรี 70000",
      label: "บ้านไร่ เมืองราชบุรี ราชบุรี 70000",
    },
    {
      value: "พงสวาย เมืองราชบุรี ราชบุรี 70000",
      label: "พงสวาย เมืองราชบุรี ราชบุรี 70000",
    },
    {
      value: "พิกุลทอง เมืองราชบุรี ราชบุรี 70000",
      label: "พิกุลทอง เมืองราชบุรี ราชบุรี 70000",
    },
    {
      value: "สามเรือน เมืองราชบุรี ราชบุรี 70000",
      label: "สามเรือน เมืองราชบุรี ราชบุรี 70000",
    },
    {
      value: "หนองกลางนา เมืองราชบุรี ราชบุรี 70000",
      label: "หนองกลางนา เมืองราชบุรี ราชบุรี 70000",
    },
    {
      value: "หน้าเมือง เมืองราชบุรี ราชบุรี 70000",
      label: "หน้าเมือง เมืองราชบุรี ราชบุรี 70000",
    },
    {
      value: "หลุมดิน เมืองราชบุรี ราชบุรี 70000",
      label: "หลุมดิน เมืองราชบุรี ราชบุรี 70000",
    },
    {
      value: "หินกอง เมืองราชบุรี ราชบุรี 70000",
      label: "หินกอง เมืองราชบุรี ราชบุรี 70000",
    },
    {
      value: "ห้วยไผ่ เมืองราชบุรี ราชบุรี 70000",
      label: "ห้วยไผ่ เมืองราชบุรี ราชบุรี 70000",
    },
    {
      value: "อ่างทอง เมืองราชบุรี ราชบุรี 70000",
      label: "อ่างทอง เมืองราชบุรี ราชบุรี 70000",
    },
    {
      value: "เกาะพลับพลา เมืองราชบุรี ราชบุรี 70000",
      label: "เกาะพลับพลา เมืองราชบุรี ราชบุรี 70000",
    },
    {
      value: "เขาแร้ง เมืองราชบุรี ราชบุรี 70000",
      label: "เขาแร้ง เมืองราชบุรี ราชบุรี 70000",
    },
    {
      value: "เจดีย์หัก เมืองราชบุรี ราชบุรี 70000",
      label: "เจดีย์หัก เมืองราชบุรี ราชบุรี 70000",
    },
    {
      value: "โคกหม้อ เมืองราชบุรี ราชบุรี 70000",
      label: "โคกหม้อ เมืองราชบุรี ราชบุรี 70000",
    },
    {
      value: "คลองข่อย โพธาราม ราชบุรี 70120",
      label: "คลองข่อย โพธาราม ราชบุรี 70120",
    },
    {
      value: "คลองตาคต โพธาราม ราชบุรี 70120",
      label: "คลองตาคต โพธาราม ราชบุรี 70120",
    },
    {
      value: "ชำแระ โพธาราม ราชบุรี 70120",
      label: "ชำแระ โพธาราม ราชบุรี 70120",
    },
    {
      value: "ดอนกระเบื้อง โพธาราม ราชบุรี 70120",
      label: "ดอนกระเบื้อง โพธาราม ราชบุรี 70120",
    },
    {
      value: "ดอนทราย โพธาราม ราชบุรี 70120",
      label: "ดอนทราย โพธาราม ราชบุรี 70120",
    },
    {
      value: "ท่าชุมพล โพธาราม ราชบุรี 70120",
      label: "ท่าชุมพล โพธาราม ราชบุรี 70120",
    },
    {
      value: "ธรรมเสน โพธาราม ราชบุรี 70120",
      label: "ธรรมเสน โพธาราม ราชบุรี 70120",
    },
    {
      value: "นางแก้ว โพธาราม ราชบุรี 70120",
      label: "นางแก้ว โพธาราม ราชบุรี 70120",
    },
    {
      value: "บางโตนด โพธาราม ราชบุรี 70120",
      label: "บางโตนด โพธาราม ราชบุรี 70120",
    },
    {
      value: "บ้านฆ้อง โพธาราม ราชบุรี 70120",
      label: "บ้านฆ้อง โพธาราม ราชบุรี 70120",
    },
    {
      value: "บ้านสิงห์ โพธาราม ราชบุรี 70120",
      label: "บ้านสิงห์ โพธาราม ราชบุรี 70120",
    },
    {
      value: "บ้านเลือก โพธาราม ราชบุรี 70120",
      label: "บ้านเลือก โพธาราม ราชบุรี 70120",
    },
    {
      value: "สร้อยฟ้า โพธาราม ราชบุรี 70120",
      label: "สร้อยฟ้า โพธาราม ราชบุรี 70120",
    },
    {
      value: "หนองกวาง โพธาราม ราชบุรี 70120",
      label: "หนองกวาง โพธาราม ราชบุรี 70120",
    },
    {
      value: "หนองโพ โพธาราม ราชบุรี 70120",
      label: "หนองโพ โพธาราม ราชบุรี 70120",
    },
    {
      value: "เขาชะงุ้ม โพธาราม ราชบุรี 70120",
      label: "เขาชะงุ้ม โพธาราม ราชบุรี 70120",
    },
    {
      value: "เจ็ดเสมียน โพธาราม ราชบุรี 70120",
      label: "เจ็ดเสมียน โพธาราม ราชบุรี 70120",
    },
    {
      value: "เตาปูน โพธาราม ราชบุรี 70120",
      label: "เตาปูน โพธาราม ราชบุรี 70120",
    },
    {
      value: "โพธาราม โพธาราม ราชบุรี 70120",
      label: "โพธาราม โพธาราม ราชบุรี 70120",
    },
    {
      value: "คลองท่อมเหนือ คลองท่อม กระบี่ 81120",
      label: "คลองท่อมเหนือ คลองท่อม กระบี่ 81120",
    },
    {
      value: "คลองท่อมใต้ คลองท่อม กระบี่ 81120",
      label: "คลองท่อมใต้ คลองท่อม กระบี่ 81120",
    },
    {
      value: "คลองพน คลองท่อม กระบี่ 81170",
      label: "คลองพน คลองท่อม กระบี่ 81170",
    },
    {
      value: "ทรายขาว คลองท่อม กระบี่ 81170",
      label: "ทรายขาว คลองท่อม กระบี่ 81170",
    },
    {
      value: "พรุดินนา คลองท่อม กระบี่ 81120",
      label: "พรุดินนา คลองท่อม กระบี่ 81120",
    },
    {
      value: "ห้วยน้ำขาว คลองท่อม กระบี่ 81120",
      label: "ห้วยน้ำขาว คลองท่อม กระบี่ 81120",
    },
    {
      value: "เพหลา คลองท่อม กระบี่ 81120",
      label: "เพหลา คลองท่อม กระบี่ 81120",
    },
    {
      value: "คีรีวง ปลายพระยา กระบี่ 81160",
      label: "คีรีวง ปลายพระยา กระบี่ 81160",
    },
    {
      value: "ปลายพระยา ปลายพระยา กระบี่ 81160",
      label: "ปลายพระยา ปลายพระยา กระบี่ 81160",
    },
    {
      value: "เขาต่อ ปลายพระยา กระบี่ 81160",
      label: "เขาต่อ ปลายพระยา กระบี่ 81160",
    },
    {
      value: "เขาเขน ปลายพระยา กระบี่ 81160",
      label: "เขาเขน ปลายพระยา กระบี่ 81160",
    },
    { value: "ดินอุดม ลำทับ กระบี่ 81190", label: "ดินอุดม ลำทับ กระบี่ 81190" },
    { value: "ดินแดง ลำทับ กระบี่ 81190", label: "ดินแดง ลำทับ กระบี่ 81190" },
    {
      value: "ทุ่งไทรทอง ลำทับ กระบี่ 81190",
      label: "ทุ่งไทรทอง ลำทับ กระบี่ 81190",
    },
    { value: "ลำทับ ลำทับ กระบี่ 81190", label: "ลำทับ ลำทับ กระบี่ 81190" },
    {
      value: "คลองยา อ่าวลึก กระบี่ 81110",
      label: "คลองยา อ่าวลึก กระบี่ 81110",
    },
    {
      value: "คลองหิน อ่าวลึก กระบี่ 81110",
      label: "คลองหิน อ่าวลึก กระบี่ 81110",
    },
    {
      value: "นาเหนือ อ่าวลึก กระบี่ 81110",
      label: "นาเหนือ อ่าวลึก กระบี่ 81110",
    },
    {
      value: "บ้านกลาง อ่าวลึก กระบี่ 81110",
      label: "บ้านกลาง อ่าวลึก กระบี่ 81110",
    },
    {
      value: "อ่าวลึกน้อย อ่าวลึก กระบี่ 81110",
      label: "อ่าวลึกน้อย อ่าวลึก กระบี่ 81110",
    },
    {
      value: "อ่าวลึกเหนือ อ่าวลึก กระบี่ 81110",
      label: "อ่าวลึกเหนือ อ่าวลึก กระบี่ 81110",
    },
    {
      value: "อ่าวลึกใต้ อ่าวลึก กระบี่ 81110",
      label: "อ่าวลึกใต้ อ่าวลึก กระบี่ 81110",
    },
    {
      value: "เขาใหญ่ อ่าวลึก กระบี่ 81110",
      label: "เขาใหญ่ อ่าวลึก กระบี่ 81110",
    },
    {
      value: "แหลมสัก อ่าวลึก กระบี่ 81110",
      label: "แหลมสัก อ่าวลึก กระบี่ 81110",
    },
    {
      value: "คลองยาง เกาะลันตา กระบี่ 81120",
      label: "คลองยาง เกาะลันตา กระบี่ 81120",
    },
    {
      value: "ศาลาด่าน เกาะลันตา กระบี่ 81150",
      label: "ศาลาด่าน เกาะลันตา กระบี่ 81150",
    },
    {
      value: "เกาะกลาง เกาะลันตา กระบี่ 81120",
      label: "เกาะกลาง เกาะลันตา กระบี่ 81120",
    },
    {
      value: "เกาะลันตาน้อย เกาะลันตา กระบี่ 81150",
      label: "เกาะลันตาน้อย เกาะลันตา กระบี่ 81150",
    },
    {
      value: "เกาะลันตาใหญ่ เกาะลันตา กระบี่ 81150",
      label: "เกาะลันตาใหญ่ เกาะลันตา กระบี่ 81150",
    },
    {
      value: "พรุเตียว เขาพนม กระบี่ 81140",
      label: "พรุเตียว เขาพนม กระบี่ 81140",
    },
    { value: "สินปุน เขาพนม กระบี่ 80240", label: "สินปุน เขาพนม กระบี่ 80240" },
    {
      value: "หน้าเขา เขาพนม กระบี่ 81140",
      label: "หน้าเขา เขาพนม กระบี่ 81140",
    },
    { value: "เขาดิน เขาพนม กระบี่ 81140", label: "เขาดิน เขาพนม กระบี่ 81140" },
    { value: "เขาพนม เขาพนม กระบี่ 81140", label: "เขาพนม เขาพนม กระบี่ 81140" },
    { value: "โคกหาร เขาพนม กระบี่ 80240", label: "โคกหาร เขาพนม กระบี่ 80240" },
    {
      value: "กระบี่น้อย เมืองกระบี่ กระบี่ 81000",
      label: "กระบี่น้อย เมืองกระบี่ กระบี่ 81000",
    },
    {
      value: "กระบี่ใหญ่ เมืองกระบี่ กระบี่ 81000",
      label: "กระบี่ใหญ่ เมืองกระบี่ กระบี่ 81000",
    },
    {
      value: "คลองประสงค์ เมืองกระบี่ กระบี่ 81000",
      label: "คลองประสงค์ เมืองกระบี่ กระบี่ 81000",
    },
    {
      value: "ทับปริก เมืองกระบี่ กระบี่ 81000",
      label: "ทับปริก เมืองกระบี่ กระบี่ 81000",
    },
    {
      value: "ปากน้ำ เมืองกระบี่ กระบี่ 81000",
      label: "ปากน้ำ เมืองกระบี่ กระบี่ 81000",
    },
    {
      value: "หนองทะเล เมืองกระบี่ กระบี่ 81180",
      label: "หนองทะเล เมืองกระบี่ กระบี่ 81180",
    },
    {
      value: "อ่าวนาง เมืองกระบี่ กระบี่ 81000",
      label: "อ่าวนาง เมืองกระบี่ กระบี่ 81000",
    },
    {
      value: "เขาคราม เมืองกระบี่ กระบี่ 81000",
      label: "เขาคราม เมืองกระบี่ กระบี่ 81000",
    },
    {
      value: "เขาทอง เมืองกระบี่ กระบี่ 81000",
      label: "เขาทอง เมืองกระบี่ กระบี่ 81000",
    },
    {
      value: "ไสไทย เมืองกระบี่ กระบี่ 81000",
      label: "ไสไทย เมืองกระบี่ กระบี่ 81000",
    },
    {
      value: "คลองขนาน เหนือคลอง กระบี่ 81130",
      label: "คลองขนาน เหนือคลอง กระบี่ 81130",
    },
    {
      value: "คลองเขม้า เหนือคลอง กระบี่ 81130",
      label: "คลองเขม้า เหนือคลอง กระบี่ 81130",
    },
    {
      value: "ตลิ่งชัน เหนือคลอง กระบี่ 81130",
      label: "ตลิ่งชัน เหนือคลอง กระบี่ 81130",
    },
    {
      value: "ปกาสัย เหนือคลอง กระบี่ 81130",
      label: "ปกาสัย เหนือคลอง กระบี่ 81130",
    },
    {
      value: "ห้วยยูง เหนือคลอง กระบี่ 81130",
      label: "ห้วยยูง เหนือคลอง กระบี่ 81130",
    },
    {
      value: "เกาะศรีบอยา เหนือคลอง กระบี่ 81130",
      label: "เกาะศรีบอยา เหนือคลอง กระบี่ 81130",
    },
    {
      value: "เหนือคลอง เหนือคลอง กระบี่ 81130",
      label: "เหนือคลอง เหนือคลอง กระบี่ 81130",
    },
    {
      value: "โคกยาง เหนือคลอง กระบี่ 81130",
      label: "โคกยาง เหนือคลอง กระบี่ 81130",
    },
    {
      value: "ช่องไม้แก้ว ทุ่งตะโก ชุมพร 86220",
      label: "ช่องไม้แก้ว ทุ่งตะโก ชุมพร 86220",
    },
    { value: "ตะโก ทุ่งตะโก ชุมพร 86220", label: "ตะโก ทุ่งตะโก ชุมพร 86220" },
    {
      value: "ทุ่งตะไคร ทุ่งตะโก ชุมพร 86220",
      label: "ทุ่งตะไคร ทุ่งตะโก ชุมพร 86220",
    },
    {
      value: "ปากตะโก ทุ่งตะโก ชุมพร 86220",
      label: "ปากตะโก ทุ่งตะโก ชุมพร 86220",
    },
    {
      value: "หมู่เกาะศรีอบยา ทุ่งตะโก ชุมพร ",
      label: "หมู่เกาะศรีอบยา ทุ่งตะโก ชุมพร ",
    },
    { value: "เกาะขี้นก ทุ่งตะโก ชุมพร ", label: "เกาะขี้นก ทุ่งตะโก ชุมพร " },
    { value: "เกาะคราม ทุ่งตะโก ชุมพร ", label: "เกาะคราม ทุ่งตะโก ชุมพร " },
    { value: "เกาะคางสือ ทุ่งตะโก ชุมพร ", label: "เกาะคางสือ ทุ่งตะโก ชุมพร " },
    {
      value: "เกาะมะพร้าว ทุ่งตะโก ชุมพร ",
      label: "เกาะมะพร้าว ทุ่งตะโก ชุมพร ",
    },
    { value: "เกาะมุก ทุ่งตะโก ชุมพร ", label: "เกาะมุก ทุ่งตะโก ชุมพร " },
    { value: "เกาะยอ ทุ่งตะโก ชุมพร ", label: "เกาะยอ ทุ่งตะโก ชุมพร " },
    {
      value: "เกาะรางบรรทัด ทุ่งตะโก ชุมพร ",
      label: "เกาะรางบรรทัด ทุ่งตะโก ชุมพร ",
    },
    { value: "เกาะสูบ ทุ่งตะโก ชุมพร ", label: "เกาะสูบ ทุ่งตะโก ชุมพร " },
    { value: "คุริง ท่าแซะ ชุมพร 86140", label: "คุริง ท่าแซะ ชุมพร 86140" },
    {
      value: "ทรัพย์อนันต์ ท่าแซะ ชุมพร 86140",
      label: "ทรัพย์อนันต์ ท่าแซะ ชุมพร 86140",
    },
    { value: "ท่าข้าม ท่าแซะ ชุมพร 86140", label: "ท่าข้าม ท่าแซะ ชุมพร 86140" },
    { value: "ท่าแซะ ท่าแซะ ชุมพร 86140", label: "ท่าแซะ ท่าแซะ ชุมพร 86140" },
    {
      value: "นากระตาม ท่าแซะ ชุมพร 86140",
      label: "นากระตาม ท่าแซะ ชุมพร 86140",
    },
    { value: "รับร่อ ท่าแซะ ชุมพร 86190", label: "รับร่อ ท่าแซะ ชุมพร 86190" },
    { value: "สลุย ท่าแซะ ชุมพร 86140", label: "สลุย ท่าแซะ ชุมพร 86140" },
    {
      value: "สองพี่น้อง ท่าแซะ ชุมพร 86140",
      label: "สองพี่น้อง ท่าแซะ ชุมพร 86140",
    },
    {
      value: "หงษ์เจริญ ท่าแซะ ชุมพร 86140",
      label: "หงษ์เจริญ ท่าแซะ ชุมพร 86140",
    },
    { value: "หินแก้ว ท่าแซะ ชุมพร 86190", label: "หินแก้ว ท่าแซะ ชุมพร 86190" },
    { value: "ชุมโค ปะทิว ชุมพร 86160", label: "ชุมโค ปะทิว ชุมพร 86160" },
    { value: "ดอนยาง ปะทิว ชุมพร 86210", label: "ดอนยาง ปะทิว ชุมพร 86210" },
    {
      value: "ทะเลทรัพย์ ปะทิว ชุมพร 86160",
      label: "ทะเลทรัพย์ ปะทิว ชุมพร 86160",
    },
    { value: "บางสน ปะทิว ชุมพร 86160", label: "บางสน ปะทิว ชุมพร 86160" },
    { value: "ปากคลอง ปะทิว ชุมพร 86210", label: "ปากคลอง ปะทิว ชุมพร 86210" },
    { value: "สะพลี ปะทิว ชุมพร 86230", label: "สะพลี ปะทิว ชุมพร 86230" },
    { value: "เกาะซีกง ปะทิว ชุมพร ", label: "เกาะซีกง ปะทิว ชุมพร " },
    { value: "เกาะพระ ปะทิว ชุมพร ", label: "เกาะพระ ปะทิว ชุมพร " },
    { value: "เกาะยอ ปะทิว ชุมพร ", label: "เกาะยอ ปะทิว ชุมพร " },
    { value: "เกาะรัง ปะทิว ชุมพร ", label: "เกาะรัง ปะทิว ชุมพร " },
    { value: "เกาะร้านเป็ด ปะทิว ชุมพร ", label: "เกาะร้านเป็ด ปะทิว ชุมพร " },
    { value: "เกาะร้านไก่ ปะทิว ชุมพร ", label: "เกาะร้านไก่ ปะทิว ชุมพร " },
    { value: "เกาะเอียง ปะทิว ชุมพร ", label: "เกาะเอียง ปะทิว ชุมพร " },
    { value: "เกาะไข่ ปะทิว ชุมพร ", label: "เกาะไข่ ปะทิว ชุมพร " },
    {
      value: "เขาไชยราช ปะทิว ชุมพร 86210",
      label: "เขาไชยราช ปะทิว ชุมพร 86210",
    },
    { value: "ปังหวาน พะโต๊ะ ชุมพร 86180", label: "ปังหวาน พะโต๊ะ ชุมพร 86180" },
    { value: "ปากทรง พะโต๊ะ ชุมพร 86180", label: "ปากทรง พะโต๊ะ ชุมพร 86180" },
    {
      value: "พระรักษ์ พะโต๊ะ ชุมพร 86180",
      label: "พระรักษ์ พะโต๊ะ ชุมพร 86180",
    },
    { value: "พะโต๊ะ พะโต๊ะ ชุมพร 86180", label: "พะโต๊ะ พะโต๊ะ ชุมพร 86180" },
    { value: "ทุ่งคาวัด ละแม ชุมพร 86170", label: "ทุ่งคาวัด ละแม ชุมพร 86170" },
    { value: "ทุ่งหลวง ละแม ชุมพร 86170", label: "ทุ่งหลวง ละแม ชุมพร 86170" },
    { value: "ละแม ละแม ชุมพร 86170", label: "ละแม ละแม ชุมพร 86170" },
    { value: "สวนแตง ละแม ชุมพร 86170", label: "สวนแตง ละแม ชุมพร 86170" },
    { value: "ครน สวี ชุมพร 86130", label: "ครน สวี ชุมพร 86130" },
    { value: "ด่านสวี สวี ชุมพร 86130", label: "ด่านสวี สวี ชุมพร 86130" },
    { value: "ทุ่งระยะ สวี ชุมพร 86130", label: "ทุ่งระยะ สวี ชุมพร 86130" },
    { value: "ท่าหิน สวี ชุมพร 86130", label: "ท่าหิน สวี ชุมพร 86130" },
    { value: "นาสัก สวี ชุมพร 86130", label: "นาสัก สวี ชุมพร 86130" },
    { value: "นาโพธิ์ สวี ชุมพร 86130", label: "นาโพธิ์ สวี ชุมพร 86130" },
    { value: "ปากแพรก สวี ชุมพร 86130", label: "ปากแพรก สวี ชุมพร 86130" },
    { value: "วิสัยใต้ สวี ชุมพร 86130", label: "วิสัยใต้ สวี ชุมพร 86130" },
    { value: "สวี สวี ชุมพร 86130", label: "สวี สวี ชุมพร 86130" },
    { value: "เกาะกระ สวี ชุมพร ", label: "เกาะกระ สวี ชุมพร " },
    { value: "เกาะกุลา สวี ชุมพร ", label: "เกาะกุลา สวี ชุมพร " },
    { value: "เกาะทองแก้ว สวี ชุมพร ", label: "เกาะทองแก้ว สวี ชุมพร " },
    { value: "เกาะบาตร์ สวี ชุมพร ", label: "เกาะบาตร์ สวี ชุมพร " },
    { value: "เกาะพัดหวายเล็ก สวี ชุมพร ", label: "เกาะพัดหวายเล็ก สวี ชุมพร " },
    { value: "เกาะพัดหวายใหญ่ สวี ชุมพร ", label: "เกาะพัดหวายใหญ่ สวี ชุมพร " },
    { value: "เกาะยูง สวี ชุมพร ", label: "เกาะยูง สวี ชุมพร " },
    { value: "เกาะหนู สวี ชุมพร ", label: "เกาะหนู สวี ชุมพร " },
    { value: "เกาะแมว สวี ชุมพร ", label: "เกาะแมว สวี ชุมพร " },
    { value: "เกาะแรด สวี ชุมพร ", label: "เกาะแรด สวี ชุมพร " },
    { value: "เขาค่าย สวี ชุมพร 86130", label: "เขาค่าย สวี ชุมพร 86130" },
    { value: "เขาทะลุ สวี ชุมพร 86130", label: "เขาทะลุ สวี ชุมพร 86130" },
    {
      value: "ขันเงิน หลังสวน ชุมพร 86110",
      label: "ขันเงิน หลังสวน ชุมพร 86110",
    },
    {
      value: "ท่ามะพลา หลังสวน ชุมพร 86110",
      label: "ท่ามะพลา หลังสวน ชุมพร 86110",
    },
    { value: "นาขา หลังสวน ชุมพร 86110", label: "นาขา หลังสวน ชุมพร 86110" },
    { value: "นาพญา หลังสวน ชุมพร 86110", label: "นาพญา หลังสวน ชุมพร 86110" },
    {
      value: "บางน้ำจืด หลังสวน ชุมพร 86150",
      label: "บางน้ำจืด หลังสวน ชุมพร 86150",
    },
    {
      value: "บางมะพร้าว หลังสวน ชุมพร 86110",
      label: "บางมะพร้าว หลังสวน ชุมพร 86110",
    },
    {
      value: "บ้านควน หลังสวน ชุมพร 86110",
      label: "บ้านควน หลังสวน ชุมพร 86110",
    },
    { value: "ปากน้ำ หลังสวน ชุมพร 86150", label: "ปากน้ำ หลังสวน ชุมพร 86150" },
    { value: "พ้อแดง หลังสวน ชุมพร 86110", label: "พ้อแดง หลังสวน ชุมพร 86110" },
    {
      value: "วังตะกอ หลังสวน ชุมพร 86110",
      label: "วังตะกอ หลังสวน ชุมพร 86110",
    },
    {
      value: "หลังสวน หลังสวน ชุมพร 86110",
      label: "หลังสวน หลังสวน ชุมพร 86110",
    },
    { value: "หาดยาย หลังสวน ชุมพร 86110", label: "หาดยาย หลังสวน ชุมพร 86110" },
    { value: "เกาะพิทักษ์ หลังสวน ชุมพร ", label: "เกาะพิทักษ์ หลังสวน ชุมพร " },
    {
      value: "แหลมทราย หลังสวน ชุมพร 86110",
      label: "แหลมทราย หลังสวน ชุมพร 86110",
    },
    {
      value: "ขุนกระทิง เมืองชุมพร ชุมพร 86190",
      label: "ขุนกระทิง เมืองชุมพร ชุมพร 86190",
    },
    {
      value: "ตากแดด เมืองชุมพร ชุมพร 86000",
      label: "ตากแดด เมืองชุมพร ชุมพร 86000",
    },
    {
      value: "ถ้ำสิงห์ เมืองชุมพร ชุมพร 86100",
      label: "ถ้ำสิงห์ เมืองชุมพร ชุมพร 86100",
    },
    {
      value: "ทุ่งคา เมืองชุมพร ชุมพร 86100",
      label: "ทุ่งคา เมืองชุมพร ชุมพร 86100",
    },
    {
      value: "ท่าตะเภา เมืองชุมพร ชุมพร 86000",
      label: "ท่าตะเภา เมืองชุมพร ชุมพร 86000",
    },
    {
      value: "ท่ายาง เมืองชุมพร ชุมพร 86000",
      label: "ท่ายาง เมืองชุมพร ชุมพร 86000",
    },
    {
      value: "นาชะอัง เมืองชุมพร ชุมพร 86000",
      label: "นาชะอัง เมืองชุมพร ชุมพร 86000",
    },
    {
      value: "นาทุ่ง เมืองชุมพร ชุมพร 86000",
      label: "นาทุ่ง เมืองชุมพร ชุมพร 86000",
    },
    {
      value: "บางลึก เมืองชุมพร ชุมพร 86000",
      label: "บางลึก เมืองชุมพร ชุมพร 86000",
    },
    {
      value: "บางหมาก เมืองชุมพร ชุมพร 86000",
      label: "บางหมาก เมืองชุมพร ชุมพร 86000",
    },
    {
      value: "บ้านนา เมืองชุมพร ชุมพร 86190",
      label: "บ้านนา เมืองชุมพร ชุมพร 86190",
    },
    {
      value: "ปากน้ำ เมืองชุมพร ชุมพร 86120",
      label: "ปากน้ำ เมืองชุมพร ชุมพร 86120",
    },
    {
      value: "วังใหม่ เมืองชุมพร ชุมพร 86190",
      label: "วังใหม่ เมืองชุมพร ชุมพร 86190",
    },
    {
      value: "วังไผ่ เมืองชุมพร ชุมพร 86000",
      label: "วังไผ่ เมืองชุมพร ชุมพร 86000",
    },
    {
      value: "วิสัยเหนือ เมืองชุมพร ชุมพร 86100",
      label: "วิสัยเหนือ เมืองชุมพร ชุมพร 86100",
    },
    {
      value: "หาดทรายรี เมืองชุมพร ชุมพร 86120",
      label: "หาดทรายรี เมืองชุมพร ชุมพร 86120",
    },
    {
      value: "หาดพันไกร เมืองชุมพร ชุมพร 86000",
      label: "หาดพันไกร เมืองชุมพร ชุมพร 86000",
    },
    { value: "เกาะกา เมืองชุมพร ชุมพร ", label: "เกาะกา เมืองชุมพร ชุมพร " },
    {
      value: "เกาะกินหลักง้ำ เมืองชุมพร ชุมพร ",
      label: "เกาะกินหลักง้ำ เมืองชุมพร ชุมพร ",
    },
    {
      value: "เกาะงามเล็ก เมืองชุมพร ชุมพร ",
      label: "เกาะงามเล็ก เมืองชุมพร ชุมพร ",
    },
    {
      value: "เกาะงามใหญ่ เมืองชุมพร ชุมพร ",
      label: "เกาะงามใหญ่ เมืองชุมพร ชุมพร ",
    },
    {
      value: "เกาะทองหลาง เมืองชุมพร ชุมพร ",
      label: "เกาะทองหลาง เมืองชุมพร ชุมพร ",
    },
    { value: "เกาะทะลุ เมืองชุมพร ชุมพร ", label: "เกาะทะลุ เมืองชุมพร ชุมพร " },
    {
      value: "เกาะมะพร้าว เมืองชุมพร ชุมพร ",
      label: "เกาะมะพร้าว เมืองชุมพร ชุมพร ",
    },
    {
      value: "เกาะมัตโพน เมืองชุมพร ชุมพร ",
      label: "เกาะมัตโพน เมืองชุมพร ชุมพร ",
    },
    {
      value: "เกาะมาตรา เมืองชุมพร ชุมพร ",
      label: "เกาะมาตรา เมืองชุมพร ชุมพร ",
    },
    {
      value: "เกาะรังกาจิว เมืองชุมพร ชุมพร ",
      label: "เกาะรังกาจิว เมืองชุมพร ชุมพร ",
    },
    { value: "เกาะละวะ เมืองชุมพร ชุมพร ", label: "เกาะละวะ เมืองชุมพร ชุมพร " },
    { value: "เกาะสาก เมืองชุมพร ชุมพร ", label: "เกาะสาก เมืองชุมพร ชุมพร " },
    {
      value: "เกาะหลักแรด เมืองชุมพร ชุมพร ",
      label: "เกาะหลักแรด เมืองชุมพร ชุมพร ",
    },
    {
      value: "เกาะอีแรด เมืองชุมพร ชุมพร ",
      label: "เกาะอีแรด เมืองชุมพร ชุมพร ",
    },
    {
      value: "เกาะเสม็ด เมืองชุมพร ชุมพร ",
      label: "เกาะเสม็ด เมืองชุมพร ชุมพร ",
    },
    { value: "กันตัง กันตัง ตรัง 92110", label: "กันตัง กันตัง ตรัง 92110" },
    {
      value: "กันตังใต้ กันตัง ตรัง 92110",
      label: "กันตังใต้ กันตัง ตรัง 92110",
    },
    {
      value: "คลองชีล้อม กันตัง ตรัง 92110",
      label: "คลองชีล้อม กันตัง ตรัง 92110",
    },
    { value: "คลองลุ กันตัง ตรัง 92110", label: "คลองลุ กันตัง ตรัง 92110" },
    { value: "ควนธานี กันตัง ตรัง 92110", label: "ควนธานี กันตัง ตรัง 92110" },
    { value: "นาเกลือ กันตัง ตรัง 92110", label: "นาเกลือ กันตัง ตรัง 92110" },
    { value: "บางสัก กันตัง ตรัง 92110", label: "บางสัก กันตัง ตรัง 92110" },
    { value: "บางหมาก กันตัง ตรัง 92110", label: "บางหมาก กันตัง ตรัง 92110" },
    { value: "บางเป้า กันตัง ตรัง 92110", label: "บางเป้า กันตัง ตรัง 92110" },
    {
      value: "บ่อน้ำร้อน กันตัง ตรัง 92110",
      label: "บ่อน้ำร้อน กันตัง ตรัง 92110",
    },
    { value: "ย่านซื่อ กันตัง ตรัง 92110", label: "ย่านซื่อ กันตัง ตรัง 92110" },
    { value: "วังวน กันตัง ตรัง 92110", label: "วังวน กันตัง ตรัง 92110" },
    { value: "เกาะลิบง กันตัง ตรัง 92110", label: "เกาะลิบง กันตัง ตรัง 92110" },
    { value: "โคกยาง กันตัง ตรัง 92110", label: "โคกยาง กันตัง ตรัง 92110" },
    { value: "ช่อง นาโยง ตรัง 92170", label: "ช่อง นาโยง ตรัง 92170" },
    {
      value: "นาข้าวเสีย นาโยง ตรัง 92170",
      label: "นาข้าวเสีย นาโยง ตรัง 92170",
    },
    {
      value: "นาหมื่นศรี นาโยง ตรัง 92170",
      label: "นาหมื่นศรี นาโยง ตรัง 92170",
    },
    {
      value: "นาโยงเหนือ นาโยง ตรัง 92170",
      label: "นาโยงเหนือ นาโยง ตรัง 92170",
    },
    { value: "ละมอ นาโยง ตรัง 92170", label: "ละมอ นาโยง ตรัง 92170" },
    { value: "โคกสะบ้า นาโยง ตรัง 92170", label: "โคกสะบ้า นาโยง ตรัง 92170" },
    {
      value: "ทุ่งยาว ปะเหลียน ตรัง 92180",
      label: "ทุ่งยาว ปะเหลียน ตรัง 92180",
    },
    {
      value: "ท่าข้าม ปะเหลียน ตรัง 92120",
      label: "ท่าข้าม ปะเหลียน ตรัง 92120",
    },
    { value: "ท่าพญา ปะเหลียน ตรัง 92140", label: "ท่าพญา ปะเหลียน ตรัง 92140" },
    {
      value: "บางด้วน ปะเหลียน ตรัง 92140",
      label: "บางด้วน ปะเหลียน ตรัง 92140",
    },
    { value: "บ้านนา ปะเหลียน ตรัง 92140", label: "บ้านนา ปะเหลียน ตรัง 92140" },
    {
      value: "ปะเหลียน ปะเหลียน ตรัง 92180",
      label: "ปะเหลียน ปะเหลียน ตรัง 92180",
    },
    { value: "ลิพัง ปะเหลียน ตรัง 92180", label: "ลิพัง ปะเหลียน ตรัง 92180" },
    { value: "สุโสะ ปะเหลียน ตรัง 92120", label: "สุโสะ ปะเหลียน ตรัง 92120" },
    {
      value: "เกาะสุกร ปะเหลียน ตรัง 92120",
      label: "เกาะสุกร ปะเหลียน ตรัง 92120",
    },
    {
      value: "แหลมสอม ปะเหลียน ตรัง 92180",
      label: "แหลมสอม ปะเหลียน ตรัง 92180",
    },
    {
      value: "ทุ่งกระบือ ย่านตาขาว ตรัง 92140",
      label: "ทุ่งกระบือ ย่านตาขาว ตรัง 92140",
    },
    {
      value: "ทุ่งค่าย ย่านตาขาว ตรัง 92140",
      label: "ทุ่งค่าย ย่านตาขาว ตรัง 92140",
    },
    {
      value: "นาชุมเห็ด ย่านตาขาว ตรัง 92140",
      label: "นาชุมเห็ด ย่านตาขาว ตรัง 92140",
    },
    {
      value: "ย่านตาขาว ย่านตาขาว ตรัง 92140",
      label: "ย่านตาขาว ย่านตาขาว ตรัง 92140",
    },
    {
      value: "หนองบ่อ ย่านตาขาว ตรัง 92140",
      label: "หนองบ่อ ย่านตาขาว ตรัง 92140",
    },
    {
      value: "เกาะเปียะ ย่านตาขาว ตรัง 92140",
      label: "เกาะเปียะ ย่านตาขาว ตรัง 92140",
    },
    {
      value: "โพรงจระเข้ ย่านตาขาว ตรัง 92140",
      label: "โพรงจระเข้ ย่านตาขาว ตรัง 92140",
    },
    { value: "ในควน ย่านตาขาว ตรัง 92140", label: "ในควน ย่านตาขาว ตรัง 92140" },
    { value: "คลองปาง รัษฎา ตรัง 92160", label: "คลองปาง รัษฎา ตรัง 92160" },
    { value: "ควนเมา รัษฎา ตรัง 92160", label: "ควนเมา รัษฎา ตรัง 92160" },
    { value: "หนองบัว รัษฎา ตรัง 92160", label: "หนองบัว รัษฎา ตรัง 92160" },
    { value: "หนองปรือ รัษฎา ตรัง 92130", label: "หนองปรือ รัษฎา ตรัง 92130" },
    { value: "เขาไพร รัษฎา ตรัง 92160", label: "เขาไพร รัษฎา ตรัง 92160" },
    {
      value: "ท่าสะบ้า วังวิเศษ ตรัง 92000",
      label: "ท่าสะบ้า วังวิเศษ ตรัง 92000",
    },
    {
      value: "วังมะปราง วังวิเศษ ตรัง 92220",
      label: "วังมะปราง วังวิเศษ ตรัง 92220",
    },
    {
      value: "วังมะปรางเหนือ วังวิเศษ ตรัง 92220",
      label: "วังมะปรางเหนือ วังวิเศษ ตรัง 92220",
    },
    { value: "อ่าวตง วังวิเศษ ตรัง 92220", label: "อ่าวตง วังวิเศษ ตรัง 92220" },
    {
      value: "เขาวิเศษ วังวิเศษ ตรัง 92220",
      label: "เขาวิเศษ วังวิเศษ ตรัง 92220",
    },
    { value: "กะลาเส สิเกา ตรัง 92150", label: "กะลาเส สิเกา ตรัง 92150" },
    {
      value: "นาเมืองเพชร สิเกา ตรัง 92000",
      label: "นาเมืองเพชร สิเกา ตรัง 92000",
    },
    { value: "บ่อหิน สิเกา ตรัง 92150", label: "บ่อหิน สิเกา ตรัง 92150" },
    {
      value: "เขาไม้แก้ว สิเกา ตรัง 92150",
      label: "เขาไม้แก้ว สิเกา ตรัง 92150",
    },
    { value: "ไม้ฝาด สิเกา ตรัง 92150", label: "ไม้ฝาด สิเกา ตรัง 92150" },
    { value: "ตะเสะ หาดสำราญ ตรัง 92120", label: "ตะเสะ หาดสำราญ ตรัง 92120" },
    { value: "บ้าหวี หาดสำราญ ตรัง 92120", label: "บ้าหวี หาดสำราญ ตรัง 92120" },
    {
      value: "หาดสำราญ หาดสำราญ ตรัง 92120",
      label: "หาดสำราญ หาดสำราญ ตรัง 92120",
    },
    { value: "ทุ่งต่อ ห้วยยอด ตรัง 92130", label: "ทุ่งต่อ ห้วยยอด ตรัง 92130" },
    { value: "ท่างิ้ว ห้วยยอด ตรัง 92130", label: "ท่างิ้ว ห้วยยอด ตรัง 92130" },
    { value: "นาวง ห้วยยอด ตรัง 92210", label: "นาวง ห้วยยอด ตรัง 92210" },
    { value: "บางกุ้ง ห้วยยอด ตรัง 92210", label: "บางกุ้ง ห้วยยอด ตรัง 92210" },
    { value: "บางดี ห้วยยอด ตรัง 92210", label: "บางดี ห้วยยอด ตรัง 92210" },
    { value: "ปากคม ห้วยยอด ตรัง 92130", label: "ปากคม ห้วยยอด ตรัง 92130" },
    { value: "ปากแจ่ม ห้วยยอด ตรัง 92190", label: "ปากแจ่ม ห้วยยอด ตรัง 92190" },
    { value: "ลำภูรา ห้วยยอด ตรัง 92190", label: "ลำภูรา ห้วยยอด ตรัง 92190" },
    { value: "วังคีรี ห้วยยอด ตรัง 92210", label: "วังคีรี ห้วยยอด ตรัง 92210" },
    {
      value: "หนองช้างแล่น ห้วยยอด ตรัง 92130",
      label: "หนองช้างแล่น ห้วยยอด ตรัง 92130",
    },
    { value: "ห้วยนาง ห้วยยอด ตรัง 92130", label: "ห้วยนาง ห้วยยอด ตรัง 92130" },
    { value: "ห้วยยอด ห้วยยอด ตรัง 92130", label: "ห้วยยอด ห้วยยอด ตรัง 92130" },
    { value: "เขากอบ ห้วยยอด ตรัง 92130", label: "เขากอบ ห้วยยอด ตรัง 92130" },
    { value: "เขาขาว ห้วยยอด ตรัง 92130", label: "เขาขาว ห้วยยอด ตรัง 92130" },
    { value: "เขาปูน ห้วยยอด ตรัง 92130", label: "เขาปูน ห้วยยอด ตรัง 92130" },
    { value: "ในเตา ห้วยยอด ตรัง 92130", label: "ในเตา ห้วยยอด ตรัง 92130" },
    {
      value: "ควนปริง เมืองตรัง ตรัง 92000",
      label: "ควนปริง เมืองตรัง ตรัง 92000",
    },
    {
      value: "ทับเที่ยง เมืองตรัง ตรัง 92000",
      label: "ทับเที่ยง เมืองตรัง ตรัง 92000",
    },
    {
      value: "นาตาล่วง เมืองตรัง ตรัง 92000",
      label: "นาตาล่วง เมืองตรัง ตรัง 92000",
    },
    {
      value: "นาท่ามเหนือ เมืองตรัง ตรัง 92190",
      label: "นาท่ามเหนือ เมืองตรัง ตรัง 92190",
    },
    {
      value: "นาท่ามใต้ เมืองตรัง ตรัง 92190",
      label: "นาท่ามใต้ เมืองตรัง ตรัง 92190",
    },
    {
      value: "นาบินหลา เมืองตรัง ตรัง 92170",
      label: "นาบินหลา เมืองตรัง ตรัง 92170",
    },
    { value: "นาพละ เมืองตรัง ตรัง 92000", label: "นาพละ เมืองตรัง ตรัง 92000" },
    {
      value: "นาโต๊ะหมิง เมืองตรัง ตรัง 92000",
      label: "นาโต๊ะหมิง เมืองตรัง ตรัง 92000",
    },
    {
      value: "นาโยงใต้ เมืองตรัง ตรัง 92170",
      label: "นาโยงใต้ เมืองตรัง ตรัง 92170",
    },
    {
      value: "น้ำผุด เมืองตรัง ตรัง 92000",
      label: "น้ำผุด เมืองตรัง ตรัง 92000",
    },
    {
      value: "บางรัก เมืองตรัง ตรัง 92000",
      label: "บางรัก เมืองตรัง ตรัง 92000",
    },
    {
      value: "บ้านควน เมืองตรัง ตรัง 92000",
      label: "บ้านควน เมืองตรัง ตรัง 92000",
    },
    {
      value: "บ้านโพธิ์ เมืองตรัง ตรัง 92000",
      label: "บ้านโพธิ์ เมืองตรัง ตรัง 92000",
    },
    {
      value: "หนองตรุด เมืองตรัง ตรัง 92000",
      label: "หนองตรุด เมืองตรัง ตรัง 92000",
    },
    {
      value: "โคกหล่อ เมืองตรัง ตรัง 92000",
      label: "โคกหล่อ เมืองตรัง ตรัง 92000",
    },
    {
      value: "ขนอม ขนอม นครศรีธรรมราช 80210",
      label: "ขนอม ขนอม นครศรีธรรมราช 80210",
    },
    {
      value: "ควนทอง ขนอม นครศรีธรรมราช 80210",
      label: "ควนทอง ขนอม นครศรีธรรมราช 80210",
    },
    {
      value: "ท้องเนียน ขนอม นครศรีธรรมราช 80210",
      label: "ท้องเนียน ขนอม นครศรีธรรมราช 80210",
    },
    {
      value: "ควนหนองคว้า จุฬาภรณ์ นครศรีธรรมราช 80130",
      label: "ควนหนองคว้า จุฬาภรณ์ นครศรีธรรมราช 80130",
    },
    {
      value: "ทุ่งโพธิ์ จุฬาภรณ์ นครศรีธรรมราช 80130",
      label: "ทุ่งโพธิ์ จุฬาภรณ์ นครศรีธรรมราช 80130",
    },
    {
      value: "นาหมอบุญ จุฬาภรณ์ นครศรีธรรมราช 80130",
      label: "นาหมอบุญ จุฬาภรณ์ นครศรีธรรมราช 80130",
    },
    {
      value: "บ้านควนมุด จุฬาภรณ์ นครศรีธรรมราช 80180",
      label: "บ้านควนมุด จุฬาภรณ์ นครศรีธรรมราช 80180",
    },
    {
      value: "บ้านชะอวด จุฬาภรณ์ นครศรีธรรมราช 80180",
      label: "บ้านชะอวด จุฬาภรณ์ นครศรีธรรมราช 80180",
    },
    {
      value: "สามตำบล จุฬาภรณ์ นครศรีธรรมราช 80130",
      label: "สามตำบล จุฬาภรณ์ นครศรีธรรมราช 80130",
    },
    {
      value: "กะเปียด ฉวาง นครศรีธรรมราช 80260",
      label: "กะเปียด ฉวาง นครศรีธรรมราช 80260",
    },
    {
      value: "จันดี ฉวาง นครศรีธรรมราช 80250",
      label: "จันดี ฉวาง นครศรีธรรมราช 80250",
    },
    {
      value: "ฉวาง ฉวาง นครศรีธรรมราช 80150",
      label: "ฉวาง ฉวาง นครศรีธรรมราช 80150",
    },
    {
      value: "นากะชะ ฉวาง นครศรีธรรมราช 80150",
      label: "นากะชะ ฉวาง นครศรีธรรมราช 80150",
    },
    {
      value: "นาเขลียง ฉวาง นครศรีธรรมราช 80260",
      label: "นาเขลียง ฉวาง นครศรีธรรมราช 80260",
    },
    {
      value: "นาแว ฉวาง นครศรีธรรมราช 80260",
      label: "นาแว ฉวาง นครศรีธรรมราช 80260",
    },
    {
      value: "ละอาย ฉวาง นครศรีธรรมราช 80250",
      label: "ละอาย ฉวาง นครศรีธรรมราช 80250",
    },
    {
      value: "ห้วยปริก ฉวาง นครศรีธรรมราช 80260",
      label: "ห้วยปริก ฉวาง นครศรีธรรมราช 80260",
    },
    {
      value: "ไม้เรียง ฉวาง นครศรีธรรมราช 80260",
      label: "ไม้เรียง ฉวาง นครศรีธรรมราช 80260",
    },
    {
      value: "ไสหร้า ฉวาง นครศรีธรรมราช 80150",
      label: "ไสหร้า ฉวาง นครศรีธรรมราช 80150",
    },
    {
      value: "ขอนหาด ชะอวด นครศรีธรรมราช 80180",
      label: "ขอนหาด ชะอวด นครศรีธรรมราช 80180",
    },
    {
      value: "ควนหนองหงษ์ ชะอวด นครศรีธรรมราช 80180",
      label: "ควนหนองหงษ์ ชะอวด นครศรีธรรมราช 80180",
    },
    {
      value: "ชะอวด ชะอวด นครศรีธรรมราช 80180",
      label: "ชะอวด ชะอวด นครศรีธรรมราช 80180",
    },
    {
      value: "ท่าประจะ ชะอวด นครศรีธรรมราช 80180",
      label: "ท่าประจะ ชะอวด นครศรีธรรมราช 80180",
    },
    {
      value: "ท่าเสม็ด ชะอวด นครศรีธรรมราช 80180",
      label: "ท่าเสม็ด ชะอวด นครศรีธรรมราช 80180",
    },
    {
      value: "นางหลง ชะอวด นครศรีธรรมราช 80180",
      label: "นางหลง ชะอวด นครศรีธรรมราช 80180",
    },
    {
      value: "บ้านตูล ชะอวด นครศรีธรรมราช 80180",
      label: "บ้านตูล ชะอวด นครศรีธรรมราช 80180",
    },
    {
      value: "วังอ่าง ชะอวด นครศรีธรรมราช 80180",
      label: "วังอ่าง ชะอวด นครศรีธรรมราช 80180",
    },
    {
      value: "เกาะขันธ์ ชะอวด นครศรีธรรมราช 80180",
      label: "เกาะขันธ์ ชะอวด นครศรีธรรมราช 80180",
    },
    {
      value: "เขาพระทอง ชะอวด นครศรีธรรมราช 80180",
      label: "เขาพระทอง ชะอวด นครศรีธรรมราช 80180",
    },
    {
      value: "เคร็ง ชะอวด นครศรีธรรมราช 80180",
      label: "เคร็ง ชะอวด นครศรีธรรมราช 80180",
    },
    {
      value: "ช้างกลาง ช้างกลาง นครศรีธรรมราช 80250",
      label: "ช้างกลาง ช้างกลาง นครศรีธรรมราช 80250",
    },
    {
      value: "สวนขัน ช้างกลาง นครศรีธรรมราช 80250",
      label: "สวนขัน ช้างกลาง นครศรีธรรมราช 80250",
    },
    {
      value: "หลักช้าง ช้างกลาง นครศรีธรรมราช 80250",
      label: "หลักช้าง ช้างกลาง นครศรีธรรมราช 80250",
    },
    {
      value: "คลองเส ถ้ำพรรณรา นครศรีธรรมราช 80260",
      label: "คลองเส ถ้ำพรรณรา นครศรีธรรมราช 80260",
    },
    {
      value: "ดุสิต ถ้ำพรรณรา นครศรีธรรมราช 80260",
      label: "ดุสิต ถ้ำพรรณรา นครศรีธรรมราช 80260",
    },
    {
      value: "ถ้ำพรรณรา ถ้ำพรรณรา นครศรีธรรมราช 80260",
      label: "ถ้ำพรรณรา ถ้ำพรรณรา นครศรีธรรมราช 80260",
    },
    {
      value: "กะปาง ทุ่งสง นครศรีธรรมราช 80310",
      label: "กะปาง ทุ่งสง นครศรีธรรมราช 80310",
    },
    {
      value: "ควนกรด ทุ่งสง นครศรีธรรมราช 80110",
      label: "ควนกรด ทุ่งสง นครศรีธรรมราช 80110",
    },
    {
      value: "ชะมาย ทุ่งสง นครศรีธรรมราช 80110",
      label: "ชะมาย ทุ่งสง นครศรีธรรมราช 80110",
    },
    {
      value: "ถ้ำใหญ่ ทุ่งสง นครศรีธรรมราช 80110",
      label: "ถ้ำใหญ่ ทุ่งสง นครศรีธรรมราช 80110",
    },
    {
      value: "ที่วัง ทุ่งสง นครศรีธรรมราช 80110",
      label: "ที่วัง ทุ่งสง นครศรีธรรมราช 80110",
    },
    {
      value: "นาหลวงเสน ทุ่งสง นครศรีธรรมราช 80110",
      label: "นาหลวงเสน ทุ่งสง นครศรีธรรมราช 80110",
    },
    {
      value: "นาโพธิ์ ทุ่งสง นครศรีธรรมราช 80110",
      label: "นาโพธิ์ ทุ่งสง นครศรีธรรมราช 80110",
    },
    {
      value: "นาไม้ไผ่ ทุ่งสง นครศรีธรรมราช 80110",
      label: "นาไม้ไผ่ ทุ่งสง นครศรีธรรมราช 80110",
    },
    {
      value: "น้ำตก ทุ่งสง นครศรีธรรมราช 80110",
      label: "น้ำตก ทุ่งสง นครศรีธรรมราช 80110",
    },
    {
      value: "ปากแพรก ทุ่งสง นครศรีธรรมราช 80110",
      label: "ปากแพรก ทุ่งสง นครศรีธรรมราช 80110",
    },
    {
      value: "หนองหงส์ ทุ่งสง นครศรีธรรมราช 80110",
      label: "หนองหงส์ ทุ่งสง นครศรีธรรมราช 80110",
    },
    {
      value: "เขาขาว ทุ่งสง นครศรีธรรมราช 80110",
      label: "เขาขาว ทุ่งสง นครศรีธรรมราช 80110",
    },
    {
      value: "เขาโร ทุ่งสง นครศรีธรรมราช 80110",
      label: "เขาโร ทุ่งสง นครศรีธรรมราช 80110",
    },
    {
      value: "กรุงหยัน ทุ่งใหญ่ นครศรีธรรมราช 80240",
      label: "กรุงหยัน ทุ่งใหญ่ นครศรีธรรมราช 80240",
    },
    {
      value: "กุแหระ ทุ่งใหญ่ นครศรีธรรมราช 80240",
      label: "กุแหระ ทุ่งใหญ่ นครศรีธรรมราช 80240",
    },
    {
      value: "ทุ่งสัง ทุ่งใหญ่ นครศรีธรรมราช 80240",
      label: "ทุ่งสัง ทุ่งใหญ่ นครศรีธรรมราช 80240",
    },
    {
      value: "ทุ่งใหญ่ ทุ่งใหญ่ นครศรีธรรมราช 80240",
      label: "ทุ่งใหญ่ ทุ่งใหญ่ นครศรีธรรมราช 80240",
    },
    {
      value: "ท่ายาง ทุ่งใหญ่ นครศรีธรรมราช 80240",
      label: "ท่ายาง ทุ่งใหญ่ นครศรีธรรมราช 80240",
    },
    {
      value: "บางรูป ทุ่งใหญ่ นครศรีธรรมราช 80240",
      label: "บางรูป ทุ่งใหญ่ นครศรีธรรมราช 80240",
    },
    {
      value: "ปริก ทุ่งใหญ่ นครศรีธรรมราช 80240",
      label: "ปริก ทุ่งใหญ่ นครศรีธรรมราช 80240",
    },
    {
      value: "กลาย ท่าศาลา นครศรีธรรมราช 80160",
      label: "กลาย ท่าศาลา นครศรีธรรมราช 80160",
    },
    {
      value: "ดอนตะโก ท่าศาลา นครศรีธรรมราช 80160",
      label: "ดอนตะโก ท่าศาลา นครศรีธรรมราช 80160",
    },
    {
      value: "ตลิ่งชัน ท่าศาลา นครศรีธรรมราช 80160",
      label: "ตลิ่งชัน ท่าศาลา นครศรีธรรมราช 80160",
    },
    {
      value: "ท่าขึ้น ท่าศาลา นครศรีธรรมราช 80160",
      label: "ท่าขึ้น ท่าศาลา นครศรีธรรมราช 80160",
    },
    {
      value: "ท่าศาลา ท่าศาลา นครศรีธรรมราช 80160",
      label: "ท่าศาลา ท่าศาลา นครศรีธรรมราช 80160",
    },
    {
      value: "สระแก้ว ท่าศาลา นครศรีธรรมราช 80160",
      label: "สระแก้ว ท่าศาลา นครศรีธรรมราช 80160",
    },
    {
      value: "หัวตะพาน ท่าศาลา นครศรีธรรมราช 80160",
      label: "หัวตะพาน ท่าศาลา นครศรีธรรมราช 80160",
    },
    {
      value: "โพธิ์ทอง ท่าศาลา นครศรีธรรมราช 80160",
      label: "โพธิ์ทอง ท่าศาลา นครศรีธรรมราช 80160",
    },
    {
      value: "โมคลาน ท่าศาลา นครศรีธรรมราช 80160",
      label: "โมคลาน ท่าศาลา นครศรีธรรมราช 80160",
    },
    {
      value: "ไทยบุรี ท่าศาลา นครศรีธรรมราช 80160",
      label: "ไทยบุรี ท่าศาลา นครศรีธรรมราช 80160",
    },
    {
      value: "กรุงชิง นบพิตำ นครศรีธรรมราช 80160",
      label: "กรุงชิง นบพิตำ นครศรีธรรมราช 80160",
    },
    {
      value: "กะหรอ นบพิตำ นครศรีธรรมราช 80160",
      label: "กะหรอ นบพิตำ นครศรีธรรมราช 80160",
    },
    {
      value: "นบพิตำ นบพิตำ นครศรีธรรมราช 80160",
      label: "นบพิตำ นบพิตำ นครศรีธรรมราช 80160",
    },
    {
      value: "นาเหรง นบพิตำ นครศรีธรรมราช 80160",
      label: "นาเหรง นบพิตำ นครศรีธรรมราช 80160",
    },
    {
      value: "ทุ่งสง นาบอน นครศรีธรรมราช 80220",
      label: "ทุ่งสง นาบอน นครศรีธรรมราช 80220",
    },
    {
      value: "นาบอน นาบอน นครศรีธรรมราช 80220",
      label: "นาบอน นาบอน นครศรีธรรมราช 80220",
    },
    {
      value: "แก้วแสน นาบอน นครศรีธรรมราช 80220",
      label: "แก้วแสน นาบอน นครศรีธรรมราช 80220",
    },
    {
      value: "บางขัน บางขัน นครศรีธรรมราช 80360",
      label: "บางขัน บางขัน นครศรีธรรมราช 80360",
    },
    {
      value: "บ้านนิคม บางขัน นครศรีธรรมราช 80360",
      label: "บ้านนิคม บางขัน นครศรีธรรมราช 80360",
    },
    {
      value: "บ้านลำนาว บางขัน นครศรีธรรมราช 80360",
      label: "บ้านลำนาว บางขัน นครศรีธรรมราช 80360",
    },
    {
      value: "วังหิน บางขัน นครศรีธรรมราช 80360",
      label: "วังหิน บางขัน นครศรีธรรมราช 80360",
    },
    {
      value: "ขนาบนาก ปากพนัง นครศรีธรรมราช 80140",
      label: "ขนาบนาก ปากพนัง นครศรีธรรมราช 80140",
    },
    {
      value: "คลองกระบือ ปากพนัง นครศรีธรรมราช 80370",
      label: "คลองกระบือ ปากพนัง นครศรีธรรมราช 80370",
    },
    {
      value: "คลองน้อย ปากพนัง นครศรีธรรมราช 80330",
      label: "คลองน้อย ปากพนัง นครศรีธรรมราช 80330",
    },
    {
      value: "ชะเมา ปากพนัง นครศรีธรรมราช 80330",
      label: "ชะเมา ปากพนัง นครศรีธรรมราช 80330",
    },
    {
      value: "ท่าพยา ปากพนัง นครศรีธรรมราช 80140",
      label: "ท่าพยา ปากพนัง นครศรีธรรมราช 80140",
    },
    {
      value: "บางตะพง ปากพนัง นครศรีธรรมราช 80370",
      label: "บางตะพง ปากพนัง นครศรีธรรมราช 80370",
    },
    {
      value: "บางพระ ปากพนัง นครศรีธรรมราช 80140",
      label: "บางพระ ปากพนัง นครศรีธรรมราช 80140",
    },
    {
      value: "บางศาลา ปากพนัง นครศรีธรรมราช 80370",
      label: "บางศาลา ปากพนัง นครศรีธรรมราช 80370",
    },
    {
      value: "บ้านเพิง ปากพนัง นครศรีธรรมราช 80140",
      label: "บ้านเพิง ปากพนัง นครศรีธรรมราช 80140",
    },
    {
      value: "บ้านใหม่ ปากพนัง นครศรีธรรมราช 80370",
      label: "บ้านใหม่ ปากพนัง นครศรีธรรมราช 80370",
    },
    {
      value: "ปากพนัง ปากพนัง นครศรีธรรมราช 80140",
      label: "ปากพนัง ปากพนัง นครศรีธรรมราช 80140",
    },
    {
      value: "ปากพนังฝั่งตะวันตก ปากพนัง นครศรีธรรมราช 80370",
      label: "ปากพนังฝั่งตะวันตก ปากพนัง นครศรีธรรมราช 80370",
    },
    {
      value: "ปากพนังฝั่งตะวันออก ปากพนัง นครศรีธรรมราช 80140",
      label: "ปากพนังฝั่งตะวันออก ปากพนัง นครศรีธรรมราช 80140",
    },
    {
      value: "ปากแพรก ปากพนัง นครศรีธรรมราช 80140",
      label: "ปากแพรก ปากพนัง นครศรีธรรมราช 80140",
    },
    {
      value: "ป่าระกำ ปากพนัง นครศรีธรรมราช 80370",
      label: "ป่าระกำ ปากพนัง นครศรีธรรมราช 80370",
    },
    {
      value: "หูล่อง ปากพนัง นครศรีธรรมราช 80370",
      label: "หูล่อง ปากพนัง นครศรีธรรมราช 80370",
    },
    {
      value: "เกาะทวด ปากพนัง นครศรีธรรมราช 80330",
      label: "เกาะทวด ปากพนัง นครศรีธรรมราช 80330",
    },
    {
      value: "แหลมตะลุมพุก ปากพนัง นครศรีธรรมราช 80140",
      label: "แหลมตะลุมพุก ปากพนัง นครศรีธรรมราช 80140",
    },
    {
      value: "ทอนหงส์ พรหมคีรี นครศรีธรรมราช 80320",
      label: "ทอนหงส์ พรหมคีรี นครศรีธรรมราช 80320",
    },
    {
      value: "นาเรียง พรหมคีรี นครศรีธรรมราช 80320",
      label: "นาเรียง พรหมคีรี นครศรีธรรมราช 80320",
    },
    {
      value: "บ้านเกาะ พรหมคีรี นครศรีธรรมราช 80320",
      label: "บ้านเกาะ พรหมคีรี นครศรีธรรมราช 80320",
    },
    {
      value: "พรหมโลก พรหมคีรี นครศรีธรรมราช 80320",
      label: "พรหมโลก พรหมคีรี นครศรีธรรมราช 80320",
    },
    {
      value: "อินคีรี พรหมคีรี นครศรีธรรมราช 80320",
      label: "อินคีรี พรหมคีรี นครศรีธรรมราช 80320",
    },
    {
      value: "ช้างซ้าย พระพรหม นครศรีธรรมราช 80000",
      label: "ช้างซ้าย พระพรหม นครศรีธรรมราช 80000",
    },
    {
      value: "ท้ายสำเภา พระพรหม นครศรีธรรมราช 80000",
      label: "ท้ายสำเภา พระพรหม นครศรีธรรมราช 80000",
    },
    {
      value: "นาพรุ พระพรหม นครศรีธรรมราช 80000",
      label: "นาพรุ พระพรหม นครศรีธรรมราช 80000",
    },
    {
      value: "นาสาร พระพรหม นครศรีธรรมราช 80000",
      label: "นาสาร พระพรหม นครศรีธรรมราช 80000",
    },
    {
      value: "กะทูน พิปูน นครศรีธรรมราช 80270",
      label: "กะทูน พิปูน นครศรีธรรมราช 80270",
    },
    {
      value: "ควนกลาง พิปูน นครศรีธรรมราช 80270",
      label: "ควนกลาง พิปูน นครศรีธรรมราช 80270",
    },
    {
      value: "พิปูน พิปูน นครศรีธรรมราช 80270",
      label: "พิปูน พิปูน นครศรีธรรมราช 80270",
    },
    {
      value: "ยางค้อม พิปูน นครศรีธรรมราช 80270",
      label: "ยางค้อม พิปูน นครศรีธรรมราช 80270",
    },
    {
      value: "เขาพระ พิปูน นครศรีธรรมราช 80270",
      label: "เขาพระ พิปูน นครศรีธรรมราช 80270",
    },
    {
      value: "ควนชุม ร่อนพิบูลย์ นครศรีธรรมราช 80130",
      label: "ควนชุม ร่อนพิบูลย์ นครศรีธรรมราช 80130",
    },
    {
      value: "ควนพัง ร่อนพิบูลย์ นครศรีธรรมราช 80130",
      label: "ควนพัง ร่อนพิบูลย์ นครศรีธรรมราช 80130",
    },
    {
      value: "ควนเกย ร่อนพิบูลย์ นครศรีธรรมราช 80130",
      label: "ควนเกย ร่อนพิบูลย์ นครศรีธรรมราช 80130",
    },
    {
      value: "ร่อนพิบูลย์ ร่อนพิบูลย์ นครศรีธรรมราช 80130",
      label: "ร่อนพิบูลย์ ร่อนพิบูลย์ นครศรีธรรมราช 80130",
    },
    {
      value: "หินตก ร่อนพิบูลย์ นครศรีธรรมราช 80350",
      label: "หินตก ร่อนพิบูลย์ นครศรีธรรมราช 80350",
    },
    {
      value: "เสาธง ร่อนพิบูลย์ นครศรีธรรมราช 80350",
      label: "เสาธง ร่อนพิบูลย์ นครศรีธรรมราช 80350",
    },
    {
      value: "กำโลน ลานสกา นครศรีธรรมราช 80230",
      label: "กำโลน ลานสกา นครศรีธรรมราช 80230",
    },
    {
      value: "ขุนทะเล ลานสกา นครศรีธรรมราช 80230",
      label: "ขุนทะเล ลานสกา นครศรีธรรมราช 80230",
    },
    {
      value: "ท่าดี ลานสกา นครศรีธรรมราช 80230",
      label: "ท่าดี ลานสกา นครศรีธรรมราช 80230",
    },
    {
      value: "ลานสกา ลานสกา นครศรีธรรมราช 80230",
      label: "ลานสกา ลานสกา นครศรีธรรมราช 80230",
    },
    {
      value: "เขาแก้ว ลานสกา นครศรีธรรมราช 80230",
      label: "เขาแก้ว ลานสกา นครศรีธรรมราช 80230",
    },
    {
      value: "ฉลอง สิชล นครศรีธรรมราช 80120",
      label: "ฉลอง สิชล นครศรีธรรมราช 80120",
    },
    {
      value: "ทุ่งปรัง สิชล นครศรีธรรมราช 80120",
      label: "ทุ่งปรัง สิชล นครศรีธรรมราช 80120",
    },
    {
      value: "ทุ่งใส สิชล นครศรีธรรมราช 80120",
      label: "ทุ่งใส สิชล นครศรีธรรมราช 80120",
    },
    {
      value: "สิชล สิชล นครศรีธรรมราช 80120",
      label: "สิชล สิชล นครศรีธรรมราช 80120",
    },
    {
      value: "สี่ขีด สิชล นครศรีธรรมราช 80120",
      label: "สี่ขีด สิชล นครศรีธรรมราช 80120",
    },
    {
      value: "เขาน้อย สิชล นครศรีธรรมราช 80120",
      label: "เขาน้อย สิชล นครศรีธรรมราช 80120",
    },
    {
      value: "เทพราช สิชล นครศรีธรรมราช 80340",
      label: "เทพราช สิชล นครศรีธรรมราช 80340",
    },
    {
      value: "เปลี่ยน สิชล นครศรีธรรมราช 80120",
      label: "เปลี่ยน สิชล นครศรีธรรมราช 80120",
    },
    {
      value: "เสาเภา สิชล นครศรีธรรมราช 80340",
      label: "เสาเภา สิชล นครศรีธรรมราช 80340",
    },
    {
      value: "ควนชะลิก หัวไทร นครศรีธรรมราช 80170",
      label: "ควนชะลิก หัวไทร นครศรีธรรมราช 80170",
    },
    {
      value: "ทรายขาว หัวไทร นครศรีธรรมราช 80170",
      label: "ทรายขาว หัวไทร นครศรีธรรมราช 80170",
    },
    {
      value: "ท่าซอม หัวไทร นครศรีธรรมราช 80170",
      label: "ท่าซอม หัวไทร นครศรีธรรมราช 80170",
    },
    {
      value: "บางนบ หัวไทร นครศรีธรรมราช 80170",
      label: "บางนบ หัวไทร นครศรีธรรมราช 80170",
    },
    {
      value: "บ้านราม หัวไทร นครศรีธรรมราช 80170",
      label: "บ้านราม หัวไทร นครศรีธรรมราช 80170",
    },
    {
      value: "รามแก้ว หัวไทร นครศรีธรรมราช 80170",
      label: "รามแก้ว หัวไทร นครศรีธรรมราช 80170",
    },
    {
      value: "หน้าสตน หัวไทร นครศรีธรรมราช 80170",
      label: "หน้าสตน หัวไทร นครศรีธรรมราช 80170",
    },
    {
      value: "หัวไทร หัวไทร นครศรีธรรมราช 80170",
      label: "หัวไทร หัวไทร นครศรีธรรมราช 80170",
    },
    {
      value: "เกาะเพชร หัวไทร นครศรีธรรมราช 80170",
      label: "เกาะเพชร หัวไทร นครศรีธรรมราช 80170",
    },
    {
      value: "เขาพังไกร หัวไทร นครศรีธรรมราช 80170",
      label: "เขาพังไกร หัวไทร นครศรีธรรมราช 80170",
    },
    {
      value: "แหลม หัวไทร นครศรีธรรมราช 80170",
      label: "แหลม หัวไทร นครศรีธรรมราช 80170",
    },
    {
      value: "ดอนตรอ เฉลิมพระเกียรติ นครศรีธรรมราช 80290",
      label: "ดอนตรอ เฉลิมพระเกียรติ นครศรีธรรมราช 80290",
    },
    {
      value: "ทางพูน เฉลิมพระเกียรติ นครศรีธรรมราช 80290",
      label: "ทางพูน เฉลิมพระเกียรติ นครศรีธรรมราช 80290",
    },
    {
      value: "สวนหลวง เฉลิมพระเกียรติ นครศรีธรรมราช 80190",
      label: "สวนหลวง เฉลิมพระเกียรติ นครศรีธรรมราช 80190",
    },
    {
      value: "เชียรเขา เฉลิมพระเกียรติ นครศรีธรรมราช 80190",
      label: "เชียรเขา เฉลิมพระเกียรติ นครศรีธรรมราช 80190",
    },
    {
      value: "การะเกด เชียรใหญ่ นครศรีธรรมราช 80190",
      label: "การะเกด เชียรใหญ่ นครศรีธรรมราช 80190",
    },
    {
      value: "ท่าขนาน เชียรใหญ่ นครศรีธรรมราช 80190",
      label: "ท่าขนาน เชียรใหญ่ นครศรีธรรมราช 80190",
    },
    {
      value: "ท้องลำเจียก เชียรใหญ่ นครศรีธรรมราช 80190",
      label: "ท้องลำเจียก เชียรใหญ่ นครศรีธรรมราช 80190",
    },
    {
      value: "บ้านกลาง เชียรใหญ่ นครศรีธรรมราช 80190",
      label: "บ้านกลาง เชียรใหญ่ นครศรีธรรมราช 80190",
    },
    {
      value: "บ้านเนิน เชียรใหญ่ นครศรีธรรมราช 80190",
      label: "บ้านเนิน เชียรใหญ่ นครศรีธรรมราช 80190",
    },
    {
      value: "เขาพระบาท เชียรใหญ่ นครศรีธรรมราช 80190",
      label: "เขาพระบาท เชียรใหญ่ นครศรีธรรมราช 80190",
    },
    {
      value: "เชียรใหญ่ เชียรใหญ่ นครศรีธรรมราช 80190",
      label: "เชียรใหญ่ เชียรใหญ่ นครศรีธรรมราช 80190",
    },
    {
      value: "เสือหึง เชียรใหญ่ นครศรีธรรมราช 80190",
      label: "เสือหึง เชียรใหญ่ นครศรีธรรมราช 80190",
    },
    {
      value: "แม่เจ้าอยู่หัว เชียรใหญ่ นครศรีธรรมราช 80190",
      label: "แม่เจ้าอยู่หัว เชียรใหญ่ นครศรีธรรมราช 80190",
    },
    {
      value: "ไสหมาก เชียรใหญ่ นครศรีธรรมราช 80190",
      label: "ไสหมาก เชียรใหญ่ นครศรีธรรมราช 80190",
    },
    {
      value: "กำแพงเซา เมืองนครศรีธรรมราช นครศรีธรรมราช 80280",
      label: "กำแพงเซา เมืองนครศรีธรรมราช นครศรีธรรมราช 80280",
    },
    {
      value: "คลัง เมืองนครศรีธรรมราช นครศรีธรรมราช 80000",
      label: "คลัง เมืองนครศรีธรรมราช นครศรีธรรมราช 80000",
    },
    {
      value: "ท่างิ้ว เมืองนครศรีธรรมราช นครศรีธรรมราช 80280",
      label: "ท่างิ้ว เมืองนครศรีธรรมราช นครศรีธรรมราช 80280",
    },
    {
      value: "ท่าซัก เมืองนครศรีธรรมราช นครศรีธรรมราช 80000",
      label: "ท่าซัก เมืองนครศรีธรรมราช นครศรีธรรมราช 80000",
    },
    {
      value: "ท่าวัง เมืองนครศรีธรรมราช นครศรีธรรมราช 80000",
      label: "ท่าวัง เมืองนครศรีธรรมราช นครศรีธรรมราช 80000",
    },
    {
      value: "ท่าเรือ เมืองนครศรีธรรมราช นครศรีธรรมราช 80000",
      label: "ท่าเรือ เมืองนครศรีธรรมราช นครศรีธรรมราช 80000",
    },
    {
      value: "ท่าไร่ เมืองนครศรีธรรมราช นครศรีธรรมราช 80000",
      label: "ท่าไร่ เมืองนครศรีธรรมราช นครศรีธรรมราช 80000",
    },
    {
      value: "นาทราย เมืองนครศรีธรรมราช นครศรีธรรมราช 80280",
      label: "นาทราย เมืองนครศรีธรรมราช นครศรีธรรมราช 80280",
    },
    {
      value: "นาเคียน เมืองนครศรีธรรมราช นครศรีธรรมราช 80000",
      label: "นาเคียน เมืองนครศรีธรรมราช นครศรีธรรมราช 80000",
    },
    {
      value: "บางจาก เมืองนครศรีธรรมราช นครศรีธรรมราช 80330",
      label: "บางจาก เมืองนครศรีธรรมราช นครศรีธรรมราช 80330",
    },
    {
      value: "ปากนคร เมืองนครศรีธรรมราช นครศรีธรรมราช 80000",
      label: "ปากนคร เมืองนครศรีธรรมราช นครศรีธรรมราช 80000",
    },
    {
      value: "ปากพูน เมืองนครศรีธรรมราช นครศรีธรรมราช 80000",
      label: "ปากพูน เมืองนครศรีธรรมราช นครศรีธรรมราช 80000",
    },
    {
      value: "มะม่วงสองต้น เมืองนครศรีธรรมราช นครศรีธรรมราช 80000",
      label: "มะม่วงสองต้น เมืองนครศรีธรรมราช นครศรีธรรมราช 80000",
    },
    {
      value: "โพธิ์เสด็จ เมืองนครศรีธรรมราช นครศรีธรรมราช 80000",
      label: "โพธิ์เสด็จ เมืองนครศรีธรรมราช นครศรีธรรมราช 80000",
    },
    {
      value: "ในเมือง เมืองนครศรีธรรมราช นครศรีธรรมราช 80000",
      label: "ในเมือง เมืองนครศรีธรรมราช นครศรีธรรมราช 80000",
    },
    {
      value: "ไชยมนตรี เมืองนครศรีธรรมราช นครศรีธรรมราช 80000",
      label: "ไชยมนตรี เมืองนครศรีธรรมราช นครศรีธรรมราช 80000",
    },
    { value: "จะแนะ จะแนะ นราธิวาส 96220", label: "จะแนะ จะแนะ นราธิวาส 96220" },
    {
      value: "ช้างเผือก จะแนะ นราธิวาส 96220",
      label: "ช้างเผือก จะแนะ นราธิวาส 96220",
    },
    {
      value: "ดุซงญอ จะแนะ นราธิวาส 96220",
      label: "ดุซงญอ จะแนะ นราธิวาส 96220",
    },
    {
      value: "ผดุงมาตร จะแนะ นราธิวาส 96220",
      label: "ผดุงมาตร จะแนะ นราธิวาส 96220",
    },
    { value: "นานาค ตากใบ นราธิวาส 96110", label: "นานาค ตากใบ นราธิวาส 96110" },
    {
      value: "บางขุนทอง ตากใบ นราธิวาส 96110",
      label: "บางขุนทอง ตากใบ นราธิวาส 96110",
    },
    { value: "พร่อน ตากใบ นราธิวาส 96110", label: "พร่อน ตากใบ นราธิวาส 96110" },
    {
      value: "ศาลาใหม่ ตากใบ นราธิวาส 96110",
      label: "ศาลาใหม่ ตากใบ นราธิวาส 96110",
    },
    {
      value: "เกาะสะท้อน ตากใบ นราธิวาส 96110",
      label: "เกาะสะท้อน ตากใบ นราธิวาส 96110",
    },
    {
      value: "เจ๊ะเห ตากใบ นราธิวาส 96110",
      label: "เจ๊ะเห ตากใบ นราธิวาส 96110",
    },
    { value: "โฆษิต ตากใบ นราธิวาส 96110", label: "โฆษิต ตากใบ นราธิวาส 96110" },
    {
      value: "ไพรวัน ตากใบ นราธิวาส 96110",
      label: "ไพรวัน ตากใบ นราธิวาส 96110",
    },
    {
      value: "กาเยาะมาตี บาเจาะ นราธิวาส 96170",
      label: "กาเยาะมาตี บาเจาะ นราธิวาส 96170",
    },
    {
      value: "บาเจาะ บาเจาะ นราธิวาส 96170",
      label: "บาเจาะ บาเจาะ นราธิวาส 96170",
    },
    {
      value: "บาเระเหนือ บาเจาะ นราธิวาส 96170",
      label: "บาเระเหนือ บาเจาะ นราธิวาส 96170",
    },
    {
      value: "บาเระใต้ บาเจาะ นราธิวาส 96170",
      label: "บาเระใต้ บาเจาะ นราธิวาส 96170",
    },
    {
      value: "ปะลุกาสาเมาะ บาเจาะ นราธิวาส 96170",
      label: "ปะลุกาสาเมาะ บาเจาะ นราธิวาส 96170",
    },
    {
      value: "ลุโบะสาวอ บาเจาะ นราธิวาส 96170",
      label: "ลุโบะสาวอ บาเจาะ นราธิวาส 96170",
    },
    {
      value: "จอเบาะ ยี่งอ นราธิวาส 96180",
      label: "จอเบาะ ยี่งอ นราธิวาส 96180",
    },
    {
      value: "ตะปอเยาะ ยี่งอ นราธิวาส 96180",
      label: "ตะปอเยาะ ยี่งอ นราธิวาส 96180",
    },
    { value: "ยี่งอ ยี่งอ นราธิวาส 96180", label: "ยี่งอ ยี่งอ นราธิวาส 96180" },
    { value: "ละหาร ยี่งอ นราธิวาส 96180", label: "ละหาร ยี่งอ นราธิวาส 96180" },
    {
      value: "ลุโบะบายะ ยี่งอ นราธิวาส 96180",
      label: "ลุโบะบายะ ยี่งอ นราธิวาส 96180",
    },
    {
      value: "ลุโบะบือซา ยี่งอ นราธิวาส 96180",
      label: "ลุโบะบือซา ยี่งอ นราธิวาส 96180",
    },
    {
      value: "กาลิซา ระแงะ นราธิวาส 96130",
      label: "กาลิซา ระแงะ นราธิวาส 96130",
    },
    {
      value: "ตันหยงมัส ระแงะ นราธิวาส 96130",
      label: "ตันหยงมัส ระแงะ นราธิวาส 96130",
    },
    {
      value: "ตันหยงลิมอ ระแงะ นราธิวาส 96130",
      label: "ตันหยงลิมอ ระแงะ นราธิวาส 96130",
    },
    { value: "บองอ ระแงะ นราธิวาส 96220", label: "บองอ ระแงะ นราธิวาส 96220" },
    {
      value: "บาโงสะโต ระแงะ นราธิวาส 96130",
      label: "บาโงสะโต ระแงะ นราธิวาส 96130",
    },
    {
      value: "มะรือโบตก ระแงะ นราธิวาส 96130",
      label: "มะรือโบตก ระแงะ นราธิวาส 96130",
    },
    { value: "เฉลิม ระแงะ นราธิวาส 96130", label: "เฉลิม ระแงะ นราธิวาส 96130" },
    {
      value: "บาตง รือเสาะ นราธิวาส 96150",
      label: "บาตง รือเสาะ นราธิวาส 96150",
    },
    {
      value: "รือเสาะ รือเสาะ นราธิวาส 96150",
      label: "รือเสาะ รือเสาะ นราธิวาส 96150",
    },
    {
      value: "รือเสาะออก รือเสาะ นราธิวาส 96150",
      label: "รือเสาะออก รือเสาะ นราธิวาส 96150",
    },
    {
      value: "ลาโละ รือเสาะ นราธิวาส 96150",
      label: "ลาโละ รือเสาะ นราธิวาส 96150",
    },
    {
      value: "สามัคคี รือเสาะ นราธิวาส 96150",
      label: "สามัคคี รือเสาะ นราธิวาส 96150",
    },
    {
      value: "สาวอ รือเสาะ นราธิวาส 96150",
      label: "สาวอ รือเสาะ นราธิวาส 96150",
    },
    {
      value: "สุวารี รือเสาะ นราธิวาส 96150",
      label: "สุวารี รือเสาะ นราธิวาส 96150",
    },
    {
      value: "เรียง รือเสาะ นราธิวาส 96150",
      label: "เรียง รือเสาะ นราธิวาส 96150",
    },
    {
      value: "โคกสะตอ รือเสาะ นราธิวาส 96150",
      label: "โคกสะตอ รือเสาะ นราธิวาส 96150",
    },
    {
      value: "กาหลง ศรีสาคร นราธิวาส 96210",
      label: "กาหลง ศรีสาคร นราธิวาส 96210",
    },
    {
      value: "ซากอ ศรีสาคร นราธิวาส 96210",
      label: "ซากอ ศรีสาคร นราธิวาส 96210",
    },
    {
      value: "ตะมะยูง ศรีสาคร นราธิวาส 96210",
      label: "ตะมะยูง ศรีสาคร นราธิวาส 96210",
    },
    {
      value: "ศรีบรรพต ศรีสาคร นราธิวาส 96210",
      label: "ศรีบรรพต ศรีสาคร นราธิวาส 96210",
    },
    {
      value: "ศรีสาคร ศรีสาคร นราธิวาส 96210",
      label: "ศรีสาคร ศรีสาคร นราธิวาส 96210",
    },
    {
      value: "เชิงคีรี ศรีสาคร นราธิวาส 96210",
      label: "เชิงคีรี ศรีสาคร นราธิวาส 96210",
    },
    {
      value: "ภูเขาทอง สุคิริน นราธิวาส 96190",
      label: "ภูเขาทอง สุคิริน นราธิวาส 96190",
    },
    {
      value: "มาโมง สุคิริน นราธิวาส 96190",
      label: "มาโมง สุคิริน นราธิวาส 96190",
    },
    {
      value: "ร่มไทร สุคิริน นราธิวาส 96190",
      label: "ร่มไทร สุคิริน นราธิวาส 96190",
    },
    {
      value: "สุคิริน สุคิริน นราธิวาส 96190",
      label: "สุคิริน สุคิริน นราธิวาส 96190",
    },
    {
      value: "เกียร์ สุคิริน นราธิวาส 96190",
      label: "เกียร์ สุคิริน นราธิวาส 96190",
    },
    {
      value: "กาวะ สุไหงปาดี นราธิวาส 96140",
      label: "กาวะ สุไหงปาดี นราธิวาส 96140",
    },
    {
      value: "ปะลุรู สุไหงปาดี นราธิวาส 96140",
      label: "ปะลุรู สุไหงปาดี นราธิวาส 96140",
    },
    {
      value: "ริโก๋ สุไหงปาดี นราธิวาส 96140",
      label: "ริโก๋ สุไหงปาดี นราธิวาส 96140",
    },
    {
      value: "สากอ สุไหงปาดี นราธิวาส 96140",
      label: "สากอ สุไหงปาดี นราธิวาส 96140",
    },
    {
      value: "สุไหงปาดี สุไหงปาดี นราธิวาส 96140",
      label: "สุไหงปาดี สุไหงปาดี นราธิวาส 96140",
    },
    {
      value: "โต๊ะเด็ง สุไหงปาดี นราธิวาส 96140",
      label: "โต๊ะเด็ง สุไหงปาดี นราธิวาส 96140",
    },
    {
      value: "ปาเสมัส สุไหงโก-ลก นราธิวาส 96120",
      label: "ปาเสมัส สุไหงโก-ลก นราธิวาส 96120",
    },
    {
      value: "ปูโยะ สุไหงโก-ลก นราธิวาส 96120",
      label: "ปูโยะ สุไหงโก-ลก นราธิวาส 96120",
    },
    {
      value: "มูโนะ สุไหงโก-ลก นราธิวาส 96120",
      label: "มูโนะ สุไหงโก-ลก นราธิวาส 96120",
    },
    {
      value: "สุไหงโก-ลก สุไหงโก-ลก นราธิวาส 96120",
      label: "สุไหงโก-ลก สุไหงโก-ลก นราธิวาส 96120",
    },
    {
      value: "จวบ เจาะไอร้อง นราธิวาส 96130",
      label: "จวบ เจาะไอร้อง นราธิวาส 96130",
    },
    {
      value: "บูกิต เจาะไอร้อง นราธิวาส 96130",
      label: "บูกิต เจาะไอร้อง นราธิวาส 96130",
    },
    {
      value: "มะรือโบออก เจาะไอร้อง นราธิวาส 96130",
      label: "มะรือโบออก เจาะไอร้อง นราธิวาส 96130",
    },
    {
      value: "กะลุวอ เมืองนราธิวาส นราธิวาส 96000",
      label: "กะลุวอ เมืองนราธิวาส นราธิวาส 96000",
    },
    {
      value: "กะลุวอเหนือ เมืองนราธิวาส นราธิวาส 96000",
      label: "กะลุวอเหนือ เมืองนราธิวาส นราธิวาส 96000",
    },
    {
      value: "บางนาค เมืองนราธิวาส นราธิวาส 96000",
      label: "บางนาค เมืองนราธิวาส นราธิวาส 96000",
    },
    {
      value: "บางปอ เมืองนราธิวาส นราธิวาส 96000",
      label: "บางปอ เมืองนราธิวาส นราธิวาส 96000",
    },
    {
      value: "มะนังตายอ เมืองนราธิวาส นราธิวาส 96000",
      label: "มะนังตายอ เมืองนราธิวาส นราธิวาส 96000",
    },
    {
      value: "ลำภู เมืองนราธิวาส นราธิวาส 96000",
      label: "ลำภู เมืองนราธิวาส นราธิวาส 96000",
    },
    {
      value: "โคกเคียน เมืองนราธิวาส นราธิวาส 96000",
      label: "โคกเคียน เมืองนราธิวาส นราธิวาส 96000",
    },
    {
      value: "กายูคละ แว้ง นราธิวาส 96160",
      label: "กายูคละ แว้ง นราธิวาส 96160",
    },
    { value: "ฆอเลาะ แว้ง นราธิวาส 96160", label: "ฆอเลาะ แว้ง นราธิวาส 96160" },
    {
      value: "เอราวัณ แว้ง นราธิวาส 96160",
      label: "เอราวัณ แว้ง นราธิวาส 96160",
    },
    { value: "แม่ดง แว้ง นราธิวาส 96160", label: "แม่ดง แว้ง นราธิวาส 96160" },
    { value: "แว้ง แว้ง นราธิวาส 96160", label: "แว้ง แว้ง นราธิวาส 96160" },
    { value: "โละจูด แว้ง นราธิวาส 96160", label: "โละจูด แว้ง นราธิวาส 96160" },
    { value: "กะรุบี กะพ้อ ปัตตานี 94230", label: "กะรุบี กะพ้อ ปัตตานี 94230" },
    {
      value: "ตะโละดือรามัน กะพ้อ ปัตตานี 94230",
      label: "ตะโละดือรามัน กะพ้อ ปัตตานี 94230",
    },
    {
      value: "ปล่องหอย กะพ้อ ปัตตานี 94230",
      label: "ปล่องหอย กะพ้อ ปัตตานี 94230",
    },
    {
      value: "ตะโละแมะนา ทุ่งยางแดง ปัตตานี 94140",
      label: "ตะโละแมะนา ทุ่งยางแดง ปัตตานี 94140",
    },
    {
      value: "น้ำดำ ทุ่งยางแดง ปัตตานี 94140",
      label: "น้ำดำ ทุ่งยางแดง ปัตตานี 94140",
    },
    {
      value: "ปากู ทุ่งยางแดง ปัตตานี 94140",
      label: "ปากู ทุ่งยางแดง ปัตตานี 94140",
    },
    {
      value: "พิเทน ทุ่งยางแดง ปัตตานี 94140",
      label: "พิเทน ทุ่งยางแดง ปัตตานี 94140",
    },
    { value: "ควน ปะนาเระ ปัตตานี 94190", label: "ควน ปะนาเระ ปัตตานี 94190" },
    {
      value: "คอกกระบือ ปะนาเระ ปัตตานี 94130",
      label: "คอกกระบือ ปะนาเระ ปัตตานี 94130",
    },
    { value: "ดอน ปะนาเระ ปัตตานี 94190", label: "ดอน ปะนาเระ ปัตตานี 94190" },
    {
      value: "ท่าข้าม ปะนาเระ ปัตตานี 94130",
      label: "ท่าข้าม ปะนาเระ ปัตตานี 94130",
    },
    {
      value: "ท่าน้ำ ปะนาเระ ปัตตานี 94130",
      label: "ท่าน้ำ ปะนาเระ ปัตตานี 94130",
    },
    {
      value: "บ้านกลาง ปะนาเระ ปัตตานี 94130",
      label: "บ้านกลาง ปะนาเระ ปัตตานี 94130",
    },
    {
      value: "บ้านนอก ปะนาเระ ปัตตานี 94130",
      label: "บ้านนอก ปะนาเระ ปัตตานี 94130",
    },
    {
      value: "บ้านน้ำบ่อ ปะนาเระ ปัตตานี 94130",
      label: "บ้านน้ำบ่อ ปะนาเระ ปัตตานี 94130",
    },
    {
      value: "ปะนาเระ ปะนาเระ ปัตตานี 94130",
      label: "ปะนาเระ ปะนาเระ ปัตตานี 94130",
    },
    {
      value: "พ่อมิ่ง ปะนาเระ ปัตตานี 94130",
      label: "พ่อมิ่ง ปะนาเระ ปัตตานี 94130",
    },
    { value: "กระหวะ มายอ ปัตตานี 94190", label: "กระหวะ มายอ ปัตตานี 94190" },
    { value: "กระเสาะ มายอ ปัตตานี 94140", label: "กระเสาะ มายอ ปัตตานี 94140" },
    { value: "ตรัง มายอ ปัตตานี 94140", label: "ตรัง มายอ ปัตตานี 94140" },
    { value: "ถนน มายอ ปัตตานี 94140", label: "ถนน มายอ ปัตตานี 94140" },
    { value: "ปะโด มายอ ปัตตานี 94140", label: "ปะโด มายอ ปัตตานี 94140" },
    { value: "ปานัน มายอ ปัตตานี 94140", label: "ปานัน มายอ ปัตตานี 94140" },
    { value: "มายอ มายอ ปัตตานี 94140", label: "มายอ มายอ ปัตตานี 94140" },
    { value: "ลางา มายอ ปัตตานี 94190", label: "ลางา มายอ ปัตตานี 94190" },
    {
      value: "ลุโบะยิไร มายอ ปัตตานี 94140",
      label: "ลุโบะยิไร มายอ ปัตตานี 94140",
    },
    { value: "สะกำ มายอ ปัตตานี 94140", label: "สะกำ มายอ ปัตตานี 94140" },
    { value: "สาคอบน มายอ ปัตตานี 94140", label: "สาคอบน มายอ ปัตตานี 94140" },
    { value: "สาคอใต้ มายอ ปัตตานี 94140", label: "สาคอใต้ มายอ ปัตตานี 94140" },
    { value: "เกาะจัน มายอ ปัตตานี 94140", label: "เกาะจัน มายอ ปัตตานี 94140" },
    { value: "กระโด ยะรัง ปัตตานี 94160", label: "กระโด ยะรัง ปัตตานี 94160" },
    { value: "กอลำ ยะรัง ปัตตานี 94160", label: "กอลำ ยะรัง ปัตตานี 94160" },
    {
      value: "คลองใหม่ ยะรัง ปัตตานี 94160",
      label: "คลองใหม่ ยะรัง ปัตตานี 94160",
    },
    { value: "ประจัน ยะรัง ปัตตานี 94160", label: "ประจัน ยะรัง ปัตตานี 94160" },
    {
      value: "ปิตูมุดี ยะรัง ปัตตานี 94160",
      label: "ปิตูมุดี ยะรัง ปัตตานี 94160",
    },
    { value: "ยะรัง ยะรัง ปัตตานี 94160", label: "ยะรัง ยะรัง ปัตตานี 94160" },
    { value: "ระแว้ง ยะรัง ปัตตานี 94160", label: "ระแว้ง ยะรัง ปัตตานี 94160" },
    { value: "วัด ยะรัง ปัตตานี 94160", label: "วัด ยะรัง ปัตตานี 94160" },
    { value: "สะดาวา ยะรัง ปัตตานี 94160", label: "สะดาวา ยะรัง ปัตตานี 94160" },
    { value: "สะนอ ยะรัง ปัตตานี 94160", label: "สะนอ ยะรัง ปัตตานี 94160" },
    { value: "เขาตูม ยะรัง ปัตตานี 94160", label: "เขาตูม ยะรัง ปัตตานี 94160" },
    {
      value: "เมาะมาวี ยะรัง ปัตตานี 94160",
      label: "เมาะมาวี ยะรัง ปัตตานี 94160",
    },
    {
      value: "จะรัง ยะหริ่ง ปัตตานี 94150",
      label: "จะรัง ยะหริ่ง ปัตตานี 94150",
    },
    {
      value: "ตอหลัง ยะหริ่ง ปัตตานี 94150",
      label: "ตอหลัง ยะหริ่ง ปัตตานี 94150",
    },
    {
      value: "ตะโละ ยะหริ่ง ปัตตานี 94150",
      label: "ตะโละ ยะหริ่ง ปัตตานี 94150",
    },
    {
      value: "ตะโละกาโปร์ ยะหริ่ง ปัตตานี 94150",
      label: "ตะโละกาโปร์ ยะหริ่ง ปัตตานี 94150",
    },
    {
      value: "ตันหยงจึงงา ยะหริ่ง ปัตตานี 94190",
      label: "ตันหยงจึงงา ยะหริ่ง ปัตตานี 94190",
    },
    {
      value: "ตันหยงดาลอ ยะหริ่ง ปัตตานี 94150",
      label: "ตันหยงดาลอ ยะหริ่ง ปัตตานี 94150",
    },
    {
      value: "ตาลีอายร์ ยะหริ่ง ปัตตานี 94150",
      label: "ตาลีอายร์ ยะหริ่ง ปัตตานี 94150",
    },
    {
      value: "ตาแกะ ยะหริ่ง ปัตตานี 94150",
      label: "ตาแกะ ยะหริ่ง ปัตตานี 94150",
    },
    {
      value: "บางปู ยะหริ่ง ปัตตานี 94150",
      label: "บางปู ยะหริ่ง ปัตตานี 94150",
    },
    {
      value: "บาโลย ยะหริ่ง ปัตตานี 94190",
      label: "บาโลย ยะหริ่ง ปัตตานี 94190",
    },
    {
      value: "ปิยามุมัง ยะหริ่ง ปัตตานี 94150",
      label: "ปิยามุมัง ยะหริ่ง ปัตตานี 94150",
    },
    {
      value: "ปุลากง ยะหริ่ง ปัตตานี 94150",
      label: "ปุลากง ยะหริ่ง ปัตตานี 94150",
    },
    {
      value: "มะนังยง ยะหริ่ง ปัตตานี 94150",
      label: "มะนังยง ยะหริ่ง ปัตตานี 94150",
    },
    { value: "ยามู ยะหริ่ง ปัตตานี 94150", label: "ยามู ยะหริ่ง ปัตตานี 94150" },
    {
      value: "ราตาปันยัง ยะหริ่ง ปัตตานี 94150",
      label: "ราตาปันยัง ยะหริ่ง ปัตตานี 94150",
    },
    {
      value: "สาบัน ยะหริ่ง ปัตตานี 94150",
      label: "สาบัน ยะหริ่ง ปัตตานี 94150",
    },
    {
      value: "หนองแรต ยะหริ่ง ปัตตานี 94150",
      label: "หนองแรต ยะหริ่ง ปัตตานี 94150",
    },
    {
      value: "แหลมโพธิ์ ยะหริ่ง ปัตตานี 94150",
      label: "แหลมโพธิ์ ยะหริ่ง ปัตตานี 94150",
    },
    {
      value: "กะดุนง สายบุรี ปัตตานี 94110",
      label: "กะดุนง สายบุรี ปัตตานี 94110",
    },
    {
      value: "ตะบิ้ง สายบุรี ปัตตานี 94110",
      label: "ตะบิ้ง สายบุรี ปัตตานี 94110",
    },
    {
      value: "ตะลุบัน สายบุรี ปัตตานี 94110",
      label: "ตะลุบัน สายบุรี ปัตตานี 94110",
    },
    {
      value: "ทุ่งคล้า สายบุรี ปัตตานี 94190",
      label: "ทุ่งคล้า สายบุรี ปัตตานี 94190",
    },
    {
      value: "บางเก่า สายบุรี ปัตตานี 94110",
      label: "บางเก่า สายบุรี ปัตตานี 94110",
    },
    {
      value: "บือเระ สายบุรี ปัตตานี 94110",
      label: "บือเระ สายบุรี ปัตตานี 94110",
    },
    {
      value: "ปะเสยะวอ สายบุรี ปัตตานี 94110",
      label: "ปะเสยะวอ สายบุรี ปัตตานี 94110",
    },
    {
      value: "มะนังดาลำ สายบุรี ปัตตานี 94110",
      label: "มะนังดาลำ สายบุรี ปัตตานี 94110",
    },
    {
      value: "ละหาร สายบุรี ปัตตานี 94110",
      label: "ละหาร สายบุรี ปัตตานี 94110",
    },
    {
      value: "เตราะบอน สายบุรี ปัตตานี 94110",
      label: "เตราะบอน สายบุรี ปัตตานี 94110",
    },
    { value: "แป้น สายบุรี ปัตตานี 94110", label: "แป้น สายบุรี ปัตตานี 94110" },
    {
      value: "คอลอตันหยง หนองจิก ปัตตานี 94170",
      label: "คอลอตันหยง หนองจิก ปัตตานี 94170",
    },
    {
      value: "ดอนรัก หนองจิก ปัตตานี 94170",
      label: "ดอนรัก หนองจิก ปัตตานี 94170",
    },
    {
      value: "ดาโต๊ะ หนองจิก ปัตตานี 94170",
      label: "ดาโต๊ะ หนองจิก ปัตตานี 94170",
    },
    { value: "ตุยง หนองจิก ปัตตานี 94170", label: "ตุยง หนองจิก ปัตตานี 94170" },
    {
      value: "ท่ากำชำ หนองจิก ปัตตานี 94170",
      label: "ท่ากำชำ หนองจิก ปัตตานี 94170",
    },
    {
      value: "บางตาวา หนองจิก ปัตตานี 94170",
      label: "บางตาวา หนองจิก ปัตตานี 94170",
    },
    {
      value: "บางเขา หนองจิก ปัตตานี 94170",
      label: "บางเขา หนองจิก ปัตตานี 94170",
    },
    {
      value: "บ่อทอง หนองจิก ปัตตานี 94170",
      label: "บ่อทอง หนองจิก ปัตตานี 94170",
    },
    {
      value: "ปุโละปุโย หนองจิก ปัตตานี 94170",
      label: "ปุโละปุโย หนองจิก ปัตตานี 94170",
    },
    { value: "ยาบี หนองจิก ปัตตานี 94170", label: "ยาบี หนองจิก ปัตตานี 94170" },
    {
      value: "ลิปะสะโง หนองจิก ปัตตานี 94170",
      label: "ลิปะสะโง หนองจิก ปัตตานี 94170",
    },
    {
      value: "เกาะเปาะ หนองจิก ปัตตานี 94170",
      label: "เกาะเปาะ หนองจิก ปัตตานี 94170",
    },
    {
      value: "กะมิยอ เมืองปัตตานี ปัตตานี 94000",
      label: "กะมิยอ เมืองปัตตานี ปัตตานี 94000",
    },
    {
      value: "คลองมานิง เมืองปัตตานี ปัตตานี 94000",
      label: "คลองมานิง เมืองปัตตานี ปัตตานี 94000",
    },
    {
      value: "จะบังติกอ เมืองปัตตานี ปัตตานี 94000",
      label: "จะบังติกอ เมืองปัตตานี ปัตตานี 94000",
    },
    {
      value: "ตะลุโบะ เมืองปัตตานี ปัตตานี 94000",
      label: "ตะลุโบะ เมืองปัตตานี ปัตตานี 94000",
    },
    {
      value: "ตันหยงลุโละ เมืองปัตตานี ปัตตานี 94000",
      label: "ตันหยงลุโละ เมืองปัตตานี ปัตตานี 94000",
    },
    {
      value: "บานา เมืองปัตตานี ปัตตานี 94000",
      label: "บานา เมืองปัตตานี ปัตตานี 94000",
    },
    {
      value: "บาราเฮาะ เมืองปัตตานี ปัตตานี 94000",
      label: "บาราเฮาะ เมืองปัตตานี ปัตตานี 94000",
    },
    {
      value: "บาราโหม เมืองปัตตานี ปัตตานี 94000",
      label: "บาราโหม เมืองปัตตานี ปัตตานี 94000",
    },
    {
      value: "ปะกาฮะรัง เมืองปัตตานี ปัตตานี 94000",
      label: "ปะกาฮะรัง เมืองปัตตานี ปัตตานี 94000",
    },
    {
      value: "ปุยุด เมืองปัตตานี ปัตตานี 94000",
      label: "ปุยุด เมืองปัตตานี ปัตตานี 94000",
    },
    {
      value: "รูสะมิแล เมืองปัตตานี ปัตตานี 94000",
      label: "รูสะมิแล เมืองปัตตานี ปัตตานี 94000",
    },
    {
      value: "สะบารัง เมืองปัตตานี ปัตตานี 94000",
      label: "สะบารัง เมืองปัตตานี ปัตตานี 94000",
    },
    {
      value: "อาเนาะรู เมืองปัตตานี ปัตตานี 94000",
      label: "อาเนาะรู เมืองปัตตานี ปัตตานี 94000",
    },
    {
      value: "ป่าไร่ แม่ลาน ปัตตานี 94180",
      label: "ป่าไร่ แม่ลาน ปัตตานี 94180",
    },
    {
      value: "ม่วงเตี้ย แม่ลาน ปัตตานี 94180",
      label: "ม่วงเตี้ย แม่ลาน ปัตตานี 94180",
    },
    {
      value: "แม่ลาน แม่ลาน ปัตตานี 94180",
      label: "แม่ลาน แม่ลาน ปัตตานี 94180",
    },
    {
      value: "ควนโนรี โคกโพธิ์ ปัตตานี 94180",
      label: "ควนโนรี โคกโพธิ์ ปัตตานี 94180",
    },
    {
      value: "ช้างให้ตก โคกโพธิ์ ปัตตานี 94120",
      label: "ช้างให้ตก โคกโพธิ์ ปัตตานี 94120",
    },
    {
      value: "ทรายขาว โคกโพธิ์ ปัตตานี 94120",
      label: "ทรายขาว โคกโพธิ์ ปัตตานี 94120",
    },
    {
      value: "ทุ่งพลา โคกโพธิ์ ปัตตานี 94180",
      label: "ทุ่งพลา โคกโพธิ์ ปัตตานี 94180",
    },
    {
      value: "ท่าเรือ โคกโพธิ์ ปัตตานี 94120",
      label: "ท่าเรือ โคกโพธิ์ ปัตตานี 94120",
    },
    {
      value: "นาประดู่ โคกโพธิ์ ปัตตานี 94180",
      label: "นาประดู่ โคกโพธิ์ ปัตตานี 94180",
    },
    {
      value: "นาเกตุ โคกโพธิ์ ปัตตานี 94120",
      label: "นาเกตุ โคกโพธิ์ ปัตตานี 94120",
    },
    {
      value: "บางโกระ โคกโพธิ์ ปัตตานี 94120",
      label: "บางโกระ โคกโพธิ์ ปัตตานี 94120",
    },
    {
      value: "ปากล่อ โคกโพธิ์ ปัตตานี 94180",
      label: "ปากล่อ โคกโพธิ์ ปัตตานี 94180",
    },
    {
      value: "ป่าบอน โคกโพธิ์ ปัตตานี 94120",
      label: "ป่าบอน โคกโพธิ์ ปัตตานี 94120",
    },
    {
      value: "มะกรูด โคกโพธิ์ ปัตตานี 94120",
      label: "มะกรูด โคกโพธิ์ ปัตตานี 94120",
    },
    {
      value: "โคกโพธิ์ โคกโพธิ์ ปัตตานี 94120",
      label: "โคกโพธิ์ โคกโพธิ์ ปัตตานี 94120",
    },
    {
      value: "ดอนทราย ไม้แก่น ปัตตานี 94220",
      label: "ดอนทราย ไม้แก่น ปัตตานี 94220",
    },
    {
      value: "ตะโละไกรทอง ไม้แก่น ปัตตานี 94220",
      label: "ตะโละไกรทอง ไม้แก่น ปัตตานี 94220",
    },
    {
      value: "ไทรทอง ไม้แก่น ปัตตานี 94220",
      label: "ไทรทอง ไม้แก่น ปัตตานี 94220",
    },
    {
      value: "ไม้แก่น ไม้แก่น ปัตตานี 94220",
      label: "ไม้แก่น ไม้แก่น ปัตตานี 94220",
    },
    { value: "กะปง กะปง พังงา 82170", label: "กะปง กะปง พังงา 82170" },
    { value: "ท่านา กะปง พังงา 82170", label: "ท่านา กะปง พังงา 82170" },
    { value: "รมณีย์ กะปง พังงา 82170", label: "รมณีย์ กะปง พังงา 82170" },
    { value: "เหมาะ กะปง พังงา 82170", label: "เหมาะ กะปง พังงา 82170" },
    { value: "เหล กะปง พังงา 82170", label: "เหล กะปง พังงา 82170" },
    { value: "คุระ คุระบุรี พังงา 82150", label: "คุระ คุระบุรี พังงา 82150" },
    {
      value: "บางวัน คุระบุรี พังงา 82150",
      label: "บางวัน คุระบุรี พังงา 82150",
    },
    {
      value: "เกาะพระทอง คุระบุรี พังงา 82150",
      label: "เกาะพระทอง คุระบุรี พังงา 82150",
    },
    {
      value: "แม่นางขาว คุระบุรี พังงา 82150",
      label: "แม่นางขาว คุระบุรี พังงา 82150",
    },
    {
      value: "กระโสม ตะกั่วทุ่ง พังงา 82130",
      label: "กระโสม ตะกั่วทุ่ง พังงา 82130",
    },
    {
      value: "กะไหล ตะกั่วทุ่ง พังงา 82130",
      label: "กะไหล ตะกั่วทุ่ง พังงา 82130",
    },
    {
      value: "คลองเคียน ตะกั่วทุ่ง พังงา 82130",
      label: "คลองเคียน ตะกั่วทุ่ง พังงา 82130",
    },
    { value: "ถ้ำ ตะกั่วทุ่ง พังงา 82130", label: "ถ้ำ ตะกั่วทุ่ง พังงา 82130" },
    {
      value: "ท่าอยู่ ตะกั่วทุ่ง พังงา 82130",
      label: "ท่าอยู่ ตะกั่วทุ่ง พังงา 82130",
    },
    {
      value: "หล่อยูง ตะกั่วทุ่ง พังงา 82140",
      label: "หล่อยูง ตะกั่วทุ่ง พังงา 82140",
    },
    {
      value: "โคกกลอย ตะกั่วทุ่ง พังงา 82140",
      label: "โคกกลอย ตะกั่วทุ่ง พังงา 82140",
    },
    {
      value: "คึกคัก ตะกั่วป่า พังงา 82220",
      label: "คึกคัก ตะกั่วป่า พังงา 82220",
    },
    {
      value: "ตะกั่วป่า ตะกั่วป่า พังงา 82110",
      label: "ตะกั่วป่า ตะกั่วป่า พังงา 82110",
    },
    {
      value: "ตำตัว ตะกั่วป่า พังงา 82110",
      label: "ตำตัว ตะกั่วป่า พังงา 82110",
    },
    {
      value: "บางนายสี ตะกั่วป่า พังงา 82110",
      label: "บางนายสี ตะกั่วป่า พังงา 82110",
    },
    {
      value: "บางม่วง ตะกั่วป่า พังงา 82190",
      label: "บางม่วง ตะกั่วป่า พังงา 82190",
    },
    {
      value: "บางไทร ตะกั่วป่า พังงา 82110",
      label: "บางไทร ตะกั่วป่า พังงา 82110",
    },
    {
      value: "เกาะคอเขา ตะกั่วป่า พังงา 82190",
      label: "เกาะคอเขา ตะกั่วป่า พังงา 82190",
    },
    {
      value: "โคกเคียน ตะกั่วป่า พังงา 82110",
      label: "โคกเคียน ตะกั่วป่า พังงา 82110",
    },
    {
      value: "ถ้ำทองหลาง ทับปุด พังงา 82180",
      label: "ถ้ำทองหลาง ทับปุด พังงา 82180",
    },
    { value: "ทับปุด ทับปุด พังงา 82180", label: "ทับปุด ทับปุด พังงา 82180" },
    {
      value: "บางเหรียง ทับปุด พังงา 82180",
      label: "บางเหรียง ทับปุด พังงา 82180",
    },
    { value: "บ่อแสน ทับปุด พังงา 82180", label: "บ่อแสน ทับปุด พังงา 82180" },
    { value: "มะรุ่ย ทับปุด พังงา 82180", label: "มะรุ่ย ทับปุด พังงา 82180" },
    {
      value: "โคกเจริญ ทับปุด พังงา 82180",
      label: "โคกเจริญ ทับปุด พังงา 82180",
    },
    {
      value: "ทุ่งมะพร้าว ท้ายเหมือง พังงา 82120",
      label: "ทุ่งมะพร้าว ท้ายเหมือง พังงา 82120",
    },
    {
      value: "ท้ายเหมือง ท้ายเหมือง พังงา 82120",
      label: "ท้ายเหมือง ท้ายเหมือง พังงา 82120",
    },
    {
      value: "นาเตย ท้ายเหมือง พังงา 82120",
      label: "นาเตย ท้ายเหมือง พังงา 82120",
    },
    {
      value: "บางทอง ท้ายเหมือง พังงา 82120",
      label: "บางทอง ท้ายเหมือง พังงา 82120",
    },
    {
      value: "ลำภี ท้ายเหมือง พังงา 82120",
      label: "ลำภี ท้ายเหมือง พังงา 82120",
    },
    {
      value: "ลำแก่น ท้ายเหมือง พังงา 82210",
      label: "ลำแก่น ท้ายเหมือง พังงา 82210",
    },
    { value: "พรุใน เกาะยาว พังงา 83000", label: "พรุใน เกาะยาว พังงา 83000" },
    {
      value: "เกาะยาวน้อย เกาะยาว พังงา 82160",
      label: "เกาะยาวน้อย เกาะยาว พังงา 82160",
    },
    {
      value: "เกาะยาวใหญ่ เกาะยาว พังงา 82160",
      label: "เกาะยาวใหญ่ เกาะยาว พังงา 82160",
    },
    {
      value: "ตากแดด เมืองพังงา พังงา 82000",
      label: "ตากแดด เมืองพังงา พังงา 82000",
    },
    {
      value: "ถ้ำน้ำผุด เมืองพังงา พังงา 82000",
      label: "ถ้ำน้ำผุด เมืองพังงา พังงา 82000",
    },
    {
      value: "ทุ่งคาโงก เมืองพังงา พังงา 82000",
      label: "ทุ่งคาโงก เมืองพังงา พังงา 82000",
    },
    {
      value: "ท้ายช้าง เมืองพังงา พังงา 82000",
      label: "ท้ายช้าง เมืองพังงา พังงา 82000",
    },
    {
      value: "นบปริง เมืองพังงา พังงา 82000",
      label: "นบปริง เมืองพังงา พังงา 82000",
    },
    {
      value: "บางเตย เมืองพังงา พังงา 82000",
      label: "บางเตย เมืองพังงา พังงา 82000",
    },
    {
      value: "ป่ากอ เมืองพังงา พังงา 82000",
      label: "ป่ากอ เมืองพังงา พังงา 82000",
    },
    {
      value: "สองแพรก เมืองพังงา พังงา 82000",
      label: "สองแพรก เมืองพังงา พังงา 82000",
    },
    {
      value: "เกาะปันหยี เมืองพังงา พังงา 82000",
      label: "เกาะปันหยี เมืองพังงา พังงา 82000",
    },
    { value: "กงหรา กงหรา พัทลุง 93180", label: "กงหรา กงหรา พัทลุง 93180" },
    {
      value: "คลองทรายขาว กงหรา พัทลุง 93180",
      label: "คลองทรายขาว กงหรา พัทลุง 93180",
    },
    {
      value: "คลองเฉลิม กงหรา พัทลุง 93180",
      label: "คลองเฉลิม กงหรา พัทลุง 93180",
    },
    { value: "ชะรัด กงหรา พัทลุง 93000", label: "ชะรัด กงหรา พัทลุง 93000" },
    { value: "สมหวัง กงหรา พัทลุง 93000", label: "สมหวัง กงหรา พัทลุง 93000" },
    {
      value: "ควนขนุน ควนขนุน พัทลุง 93110",
      label: "ควนขนุน ควนขนุน พัทลุง 93110",
    },
    { value: "ชะมวง ควนขนุน พัทลุง 93110", label: "ชะมวง ควนขนุน พัทลุง 93110" },
    {
      value: "ดอนทราย ควนขนุน พัทลุง 93110",
      label: "ดอนทราย ควนขนุน พัทลุง 93110",
    },
    {
      value: "ทะเลน้อย ควนขนุน พัทลุง 93150",
      label: "ทะเลน้อย ควนขนุน พัทลุง 93150",
    },
    {
      value: "นาขยาด ควนขนุน พัทลุง 93110",
      label: "นาขยาด ควนขนุน พัทลุง 93110",
    },
    { value: "ปันแต ควนขนุน พัทลุง 93110", label: "ปันแต ควนขนุน พัทลุง 93110" },
    {
      value: "พนมวังก์ ควนขนุน พัทลุง 93110",
      label: "พนมวังก์ ควนขนุน พัทลุง 93110",
    },
    {
      value: "พนางตุง ควนขนุน พัทลุง 93150",
      label: "พนางตุง ควนขนุน พัทลุง 93150",
    },
    {
      value: "มะกอกเหนือ ควนขนุน พัทลุง 93150",
      label: "มะกอกเหนือ ควนขนุน พัทลุง 93150",
    },
    {
      value: "แพรกหา ควนขนุน พัทลุง 93110",
      label: "แพรกหา ควนขนุน พัทลุง 93110",
    },
    {
      value: "แหลมโตนด ควนขนุน พัทลุง 93110",
      label: "แหลมโตนด ควนขนุน พัทลุง 93110",
    },
    {
      value: "โตนดด้วน ควนขนุน พัทลุง 93110",
      label: "โตนดด้วน ควนขนุน พัทลุง 93110",
    },
    {
      value: "คลองใหญ่ ตะโหมด พัทลุง 93160",
      label: "คลองใหญ่ ตะโหมด พัทลุง 93160",
    },
    { value: "ตะโหมด ตะโหมด พัทลุง 93160", label: "ตะโหมด ตะโหมด พัทลุง 93160" },
    { value: "แม่ขรี ตะโหมด พัทลุง 93160", label: "แม่ขรี ตะโหมด พัทลุง 93160" },
    {
      value: "ท่ามะเดื่อ บางแก้ว พัทลุง 93140",
      label: "ท่ามะเดื่อ บางแก้ว พัทลุง 93140",
    },
    {
      value: "นาปะขอ บางแก้ว พัทลุง 93140",
      label: "นาปะขอ บางแก้ว พัทลุง 93140",
    },
    {
      value: "โคกสัก บางแก้ว พัทลุง 93140",
      label: "โคกสัก บางแก้ว พัทลุง 93140",
    },
    {
      value: "ดอนทราย ปากพะยูน พัทลุง 93120",
      label: "ดอนทราย ปากพะยูน พัทลุง 93120",
    },
    {
      value: "ดอนประดู่ ปากพะยูน พัทลุง 93120",
      label: "ดอนประดู่ ปากพะยูน พัทลุง 93120",
    },
    {
      value: "ปากพะยูน ปากพะยูน พัทลุง 93120",
      label: "ปากพะยูน ปากพะยูน พัทลุง 93120",
    },
    {
      value: "ฝาละมี ปากพะยูน พัทลุง 93120",
      label: "ฝาละมี ปากพะยูน พัทลุง 93120",
    },
    {
      value: "หารเทา ปากพะยูน พัทลุง 93120",
      label: "หารเทา ปากพะยูน พัทลุง 93120",
    },
    {
      value: "เกาะนางคำ ปากพะยูน พัทลุง 93120",
      label: "เกาะนางคำ ปากพะยูน พัทลุง 93120",
    },
    {
      value: "เกาะหมาก ปากพะยูน พัทลุง 93120",
      label: "เกาะหมาก ปากพะยูน พัทลุง 93120",
    },
    {
      value: "ทุ่งนารี ป่าบอน พัทลุง 93170",
      label: "ทุ่งนารี ป่าบอน พัทลุง 93170",
    },
    { value: "ป่าบอน ป่าบอน พัทลุง 93170", label: "ป่าบอน ป่าบอน พัทลุง 93170" },
    {
      value: "วังใหม่ ป่าบอน พัทลุง 93170",
      label: "วังใหม่ ป่าบอน พัทลุง 93170",
    },
    { value: "หนองธง ป่าบอน พัทลุง 93170", label: "หนองธง ป่าบอน พัทลุง 93170" },
    {
      value: "โคกทราย ป่าบอน พัทลุง 93170",
      label: "โคกทราย ป่าบอน พัทลุง 93170",
    },
    {
      value: "บ้านพร้าว ป่าพะยอม พัทลุง 93210",
      label: "บ้านพร้าว ป่าพะยอม พัทลุง 93210",
    },
    {
      value: "ป่าพะยอม ป่าพะยอม พัทลุง 93210",
      label: "ป่าพะยอม ป่าพะยอม พัทลุง 93210",
    },
    {
      value: "ลานข่อย ป่าพะยอม พัทลุง 93210",
      label: "ลานข่อย ป่าพะยอม พัทลุง 93210",
    },
    {
      value: "เกาะเต่า ป่าพะยอม พัทลุง 93210",
      label: "เกาะเต่า ป่าพะยอม พัทลุง 93210",
    },
    {
      value: "ชุมพล ศรีนครินทร์ พัทลุง 93000",
      label: "ชุมพล ศรีนครินทร์ พัทลุง 93000",
    },
    {
      value: "บ้านนา ศรีนครินทร์ พัทลุง 93000",
      label: "บ้านนา ศรีนครินทร์ พัทลุง 93000",
    },
    {
      value: "ลำสินธุ์ ศรีนครินทร์ พัทลุง 93000",
      label: "ลำสินธุ์ ศรีนครินทร์ พัทลุง 93000",
    },
    {
      value: "อ่างทอง ศรีนครินทร์ พัทลุง 93000",
      label: "อ่างทอง ศรีนครินทร์ พัทลุง 93000",
    },
    {
      value: "ตะแพน ศรีบรรพต พัทลุง 93190",
      label: "ตะแพน ศรีบรรพต พัทลุง 93190",
    },
    {
      value: "เขาปู่ ศรีบรรพต พัทลุง 93190",
      label: "เขาปู่ ศรีบรรพต พัทลุง 93190",
    },
    {
      value: "เขาย่า ศรีบรรพต พัทลุง 93190",
      label: "เขาย่า ศรีบรรพต พัทลุง 93190",
    },
    {
      value: "ควนขนุน เขาชัยสน พัทลุง 93130",
      label: "ควนขนุน เขาชัยสน พัทลุง 93130",
    },
    {
      value: "จองถนน เขาชัยสน พัทลุง 93130",
      label: "จองถนน เขาชัยสน พัทลุง 93130",
    },
    {
      value: "หานโพธิ์ เขาชัยสน พัทลุง 93130",
      label: "หานโพธิ์ เขาชัยสน พัทลุง 93130",
    },
    {
      value: "เขาชัยสน เขาชัยสน พัทลุง 93130",
      label: "เขาชัยสน เขาชัยสน พัทลุง 93130",
    },
    {
      value: "โคกม่วง เขาชัยสน พัทลุง 93130",
      label: "โคกม่วง เขาชัยสน พัทลุง 93130",
    },
    {
      value: "ควนมะพร้าว เมืองพัทลุง พัทลุง 93000",
      label: "ควนมะพร้าว เมืองพัทลุง พัทลุง 93000",
    },
    {
      value: "คูหาสวรรค์ เมืองพัทลุง พัทลุง 93000",
      label: "คูหาสวรรค์ เมืองพัทลุง พัทลุง 93000",
    },
    {
      value: "ชัยบุรี เมืองพัทลุง พัทลุง 93000",
      label: "ชัยบุรี เมืองพัทลุง พัทลุง 93000",
    },
    {
      value: "ตำนาน เมืองพัทลุง พัทลุง 93000",
      label: "ตำนาน เมืองพัทลุง พัทลุง 93000",
    },
    {
      value: "ท่ามิหรำ เมืองพัทลุง พัทลุง 93000",
      label: "ท่ามิหรำ เมืองพัทลุง พัทลุง 93000",
    },
    {
      value: "ท่าแค เมืองพัทลุง พัทลุง 93000",
      label: "ท่าแค เมืองพัทลุง พัทลุง 93000",
    },
    {
      value: "นาท่อม เมืองพัทลุง พัทลุง 93000",
      label: "นาท่อม เมืองพัทลุง พัทลุง 93000",
    },
    {
      value: "นาโหนด เมืองพัทลุง พัทลุง 93000",
      label: "นาโหนด เมืองพัทลุง พัทลุง 93000",
    },
    {
      value: "ปรางหมู่ เมืองพัทลุง พัทลุง 93000",
      label: "ปรางหมู่ เมืองพัทลุง พัทลุง 93000",
    },
    {
      value: "พญาขัน เมืองพัทลุง พัทลุง 93000",
      label: "พญาขัน เมืองพัทลุง พัทลุง 93000",
    },
    {
      value: "ร่มเมือง เมืองพัทลุง พัทลุง 93000",
      label: "ร่มเมือง เมืองพัทลุง พัทลุง 93000",
    },
    {
      value: "ลำปำ เมืองพัทลุง พัทลุง 93000",
      label: "ลำปำ เมืองพัทลุง พัทลุง 93000",
    },
    {
      value: "เขาเจียก เมืองพัทลุง พัทลุง 93000",
      label: "เขาเจียก เมืองพัทลุง พัทลุง 93000",
    },
    {
      value: "โคกชะงาย เมืองพัทลุง พัทลุง 93000",
      label: "โคกชะงาย เมืองพัทลุง พัทลุง 93000",
    },
    { value: "กมลา กะทู้ ภูเก็ต 83120", label: "กมลา กะทู้ ภูเก็ต 83120" },
    { value: "กะทู้ กะทู้ ภูเก็ต 83120", label: "กะทู้ กะทู้ ภูเก็ต 83120" },
    { value: "ป่าตอง กะทู้ ภูเก็ต 83150", label: "ป่าตอง กะทู้ ภูเก็ต 83150" },
    { value: "ป่าคลอก ถลาง ภูเก็ต 83110", label: "ป่าคลอก ถลาง ภูเก็ต 83110" },
    { value: "ศรีสุนทร ถลาง ภูเก็ต 83110", label: "ศรีสุนทร ถลาง ภูเก็ต 83110" },
    { value: "สาคู ถลาง ภูเก็ต 83110", label: "สาคู ถลาง ภูเก็ต 83110" },
    { value: "เชิงทะเล ถลาง ภูเก็ต 83110", label: "เชิงทะเล ถลาง ภูเก็ต 83110" },
    {
      value: "เทพกระษัตรี ถลาง ภูเก็ต 83110",
      label: "เทพกระษัตรี ถลาง ภูเก็ต 83110",
    },
    { value: "ไม้ขาว ถลาง ภูเก็ต 83110", label: "ไม้ขาว ถลาง ภูเก็ต 83110" },
    {
      value: "กะรน เมืองภูเก็ต ภูเก็ต 83100",
      label: "กะรน เมืองภูเก็ต ภูเก็ต 83100",
    },
    {
      value: "ฉลอง เมืองภูเก็ต ภูเก็ต 83000",
      label: "ฉลอง เมืองภูเก็ต ภูเก็ต 83000",
    },
    {
      value: "ตลาดเหนือ เมืองภูเก็ต ภูเก็ต 83000",
      label: "ตลาดเหนือ เมืองภูเก็ต ภูเก็ต 83000",
    },
    {
      value: "ตลาดใหญ่ เมืองภูเก็ต ภูเก็ต 83000",
      label: "ตลาดใหญ่ เมืองภูเก็ต ภูเก็ต 83000",
    },
    {
      value: "รัษฎา เมืองภูเก็ต ภูเก็ต 83000",
      label: "รัษฎา เมืองภูเก็ต ภูเก็ต 83000",
    },
    {
      value: "ราไวย์ เมืองภูเก็ต ภูเก็ต 83100",
      label: "ราไวย์ เมืองภูเก็ต ภูเก็ต 83100",
    },
    {
      value: "วิชิต เมืองภูเก็ต ภูเก็ต 83000",
      label: "วิชิต เมืองภูเก็ต ภูเก็ต 83000",
    },
    {
      value: "เกาะแก้ว เมืองภูเก็ต ภูเก็ต 83000",
      label: "เกาะแก้ว เมืองภูเก็ต ภูเก็ต 83000",
    },
    { value: "จ.ป.ร. กระบุรี ระนอง 85110", label: "จ.ป.ร. กระบุรี ระนอง 85110" },
    { value: "น้ำจืด กระบุรี ระนอง 85110", label: "น้ำจืด กระบุรี ระนอง 85110" },
    {
      value: "น้ำจืดน้อย กระบุรี ระนอง 85110",
      label: "น้ำจืดน้อย กระบุรี ระนอง 85110",
    },
    {
      value: "บางใหญ่ กระบุรี ระนอง 85110",
      label: "บางใหญ่ กระบุรี ระนอง 85110",
    },
    {
      value: "ปากจั่น กระบุรี ระนอง 85110",
      label: "ปากจั่น กระบุรี ระนอง 85110",
    },
    { value: "มะมุ กระบุรี ระนอง 85110", label: "มะมุ กระบุรี ระนอง 85110" },
    {
      value: "ลำเลียง กระบุรี ระนอง 85110",
      label: "ลำเลียง กระบุรี ระนอง 85110",
    },
    {
      value: "กะเปอร์ กะเปอร์ ระนอง 85120",
      label: "กะเปอร์ กะเปอร์ ระนอง 85120",
    },
    { value: "บางหิน กะเปอร์ ระนอง 85120", label: "บางหิน กะเปอร์ ระนอง 85120" },
    { value: "บ้านนา กะเปอร์ ระนอง 85120", label: "บ้านนา กะเปอร์ ระนอง 85120" },
    {
      value: "ม่วงกลวง กะเปอร์ ระนอง 85120",
      label: "ม่วงกลวง กะเปอร์ ระนอง 85120",
    },
    {
      value: "เชี่ยวเหลียง กะเปอร์ ระนอง 85120",
      label: "เชี่ยวเหลียง กะเปอร์ ระนอง 85120",
    },
    {
      value: "บางพระเหนือ ละอุ่น ระนอง 85130",
      label: "บางพระเหนือ ละอุ่น ระนอง 85130",
    },
    {
      value: "บางพระใต้ ละอุ่น ระนอง 85130",
      label: "บางพระใต้ ละอุ่น ระนอง 85130",
    },
    { value: "บางแก้ว ละอุ่น ระนอง 85130", label: "บางแก้ว ละอุ่น ระนอง 85130" },
    {
      value: "ละอุ่นเหนือ ละอุ่น ระนอง 85130",
      label: "ละอุ่นเหนือ ละอุ่น ระนอง 85130",
    },
    {
      value: "ละอุ่นใต้ ละอุ่น ระนอง 85130",
      label: "ละอุ่นใต้ ละอุ่น ระนอง 85130",
    },
    {
      value: "ในวงเหนือ ละอุ่น ระนอง 85130",
      label: "ในวงเหนือ ละอุ่น ระนอง 85130",
    },
    { value: "ในวงใต้ ละอุ่น ระนอง 85130", label: "ในวงใต้ ละอุ่น ระนอง 85130" },
    { value: "กำพวน สุขสำราญ ระนอง 85120", label: "กำพวน สุขสำราญ ระนอง 85120" },
    { value: "นาคา สุขสำราญ ระนอง 85120", label: "นาคา สุขสำราญ ระนอง 85120" },
    {
      value: "ทรายแดง เมืองระนอง ระนอง 85130",
      label: "ทรายแดง เมืองระนอง ระนอง 85130",
    },
    {
      value: "บางนอน เมืองระนอง ระนอง 85000",
      label: "บางนอน เมืองระนอง ระนอง 85000",
    },
    {
      value: "บางริ้น เมืองระนอง ระนอง 85000",
      label: "บางริ้น เมืองระนอง ระนอง 85000",
    },
    {
      value: "ปากน้ำ เมืองระนอง ระนอง 85000",
      label: "ปากน้ำ เมืองระนอง ระนอง 85000",
    },
    {
      value: "ราชกรูด เมืองระนอง ระนอง 85000",
      label: "ราชกรูด เมืองระนอง ระนอง 85000",
    },
    {
      value: "หงาว เมืองระนอง ระนอง 85000",
      label: "หงาว เมืองระนอง ระนอง 85000",
    },
    {
      value: "หาดส้มแป้น เมืองระนอง ระนอง 85000",
      label: "หาดส้มแป้น เมืองระนอง ระนอง 85000",
    },
    {
      value: "เกาะพยาม เมืองระนอง ระนอง 85000",
      label: "เกาะพยาม เมืองระนอง ระนอง 85000",
    },
    {
      value: "เขานิเวศน์ เมืองระนอง ระนอง 85000",
      label: "เขานิเวศน์ เมืองระนอง ระนอง 85000",
    },
    {
      value: "ควนกาหลง ควนกาหลง สตูล 91130",
      label: "ควนกาหลง ควนกาหลง สตูล 91130",
    },
    {
      value: "ทุ่งนุ้ย ควนกาหลง สตูล 91130",
      label: "ทุ่งนุ้ย ควนกาหลง สตูล 91130",
    },
    {
      value: "อุไดเจริญ ควนกาหลง สตูล 91130",
      label: "อุไดเจริญ ควนกาหลง สตูล 91130",
    },
    { value: "ควนสตอ ควนโดน สตูล 91160", label: "ควนสตอ ควนโดน สตูล 91160" },
    { value: "ควนโดน ควนโดน สตูล 91160", label: "ควนโดน ควนโดน สตูล 91160" },
    { value: "ย่านซื่อ ควนโดน สตูล 91160", label: "ย่านซื่อ ควนโดน สตูล 91160" },
    {
      value: "วังประจัน ควนโดน สตูล 91160",
      label: "วังประจัน ควนโดน สตูล 91160",
    },
    {
      value: "ขอนคลาน ทุ่งหว้า สตูล 91120",
      label: "ขอนคลาน ทุ่งหว้า สตูล 91120",
    },
    {
      value: "ทุ่งบุหลัง ทุ่งหว้า สตูล 91120",
      label: "ทุ่งบุหลัง ทุ่งหว้า สตูล 91120",
    },
    {
      value: "ทุ่งหว้า ทุ่งหว้า สตูล 91120",
      label: "ทุ่งหว้า ทุ่งหว้า สตูล 91120",
    },
    { value: "นาทอน ทุ่งหว้า สตูล 91120", label: "นาทอน ทุ่งหว้า สตูล 91120" },
    {
      value: "ป่าแก่บ่อหิน ทุ่งหว้า สตูล 91120",
      label: "ป่าแก่บ่อหิน ทุ่งหว้า สตูล 91120",
    },
    { value: "ท่าเรือ ท่าแพ สตูล 91150", label: "ท่าเรือ ท่าแพ สตูล 91150" },
    { value: "ท่าแพ ท่าแพ สตูล 91150", label: "ท่าแพ ท่าแพ สตูล 91150" },
    { value: "สาคร ท่าแพ สตูล 91150", label: "สาคร ท่าแพ สตูล 91150" },
    { value: "แป-ระ ท่าแพ สตูล 91150", label: "แป-ระ ท่าแพ สตูล 91150" },
    { value: "นิคมพัฒนา มะนัง สตูล 91130", label: "นิคมพัฒนา มะนัง สตูล 91130" },
    {
      value: "ปาล์มพัฒนา มะนัง สตูล 91130",
      label: "ปาล์มพัฒนา มะนัง สตูล 91130",
    },
    { value: "กำแพง ละงู สตูล 91110", label: "กำแพง ละงู สตูล 91110" },
    { value: "น้ำผุด ละงู สตูล 91110", label: "น้ำผุด ละงู สตูล 91110" },
    { value: "ปากน้ำ ละงู สตูล 91110", label: "ปากน้ำ ละงู สตูล 91110" },
    { value: "ละงู ละงู สตูล 91110", label: "ละงู ละงู สตูล 91110" },
    { value: "เขาขาว ละงู สตูล 91110", label: "เขาขาว ละงู สตูล 91110" },
    { value: "แหลมสน ละงู สตูล 91110", label: "แหลมสน ละงู สตูล 91110" },
    {
      value: "คลองขุด เมืองสตูล สตูล 91000",
      label: "คลองขุด เมืองสตูล สตูล 91000",
    },
    {
      value: "ควนขัน เมืองสตูล สตูล 91000",
      label: "ควนขัน เมืองสตูล สตูล 91000",
    },
    {
      value: "ควนโพธิ์ เมืองสตูล สตูล 91140",
      label: "ควนโพธิ์ เมืองสตูล สตูล 91140",
    },
    { value: "ฉลุง เมืองสตูล สตูล 91140", label: "ฉลุง เมืองสตูล สตูล 91140" },
    {
      value: "ตันหยงโป เมืองสตูล สตูล 91000",
      label: "ตันหยงโป เมืองสตูล สตูล 91000",
    },
    {
      value: "ตำมะลัง เมืองสตูล สตูล 91000",
      label: "ตำมะลัง เมืองสตูล สตูล 91000",
    },
    {
      value: "บ้านควน เมืองสตูล สตูล 91140",
      label: "บ้านควน เมืองสตูล สตูล 91140",
    },
    { value: "ปูยู เมืองสตูล สตูล 91000", label: "ปูยู เมืองสตูล สตูล 91000" },
    { value: "พิมาน เมืองสตูล สตูล 91000", label: "พิมาน เมืองสตูล สตูล 91000" },
    { value: "เกตรี เมืองสตูล สตูล 91140", label: "เกตรี เมืองสตูล สตูล 91140" },
    {
      value: "เกาะสาหร่าย เมืองสตูล สตูล 91000",
      label: "เกาะสาหร่าย เมืองสตูล สตูล 91000",
    },
    {
      value: "เจ๊ะบิลัง เมืองสตูล สตูล 91000",
      label: "เจ๊ะบิลัง เมืองสตูล สตูล 91000",
    },
    {
      value: "กระแสสินธุ์ กระแสสินธุ์ สงขลา 90270",
      label: "กระแสสินธุ์ กระแสสินธุ์ สงขลา 90270",
    },
    {
      value: "เกาะใหญ่ กระแสสินธุ์ สงขลา 90270",
      label: "เกาะใหญ่ กระแสสินธุ์ สงขลา 90270",
    },
    {
      value: "เชิงแส กระแสสินธุ์ สงขลา 90270",
      label: "เชิงแส กระแสสินธุ์ สงขลา 90270",
    },
    {
      value: "โรง กระแสสินธุ์ สงขลา 90270",
      label: "โรง กระแสสินธุ์ สงขลา 90270",
    },
    {
      value: "คลองหลา คลองหอยโข่ง สงขลา 90115",
      label: "คลองหลา คลองหอยโข่ง สงขลา 90115",
    },
    {
      value: "คลองหอยโข่ง คลองหอยโข่ง สงขลา 90230",
      label: "คลองหอยโข่ง คลองหอยโข่ง สงขลา 90230",
    },
    {
      value: "ทุ่งลาน คลองหอยโข่ง สงขลา 90230",
      label: "ทุ่งลาน คลองหอยโข่ง สงขลา 90230",
    },
    {
      value: "โคกม่วง คลองหอยโข่ง สงขลา 90230",
      label: "โคกม่วง คลองหอยโข่ง สงขลา 90230",
    },
    { value: "ควนโส ควนเนียง สงขลา 90220", label: "ควนโส ควนเนียง สงขลา 90220" },
    {
      value: "บางเหรียง ควนเนียง สงขลา 90220",
      label: "บางเหรียง ควนเนียง สงขลา 90220",
    },
    {
      value: "รัตภูมิ ควนเนียง สงขลา 90220",
      label: "รัตภูมิ ควนเนียง สงขลา 90220",
    },
    {
      value: "ห้วยลึก ควนเนียง สงขลา 90220",
      label: "ห้วยลึก ควนเนียง สงขลา 90220",
    },
    {
      value: "ขุนตัดหวาย จะนะ สงขลา 90130",
      label: "ขุนตัดหวาย จะนะ สงขลา 90130",
    },
    { value: "คลองเปียะ จะนะ สงขลา 90130", label: "คลองเปียะ จะนะ สงขลา 90130" },
    { value: "คู จะนะ สงขลา 90130", label: "คู จะนะ สงขลา 90130" },
    { value: "จะโหนง จะนะ สงขลา 90130", label: "จะโหนง จะนะ สงขลา 90130" },
    { value: "ตลิ่งชัน จะนะ สงขลา 90130", label: "ตลิ่งชัน จะนะ สงขลา 90130" },
    { value: "ท่าหมอไทร จะนะ สงขลา 90130", label: "ท่าหมอไทร จะนะ สงขลา 90130" },
    { value: "นาทับ จะนะ สงขลา 90130", label: "นาทับ จะนะ สงขลา 90130" },
    { value: "นาหว้า จะนะ สงขลา 90130", label: "นาหว้า จะนะ สงขลา 90130" },
    { value: "น้ำขาว จะนะ สงขลา 90130", label: "น้ำขาว จะนะ สงขลา 90130" },
    { value: "บ้านนา จะนะ สงขลา 90130", label: "บ้านนา จะนะ สงขลา 90130" },
    { value: "ป่าชิง จะนะ สงขลา 90130", label: "ป่าชิง จะนะ สงขลา 90130" },
    { value: "สะกอม จะนะ สงขลา 90130", label: "สะกอม จะนะ สงขลา 90130" },
    {
      value: "สะพานไม้แก่น จะนะ สงขลา 90130",
      label: "สะพานไม้แก่น จะนะ สงขลา 90130",
    },
    { value: "แค จะนะ สงขลา 90130", label: "แค จะนะ สงขลา 90130" },
    { value: "คลองกวาง นาทวี สงขลา 90160", label: "คลองกวาง นาทวี สงขลา 90160" },
    { value: "คลองทราย นาทวี สงขลา 90160", label: "คลองทราย นาทวี สงขลา 90160" },
    { value: "ฉาง นาทวี สงขลา 90160", label: "ฉาง นาทวี สงขลา 90160" },
    { value: "ทับช้าง นาทวี สงขลา 90160", label: "ทับช้าง นาทวี สงขลา 90160" },
    {
      value: "ท่าประดู่ นาทวี สงขลา 90160",
      label: "ท่าประดู่ นาทวี สงขลา 90160",
    },
    { value: "นาทวี นาทวี สงขลา 90160", label: "นาทวี นาทวี สงขลา 90160" },
    { value: "นาหมอศรี นาทวี สงขลา 90160", label: "นาหมอศรี นาทวี สงขลา 90160" },
    { value: "ประกอบ นาทวี สงขลา 90160", label: "ประกอบ นาทวี สงขลา 90160" },
    { value: "ปลักหนู นาทวี สงขลา 90160", label: "ปลักหนู นาทวี สงขลา 90160" },
    { value: "สะท้อน นาทวี สงขลา 90160", label: "สะท้อน นาทวี สงขลา 90160" },
    {
      value: "คลองหรัง นาหม่อม สงขลา 90310",
      label: "คลองหรัง นาหม่อม สงขลา 90310",
    },
    {
      value: "ทุ่งขมิ้น นาหม่อม สงขลา 90310",
      label: "ทุ่งขมิ้น นาหม่อม สงขลา 90310",
    },
    {
      value: "นาหม่อม นาหม่อม สงขลา 90310",
      label: "นาหม่อม นาหม่อม สงขลา 90310",
    },
    { value: "พิจิตร นาหม่อม สงขลา 90310", label: "พิจิตร นาหม่อม สงขลา 90310" },
    {
      value: "ท่าช้าง บางกล่ำ สงขลา 90110",
      label: "ท่าช้าง บางกล่ำ สงขลา 90110",
    },
    {
      value: "บางกล่ำ บางกล่ำ สงขลา 90110",
      label: "บางกล่ำ บางกล่ำ สงขลา 90110",
    },
    {
      value: "บ้านหาร บางกล่ำ สงขลา 90110",
      label: "บ้านหาร บางกล่ำ สงขลา 90110",
    },
    { value: "แม่ทอม บางกล่ำ สงขลา 90110", label: "แม่ทอม บางกล่ำ สงขลา 90110" },
    { value: "คลองแดน ระโนด สงขลา 90140", label: "คลองแดน ระโนด สงขลา 90140" },
    { value: "ตะเครียะ ระโนด สงขลา 90140", label: "ตะเครียะ ระโนด สงขลา 90140" },
    { value: "ท่าบอน ระโนด สงขลา 90140", label: "ท่าบอน ระโนด สงขลา 90140" },
    { value: "บ่อตรุ ระโนด สงขลา 90140", label: "บ่อตรุ ระโนด สงขลา 90140" },
    { value: "บ้านขาว ระโนด สงขลา 90140", label: "บ้านขาว ระโนด สงขลา 90140" },
    { value: "บ้านใหม่ ระโนด สงขลา 90140", label: "บ้านใหม่ ระโนด สงขลา 90140" },
    { value: "ปากแตระ ระโนด สงขลา 90140", label: "ปากแตระ ระโนด สงขลา 90140" },
    { value: "พังยาง ระโนด สงขลา 90140", label: "พังยาง ระโนด สงขลา 90140" },
    { value: "ระวะ ระโนด สงขลา 90140", label: "ระวะ ระโนด สงขลา 90140" },
    { value: "ระโนด ระโนด สงขลา 90140", label: "ระโนด ระโนด สงขลา 90140" },
    { value: "วัดสน ระโนด สงขลา 90140", label: "วัดสน ระโนด สงขลา 90140" },
    { value: "แดนสงวน ระโนด สงขลา 90140", label: "แดนสงวน ระโนด สงขลา 90140" },
    {
      value: "กำแพงเพชร รัตภูมิ สงขลา 90180",
      label: "กำแพงเพชร รัตภูมิ สงขลา 90180",
    },
    { value: "ควนรู รัตภูมิ สงขลา 90220", label: "ควนรู รัตภูมิ สงขลา 90220" },
    {
      value: "คูหาใต้ รัตภูมิ สงขลา 90180",
      label: "คูหาใต้ รัตภูมิ สงขลา 90180",
    },
    {
      value: "ท่าชะมวง รัตภูมิ สงขลา 90180",
      label: "ท่าชะมวง รัตภูมิ สงขลา 90180",
    },
    { value: "เขาพระ รัตภูมิ สงขลา 90180", label: "เขาพระ รัตภูมิ สงขลา 90180" },
    {
      value: "กระดังงา สทิงพระ สงขลา 90190",
      label: "กระดังงา สทิงพระ สงขลา 90190",
    },
    { value: "คลองรี สทิงพระ สงขลา 90190", label: "คลองรี สทิงพระ สงขลา 90190" },
    { value: "คูขุด สทิงพระ สงขลา 90190", label: "คูขุด สทิงพระ สงขลา 90190" },
    {
      value: "จะทิ้งพระ สทิงพระ สงขลา 90190",
      label: "จะทิ้งพระ สทิงพระ สงขลา 90190",
    },
    { value: "ชุมพล สทิงพระ สงขลา 90190", label: "ชุมพล สทิงพระ สงขลา 90190" },
    { value: "ดีหลวง สทิงพระ สงขลา 90190", label: "ดีหลวง สทิงพระ สงขลา 90190" },
    { value: "ท่าหิน สทิงพระ สงขลา 90190", label: "ท่าหิน สทิงพระ สงขลา 90190" },
    { value: "บ่อดาน สทิงพระ สงขลา 90190", label: "บ่อดาน สทิงพระ สงขลา 90190" },
    { value: "บ่อแดง สทิงพระ สงขลา 90190", label: "บ่อแดง สทิงพระ สงขลา 90190" },
    {
      value: "วัดจันทร์ สทิงพระ สงขลา 90190",
      label: "วัดจันทร์ สทิงพระ สงขลา 90190",
    },
    {
      value: "สนามชัย สทิงพระ สงขลา 90190",
      label: "สนามชัย สทิงพระ สงขลา 90190",
    },
    { value: "คูหา สะบ้าย้อย สงขลา 90210", label: "คูหา สะบ้าย้อย สงขลา 90210" },
    {
      value: "จะแหน สะบ้าย้อย สงขลา 90210",
      label: "จะแหน สะบ้าย้อย สงขลา 90210",
    },
    {
      value: "ทุ่งพอ สะบ้าย้อย สงขลา 90210",
      label: "ทุ่งพอ สะบ้าย้อย สงขลา 90210",
    },
    {
      value: "ธารคีรี สะบ้าย้อย สงขลา 90210",
      label: "ธารคีรี สะบ้าย้อย สงขลา 90210",
    },
    {
      value: "บาโหย สะบ้าย้อย สงขลา 90210",
      label: "บาโหย สะบ้าย้อย สงขลา 90210",
    },
    {
      value: "บ้านโหนด สะบ้าย้อย สงขลา 90210",
      label: "บ้านโหนด สะบ้าย้อย สงขลา 90210",
    },
    {
      value: "สะบ้าย้อย สะบ้าย้อย สงขลา 90210",
      label: "สะบ้าย้อย สะบ้าย้อย สงขลา 90210",
    },
    {
      value: "เขาแดง สะบ้าย้อย สงขลา 90210",
      label: "เขาแดง สะบ้าย้อย สงขลา 90210",
    },
    {
      value: "เปียน สะบ้าย้อย สงขลา 90210",
      label: "เปียน สะบ้าย้อย สงขลา 90210",
    },
    { value: "ทุ่งหมอ สะเดา สงขลา 90240", label: "ทุ่งหมอ สะเดา สงขลา 90240" },
    { value: "ท่าโพธิ์ สะเดา สงขลา 90170", label: "ท่าโพธิ์ สะเดา สงขลา 90170" },
    { value: "ปริก สะเดา สงขลา 90120", label: "ปริก สะเดา สงขลา 90120" },
    {
      value: "ปาดังเบซาร์ สะเดา สงขลา 90240",
      label: "ปาดังเบซาร์ สะเดา สงขลา 90240",
    },
    { value: "พังลา สะเดา สงขลา 90170", label: "พังลา สะเดา สงขลา 90170" },
    { value: "สะเดา สะเดา สงขลา 90120", label: "สะเดา สะเดา สงขลา 90120" },
    { value: "สำนักขาม สะเดา สงขลา 90320", label: "สำนักขาม สะเดา สงขลา 90320" },
    {
      value: "สำนักแต้ว สะเดา สงขลา 90120",
      label: "สำนักแต้ว สะเดา สงขลา 90120",
    },
    {
      value: "เขามีเกียรติ สะเดา สงขลา 90170",
      label: "เขามีเกียรติ สะเดา สงขลา 90170",
    },
    { value: "ชะแล้ สิงหนคร สงขลา 90330", label: "ชะแล้ สิงหนคร สงขลา 90330" },
    { value: "ชิงโค สิงหนคร สงขลา 90280", label: "ชิงโค สิงหนคร สงขลา 90280" },
    { value: "ทำนบ สิงหนคร สงขลา 90280", label: "ทำนบ สิงหนคร สงขลา 90280" },
    {
      value: "บางเขียด สิงหนคร สงขลา 90330",
      label: "บางเขียด สิงหนคร สงขลา 90330",
    },
    { value: "ปากรอ สิงหนคร สงขลา 90330", label: "ปากรอ สิงหนคร สงขลา 90330" },
    { value: "ป่าขาด สิงหนคร สงขลา 90330", label: "ป่าขาด สิงหนคร สงขลา 90330" },
    {
      value: "ม่วงงาม สิงหนคร สงขลา 90330",
      label: "ม่วงงาม สิงหนคร สงขลา 90330",
    },
    { value: "รำแดง สิงหนคร สงขลา 90330", label: "รำแดง สิงหนคร สงขลา 90330" },
    {
      value: "วัดขนุน สิงหนคร สงขลา 90330",
      label: "วัดขนุน สิงหนคร สงขลา 90330",
    },
    {
      value: "สทิงหม้อ สิงหนคร สงขลา 90280",
      label: "สทิงหม้อ สิงหนคร สงขลา 90280",
    },
    { value: "หัวเขา สิงหนคร สงขลา 90280", label: "หัวเขา สิงหนคร สงขลา 90280" },
    {
      value: "คลองอู่ตะเภา หาดใหญ่ สงขลา 90110",
      label: "คลองอู่ตะเภา หาดใหญ่ สงขลา 90110",
    },
    { value: "คลองแห หาดใหญ่ สงขลา 90110", label: "คลองแห หาดใหญ่ สงขลา 90110" },
    { value: "ควนลัง หาดใหญ่ สงขลา 90110", label: "ควนลัง หาดใหญ่ สงขลา 90110" },
    { value: "คอหงส์ หาดใหญ่ สงขลา 90110", label: "คอหงส์ หาดใหญ่ สงขลา 90110" },
    { value: "คูเต่า หาดใหญ่ สงขลา 90110", label: "คูเต่า หาดใหญ่ สงขลา 90110" },
    { value: "ฉลุง หาดใหญ่ สงขลา 90110", label: "ฉลุง หาดใหญ่ สงขลา 90110" },
    {
      value: "ทุ่งตำเสา หาดใหญ่ สงขลา 90110",
      label: "ทุ่งตำเสา หาดใหญ่ สงขลา 90110",
    },
    {
      value: "ทุ่งใหญ่ หาดใหญ่ สงขลา 90110",
      label: "ทุ่งใหญ่ หาดใหญ่ สงขลา 90110",
    },
    {
      value: "ท่าข้าม หาดใหญ่ สงขลา 90110",
      label: "ท่าข้าม หาดใหญ่ สงขลา 90110",
    },
    {
      value: "น้ำน้อย หาดใหญ่ สงขลา 90110",
      label: "น้ำน้อย หาดใหญ่ สงขลา 90110",
    },
    {
      value: "บ้านพรุ หาดใหญ่ สงขลา 90250",
      label: "บ้านพรุ หาดใหญ่ สงขลา 90250",
    },
    { value: "พะตง หาดใหญ่ สงขลา 90230", label: "พะตง หาดใหญ่ สงขลา 90230" },
    {
      value: "หาดใหญ่ หาดใหญ่ สงขลา 90110",
      label: "หาดใหญ่ หาดใหญ่ สงขลา 90110",
    },
    { value: "ท่าม่วง เทพา สงขลา 90150", label: "ท่าม่วง เทพา สงขลา 90150" },
    { value: "ปากบาง เทพา สงขลา 90150", label: "ปากบาง เทพา สงขลา 90150" },
    { value: "ลำไพล เทพา สงขลา 90260", label: "ลำไพล เทพา สงขลา 90260" },
    { value: "วังใหญ่ เทพา สงขลา 90260", label: "วังใหญ่ เทพา สงขลา 90260" },
    { value: "สะกอม เทพา สงขลา 90150", label: "สะกอม เทพา สงขลา 90150" },
    { value: "เกาะสะบ้า เทพา สงขลา 90150", label: "เกาะสะบ้า เทพา สงขลา 90150" },
    { value: "เทพา เทพา สงขลา 90150", label: "เทพา เทพา สงขลา 90150" },
    {
      value: "ทุ่งหวัง เมืองสงขลา สงขลา 90000",
      label: "ทุ่งหวัง เมืองสงขลา สงขลา 90000",
    },
    {
      value: "บ่อยาง เมืองสงขลา สงขลา 90000",
      label: "บ่อยาง เมืองสงขลา สงขลา 90000",
    },
    {
      value: "พะวง เมืองสงขลา สงขลา 90100",
      label: "พะวง เมืองสงขลา สงขลา 90100",
    },
    {
      value: "เกาะยอ เมืองสงขลา สงขลา 90100",
      label: "เกาะยอ เมืองสงขลา สงขลา 90100",
    },
    {
      value: "เกาะแต้ว เมืองสงขลา สงขลา 90000",
      label: "เกาะแต้ว เมืองสงขลา สงขลา 90000",
    },
    {
      value: "เขารูปช้าง เมืองสงขลา สงขลา 90000",
      label: "เขารูปช้าง เมืองสงขลา สงขลา 90000",
    },
    {
      value: "กรูด กาญจนดิษฐ์ สุราษฎร์ธานี 84160",
      label: "กรูด กาญจนดิษฐ์ สุราษฎร์ธานี 84160",
    },
    {
      value: "กะแดะ กาญจนดิษฐ์ สุราษฎร์ธานี 84160",
      label: "กะแดะ กาญจนดิษฐ์ สุราษฎร์ธานี 84160",
    },
    {
      value: "คลองสระ กาญจนดิษฐ์ สุราษฎร์ธานี 84160",
      label: "คลองสระ กาญจนดิษฐ์ สุราษฎร์ธานี 84160",
    },
    {
      value: "ช้างขวา กาญจนดิษฐ์ สุราษฎร์ธานี 84160",
      label: "ช้างขวา กาญจนดิษฐ์ สุราษฎร์ธานี 84160",
    },
    {
      value: "ช้างซ้าย กาญจนดิษฐ์ สุราษฎร์ธานี 84160",
      label: "ช้างซ้าย กาญจนดิษฐ์ สุราษฎร์ธานี 84160",
    },
    {
      value: "ตะเคียนทอง กาญจนดิษฐ์ สุราษฎร์ธานี 84160",
      label: "ตะเคียนทอง กาญจนดิษฐ์ สุราษฎร์ธานี 84160",
    },
    {
      value: "ทุ่งกง กาญจนดิษฐ์ สุราษฎร์ธานี 84290",
      label: "ทุ่งกง กาญจนดิษฐ์ สุราษฎร์ธานี 84290",
    },
    {
      value: "ทุ่งรัง กาญจนดิษฐ์ สุราษฎร์ธานี 84290",
      label: "ทุ่งรัง กาญจนดิษฐ์ สุราษฎร์ธานี 84290",
    },
    {
      value: "ท่าทอง กาญจนดิษฐ์ สุราษฎร์ธานี 84160",
      label: "ท่าทอง กาญจนดิษฐ์ สุราษฎร์ธานี 84160",
    },
    {
      value: "ท่าทองใหม่ กาญจนดิษฐ์ สุราษฎร์ธานี 84290",
      label: "ท่าทองใหม่ กาญจนดิษฐ์ สุราษฎร์ธานี 84290",
    },
    {
      value: "ท่าอุแท กาญจนดิษฐ์ สุราษฎร์ธานี 84160",
      label: "ท่าอุแท กาญจนดิษฐ์ สุราษฎร์ธานี 84160",
    },
    {
      value: "ป่าร่อน กาญจนดิษฐ์ สุราษฎร์ธานี 84160",
      label: "ป่าร่อน กาญจนดิษฐ์ สุราษฎร์ธานี 84160",
    },
    {
      value: "พลายวาส กาญจนดิษฐ์ สุราษฎร์ธานี 84160",
      label: "พลายวาส กาญจนดิษฐ์ สุราษฎร์ธานี 84160",
    },
    {
      value: "กะเปา คีรีรัฐนิคม สุราษฎร์ธานี 84180",
      label: "กะเปา คีรีรัฐนิคม สุราษฎร์ธานี 84180",
    },
    {
      value: "ถ้ำสิงขร คีรีรัฐนิคม สุราษฎร์ธานี 84180",
      label: "ถ้ำสิงขร คีรีรัฐนิคม สุราษฎร์ธานี 84180",
    },
    {
      value: "ท่ากระดาน คีรีรัฐนิคม สุราษฎร์ธานี 84180",
      label: "ท่ากระดาน คีรีรัฐนิคม สุราษฎร์ธานี 84180",
    },
    {
      value: "ท่าขนอน คีรีรัฐนิคม สุราษฎร์ธานี 84180",
      label: "ท่าขนอน คีรีรัฐนิคม สุราษฎร์ธานี 84180",
    },
    {
      value: "น้ำหัก คีรีรัฐนิคม สุราษฎร์ธานี 84180",
      label: "น้ำหัก คีรีรัฐนิคม สุราษฎร์ธานี 84180",
    },
    {
      value: "บ้านทำเนียบ คีรีรัฐนิคม สุราษฎร์ธานี 84180",
      label: "บ้านทำเนียบ คีรีรัฐนิคม สุราษฎร์ธานี 84180",
    },
    {
      value: "บ้านยาง คีรีรัฐนิคม สุราษฎร์ธานี 84180",
      label: "บ้านยาง คีรีรัฐนิคม สุราษฎร์ธานี 84180",
    },
    {
      value: "ย่านยาว คีรีรัฐนิคม สุราษฎร์ธานี 84180",
      label: "ย่านยาว คีรีรัฐนิคม สุราษฎร์ธานี 84180",
    },
    {
      value: "คลองน้อย ชัยบุรี สุราษฎร์ธานี 84350",
      label: "คลองน้อย ชัยบุรี สุราษฎร์ธานี 84350",
    },
    {
      value: "ชัยบุรี ชัยบุรี สุราษฎร์ธานี 84350",
      label: "ชัยบุรี ชัยบุรี สุราษฎร์ธานี 84350",
    },
    {
      value: "สองแพรก ชัยบุรี สุราษฎร์ธานี 84350",
      label: "สองแพรก ชัยบุรี สุราษฎร์ธานี 84350",
    },
    {
      value: "ไทรทอง ชัยบุรี สุราษฎร์ธานี 84350",
      label: "ไทรทอง ชัยบุรี สุราษฎร์ธานี 84350",
    },
    {
      value: "ชลคราม ดอนสัก สุราษฎร์ธานี 84160",
      label: "ชลคราม ดอนสัก สุราษฎร์ธานี 84160",
    },
    {
      value: "ดอนสัก ดอนสัก สุราษฎร์ธานี 84220",
      label: "ดอนสัก ดอนสัก สุราษฎร์ธานี 84220",
    },
    {
      value: "ปากแพรก ดอนสัก สุราษฎร์ธานี 84340",
      label: "ปากแพรก ดอนสัก สุราษฎร์ธานี 84340",
    },
    {
      value: "ไชยคราม ดอนสัก สุราษฎร์ธานี 84220",
      label: "ไชยคราม ดอนสัก สุราษฎร์ธานี 84220",
    },
    {
      value: "คลองไทร ท่าฉาง สุราษฎร์ธานี 84150",
      label: "คลองไทร ท่าฉาง สุราษฎร์ธานี 84150",
    },
    {
      value: "ท่าฉาง ท่าฉาง สุราษฎร์ธานี 84150",
      label: "ท่าฉาง ท่าฉาง สุราษฎร์ธานี 84150",
    },
    {
      value: "ท่าเคย ท่าฉาง สุราษฎร์ธานี 84150",
      label: "ท่าเคย ท่าฉาง สุราษฎร์ธานี 84150",
    },
    {
      value: "ปากฉลุย ท่าฉาง สุราษฎร์ธานี 84150",
      label: "ปากฉลุย ท่าฉาง สุราษฎร์ธานี 84150",
    },
    {
      value: "เขาถ่าน ท่าฉาง สุราษฎร์ธานี 84150",
      label: "เขาถ่าน ท่าฉาง สุราษฎร์ธานี 84150",
    },
    {
      value: "เสวียด ท่าฉาง สุราษฎร์ธานี 84150",
      label: "เสวียด ท่าฉาง สุราษฎร์ธานี 84150",
    },
    {
      value: "คลองพา ท่าชนะ สุราษฎร์ธานี 84170",
      label: "คลองพา ท่าชนะ สุราษฎร์ธานี 84170",
    },
    {
      value: "คันธุลี ท่าชนะ สุราษฎร์ธานี 84170",
      label: "คันธุลี ท่าชนะ สุราษฎร์ธานี 84170",
    },
    {
      value: "ท่าชนะ ท่าชนะ สุราษฎร์ธานี 84170",
      label: "ท่าชนะ ท่าชนะ สุราษฎร์ธานี 84170",
    },
    {
      value: "ประสงค์ ท่าชนะ สุราษฎร์ธานี 84170",
      label: "ประสงค์ ท่าชนะ สุราษฎร์ธานี 84170",
    },
    {
      value: "วัง ท่าชนะ สุราษฎร์ธานี 84170",
      label: "วัง ท่าชนะ สุราษฎร์ธานี 84170",
    },
    {
      value: "สมอทอง ท่าชนะ สุราษฎร์ธานี 84170",
      label: "สมอทอง ท่าชนะ สุราษฎร์ธานี 84170",
    },
    {
      value: "พรุไทย บ้านตาขุน สุราษฎร์ธานี 84230",
      label: "พรุไทย บ้านตาขุน สุราษฎร์ธานี 84230",
    },
    {
      value: "พะแสง บ้านตาขุน สุราษฎร์ธานี 84230",
      label: "พะแสง บ้านตาขุน สุราษฎร์ธานี 84230",
    },
    {
      value: "เขาพัง บ้านตาขุน สุราษฎร์ธานี 84230",
      label: "เขาพัง บ้านตาขุน สุราษฎร์ธานี 84230",
    },
    {
      value: "เขาวง บ้านตาขุน สุราษฎร์ธานี 84230",
      label: "เขาวง บ้านตาขุน สุราษฎร์ธานี 84230",
    },
    {
      value: "คลองปราบ บ้านนาสาร สุราษฎร์ธานี 84120",
      label: "คลองปราบ บ้านนาสาร สุราษฎร์ธานี 84120",
    },
    {
      value: "ควนศรี บ้านนาสาร สุราษฎร์ธานี 84270",
      label: "ควนศรี บ้านนาสาร สุราษฎร์ธานี 84270",
    },
    {
      value: "ควนสุบรรณ บ้านนาสาร สุราษฎร์ธานี 84120",
      label: "ควนสุบรรณ บ้านนาสาร สุราษฎร์ธานี 84120",
    },
    {
      value: "ทุ่งเตา บ้านนาสาร สุราษฎร์ธานี 84120",
      label: "ทุ่งเตา บ้านนาสาร สุราษฎร์ธานี 84120",
    },
    {
      value: "ทุ่งเตาใหม่ บ้านนาสาร สุราษฎร์ธานี 84120",
      label: "ทุ่งเตาใหม่ บ้านนาสาร สุราษฎร์ธานี 84120",
    },
    {
      value: "ท่าชี บ้านนาสาร สุราษฎร์ธานี 84120",
      label: "ท่าชี บ้านนาสาร สุราษฎร์ธานี 84120",
    },
    {
      value: "นาสาร บ้านนาสาร สุราษฎร์ธานี 84120",
      label: "นาสาร บ้านนาสาร สุราษฎร์ธานี 84120",
    },
    {
      value: "น้ำพุ บ้านนาสาร สุราษฎร์ธานี 84120",
      label: "น้ำพุ บ้านนาสาร สุราษฎร์ธานี 84120",
    },
    {
      value: "พรุพี บ้านนาสาร สุราษฎร์ธานี 84270",
      label: "พรุพี บ้านนาสาร สุราษฎร์ธานี 84270",
    },
    {
      value: "ลำพูน บ้านนาสาร สุราษฎร์ธานี 84120",
      label: "ลำพูน บ้านนาสาร สุราษฎร์ธานี 84120",
    },
    {
      value: "เพิ่มพูนทรัพย์ บ้านนาสาร สุราษฎร์ธานี 84120",
      label: "เพิ่มพูนทรัพย์ บ้านนาสาร สุราษฎร์ธานี 84120",
    },
    {
      value: "ทรัพย์ทวี บ้านนาเดิม สุราษฎร์ธานี 84240",
      label: "ทรัพย์ทวี บ้านนาเดิม สุราษฎร์ธานี 84240",
    },
    {
      value: "ท่าเรือ บ้านนาเดิม สุราษฎร์ธานี 84240",
      label: "ท่าเรือ บ้านนาเดิม สุราษฎร์ธานี 84240",
    },
    {
      value: "นาใต้ บ้านนาเดิม สุราษฎร์ธานี 84240",
      label: "นาใต้ บ้านนาเดิม สุราษฎร์ธานี 84240",
    },
    {
      value: "บ้านนา บ้านนาเดิม สุราษฎร์ธานี 84240",
      label: "บ้านนา บ้านนาเดิม สุราษฎร์ธานี 84240",
    },
    {
      value: "คลองชะอุ่น พนม สุราษฎร์ธานี 84250",
      label: "คลองชะอุ่น พนม สุราษฎร์ธานี 84250",
    },
    {
      value: "คลองศก พนม สุราษฎร์ธานี 84250",
      label: "คลองศก พนม สุราษฎร์ธานี 84250",
    },
    {
      value: "ต้นยวน พนม สุราษฎร์ธานี 84250",
      label: "ต้นยวน พนม สุราษฎร์ธานี 84250",
    },
    { value: "พนม พนม สุราษฎร์ธานี 84250", label: "พนม พนม สุราษฎร์ธานี 84250" },
    {
      value: "พลูเถื่อน พนม สุราษฎร์ธานี 84250",
      label: "พลูเถื่อน พนม สุราษฎร์ธานี 84250",
    },
    {
      value: "พังกาญจน์ พนม สุราษฎร์ธานี 84250",
      label: "พังกาญจน์ พนม สุราษฎร์ธานี 84250",
    },
    {
      value: "บางสวรรค์ พระแสง สุราษฎร์ธานี 84210",
      label: "บางสวรรค์ พระแสง สุราษฎร์ธานี 84210",
    },
    {
      value: "สาคู พระแสง สุราษฎร์ธานี 84210",
      label: "สาคู พระแสง สุราษฎร์ธานี 84210",
    },
    {
      value: "สินปุน พระแสง สุราษฎร์ธานี 84210",
      label: "สินปุน พระแสง สุราษฎร์ธานี 84210",
    },
    {
      value: "สินเจริญ พระแสง สุราษฎร์ธานี 84210",
      label: "สินเจริญ พระแสง สุราษฎร์ธานี 84210",
    },
    {
      value: "อิปัน พระแสง สุราษฎร์ธานี 84210",
      label: "อิปัน พระแสง สุราษฎร์ธานี 84210",
    },
    {
      value: "ไทรขึง พระแสง สุราษฎร์ธานี 84210",
      label: "ไทรขึง พระแสง สุราษฎร์ธานี 84210",
    },
    {
      value: "ไทรโสภา พระแสง สุราษฎร์ธานี 84210",
      label: "ไทรโสภา พระแสง สุราษฎร์ธานี 84210",
    },
    {
      value: "กรูด พุนพิน สุราษฎร์ธานี 84130",
      label: "กรูด พุนพิน สุราษฎร์ธานี 84130",
    },
    {
      value: "ตะปาน พุนพิน สุราษฎร์ธานี 84130",
      label: "ตะปาน พุนพิน สุราษฎร์ธานี 84130",
    },
    {
      value: "ท่าข้าม พุนพิน สุราษฎร์ธานี 84130",
      label: "ท่าข้าม พุนพิน สุราษฎร์ธานี 84130",
    },
    {
      value: "ท่าสะท้อน พุนพิน สุราษฎร์ธานี 84130",
      label: "ท่าสะท้อน พุนพิน สุราษฎร์ธานี 84130",
    },
    {
      value: "ท่าโรงช้าง พุนพิน สุราษฎร์ธานี 84130",
      label: "ท่าโรงช้าง พุนพิน สุราษฎร์ธานี 84130",
    },
    {
      value: "น้ำรอบ พุนพิน สุราษฎร์ธานี 84130",
      label: "น้ำรอบ พุนพิน สุราษฎร์ธานี 84130",
    },
    {
      value: "บางงอน พุนพิน สุราษฎร์ธานี 84130",
      label: "บางงอน พุนพิน สุราษฎร์ธานี 84130",
    },
    {
      value: "บางมะเดื่อ พุนพิน สุราษฎร์ธานี 84130",
      label: "บางมะเดื่อ พุนพิน สุราษฎร์ธานี 84130",
    },
    {
      value: "บางเดือน พุนพิน สุราษฎร์ธานี 84130",
      label: "บางเดือน พุนพิน สุราษฎร์ธานี 84130",
    },
    {
      value: "พุนพิน พุนพิน สุราษฎร์ธานี 84130",
      label: "พุนพิน พุนพิน สุราษฎร์ธานี 84130",
    },
    {
      value: "มะลวน พุนพิน สุราษฎร์ธานี 84130",
      label: "มะลวน พุนพิน สุราษฎร์ธานี 84130",
    },
    {
      value: "ลีเล็ด พุนพิน สุราษฎร์ธานี 84130",
      label: "ลีเล็ด พุนพิน สุราษฎร์ธานี 84130",
    },
    {
      value: "ศรีวิชัย พุนพิน สุราษฎร์ธานี 84130",
      label: "ศรีวิชัย พุนพิน สุราษฎร์ธานี 84130",
    },
    {
      value: "หนองไทร พุนพิน สุราษฎร์ธานี 84130",
      label: "หนองไทร พุนพิน สุราษฎร์ธานี 84130",
    },
    {
      value: "หัวเตย พุนพิน สุราษฎร์ธานี 84130",
      label: "หัวเตย พุนพิน สุราษฎร์ธานี 84130",
    },
    {
      value: "เขาหัวควาย พุนพิน สุราษฎร์ธานี 84130",
      label: "เขาหัวควาย พุนพิน สุราษฎร์ธานี 84130",
    },
    {
      value: "ตะกุกเหนือ วิภาวดี สุราษฎร์ธานี 84180",
      label: "ตะกุกเหนือ วิภาวดี สุราษฎร์ธานี 84180",
    },
    {
      value: "ตะกุกใต้ วิภาวดี สุราษฎร์ธานี 84180",
      label: "ตะกุกใต้ วิภาวดี สุราษฎร์ธานี 84180",
    },
    {
      value: "บ้านใต้ เกาะพะงัน สุราษฎร์ธานี 84280",
      label: "บ้านใต้ เกาะพะงัน สุราษฎร์ธานี 84280",
    },
    {
      value: "เกาะพะงัน เกาะพะงัน สุราษฎร์ธานี 84280",
      label: "เกาะพะงัน เกาะพะงัน สุราษฎร์ธานี 84280",
    },
    {
      value: "เกาะเต่า เกาะพะงัน สุราษฎร์ธานี 84280",
      label: "เกาะเต่า เกาะพะงัน สุราษฎร์ธานี 84280",
    },
    {
      value: "ตลิ่งงาม เกาะสมุย สุราษฎร์ธานี 84140",
      label: "ตลิ่งงาม เกาะสมุย สุราษฎร์ธานี 84140",
    },
    {
      value: "บ่อผุด เกาะสมุย สุราษฎร์ธานี 84320",
      label: "บ่อผุด เกาะสมุย สุราษฎร์ธานี 84320",
    },
    {
      value: "มะเร็ต เกาะสมุย สุราษฎร์ธานี 84310",
      label: "มะเร็ต เกาะสมุย สุราษฎร์ธานี 84310",
    },
    {
      value: "ลิปะน้อย เกาะสมุย สุราษฎร์ธานี 84140",
      label: "ลิปะน้อย เกาะสมุย สุราษฎร์ธานี 84140",
    },
    {
      value: "หน้าเมือง เกาะสมุย สุราษฎร์ธานี 84140",
      label: "หน้าเมือง เกาะสมุย สุราษฎร์ธานี 84140",
    },
    {
      value: "อ่างทอง เกาะสมุย สุราษฎร์ธานี 84140",
      label: "อ่างทอง เกาะสมุย สุราษฎร์ธานี 84140",
    },
    {
      value: "แม่น้ำ เกาะสมุย สุราษฎร์ธานี 84330",
      label: "แม่น้ำ เกาะสมุย สุราษฎร์ธานี 84330",
    },
    {
      value: "บ้านเสด็จ เคียนซา สุราษฎร์ธานี 84260",
      label: "บ้านเสด็จ เคียนซา สุราษฎร์ธานี 84260",
    },
    {
      value: "พ่วงพรมคร เคียนซา สุราษฎร์ธานี 84210",
      label: "พ่วงพรมคร เคียนซา สุราษฎร์ธานี 84210",
    },
    {
      value: "อรัญคามวารี เคียนซา สุราษฎร์ธานี 84260",
      label: "อรัญคามวารี เคียนซา สุราษฎร์ธานี 84260",
    },
    {
      value: "เขาตอก เคียนซา สุราษฎร์ธานี 84260",
      label: "เขาตอก เคียนซา สุราษฎร์ธานี 84260",
    },
    {
      value: "เคียนซา เคียนซา สุราษฎร์ธานี 84260",
      label: "เคียนซา เคียนซา สุราษฎร์ธานี 84260",
    },
    {
      value: "ขุนทะเล เมืองสุราษฎร์ธานี สุราษฎร์ธานี 84100",
      label: "ขุนทะเล เมืองสุราษฎร์ธานี สุราษฎร์ธานี 84100",
    },
    {
      value: "คลองฉนาก เมืองสุราษฎร์ธานี สุราษฎร์ธานี 84000",
      label: "คลองฉนาก เมืองสุราษฎร์ธานี สุราษฎร์ธานี 84000",
    },
    {
      value: "คลองน้อย เมืองสุราษฎร์ธานี สุราษฎร์ธานี 84000",
      label: "คลองน้อย เมืองสุราษฎร์ธานี สุราษฎร์ธานี 84000",
    },
    {
      value: "ตลาด เมืองสุราษฎร์ธานี สุราษฎร์ธานี 84000",
      label: "ตลาด เมืองสุราษฎร์ธานี สุราษฎร์ธานี 84000",
    },
    {
      value: "บางกุ้ง เมืองสุราษฎร์ธานี สุราษฎร์ธานี 84000",
      label: "บางกุ้ง เมืองสุราษฎร์ธานี สุราษฎร์ธานี 84000",
    },
    {
      value: "บางชนะ เมืองสุราษฎร์ธานี สุราษฎร์ธานี 84000",
      label: "บางชนะ เมืองสุราษฎร์ธานี สุราษฎร์ธานี 84000",
    },
    {
      value: "บางโพธิ์ เมืองสุราษฎร์ธานี สุราษฎร์ธานี 84000",
      label: "บางโพธิ์ เมืองสุราษฎร์ธานี สุราษฎร์ธานี 84000",
    },
    {
      value: "บางใบไม้ เมืองสุราษฎร์ธานี สุราษฎร์ธานี 84000",
      label: "บางใบไม้ เมืองสุราษฎร์ธานี สุราษฎร์ธานี 84000",
    },
    {
      value: "บางไทร เมืองสุราษฎร์ธานี สุราษฎร์ธานี 84000",
      label: "บางไทร เมืองสุราษฎร์ธานี สุราษฎร์ธานี 84000",
    },
    {
      value: "มะขามเตี้ย เมืองสุราษฎร์ธานี สุราษฎร์ธานี 84000",
      label: "มะขามเตี้ย เมืองสุราษฎร์ธานี สุราษฎร์ธานี 84000",
    },
    {
      value: "วัดประดู่ เมืองสุราษฎร์ธานี สุราษฎร์ธานี 84000",
      label: "วัดประดู่ เมืองสุราษฎร์ธานี สุราษฎร์ธานี 84000",
    },
    {
      value: "คลองฉนวน เวียงสระ สุราษฎร์ธานี 84190",
      label: "คลองฉนวน เวียงสระ สุราษฎร์ธานี 84190",
    },
    {
      value: "ทุ่งหลวง เวียงสระ สุราษฎร์ธานี 84190",
      label: "ทุ่งหลวง เวียงสระ สุราษฎร์ธานี 84190",
    },
    {
      value: "บ้านส้อง เวียงสระ สุราษฎร์ธานี 84190",
      label: "บ้านส้อง เวียงสระ สุราษฎร์ธานี 84190",
    },
    {
      value: "เขานิพันธ์ เวียงสระ สุราษฎร์ธานี 84190",
      label: "เขานิพันธ์ เวียงสระ สุราษฎร์ธานี 84190",
    },
    {
      value: "เวียงสระ เวียงสระ สุราษฎร์ธานี 84190",
      label: "เวียงสระ เวียงสระ สุราษฎร์ธานี 84190",
    },
    {
      value: "ตลาดไชยา ไชยา สุราษฎร์ธานี 84110",
      label: "ตลาดไชยา ไชยา สุราษฎร์ธานี 84110",
    },
    {
      value: "ตะกรบ ไชยา สุราษฎร์ธานี 84110",
      label: "ตะกรบ ไชยา สุราษฎร์ธานี 84110",
    },
    {
      value: "ทุ่ง ไชยา สุราษฎร์ธานี 84110",
      label: "ทุ่ง ไชยา สุราษฎร์ธานี 84110",
    },
    {
      value: "ปากหมาก ไชยา สุราษฎร์ธานี 84110",
      label: "ปากหมาก ไชยา สุราษฎร์ธานี 84110",
    },
    {
      value: "ป่าเว ไชยา สุราษฎร์ธานี 84110",
      label: "ป่าเว ไชยา สุราษฎร์ธานี 84110",
    },
    {
      value: "พุมเรียง ไชยา สุราษฎร์ธานี 84110",
      label: "พุมเรียง ไชยา สุราษฎร์ธานี 84110",
    },
    {
      value: "เลม็ด ไชยา สุราษฎร์ธานี 84110",
      label: "เลม็ด ไชยา สุราษฎร์ธานี 84110",
    },
    {
      value: "เวียง ไชยา สุราษฎร์ธานี 84110",
      label: "เวียง ไชยา สุราษฎร์ธานี 84110",
    },
    {
      value: "โมถ่าย ไชยา สุราษฎร์ธานี 84110",
      label: "โมถ่าย ไชยา สุราษฎร์ธานี 84110",
    },
    {
      value: "กรงปินัง กรงปินัง ยะลา 95000",
      label: "กรงปินัง กรงปินัง ยะลา 95000",
    },
    { value: "ปุโรง กรงปินัง ยะลา 95000", label: "ปุโรง กรงปินัง ยะลา 95000" },
    { value: "สะเอะ กรงปินัง ยะลา 95000", label: "สะเอะ กรงปินัง ยะลา 95000" },
    {
      value: "ห้วยกระทิง กรงปินัง ยะลา 95000",
      label: "ห้วยกระทิง กรงปินัง ยะลา 95000",
    },
    { value: "กาบัง กาบัง ยะลา 95120", label: "กาบัง กาบัง ยะลา 95120" },
    { value: "บาละ กาบัง ยะลา 95120", label: "บาละ กาบัง ยะลา 95120" },
    { value: "คีรีเขต ธารโต ยะลา 95150", label: "คีรีเขต ธารโต ยะลา 95150" },
    { value: "ธารโต ธารโต ยะลา 95150", label: "ธารโต ธารโต ยะลา 95150" },
    { value: "บ้านแหร ธารโต ยะลา 95150", label: "บ้านแหร ธารโต ยะลา 95150" },
    { value: "แม่หวาด ธารโต ยะลา 95170", label: "แม่หวาด ธารโต ยะลา 95170" },
    {
      value: "ตลิ่งชัน บันนังสตา ยะลา 95130",
      label: "ตลิ่งชัน บันนังสตา ยะลา 95130",
    },
    {
      value: "ตาเนาะปูเต๊ะ บันนังสตา ยะลา 95130",
      label: "ตาเนาะปูเต๊ะ บันนังสตา ยะลา 95130",
    },
    {
      value: "ถ้ำทะลุ บันนังสตา ยะลา 95130",
      label: "ถ้ำทะลุ บันนังสตา ยะลา 95130",
    },
    {
      value: "บันนังสตา บันนังสตา ยะลา 95130",
      label: "บันนังสตา บันนังสตา ยะลา 95130",
    },
    {
      value: "บาเจาะ บันนังสตา ยะลา 95130",
      label: "บาเจาะ บันนังสตา ยะลา 95130",
    },
    {
      value: "เขื่อนบางลาง บันนังสตา ยะลา 95130",
      label: "เขื่อนบางลาง บันนังสตา ยะลา 95130",
    },
    { value: "กาตอง ยะหา ยะลา 95120", label: "กาตอง ยะหา ยะลา 95120" },
    { value: "ตาชี ยะหา ยะลา 95120", label: "ตาชี ยะหา ยะลา 95120" },
    { value: "บาโงยซิแน ยะหา ยะลา 95120", label: "บาโงยซิแน ยะหา ยะลา 95120" },
    { value: "บาโร๊ะ ยะหา ยะลา 95120", label: "บาโร๊ะ ยะหา ยะลา 95120" },
    { value: "ปะแต ยะหา ยะลา 95120", label: "ปะแต ยะหา ยะลา 95120" },
    { value: "ยะหา ยะหา ยะลา 95120", label: "ยะหา ยะหา ยะลา 95120" },
    { value: "ละแอ ยะหา ยะลา 95120", label: "ละแอ ยะหา ยะลา 95120" },
    {
      value: "กอตอตือร๊ะ รามัน ยะลา 95140",
      label: "กอตอตือร๊ะ รามัน ยะลา 95140",
    },
    {
      value: "กายูบอเกาะ รามัน ยะลา 95140",
      label: "กายูบอเกาะ รามัน ยะลา 95140",
    },
    { value: "กาลอ รามัน ยะลา 95140", label: "กาลอ รามัน ยะลา 95140" },
    { value: "กาลูปัง รามัน ยะลา 95140", label: "กาลูปัง รามัน ยะลา 95140" },
    { value: "จะกว๊ะ รามัน ยะลา 95140", label: "จะกว๊ะ รามัน ยะลา 95140" },
    {
      value: "ตะโล๊ะหะลอ รามัน ยะลา 95140",
      label: "ตะโล๊ะหะลอ รามัน ยะลา 95140",
    },
    { value: "ท่าธง รามัน ยะลา 95140", label: "ท่าธง รามัน ยะลา 95140" },
    { value: "บาลอ รามัน ยะลา 95140", label: "บาลอ รามัน ยะลา 95140" },
    { value: "บาโงย รามัน ยะลา 95140", label: "บาโงย รามัน ยะลา 95140" },
    { value: "บือมัง รามัน ยะลา 95140", label: "บือมัง รามัน ยะลา 95140" },
    { value: "ยะต๊ะ รามัน ยะลา 95140", label: "ยะต๊ะ รามัน ยะลา 95140" },
    { value: "วังพญา รามัน ยะลา 95140", label: "วังพญา รามัน ยะลา 95140" },
    { value: "อาซ่อง รามัน ยะลา 95140", label: "อาซ่อง รามัน ยะลา 95140" },
    { value: "เกะรอ รามัน ยะลา 95140", label: "เกะรอ รามัน ยะลา 95140" },
    { value: "เนินงาม รามัน ยะลา 95140", label: "เนินงาม รามัน ยะลา 95140" },
    { value: "โกตาบารู รามัน ยะลา 95140", label: "โกตาบารู รามัน ยะลา 95140" },
    {
      value: "ตาเนาะแมเราะ เบตง ยะลา 95110",
      label: "ตาเนาะแมเราะ เบตง ยะลา 95110",
    },
    {
      value: "ธารน้ำทิพย์ เบตง ยะลา 95110",
      label: "ธารน้ำทิพย์ เบตง ยะลา 95110",
    },
    { value: "ยะรม เบตง ยะลา 95110", label: "ยะรม เบตง ยะลา 95110" },
    {
      value: "อัยเยอร์เวง เบตง ยะลา 95110",
      label: "อัยเยอร์เวง เบตง ยะลา 95110",
    },
    { value: "เบตง เบตง ยะลา 95110", label: "เบตง เบตง ยะลา 95110" },
    { value: "ตาเซะ เมืองยะลา ยะลา 95000", label: "ตาเซะ เมืองยะลา ยะลา 95000" },
    {
      value: "ท่าสาป เมืองยะลา ยะลา 95000",
      label: "ท่าสาป เมืองยะลา ยะลา 95000",
    },
    {
      value: "บันนังสาเรง เมืองยะลา ยะลา 95000",
      label: "บันนังสาเรง เมืองยะลา ยะลา 95000",
    },
    { value: "บุดี เมืองยะลา ยะลา 95000", label: "บุดี เมืองยะลา ยะลา 95000" },
    { value: "พร่อน เมืองยะลา ยะลา 95160", label: "พร่อน เมืองยะลา ยะลา 95160" },
    { value: "ยะลา เมืองยะลา ยะลา 95000", label: "ยะลา เมืองยะลา ยะลา 95000" },
    { value: "ยุโป เมืองยะลา ยะลา 95000", label: "ยุโป เมืองยะลา ยะลา 95000" },
    {
      value: "ลำพะยา เมืองยะลา ยะลา 95160",
      label: "ลำพะยา เมืองยะลา ยะลา 95160",
    },
    {
      value: "ลำใหม่ เมืองยะลา ยะลา 95160",
      label: "ลำใหม่ เมืองยะลา ยะลา 95160",
    },
    { value: "ลิดล เมืองยะลา ยะลา 95160", label: "ลิดล เมืองยะลา ยะลา 95160" },
    { value: "สะเตง เมืองยะลา ยะลา 95000", label: "สะเตง เมืองยะลา ยะลา 95000" },
    {
      value: "สะเตงนอก เมืองยะลา ยะลา 95000",
      label: "สะเตงนอก เมืองยะลา ยะลา 95000",
    },
    {
      value: "หน้าถ้ำ เมืองยะลา ยะลา 95000",
      label: "หน้าถ้ำ เมืองยะลา ยะลา 95000",
    },
    {
      value: "เปาะเส้ง เมืองยะลา ยะลา 95000",
      label: "เปาะเส้ง เมืองยะลา ยะลา 95000",
    },
  ];
  