import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { useState } from "react";
import { API } from "../../api/api.main";
import qs, { stringify } from "qs";
import { useEffect } from "react";

const DataDetail = ({ title = "", detail = "", style = "" }) => {
  return (
    <div>
      <div>{title}</div>
      <div className={style}>{detail}</div>
    </div>
  );
};

const Requestdetail = () => {
  const [allcontactdata, setAllcontactdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const location = useLocation();
  const params = useParams();
  const searchParams = new URLSearchParams(location.search);
  // console.log("title", location.search.split("&")[0].slice(1).split("=")[1]);
  // console.log("id", location.search.split("&")[1].split("=")[1]);

  // console.log("params", params)

  const CONTACT_CATEGORY = {
    sell: "ฝากขายบ้าน",
    find: "ฝากหาบ้าน",
    advice: "แนะนำ ติชม",
    ads: "ติดต่อลงโฆษณา",
    etc: "ติดต่อสอบถามเรื่องอื่น ๆ",
  };

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    console.log("location", location.search.split("=")[1]);

    switch (location.search.split("=")[1]) {
      case "contactus":
        try {
          setLoading(true);
          const { data } = await API.get("/contactus/" + params.id);
          setAllcontactdata(data?.contacts);
          //console.log("data",data?.contacts)
        } catch (error) {
          console.log("error", error);
          setError(error);
        } finally {
          setLoading(false);
        }
        break;
      case "buyandsells":
        try {
          setLoading(true);
          const { data } = await API.get("/buyandsells/" + params.id);
          console.log("data", data);
          setAllcontactdata(data?.buyandsells);
        } catch (error) {
          console.log("error", error);
          setError(error);
        } finally {
          setLoading(false);
        }
        break;
        case "appointments":
        try {
          setLoading(true);
          const { data } = await API.get("/appointments/"+ params.id);
          console.log("data", data);
          setAllcontactdata(data?.appointments);
        } catch (error) {
          console.log("error", error);
          setError(error);
        } finally {
          setLoading(false);
        }
        break;
      default:
        break;
    }

    // try {
    //   setLoading(true);
    //   const { data } = await API.get(
    //     "/contactus/" + params.id
    //   );
    //   setAllcontactdata(data?.contacts);
    //   //console.log("data",data?.contacts)
    // } catch (error) {
    //   console.log("error", error);
    //   setError(error);
    // } finally {
    //   setLoading(false);
    // }
  };
  if (loading) return <div>Loading...</div>;

  return (
    <div className=" pl-[40px] pt-[28px] flex flex-col">
      <div className="text-[28px] text-era-red font-semibold mb-[21px]">
        รายละเอียด
      </div>
      {location.search.split("=")[1] === "contactus" && (
        <>
          <div className="flex space-x-[32px] mb-[20px]">
            <DataDetail
              title="ชื่อ-นามสกุล"
              detail={allcontactdata?.contact_name}
              style="w-[534px] h-[52px] px-[24px] rounded-[8.4px] flex items-center border-solid border-[1px] border-black/[0.29]"
            />
            <DataDetail
              title="อีเมล"
              detail={allcontactdata?.contact_email}
              style="w-[534px] h-[52px] px-[24px] rounded-[8.4px] flex items-center border-solid border-[1px] border-black/[0.29]"
            />
          </div>
          <div className="flex space-x-[32px] mb-[20px]">
            <DataDetail
              title="เบอร์โทร"
              detail={allcontactdata?.contact_telNum}
              style="w-[534px] h-[52px] px-[24px] rounded-[8.4px] flex items-center border-solid border-[1px] border-black/[0.29]"
            />
            <DataDetail
              title="หัวข้อที่ติดต่อ"
              detail={CONTACT_CATEGORY[allcontactdata?.contact_title]}
              style="w-[534px] h-[52px] px-[24px] rounded-[8.4px] flex items-center border-solid border-[1px] border-black/[0.29]"
            />
          </div>
          <DataDetail
            title="รายละเอียด"
            detail={allcontactdata?.contact_detail}
            style="w-[1098px] h-[100px] px-[11px] py-[12px] rounded-[8.4px] flex border-solid border-[1px] border-black/[0.29] mb-[25px]"
          />
        </>
      )}
      {location.search.split("=")[1] === "buyandsells" && (
        <>
          <div className="flex space-x-[32px] mb-[20px]">
            <DataDetail
              title="ชื่อ-นามสกุล"
              detail={
                allcontactdata?.buysell_name + allcontactdata?.buysell_lastname
              }
              style="w-[534px] h-[52px] px-[24px] rounded-[8.4px] flex items-center border-solid border-[1px] border-black/[0.29]"
            />
            <DataDetail
              title="อีเมล"
              detail={allcontactdata?.buysell_email}
              style="w-[534px] h-[52px] px-[24px] rounded-[8.4px] flex items-center border-solid border-[1px] border-black/[0.29]"
            />
          </div>
          <div className="flex space-x-[32px] mb-[20px]">
            <DataDetail
              title="เบอร์โทร"
              detail={allcontactdata?.buysell_telNum}
              style="w-[534px] h-[52px] px-[24px] rounded-[8.4px] flex items-center border-solid border-[1px] border-black/[0.29]"
            />
            <DataDetail
              title="ประเภททรัพย์"
              detail={allcontactdata?.buysell_type}
              style="w-[534px] h-[52px] px-[24px] rounded-[8.4px] flex items-center border-solid border-[1px] border-black/[0.29]"
            />
          </div>
          <DataDetail
            title="สถานที่ตั้ง"
            detail={allcontactdata?.buysell_address}
            style="w-[1098px] h-[100px] px-[11px] py-[12px] rounded-[8.4px] flex border-solid border-[1px] border-black/[0.29] mb-[25px]"
          />
          <DataDetail
            title="Link จาก Google map"
            detail={allcontactdata?.buysell_location}
            style="w-[1098px] h-[51px] px-[24px] rounded-[8.4px] flex items-center border-solid border-[1px] border-black/[0.29]"
          />
        </>
      )}
      {location.search.split("=")[1] === "appointments" && (
        <>
          <div className="flex space-x-[32px] mb-[20px]">
            <DataDetail
              title="ชื่อ-นามสกุล"
              detail={allcontactdata?.appointment_name}
              style="w-[534px] h-[52px] px-[24px] rounded-[8.4px] flex items-center border-solid border-[1px] border-black/[0.29]"
            />
            <DataDetail
              title="ข้อมูลทรัพย์"
              detail={allcontactdata?.appointment_info}
              style="w-[534px] h-[52px] px-[24px] rounded-[8.4px] flex items-center border-solid border-[1px] border-black/[0.29]"
            />
          </div>
          <div className="flex space-x-[32px] mb-[20px]">
          <DataDetail
              title="อีเมล"
              detail={allcontactdata?.appointment_email}
              style="w-[534px] h-[52px] px-[24px] rounded-[8.4px] flex items-center border-solid border-[1px] border-black/[0.29]"
            />
            <DataDetail
              title="เบอร์โทร"
              detail={allcontactdata?.appointment_tel}
              style="w-[534px] h-[52px] px-[24px] rounded-[8.4px] flex items-center border-solid border-[1px] border-black/[0.29]"
            />
          </div>
          <div className="flex space-x-[32px] mb-[20px]">
          <DataDetail
              title="วันที่นัดเข้าชม"
              detail={allcontactdata?.appointment_date}
              style="w-[534px] h-[52px] px-[24px] rounded-[8.4px] flex items-center border-solid border-[1px] border-black/[0.29]"
            />
            <DataDetail
              title="เวลาที่นัดเข้าชม"
              detail={allcontactdata?.appointment_time}
              style="w-[534px] h-[52px] px-[24px] rounded-[8.4px] flex items-center border-solid border-[1px] border-black/[0.29]"
            />
          </div>
          <DataDetail
            title="รายละเอียด"
            detail={allcontactdata?.appointment_datail}
            style="w-[1098px] h-[100px] px-[11px] py-[12px] rounded-[8.4px] flex border-solid border-[1px] border-black/[0.29] mb-[25px]"
          />
        </>
      )}
    </div>
  );
};

export default Requestdetail;
