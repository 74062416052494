import React, { useEffect, useState } from "react";
import InputText from "../../component/textinput";
import UploadImage from "../../component/uploadImage";
import { ImgFromFile,IMG_URL } from "../../utils/util.con";
import Namecon from "../../utils/Name.con";
import { uploadData } from "aws-amplify/storage";
import { API } from "../../api/api.main";
import { X } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const checkFormError = (form) => {

  return {
    name: !form.name,
    Link:!form.Link,
  };
};

const initError = {
  name: false,
  Link: false,
};

const Editbanner = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formError, setFormError] = useState(initError);
  const [allInfo, setAllInfo] = useState({
    name: "",
    Link: "",
    pic: "",
  });
  const [cover, setCover] = useState("");
  const [displayCover, setDisplayCover] = useState("");
  const navigate = useNavigate();
  const params = useParams();

  const addCover = async (e) => {
    console.log("e.target.files", e.target.files);
    let displayImg = "";
    if(e.target.files.length>0){
      setCover(e.target.files[0]);
      displayImg = await ImgFromFile(e.target.files[0]);
      setDisplayCover(displayImg);
    }else{
      setCover(cover);
      setDisplayCover(displayCover);
    }
  };
  console.log("allInfo", allInfo);
  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      setLoading(true);
      const { data } = await API.get("/banners/" + params.id);
      console.log("data", data.banners);
      setAllInfo({
        name: data?.banners?.banner_name,
        Link: data?.banners?.banner_link,
        pic: data?.banners?.banner_pic,
      });
      setCover(data?.banners?.banner_pic);
      setDisplayCover(IMG_URL(data?.banners?.banner_pic));
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };
  console.log("cover", cover);
  console.log("displayCover", displayCover);

  const submitclick = async () => {
    let CoverName = "";

    const thisFormError = checkFormError(allInfo);
    console.log("thisFormError",thisFormError)
    const isFormError = Object.entries(thisFormError)?.some(
      ([_, value]) => value === true
    );

    if (isFormError ) {
      console.log("checkTypePrice",thisFormError)
      setFormError(thisFormError);
      Swal.fire({
        title: "โปรดกรอกข้อมูลให้ครบถ้วน",
        icon: "warning",
      });
      return;
    } else {
      setFormError(initError);
    }

    if (allInfo.name !== "" && allInfo.Link !== "" && cover !== "") {
      CoverName = Namecon(cover);
      if (cover !== "" && cover?.type !== undefined) {
        CoverName = Namecon(cover);
        const result = await uploadData({
          key: CoverName,
          data: cover,
        }).result;
        console.log("result", result);
      } else {
        CoverName = cover;
      }

      await API.put("/banners/"+params.id,{
        banner_pic:CoverName,
        banner_link:allInfo.Link,
        banner_name:allInfo.name
      })
      .then((res)=>{
        Swal.fire({
          title: "เพิ่มข้อมูลข้อมูลเรียบร้อย",
          icon: "success",
        }).then(()=>{
          navigate("/content/banner");
        });
        console.log("res",res)
      }).catch((error)=>{
        Swal.fire({
          title: "บันทึกข้อมูลผิดพลาด",
          icon: "error",
        });
        console.log(error)
      })
    } else {
      Swal.fire({
        title: "กรุณากรอกให้ครบ",
        icon: "alert",
      });
      console.log("not complete");
    }
  };

  return (
    <div className=" pl-[40px] pr-[90px] pt-[28px] pb-[25px] flex flex-col just self-center">
      <div className="text-[28px] text-era-red font-semibold mb-[21px]">
        Banner
      </div>
      <div className="w-full flex space-x-[30px] mb-[16px]">
      <InputText
          name="ชื่อ"
          placeholder="ระบุ"
          allInfo={Object.entries(allInfo)[0]}
          setAllInfo={setAllInfo}
          error={formError.name}
        />
        <InputText
          name="Link"
          placeholder="ระบุ"
          allInfo={Object.entries(allInfo)[1]}
          setAllInfo={setAllInfo}
          error={formError.Link}
        />
      </div>
      <div className="max-w-[1210px]">
      <UploadImage
        name="Banner Home Desktop (1210x480 px)"
        cover={cover}
        addCover={addCover}
        displayImg={displayCover}
        multiple={false}
        displayStyle="w-ful h-[480px] rounded-[10px]"
        framstyle="w-full h-[480px] flex justify-center items-center border-[1px] border-solid border-[#000]/[0.29] rounded-[10px]"
      />
       {cover !== "" && (
              <div className="flex mt-[10px] space-x-[7px]">
                <button onClick={()=>{
                  setCover('')
                }}>
                  <X className="w-[24px] h-[24px]" />
                </button>
                <div className="w-full text-base text-[#F0BF50] font-normal">
                {cover?.name ? cover?.name : cover}
                </div>
              </div>
            )}
      </div>
      <div className="w-full flex justify-center">
        <button onClick={submitclick} className="w-[189px] h-[50px] mt-[20px] flex justify-center items-center text-xl text-white font-semibold rounded-[40px] bg-era-red duration-300 hover:bg-[#E53648]">
          บันทึก
        </button>
      </div>
    </div>
  );
};

export default Editbanner;
