import React, { useEffect, useState } from "react";
import COVERIMAGE from "../../Image/timeline_20180320_203316 1.png";
import { useNavigate, useParams } from "react-router-dom";
import { API, S3_URL } from "../../api/api.main";
import { CONVERT_TYPE, ConverseDate, Postdate } from "../../utils/util.con";
import Swal from "sweetalert2";


const BannerCard = ({ ID ,letload}) => {
    const param = useParams();
  const [alldata, setAllData] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const navto = useNavigate()

  useEffect(() => {
    // API.get('')
    loadData();
  }, []);

  const loadData = async () => {
    try {
      setLoading(true);
      const { data } = await API.get("/banners/" + ID);
      setAllData(data?.banners);
    } catch (error) {
      console.log("error", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div onClick={()=>{navto(`/content/banner/edit/${ID}`)}} className="w-[245px] p-[8px] rounded-[20px] space-y-[15px] bg-white shadow-productCard">
    <img
      src={S3_URL + "/" + alldata?.banner_pic}
      className="w-[230px] h-[158px] rounded-[10px] mb-[8px]"
    />
    <div className="w-full h-[16px] text-end text-sm text-[#716E6E] font-light mb-[20px]">
      {/* Posted on <ConverseDate /> */}
      <Postdate date={ConverseDate(alldata?.createdAt)}/>
    </div>
    <div className="w-full h-[21px] text-base text-[#0B3279] font-semibold truncate mb-[24px]">
      {alldata?.banner_name}
    </div>
    {/* <div className="w-full h-[16px] text-start text-sm font-light">
    {CONVERT_TYPE[alldata?.article_type]}
    </div> */}
    <button onClick={async (e)=>{
      e.stopPropagation();
      console.log("ID",ID)
      Swal.fire({
        title: "ท่านต้องการลบบใช่หรือไม่",
        showDenyButton: true,
        confirmButtonText: "ใช่",
        denyButtonText: `ไม่`,
      }).then( async (result) => {
        if (result.isConfirmed) {
        try {
          setLoading(true);
          await API.delete("/banners/" + ID);
        } catch (error) {
          console.log("product error", error);
          setError(error);
        } finally {
          setLoading(false);
          console.log("ID",ID)
          letload();
          // loadData
        }}
      })
    }} className="flex justify-center items-center w-full h-[46px] rounded-[10px] bg-era-red">
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.11193 8.49252C6.55719 8.5033 6.90935 8.87068 6.8985 9.31308L6.09229 9.29357L5.28608 9.27405C5.29692 8.83165 5.66667 8.48175 6.11193 8.49252ZM6.09229 9.29357C5.28608 9.27405 5.28608 9.27405 5.28608 9.27405L5.285 9.31961L5.28209 9.44909C5.27964 9.56165 5.27626 9.725 5.27249 9.92874C5.26497 10.3361 5.25595 10.9054 5.24993 11.5533C5.23793 12.8456 5.23774 14.4639 5.28642 15.7343C5.33967 17.1239 5.48304 18.9226 5.60134 20.2619C5.73493 21.7745 7.01357 22.9154 8.5294 22.9154H16.4732C17.989 22.9154 19.2677 21.7745 19.4013 20.2619C19.5196 18.9226 19.6629 17.1239 19.7162 15.7343C19.7649 14.4639 19.7647 12.8456 19.7527 11.5533C19.7467 10.9054 19.7376 10.3361 19.7301 9.92874C19.7263 9.725 19.723 9.56165 19.7205 9.44909L19.7176 9.31961L19.7165 9.27489C19.7057 8.83249 19.3359 8.48175 18.8907 8.49252C18.4454 8.5033 18.0933 8.87068 18.1041 9.31308L18.6179 9.30065C18.1041 9.31308 18.1041 9.31308 18.1041 9.31308L18.1051 9.35655L18.108 9.48375C18.1104 9.59473 18.1138 9.75632 18.1175 9.95814C18.1249 10.3618 18.1339 10.9261 18.1398 11.5681C18.1518 12.8557 18.1516 14.4426 18.1044 15.6733C18.0528 17.0207 17.9125 18.786 17.7945 20.1218C17.7351 20.7946 17.1653 21.3128 16.4732 21.3128H8.5294C7.83733 21.3128 7.26749 20.7946 7.20807 20.1218C7.09008 18.786 6.94979 17.0207 6.89816 15.6733C6.85099 14.4426 6.8508 12.8557 6.86276 11.5681C6.86873 10.9261 6.87767 10.3618 6.88513 9.95814C6.88885 9.75632 6.8922 9.59473 6.89462 9.48375L6.89747 9.35655L6.8985 9.31308L6.09229 9.29357Z"
          fill="#fff"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.16797 6.08844C4.16797 5.64591 4.52903 5.28716 4.97442 5.28716H20.0282C20.4736 5.28716 20.8346 5.64591 20.8346 6.08844C20.8346 6.53098 20.4736 6.88972 20.0282 6.88972H4.97442C4.52903 6.88972 4.16797 6.53098 4.16797 6.08844Z"
          fill="#fff"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.60187 3.63746C9.11578 2.68006 10.1189 2.08203 11.211 2.08203H13.7916C14.8837 2.08203 15.8868 2.68006 16.4007 3.63746L17.514 5.71137C17.7235 6.10184 17.5749 6.5872 17.1819 6.79545C16.7889 7.00371 16.3004 6.85599 16.0908 6.46552L14.9776 4.39161C14.744 3.95643 14.288 3.6846 13.7916 3.6846H11.211C10.7146 3.6846 10.2586 3.95643 10.025 4.39161L8.9118 6.46552C8.70221 6.85599 8.21371 7.00371 7.82072 6.79545C7.42773 6.5872 7.27906 6.10184 7.48865 5.71137L8.60187 3.63746Z"
          fill="#fff"
        />
      </svg>
    </button>
  </div>
  )
}

export default BannerCard