import { Image } from "lucide-react";
import React, { useId, useState } from "react";
import { twMerge } from "tailwind-merge";
import classNames from "classnames";

const multiPic = ({ multiple, displayImg, displayStyle }) => {
  if (multiple) {
    return <img src={displayImg[0]} className={displayStyle} />;
  } else {
    return <img src={displayImg} className={displayStyle} />;
  }
};

const UploadImage = ({
  name = "",
  cover = "",
  reddot = true,
  addCover,
  displayImg,
  displayStyle = "w-[270px] h-[248px] rounded-[10px]",
  framstyle = "w-[270px] h-[248px] flex justify-center items-center border-[1px] border-solid border-[#000]/[0.29] rounded-[10px]",
  multiple = true,
  error = false
}) => {
  const id = useId()

  return (
    <div className="flex flex-col">
      <div className="flex mb-[10px] text-base font-medium">
        <div className="">{name}</div>
        {reddot && <div className="text-[#C41F30]">*</div>}
      </div>
      <label className={twMerge(classNames(framstyle,
        error && "border-[2px] border-red-500"
        ))}>
        <input
          id={id}
          onChange={addCover}
          type="file"
          className="hidden"
          multiple={multiple}
          hidden
        ></input>
        <div className="flex space-x-[13px] self-center">
          {cover === "" ? (
            <div
              className="flex space-x-[13px] self-center"
            >
              <Image className="w-[24px] h-[24px] stroke-[#A0A0A0]" />
              <div className="text-base font-light text-[#A0A0A0]">
                อัปโหลดรูปภาพ
              </div>
            </div>
          ) : (
            multiPic({ multiple, displayImg, displayStyle })
          )}
        </div>
      </label>
    </div>
  );
};

export default UploadImage;
