import React from "react";
import CategoryProduct from "../../component/button/categoryProduct";

const RequestHome = () => {
  return (
    <div className=" flex flex-col items-center pt-[178px]">
      <div className="flex space-x-[19px] mb-[14px]">
        <CategoryProduct
          label="ติดต่อสอบถาม"
          navto="/request/list?title=contactus"
          icon={
            <svg
              width="61"
              height="60"
              viewBox="0 0 61 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="w-[60px] h-[60px] stroke-era-red self-center m-[10px]"
            >
              <path
                d="M14.4269 19.7194C13.7107 15.0019 17.0369 10.7644 22.1182 9.21193C23.0199 8.93624 23.9928 9.01471 24.8387 9.43137C25.6845 9.84803 26.3397 10.5715 26.6707 11.4544L28.3019 15.8044C28.5645 16.5042 28.612 17.2665 28.4383 17.9935C28.2646 18.7205 27.8776 19.379 27.3269 19.8844L22.4744 24.3282C22.2353 24.5477 22.057 24.8254 21.9571 25.1343C21.8572 25.4432 21.8391 25.7727 21.9044 26.0907L21.9494 26.2857L22.0657 26.7732C22.6692 29.1418 23.5887 31.4185 24.7994 33.5419C26.1184 35.7983 27.7551 37.8531 29.6594 39.6432L29.8094 39.7782C30.0517 39.9932 30.3455 40.1418 30.6623 40.2095C30.9791 40.2772 31.308 40.2617 31.6169 40.1644L37.8907 38.1882C38.6037 37.9643 39.3673 37.9586 40.0836 38.1717C40.7998 38.3849 41.4361 38.8072 41.9107 39.3844L44.8807 42.9882C46.1182 44.4882 45.9682 46.6932 44.547 48.0169C40.6582 51.6432 35.3107 52.3857 31.5907 49.3969C27.0308 45.7188 23.1868 41.2328 20.2507 36.1632C17.2908 31.0974 15.3174 25.5185 14.4269 19.7194ZM25.8382 26.3382L29.8582 22.6482C30.9601 21.6377 31.7348 20.3209 32.0829 18.867C32.4311 17.413 32.3367 15.8881 31.8119 14.4882L30.1844 10.1382C29.5182 8.36161 28.1997 6.9059 26.4975 6.0677C24.7953 5.22949 22.8376 5.07186 21.0232 5.62693C14.7119 7.55818 9.6457 13.2169 10.7182 20.2857C11.4682 25.2207 13.1969 31.4982 17.0107 38.0532C20.1784 43.5196 24.3249 48.3563 29.2432 52.3219C34.8232 56.8032 42.2707 55.2732 47.1082 50.7657C48.4924 49.4769 49.3326 47.7084 49.4573 45.8213C49.5821 43.9341 48.9819 42.0705 47.7794 40.6107L44.8095 37.0032C43.8593 35.85 42.5864 35.0069 41.154 34.5819C39.7215 34.157 38.1947 34.1696 36.7694 34.6182L31.5607 36.2569C30.2158 34.8703 29.0378 33.3311 28.0507 31.6707C27.0972 29.992 26.3541 28.2023 25.8382 26.3419V26.3382Z"
                fill="#C41F30"
              />
            </svg>
          }
        />
        <CategoryProduct
          label="ฝากขายทรัพย์"
          navto="/request/list?title=buyandsells"
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-[60px] h-[60px] stroke-era-red self-center m-[10px]"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
              />
            </svg>
          }
        />
      </div>
      <div className="flex space-x-[19px]">
        <CategoryProduct
          label="นัดหมายเข้าชมทรัพย์"
          navto="/request/list?title=appointments"
          icon={
            <svg
              width="60"
              height="60"
              viewBox="0 0 60 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="w-[60px] h-[60px] stroke-era-red self-center m-[10px]"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23.4066 8.84615C19.0065 8.84615 15.4396 12.4131 15.4396 16.8132C15.4396 21.2133 19.0065 24.7802 23.4066 24.7802C27.8067 24.7802 31.3736 21.2133 31.3736 16.8132C31.3736 12.4131 27.8067 8.84615 23.4066 8.84615ZM11.5934 16.8132C11.5934 10.2889 16.8823 5 23.4066 5C29.9308 5 35.2198 10.2889 35.2198 16.8132C35.2198 23.3374 29.9308 28.6264 23.4066 28.6264C16.8823 28.6264 11.5934 23.3374 11.5934 16.8132Z"
                fill="#C41F30"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18.5288 38.5165C13.1812 38.5165 8.84615 42.8516 8.84615 48.1992C8.84615 48.4653 8.89205 48.6491 8.93815 48.7532C8.97529 48.837 9.00265 48.8529 9.01478 48.86C10.3338 49.6352 14.1283 51.1539 23.4066 51.1539C32.6848 51.1539 36.4782 49.6359 37.7973 48.8606C37.8094 48.8536 37.8379 48.837 37.875 48.7532C37.9211 48.6491 37.967 48.4653 37.967 48.1992C37.967 42.8516 33.632 38.5165 28.2844 38.5165H18.5288ZM5 48.1992C5 40.7274 11.0571 34.6703 18.5288 34.6703H28.2844C35.7561 34.6703 41.8132 40.7274 41.8132 48.1992C41.8132 49.4861 41.3782 51.2173 39.7461 52.1765C37.5951 53.4407 33.0222 55 23.4066 55C13.791 55 9.21805 53.4407 7.06708 52.1765C5.43494 51.2173 5 49.4861 5 48.1992Z"
                fill="#C41F30"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M38.8578 9.71803C39.1349 8.69273 40.1907 8.0862 41.216 8.36331C45.4581 9.5098 48.4066 13.6987 48.4066 18.4615C48.4066 23.4941 44.9573 28.0315 40.1195 28.6126C39.065 28.7393 38.1074 27.9872 37.9808 26.9326C37.8541 25.8781 38.6063 24.9206 39.6608 24.7939C42.2405 24.4841 44.5604 21.8935 44.5604 18.4615C44.5604 15.2053 42.5639 12.7118 40.2125 12.0762C39.1872 11.7991 38.5807 10.7433 38.8578 9.71803Z"
                fill="#C41F30"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M44.596 36.2253C44.7993 35.1828 45.8092 34.5026 46.8516 34.7059C51.5843 35.6289 55 39.7751 55 44.5969V45.9337C55 47.1173 54.6449 48.8029 53.101 49.7898C52.0616 50.4543 50.4495 51.1622 47.9638 51.6651C46.9228 51.8757 45.9081 51.2026 45.6975 50.1616C45.4869 49.1206 46.1601 48.1059 47.2011 47.8953C49.3217 47.4663 50.4678 46.9082 51.0295 46.5492L51.0341 46.5448C51.0383 46.5396 51.0532 46.5193 51.0719 46.4739C51.1127 46.3744 51.1538 46.1961 51.1538 45.9337V44.5969C51.1538 41.6154 49.0418 39.0516 46.1154 38.4809C45.073 38.2776 44.3927 37.2677 44.596 36.2253Z"
                fill="#C41F30"
              />
            </svg>
          }
        />
      </div>
    </div>
  );
};

export default RequestHome;
