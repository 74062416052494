import axios from "axios";

// const env = process?.env?.NEXT_PUBLIC_NODE_ENV ?? "production";
const env = "dd";

// const url =
//   env === "development"
//     ? "https://pjj6vo6pdd.execute-api.ap-southeast-1.amazonaws.com/dev/api"
//     : "https://pjj6vo6pdd.execute-api.ap-southeast-1.amazonaws.com/dev/api";

    const url ="https://pjj6vo6pdd.execute-api.ap-southeast-1.amazonaws.com/dev/api"
    // const url ="http://localhost:1337/api"
    
export const S3_URL = "https://d8ckp0hkq9wi4.cloudfront.net/public";
//"http://localhost:1337/api"
//"https://pjj6vo6pdd.execute-api.ap-southeast-1.amazonaws.com/dev/api"
export const API = axios.create({
  baseURL: url,
});
// requestInterceptors = (configAxios) => {
//   const user = localStorage.getItem("admin");

//   const access_token = JSON.parse(user)?.state?.admin?.access_token_admin || "";

//   if (access_token) {
//     configAxios.headers = {
//       ...configAxios.headers,
//       Authorization: `Bearer ${access_token}`,
//     };
//   }
//   return configAxios;
// };

// API.interceptors.request.use(requestInterceptors);


