import React, { useEffect, useState } from "react";
import BlogCard from "../../component/card/blogCard";
import { API } from "../../api/api.main";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import qs from "qs";
import PaginationMain from "../../component/pagination/paginationMain";

const FILTERSTYLE =
  "flex justify-center items-center text-xl font-semibold rounded-[5px] px-[15px] py-[4px] hover:text-era-red hover:bg-era-red/[0.1] transition-all duration-300";

const Bloglist = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [alldata, setAllData] = useState([]);
  const [reqtitle, setReqTitle] = useState("");
  const [articleType, setArticleType] = useState("");
  const [skip, setSkip] = useState(0);
  const [moreload, setMoreload] = useState(20);
  const [count, setCount] = useState("");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const paramsFilter = {
    skip: skip,
    limit: moreload,
    sort: { updatedAt : -1 },
    filter: {
      article_type: { $regex: articleType, $options: "i" },
    },
  };

  // useEffect(()=>{
  //   let containerWidth = document.getElementById("CardContainer").getBoundingClientRect().width;
  //   setMoreload(Math.floor(containerWidth/432)*4)
  //   console.log("containerWidth",Math.floor(containerWidth/432))
  //   console.log("moreload",moreload);
  // },[])

  // console.log("moreload",moreload);

  useEffect(() => {
    setSkip(0)
    changetype()
  }, [articleType,moreload]);

  useEffect(()=>{
    changetype()
  },[skip,moreload])


  const pageSelect = (number) => {
    setSkip((number - 1) * moreload);
  };

  const mainfilter = {
    skip: skip,
    limit: moreload,
    sort: { updatedAt : -1 },
  };

  const loadData = async () => {
    try {
      setLoading(true);
      const { data } = await API.get(`/articles?${qs.stringify(mainfilter)}`);
      setAllData(data?.articles);
      setCount(data?.count);
    } catch (error) {
      console.log("product error", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  const changetype = async () => {
    // console.log("paramsFilter",paramsFilter)
    try {
      setLoading(true);
      const { data } = await API.get(`/articles?${qs.stringify(paramsFilter)}`);
      // console.log("changetype",data);
      setAllData(data?.articles);
      setCount(data?.count);
    } catch (error) {
      console.log("product error", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };
// console.log("skip",skip)

  return (
    <div className=" pl-[30px] pt-[28px] pb-[30px]">
      <div className="flex items-end w-full h-[56px] mb-[28px]">
        <div className="w-[56px] h-[36px] text-3xl text-era-red font-semibold mr-[43px]">
          Blog
        </div>

        <div className="flex space-x-[17px]">
          <button
            onClick={() => {setArticleType("")}}
            className={classNames(FILTERSTYLE, {
              "text-era-red bg-era-red/[0.1]": articleType === "",
              "text-[#8E8E8E] bg-[#ABABAB1A]/[0.1]": articleType !== "",
            })}
          >
            ทั้งหมด
          </button>
          <button
            onClick={() => {setArticleType("news")}}
            className={classNames(FILTERSTYLE, {
              "text-era-red bg-era-red/[0.1]": articleType === "news",
              "text-[#8E8E8E] bg-[#ABABAB1A]/[0.1]": articleType !== "news",
            })}
          >
            ข่าวสารองค์กร
          </button>
          <button
            onClick={() => {setArticleType("promotion")}}
            className={classNames(FILTERSTYLE, {
              "text-era-red bg-era-red/[0.1]": articleType === "promotion",
              "text-[#8E8E8E] bg-[#ABABAB1A]/[0.1]":
                articleType !== "promotion",
            })}
          >
            โปรโมชั่น
          </button>
        </div>
      </div>
      {/* <div className="grid grid-cols-4 gap-[40px] pl-[23px]"> */}
      <div id="CardContainer" className="w-full pl-[23px]">
        {alldata?.map((item, index) => {
          return (
            <span className="w-fit h-fit inline-block mx-6 my-10">
              <BlogCard
              key={item?._id}
              ID={item?._id}
              letload={() => {
                changetype(articleType);
              }}
            />
            </span>
          );
        })}
      </div>
      {alldata.length >= count ? (
        <></>
      ) : (
        // <div className="flex w-[950px] justify-center mt-[70px]">
        <div className="flex w-11/12 justify-center mt-[70px]">
          <PaginationMain
            total={count}
            perpage={moreload}
            quanDisplay={5}
            setSkip={pageSelect}
          />
        </div>
      )}
    </div>
  );
};

export default Bloglist;
