import React, { useRef } from "react";
import numeral from "numeral";
import COVERIMAGE1 from "../../Image/covercard1.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import DropDownInfo from "../../component/dropDownInfo";
import { useState } from "react";
import { API, S3_URL } from "../../api/api.main";
import { useEffect } from "react";
import qs, { stringify } from "qs";
import { useParams } from "react-router-dom";
import CardProduct from "../../component/card/cardProduct";
import { ArchiveIcon, ChevronDown, FilterIcon } from "lucide-react";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import PaginationMain from "../../component/pagination/paginationMain";

const PRODUCT_TYPE_STYLE =
  "flex justify-center w-[58px] rounded-r-[10px] text-white text-[20px] absolute z-10 top-[18px] left-0 py-[9.5px] max-sm:w-[24px] max-sm:h-[24px] max-sm:text-[11px] max-sm:rounded-l-[0px] max-sm:rounded-r-[3.72px] max-sm:pt-[2.3px] max-sm:pb-[3.75px] max-sm:px-[4.7px] max-sm:top-[8px] max-sm:left-0";
const FONT_TITLE = "text-[20px] font-bold max-sm:text-[12px] max-sm:font-bold";

const PRODUCT_NUMBER = {
  "": "ทั้งหมด",
  land: "ที่ดิน",
  singleHouse: "บ้านเดี่ยว",
  townHouse: "ทาวน์โฮม, ทาวน์เฮ้าส์",
  condo: "คอนโดมิเนียม",
  apartment: "อพาร์ทเมนท์",
  businessBuilding: "อาคารพาณิชย์",
  hotel: "โรงแรม, รีสอร์ท",
  office: "พื้นที่สำนักงาน",
  factory: "โรงงาน, คลังสินค้า",
  forinvestment: "เพื่อการลงทุน",
  etc: "อื่นๆ",
};

const ProductShow = () => {
  const [search, setSearch] = useState("");
  const [alldata, setAllData] = useState([]);
  const [count, setcount] = useState(0);
  const [DDOpen, setDDopen] = useState({
    open: false,
    sort: false,
  });
  const [forfilter, setForFilter] = useState({
    open: "",
    // sort: "",
    sort: {updatedAt:-1},
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // const [moreload, setMoreLoad] = useState(30);
  const [moreload, setMoreLoad] = useState(30);
  const [skip, setSkip] = useState(0);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const getParams = () => {
    let categoryParams = searchParams.get("category");
    let purposeParams = searchParams.get("purpose");
    let titleParams = searchParams.get("title");
    return { categoryParams, purposeParams, titleParams };
  };

  const filterWhere = {
    "": "product_title",
    title: "product_title",
    propcode: "product_propcode",
    contact: "product_contact.name",
    location: "product_location",
    category: "product_category",
    purpose: "product_purpose",
  };

  const defaultfilter = () => {
    if (
      location.search.split("=")[0].slice(1) !== "" &&
      location.search.split("=")[0].slice(1) === "purpose"
    ) {
      return {
        limit: moreload,
        sort: forfilter.sort ,
        // sort: forfilter.sort = "" ? forfilter.sort : { updatedAt: -1 },
        skip: skip,
        filter: forfilter.open !== "Draft" ? {
          product_purpose: { $in: [getParams().purposeParams] },
          [filterWhere[forfilter.open]]: { $regex: search, $options: "i" },
        } : {
          product_draft: "draft",
          product_purpose: { $in: [getParams().purposeParams] },
          product_title: { $regex: search, $options: "i" }
        },
      };
    } else if (
      location.search.split("=")[0].slice(1) !== "" &&
      location.search.split("=")[0].slice(1) === "category"
    ) {
      //console.log("111")
      return {
        limit: moreload,
        sort: forfilter.sort ,
        // sort: forfilter.sort = "" ? forfilter.sort : { updatedAt: -1 },
        skip: skip,
        filter: forfilter.open !== "Draft" ? {
          product_category: getParams().categoryParams,
          [filterWhere[forfilter.open]]: { $regex: search, $options: "i" },
        } : {
          product_draft: "draft",
          product_category: getParams().categoryParams,
          product_title: { $regex: search, $options: "i" }
        },
      };
    } else {
      //console.log("222")

      return {
        limit: moreload,
        sort: forfilter.sort ,
        // sort: forfilter.sort = "" ? forfilter.sort : { updatedAt: -1 },
        skip: skip,
        filter: forfilter.open !== "Draft" ? {
          [filterWhere[forfilter.open]]: { $regex: search, $options: "i" },
        } : {
          product_draft: "draft",
          product_title: { $regex: search, $options: "i" }
        },
      };
    }
  };
  // console.log("forfilter", filterWhere[forfilter.open]);
  const qsDefault = qs.stringify(defaultfilter());

  useEffect(() => {
    loadData();
    // console.log("aaa")
  }, [forfilter.sort]);



  useEffect(() => {
    loadData();
  }, [skip]);

  const loadData = async () => {
    try {
      setLoading(true);
      // console.log("qsDefault: ",qsDefault)
      const { data } = await API.get(`/products?${qsDefault}`);
      // console.log("params", getParams().categoryParams);
      // console.log("data", data);
      setAllData(data?.products);
      setcount(data?.count);
    } catch (error) {
      console.log("product error", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  let navigate = useNavigate();
  const routeChange = () => {
    let path = "/product/create";
    navigate(path);
  };

  const sortRef = useRef(null);

  const OutsideSortClick = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setDDopen((prev) => ({
            ...prev,
            sort: false,
          }));
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  OutsideSortClick(sortRef);

  const pageSelect = (number) => {
    // console.log("number",number)
    setSkip((number - 1) * moreload);
  };

  const selectSort = (sortway) => {
    setForFilter((prev) => ({
      ...prev,
      sort: sortway,
    }));
    setDDopen((prev) => ({
      ...prev,
      sort: false,
    }));
  };

  // console.log("sort",forfilter.sort)

  return (
    <div className="flex flex-col h-full px-[96px] py-[38px]  ">
      <div className="flex w-[980px] space-x-[26px] mb-[38px]">
        <label className="w-[692px] border-b-solid border-b-black border-b-[1px] pb-[14px] pl-[41px] justify-start relative">
          <svg
            width="18"
            height="18"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute left-0 top-[5px]"
          >
            <path
              d="M19 19L14.6569 14.6569M14.6569 14.6569C16.1046 13.2091 17 11.2091 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17C11.2091 17 13.2091 16.1046 14.6569 14.6569Z"
              stroke="#14181F"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <input
            placeholder="ค้นหาทรัพย์ (ชื่อ, ที่ตั้ง)"
            onKeyDown={(e) => {
              if (e.key === "Enter" && e.target.value !== "") {
                loadData();
              }
            }}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            value={search}
            className="w-full h-full outline-0"
          ></input>
        </label>
        <DropDownInfo
          name=""
          placeholder="ค้นหาโดย"
          allstyle="flex flex-col w-[157px] h-[44px]"
          inputstyle="flex justify-between h-[44px] px-[10px] py-[10px] border-[1px] border-[#8E8E8E] outline-none rounded-[8.4px]"
          placeholderstyle="text-[#000000] self-center"
          chevronStyle="w-[24px] h-[24px] stroke-[#000000]"
          reddot={false}
          openDropDown={Object.entries(DDOpen)[0]}
          setOpenDropDown={setDDopen}
          allInfo={Object.entries(forfilter)[0]}
          setAllInfo={setForFilter}
          choice={[
            {
              label: "ชื่อ",
              value: "title",
            },
            {
              label: "รหัส",
              value: "propcode",
            },
            {
              label: "ผู้ดูแลทรัพย์",
              value: "contact",
            },
            {
              label: "ที่ตั้ง",
              value: "location",
            },
            {
              label: "Draft",
              value: "Draft",
            },
          ]}
        />
        <button
          onClick={loadData}
          className="h-full px-[21px] py-[9px] bg-era-red rounded-[10px] text-[16px] font-medium text-white duration-300 hover:bg-[#E53648]"
        >
          ค้นหา
        </button>
        <div ref={sortRef} className="relative">
          <button
            onClick={() => {
              //DDOpen, setDDopen
              setDDopen((prev) => ({
                ...prev,
                sort: !DDOpen.sort,
              }));
            }}
            className="flex justify-center bg-[#CECECE]/[0.37] px-[10px] items-center rounded-[8px]"
          >
            <div className="flex justify-center w-[100px] h-[44px]">
              <div className="flex w-[24px] h-full items-center">
                <FilterIcon width={24} height={24} />
              </div>
              <div className="flex text-base font-medium h-full items-center">Sort by</div>
            </div>
          </button>
          {/* {forfilter.sort !== "" && (
            <div className="w-3 h-3 rounded-md bg-red-500 absolute -top-1 -right-1"></div>
          )} */}
          {DDOpen.sort && (
            <div className="bg-white absolute right-0 w-[280px] shadow-admin_nav rounded-[8px] space-y-4 px-[24px] py-[15px] z-[20]">
              <button
                onClick={() => {
                  selectSort({ updatedAt: -1 });
                }}
                className={twMerge(
                  classNames("w-full flex justify-start", {
                    "bg-[#CECECE]/[0.37]": forfilter.sort?.updatedAt === -1,
                  })
                )}
              >
                เรียงจากล่าสุด - เก่าสุด
              </button>
              <button
                onClick={() => {
                  selectSort({ updatedAt: 1 });
                }}
                className={twMerge(
                  classNames("w-full flex justify-start", {
                    "bg-[#CECECE]/[0.37]": forfilter.sort?.updatedAt === 1,
                  })
                )}
              >
                เรียงจากเก่าสุด - ล่าสุด
              </button>
              <button
                onClick={() => {
                  selectSort({ product_price: -1 })
                }}
                className={twMerge(
                  classNames("w-full flex justify-start", {
                    "bg-[#CECECE]/[0.37]": forfilter.sort?.product_price === -1,
                  })
                )}
              >
                เรียงจากราคามากสุด - น้อยสุด
              </button>
              <button
                onClick={() => {
                  selectSort({ product_price: 1 })
                }}
                className={twMerge(
                  classNames("w-full flex justify-start", {
                    "bg-[#CECECE]/[0.37]": forfilter.sort?.product_price === 1,
                  })
                )}
              >
                เรียงจากราคาน้อยสุด - มากสุด
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-start w-[980px] space-x-[26px] mb-[38px]">
        <div className="self-end px-[9px] min-w-[163px] h-[36px] flex space-x-[9px] justify-center items-center font-medium line-clamp-1 text-era-red text-base border-solid border-[0.5px] border-era-red rounded-[13px]">
          <ArchiveIcon className="w-5 h-5 " />
          {getParams()?.categoryParams
            ? PRODUCT_NUMBER[getParams().categoryParams]
            : "ทั้งหมด"}
          :
          {count}
        </div>
      </div>
      {alldata.length === 0 ? (
        <div className="w-[980px] flex justify-center mt-[50px] text-3xl font-semibold text-era-red">
          ไม่พบทรัพย์
        </div>
      ) : loading ? (
        <div className="w-[980px] flex justify-center mt-[50px] text-3xl font-semibold text-era-red">
          loading . . .
        </div>
      ) : (
        // <div className="grid grid-cols-3 gap-y-[70px] w-[980px]">
        <div id="cardContainer" className="w-full">
          {alldata.map((item, index) => {
            return (
              <span className="items-start w-fit h-fit inline-block mx-6 my-10">
                <CardProduct
                  ID={item?._id}
                  product_draft={item?.product_draft}
                  product_title={item?.product_title}
                  product_cover={S3_URL + "/" + item?.product_cover}
                  product_type={item?.product_type}
                  product_location={item?.product_location}
                  product_bedRoom={item?.product_bedRoom}
                  product_bathRoom={item?.product_bathRoom}
                  product_price={item?.product_price}
                  product_price_rent={item?.product_price_rent}
                  product_category={item?.product_category}
                  product_area={item?.product_area}
                  product_unitarea={item?.product_unitarea}
                  product_nearby={item?.product_nearby}
                  product_district={item?.product_district}
                  product_sub_district={item?.product_sub_district}
                  product_province={item?.product_province}
                  product_postcode={item?.product_postcode}
                  product_view={item?.product_view}
                  loadData={loadData}
                />
              </span>
            );
          })}
        </div>
      )}
      {alldata.length >= count ? (
        <></>
      ) : (
        // <div className="flex w-[950px] justify-center mt-[70px]">
        <div className="flex w-11/12 justify-center mt-[70px]">
          <PaginationMain
            total={count}
            perpage={moreload}
            quanDisplay={5}
            setSkip={pageSelect}
          />
        </div>
      )}
    </div>
  );
};

export default ProductShow;
