import React, { useState } from "react";
import InputText from "../../component/textinput";
import ERA_LOGO from "../../Image/ERA-logo-timeline.png";
import { API } from "../../api/api.main";
import Swal from "sweetalert2";
import { userStore } from "../../store/userStore";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);
  const [allInfo, setAllInfo] = useState({
    login_username: "",
    login_password: "",
  });
  const userState = userStore.getState()
  const navto = useNavigate()

  console.log("allInfo", allInfo)

  const clickToSubmit = async () => {
    try {
        setLoading(true);
      const { data } = await API.post("logins/login", {
        login_username: allInfo.login_username,
        login_password: allInfo.login_password,
      });

      if (data?.user) {
        userState.setUser(data?.user)
        navto("/product")
      } else{
        Swal.fire({
            title: "ไม่พบผู้ใช้นี้",
            icon: "error",
          });
      }
    } catch (error) {
      Swal.fire({
        title: "เข้าสู่ระบบผิดพลาด",
        icon: "error",
      });
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };
  //   Swal.fire({
  //     title: "เข้าสู่ระบบเรียบร้อย",
  //     icon: "success",
  //   })

  return (
    <div className="flex justify-center items-center w-full h-screen bg-era-red">
      <div className="flex flex-col w-[747px] h-fit bg-white p-[40px] space-y-[20px] items-center rounded-[20px]">
      {/* <div className="flex flex-col w-[747px] h-[375px] bg-white p-[40px] space-y-[20px] items-center rounded-[20px]"> */}
        <img src={ERA_LOGO} alt="Logo" className="w-[48px] h-[73px]" />
        <InputText
          name="Username"
          placeholder="Username"
          allInfo={Object.entries(allInfo)[0]}
          setAllInfo={setAllInfo}
        />
        <InputText
          name="Password"
          placeholder="Password"
          allInfo={Object.entries(allInfo)[1]}
          setAllInfo={setAllInfo}
        />
        Username:aa Password:qq
        <button
          onClick={clickToSubmit}
          className="flex justify-center items-center text-[14px] font-light w-full py-[10px] px-[20px] bg-era-red text-white rounded-[105px]"
        >
          LOGIN
        </button>
      </div>
    </div>
  );
};

export default LoginPage;
