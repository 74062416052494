import React from "react";

const IconNote1 = ({ width ="24", height ="24", fill ="#030D45"}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="group-hover:fill-[#C41F30]"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 3.53846C10.4751 3.53846 8.42753 3.70582 6.99542 3.8447C6.40117 3.90233 5.93741 4.3731 5.89141 4.96993C5.749 6.81754 5.54839 9.81282 5.54839 12C5.54839 14.1872 5.749 17.1825 5.89141 19.0301C5.93741 19.6269 6.40117 20.0977 6.99542 20.1553C8.42753 20.2942 10.4751 20.4615 12 20.4615C13.5249 20.4615 15.5725 20.2942 17.0046 20.1553C17.5988 20.0977 18.0626 19.6269 18.1086 19.0301C18.251 17.1825 18.4516 14.1872 18.4516 12C18.4516 9.81282 18.251 6.81754 18.1086 4.96993C18.0626 4.3731 17.5988 3.90233 17.0046 3.8447C15.5725 3.70582 13.5249 3.53846 12 3.53846ZM6.84502 2.31351C8.28091 2.17427 10.3935 2 12 2C13.6065 2 15.7191 2.17427 17.155 2.31351C18.5029 2.44423 19.5495 3.51605 19.6525 4.85245C19.7949 6.70071 20 9.74813 20 12C20 14.2519 19.7949 17.2993 19.6525 19.1476C19.5495 20.484 18.5029 21.5558 17.155 21.6865C15.7191 21.8257 13.6065 22 12 22C10.3935 22 8.28091 21.8257 6.84502 21.6865C5.49709 21.5558 4.45055 20.484 4.34754 19.1476C4.20508 17.2993 4 14.2519 4 12C4 9.74813 4.20508 6.70071 4.34754 4.85245C4.45055 3.51605 5.49709 2.44423 6.84502 2.31351Z"
        fill={fill}
        className="group-hover:fill-[#C41F30]"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.12903 14.0513C8.12903 14.4761 8.47565 14.8205 8.90323 14.8205H15.0968C15.5243 14.8205 15.871 14.4761 15.871 14.0513C15.871 13.6264 15.5243 13.2821 15.0968 13.2821H8.90323C8.47565 13.2821 8.12903 13.6264 8.12903 14.0513Z"
        fill={fill}
        className="group-hover:fill-[#C41F30]"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.12903 9.94872C8.12903 10.3736 8.47565 10.7179 8.90323 10.7179H12C12.4276 10.7179 12.7742 10.3736 12.7742 9.94872C12.7742 9.52388 12.4276 9.17949 12 9.17949H8.90323C8.47565 9.17949 8.12903 9.52388 8.12903 9.94872Z"
        fill={fill}
        className="group-hover:fill-[#C41F30]"
      />
    </svg>
  );
};

export default IconNote1;
