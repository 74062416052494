import React from "react";
import classNames from "classnames";

const Iconhome1 = ({width="24",height="24",fill="#000",stroke="" ,className="group-hover:fill-[#C41F30]"}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.7166 3.79535C12.2835 3.4971 11.7165 3.4971 11.2834 3.79535L4.14336 8.71204C3.81027 8.9414 3.60747 9.31102 3.59247 9.70791C3.54064 11.0799 3.4857 13.4824 3.63658 15.1877C3.7504 16.4741 4.05336 18.1746 4.29944 19.4256C4.41371 20.0065 4.91937 20.4283 5.52037 20.4283H8.84433C8.98594 20.4283 9.10074 20.3111 9.10074 20.1664V15.9753C9.10074 14.9626 9.90433 14.1417 10.8956 14.1417H13.4091C14.4004 14.1417 15.204 14.9626 15.204 15.9753V20.1664C15.204 20.3111 15.3188 20.4283 15.4604 20.4283H18.4796C19.0806 20.4283 19.5863 20.0065 19.7006 19.4256C19.9466 18.1746 20.2496 16.4741 20.3634 15.1877C20.5143 13.4824 20.4594 11.0799 20.4075 9.70791C20.3925 9.31102 20.1897 8.9414 19.8566 8.71204L12.7166 3.79535ZM10.4235 2.49211C11.3764 1.83596 12.6236 1.83596 13.5765 2.49211L20.7165 7.4088C21.4457 7.91092 21.9104 8.73645 21.9448 9.6473C21.9966 11.0177 22.0564 13.5119 21.8956 15.3291C21.7738 16.7066 21.4561 18.4785 21.2089 19.7352C20.9461 21.071 19.7924 22 18.4796 22H15.4604C14.4691 22 13.6655 21.1791 13.6655 20.1664V15.9753C13.6655 15.8306 13.5507 15.7133 13.4091 15.7133H10.8956C10.754 15.7133 10.6392 15.8306 10.6392 15.9753V20.1664C10.6392 21.1791 9.83561 22 8.84433 22H5.52037C4.20761 22 3.05389 21.071 2.79113 19.7352C2.54392 18.4785 2.22624 16.7066 2.10437 15.3291C1.94358 13.5119 2.00338 11.0177 2.05515 9.6473C2.08957 8.73645 2.55427 7.91092 3.28346 7.4088L10.4235 2.49211Z"
        fill={fill}
        className={className}
      />
    </svg>
  );
};

export default Iconhome1;
