import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { API } from "../../api/api.main";

const DataDetail = ({ title = "", detail = "", style = "" }) => {
  return (
    <div>
      <div>{title}</div>
      <div className={style}>{detail}</div>
    </div>
  );
};

const CareerDatail = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [alldata, setAllData] = useState([]);
  const [reqtitle, setReqTitle] = useState("");
  const params = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    console.log("location", location.search.split("=")[1]);
    setReqTitle(location.search.split("=")[1]);
    loadData();
  }, []);

  const loadData = async () => {
    const { data } = await API.get("/appliedjobs/" + params.id);
    // console.log("data.appliedJobs",data.appliedJobs)
    setAllData(data?.appliedJobs);
  };

  return (
    <div className=" pl-[40px] pt-[28px] flex flex-col">
      <div className="text-[28px] text-era-red font-semibold mb-[21px]">
        รายละเอียด
      </div>
      <div className="flex space-x-[32px] mb-[20px]">
        <DataDetail
          title="ชื่อ-นามสกุล"
          detail={alldata?.appliedjob_name}
          style="w-[534px] h-[52px] px-[24px] rounded-[8.4px] flex items-center border-solid border-[1px] border-black/[0.29]"
        />
        <DataDetail
          title="Line id"
          detail={alldata?.appliedjob_lineID}
          style="w-[534px] h-[52px] px-[24px] rounded-[8.4px] flex items-center border-solid border-[1px] border-black/[0.29]"
        />
      </div>
      <div className="flex space-x-[32px] mb-[20px]">
        <DataDetail
          title="เบอร์โทร"
          detail={alldata?.appliedjob_tel}
          style="w-[534px] h-[52px] px-[24px] rounded-[8.4px] flex items-center border-solid border-[1px] border-black/[0.29]"
        />
        <DataDetail
          title="อาชีพปัจจุบัน"
          detail={alldata?.appliedjob_currentJob}
          style="w-[534px] h-[52px] px-[24px] rounded-[8.4px] flex items-center border-solid border-[1px] border-black/[0.29]"
        />
      </div>
      <DataDetail
        title="โซนที่อยู่อาศัย"
        detail={alldata?.appliedjob_livingArea}
        style="w-[1098px] h-[100px] px-[11px] py-[12px] rounded-[8.4px] flex border-solid border-[1px] border-black/[0.29] mb-[25px]"
      />
    </div>
  );
};

export default CareerDatail;
