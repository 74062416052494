import React, { useEffect, useState } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { onImageUploadBefore } from "../../utils/util.con";
import "./font.css";

const FieldWysiwyg = ({ value, setValue, setHasFeature, onChange }) => {
  const [loaded, setLoaded] = React.useState(false);

  React.useLayoutEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 1000);
  }, []);

  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // only execute all the code below in client side
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    if (typeof window !== "undefined") {
      // Add event listener
      window.addEventListener("resize", handleResize);

      // Call handler right away so state gets updated with initial window size
      handleResize();
    }

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  if (loaded) {
    return (
      <SunEditor
        showToolbar={true}
        onChange={onChange}
        setContents={value}
        setDefaultStyle="height: auto ;text-align:left"
        onImageUploadBefore={onImageUploadBefore}
        setOptions={{
          minHeight: 500,
          font: [
            "Sarabun",
            "Arial",
            "Comic Sans MS",
            "Courier New",
            "Impact",
            "Georgia",
            "tahoma",
            "Trebuchet MS",
            "Verdana",
            "Kanit"
          ],
          maxWidth: windowSize.width,
          buttonList: [
            ["font", "fontSize", "align", "fontColor", "hiliteColor"],
            [
              "bold",
              "underline",
              "italic",
              "strike",
              "subscript",
              "superscript",
            ],
            ["horizontalRule", "list", "table"],
            ["image", "link"],
            ["undo", "redo"],
          ],
        }}
      />
    );
  } else {
    return (
      <div className="h-[500px] flex flex-col justify-center h-full mx-auto">
        <img alt="" src="/Loading_2.gif" width={30} height={30} />
      </div>
    );
  }
};

export default FieldWysiwyg;
