import { ChevronLeft, ChevronRight } from "lucide-react";
import React, { useEffect, useState } from "react";
import { PAGINATION_BUTTON } from "../../utils/util.style";
import { twMerge } from "tailwind-merge";
import classNames from "classnames";

const ARROW_BUTTON = PAGINATION_BUTTON + " bg-[#CECECE]/[0.37]";
const NUMBER_BUTTON = PAGINATION_BUTTON + " bg-white";

const PaginationMain = ({
  total = 0,
  perpage = 0,
  quanDisplay = 3,
  setSkip,
}) => {
  const pageTotal = Math.ceil(total / perpage);
  const [pageGroup, setPageGroup] = useState(1);
  const [currrentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    pagingNumber();
    setSkip(currrentPage)
  }, [currrentPage]);

  const pagingNumber = () => {
    let pagingSet = [];
    for (let i = currrentPage - 2; i <= currrentPage + 2; i++) {
      if (i > 0 && i <= pageTotal) {
        pagingSet.push(
          <button
            onClick={() => {
              setSkip(i);
              setCurrentPage(i);
            }}
            className={twMerge(
              classNames(NUMBER_BUTTON, {
                "bg-era-red text-white": currrentPage === i,
              })
            )}
          >
            {i}
          </button>
        );
      }
    }
    return pagingSet;
  };

  const changeGroup = (ways) => {
    if (ways === "next") {
      if (currrentPage === pageTotal) {
        setCurrentPage(currrentPage);
        // setPageGroup(pageGroup);
      } else {
        // setPageGroup(pageGroup + 1);
        setCurrentPage(currrentPage + 1);
      }
    }

    if (ways === "prev") {
      if (currrentPage === 1) {
        // setPageGroup(1);
        setCurrentPage(1);
      } else {
        // setPageGroup(pageGroup - 1);
        setCurrentPage(currrentPage - 1);
      }
    }
  };

  return (
    <div className="w-full h-[50px] px-2 flex justify-between items-center">
      <div>showing {(currrentPage-1)*perpage+1} - {currrentPage*perpage <= total ? (currrentPage*perpage):(total)} of list </div>
      <div className="flex space-x-5 items-center">
        <div className="flex space-x-2 items-center">
          {currrentPage - 4 >= 0 && <button
            onClick={() => {
              changeGroup("prev");
            }}
            className={ARROW_BUTTON}
          >
            <ChevronLeft />{" "}
          </button>}
          {pagingNumber()}
          {currrentPage + 3 < pageTotal &&
            (<div className="flex space-x-2 items-center">
              <div>...</div>
              <button
                onClick={() => {
                  setSkip(pageTotal);
                  setCurrentPage(pageTotal);
                }}
                className={twMerge(
                  classNames(NUMBER_BUTTON, {
                    "bg-era-red text-white": currrentPage === 13,
                  })
                )}
              >
                {pageTotal}
              </button>
            </div>)
          }

          {currrentPage + 3 < pageTotal && (
            <button
              onClick={() => {
                changeGroup("next");
              }}
              className={ARROW_BUTTON}
            >
              <ChevronRight />
            </button>
          )}
        </div>
        <div className="h-full items-center">
          {"ทั้งหมด " + pageTotal + " หน้า"}
        </div>
      </div>
    </div>
  );
};

export default PaginationMain;
